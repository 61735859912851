import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import * as colors from '@shared/colors'
import { AuthContext } from '../../../../../../app/modules/Auth/AuthContext'
import { UpgradePlanButton, ButtonText } from '../../../../../../app/modules/Accounts/pages/account-page/subscription-plans/style'
import { renderIcon } from '../../../../../../app/modules/Accounts/pages/account-page/subscription-plans/components/Plan'
import { SETTINGS } from '../../../../../../app/navigation/url-constants'
import { capitalizeFirstLetter } from '@shared/helpers'
import { SubscriptionIndicatorWrapper, SubscriptionName, TrialPeriodIndicator } from '../style'

const SubscriptionIndicator = ({ asideIsOpen = true, tracesView = false }) => {
    const history = useHistory()
    const { orgSubscription } = useContext(AuthContext)
    const [trialText, setTrialText] = useState('')
    const [showUpgradeButton, setShowUpgradeButton] = useState(false)
    const [curentSubscriptionIndicator, setSurentSubscriptionIndicator] = useState('')
    const [showTracesUpgradeButton, setShowTracesUpgradeButton] = useState(false)
    const goToUpgrade = () => {
        history.push(SETTINGS.ACCOUNT.SUBSCRIPTION)
    }
    useEffect(() => {
        if (orgSubscription) {
            const subscriptionName = capitalizeFirstLetter(orgSubscription.name)
            setShowUpgradeButton((subscriptionName === 'Basic' || orgSubscription.status === 'trial') && asideIsOpen)
            setShowTracesUpgradeButton(subscriptionName === 'Basic' || (subscriptionName === 'Pro' && !orgSubscription.status === 'trial'))
            setSurentSubscriptionIndicator(`${subscriptionName} ${renderIcon(subscriptionName)} subscription`)
            if (orgSubscription.status === 'trial') {
                const trialDays = moment(orgSubscription.trialEnd).startOf('day').diff(moment().startOf('day'), 'days')
                setTrialText(trialDays > 0 ? `Trial ends in ${trialDays} ${trialDays > 1 ? 'days' : 'day'}` : 'Your trial has expired')
            }
        }
    }, [orgSubscription])

    return (
        <SubscriptionIndicatorWrapper tracesView={tracesView}>
            {asideIsOpen && (
                <SubscriptionName tracesView={tracesView} onClick={goToUpgrade}>
                    {curentSubscriptionIndicator}
                </SubscriptionName>
            )}
            {asideIsOpen && trialText && <TrialPeriodIndicator>{trialText}</TrialPeriodIndicator>}

            {!tracesView && asideIsOpen && showUpgradeButton && (
                <UpgradePlanButton onClick={goToUpgrade}>
                    <ButtonText className="font-size-lg" color={colors.white}>
                        Upgrade
                    </ButtonText>
                </UpgradePlanButton>
            )}

            {tracesView && showTracesUpgradeButton && (
                <ButtonText onClick={goToUpgrade} className="font-size-lg" color={colors.red_sweeply}>
                    Upgrade
                </ButtonText>
            )}
        </SubscriptionIndicatorWrapper>
    )
}

export default SubscriptionIndicator
