import React, { useState } from 'react'
import * as c from '@shared/constants'
import { useRecoilState } from 'recoil'
import { occupancyFilterValueAtom } from 'app/modules/HousekeepingDashboard/state/atoms'
import { pickOccupancyIcon } from 'app/utils/housekeeping-helpers'
import { IconButton } from 'app/components/buttons/IconButton'
import { FilterButtonWrap, ResetButton } from '../style'
import ContextMenu from 'app/components/ContextMenu'
import { OccupancyFilterValue } from 'app/modules/HousekeepingDashboard/types'
import OccupancyOption from './OccupancyOption'

const occupancyFilterOptions: OccupancyFilterValue[] = [
    c.OCCUPANCY_ALL,
    c.OCCUPANCY_CHECKIN,
    c.OCCUPANCY_STAYOVER,
    c.OCCUPANCY_STAYOVER_80,
    c.OCCUPANCY_CHECKOUT,
    c.OCCUPANCY_VACANT,
    c.OCCUPANCY_TURNOVER
]

function OccupancyFilter() {
    const [showDropdown, setShowDropdown] = useState(false)
    const [occupancyFilterValue, setOccupancyFilterValue] = useRecoilState(occupancyFilterValueAtom)

    function onOptionClick(value: OccupancyFilterValue) {
        setOccupancyFilterValue(value)
    }

    function onResetClick() {
        setOccupancyFilterValue(c.OCCUPANCY_ALL)
    }

    return (
        <FilterButtonWrap>
            <IconButton
                className={
                    occupancyFilterValue === c.OCCUPANCY_ALL
                        ? 'btn btn-circle btn-icon btn-specta-gray'
                        : 'btn btn-circle btn-icon btn-danger'
                }
                icon={pickOccupancyIcon(occupancyFilterValue) + ' icon-lg'}
                tooltip={{
                    text: 'Occupancy filter',
                    position: 'top'
                }}
                onClick={() => setShowDropdown(!showDropdown)}
            />

            {showDropdown && (
                <ContextMenu width={218} onBlur={() => setShowDropdown(false)}>
                    {occupancyFilterOptions.map((option, index) => (
                        <OccupancyOption key={option + index} occupancyOption={option} onClick={onOptionClick} />
                    ))}
                    <ResetButton onClick={onResetClick}>Reset</ResetButton>
                </ContextMenu>
            )}
        </FilterButtonWrap>
    )
}

export default OccupancyFilter
