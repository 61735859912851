import firebase from '../../utils/firebase'

export const checkAppUpgradeNeeded = async () => {
    return false
    // console.log('Checking for new version: ', new Date())
    // let cacheTimeOutSeconds = 3600
    // const remoteConfig = firebase.remoteConfig()
    // const appVersion = import.meta.env.VITE_VERSION
    //
    // const __DEV__ = import.meta.env.DEV
    //
    // if (__DEV__) {
    //     cacheTimeOutSeconds = 2
    // }
    //
    // remoteConfig.settings = {
    //     minimumFetchIntervalMillis: cacheTimeOutSeconds * 1000
    // }
    //
    // // Set default values
    // remoteConfig.defaultConfig = {
    //     checkVersion: false,
    //     hasOldVersion: false
    // }
    //
    // if (!__DEV__) {
    //     await remoteConfig.fetchAndActivate()
    //     const checkVersion = remoteConfig.getValue('checkVersion').asBoolean()
    //     if (!checkVersion) {
    //         return false
    //     }
    //     const webVersion = remoteConfig.getValue('webVersion').asString()
    //     if (webVersion !== appVersion) {
    //         return true
    //     }
    //     return false
    // }
    // return false
}
