import React, { useState, forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import './react-datepicker.css'
import * as uc from '../utils/constants'
import { HoverableImageButton } from './buttons/hoverable-image-button'
import { SubscriptionCheck } from './upgrade-signals'
import styled from 'styled-components'

const DatePickerWrap = styled.div`
    border-width: 0;
    height: ${uc.BUTTON_DIM};

    div {
        justify-content: flex-end !important;
    }
`

export const ButtonDatePicker = ({
    selectedDateNumber,
    onDateChange,
    disabled,
    disabledOpacity,
    tooltipDistance,
    isWhite = false,
    placement,
    subscriptionFeatureId = null,
    openUpgradeMessage = () => {},
    currentOrganization = null
}) => {
    const [selectedPickerDate, setSelectecPickerDate] = useState(moment())
    const ref = React.createRef()
    const selectedPickerDateNumber = selectedPickerDate.valueOf()

    if (selectedDateNumber !== selectedPickerDateNumber) {
        setSelectecPickerDate(moment(selectedDateNumber))
    }

    const tooltip_distance = tooltipDistance ? tooltipDistance : 0

    const imgSource = isWhite ? require('../img/due-date-button-white.svg') : require('../img/due-date-button.svg')

    const popper_placement = placement ? placement : 'bottom-end'

    const DEBUG_BORDER = 0

    const CustomDateInput = forwardRef((props, ref) => {
        if (subscriptionFeatureId) {
            return (
                <SubscriptionCheck
                    featureId="assign-housekeeping"
                    onAvailable={() => (
                        <HoverableImageButton
                            ref={ref}
                            tooltipDistance={tooltip_distance}
                            dimension={uc.BUTTON_DIM}
                            imageSource={imgSource}
                            onPress={props.onClick}
                            tooltipText={'Due date'}
                            tooltipFontSize={13}
                            disabledOpacity={disabledOpacity}
                            disabled={disabled}
                        />
                    )}
                    onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                        return (
                            <HoverableImageButton
                                ref={ref}
                                tooltipDistance={tooltip_distance}
                                dimension={uc.BUTTON_DIM}
                                imageSource={imgSource}
                                onPress={() => {
                                    openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                }}
                                tooltipText={'Due date'}
                                tooltipFontSize={13}
                                disabledOpacity={disabledOpacity}
                                disabled={disabled}
                            />
                        )
                    }}
                />
            )
        }
        return (
            <HoverableImageButton
                ref={ref}
                tooltipDistance={tooltip_distance}
                dimension={uc.BUTTON_DIM}
                imageSource={imgSource}
                onPress={props.onClick}
                tooltipText={'Due date'}
                tooltipFontSize={13}
                disabledOpacity={disabledOpacity}
                disabled={disabled}
            />
        )
    })

    CustomDateInput.displayName = 'CustomDateInput'

    function onDateSelect(date) {
        setSelectecPickerDate(date)
        onDateChange(date)
    }

    return (
        <DatePickerWrap>
            <DatePicker
                minDate={moment().toDate()}
                dateFormat="eee, MMM dd, yyyy"
                selected={selectedPickerDateNumber}
                customInput={<CustomDateInput ref={ref} />}
                popperPlacement={popper_placement}
                onSelect={onDateSelect}
            />
        </DatePickerWrap>
    )
}
