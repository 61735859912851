import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Loader from '../../components/loaders/Loader'
import { dataLoadingAtom, minimizedTasksAtom } from '../state/atoms'
import TasksColumns from './TasksColumns'
import AsyncStorage from '@react-native-community/async-storage'
import withErrorCatching from '../../utils/hocs/withErrorCatching'
import { CatchError } from '../../types'
import useTaskboardFirestore from '../useTaskboardFirestore'

const TasksBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
`

interface ITasksBody {
    catchError: CatchError
}

function TasksBody({ catchError }: ITasksBody) {
    const dataLoading = useRecoilValue(dataLoadingAtom)
    const setMinimizedTasks = useSetRecoilState(minimizedTasksAtom)

    const { error } = useTaskboardFirestore()

    useEffect(() => {
        if (error) {
            console.error(error)
            const message = 'Something went wrong while loading tasks data. Please reload the page or try again later.'
            const onClick = () => window.location.reload()
            catchError(message, { button: { text: 'Reload', onClick }, contactSupportMessage: true })
        }
    }, [error])

    useEffect(() => {
        AsyncStorage.getItem('minimizedTasks', (err, result) => {
            if (result && !err) setMinimizedTasks(JSON.parse(result))
        })
    }, [])

    return (
        <TasksBodyWrapper>
            <TasksBodyWrapper>{dataLoading ? <Loader /> : <TasksColumns />}</TasksBodyWrapper>
        </TasksBodyWrapper>
    )
}

export default withErrorCatching(TasksBody)
