import ModalLayout from 'app/components/modals/ModalLayout'
import React from 'react'
import { Wrapper } from './style'
import { ModalDescription, ModalTitle } from '../style'
import RectangularButton from 'app/components/buttons/RectangularButton'

interface Props {
    title: string
    description: string
    onClick: () => void
}

function ReconnectScreen({ onClick, title, description }: Props) {
    return (
        <ModalLayout>
            <Wrapper>
                <ModalTitle>{title}</ModalTitle>

                <ModalDescription dangerouslySetInnerHTML={{ __html: description }} />

                <RectangularButton onClick={onClick}>Reconnect</RectangularButton>
            </Wrapper>
        </ModalLayout>
    )
}

export default ReconnectScreen
