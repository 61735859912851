import React, { useEffect } from 'react'

export function useClickOutside(ref: React.RefObject<HTMLElement>, onOutsideClick: () => void) {
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleClickOutside = (event: MouseEvent) => {
        const menuElement = ref.current
        const parentElement = ref.current?.parentElement

        if (menuElement && menuElement.contains(event.target as Node)) {
            return
        }

        if (menuElement && !menuElement.contains(event.target as Node) && parentElement && !parentElement.contains(event.target as Node)) {
            onOutsideClick()
        }
    }
}
