import React from 'react'
import { DashboardProvider } from './DashboardContext'
import ProjectionsView from './projections-view'

const HousekeepingProjectionView = () => {
    return (
        <DashboardProvider>
            <ProjectionsView />
        </DashboardProvider>
    )
}

export default HousekeepingProjectionView
