import React, { useMemo, useState } from 'react'
import { Wrapper, NavItemText } from './style'
import { NavItemType } from 'app/navigation/types'
import { useHistory, useLocation } from 'react-router-dom'
import * as URL from 'app/navigation/url-constants'
import HousekeepingIcon from '@shared/img/navbar/new/housekeeping-nav-icon.svg?react'
import TaskboardIcon from '@shared/img/navbar/new/tasks-nav-icon.svg?react'
import IssuesIcon from '@shared/img/navbar/new/issues-nav-icon.svg?react'
import SettingsIcon from '@shared/img/navbar/new/settings-nav-icon.svg?react'
import ReportsIcon from '@shared/img/navbar/new/reports-nav-icon.svg?react'
import HelpIcon from '@shared/img/navbar/new/help-nav-icon.svg?react'
import SubNavMenu from './SubNavMenu'
import { SelectOption } from 'app/types'
import { SingleValue } from 'react-select'

interface NavItemProps {
    navItem: NavItemType
}

function NavItem({ navItem: { name, path, subMenuItems } }: NavItemProps) {
    const history = useHistory()
    const { pathname } = useLocation()

    const [subMenuOpen, setSubMenuOpen] = useState(false)
    const [subMenuValue, setSubMenuValue] = useState<SelectOption<string> | null>(null)
    const [showTabNames, setShowTabNames] = useState(window.innerWidth > 1150)

    function widthResizer() {
        if (window.innerWidth > 1150) {
            setShowTabNames(true)
        } else setShowTabNames(false)
    }
    window.addEventListener('resize', widthResizer)

    const iconComponent = useMemo(() => pickIcon(path), [])
    const active = useMemo(() => (subMenuItems.length > 0 ? pathname.includes(path) : pathname === path), [pathname, path])

    const subMenuOptions = useMemo(() => subMenuItems.map(({ name, path }) => ({ label: name, value: path })), [])

    function onClick() {
        if (subMenuItems.length > 0) {
            setSubMenuOpen(!subMenuOpen)
            return
        }

        if (path.includes('http')) {
            window.open(path, '_blank', 'noopener noreferrer')
        } else {
            history.push(path)
        }
    }

    function onSubMenuItemClick(value: SingleValue<SelectOption<string>>) {
        setSubMenuValue(value)
        value && history.push(value.value)
    }
    return (
        <Wrapper active={active} onClick={onClick}>
            {iconComponent}
            {subMenuItems.length > 0 ? (
                <SubNavMenu
                    onBlur={() => setSubMenuOpen(false)}
                    controlComponent={({ children, ...props }) => {
                        return (
                            <NavItemText active={active} {...props}>
                                {showTabNames && name}
                                {children}
                            </NavItemText>
                        )
                    }}
                    value={subMenuValue}
                    options={subMenuOptions}
                    onChange={onSubMenuItemClick}
                    menuIsOpen={subMenuOpen}
                />
            ) : (
                <NavItemText active={active}>{showTabNames && name}</NavItemText>
            )}
        </Wrapper>
    )
}

function pickIcon(path: string) {
    switch (path) {
        case URL.HOUSEKEEPING.INDEX:
            return <HousekeepingIcon />

        case URL.TASKBOARD.APALEO:
            return <TaskboardIcon />

        case URL.TASKBOARD.INDEX:
            return <TaskboardIcon />

        case URL.ISSUES.INDEX:
            return <IssuesIcon />

        case URL.SETTINGS.INDEX:
            return <SettingsIcon />

        case URL.REPORTS.INDEX:
            return <ReportsIcon />

        case URL.HELP:
            return <HelpIcon />

        default:
            return null
    }
}

export default NavItem
