import React, { memo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SIGN_UP } from '../../navigation/url-constants'
import ApaleoAuthorize from './pms/apaleo/ApaleoAuthorize'
import SignUpWelcome from './SignUpWelcome/index'
import NoPmsStart from './pms/no-pms/NoPmsStart'
import StartScreen from './StartScreen'
import NoPmsCallback from './pms/no-pms/NoPmsCallback'
import Beds24Start from './pms/beds24/Beds24Start'
import Beds24Config from './pms/beds24/Beds24Config'
import ApaleoStart from './pms/apaleo/ApaleoStart'
import ApaleoCallback from './pms/apaleo/ApaleoCallback'
import RentlioStart from './pms/rentlio/RentlioStart'
import RentlioAuthorize from './pms/rentlio/RentlioAuthorize'
import GuestyAuthorize from './pms/guesty/GuestyAuthhorize'
import RentlioCallback from './pms/rentlio/RentlioCallback'
import BookingFactoryStart from './pms/booking-factory/BookingFactoryStart'
import BookingFactorySetup from './pms/booking-factory/BookingFactorySetup'
import MewsStart from './pms/mews/MewsStart'
import MewsSetup from './pms/mews/MewsSetup'
import CloudbedsAuthorize from './pms/cloudbeds/CloudbedsAuthorize'
import CloudbedsStart from './pms/cloudbeds/CloudbedsStart'
import CloudbedsCallback from './pms/cloudbeds/CloudbedsCallback'
import GuestyStart from './pms/guesty/GuestyStart'
import GuestySetup from './pms/guesty/GuestySetup'
import HostStart from './pms/host/HostStart'
import HostCallback from './pms/host/HostCallback'
import HostAuthorize from './pms/host/HostAuthorize'
import GuestlineStart from './pms/guestline/GuestlineStart'
import GuestlineCallback from './pms/guestline/GuestlineCallback'
import Beds24V2Start from './pms/beds24-v2/Beds24Start'
import Beds24Authorize from './pms/beds24-v2/Beds24Authorize'
import Beds24Callback from './pms/beds24-v2/Beds24Callback'
import GuestlineAuthorize from './pms/guestline/GuestlineAuthorize'
import OracleStart from './pms/oracle/OracleStart'
import OracleAuthorize from './pms/oracle/OracleAuthorize'
import OracleCallback from './pms/oracle/OracleCallback'
import BlueLagoonStart from './pms/bluelagoon/BluelagoonStart'
import BlueLagoonAuthorize from './pms/bluelagoon/BluelagoonAuthorize'
import BlueLagoonCallback from './pms/bluelagoon/BluelagoonCallback'
import ApaleoReconnect from './pms/apaleo/ApaleoReconnect'
import { ErrorPage } from 'app/error/404'

const OrganizationSignUp = ({ authorized, currentOrganization }) => {
    return (
        <Switch>
            <Route exact path={SIGN_UP.BEDS_24_START} component={Beds24Start} />
            <Route exact path={SIGN_UP.BEDS_24_CONFIG} component={Beds24Config} />

            <Route exact path={SIGN_UP.BEDS_24_V2_START} component={Beds24V2Start} />
            <Route exact path={SIGN_UP.BEDS_24_V2_AUTHORIZE} component={Beds24Authorize} />
            <Route exact path={SIGN_UP.BEDS_24_V2_CALLBACK} component={Beds24Callback} />

            <Route exact path={SIGN_UP.BOOKING_FACTORY_START} component={BookingFactoryStart} />
            <Route exact path={SIGN_UP.BOOKING_FACTORY_SETUP} component={BookingFactorySetup} />

            <Route exact path={SIGN_UP.MEWS_START} component={MewsStart} />
            <Route exact path={SIGN_UP.MEWS_SETUP} component={MewsSetup} />

            <Route exact path={SIGN_UP.APALEO_START} component={ApaleoStart} />
            <Route
                exact
                path={SIGN_UP.APALEO_RECONNECT}
                component={() =>
                    authorized && currentOrganization.pms === 'apaleo' ? <ApaleoReconnect /> : <Route component={ErrorPage} />
                }
            />
            <Route exact path={SIGN_UP.APALEO_AUTHORIZE} component={ApaleoAuthorize} />
            <Route exact path={SIGN_UP.APALEO_CALLBACK} component={() => <ApaleoCallback currentOrganization={currentOrganization} />} />

            <Route exact path={SIGN_UP.RENTLIO_START} component={RentlioStart} />
            <Route exact path={SIGN_UP.RENTLIO_AUTHORIZE} component={RentlioAuthorize} />
            <Route exact path={SIGN_UP.RENTLIO_CALLBACK} component={RentlioCallback} />

            <Route exact path={SIGN_UP.NO_PMS_START} component={NoPmsStart} />
            <Route exact path={SIGN_UP.NO_PMS_CALLBACK} component={NoPmsCallback} />

            <Route exact path={SIGN_UP.ORG} component={StartScreen} />
            <Route exact path={SIGN_UP.WELCOME} component={SignUpWelcome} />

            <Route exact path={SIGN_UP.CLOUDBEDS_START} component={CloudbedsStart} />
            <Route exact path={SIGN_UP.CLOUDBEDS_AUTHORIZE} component={CloudbedsAuthorize} />
            <Route exact path={SIGN_UP.CLOUDBEDS_CALLBACK} component={CloudbedsCallback} />

            <Route exact path={SIGN_UP.GUESTY_START} component={GuestyStart} />
            <Route exact path={SIGN_UP.GUESTY_AUTHORIZE} component={GuestyAuthorize} />
            <Route exact path={SIGN_UP.GUESTY_SETUP} component={GuestySetup} />

            <Route exact path={SIGN_UP.HOST_START} component={HostStart} />
            <Route exact path={SIGN_UP.HOST_AUTHORIZE} component={HostAuthorize} />
            <Route exact path={SIGN_UP.HOST_CALLBACK} component={HostCallback} />

            <Route exact path={SIGN_UP.GUESTLINE_START} component={GuestlineStart} />
            <Route exact path={SIGN_UP.GUESTLINE_AUTHORIZE} component={GuestlineAuthorize} />
            <Route exact path={SIGN_UP.GUESTLINE_CALLBACK} component={GuestlineCallback} />

            <Route exact path={SIGN_UP.ORACLE_START} component={OracleStart} />
            <Route exact path={SIGN_UP.ORACLE_AUTHORIZE} component={OracleAuthorize} />
            <Route exact path={SIGN_UP.ORACLE_CALLBACK} component={OracleCallback} />

            <Route exact path={SIGN_UP.BLUE_LAGOON_START} component={BlueLagoonStart} />
            <Route exact path={SIGN_UP.BLUE_LAGOON_AUTHORIZE} component={BlueLagoonAuthorize} />
            <Route exact path={SIGN_UP.BLUE_LAGOON_CALLBACK} component={BlueLagoonCallback} />
        </Switch>
    )
}

export default memo(OrganizationSignUp)
