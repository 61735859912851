import styled from 'styled-components'

export const TitleWrap = styled.div`
    display: flex;
    align-items: center;
    column-gap: 6.5px;

    > h1 {
        font-weight: 600;
        font-size: 2.5rem;
        color: #181c32;
    }

    > div > h3 {
        padding-top: 6px;
    }
`
