import React, { useEffect } from 'react'
import SearchInput from 'app/components/forms/Search'
import { getCleaningTask } from '../state/selectors/areaRelatedData'
import { search } from 'app/utils/formatters'
import { AreaStruct } from '@shared/firestore-structs'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { areasAtom, cleaningTasksAtom, searchValueAtom } from '../state/atoms'
import AsyncStorage from '@react-native-community/async-storage'
import { filteredAreasSelector } from '../state/selectors/areasGroups'
import { HOUSEKEEPING_SEARCH_VALUE_KEY } from '../types'

function Search() {
    const [searchValue, setSearchValue] = useRecoilState(searchValueAtom)

    const filteredAreas = useRecoilValue(filteredAreasSelector)
    const cleaningTasks = useRecoilValue(cleaningTasksAtom)

    const setAreas = useSetRecoilState(areasAtom)

    useEffect(() => {
        AsyncStorage.getItem(HOUSEKEEPING_SEARCH_VALUE_KEY, (err, result) => {
            if (result && !err && result.length > 0 && filteredAreas.length > 0) {
                setSearchValue(result)
                setAreas(search(result, filteredAreas, extract))
            }
        })
    }, [filteredAreas.length])

    useEffect(() => {
        if (searchValue.length === 0) setAreas(filteredAreas)
    }, [searchValue.length])

    useEffect(() => {
        if (searchValue.length !== 0) setAreas(search(searchValue, filteredAreas, extract))
    }, [filteredAreas.length, searchValue.length])

    function onSearchChange(value: string) {
        setSearchValue(value)
        AsyncStorage.setItem(HOUSEKEEPING_SEARCH_VALUE_KEY, value)

        setAreas(search(value, filteredAreas, extract))
    }

    function extract(area: AreaStruct) {
        const task = getCleaningTask(area.key, cleaningTasks)
        const address = area.address ? area.address : ''
        const taskName = task ? task.name : ''
        const assignees = task && task.assignedTo ? task.assignedTo.map(assignee => assignee.name).join(' ') : ''

        return area.name + area.group + address + taskName + assignees
    }

    return <SearchInput value={searchValue} onChange={onSearchChange} placeholder={'Units, assignees and more'} />
}

export default Search
