import React, { useContext } from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { checkIsActive } from '../../../../_helpers'
import { filterNavItems } from '../../../../../app/navigation/nav-items'
import { AuthContext } from '../../../../../app/modules/Auth/AuthContext'

export function HeaderMenu({ layoutProps }) {
    const location = useLocation()
    const { user } = useContext(AuthContext)
    const getMenuItemActive = url => {
        return checkIsActive(location, url) ? 'nav-item-active' : ''
    }

    const filteredNavItems = filterNavItems(user)

    return (
        <div
            id="kt_header_menu"
            className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
            {...layoutProps.headerMenuAttributes}>
            {/*begin::Header Nav*/}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {filteredNavItems.map(i => {
                    if (i.subMenuItems.length > 0) {
                        return (
                            <li
                                key={i.path}
                                data-menu-toggle={layoutProps.menuDesktopToggle}
                                aria-haspopup="true"
                                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(i.path)}`}>
                                <NavLink className="menu-link menu-toggle" to={i.path}>
                                    {i.icon && (
                                        <span className="symbol symbol-circle symbol-30 mr-3">
                                            <span className="symbol-label font-size-sm font-weight-bolder text-white">
                                                <i
                                                    className={`menu-icon specta-${i.icon} ${getMenuItemActive(i.path)} icon-${i.iconSize}`}
                                                    style={{ fontSize: i.fontSize }}
                                                />
                                            </span>
                                        </span>
                                    )}
                                    <span className={`menu-text font-size-base font-weight-bolder  ${getMenuItemActive(i.path)}`}>
                                        {i.name}
                                    </span>
                                </NavLink>
                                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                                    <ul className="menu-subnav">
                                        {i.subMenuItems.map(subItem => {
                                            return (
                                                <li
                                                    key={subItem.path}
                                                    className={`menu-item menu-item-submenu ${getMenuItemActive(subItem.path)}`}
                                                    data-menu-toggle="hover"
                                                    aria-haspopup="true">
                                                    <NavLink
                                                        className={`menu-link ${subItem.subMenuItems.length > 0 ? 'menu-toggle' : ''}`}
                                                        to={subItem.path}>
                                                        {subItem.icon && (
                                                            <span className="symbol symbol-circle symbol-30 mr-3">
                                                                <span className="symbol-label font-size-sm font-weight-bolder text-white">
                                                                    <i
                                                                        className={`menu-icon specta-${subItem.icon} ${getMenuItemActive(
                                                                            subItem.path
                                                                        )} icon-${subItem.iconSize}`}
                                                                        style={{ fontSize: i.fontSize }}
                                                                    />
                                                                </span>
                                                            </span>
                                                        )}
                                                        <span
                                                            className={`menu-text font-size-base font-weight-bolder ${getMenuItemActive(
                                                                subItem.path
                                                            )}`}>
                                                            {subItem.name}
                                                        </span>
                                                    </NavLink>

                                                    {subItem.subMenuItems.map(subSubItem => {
                                                        return (
                                                            <div
                                                                key={subSubItem.path}
                                                                className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                                                <ul className="menu-subnav">
                                                                    {/*begin::3 Level*/}
                                                                    <li className={`menu-item ${getMenuItemActive(subSubItem.path)}`}>
                                                                        <NavLink className="menu-link" to={subSubItem.path}>
                                                                            <i className="menu-bullet menu-bullet-dot">
                                                                                <span />
                                                                            </i>
                                                                            <span className="menu-text font-size-base font-weight-bolder ">
                                                                                {subSubItem.name}
                                                                            </span>
                                                                        </NavLink>
                                                                    </li>
                                                                    {/*end::3 Level*/}
                                                                </ul>
                                                            </div>
                                                        )
                                                    })}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </li>
                        )
                    } else {
                        return (
                            <li key={i.path} className={`menu-item menu-item-rel`}>
                                <NavLink className="menu-link" to={i.path}>
                                    {i.icon && (
                                        <span className="symbol symbol-circle symbol-30 mr-3">
                                            <span className="symbol-label font-size-sm font-weight-bolder text-white text-hover-specta-main">
                                                <i
                                                    className={`menu-icon specta-${i.icon} ${getMenuItemActive(i.path)} icon-${i.iconSize}`}
                                                    style={i.style}
                                                />
                                            </span>
                                        </span>
                                    )}

                                    <span className={`menu-text font-size-base font-weight-bolder ${getMenuItemActive(i.path)}`}>
                                        {i.name}
                                    </span>
                                </NavLink>
                            </li>
                        )
                    }
                })}
            </ul>
            {/*end::Header Nav*/}
        </div>
    )
}
