/* eslint-disable no-unused-vars */
import React from 'react'
import { PaginationTotalStandalone } from 'react-bootstrap-table2-paginator'
import { View, ActivityIndicator } from 'react-native-web'
import { getBrand } from '@shared/brand'

export function PaginationToolbar(props) {
    const { isLoading, paginationProps } = props
    const {
        sizePerPageList,
        sizePerPage,
        totalSize,
        page,
        hidePageSizeDropdown,
        onSizePerPageChange = [
            { text: '3', value: 3 },
            { text: '5', value: 5 },
            { text: '10', value: 10 }
        ]
    } = paginationProps
    const style = {
        width: '75px'
    }

    const onSizeChange = event => {
        const newSize = +event.target.value
        onSizePerPageChange(newSize)
    }

    const pagesCount = Math.ceil(totalSize / sizePerPage)

    let from = page === 1 ? 1 : (page - 1) * sizePerPage + 1
    let to = page === pagesCount ? totalSize : page * sizePerPage

    return (
        <div className="d-flex align-items-center py-3">
            {/* {isLoading && (
                <div className="d-flex align-items-center">
                    <div className="mr-2 text-muted">Loading...</div>
                    <div className="spinner spinner-primary mr-10" />
                </div>
            )} */}
            {!isLoading && (
                <>
                    {!hidePageSizeDropdown && (
                        <select
                            disabled={totalSize === 0}
                            className={`form-control form-control-sm font-weight-bold mr-4 border-0 bg-light ${
                                totalSize === 0 && 'disabled'
                            }`}
                            onChange={onSizeChange}
                            value={sizePerPage}
                            style={style}>
                            {sizePerPageList.map(option => {
                                const isSelect = sizePerPage === `${option.page}`
                                return (
                                    <option key={option.text} value={option.page} className={`btn ${isSelect ? 'active' : ''}`}>
                                        {option.text}
                                    </option>
                                )
                            })}
                        </select>
                    )}

                    <span className="text-muted">
                        Showing rows {from} to {to} of {totalSize}
                    </span>
                </>
            )}
            {/* <PaginationTotalStandalone className="text-muted" {...paginationProps} /> */}
        </div>
    )
}
