import React, { useContext, useEffect } from 'react'
import { TaskboardWrapper } from './style'
import { Card, CardBody } from '../../_metronic/_partials/controls'
import Header from './Header'
import SearchAndFilters from './SearchAndFilters'
import TasksBody from './TasksBody'
import Modal from 'react-modal'
import { UpgradeMessageContent } from '../components/upgrade-signals'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { respParamsAtom, subscriptionParamsAtom, taskboardContextAtom } from './state/atoms'
import { SubscriptionContext } from '../modules/Subscriptions/SubscriptionContext'
import { width, height } from '../utils/styles'
import { useLocation } from 'react-router-dom'
import { defineTaskboardContext } from '../traces/integration-utils'
import { upgradeMessageStyle } from 'app/components/styled-components/modal'
import { TaskboardContext } from '@shared/traces-types'

function Taskboard() {
    const { pathname, search } = useLocation()
    const { checkSubscription } = useContext(SubscriptionContext)

    const [subscriptionParams, setSubscriptionParams] = useRecoilState(subscriptionParamsAtom)

    const [taskboardContext, setTaskboardContext] = useRecoilState(taskboardContextAtom)
    const setRespParams = useSetRecoilState(respParamsAtom)

    useEffect(() => {
        setTaskboardContext(defineTaskboardContext(pathname, search))
    }, [])

    useEffect(() => {
        checkSubscription(
            'assign-tasks',
            () =>
                setSubscriptionParams({
                    hasSubscriptionAccess: true,
                    notAccessibleFeature: null,
                    availableUpgrades: null,
                    upgradeMessageIsOpen: false
                }),
            (notAccessibleFeature, availableUpgrades) =>
                setSubscriptionParams({
                    hasSubscriptionAccess: false,
                    notAccessibleFeature,
                    availableUpgrades,
                    upgradeMessageIsOpen: false
                })
        )
    }, [checkSubscription])

    useEffect(() => {
        setRespParams({ respWidth: width, respHeight: height })
    }, [width, height])

    function closeUpgradeMessage() {
        setSubscriptionParams({ ...subscriptionParams, upgradeMessageIsOpen: false })
    }

    return (
        <TaskboardWrapper>
            <Modal
                style={upgradeMessageStyle}
                isOpen={subscriptionParams.upgradeMessageIsOpen}
                contentLabel="UpgradeMessages"
                onRequestClose={closeUpgradeMessage}>
                <UpgradeMessageContent
                    onClose={closeUpgradeMessage}
                    notAccessibleFeature={subscriptionParams.notAccessibleFeature}
                    availableUpgrades={subscriptionParams.availableUpgrades!}
                    showCloseButton
                    height={'100%'}
                />
            </Modal>

            {/* FIXME define types */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Card className="card-no-shadow">
                <Header />
                {/* FIXME define types */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <CardBody>
                    {taskboardContext !== TaskboardContext.RESERVATIONS && <SearchAndFilters />}
                    <TasksBody />
                </CardBody>
            </Card>
        </TaskboardWrapper>
    )
}

export default Taskboard
