import objectPath from 'object-path'
import React, { useContext, useMemo, useEffect, useState } from 'react'
import ApexCharts from 'apexcharts'
import { useHtmlClassService } from '../../../../_metronic/layout'
import { KTUtil } from '../../../../_metronic/_assets/js/components/util'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import { AccountsContext } from '../../Accounts/AccountsContext'
import { db } from '../../../utils/firebase'
import moment from 'moment-timezone'

export default function DashboardCard({ className, symbolShape = 'circle', baseColor = 'success' }) {
    const [basicAccounts, setBasicAccounts] = useState([])
    const uiService = useHtmlClassService()
    const {
        setAccounts,
        setAllAccounts,

        setTotalCount,

        setListLoading
    } = useContext(AccountsContext)
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(uiService.config, 'js.colors.gray.gray500'),
            colorsGrayGray200: objectPath.get(uiService.config, 'js.colors.gray.gray200'),
            colorsGrayGray300: objectPath.get(uiService.config, 'js.colors.gray.gray300'),
            colorsThemeBaseSuccess: objectPath.get(uiService.config, `js.colors.theme.base.${baseColor}`),
            colorsThemeLightSuccess: objectPath.get(uiService.config, `js.colors.theme.light.${baseColor}`),
            fontFamily: objectPath.get(uiService.config, 'js.fontFamily')
        }
    }, [uiService, baseColor])

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index
    }

    useEffect(() => {
        const getAccounts = async () => {
            let accountsRef = await db
                .collection('organizations')
                .where('status', '==', 'active')
                // .orderBy(queryParams.sortField, queryParams.sortOrder)
                .get()

            let fetchedAccounts = accountsRef.docs.map(a => ({
                id: a.id,
                name: a.data().name,
                status: a.data().status,
                subscription: a.data().subscription,
                created: a.data().created || moment()
            }))
            setAllAccounts(fetchedAccounts)

            const basicAccounts = fetchedAccounts.filter(a => a.subscription === 'basic')
            const basicAccountMonths = basicAccounts.map(a => moment(a.created).month()).filter(onlyUnique)
            const basicAccountCount = basicAccountMonths.map(m => basicAccounts.filter(a => moment(a.created).month() === m).length)
            setBasicAccounts({ months: basicAccountMonths, count: basicAccountCount })

            setAccounts(fetchedAccounts)
            setListLoading(false)
            setTotalCount(fetchedAccounts.length)
        }

        getAccounts()
    }, [])

    useEffect(() => {
        const element = document.getElementById('kt_stats_widget_10_chart')
        if (!element || basicAccounts.length === 0) {
            return
        }

        const height = parseInt(KTUtil.css(element, 'height'))
        const options = getChartOption(layoutProps, height, basicAccounts)
        const chart = new ApexCharts(element, options)
        console.log(layoutProps)
        chart.render()
        return function cleanUp() {
            chart.destroy()
        }
    }, [layoutProps, basicAccounts])

    return (
        <Card className="card-no-shadow">
            <CardHeader title="Dashboard"></CardHeader>
            <CardBody>
                <div className="row">
                    <div className="col-lg-4">
                        <>
                            {/* begin::Stats Widget 10 */}
                            <div className={`card card-custom ${className}`}>
                                {/* begin::Body */}
                                <div className="card-body p-0">
                                    <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                                        <span className={`symbol ${symbolShape} symbol-50 symbol-light${baseColor} mr-2`}>
                                            <span className="symbol-label">
                                                <span className={`svg-icon svg-icon-xl svg-icon-${baseColor}`}>
                                                    {/* <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Cart3.svg')}></SVG> */}
                                                </span>
                                            </span>
                                        </span>
                                        <div className="d-flex flex-column text-right">
                                            <span className="text-dark-75 font-weight-bolder font-size-h3">+259</span>
                                            <span className="text-muted font-weight-bold mt-2">Sales Change</span>
                                        </div>
                                    </div>
                                    <div
                                        id="kt_stats_widget_10_chart"
                                        className="card-rounded-bottom"
                                        data-color={baseColor}
                                        style={{ height: '150px' }}
                                    />
                                </div>
                                {/* end::Body */}
                            </div>
                            {/* end::Stats Widget 10 */}
                        </>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

function getChartOption(layoutProps, height, data) {
    console.log(data)
    const options = {
        series: [
            {
                name: 'Net Profit',
                data: data.count
            }
        ],
        chart: {
            type: 'area',
            height: height,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {},
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            type: 'solid',
            opacity: 1
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [layoutProps.colorsThemeBaseSuccess]
        },
        xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Aug', 'Sep'],
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: false,
                style: {
                    colors: layoutProps.colorsGrayGray500,
                    fontSize: '12px',
                    fontFamily: layoutProps.fontFamily
                }
            },
            crosshairs: {
                show: false,
                position: 'front',
                stroke: {
                    color: layoutProps.colorsGrayGray300,
                    width: 1,
                    dashArray: 3
                }
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                    fontFamily: layoutProps.fontFamily
                }
            }
        },
        yaxis: {
            min: 0,
            max: 5,
            labels: {
                show: false,
                style: {
                    colors: layoutProps.colorsGrayGray500,
                    fontSize: '12px',
                    fontFamily: layoutProps.fontFamily
                }
            }
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0
                }
            }
        },
        tooltip: {
            style: {
                fontSize: '12px',
                fontFamily: layoutProps.fontFamily
            },
            y: {
                formatter: function (val) {
                    return '$' + val + ' thousands'
                }
            }
        },
        colors: [layoutProps.colorsThemeLightSuccess],
        markers: {
            colors: [layoutProps.colorsThemeLightSuccess],
            strokeColor: [layoutProps.colorsThemeBaseSuccess],
            strokeWidth: 3
        }
    }
    return options
}
