import React, { useContext, useEffect, useMemo } from 'react'
import { AuthContext } from '../../../Auth/AuthContext'
import LoadingView from '../../../../utils/loading-view'
import moment from 'moment-timezone'
import { sortByName, sortTimeStampAscending, sortTimeStampDescending } from '@shared/helpers'
import { ReportsContext } from '../../ReportsContext'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import CleaningSummary from './components/CleaningSummary'
import ReportTableHeaders from './components/ReportTableHeaders'
import { dateFilterType, reportsData, reportsTaskTypes, reportTableDateHeaders, reportTablePeriodHeaders } from './atoms.js'
import { reportTableData } from './selectors.js'
import UserRow from './components/UserRow'
import { filterByAreaGroupAccess } from '@shared/area-groups-helpers'
import { getHousekeepingTasksQuery, getTasks } from '@shared/task-data'
import firebase from '../../../../utils/firebase'
import { TASK_COMPLETE } from '@shared/txt-constants'

export function ReportsTable() {
    const { listLoading, setListLoading, queryParams } = useContext(ReportsContext)
    const { currentOrganization, currentUser } = useContext(AuthContext)
    const dateHeaders = useRecoilValue(reportTableDateHeaders)
    const periodHeaders = useRecoilValue(reportTablePeriodHeaders)
    const [reports, setReports] = useRecoilState(reportsData)
    const tableData = useRecoilValue(reportTableData)
    const dateFilter = useRecoilValue(dateFilterType)
    const setTaskTypes = useSetRecoilState(reportsTaskTypes)

    const { userRows, summaryByCleaningType } = tableData

    const reportsUIProps = useMemo(() => {
        return {
            listLoading: listLoading,
            setListLoading: setListLoading
        }
    }, [listLoading, setListLoading])

    const getData = async () => {
        setReports([])
        reportsUIProps.setListLoading(true)

        let tasks = await getTasks(
            getHousekeepingTasksQuery(
                firebase,
                currentOrganization.key,
                [
                    moment(queryParams.filter.startDate).valueOf(),
                    moment(queryParams.filter.endDate || queryParams.filter.startDate).valueOf()
                ],
                [TASK_COMPLETE]
            )
        )

        tasks = tasks.filter(task => {
            const filteredArea = filterByAreaGroupAccess(currentUser, [task.area])
            return filteredArea.length > 0
        })

        tasks = tasks.filter(t => t.cleaning && t.cleaning.end)

        const groupBy = (items, key) =>
            items.reduce(
                (result, item) => ({
                    ...result,
                    [item[key]]: [...(result[item[key]] || []), item]
                }),
                {}
            )

        let taskUsers = tasks
            .reduce((acc, val) => acc.concat(val.assignedTo), [])
            .filter((usr, index, self) => {
                return self.findIndex(u => u.key === usr.key) === index
            })

        tasks.forEach(t => {
            taskUsers
                .filter(tu => t.assignedToKeys.includes(tu.key))
                .map(usr => {
                    if (usr.tasks) {
                        usr.tasks.push(t)
                    } else {
                        usr.tasks = [t]
                    }
                })
        })

        taskUsers.forEach(tu => {
            const taskTimes = []
            let calculatedTasks = []

            tu.tasks
                .sort((a, b) => sortTimeStampAscending(a.cleaning.start, b.cleaning.start))
                .forEach(t => {
                    if (!calculatedTasks.includes(t.key)) {
                        let sameTimeTasks = tu.tasks.filter(
                            x =>
                                x.key !== t.key &&
                                moment(x.cleaning.start).startOf('day').valueOf() === moment(t.cleaning.start).startOf('day').valueOf() &&
                                ((x.cleaning.start >= t.cleaning.start && x.cleaning.end <= t.cleaning.end) ||
                                    (t.cleaning.start >= x.cleaning.start && t.cleaning.end <= x.cleaning.end))
                        )
                        if (sameTimeTasks.length >= 1) {
                            sameTimeTasks.unshift(t)
                            const start = sameTimeTasks.sort((a, b) => sortTimeStampAscending(a.cleaning.start, b.cleaning.start))[0]
                                .cleaning.start
                            const end = sameTimeTasks.sort((a, b) => sortTimeStampDescending(a.cleaning.end, b.cleaning.end))[0].cleaning
                                .end
                            const pauses = sameTimeTasks
                                .map(x => {
                                    if (x.cleaning.pause && x.cleaning.pause.length > 1) {
                                        return x.cleaning.pause
                                            .map((c, i) => {
                                                if (i !== x.cleaning.pause.length - 1) {
                                                    return x.cleaning.play[i + 1] - c
                                                }
                                                return 0
                                            })
                                            .reduce((c, tot) => {
                                                return c + tot
                                            }, 0)
                                    }
                                    return 0
                                })
                                .reduce((tot, x) => {
                                    return x + tot
                                })
                            taskTimes.push(end - start - pauses)
                            t.cleaningTime = end - start - pauses
                            t.pauses = pauses
                            calculatedTasks = calculatedTasks.concat(sameTimeTasks.map(x => x.key))
                        } else {
                            let pauses = 0
                            if (t.cleaning.pause && t.cleaning.pause.length > 1) {
                                pauses = t.cleaning.pause
                                    .map((x, i) => {
                                        if (i !== t.cleaning.pause.length - 1) {
                                            return t.cleaning.play[i + 1] - x
                                        }
                                        return 0
                                    })
                                    .reduce((tot, x) => {
                                        return x + tot
                                    }, 0)
                            }
                            taskTimes.push(t.cleaning.end - t.cleaning.start - pauses)
                            t.cleaningTime = t.cleaning.end - t.cleaning.start - pauses
                            t.pauses = pauses

                            calculatedTasks.push(t.key)
                        }
                    } else {
                        let pauses = 0
                        if (t.cleaning.pause && t.cleaning.pause.length > 1) {
                            pauses = t.cleaning.pause
                                .splice(-1, 1)
                                .map((x, i) => {
                                    return t.cleaning.play[i + 1] - x
                                })
                                .reduce((tot, x) => {
                                    return x + tot
                                }, 0)
                        }

                        t.cleaningTime = t.cleaning.end - t.cleaning.start - pauses
                        t.pauses = pauses
                    }
                })

            const taskTypes = groupBy(tu.tasks, 'name')
            tu.taskTypes = []

            for (const [key, value] of Object.entries(taskTypes)) {
                tu.taskTypes.push({
                    type: key,
                    data: value,
                    totalTime: value.reduce((tot, arr) => {
                        return tot + (arr.cleaningTime - arr.pauses)
                    }, 0)
                })
            }

            tu.collapsed = true
            tu.totalCleaningTime = 0
            tu.totalIdleTime = 0
            taskTimes.forEach(t => {
                tu.totalCleaningTime += t
            })
            tu.firstCleaningTime =
                tu.tasks.sort((a, b) => sortTimeStampAscending(a.cleaning.start, b.cleaning.start))[0].cleaning.start || 'n/a'
            tu.lastCleaningTime = tu.tasks.sort((a, b) => sortTimeStampDescending(a.cleaning.end, b.cleaning.end))[0].cleaning.end || 'n/a'
            tu.totalIdleTime = tu.lastCleaningTime - tu.firstCleaningTime - tu.totalCleaningTime
            if (tu.totalIdleTime < 0) {
                tu.totalIdleTime = 0
            }
            tu.totalTime = tu.totalCleaningTime + tu.totalIdleTime
            tu.pauses = tu.tasks
                .map(x => x.pauses)
                .reduce((tot, x) => {
                    return tot + x
                }, 0)
        })

        taskUsers = taskUsers.sort((a, b) => sortByName(a.name, b.name))

        const mergedTasks = [].concat.apply(
            [],
            taskUsers.map(x => {
                return x.tasks
            })
        )

        const taskTypesDict = groupBy(mergedTasks, 'name')
        let tt = []

        for (const [key, value] of Object.entries(taskTypesDict)) {
            tt.push({
                type: key,
                data: value,
                totalTime: value.reduce((tot, arr) => {
                    return tot + (arr.cleaningTime - arr.pauses)
                }, 0)
            })
        }

        setTaskTypes(tt)

        setReports(taskUsers)
        reportsUIProps.setListLoading(false)
    }

    async function handleGetData() {
        await getData()
    }

    useEffect(() => {
        handleGetData()
    }, [queryParams.filter.startDate, queryParams.filter.endDate])

    const headersCondition = dateFilter === 'date' ? dateHeaders : periodHeaders

    const userRowsCondition = !reportsUIProps.listLoading && (
        <tbody>
            {userRows.map((userRow, index) => {
                const initials = reports[index].initials

                return <UserRow key={userRow.user + index} userRow={userRow} initials={initials} />
            })}
        </tbody>
    )

    const cleaningSummaryCondition = !reportsUIProps.listLoading && summaryByCleaningType && (
        <CleaningSummary summaryByCleaningType={summaryByCleaningType} />
    )

    return (
        <>
            <div className="table-responsive">
                <table className="table table-hover table-head-custom table-vertical-center">
                    <ReportTableHeaders headers={headersCondition} />

                    {userRowsCondition}
                </table>
            </div>
            {cleaningSummaryCondition}
            {!reportsUIProps.listLoading && reports.length === 0 && <h3 className="mt-20 text-center">No records found</h3>}
            {reportsUIProps.listLoading && (
                <div className="mt-20">
                    <LoadingView />
                </div>
            )}
        </>
    )
}
