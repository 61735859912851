import React from 'react'
import { View } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'

export const TOOLTIP_DELAY = 300

export function getDefaultTooltip(textArr, color = iOSColors.black, opacity = 0.7, fontSize = 13) {
    const paddingTop = 10
    const itemMargin = 4
    const paddingBottom = paddingTop - itemMargin

    fontSize

    return (
        <View
            style={{
                opacity: opacity,
                backgroundColor: color,
                paddingTop: paddingTop,
                paddingBottom: paddingBottom,
                paddingHorizontal: 10,
                borderRadius: 10
            }}>
            {textArr.map(text => {
                return (
                    <span
                        key={Math.random()}
                        style={{ fontSize: fontSize, fontWeight: '600', color: colors.white, marginBottom: itemMargin }}>
                        {text}
                    </span>
                )
            })}
        </View>
    )
}
