import React, { useMemo, useState } from 'react'
import { IconButton } from 'app/components/buttons/IconButton'
import ContextMenu from 'app/components/ContextMenu'
import * as c from '@shared/txt-constants'
import { CleaningStatusFilterOption } from 'app/modules/HousekeepingDashboard/types'
import CleaningOption from './CleaningOption'
import { FilterButtonWrap, ResetButton } from '../style'
import { isEqual } from 'lodash'
import { useSetRecoilState } from 'recoil'
import { cleaningStatusFilterValuesAtom } from 'app/modules/HousekeepingDashboard/state/atoms'

const defaultCleaningStatusFilter: CleaningStatusFilterOption[] = [
    {
        label: 'All',
        value: c.CLEANING_STATUS_ALL,
        isSelected: true
    },
    {
        label: 'Waiting for checkout',
        value: c.CLEANING_STATUS_WAITING_FOR_CHECKOUT,
        isSelected: true
    },
    { label: 'Unclean', value: c.CLEANING_STATUS_DIRTY, isSelected: true },
    { label: 'Preparing', value: c.CLEANING_STATUS_PREPARING, isSelected: true },
    { label: 'Preparing pause', value: c.CLEANING_STATUS_PREPARING_PAUSE, isSelected: true },
    { label: 'Clean', value: c.CLEANING_STATUS_CLEAN, isSelected: true },
    { label: 'Inspection', value: c.CLEANING_STATUS_INSPECTION, isSelected: true },
    { label: 'Do not disturb', value: c.CLEANING_STATUS_DO_NOT_DISTURB, isSelected: true },
    { label: 'Out of service', value: c.CLEANING_STATUS_OUT_OF_SERVICE, isSelected: true },
    { label: 'No service', value: c.CLEANING_STATUS_NO_SERVICE, isSelected: true }
]

function CleaningStatusFilter() {
    const [showDropdown, setShowDropdown] = useState(false)
    const [options, setOptions] = useState(defaultCleaningStatusFilter)

    const setCleaningStatusFilterValues = useSetRecoilState(cleaningStatusFilterValuesAtom)

    const isDefault = useMemo(() => isEqual(options, defaultCleaningStatusFilter), [options])

    function onOptionClick(value: string) {
        const newOptions = options.map((option, _, arr) => {
            if (option.value === value)
                return {
                    ...option,
                    isSelected: !option.isSelected
                }
            return { ...option, isSelected: value === c.CLEANING_STATUS_ALL ? !arr[0].isSelected : option.isSelected }
        })

        const allOption = newOptions[0]
        allOption.isSelected = newOptions.slice(1).every(option => option.isSelected)

        setOptions(newOptions)
        setCleaningStatusFilterValues(
            newOptions.filter(option => !option.isSelected && option.value !== c.CLEANING_STATUS_ALL).map(option => option.value)
        )
    }

    function onResetClick() {
        setOptions(defaultCleaningStatusFilter)
        setCleaningStatusFilterValues([])
    }

    return (
        <FilterButtonWrap>
            <IconButton
                onClick={() => setShowDropdown(!showDropdown)}
                className={`btn btn-circle btn-icon ${isDefault ? 'btn-specta-gray' : 'btn-danger'}`}
                icon="housekeeping-filter icon-lg"
                tooltip={{
                    text: 'Housekeeping status filter',
                    position: 'top'
                }}
            />

            {showDropdown && (
                <ContextMenu onBlur={() => setShowDropdown(false)}>
                    {options.map((option, index) => (
                        <CleaningOption onClick={onOptionClick} key={index + option.value} option={option} />
                    ))}
                    <ResetButton onClick={onResetClick}>Reset</ResetButton>
                </ContextMenu>
            )}
        </FilterButtonWrap>
    )
}

export default CleaningStatusFilter
