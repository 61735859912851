import React from 'react'
import * as c from '@shared/constants'
import { IssueStruct } from '@shared/dataObjects'
import { CheckboxContainer, CheckboxWrapper } from '../../style'
import CheckBox from 'app/components/check-box'
import { HoverableImageButton } from 'app/components/buttons/hoverable-image-button'

interface LeftBlockProps {
    issue: IssueStruct
    checked: boolean
    addCheckedIssue: (issue: IssueStruct) => void
    removeCheckedIssue: (issue: IssueStruct) => void
    onPressComplete: () => void
}

const IMG_HEIGHT = 100

export default function LeftBlock({ issue, checked, addCheckedIssue, removeCheckedIssue, onPressComplete }: LeftBlockProps) {
    const { status } = issue

    const complete_img_src =
        status === c.ISSUE_COMPLETE ? require('@shared/img/status-complete-inverse.svg') : require('@shared/img/status-open.svg')
    const tooltipText = status === c.ISSUE_COMPLETE ? 'Uncomplete issue' : 'Complete issue'

    return (
        <CheckboxContainer height={IMG_HEIGHT}>
            <HoverableImageButton
                dimension={24}
                hoverOpacity={0.2}
                imageSource={complete_img_src}
                hoverImageSource={require('@shared/img/status-complete-inverse.svg')}
                onPress={onPressComplete}
                tooltipDistance={5}
                tooltipOffset={0}
                tooltipText={tooltipText}
            />
            <CheckboxWrapper>
                <CheckBox
                    useExtraTouchable
                    isChecked={checked}
                    onValueChange={(checked: boolean) => {
                        if (checked) {
                            addCheckedIssue(issue)
                        } else {
                            removeCheckedIssue(issue)
                        }
                    }}
                />
            </CheckboxWrapper>
        </CheckboxContainer>
    )
}
