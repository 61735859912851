import React from 'react'
import { Wrap } from './style'
import { OccupancyFilterValue } from 'app/modules/HousekeepingDashboard/types'
import { pickOccupancyButtonImage } from '@shared/cleaning-helpers'
import { occupancyToHuman } from '@shared/get-booking-display'

interface Props {
    occupancyOption: OccupancyFilterValue
    onClick: (value: OccupancyFilterValue) => void
}

function OccupancyOption({ occupancyOption, onClick }: Props) {
    return (
        <Wrap onClick={() => onClick(occupancyOption)}>
            <img src={(pickOccupancyButtonImage(occupancyOption) as unknown) as string} />
            <span>{occupancyToHuman(occupancyOption)}</span>
        </Wrap>
    )
}

export default OccupancyOption
