import React from 'react'
import { PhoneInputWrap, countrySelectorStyles } from './style'
import { InputItem, Error, LabelWrap } from '../Input/style'
import { PhoneCountrySelector } from '../../country-selector'
import errorIcon from '@shared/img/error/error-face.svg'
import checkmarkIcon from '@shared/img/checkmark-blue.svg'

const PhoneInput = ({
    label,
    selectedCountry,
    onCountryChange,
    placeholder,
    value,
    form: { setFieldTouched, errors, touched },
    field: { name, onChange }
}) => {
    const errorMessage = errors[name]
    const isTouched = touched[name]
    const isError = errorMessage && isTouched

    const onBlur = () => {
        setFieldTouched(name)
    }

    return (
        <PhoneInputWrap>
            <LabelWrap>
                {label && <label>{label}</label>}
                {isError && <Error>{errorMessage}</Error>}
            </LabelWrap>

            <div className="input-wrap">
                <PhoneCountrySelector
                    selectedCountry={selectedCountry}
                    onCountryChange={onCountryChange}
                    customStyles={countrySelectorStyles({ isError })}
                />
                <InputItem isError={isError} phone>
                    <input name={name} value={value} type="text" placeholder={placeholder} onChange={onChange} onBlur={onBlur} />
                    {isTouched && <img src={isError ? errorIcon : checkmarkIcon} />}
                </InputItem>
            </div>
        </PhoneInputWrap>
    )
}

export default PhoneInput
