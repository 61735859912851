import React, { useEffect, useState, useMemo, useContext } from 'react'
import { View, Picker, Text } from 'react-native-web'
import * as crossfilter from 'crossfilter2'
import moment from 'moment-timezone'
import { BarChart, XAxis, YAxis, Tooltip, CartesianGrid, Line, Bar, Legend, ResponsiveContainer, Label, LabelList } from 'recharts'
import { iOSUIKit, iOSColors, webWeights } from '@shared/react-native-typography'

import { prepareDimensions, prepareResults } from './data'
import firebase from '../../utils/firebase'
import * as colors from '@shared/colors'
import { onFilteredIssues } from '@shared/issue-data'
import * as responsive from '../../utils/responsive'
import { SubscriptionContext } from '../../modules/Subscriptions/SubscriptionContext'

// eslint-disable-next-line import/prefer-default-export
export const Reports = ({ respWidth, respHeight, currentUser, style }) => {
    const [creatorsDimension, setCreatorsDimension] = useState([])
    const [areasDimension, setAreasDimension] = useState([])
    const [dateDimension, setDateDimension] = useState([])
    const [flipper, setFlipper] = useState(true)
    const [period, setPeriod] = useState(3)
    const [pickerFocus, setPickerFocus] = useState(false)
    const { hasAccess, showUpgradeModal, setShowUpgradeModal } = useContext(SubscriptionContext)
    const DEBUG_BORDER = 0
    const cutoffMoment = moment().subtract(period, 'days').valueOf()
    const cf = useMemo(() => {
        const result = crossfilter.default([])
        return result
    }, [])
    useEffect(() => {
        const result = cf
        const { creators, areas, dates } = prepareDimensions(result)
        // setCreatorsDimension(creators)
        // setAreasDimension(areas)
        setDateDimension(dates)
        return () => {
            // creators.dispose()
            // areas.dispose()
            dates.dispose()
            setDateDimension({})
            // setAreasDimension({})
            // setCreatorsDimension({})
        }
    }, [])
    useEffect(() => {
        let unsubscribe = onFilteredIssues(firebase, currentUser.organizationKey).onSnapshot(onIssuesUpdate)
        return () => unsubscribe()
    }, [currentUser.organizationKey, period])
    const onIssuesUpdate = snap => {
        const sn = snap.map(d => ({ ...d, cutoffMoment }))
        cf.remove()
        cf.add(sn)
        setFlipper(!flipper)
    }
    const filterByCreator = data => {
        creatorsDimension.filterAll()
        areasDimension.filterAll()
        dateDimension.filterAll()
        creatorsDimension.filter(data.key)
        setFlipper(!flipper)
    }
    const filterByArea = data => {
        creatorsDimension.filterAll()
        areasDimension.filterAll()
        dateDimension.filterAll()
        areasDimension.filter(data.key)
        setFlipper(!flipper)
    }

    const filterByDate = data => {
        creatorsDimension.filterAll()
        areasDimension.filterAll()
        dateDimension.filterAll()
        dateDimension.filter(data.key)
        setFlipper(!flipper)
    }

    const { dateDim: dataDim, creatorsDim, areasDim } = prepareResults(areasDimension, creatorsDimension, dateDimension, cutoffMoment)

    const texts = {
        'acc.open': 'Open',
        'value.open': 'Created',
        'value.completed': 'Completed',
        'value.deleted': 'Deleted'
    }

    const textSorter = text => {
        let sort
        switch (text) {
            case 'value.open':
                sort = 1
                break
            case 'acc.open':
                sort = 2
                break
            case 'value.completed':
                sort = 3
                break
            case 'value.deleted':
                sort = 4
                break
            default:
                sort = 5
        }
        return sort
    }

    const labelFormatter = (value, name, props) => {
        return [value, texts[name]]
    }

    const legendFormatter = (v, e, i) => {
        return (
            <span
                style={{
                    fontSize: 16,
                    color: colors.textPrimary,
                    marginLeft: 10
                }}>
                {texts[v]}
            </span>
        )
    }

    return (
        <View
            style={{
                marginTop: style.marginTop,
                paddingBottom: 80,
                borderWidth: DEBUG_BORDER,
                borderColor: 'yellow',
                justifyContent: 'flex-start',
                backgroundColor: 'white'
            }}>
            <View
                style={{
                    paddingLeft: responsive.SIDE_MARGIN,
                    marginRight: responsive.SIDE_MARGIN,
                    width: responsive.getWidthForMainView(respWidth),
                    alignSelf: 'center',
                    alignItems: 'flex-start',
                    borderWidth: DEBUG_BORDER,
                    borderColor: 'green',
                    justifyContent: 'flex-start'
                }}>
                <View
                    style={{
                        marginTop: 35,
                        width: '100%',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'red'
                    }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderWidth: DEBUG_BORDER,
                            borderColor: 'yellow'
                        }}>
                        <View
                            style={{
                                borderWidth: DEBUG_BORDER,
                                borderColor: 'blue'
                            }}>
                            <span
                                style={{
                                    fontSize: 42,
                                    color: colors.textPrimary
                                }}
                                className="font-weight-bold">
                                Issues report
                            </span>
                        </View>
                    </View>
                    <Picker
                        onFocus={() => setPickerFocus(true)}
                        onBlur={() => setPickerFocus(false)}
                        selectedValue={period}
                        style={{
                            height: 36,
                            borderColor: pickerFocus ? colors.green_spectaflow : colors.textInputBorder,
                            backgroundColor: 'white',
                            color: colors.textPrimary
                        }}
                        onValueChange={value => {
                            console.log(hasAccess)
                            if (!hasAccess) {
                                setShowUpgradeModal(true)
                            } else {
                                setPeriod(value)
                            }
                        }}>
                        <Picker.Item label="Last 3 days" value={3} />
                        <Picker.Item label="Last 7 days" value={7} />
                        <Picker.Item label="Last 30 days" value={30} />
                        <Picker.Item label="Last 90 days" value={90} />
                    </Picker>
                </View>

                <View style={{ borderColor: 'green', borderWidth: 0, height: 500, width: '100%' }}>
                    <ResponsiveContainer>
                        <BarChart data={dataDim} margin={{ top: 30 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <Legend verticalAlign="top" height={36} formatter={legendFormatter} />
                            <Tooltip
                                labelFormatter={value => moment(value).format('D.M.YY')}
                                itemSorter={v => textSorter(v.name)}
                                formatter={labelFormatter}
                            />
                            <XAxis tickFormatter={value => moment(value).format('D.M.YY')} dataKey="key" />
                            <YAxis />
                            <Bar
                                stackId="1"
                                isAnimationActive={false}
                                type="monotone"
                                dataKey="value.deleted"
                                fill={colors.red_spectaflow}
                            />
                            <Bar stackId="1" isAnimationActive={false} type="monotone" dataKey="value.completed" fill={iOSColors.green} />
                            <Bar
                                isAnimationActive={false}
                                type="monotone"
                                dataKey="acc.open"
                                fill={colors.light_blue_spectaflow}
                                stackId="1"
                            />
                            <Bar isAnimationActive={false} type="monotone" dataKey="value.open" fill={colors.blue_spectaflow} stackId="1">
                                <LabelList position="top" valueAccessor={entry => entry.value[1]} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </View>
            </View>
        </View>
    )
}
