import React, { useMemo, useEffect, useRef } from 'react'
import { AsideWrap } from './style'
import { Link } from 'react-router-dom'
import objectPath from 'object-path'
import { Brand } from '../brand/Brand'
import { AsideMenu } from './aside-menu/AsideMenu'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import * as brand from '@shared/brand'
import User from '../../../../app/components/layouts/User'
import LogoutButton from './LogoutButton'
import { useRecoilState } from 'recoil'
import { asideMenuIsOpen } from './state'
import SubscriptionIndicator from './aside-menu/SubscriptionIndicator'

export function Aside() {
    const uiService = useHtmlClassService()
    const [asideIsOpen, setAsideIsOpen] = useRecoilState(asideMenuIsOpen)
    const asideToggleObserverRef = useRef(null)
    const toggleButtonRef = useRef(null)
    const brandRef = useRef(null)
    const brandLogoRef = useRef(null)

    useEffect(() => {
        toggleButtonRef.current = document.getElementById('kt_aside_toggle')
        brandRef.current = document.getElementById('kt_brand')
        brandLogoRef.current = document.querySelector('.brand-logo')
    }, [])

    const layoutProps = useMemo(() => {
        return {
            disableScroll: objectPath.get(uiService.config, 'aside.menu.dropdown') === 'true' || false,
            asideClassesFromConfig: uiService.getClasses('aside', true),
            disableAsideSelfDisplay: objectPath.get(uiService.config, 'aside.self.display') === false,
            asideToggle: objectPath.get(uiService.config, 'aside.self.minimize.toggle')
        }
    }, [uiService])

    const getHeaderLogo = () => {
        let logo = brand.getNavBarAuthLogo()
        return logo
    }

    const observeAsideToggle = () => {
        const asideToggleCallback = mutations => {
            mutations.forEach(mutation => {
                if (mutation.attributeName === 'class') {
                    const attributeValue = mutation.target.getAttribute('class')

                    if (attributeValue.includes('aside-minimize')) {
                        setAsideIsOpen(false)
                    } else {
                        setAsideIsOpen(true)
                    }
                }
            })
        }

        const asideToggleObserver = new MutationObserver(asideToggleCallback)
        asideToggleObserverRef.current = asideToggleObserver

        asideToggleObserver.observe(document.body, {
            attributes: true
        })
    }

    useEffect(() => {
        observeAsideToggle()

        return () => {
            asideToggleObserverRef.current.disconnect()
        }
    }, [])

    return (
        <>
            <AsideWrap id="aside-wrapper" asideIsOpen={asideIsOpen}>
                {/* begin::Aside */}
                <div
                    id="kt_aside"
                    className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto justify-content-between`}>
                    <div>
                        <Brand asideIsOpen={asideIsOpen} />
                        <User asideIsOpen={asideIsOpen} />
                    </div>
                    {/* begin::Aside Menu */}
                    <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid">
                        {layoutProps.disableAsideSelfDisplay && (
                            <>
                             {/* begin::Header Logo */}
                                <div className="header-logo">
                                    <Link to="">
                                        <img alt="logo" src={getHeaderLogo().img} style={getHeaderLogo().style} />
                                    </Link>
                                </div>
                                 {/* end::Header Logo */}
                            </>
                        )}
                        <AsideMenu disableScroll={layoutProps.disableScroll} />
                    </div>

                    {/* end::Aside Menu */}
                    <div>
                        <SubscriptionIndicator asideIsOpen={asideIsOpen} />
                        <LogoutButton asideIsOpen={asideIsOpen} />
                    </div>
                </div>
                {/* end::Aside */}
            </AsideWrap>
        </>
    )
}
