// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useContext, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses
} from '../../../../../_metronic/_helpers'
import * as uiHelpers from '../../AccountsUIHelpers'
import * as columnFormatters from './column-formatters'
import { Pagination } from '../../../../../_metronic/_partials/controls'
import { AccountsContext } from '../../AccountsContext'
import { AuthContext } from '../../../Auth/AuthContext'
import { db } from '../../../../utils/firebase'
import moment from 'moment-timezone'
import { capitalize } from 'lodash'
import LoadingView from '../../../../utils/loading-view'
import { search, pageIsNotExist } from '@shared/helpers'
import AsyncStorage from '@react-native-community/async-storage'
import { beds24columns, beds24fields } from '../../pms/Beds24Table'
import Prompt from '../../../../components/alerts/Prompt'
import * as API from '../../../../utils/api'
import { toast } from 'react-toastify'
import { toastErrorStyle, toastSuccessStyle } from '../../../../utils/styles'
import { getCurrentUserOrganizations } from '../../../OrganizationSignUp/cloud-functions'

export function AccountsTable(props) {
    const {
        accounts,
        setAccounts,
        allAccounts,
        setAllAccounts,
        totalCount,
        setTotalCount,
        listLoading,
        setListLoading,
        ids,
        setIds,
        queryParams,
        setQueryParams,
        openEditAccountDialog,
        openDeleteAccountDialog,
        filterLoading
    } = useContext(AccountsContext)

    const { user, setLoadingAuthState } = useContext(AuthContext)
    const [accountDeletePromptIsOpen, setAccountDeletePromptIsOpen] = useState(false)
    const [accountDeleteData, setAccountDeleteData] = useState(null)
    const [accountDeletePromptValue, setAccountDeletePromptValue] = useState('')
    const [accountDeletePromptLoading, setAccountDeletePromptLoading] = useState(false)
    const [initialDataFetched, setInitialDataFetched] = useState(false)

    const [allowedAccounts, setAllowedAccounts] = useState([])

    const fields = props.fields

    const accountsUIProps = useMemo(() => {
        return {
            ids: ids,
            setIds: setIds,
            queryParams: queryParams,
            setQueryParams: setQueryParams,
            openEditAccountDialog: openEditAccountDialog,
            openDeleteAccountDialog: openDeleteAccountDialog
        }
    }, [ids, setIds, queryParams, setQueryParams, openEditAccountDialog, openDeleteAccountDialog])

    useEffect(() => {
        AsyncStorage.setItem('accounts_table_filter', JSON.stringify(queryParams))
    }, [queryParams])

    useEffect(() => {
        const getSubscriptions = async () => {
            const subscriptionsRef = await db.collectionGroup('subscriptions').where('status', 'in', ['active', 'trial']).get()
            return subscriptionsRef.docs.map(x => {
                return { organizationKey: x.ref.parent.parent.id, ...x.data() }
            })
        }

        const getActivities = async id => {
            const activitiesRef = await db
                .collectionGroup('activities')
                .where('organizationKey', '==', id)
                .where('type', 'in', ['cleaning-action-start', 'cleaning-action-stop', 'cleaning-status'])
                .orderBy('created', 'desc')
                .limit(1)
                .get()
                .catch(() => {
                    return { docs: [] }
                })
            let activities = activitiesRef.docs.map(a => a.data())
            return activities
        }

        const getAccounts = async () => {
            setListLoading(true)

            let accountsRef = db.collection('organizations')

            if (user.brand === 'Godo Pronto') {
                accountsRef = accountsRef.where('pms', 'in', ['beds24', 'godo', 'godo property']).where('brand', '==', 'godopronto')
            }

            let accountsSnap = await accountsRef.get()
            const getUserOrganizations = getCurrentUserOrganizations()
            const { data: userOrganizations } = await getUserOrganizations()

            const subscriptions = await getSubscriptions()

            let fetchedAccounts = accountsSnap.docs.map(a => {
                const orgSubs = subscriptions.filter(x => x.organizationKey === a.id).sort((a, b) => b.created - a.created)

                const orgData = a.data()

                let hasSubscription = false
                let trialDaysLeft

                if (orgSubs.length > 0) {
                    hasSubscription = true
                    if (orgSubs[0].status === 'trial') {
                        trialDaysLeft = moment(orgSubs[0].trialEnd).diff(moment(), 'days')
                    }
                }

                const data = {
                    id: a.id,
                    name: orgData.name,
                    status: orgData.status,
                    issueHashtags: orgData.issueHashtags,
                    subscription: orgData.subscription,
                    syncedAreas: orgData.billingUnitsOverride ?? orgData.areasSyncedTotal ?? 0,
                    unsyncedAreas: orgData.areasNotSyncedTotal || 0,
                    created: orgData.created,
                    lastActivity: '',
                    subscriptionStartDate: hasSubscription ? orgSubs[0].created : '',
                    pms: orgData.pms
                }

                if (trialDaysLeft) {
                    data.trialDaysLeft = trialDaysLeft
                }

                return data
            })
            let myAccounts = fetchedAccounts.filter(x => userOrganizations.filter(y => y.organizationKey === x.id).length > 0)
            setAllowedAccounts(myAccounts.map(x => x.id))
            setAllAccounts(fetchedAccounts)

            setInitialDataFetched(true)
            setListLoading(false)
        }

        getAccounts()
    }, [])

    useEffect(() => {
        const sortAccounts = accounts => {
            const sortProperty = queryParams.sortField
            let sortedAccounts = accounts.sort((a, b) => (a[sortProperty] > b[sortProperty] ? 1 : -1))

            if (queryParams.sortOrder === 'desc') {
                sortedAccounts = sortedAccounts.reverse()
            }

            sortedAccounts = sortedAccounts.slice(
                (queryParams.pageNumber - 1) * queryParams.pageSize,
                queryParams.pageNumber * queryParams.pageSize
            )

            return sortedAccounts
        }

        let filteredAccounts = allAccounts.filter(a => {
            return (
                (queryParams.filter.subscription === '' ||
                    queryParams.filter.subscription === 'All' ||
                    a.subscription === queryParams.filter.subscription) &&
                (queryParams.filter.status === '' || queryParams.filter.status === 'All' || a.status === queryParams.filter.status) &&
                (queryParams.filter.pms === '' || queryParams.filter.pms === 'All' || a.pms === queryParams.filter.pms)
            )
        })

        filteredAccounts = search(queryParams.filter.name, filteredAccounts, account => {
            return account.name
        })

        const sortedAccounts = sortAccounts(filteredAccounts)
        const totalCount = filteredAccounts.length
        setTotalCount(totalCount)
        const { setQueryParams } = accountsUIProps
        const { pageNumber, pageSize } = accountsUIProps.queryParams

        if (pageIsNotExist({ totalCount, pageNumber, pageSize }) && initialDataFetched) {
            setQueryParams({ ...accountsUIProps.queryParams, pageNumber: 1 })
        }

        setAccounts(sortedAccounts)
        return () => {}
    }, [
        queryParams.pageNumber,
        queryParams.pageSize,
        queryParams.sortField,
        queryParams.sortOrder,
        setAccounts,
        setIds,
        setListLoading,
        setTotalCount,
        user.organizationKey,
        ids,
        queryParams.filter.status,
        queryParams,
        setAllAccounts,
        allAccounts,
        filterLoading
    ])

    const onAccountDeleteClick = ({ organizationKey, organizationName }) => {
        setAccountDeleteData({ organizationKey, organizationName })
        setAccountDeletePromptIsOpen(true)
    }

    const deleteAccount = async () => {
        const clearData = () => {
            setAccountDeletePromptLoading(false)
            setAccountDeletePromptIsOpen(false)
            setAccountDeleteData(null)
            setAccountDeletePromptValue('')
        }

        try {
            setAccountDeletePromptLoading(true)

            const response = await API.deleteAccount(accountDeleteData.organizationKey)

            if (response.status === 200) {
                toast.success('Account deleted successfully', toastSuccessStyle)
                setAccounts(accounts.filter(a => a.id !== accountDeleteData.organizationKey))
                clearData()
            } else {
                throw new Error(`Error deleting account: ${response.message}`)
            }
        } catch (error) {
            console.error(error)
            toast.error(`Error deleting account: ${error.message}`, toastErrorStyle)
            clearData()
        }
    }

    const allColumns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            classes: 'font-weight-bolder'
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortCaret: sortCaret,
            formatter: columnFormatters.StatusColumnFormatter,
            headerSortingClasses
        },
        {
            dataField: 'subscription',
            text: 'Subscription',
            sort: true,
            sortCaret: sortCaret,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                return <span>{capitalize(row.subscription)}</span>
            },
            headerSortingClasses
        },
        {
            dataField: 'subscriptionStartDate',
            text: 'Subscription start',
            sort: true,
            sortCaret: sortCaret,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                if (row.subscriptionStartDate) {
                    return <span>{moment(row.subscriptionStartDate).format('DD MMMM, YYYY')}</span>
                } else {
                    return <span className="text-light-secondary">Unknown</span>
                }
            },
            headerSortingClasses
        },
        {
            dataField: 'trialDaysLeft',
            text: 'Trial days left',
            sort: true,
            sortCaret: sortCaret,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                if (row.trialDaysLeft) {
                    return <span>{row.trialDaysLeft}</span>
                }
            },
            headerSortingClasses
        },
        // {
        //     dataField: 'lastActivity',
        //     text: 'Latest activity',
        //     sort: true,
        //     sortCaret: sortCaret,
        //     // eslint-disable-next-line react/display-name
        //     formatter: (cellContent, row) => {
        //         if (row.lastActivity) {
        //             return <span>{moment(row.lastActivity).format('DD MMMM, YYYY')}</span>
        //         }
        //         return <span className="text-danger">No activity</span>
        //     },
        //     headerSortingClasses
        // },
        {
            dataField: 'created',
            text: 'Created',
            sort: true,
            sortCaret: sortCaret,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                if (row.created) {
                    return <span>{moment(row.created).format('DD MMMM, YYYY')}</span>
                }
                return <span className="text-light-secondary">Missing created date</span>
            },
            headerSortingClasses
        },
        {
            dataField: 'syncedAreas',
            text: 'Synced units',
            sort: true,
            sortCaret: sortCaret,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                return <span>{row.syncedAreas}</span>
            },
            headerSortingClasses
        },
        {
            dataField: 'unsyncedAreas',
            text: 'Unsynced units',
            sort: true,
            sortCaret: sortCaret,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                return <span>{row.unsyncedAreas}</span>
            },
            headerSortingClasses
        },
        {
            dataField: 'pms',
            text: 'Pms',
            sort: true,
            sortCaret: sortCaret,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                return <span>{row.pms}</span>
            },
            headerSortingClasses
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openEditAccountDialog: accountsUIProps.openEditAccountDialog,
                setListLoading: setListLoading,
                setLoadingAuthState,
                onAccountDeleteClick,
                allowedAccounts
            },
            classes: 'text-right pr-0 px-5',
            headerClasses: 'text-right pr-3 px-5',
            style: {
                minWidth: '167px'
            }
        }
    ]

    const columns = fields ? allColumns.filter(c => fields.includes(c.dataField)) : allColumns
    columns.forEach(col => {
        col.events = {
            onClick: (e, column, columnIndex, row) => {
                if (column.dataField != 'action') {
                    accountsUIProps.openEditAccountDialog(row.id)
                }
            }
        }
    })

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber
    }

    return (
        <div>
            {!filterLoading && (
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {({ paginationProps, paginationTableProps }) => {
                        return (
                            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
                                <BootstrapTable
                                    rowStyle={{ cursor: 'pointer' }}
                                    hover={true}
                                    wrapperClasses="table-responsive"
                                    bordered={false}
                                    classes="table table-head-custom table-vertical-center overflow-hidden"
                                    bootstrap4
                                    remote
                                    keyField="id"
                                    data={accounts === null ? [] : accounts}
                                    columns={columns}
                                    defaultSorted={[{ dataField: queryParams.sortField, order: queryParams.sortOrder }]}
                                    onTableChange={getHandlerTableChange(accountsUIProps.setQueryParams)}
                                    {...paginationTableProps}>
                                    <PleaseWaitMessage entities={accounts} />
                                    <NoRecordsFoundMessage entities={accounts} />
                                </BootstrapTable>
                            </Pagination>
                        )
                    }}
                </PaginationProvider>
            )}
            {listLoading && <LoadingView />}
            {accountDeletePromptIsOpen && (
                <Prompt
                    title={'Account deleting'}
                    text={`Are you sure you want to delete "${accountDeleteData.organizationName}" account? Enter "confirm" into the text field for confirmation.`}
                    placeholder={'Enter confirmation text'}
                    onConfirm={deleteAccount}
                    onCancel={() => setAccountDeletePromptIsOpen(false)}
                    value={accountDeletePromptValue}
                    onChange={setAccountDeletePromptValue}
                    disabled={accountDeletePromptValue.toLowerCase() !== 'confirm'}
                    confirmBtnBsStyle={'danger'}
                    confirmBtnText={'Delete'}
                    isLoading={accountDeletePromptLoading}
                />
            )}
        </div>
    )
}
