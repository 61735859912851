import React, { useState, useEffect } from 'react'
import { ModalContainer, ModalOverlay } from '../../components/styled-components/modal'
import LoginForm from './LoginForm'
import LoginLayout from '../../components/layouts/LoginLayout'
import { getRecaptchaVerifier } from '../../utils/app-verifier'
import firebase from '../../utils/firebase'
import { toast } from 'react-toastify'
import { toastErrorStyle } from '../../utils/styles'
import { ActivityIndicatorWrap, containerPadding, containerWidth } from './style'
import { ActivityIndicator } from 'react-native-web'
import { getBrand } from '@shared/brand'
import Verification from './Verification'
import MagicLink from './MagicLink/index'
import { getUserEmail, sendEmailLink } from './api'
import { EMAIL_STORAGE_ID } from '../../utils/constants'
import withErrorCatching from '../../utils/hocs/withErrorCatching'
import { isPhoneNumberValid } from '@shared/helpers'

const Login = ({ emailCallback = false, catchError }) => {
    const [loading, setLoading] = useState(false)
    const [verification, setVerification] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')

    const brandColor = getBrand().navBarColor

    const errorModalConfigs = { contactSupportMessage: 'Having problems logging in?' }

    useEffect(() => {
        singInWithEmail()
    }, [])

    async function singInWithEmail() {
        window.recaptchaVerifier = null

        if (emailCallback) {
            setLoading(true)

            const email = localStorage.getItem(EMAIL_STORAGE_ID)
            try {
                await firebase.auth().signInWithEmailLink(email, window.location.href)
                localStorage.removeItem(EMAIL_STORAGE_ID)
            } catch (error) {
                setLoading(false)
                catchError(error, errorModalConfigs)
            }
        }
    }

    const onSubmit = async ({ values, selectedCountry }) => {
        const { dialCode, key } = selectedCountry
        const phoneNumber = dialCode + values.phoneNumber
        const countryCode = key

        const errorMessage = 'Please enter a valid phone number'

        if (!isPhoneNumberValid(phoneNumber, countryCode)) {
            toast.error(errorMessage, toastErrorStyle)
            return
        }

        const appVerifier = getRecaptchaVerifier()

        try {
            setLoading(true)
            const verification = await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)

            setVerification(verification)
            setPhoneNumber(phoneNumber)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            if (error.code === 'auth/invalid-phone-number') {
                toast.error(errorMessage, toastErrorStyle)
                return
            }
            catchError(error, errorModalConfigs)
        }
    }

    const onLogin = async code => {
        try {
            setLoading(true)
            await verification.confirm(code)
        } catch (error) {
            setLoading(false)
            if (error.code === 'auth/invalid-verification-code') {
                toast.error('Invalid verification code. Please try again', toastErrorStyle)
                return
            }
            catchError(error, errorModalConfigs)
        }
    }

    const sendMagicLink = async () => {
        try {
            setLoading(true)

            const email = await getUserEmail(phoneNumber)
            await sendEmailLink(phoneNumber)

            localStorage.setItem(EMAIL_STORAGE_ID, email)
            setEmail(email)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            catchError(error, errorModalConfigs)
        }
    }

    const renderContent = () => {
        if (loading) {
            return (
                <ActivityIndicatorWrap>
                    <ActivityIndicator size="large" color={brandColor} />
                </ActivityIndicatorWrap>
            )
        }
        if (verification) return <Verification onLogin={onLogin} sendMagicLink={sendMagicLink} />

        return <LoginForm onSubmit={onSubmit} />
    }

    if (email) return <MagicLink email={email} />

    return (
        <ModalOverlay>
            <ModalContainer width={containerWidth} height={'718px'} padding={containerPadding} overflowY="none">
                <LoginLayout>{renderContent()}</LoginLayout>
            </ModalContainer>
            <div id="recaptcha-container" />
        </ModalOverlay>
    )
}

export default withErrorCatching(Login)
