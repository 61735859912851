import { TaskStruct } from './firestore-structs'

export const LAST_TASK_ASSIGNEE = 'LAST_TASK_ASSIGNEE'

export enum TasksSectionTitle {
    DUE_TODAY = 'Due',
    OVERDUE = 'Overdue',
    NO_DUE_DATE = 'No due date',
    ASSIGNED = 'Assigned',
    DONE = 'Done',
    CREATED_BY_ME = 'Created by me'
}

export interface TasksSection {
    count: number
    data: TaskStruct[]
    title: TasksSectionTitle
    workloadCounts?: {
        checkInCount: number
        otherWorkCount: number
        unclean50Count: number
        unclean80Count: number
        unclean100Count: number
    }
}
