import React from 'react'
import { TouchableOpacity, View } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import Hoverable from '../utils/hoverable/hoverable'
import { HOVER_OPACITY } from '../utils/constants'
export default class CheckBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = { isChecked: !!this.props.isChecked }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isChecked !== state.isChecked) {
            return { isChecked: !!props.isChecked }
        }
        return null
    }

    render() {
        const useExtraTouchable = this.props.useExtraTouchable ? true : false
        const box_width = 18
        let touchableWidth = box_width
        let touchableHeight = box_width
        if (useExtraTouchable) {
            touchableWidth = 32
            touchableHeight = 26
        }
        return (
            <Hoverable
                onHoverIn={() => {
                    this.setState({ hover: true })
                }}
                onHoverOut={() => {
                    this.setState({ hover: false })
                }}>
                <TouchableOpacity
                    onPress={() => {
                        if (this.props.onValueChange) {
                            this.props.onValueChange(!this.state.isChecked)
                        }
                    }}
                    style={{
                        // opacity: this.state.hover ? HOVER_OPACITY : 1,
                        height: touchableHeight,
                        width: touchableWidth,
                        // borderRadius: 3,
                        // borderColor: iOSColors.midGray,
                        // backgroundColor: this.state.isChecked ? iOSColors.midGray : 'white',
                        borderWidth: 0,
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}>
                    <label
                        className={`checkbox checkbox-specta-blue d-flex checkbox-outline checkbox-outline-2x
                        `}>
                        <input className="ml-2" type="checkbox" value={this.state.isChecked} checked={this.state.isChecked} />
                        <span></span>
                    </label>
                    {/*<View*/}
                    {/*    style={{*/}
                    {/*        opacity: this.state.hover ? HOVER_OPACITY : 1,*/}
                    {/*        height: box_width,*/}
                    {/*        width: box_width,*/}
                    {/*        borderRadius: 3,*/}
                    {/*        borderColor: iOSColors.midGray,*/}
                    {/*        backgroundColor: this.state.isChecked ? iOSColors.midGray : 'white',*/}
                    {/*        borderWidth: 2,*/}
                    {/*        alignItems: 'center',*/}
                    {/*        justifyContent: 'center'*/}
                    {/*    }}>*/}
                    {/*    {this.state.isChecked && (*/}
                    {/*        <span*/}
                    {/*            style={{*/}
                    {/*                fontSize: 14,*/}
                    {/*                color: 'white'*/}
                    {/*            }}>*/}
                    {/*            ✓*/}
                    {/*        </span>*/}
                    {/*    )}*/}
                    {/*</View>*/}
                </TouchableOpacity>
            </Hoverable>
        )
    }
}
