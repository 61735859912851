import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from '../../../utils/constants'

export const SignUpLayoutWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    padding: 65px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        padding: 54px 40px 40px 40px;
        align-items: center;
    }
`
