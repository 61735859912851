import React from 'react'
import { TabsWrap, Wrap } from './style'
import Tab from './Tab'
import * as uc from 'app/utils/constants'
import { HoverableImageButton } from 'app/components/buttons/hoverable-image-button'
import { SummaryTab } from 'app/modules/HousekeepingDashboard/types'
import closeIcon from '@shared/img/close.svg'

interface Props {
    tabs: Readonly<SummaryTab[]>
    setActiveTab: (tab: SummaryTab) => void
    onClose: () => void
}

function Navigation({ tabs, setActiveTab, onClose }: Props) {
    return (
        <Wrap>
            <TabsWrap>
                {tabs.map((tab, index) => (
                    <Tab
                        key={tab.title + index}
                        type={tab.type}
                        icon={tab.icon}
                        title={tab.title}
                        count={tab.count}
                        isActiveTab={tab.isActiveTab}
                        onClick={tab => setActiveTab(tab)}
                    />
                ))}
            </TabsWrap>

            <HoverableImageButton
                dimension={uc.SMALL_BUTTON_DIM}
                hoverOpacity={uc.HOVER_OPACITY}
                imageSource={closeIcon}
                onPress={onClose}
                hoverImageSource={''}
                tooltipDistance={0}
                tooltipOffset={0}
                tooltipText={''}
            />
        </Wrap>
    )
}

export default Navigation
