import React, { PureComponent } from 'react'
import { View, Image, TouchableOpacity } from 'react-native-web'
import moment from 'moment-timezone'

import { iOSColors } from '@shared/react-native-typography'
import Hoverable from '../utils/hoverable/hoverable'
import * as colors from '@shared/colors'
import * as brand from '@shared/brand'
import * as c from '@shared/constants'
import { CommentivityStruct, IssueStruct, UserStruct } from '@shared/firestore-structs'

type CommentivityProps = {
    currentUser: UserStruct
    item: CommentivityStruct
    deleteComment: (comment: CommentivityStruct) => void
}
type CommentivityState = { showCommentAction: boolean; showCommentivity: boolean }
export default class Commentivity extends React.PureComponent<CommentivityProps, CommentivityState> {
    state: CommentivityState = {
        showCommentAction: false,
        showCommentivity: true
    }

    render() {
        const fullNameFontSize = 13
        const timeStyle = {
            fontSize: 12,
            color: iOSColors.midGray
        }

        const DEBUG_BORDER = 0
        const SPACE_VERTICAL = 12
        const META_INFO_LEFT_MARGIN = 0
        const BUBBLE_PADDING = 8

        const BUTTON_DIM = 30

        const isCreator = this.props.currentUser && this.props.currentUser.key === this.props.item.creator.key

        const createdDay = moment(this.props.item.created).format(c.DATE_FORMAT_WITH_WEEK_DAY)
        const createdTime = moment(this.props.item.created).format('HH:mm')

        if (this.state.showCommentivity) {
            if (this.props.item.type === 'comment' && this.props.item.visible !== false) {
                const bubbleBackground = '#F1F0F0'

                return (
                    <Hoverable
                        onHoverIn={() => {
                            this.setState({ showCommentAction: true })
                        }}
                        onHoverOut={() => {
                            this.setState({ showCommentAction: false })
                        }}>
                        <View
                            key={this.props.item.key}
                            style={{
                                marginBottom: SPACE_VERTICAL,
                                borderWidth: DEBUG_BORDER,
                                borderColor: 'green'
                            }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
                                <span className="font-weight-bold" style={timeStyle}>
                                    {createdTime},{' '}
                                </span>
                                <span className="font-weight-bold" style={timeStyle}>
                                    {createdDay}
                                </span>
                            </View>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    borderWidth: 0
                                }}>
                                <View style={{ borderWidth: 0, borderColor: 'red', justifyContent: 'center' }}>
                                    <View
                                        style={{
                                            width: BUTTON_DIM,
                                            height: BUTTON_DIM,
                                            borderRadius: BUTTON_DIM / 2,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: isCreator ? brand.getBrand().navBarColor : colors.green_spectaflow
                                        }}>
                                        <span className="font-weight-bold" style={{ fontSize: 11, color: iOSColors.white }}>
                                            {this.props.item.creator.initials}
                                        </span>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        marginLeft: 6,
                                        borderWidth: 0,
                                        borderColor: 'red',
                                        justifyContent: 'center'
                                    }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <span
                                            className="font-weight-bold"
                                            style={{
                                                fontSize: fullNameFontSize,

                                                flexWrap: 'wrap'
                                            }}>
                                            {this.props.item.creator.name}
                                        </span>
                                    </View>
                                </View>
                                {isCreator && this.state.showCommentAction && (
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.props.deleteComment(this.props.item)
                                        }}
                                        style={{
                                            flexDirection: 'column',
                                            marginLeft: 12,
                                            marginTop: 0,
                                            justifyContent: 'center'
                                        }}>
                                        <Image
                                            style={{
                                                height: 28,
                                                width: 28
                                            }}
                                            source={require('../img/trash.svg')}
                                        />
                                    </TouchableOpacity>
                                )}
                            </View>

                            <View
                                style={{
                                    marginTop: 6,
                                    marginBottom: 0,
                                    borderWidth: DEBUG_BORDER
                                }}>
                                <View
                                    style={{
                                        borderRadius: 6,
                                        backgroundColor: bubbleBackground,
                                        minHeight: 18,
                                        flexDirection: 'column',
                                        borderWidth: 0,
                                        borderColor: 'red',
                                        alignSelf: 'flex-start'
                                    }}>
                                    <span
                                        className="font-weight-bold"
                                        style={{
                                            opacity: isCreator && this.state.showCommentAction ? 0.3 : 1,
                                            color: iOSColors.black,
                                            fontSize: 14,
                                            paddingBottom: BUBBLE_PADDING,
                                            paddingTop: BUBBLE_PADDING,
                                            paddingLeft: BUBBLE_PADDING,
                                            paddingRight: BUBBLE_PADDING,
                                            flexWrap: 'wrap'
                                        }}>
                                        {this.props.item.text}
                                    </span>
                                </View>
                            </View>
                        </View>
                    </Hoverable>
                )
            }
            if (this.props.item.type === 'comment' && !this.props.item.visible) {
                return <View key={this.props.item.key} />
            }

            return (
                <View
                    key={this.props.item.key}
                    style={{
                        marginLeft: META_INFO_LEFT_MARGIN + 0,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginBottom: SPACE_VERTICAL,
                        // alignItems: 'center',
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'red'
                    }}>
                    <View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
                            <span className="font-weight-bold" style={timeStyle}>
                                {createdTime},{' '}
                            </span>
                            <span className="font-weight-bold" style={timeStyle}>
                                {createdDay}
                            </span>
                        </View>
                        <span
                            className="font-weight-bold"
                            style={{
                                fontSize: fullNameFontSize,

                                flexWrap: 'wrap',
                                color: iOSColors.gray
                            }}>
                            {this.props.item.creator.name + ' ' + this.props.item.text}
                        </span>
                    </View>
                </View>
            )
        }
        return null
    }
}
