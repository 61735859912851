import styled from 'styled-components'
import { MOBILE_BREAKPOINT, SMALL_DESKTOP_SCREEN_HEIGHT } from '../../../../utils/constants'
import * as uc from 'app/utils/constants'

export const isSmallDesktop = window.innerHeight < uc.SMALL_DESKTOP_SCREEN_HEIGHT
export const containerPadding = isSmallDesktop ? '25px 55px 25px 55px' : '64px 73px 35px 73px'
export const containerWidth = isSmallDesktop ? '500px' : '584px'

export const LoginFormWrapper = styled.div`
    display: flex;
    justify-content: center;

    > form {
        width: 376px;

        input {
            height: 56px !important;
            padding-left: 25px;
            font-weight: 450 !important;
            font-size: 1.231rem !important;
        }
        .react-datepicker-wrapper {
            width: 100%;
            display: flex !important;
            flex-direction: column !important;
        }

        .react-datepicker__input-container {
            width: 100%;
        }
        .react-datepicker {
            font-size: 1.167rem;
        }
        .react-datepicker__input-container input {
            height: 100%;
            width: 100%;
            background: #ffffff;
            border: 2px solid #8dd0e4;
            border-radius: 8px;
            padding: 18px 0 14px 24px;
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: 450 !important;
            font-size: 1.231rem !important;
            line-height: 24px;
            color: #454647;
        }
        .form-group label {
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: 700;
            font-size: 1.077rem;
            line-height: 18px;
            color: #454647;
        }
    }

    .btn-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    @media (max-height: ${SMALL_DESKTOP_SCREEN_HEIGHT}px) {
        margin-top: 20px;

        .btn-wrap {
            > button {
                height: 56px !important;
                margin-bottom: 16px !important;
            }
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        .btn-wrap {
            > button {
                height: 46px;
                font-size: 1.167rem;
            }
        }
    }
`
