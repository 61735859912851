import styled, { keyframes } from 'styled-components'
import { gentle_gray_spectaflow } from '@shared/colors'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Wrapper = styled.div<{ asideIsOpen: boolean }>`
    animation: ${fadeIn} 0.6s;
    position: fixed;
    width: calc(100% - ${({ asideIsOpen }) => (asideIsOpen ? '248px' : '79px')});
    height: 125.5px;
    bottom: 0;
    right: 0;
    z-index: 100;
    background-color: ${gentle_gray_spectaflow};
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 22px;
`

export const CloseButtonWrap = styled.div`
    padding-left: 27px;
`

export const CenterWrap = styled.div`
    display: flex;
    justify-content: center;
`

export const SelectButtonsWrap = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
`
