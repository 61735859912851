import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Input } from '../../../../../_metronic/_partials/controls'
import { Checkbox } from '../../../../components/forms/Checkbox'
import { ModalFormFooter } from '../../../../components/modals/ModalFormFooter'
import CreatableDropdown from '../../../../components/forms/CreatableDropdown'

const AreaEditSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Name is required'),
    description: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
    address: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
    group: Yup.string().required('Unit group is required'),
    displayCleaning: Yup.boolean()
})

export function AreaEditForm({ saveArea, area, groups, setGroups, actionsLoading, dataLoading, onHide }) {
    const onSubmit = values => saveArea(values)

    return (
        <>
            <Formik enableReinitialize initialValues={area} validationSchema={AreaEditSchema} onSubmit={onSubmit}>
                {({ handleSubmit, values, setFieldValue, errors }) => {
                    const [groupValueOption, setGroupValueOption] = useState(null)

                    useEffect(() => {
                        if (values.group && values.group.length > 0) {
                            console.log('values.group', values.group)
                            setGroupValueOption({ value: values.group, label: values.group })
                        }
                    }, [values.group])

                    const handleGroupChange = group => {
                        const value = group.value ? group.value : ''
                        setFieldValue('group', value)
                    }

                    const handleGroupCreate = groupString => {
                        setFieldValue('group', groupString)
                        setGroups([...groups, { value: groupString, label: groupString }])
                    }

                    const handleDisplayCleaningStatusChange = () => {
                        setFieldValue('displayCleaningStatus', !values.displayCleaningStatus)
                    }

                    return (
                        <>
                            <Modal.Body className="overlay overlay-block cursor-default">
                                <Form className="form form-label-right">
                                    <div className="form-group row">
                                        {/* Name */}
                                        <div className="col-lg-12">
                                            <Field
                                                name="name"
                                                component={Input}
                                                placeholder="Enter name of unit"
                                                label="Name"
                                                required
                                                disabled={actionsLoading}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        {/* Description */}
                                        <div className="col-lg-12">
                                            <Field
                                                name="description"
                                                component={Input}
                                                placeholder="Enter description"
                                                label="Description"
                                                disabled={actionsLoading}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        {/* Address */}
                                        <div className="col-lg-12">
                                            <Field
                                                name="address"
                                                component={Input}
                                                placeholder="Enter address"
                                                label="Address"
                                                disabled={actionsLoading}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        {/* Group */}
                                        <div className="col-lg-12">
                                            <Field
                                                name="group"
                                                component={CreatableDropdown}
                                                placeholder="Enter group"
                                                value={groupValueOption}
                                                label="Group"
                                                options={groups}
                                                onChange={handleGroupChange}
                                                onCreateOption={handleGroupCreate}
                                                required
                                                disabled={actionsLoading}
                                                error={errors?.group}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        {/* Display in cleaning */}
                                        <div className="col-lg-12">
                                            <Field
                                                component={Checkbox}
                                                label="Display in Housekeeping"
                                                isSelected={values.displayCleaningStatus}
                                                disabled={actionsLoading}
                                                onChange={handleDisplayCleaningStatusChange}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className="border-top-0">
                                <ModalFormFooter
                                    onHide={onHide}
                                    onSubmit={handleSubmit}
                                    dataLoading={dataLoading}
                                    actionsLoading={actionsLoading}
                                    type="save"
                                />
                            </Modal.Footer>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}
