import React from 'react'
import { Input, Message, Wrap } from './style'

interface Props {
    value: string
    onChange: (value: string) => void
    placeholder?: string
    message?: string
}

function CleaningTaskNameInput({
    value,
    onChange,
    placeholder = 'Cleaning task name',
    message = 'If left empty the name of a corresponding rule will be used'
}: Props) {
    return (
        <Wrap>
            <Input value={value} placeholder={placeholder} onChange={e => onChange(e.target.value)} />
            <Message>{message}</Message>
        </Wrap>
    )
}

export default CleaningTaskNameInput
