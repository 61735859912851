import * as React from 'react'
import Chart from 'react-apexcharts'

type Props = {
    series: any

    labels: any
}
export const Donut = (props: Props) => {
    return (
        <div className="donut">
            <Chart options={{ labels: props.labels }} series={props.series} type="donut" width="500" />
        </div>
    )
}
