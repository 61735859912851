import React from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { IssueStruct, TaskStruct, UserStruct } from '@shared/firestore-structs'
import { getDataFromSnapshot } from 'app/utils/QuerySnapshotData'
import { getFilteredIssuesQuery } from '@shared/issue-data'
import { getQueryArray } from '@shared/queries/data'
import { filtersAtom, issuesListAtom, paginationAtom } from '../atoms'
import * as taskData from '@shared/task-data'
import firebase, { asFirebase, tasksRepo } from '../../../utils/firebase'
import { filterIssues, getHashTagsWithCount, paginateIssues, createNewIssue } from '../../helpers'
import * as helpers from '@shared/helpers'
import { toast } from 'react-toastify'
import { toastSuccessStyle, toastErrorStyle } from '../../../utils/styles'
import { HashTag, NewIssueValues } from '../../types'
import { userHasAccessToAreaGroup } from '@shared/area-groups-helpers'
import { currentUserAtom } from 'app/modules/Auth/atoms'
import { IssueStatus } from '@shared/dataObjects'

export const useIssues = (): {
    issues: IssueStruct[] | null
    filteredIssues: IssueStruct[]
    isLoading: boolean
    paginatedIssues: IssueStruct[] | null
    hashTags?: HashTag[]
    errorIssues: unknown
    createIssue: (values: NewIssueValues, currentUser: UserStruct) => Promise<void>
} => {
    const currentUser = useRecoilValue(currentUserAtom) as UserStruct
    const authContext = React.useContext(AuthContext)
    if (!authContext.currentOrganization)
        return {
            issues: null,
            filteredIssues: [],
            isLoading: true,
            paginatedIssues: null,
            hashTags: [],
            errorIssues: {},
            createIssue: () => Promise.resolve()
        }
    const { currentOrganization } = authContext

    const filters = useRecoilValue(filtersAtom)
    const pagination = useRecoilValue(paginationAtom)
    const [loading, setLoading] = React.useState(true)

    const [issues, setIssues] = React.useState<IssueStruct[] | null>(null)
    const setIssuesList = useSetRecoilState(issuesListAtom)

    const [issuesSnap, loadingIssues, errorIssues] = useCollection<IssueStruct>(
        getFilteredIssuesQuery(asFirebase(firebase), currentOrganization.key, {
            statuses: getQueryArray(filters.status as { isSelected: boolean; value: IssueStruct['status'] }[])
        })
    )

    const [tasksSnap] = useCollection<TaskStruct>(taskData.getIssueTasksQuery(asFirebase(firebase), currentOrganization.key))

    const structureData = async () => {
        if (!loadingIssues && issuesSnap) {
            const issuesFromSnap = [...getDataFromSnapshot(issuesSnap)]
            const normalizedIssues: IssueStruct[] = [...issuesFromSnap].filter((issue: IssueStruct) =>
                userHasAccessToAreaGroup(currentUser.areaGroups, issue.area.group)
            )

            const issuesWithDueDates = await Promise.all(
                normalizedIssues.map(async (issue: IssueStruct) => {
                    if (issue.taskKey) {
                        const task = (await tasksRepo.getTask(issue.taskKey).catch(error => {
                            console.log('error', error)
                        })) as TaskStruct
                        return { ...issue, dueDate: (task ? task.startDate : issue.dueDate) || null }
                    } else {
                        return issue
                    }
                })
            )
            setIssues(issuesWithDueDates)
            setIssuesList(issuesWithDueDates)
            setLoading(false)
        }
    }

    React.useEffect(() => {
        structureData()
    }, [issuesSnap, loadingIssues, tasksSnap])

    const filteredIssues = React.useMemo(() => {
        if (issues) {
            let i = filterIssues(issues, filters.search, helpers.searchSet)
            if (filters.priority) {
                i = i.filter((issue: IssueStruct) => issue.priority)
            }
            return [...i].sort((a: any, b: any) => b.priority - a.priority)
        } else return []
    }, [issues, filters, pagination])

    const paginatedIssues = React.useMemo(() => {
        if (filteredIssues) return paginateIssues(filteredIssues, pagination)
        else return []
    }, [filteredIssues, pagination])

    const hashTags = React.useMemo(() => {
        if (filteredIssues) return getHashTagsWithCount(filteredIssues)
    }, [filteredIssues])

    const isLoading = React.useMemo(() => {
        return (loadingIssues && !issues && !issuesSnap) || (!issues && loading)
    }, [issues, loadingIssues, issuesSnap])

    const createIssue = async (values: NewIssueValues, currentUser: UserStruct) => {
        const { issue, error } = await createNewIssue(values, currentUser)
        if (issue) {
            const successMessage = `Issue "${issue.name}" created successfully`
            toast.success(successMessage, toastSuccessStyle)
        } else {
            const errorMessage = `Issue "${values.title}" not created. ${error}`
            toast.error(errorMessage, toastErrorStyle)
        }
    }

    return { issues, filteredIssues, isLoading, paginatedIssues, hashTags, errorIssues, createIssue }
}
