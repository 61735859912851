export function getPaginationOptions(itemsCount, sizePerPage, sizePerPageList, activePage, onPageChange) {
    return {
        alwaysShowAllBtns: false,
        custom: true,
        totalSize: itemsCount || 0,
        sizePerPageList,
        sizePerPage: sizePerPage,
        pageStartIndex: 1,
        paginationSize: 5,
        pagesCount: Math.ceil(itemsCount / sizePerPage),
        page: activePage,
        onPageChange,
        firstPageTitle: 'first page',
        hidePageListOnlyOnePage: false,
        hideSizePerPage: false,
        lastPageText: '>>',
        lastPageTitle: 'last page',
        nextPageText: '>',
        nextPageTitle: 'next page',
        prePageText: '<',
        prePageTitle: 'previous page',
        withFirstAndLast: true,
        hidePageSizeDropdown: true
    }
}
