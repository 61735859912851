import React from 'react'
import { IssuesNotFoundContainer, IssuesNotFoundDescription, IssuesNotFoundImageBanner } from './../style'

export default function IssuesNotFound() {
    return (
        <IssuesNotFoundContainer>
            <IssuesNotFoundImageBanner />
            <IssuesNotFoundDescription marginTop={6} fontSize={20}>
                No issues found
            </IssuesNotFoundDescription>
            <IssuesNotFoundDescription marginTop={12} fontSize={15}>
                Try a new search or
            </IssuesNotFoundDescription>
            <IssuesNotFoundDescription marginTop={9} fontSize={15}>
                change the status
            </IssuesNotFoundDescription>
        </IssuesNotFoundContainer>
    )
}
