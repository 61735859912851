import React, { useState, useEffect, useContext } from 'react'

import { Field, Form, Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import { notify } from 'react-notify-toast'
import { ActivityIndicator } from 'react-native-web'

import firebase from '../../../../utils/firebase'
import { Toast } from '../../../../utils/toast'
import * as brand from '@shared/brand'

import { GuestsContext } from '../GuestsContext'
import {
    CLEANING_STATUS_CLEAN,
    CLEANING_STATUS_DIRTY,
    CLEANING_STATUS_DO_NOT_DISTURB,
    CLEANING_STATUS_INSPECTION,
    CLEANING_STATUS_NO_SERVICE,
    CLEANING_STATUS_PREPARING,
    CLEANING_STATUS_PREPARING_PAUSE,
    CLEANING_STATUS_WAITING_FOR_CHECKOUT,
    OCCUPANCY_CHECKOUT
} from '@shared/txt-constants'
import { calculateCleaningStatus } from '@shared/cleaning-calculator'
import moment from 'moment-timezone'
import { planningInfo } from '../GuestsUIHelpers'
import { httpsCallable } from '../../../../api'

export function CleaningSchedule(props) {
    const [loading, setloading] = useState(false)
    const [bookingDates, setBookingsDates] = useState([])
    const [saving, setSaving] = useState(false)
    const { setShowAlert, dataLoading, setDataLoading, guestData } = useContext(GuestsContext)
    const [optOutDates, setOptOutDates] = useState(guestData.booking.optOutDates || [])
    const [optInDates, setOptInDates] = useState(guestData.booking.optInDates || [])

    const initialValues = {}

    const formik = useFormik({
        initialValues,
        // validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            const setCleaningDates = httpsCallable('setCleaningDates')
            setSaving(true)
            setCleaningDates({ bookingId: booking.bookingId, optOutDates, optInDates })
                .then(() => {
                    setSubmitting(false)
                    setSaving(false)
                    // setShowAlert(true)
                    setloading(false)
                })
                .catch(error => {
                    setSubmitting(false)
                    setSaving(false)
                    setloading(false)
                    console.log(error)
                    notify.show(<Toast width={250} message={error.message} />, 'custom', 3000, {})
                })
            // saveAccount(values, setStatus, setSubmitting)
        },
        onReset: (values, { resetForm }) => {
            resetForm()
        }
    })

    const { booking, area, rules, organization } = guestData

    useEffect(() => {
        const calculations = booking.bookingDates.map(async d => {
            booking.optOutDates = [...optOutDates]
            booking.optInDates = [...optInDates]
            let resultObject = await calculateCleaningStatus(
                firebase,
                [Object.assign({}, area)],
                parseInt(d),
                organization,
                [booking],
                [],
                rules
            )
            resultObject[0].showIcon = false
            resultObject[0].date = parseInt(d)
            return resultObject[0]
        })

        Promise.all(calculations).then(values => {
            setBookingsDates(values)
        })
    }, [optOutDates, setOptOutDates, optInDates, setOptInDates])

    const onOptInClick = (e, i) => {
        let dates = [...optInDates]
        const index = dates.indexOf(e.date.toString())
        if (index > -1) {
            dates.splice(index, 1)
        } else {
            dates.push(booking.bookingDates[i])
        }
        setOptInDates(dates)
        formik.handleSubmit(formik.values)
    }

    const onOptOutClick = (e, i) => {
        let dates = [...optOutDates]
        const index = dates.indexOf(e.date.toString())
        if (index > -1) {
            dates.splice(index, 1)
        } else {
            dates.push(booking.bookingDates[i])
        }
        setOptOutDates(dates)
        formik.handleSubmit(formik.values)
    }

    const getBubbleColor = (date, index) => {
        if (booking.optInDates && booking.optInDates.includes(date.date.toString())) {
            return 'specta-teal'
        }
        if (booking.optOutDates && booking.optOutDates.includes(date.date.toString())) {
            return 'gray-500'
        }
        if (index === 0) {
            return 'specta-green'
        }
        if (index === booking.bookingDates.length - 1) {
            return 'specta-purple'
        }
        switch (date.cleaningStatus) {
            case CLEANING_STATUS_CLEAN:
                return organization.allowOptIn ? 'gray-500' : 'specta-teal'
            case CLEANING_STATUS_DIRTY:
            case CLEANING_STATUS_PREPARING:
            case CLEANING_STATUS_PREPARING_PAUSE:
            case CLEANING_STATUS_INSPECTION:
            case CLEANING_STATUS_DO_NOT_DISTURB:
            case CLEANING_STATUS_NO_SERVICE:
                return 'specta-teal'
            case CLEANING_STATUS_WAITING_FOR_CHECKOUT:
                return 'specta-purple'
            default:
                return 'gray-500'
        }
    }

    const PlanningInfo = props => {
        return planningInfo.map(x => (
            <div key={x.name} className="d-flex align-items-center pb-9">
                {/* <span className={`label label-xl font-weight-bolder label-dot label-${x.colorClass}`}></span> */}
                <span
                    className={`rounded-circle text-center bg-${x.colorClass} cursor-pointer`}
                    style={{ height: '1.2rem', width: '1.2rem' }}></span>
                <span className="font-size-lg font-weight-bolder ml-2">{x.name}</span>
            </div>
        ))
    }

    const DateBubble = ({ date, index }) => {
        const showIcon = date.showIcon
        const firstOrLast = index === 0 || index === booking.bookingDates.length - 1

        const colorClass = getBubbleColor(date, index) + ' text-white'
        return (
            <div
                className={`rounded-circle mb-5 text-center bg-${colorClass} ${!firstOrLast ? 'cursor-pointer' : ''}`}
                style={{ height: '7rem', width: '7rem' }}
                onClick={() => {
                    if (organization.allowOptIn && !firstOrLast) {
                        onOptInClick(date, index)
                    }
                    if (organization.allowOptOut && !firstOrLast) {
                        onOptOutClick(date, index)
                    }
                }}>
                <div style={{ paddingTop: showIcon ? '10%' : '15%' }}>
                    <div className="font-weight-bolder text-uppercase">{moment(date.date).format('ddd')}</div>
                    <div className="display4 font-weight-bolder" style={{ position: 'relative', bottom: 5 }}>
                        {/* {index + 1} */}
                        {moment(date.date).format('DD')}
                    </div>
                </div>
            </div>
        )
    }

    const renderBookingDates = () => {
        return (
            <div className="row mt-9">
                {bookingDates.map((e, i) => {
                    const subheader = i === 0 ? 'Check in' : i === booking.bookingDates.length - 1 ? 'Check out' : ''
                    return (
                        <div key={i} className="col-4 col-md-2 col-lg-3 text-center">
                            <div className="font-size-sm font-weight-bolder mb-2" style={{ minHeight: 18 }}>
                                {subheader}
                            </div>

                            <span className="flex-center d-flex">
                                <DateBubble date={e} index={i} />
                            </span>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <form className="card card-custom " onSubmit={formik.handleSubmit}>
            {/* {loading && <ModalProgressBar />} */}
            <div
                style={{
                    position: 'absolute',
                    right: '50%',
                    top: '40%',
                    height: 50,
                    width: 50,
                    zIndex: 10000
                }}>
                {loading && <ActivityIndicator size="large" color={brand.getBrand().navBarColor} style={{}} />}
            </div>

            {/* begin::Header */}
            <div className="card-header with-border py-3">
                <div className="card-title align-items-start flex-column">
                    <div className="d-flex">
                        <i className="specta-rules font-size-h1 text-dark mr-3"></i>
                        <h3 className="card-label font-weight-bolder text-dark">Cleaning plan</h3>
                    </div>
                    <span className="text-muted font-weight-bold font-size-lg mt-1">Choose what day you want your room cleaned.</span>
                </div>
                {saving && <div className="card-toolbar text-success font-weight-bolder font-size-lg">Saving...</div>}
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                {/* begin::Body */}
                <div className="card-body">
                    <div className="d-flex justify-content-between flex-wrap">
                        <PlanningInfo />
                    </div>
                    <h5 className="font-weight-bolder">Press a day to change plan</h5>
                    <div className="form-group">{bookingDates && bookingDates.length > 0 && renderBookingDates()}</div>
                </div>
                {/* end::Body */}
            </div>
            {/* end::Form */}
            {/* <div className="card-footer d-flex justify-content-end">
                <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}>
                    Confirm
                    {formik.isSubmitting}
                </button>
            </div> */}
        </form>
    )
}
