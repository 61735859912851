import React from 'react'
import { Input, Label, Wrapper } from './style'
import { TimerDisplay } from '@shared/timer-helpers'

function Timer({ hours, min, sec }: TimerDisplay) {
    return (
        <Wrapper>
            <Input>
                <span>{hours}</span>
                <span>{':'}</span>
                <span>{min}</span>
                <span>{':'}</span>
                <span>{sec}</span>
            </Input>
            <Label>{'Last duration'}</Label>
        </Wrapper>
    )
}

export default Timer
