import React from 'react'
import { Wrap } from './style'
import { SubscriptionCheck } from 'app/components/upgrade-signals'
import HoverableDatePicker from 'app/components/hoverable-date-picker'
import { FeatureId } from '@shared/subscriptions.types'
import { SubscriptionParams } from 'app/issues/types'

interface Props {
    featureId: FeatureId
    selectedDateNumber: number
    onDateChange: (date: number) => void
    onNotAvailableDateChange: (notAccessibleFeature: FeatureId | null, availableUpgrades: SubscriptionParams['availableUpgrades']) => void
    isWhite?: boolean
}

function DatePicker({ featureId, selectedDateNumber, onDateChange, onNotAvailableDateChange, isWhite = false }: Props) {
    return (
        <Wrap>
            <SubscriptionCheck
                featureId={featureId}
                onAvailable={() => (
                    <HoverableDatePicker
                        isWhite={isWhite}
                        hasTodayButton
                        selectedDateNumber={selectedDateNumber}
                        onDateChange={onDateChange}
                    />
                )}
                onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                    return (
                        <HoverableDatePicker
                            hasTodayButton={true}
                            isWhite={isWhite}
                            selectedDateNumber={selectedDateNumber}
                            onDateChange={() => onNotAvailableDateChange(notAccessibleFeature, availableUpgrades)}
                        />
                    )
                }}
            />
        </Wrap>
    )
}

export default DatePicker
