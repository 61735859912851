import React, { useEffect, useState, useRef } from 'react'
import { body_color, green_spectaflow } from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'

//TODO - Rewrite to TS
const ContentEditable = ({ content, showIcon, onChange, onSubmit, placeholder, style, disabled }) => {
    const divRef = useRef(null)

    const [hover, setHover] = useState(false)
    const [focus, setFocus] = useState(false)

    const styles = {
        editableDiv: {
            padding: '6px 12px',
            fontSize: 17,
            fontWeight: '300',
            borderRadius: 6,
            borderColor: hover || focus ? green_spectaflow : iOSColors.lightGray,
            borderWidth: 1,
            borderStyle: 'solid',
            outline: 'none',
            backgroundColor: 'white',
            color: content ? body_color : iOSColors.midGray,
            lineHeight: 1.4,
            resize: 'both',
            overflow: 'auto',
            minHeight: 80,
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word'
        },
        icon: { position: 'absolute', top: 50, right: 10, cursor: 'pointer', zIndex: 1000 }
    }

    useEffect(() => {
        divRef.current.innerHTML = content || placeholder
    }, [])

    const handleContentChange = () => {
        const newContent = divRef.current.innerHTML
        onChange(newContent)
    }

    const handleHover = () => {
        setHover(!hover)
    }
    const handleFocus = () => {
        if (divRef.current.innerHTML === placeholder) {
            divRef.current.innerHTML = ''
        }
        setFocus(true)
    }

    const handleBlur = () => {
        if (!divRef.current.innerHTML) {
            divRef.current.innerHTML = placeholder
        }
        setTimeout(() => {
            setFocus(false)
        }, 100)

        onSubmit()
    }

    const iconCondition = () => {
        if (!focus || !showIcon) return null

        return <i onClick={handleBlur} className={'specta-checkmark icon-md text-specta-teal'} style={styles.icon}></i>
    }

    return (
        <div style={{ position: 'relative' }}>
            <div
                style={{ ...styles.editableDiv, ...style }}
                ref={divRef}
                contentEditable={!disabled}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                onInput={handleContentChange}
                onFocus={handleFocus}
                onBlur={handleBlur}></div>
            {iconCondition()}
        </div>
    )
}

export default ContentEditable
