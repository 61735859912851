import {
    ActivityStruct,
    AreaStruct,
    BookingStruct,
    CleaningStruct,
    DailyCommentStruct,
    IssueStruct,
    RuleStruct,
    TaskStruct,
    UserStruct
} from '@shared/firestore-structs'
import moment from 'moment-timezone'
import { atom } from 'recoil'
import { LastCleaningTask } from '../../types'
import { SubscriptionParams } from 'app/types'

export const subscriptionParamsAtom = atom<SubscriptionParams & { upgradeMessageIsOpen: boolean }>({
    key: 'housekeeping-modal-subscriptionParams',
    default: {
        hasSubscriptionAccess: false,
        availableUpgrades: null,
        notAccessibleFeature: null,
        upgradeMessageIsOpen: false
    }
})

export const areaAtom = atom<AreaStruct | null>({
    key: 'housekeeping-modal-areaAtom',
    default: null
})

export const taskAtom = atom<TaskStruct | null>({
    key: 'housekeeping-modal-taskAtom',
    default: null
})

export const currentDateNumberAtom = atom<number>({
    key: 'housekeeping-modal-selectedDateNumberAtom',
    default: moment().valueOf()
})

export const rulesAtom = atom<RuleStruct[]>({
    key: 'housekeeping-modal-rulesAtom',
    default: []
})

export const dailyCommentAtom = atom<DailyCommentStruct | null>({
    key: 'housekeeping-modal-dailyCommentAtom',
    default: null
})

export const usersAtom = atom<UserStruct[]>({
    key: 'housekeeping-modal-usersAtom',
    default: []
})

export const bookingsAtom = atom<BookingStruct[]>({
    key: 'housekeeping-modal-bookingsAtom',
    default: []
})

export const activitiesAtom = atom<ActivityStruct[]>({
    key: 'housekeeping-modal-activitiesAtom',
    default: []
})

export const issuesAtom = atom<IssueStruct[]>({
    key: 'housekeeping-modal-issuesAtom',
    default: []
})

export const priorityAtom = atom<boolean>({
    key: 'housekeeping-modal-priorityAtom',
    default: false
})

export const ruleNameAtom = atom<string>({
    key: 'housekeeping-modal-ruleNameAtom',
    default: ''
})

export const activeRuleAtom = atom<RuleStruct | null>({
    key: 'housekeeping-modal-activeRuleAtom',
    default: null
})

export const dataLoadingAtom = atom<boolean>({
    key: 'housekeeping-modal-dataLoadingAtom',
    default: false
})

export const sameDateAsParentAtom = atom<boolean>({
    key: 'housekeeping-modal-sameDateAsParentAtom',
    default: false
})

export const lastCleaningTaskAtom = atom<LastCleaningTask | null>({
    key: 'housekeeping-modal-lastCleaningTask',
    default: null
})

export const cleaningAtom = atom<CleaningStruct | null>({
    key: 'housekeeping-modal-cleaningAtom',
    default: null
})
