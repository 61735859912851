import React, { useContext } from 'react'
import { SubscriptionContext } from '../../../Subscriptions/SubscriptionContext'
import { DateOptionType, FilterType } from './types'
import styled from 'styled-components'
import { red_sweeply, yellow_sweeply } from '@shared/colors'

type NavLinkProps = {
    active: boolean
}
export const NavLink = styled.a`
    font-weight: bold;
    color: ${({ active }: NavLinkProps) => (active ? `${red_sweeply} !important` : 'inherit')};
    border-bottom: ${({ active }: NavLinkProps) => (active ? `2px solid ${yellow_sweeply} !important` : 'inherit')};

    &:hover {
        color: ${red_sweeply} !important;
        border-bottom: 2px solid ${yellow_sweeply} !important;
    }
`
function DateRangeOptions({
    options,
    values,
    setFieldValue,
    handleSubmit
}: {
    options: DateOptionType[]
    values: FilterType['filter']
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
}) {
    const { hasAccess, setShowUpgradeModal } = useContext(SubscriptionContext)

    return (
        <ul className="nav nav-tabs nav-tabs-line d-flex">
            {options.map(x => (
                <li
                    key={x.dateFilter}
                    className="nav-item"
                    onClick={e => {
                        if (!hasAccess) {
                            e.preventDefault()
                            setShowUpgradeModal(true)
                        } else {
                            setFieldValue('dateFilter', x.dateFilter)
                            setFieldValue('startDate', x.startDate)
                            setFieldValue('endDate', x.endDate)
                            setFieldValue('dateFilterType', x.dateFilterType)
                            handleSubmit()
                        }
                    }}>
                    <NavLink
                        active={values.dateFilter === x.dateFilter}
                        className={`nav-link ${values.dateFilter == x.dateFilter ? 'active' : ''}`}>
                        {x.dateFilter}
                    </NavLink>
                </li>
            ))}
        </ul>
    )
}

export default DateRangeOptions
