import React, { ReactElement, useContext } from 'react'
import { Image, TouchableOpacity, View } from 'react-native-web'
import { Tooltip } from 'react-tippy'
import * as thelpers from './tooltips/tooltip-helpers'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'
import * as helpers from '@shared/helpers'
import { NavLink } from 'react-router-dom'
import { HoverableTextButton } from './buttons/hoverable-text-button'
import * as uc from '../utils/constants'

import { CloseModalButton } from './buttons/close-modal-button'
import { SubscriptionContext } from '../modules/Subscriptions/SubscriptionContext'
import { Subscription } from '@shared/subscriptions/subscription'
import { FeatureId } from '@shared/subscriptions.types'
import { ALL_FEATURES } from '@shared/subscriptions/feature-items'
import { AuthContext } from '../modules/Auth/AuthContext'
import { userIsAllowed } from '@shared/roles/roles'
import { RolePermissions } from '@shared/roles/roles-types'
import { useFeatureToggle } from '../features'

type SubscriptionCheckProps = {
    featureId: FeatureId
    permissionId?: RolePermissions
    // Invoked when the feature is available and the user has the permission to use it (if permissionId is provided)
    onAvailable: () => ReactElement | null
    // Invoked when the feature is not available
    onNotAvailable: (notAccessibleFeature: FeatureId | null, availableUpgrades: Subscription[]) => ReactElement | null
    noCheck?: boolean
}
export const SubscriptionCheck = ({ featureId, permissionId, onAvailable, onNotAvailable, noCheck = false }: SubscriptionCheckProps) => {
    const { currentSubscription, checkSubscription } = useContext(SubscriptionContext)
    const { user, organization } = useContext(AuthContext)
    const { isFeatureOn } = useFeatureToggle()

    if (noCheck) {
        return null
    }
    if (!currentSubscription) {
        return <span style={{ fontSize: 24, fontWeight: '500' }}>Null subscription!</span>
    }
    let availableUpgrades: Subscription[] = []

    let isAvailable = false

    checkSubscription(
        featureId,
        () => {
            isAvailable = true
        },
        (_notAccessibleFeature, availableUpgradesFromCheck) => {
            availableUpgrades = availableUpgradesFromCheck
        }
    )
    const permissionsFeature = isFeatureOn('roles-and-permissions')
    if (isAvailable) {
        if (permissionId && user) {
            if (permissionsFeature ? userIsAllowed(permissionId, user, organization!, currentSubscription!) : true) {
                return onAvailable()
            } else {
                return null
            }
        } else {
            return onAvailable()
        }
    } else if (availableUpgrades.length > 0) {
        if (permissionId && user) {
            if (permissionsFeature ? userIsAllowed(permissionId, user, organization!, currentSubscription!) : true) {
                return onNotAvailable(featureId, availableUpgrades)
            } else {
                return null
            }
        } else {
            return onNotAvailable(featureId, availableUpgrades)
        }
    } else return null
}

export const PermissionCheck = ({
    permissionId,
    onAllowed,
    onDenied
}: {
    permissionId: RolePermissions
    onAllowed: () => ReactElement | null
    onDenied?: () => ReactElement | null
}) => {
    const { user, organization } = useContext(AuthContext)
    const { currentSubscription } = useContext(SubscriptionContext)
    if (userIsAllowed(permissionId, user, organization!, currentSubscription!)) {
        return onAllowed()
    } else {
        return onDenied?.() ?? null
    }
}

type FeatureLockedOverlayProps = {
    notAccessibleFeature: FeatureId | null
    availableUpgrades: Subscription[]
    topMargin?: number
    showMessage?: boolean
    gradientPower?: string
    gradientFadeOutDirection?: string
    onClose?: () => void
}

export const FeatureLockedOverlay = ({
    notAccessibleFeature,
    availableUpgrades,
    topMargin,
    showMessage = true,
    gradientPower,
    gradientFadeOutDirection,
    onClose
}: FeatureLockedOverlayProps) => {
    const top_margin = topMargin ? topMargin : 50
    const gradient_power = gradientPower ? gradientPower : '70%'
    let gradient_fadeout_direction = '0deg'
    if (gradientFadeOutDirection) {
        if (gradientFadeOutDirection === 'right') {
            gradient_fadeout_direction = '-45deg'
        } else if (gradientFadeOutDirection === 'left') {
            gradient_fadeout_direction = '45deg'
        } else if (gradientFadeOutDirection === 'down') {
            gradient_fadeout_direction = '0deg'
        } else if (gradientFadeOutDirection === 'up') {
            gradient_fadeout_direction = '180deg'
        } else {
            gradient_fadeout_direction = '0deg'
        }
    }
    const linear_gradient = 'linear-gradient(' + gradient_fadeout_direction + ', #fff ' + gradient_power + ', rgba(0, 0, 0, 0) )'

    const disabled = !onClose

    return (
        <TouchableOpacity
            disabled={disabled}
            onPress={onClose}
            style={{
                borderWidth: 0,
                borderColor: 'red',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 3,
                width: '100%',
                height: '100%',
                // @ts-ignore
                background: linear_gradient
            }}>
            <View
                // @ts-ignore
                style={{
                    position: 'absolute',
                    top: '50%',
                    transform: [{ translateY: '-50%' }],
                    zIndex: 2,
                    marginTop: top_margin,
                    alignSelf: 'center',
                    alignItems: 'center'
                }}>
                {showMessage && <UpgradeMessageContent notAccessibleFeature={notAccessibleFeature} availableUpgrades={availableUpgrades} />}
            </View>
        </TouchableOpacity>
    )
}

type UpgradeMessageContentProps = {
    notAccessibleFeature: FeatureId | null
    availableUpgrades: { name: string }[]
    height?: number | string
}

export const UpgradeMessageContent = (
    props:
        | (UpgradeMessageContentProps & { showCloseButton?: false })
        | (UpgradeMessageContentProps & { onClose: () => void; showCloseButton: true })
) => {
    if (!props.notAccessibleFeature && !props.availableUpgrades) {
        return null
    }

    const featureTitle = props.notAccessibleFeature ? ALL_FEATURES[props.notAccessibleFeature].title : ''

    // const pluralOrNotPlans = props.availableUpgrades!.length > 1 ? 'plans' : 'plan'
    const side_to_content_padding = 35
    return (
        <View
            style={{
                borderRadius: 6,
                borderColor: colors.gentle_gray_spectaflow,
                borderWidth: 1,
                alignSelf: 'center',
                zIndex: 3,
                width: 320,
                height: props.height ? props.height : 430,
                backgroundColor: colors.white
            }}>
            <View
                style={{
                    marginTop: 16,
                    marginBottom: props.showCloseButton ? 16 : 0,
                    borderWidth: 0,
                    height: uc.EXTRA_SMALL_BUTTON_DIM,
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '100%',
                    paddingHorizontal: 20
                }}>
                {props.showCloseButton && <CloseModalButton dimension={14} style={{ zIndex: 3000 }} close={props.onClose} />}
            </View>
            <View style={{ alignItems: 'center', paddingHorizontal: side_to_content_padding }}>
                <View style={{ borderWidth: 0, borderColor: 'red', width: '100%' }}>
                    <View style={{ position: 'absolute', top: -15 }}>
                        <FeatureLockedSignal justifyContent={'flex-start'} isActive={false} />
                    </View>

                    <span style={{ fontSize: featureTitle.length > 20 ? 22 : 24, fontWeight: '500' }}>{featureTitle}</span>
                </View>
                <Image style={{ marginTop: 24, width: 134, height: 111 }} source={require('../img/feature-lock.svg')} />
                <span
                    style={{
                        lineHeight: 1.25,
                        textAlign: 'center',
                        marginTop: 24,
                        fontSize: 16,

                        color: colors.textGraySecondary
                    }}>
                    <span style={{ lineHeight: 2 }}>
                        Try our {renderAvailbleUpgrades(props.availableUpgrades!)} subscription for 30 days. No credit card needed!
                    </span>
                </span>
                <View
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 24
                    }}>
                    <NavLink to={'/settings/account/subscription'}>
                        <HoverableTextButton text={'Learn more'} className="btn btn-warning" />
                    </NavLink>
                </View>
            </View>
        </View>
    )
}

export const renderAvailbleUpgrades = (availableUpgrades: { name: string }[]) => {
    return (
        <span>
            {availableUpgrades.map((upgrade, index) => {
                let postFix
                if (availableUpgrades.length > 1 && index !== availableUpgrades.length - 1) {
                    if (index === availableUpgrades.length - 2) {
                        postFix = <span style={{ color: iOSColors.gray }}>, and </span>
                    } else {
                        postFix = <span style={{ color: iOSColors.gray }}>, </span>
                    }
                }
                return (
                    <span key={upgrade.name} style={{ color: colors.blue_spectaflow, fontWeight: '700' }}>
                        {helpers.capitalizeFirstLetter(upgrade.name)}
                        {postFix}
                    </span>
                )
            })}
        </span>
    )
}

type FeatureLockedSignalProps = {
    lockSide?: string
    showLock?: boolean
    onClick?: () => void
    isActive?: boolean
    justifyContent?: string
}
export const FeatureLockedSignal = ({
    lockSide,
    showLock,
    onClick,
    isActive = true,
    justifyContent = 'center'
}: FeatureLockedSignalProps) => {
    const side = lockSide ? lockSide : 'right'
    const margin = 6

    const img_width = 12
    const img_height = 16.8

    const onClickCondition = () => {
        if (onClick) {
            return (
                <div onClick={onClick} className="cursor-pointer">
                    <Content />
                </div>
            )
        } else {
            return (
                <NavLink to={'/settings/account/subscription'}>
                    <Content />
                </NavLink>
            )
        }
    }

    const Content = () => (
        <View
            // @ts-ignore
            style={{
                width: 100,
                borderWidth: 0,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: justifyContent
            }}>
            {showLock && side === 'left' && (
                <img style={{ marginRight: margin, width: img_width, height: img_height }} src={require('../img/feature-lock-small.svg')} />
            )}
            <span style={{ marginTop: 2, fontSize: 11, fontWeight: '600', color: iOSColors.orange }}>PRO FEATURE </span>
            {showLock && side === 'right' && (
                <img style={{ marginLeft: margin, width: img_width, height: img_height }} src={require('../img/feature-lock-small.svg')} />
            )}
        </View>
    )
    return (
        // @ts-ignore
        <Tooltip
            disabled={!isActive}
            html={thelpers.getDefaultTooltip(['Click to learn more'], iOSColors.orange)}
            theme="transparent"
            delay={thelpers.TOOLTIP_DELAY}
            position="top"
            trigger="mouseenter"
            interactiveBorder={0}>
            {isActive && onClickCondition()}
            {!isActive && <Content />}
        </Tooltip>
    )
}
