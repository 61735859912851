/* eslint-disable no-restricted-imports */
import React, { useMemo, useContext } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import objectPath from 'object-path'
import { useHtmlClassService } from '../../../_core/MetronicLayout'
import { toAbsoluteUrl } from '../../../../_helpers'
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'
import { AuthContext } from '../../../../../app/modules/Auth/AuthContext'
import * as brand from '@shared/brand'

export function UserProfileDropdown() {
    const { user, organization } = useContext(AuthContext)
    const uiService = useHtmlClassService()
    const layoutProps = useMemo(() => {
        return {
            light: objectPath.get(uiService.config, 'extras.user.dropdown.style') === 'light'
        }
    }, [uiService])

    return (
        <Dropdown drop="down" alignRight>
            <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
                <div className={'btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 line-height-md'}>
                    <div className="d-flex flex-row">
                        <div className="">
                            <div className="d-flex flex-row-reverse">
                                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{user.name}</span>
                            </div>

                            <div className=" float-right text-muted font-weight-bold font-size-sm d-none d-md-inline mr-3 mt-1">
                                {organization.name}
                            </div>
                        </div>

                        <span className="symbol symbol-circle symbol-35">
                            <span
                                className="symbol-label font-size-base font-weight-bolder text-white"
                                style={{ backgroundColor: brand.getBrand().navBarColor }}>
                                {user.initials}
                            </span>
                        </span>
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                <>
                    {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
                    {layoutProps.light && (
                        <>
                            <div className="d-flex align-items-center p-8 rounded-top">
                                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                                    <img src={toAbsoluteUrl('/media/users/300_21.jpg')} alt="" />
                                </div>
                                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">{user.name}</div>
                                <span className="label label-light-success label-lg font-weight-bold label-inline">3 messages</span>
                            </div>
                            <div className="separator separator-solid" />
                        </>
                    )}

                    {!layoutProps.light && (
                        <div
                            className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
                            style={{
                                backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`
                            }}>
                            <span className="symbol symbol-circle symbol-35 mr-3">
                                <span
                                    className="symbol-label font-size-base font-weight-bolder text-white"
                                    style={{ backgroundColor: brand.getBrand().navBarColor }}>
                                    {user.initials}
                                </span>
                            </span>
                            <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">{user.name}</div>
                        </div>
                    )}
                </>

                <div className="navi navi-spacer-x-0">
                    <div className="navi-separator mt-3" />

                    <div className="navi-footer  px-8 py-5">
                        <Link to="/logout" className="btn btn-light-specta-main font-weight-bold">
                            Log Out
                        </Link>
                        <Link to="/settings/account/subscription" className="btn btn-light-specta-gold font-weight-bold">
                            Upgrade Plan
                        </Link>
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}
