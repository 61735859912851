import { RefObject, useEffect, useState } from 'react'

interface ElementOffsetSize {
    offsetWidth: number
    offsetHeight: number
}

export function useElementOffsetSize(ref: RefObject<HTMLElement>): ElementOffsetSize {
    const [offsetWidth, setOffsetWidth] = useState(0)
    const [offsetHeight, setOffsetHeight] = useState(0)

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            const { offsetWidth, offsetHeight } = entries[0].target as HTMLElement
            setOffsetWidth(offsetWidth)
            setOffsetHeight(offsetHeight)
        })

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current)
            }
        }
    }, [ref])

    return { offsetWidth, offsetHeight }
}
