import React, { useMemo, useContext } from 'react'
import { Formik } from 'formik'
import { isEqual } from 'lodash'
import { UsersContext } from '../../UsersContext'
import { SearchInput } from '../../../../components/forms/SearchInput'

const prepareFilter = (queryParams, values) => {
    const { status, group, synced, searchText } = values
    const newQueryParams = { ...queryParams }
    const filter = {}
    // Filter by status

    filter.status = status !== '' ? status : ''
    filter.group = group !== '' ? group : ''
    filter.synced = synced !== '' ? synced : ''

    if (group === 'All') {
        filter.group = ''
    }
    // Filter by type
    // filter.type = type !== "" ? +type : undefined;
    // Filter by all fields
    filter.name = searchText
    filter.description = searchText
    if (searchText) {
        filter.name = searchText
    }
    newQueryParams.filter = filter
    return newQueryParams
}

export function UsersFilter() {
    // Customers UI Context
    const { allUsers, queryParams, setQueryParams } = useContext(UsersContext)
    // const customersUIContext = useCustomersUIContext();
    const customersUIProps = useMemo(() => {
        return {
            queryParams: queryParams,
            setQueryParams: setQueryParams
        }
    }, [queryParams, setQueryParams])

    let groups = allUsers.map(a => a.group).filter((x, i, a) => a.indexOf(x) === i)

    groups.unshift('All')

    // queryParams, setQueryParams,
    const applyFilter = values => {
        const newQueryParams = prepareFilter(customersUIProps.queryParams, values)
        if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
            // update list by queryParams
            customersUIProps.setQueryParams(newQueryParams)
        }
    }

    return (
        <>
            <Formik
                initialValues={{
                    status: '', // values => All=""/Susspended=0/Active=1/Pending=2
                    // type: "", // values => All=""/Business=0/Individual=1
                    searchText: '',
                    group: '',
                    synced: ''
                }}
                onSubmit={values => {
                    applyFilter(values)
                }}>
                {({ handleSubmit, handleBlur, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="form form-label-right">
                        <div className="form-group row">
                            <div className={'col-lg-4'}>
                                <SearchInput
                                    handleBlur={handleBlur}
                                    handleSubmit={handleSubmit}
                                    setFieldValue={setFieldValue}
                                    fieldValueToSet="searchText"
                                    placeholderText="Name, email, phone number and more"
                                    initValue=""
                                />
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}
