import React from 'react'
import { useRecoilValue } from 'recoil'
import { reportSummaryByUserHeaders } from '../atoms.js'

const SummaryByUser = ({ summaryByUser }) => {
    const headers = useRecoilValue(reportSummaryByUserHeaders)

    const mapHeaders = headers.map((header, index) => <th key={header.key + index}>{header.label}</th>)

    const mapRows = summaryByUser.map((row, index) => {
        const { type, count, averageTime } = row

        return (
            <tr key={type + index} className="bg-gray-100">
                <td></td>
                <td>{type}</td>
                <td>{count}</td>

                <td>{averageTime}</td>
                <td></td>
            </tr>
        )
    })

    return (
        <>
            <tr className="bg-gray-200">
                <th></th>
                {mapHeaders}
                <th></th>
            </tr>
            {mapRows}
        </>
    )
}

export default SummaryByUser
