import styled from 'styled-components'
import * as colors from '@shared/colors'

interface ColumnHeaderWrapProps {
    borderColor: string
}

interface MinimizeButtonProps {
    isMinimized: boolean
}

export const ColumnHeaderWrap = styled.div`
    display: flex;
    border-bottom: 2px solid ${({ borderColor }: ColumnHeaderWrapProps) => borderColor};
    padding-bottom: 6px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`

export const TitleWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Title = styled.div`
    margin-right: 4px;
    font-size: 1.231rem;
    font-weight: 600;
    color: ${colors.black_sweeply};
`
export const Count = styled.div`
    margin-top: 2px;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.black_sweeply};
`
export const MinimizeButton = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    > i {
        margin-top: 3px;
        color: ${colors.black_sweeply};
        margin-bottom: 1;
        font-size: 12px;
        transform: ${({ isMinimized }: MinimizeButtonProps) => (isMinimized ? 'rotate(90deg)' : 'rotate(0deg)')};
    }
`
