import React from 'react'
import { Tooltip } from '../components/misc/Tooltip'

import { View, TouchableOpacity } from 'react-native-web'

export const Workload = props => {
    const counts = props.counts
    const filters = props.filters
    if (!counts) {
        return null
    }
    const otherWorkCount = counts.otherWorkCount
    const unclean100Count = counts.unclean100Count
    const unclean80Count = counts.unclean80Count
    const unclean50Count = counts.unclean50Count
    const checkInCount = counts.checkInCount
    const noServiceCount = counts.noServiceCount

    let isOtherWorkActive
    let isUnclean100Active
    let isUnclean80Active
    let isUnclean50Active
    let isCheckInActive
    let isNoServiceActive

    if (filters) {
        isOtherWorkActive = filters.isOtherWorkActive
        isUnclean100Active = filters.isUnclean100Active
        isUnclean80Active = filters.isUnclean80Active
        isUnclean50Active = filters.isUnclean50Active
        isCheckInActive = filters.isCheckInActive
        isNoServiceActive = filters.isNoServiceActive
    }

    const main_color = props.color ? props.color : 'purple'
    const filter_color = props.filterColor ? props.filterColor : 'purple'

    const opacity = props.opacity ? props.opacity : 1
    const font_size = props.fontSize ? props.fontSize : 15
    const font_weight = props.fontWeight ? props.fontWeight : '400'
    const broom_font_size = font_size + 3
    const other_work_font_size = font_size + 2
    const checkIn_font_size = font_size + 4
    const leaf_font_size = font_size

    const isUnclean100Disabled = props.on100Press ? false : true
    const isUnclean80Disabled = props.on80Press ? false : true
    const isUnclean50Disabled = props.on50Press ? false : true
    const isOtherDisabled = props.onOtherPress ? false : true
    const isCheckInDisabled = props.onCheckInPress ? false : true
    const isNoServiceDisabled = props.onNoServicePress ? false : true

    const tooltipText = props.tooltipDisabled ? undefined : 'Filter by workload'

    const icon_margin = props.iconMargin ? props.iconMargin : 4
    let item_margin = props.itemMargin ? props.itemMargin : 7

    const item_width = props.itemWidth ? props.itemWidth : undefined
    const item_padding = props.itemPadding ? props.itemPadding : undefined

    const render_empty_slots = props.renderEmptySlots ? props.renderEmptySlots : false

    const disabled = props.disabled === true ? true : false

    if (item_width) {
        item_margin = undefined
    }

    const DEBUG_BORDER = 0

    return (
        <Tooltip text={tooltipText} position={'top'}>
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    opacity: opacity
                }}>
                {(checkInCount > 0 || render_empty_slots) && (
                    <TouchableOpacity
                        disabled={disabled || isCheckInDisabled}
                        onPress={props.onCheckInPress}
                        style={{
                            borderWidth: DEBUG_BORDER,
                            marginLeft: 0,
                            flexDirection: 'row',

                            width: item_width,
                            paddingHorizontal: item_padding
                        }}>
                        {checkInCount > 0 && (
                            <View
                                style={{
                                    width: '100%',
                                    borderWidth: DEBUG_BORDER,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <i
                                    className="specta-check-in"
                                    style={{
                                        marginTop: 2,
                                        fontSize: checkIn_font_size,
                                        color: isCheckInActive ? filter_color : main_color
                                    }}
                                />
                                <span
                                    style={{
                                        marginLeft: icon_margin,
                                        paddingBottom: 0,
                                        fontWeight: font_weight,
                                        fontSize: font_size,
                                        color: isCheckInActive ? filter_color : main_color
                                    }}>
                                    {checkInCount}
                                </span>
                            </View>
                        )}
                    </TouchableOpacity>
                )}
                {(unclean100Count > 0 || render_empty_slots) && (
                    <TouchableOpacity
                        disabled={disabled || isUnclean100Disabled}
                        onPress={props.on100Press}
                        style={{
                            borderWidth: DEBUG_BORDER,
                            marginLeft: checkInCount > 0 ? item_margin : 0,
                            flexDirection: 'row',

                            width: item_width,
                            paddingHorizontal: item_padding
                        }}>
                        {unclean100Count > 0 && (
                            <View
                                style={{
                                    width: '100%',
                                    borderWidth: DEBUG_BORDER,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <i
                                    className="specta-suitcase"
                                    style={{ fontSize: font_size, color: isUnclean100Active ? filter_color : main_color }}
                                />
                                <span
                                    style={{
                                        marginLeft: icon_margin,
                                        paddingBottom: 0,
                                        fontWeight: font_weight,
                                        fontSize: font_size,
                                        color: isUnclean100Active ? filter_color : main_color
                                    }}>
                                    {unclean100Count}
                                </span>
                            </View>
                        )}
                    </TouchableOpacity>
                )}
                {(unclean80Count > 0 || render_empty_slots) && (
                    <TouchableOpacity
                        onPress={props.on80Press}
                        disabled={disabled || isUnclean80Disabled}
                        style={{
                            borderWidth: DEBUG_BORDER,
                            marginLeft: checkInCount > 0 || unclean100Count > 0 ? item_margin : 0,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: item_width,
                            paddingHorizontal: item_padding
                        }}>
                        {unclean80Count > 0 && (
                            <View
                                style={{
                                    width: '100%',
                                    borderWidth: DEBUG_BORDER,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <i
                                    className="specta-bed"
                                    style={{ marginTop: 2, fontSize: font_size, color: isUnclean80Active ? filter_color : main_color }}
                                />

                                <span
                                    style={{
                                        marginLeft: icon_margin,
                                        paddingBottom: 0,
                                        fontWeight: font_weight,
                                        fontSize: font_size,
                                        color: isUnclean80Active ? filter_color : main_color
                                    }}>
                                    {unclean80Count}
                                </span>
                            </View>
                        )}
                    </TouchableOpacity>
                )}
                {(unclean50Count > 0 || render_empty_slots) && (
                    <TouchableOpacity
                        onPress={props.on50Press}
                        disabled={disabled || isUnclean50Disabled}
                        style={{
                            borderWidth: DEBUG_BORDER,
                            marginLeft: checkInCount > 0 || unclean100Count > 0 || unclean80Count > 0 ? item_margin - 1 : 0,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: item_width,
                            paddingHorizontal: item_padding
                        }}>
                        {unclean50Count > 0 && (
                            <View
                                style={{
                                    width: '100%',
                                    borderWidth: DEBUG_BORDER,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <i
                                    className="specta-broom"
                                    style={{ fontSize: broom_font_size, color: isUnclean50Active ? filter_color : main_color }}
                                />
                                <span
                                    style={{
                                        borderWidth: DEBUG_BORDER,
                                        marginLeft: icon_margin,
                                        paddingBottom: 0,
                                        fontWeight: font_weight,
                                        fontSize: font_size,
                                        color: isUnclean50Active ? filter_color : main_color
                                    }}>
                                    {unclean50Count}
                                </span>
                            </View>
                        )}
                    </TouchableOpacity>
                )}
                {/* No Service */}
                {(noServiceCount > 0 || render_empty_slots) && (
                    <TouchableOpacity
                        onPress={props.on50Press}
                        disabled={disabled || isNoServiceDisabled}
                        style={{
                            borderWidth: DEBUG_BORDER,
                            marginLeft:
                                checkInCount > 0 || unclean100Count > 0 || unclean80Count > 0 || unclean50Count > 0 ? item_margin - 1 : 0,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: item_width,
                            paddingHorizontal: item_padding
                        }}>
                        {noServiceCount > 0 && (
                            <View
                                style={{
                                    width: '100%',
                                    borderWidth: DEBUG_BORDER,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <i
                                    className="fas fa-leaf"
                                    style={{ fontSize: leaf_font_size, color: isNoServiceActive ? filter_color : main_color }}
                                />
                                <span
                                    style={{
                                        borderWidth: DEBUG_BORDER,
                                        marginLeft: icon_margin,
                                        paddingBottom: 0,
                                        fontWeight: font_weight,
                                        fontSize: font_size,
                                        color: isNoServiceActive ? filter_color : main_color
                                    }}>
                                    {noServiceCount}
                                </span>
                            </View>
                        )}
                    </TouchableOpacity>
                )}
                {(otherWorkCount > 0 || render_empty_slots) && (
                    <TouchableOpacity
                        onPress={props.onOtherPress}
                        disabled={disabled || isOtherDisabled}
                        style={{
                            borderWidth: DEBUG_BORDER,
                            marginLeft:
                                checkInCount > 0 || unclean50Count > 0 || unclean100Count > 0 || unclean80Count > 0 || noServiceCount > 0
                                    ? item_margin
                                    : 0,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: item_width,
                            paddingHorizontal: item_padding
                        }}>
                        {otherWorkCount > 0 && (
                            <View
                                style={{
                                    width: '100%',
                                    borderWidth: DEBUG_BORDER,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <i
                                    className="specta-workload"
                                    style={{
                                        marginTop: 1,
                                        fontSize: other_work_font_size,
                                        color: isOtherWorkActive ? filter_color : main_color
                                    }}
                                />
                                <span
                                    style={{
                                        marginLeft: icon_margin,
                                        paddingBottom: 0,
                                        fontWeight: font_weight,
                                        fontSize: font_size,
                                        color: isOtherWorkActive ? filter_color : main_color
                                    }}>
                                    {otherWorkCount}
                                </span>
                            </View>
                        )}
                    </TouchableOpacity>
                )}
            </View>
        </Tooltip>
    )
}
