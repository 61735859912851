import styled from 'styled-components'
import * as colors from '@shared/colors'
import { getBrand } from '@shared/brand'
import { MOBILE_BREAKPOINT, SMALL_DESKTOP_SCREEN_HEIGHT } from '../../../utils/constants'

const black = colors.light_black
const blue = colors.medium_blue
const brandColor = getBrand().navBarColor

const windowHeightListener = callback => {
    const matchMedia = window.matchMedia(`(max-height: ${SMALL_DESKTOP_SCREEN_HEIGHT}px)`)
    matchMedia.addEventListener('change', () => callback(matchMedia))
}

export const SelectWrapper = styled.div`
    width: 100%;
    padding-top: 3px;
    margin-bottom: 32px;

    > label {
        font-weight: 700;
        font-size: 1.077rem;
        line-height: 18px;
        color: ${black};
        margin-bottom: 9px;
    }

    @media (max-height: ${SMALL_DESKTOP_SCREEN_HEIGHT}px) {
        margin-bottom: 20px;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        input {
            font-size: 1.077rem;
        }
    }
`

export const selectInputStyles = {
    control: provided => {
        let height

        const handleHeight = media => (media.matches ? (height = 64) : (height = 74))
        windowHeightListener(handleHeight)

        return {
            ...provided,
            cursor: 'pointer',
            border: `2px solid ${blue}`,
            borderRadius: 8,
            paddingLeft: 25,
            boxShadow: 'none',
            fontWeight: 450,
            fontSize: '1.231rem',
            color: black,
            height,

            '&:hover': {
                border: `2px solid ${blue}`
            }
        }
    },

    valueContainer: provided => ({
        ...provided,
        width: '100%',
        height: '74px !important;',
        padding: 0
    }),

    input: () => ({
        position: 'relative',
        left: 0,
        top: 0,
        fontWeight: 450,
        fontSize: '1.231rem',
        padding: 0,
        color: black
    }),

    singleValue: provided => ({
        ...provided,
        position: 'absolute'
    }),

    placeholder: provided => ({
        ...provided,
        fontWeight: 450,
        fontSize: '1.231rem',
        lineHeight: '24px',
        color: black,
        opacity: 0.5
    }),

    indicatorsContainer: () => ({
        display: 'none'
    }),

    menuList: provided => {
        const maxHeight = window.innerHeight >= SMALL_DESKTOP_SCREEN_HEIGHT ? 500 : 300
        return { ...provided, maxHeight, height: 'auto' }
    },

    menu: provided => ({ ...provided, borderRadius: 8, zIndex: 2, right: '-14%' }),

    option: (provided, state) => {
        const { isSelected } = state
        const backgroundColor = isSelected ? brandColor : 'transparent'
        const backgroundColorHover = state.isSelected ? brandColor : colors.hex2rgb(blue, 0.2)
        const color = isSelected ? colors.white : black
        const fontWeight = isSelected ? 700 : 450
        let height

        const handleHeight = media => (media.matches ? (height = 40) : (height = 50))
        windowHeightListener(handleHeight)

        return {
            ...provided,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            fontSize: '1.077rem',
            backgroundColor,
            color,
            fontWeight,
            height,

            '&:hover': {
                backgroundColor: backgroundColorHover
            }
        }
    }
}
