import { selector } from 'recoil'
import { allTasksAtom, taskboardContextAtom } from '../atoms'
import { sortTasks } from '@shared/task-helpers'
import * as c from '@shared/txt-constants'
import { TaskboardContext } from '@shared/traces-types'
import moment from 'moment-timezone'
import { selectedDateNumberSelector } from './selectedDateNumber'
import { TaskStruct } from '@shared/firestore-structs'

interface DividedTasks {
    allTasks: TaskStruct[]
    dueTodayTasks: TaskStruct[]
    assignedTasks: TaskStruct[]
    noDueDateTasks: TaskStruct[]
    doneTasks: TaskStruct[]
    overdueDueTodayTasks: TaskStruct[]
    overdueAssignedTasks: TaskStruct[]
}

export const dividedTasksSelector = selector<DividedTasks>({
    key: 'taskboard-dividedTasksSelector',
    get: ({ get }) => {
        const allTasks = get(allTasksAtom)
        const selectedDateNumber = get(selectedDateNumberSelector)
        const context = get(taskboardContextAtom)

        const dueTodayTasks = filterActualTasks(allTasks, c.TASK_OPEN, selectedDateNumber, context)
        const assignedTasks = filterActualTasks(allTasks, c.TASK_ASSIGNED, selectedDateNumber, context)
        const overdueDueTodayTasks = filterOverdueTasks(allTasks, c.TASK_OPEN, selectedDateNumber)
        const overdueAssignedTasks = filterOverdueTasks(allTasks, c.TASK_ASSIGNED, selectedDateNumber)
        const noDueDateTasks = filterNoDueDateTasks(allTasks)
        const doneTasks = allTasks.filter(
            (task: TaskStruct) =>
                task.status === c.TASK_COMPLETE &&
                ((task.type !== c.TASK_TYPE_GENERAL && task.completedDate === selectedDateNumber) ||
                    (task.type === c.TASK_TYPE_GENERAL && sameDayAsSelected(task.updated)))
        )

        ;[dueTodayTasks, assignedTasks, doneTasks, overdueDueTodayTasks, overdueAssignedTasks, noDueDateTasks].forEach(tasks =>
            tasks.sort(sortTasks)
        )

        return {
            dueTodayTasks,
            assignedTasks,
            overdueDueTodayTasks,
            overdueAssignedTasks,
            noDueDateTasks,
            doneTasks,
            allTasks: [
                ...dueTodayTasks,
                ...assignedTasks,
                ...doneTasks,
                ...overdueDueTodayTasks,
                ...overdueAssignedTasks,
                ...noDueDateTasks
            ]
        }
    }
})

function sameDayAsSelected(date: number) {
    return moment(date).startOf('day').isSame(date, 'day')
}

function filterActualTasks(tasks: TaskStruct[], status: TaskStruct['status'], date: number, context: TaskboardContext): TaskStruct[] {
    if (context === TaskboardContext.RESERVATIONS) {
        return tasks.filter(task => task.status === status && task.startDate !== null && task.startDate >= date)
    }

    return tasks.filter(task => task.status === status && task.startDate !== null && task.startDate === date)
}

function filterOverdueTasks(tasks: TaskStruct[], status: TaskStruct['status'], date: number): TaskStruct[] {
    if (date > moment().startOf('day').valueOf()) return []
    return tasks.filter(task => task.type !== c.TASK_TYPE_HOUSEKEEPING && task.startDate && task.startDate < date && task.status === status)
}

function filterNoDueDateTasks(tasks: TaskStruct[]): TaskStruct[] {
    /* Temporary disable the no due date section. DON'T DELETE */
    return []
    return tasks.filter(task => task.startDate === null)
}
