import styled from 'styled-components'
import { black_sweeply, blue_sweeply, gray_sweeply, milk_gray, red_sweeply, white, yellow_sweeply } from '@shared/colors'
import { getBrand } from '@shared/brand'

const brandColor = getBrand().navBarColor
const getButtonColor = (type?: string, disabled?: boolean) => {
    switch (type) {
        case 'primary':
            return brandColor
        case 'secondary':
            return gray_sweeply
        case 'danger':
            if (disabled) {
                return gray_sweeply
            }
            return white
        case 'info':
            return blue_sweeply
        default:
            return brandColor
    }
}

const getBorder = (type?: string, disabled?: boolean) => {
    switch (type) {
        case 'primary':
            return 'none'
        case 'danger':
            if (disabled) {
                return 'none'
            }
            return `1px solid ${yellow_sweeply}`
        default:
            return 'none'
    }
}

const getFontColor = (type?: string, disabled?: boolean) => {
    switch (type) {
        case 'danger':
            if (disabled) {
                return 'rgb(33, 30, 28, 0.65)'
            }
            return red_sweeply
        default:
            return white
    }
}

const getFontSize = (size: ButtonSize) => {
    switch (size) {
        case 'small':
            return '0.75rem'
        case 'medium':
            return '1rem'
        case 'large':
            return '1.25rem'
        case 'xl':
            return '1.5rem'
        default:
            return '1rem'
    }
}

const getButtonSize = (size: ButtonSize) => {
    switch (size) {
        case 'small':
            return '36px'
        case 'medium':
            return '40px'
        case 'large':
            return '40px'
        case 'xl':
            return '48px'
        default:
            return '40px'
    }
}

export type ButtonType = 'primary' | 'secondary' | 'danger' | 'info'
export type ButtonSize = 'small' | 'medium' | 'large' | 'xl'

interface ButtonWrapProps {
    type?: ButtonType
    size?: ButtonSize
    disabled?: boolean
}

export const MainButtonWrap = styled.div`
    > button {
        height: ${({ size }: ButtonWrapProps) => getButtonSize(size || 'medium')};
        border-radius: 4px;
        font-size: ${({ size }: ButtonWrapProps) => getFontSize(size || 'medium')};
        background-color: ${({ type, disabled }: ButtonWrapProps) => getButtonColor(type, disabled)};
        border: ${({ type, disabled }: ButtonWrapProps) => getBorder(type, disabled)};
        color: ${({ type, disabled }: ButtonWrapProps) => getFontColor(type, disabled)};
        &:hover,
        &:focus {
            box-shadow: ${({ type, disabled }: ButtonWrapProps) =>
                type === 'danger' || disabled ? 'none' : 'inset 0 0 9px 9px rgba(0, 0, 0, 0.05);'};
            transform: ${({ type, disabled }: ButtonWrapProps) => (type === 'danger' && !disabled ? 'scale(1.1);' : 'none')};
        }
        &:disabled {
            background-color: ${({ type, disabled }: ButtonWrapProps) => getButtonColor(type, disabled)};
            opacity: 0.65;
        }
    }
`
