import React, { useMemo, useState } from 'react'
import { InfoWrap, WorkloadAndQuickGuideWrap, Wrapper, quickGuideStyle } from './style'
import { TitleWrap } from 'app/components/styled-components/text'
import AssignActionBarButton from './AssignActionBar/AssignActionBarButton'
import { useRecoilValue } from 'recoil'
import { filteredAreasSelector } from '../state/selectors/areasGroups'
import { Workload } from 'app/components/workload'
import { iOSColors } from '@shared/react-native-typography'
import { workloadCountsSelector } from '../state/selectors/areaRelatedData'
import ButtonIcon from 'app/components/buttons/ButtonIcon'
import QuickGuideIcon from 'app/img/quick-guide/quick-guide-button.svg?react'
import Modal from 'react-modal'
import { QuickGuide } from 'app/components/quick-guide'

function Header() {
    const [quickGuideModalIsOpen, setQuickGuideModalIsOpen] = useState(false)

    const filteredAreas = useRecoilValue(filteredAreasSelector)
    const workloadCounts = useRecoilValue(workloadCountsSelector)

    const workloadCountsToDisplay = useMemo(() => {
        const counts = { ...workloadCounts }
        delete counts.otherWorkCount
        return counts
    }, [workloadCounts])

    return (
        <>
            {quickGuideModalIsOpen && (
                <Modal
                    isOpen={quickGuideModalIsOpen}
                    style={quickGuideStyle}
                    contentLabel="QuickGuide"
                    onRequestClose={() => setQuickGuideModalIsOpen(false)}>
                    <QuickGuide close={() => setQuickGuideModalIsOpen(false)} />
                </Modal>
            )}
            <Wrapper>
                <InfoWrap>
                    <TitleWrap>
                        <h1>Housekeeping</h1>
                        <div>
                            <h3>{`(${filteredAreas.length})`}</h3>
                        </div>
                    </TitleWrap>

                    <WorkloadAndQuickGuideWrap>
                        <Workload
                            fontSize={15}
                            fontWeight={'500'}
                            color={iOSColors.black}
                            opacity={0.6}
                            counts={workloadCountsToDisplay}
                            tooltipDisabled={true}
                        />

                        <ButtonIcon size={22} tooltipText="Quick Guide" onClick={() => setQuickGuideModalIsOpen(true)}>
                            <QuickGuideIcon />
                        </ButtonIcon>
                    </WorkloadAndQuickGuideWrap>
                </InfoWrap>

                <AssignActionBarButton />
            </Wrapper>
        </>
    )
}

export default Header
