import React, { useEffect, useState, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment-timezone'
import firebase from '../../../utils/firebase'

import { TaskEditForm } from './TaskEditForm'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import * as c from '@shared/constants'
import { ModalHeader } from '../../../components/modals/ModalHeader'
import * as helpers from '@shared/helpers'
import Spinner from '../../../components/Spinner'
import { createTask, setTaskUpdate } from '@shared/task-data'
import { TaskboardContext } from '@shared/traces-types'
import { useRecoilValue } from 'recoil'
import { taskboardContextAtom } from 'app/tasks/state/atoms'
import { integrationParamsAtom } from 'app/traces/state/atoms'
import { clone } from 'ramda'
import withErrorCatching from 'app/utils/hocs/withErrorCatching'
import { setLastAssignee } from 'app/utils/helpers'
import { convertTimestampToLocalDateObject } from 'app/utils/formatters'

//TODO - refactor to the typescript
function TaskEditDialog({ task, show, onHide, areas, users, catchError }) {
    const initTask = {
        name: '',
        startDate: moment().toDate(),
        status: c.TASK_OPEN,
        type: c.TASK_TYPE_GENERAL,
        priority: false
    }

    const { user } = useContext(AuthContext)

    const taskboardContext = useRecoilValue(taskboardContextAtom)
    const integrationParams = useRecoilValue(integrationParamsAtom)

    const [isLoading, setIsLoading] = useState(false)
    const [actionsLoading, setActionsLoading] = useState(false)
    const [taskForEdit, setTaskForEdit] = useState(initTask)
    const [areasOptions, setAreasOptions] = useState([])
    const [usersOptions, setUsersOptions] = useState([])
    const [title, setTitle] = useState('New Task')

    useEffect(() => {
        initialize()
    }, [task?.key])

    function initialize() {
        setIsLoading(true)

        getData()

        if (task) {
            setTitle('Edit Task')
            setTaskForEdit({ ...task, startDate: convertTimestampToLocalDateObject(task.startDate) })
        }

        if (taskboardContext === TaskboardContext.RESERVATIONS) {
            initTask.area = areas[0]
        }

        setIsLoading(false)
    }

    function getData() {
        const areasOptions = areas.map(area => helpers.formatAreaToOption(area)).sort((a, b) => helpers.sortByName(a.label, b.label))
        const usersOptions = users.map(user => helpers.formatUserToOption(user)).sort((a, b) => helpers.sortByName(a.label, b.label))

        setAreasOptions(areasOptions)
        setUsersOptions(usersOptions)
    }

    async function saveTask(task) {
        const updatedTask = clone(task)
        const area = areas.find(area => area.key === task.areaKey)
        if (task.assignedTo && task.assignedTo.length > 0) {
            setLastAssignee(task.assignedTo)
        }

        setActionsLoading(true)

        if (taskboardContext === TaskboardContext.RESERVATIONS) {
            updatedTask.reservationId = integrationParams.reservationId
            updatedTask.propKey = integrationParams.propKey
            updatedTask.propName = integrationParams.propName
            updatedTask.pmsLinkUrl = integrationParams.pmsLinkUrl
            if (integrationParams.areaKey) {
                updatedTask.areaKey = integrationParams.areaKey
            } else {
                delete updatedTask.areaKey
                delete updatedTask.area
            }
        }

        if (taskboardContext === TaskboardContext.PROPERTY) {
            updatedTask.propKey = integrationParams.propKey
        }
        updatedTask.assignedTo = updatedTask.assignedTo ?? []

        try {
            task.key
                ? await setTaskUpdate(firebase, user, task.key, {
                      name: updatedTask.name,
                      startDate: updatedTask.startDate,
                      assignedTo: updatedTask.assignedTo,
                      priority: updatedTask.priority
                  })
                : await createTask(firebase, area, user, c.TASK_TYPE_GENERAL, {
                      name: updatedTask.name,
                      startDate: updatedTask.startDate,
                      assignedTo: updatedTask.assignedTo,
                      priority: updatedTask.priority,
                      reservationId: updatedTask.reservationId,
                      propName: updatedTask.propName,
                      propKey: updatedTask.propKey,
                      pmsLinkUrl: updatedTask.pmsLinkUrl,
                      areaKey: updatedTask.areaKey
                  })
            setActionsLoading(false)
            onHide()
        } catch (error) {
            setActionsLoading(false)
            onHide()
            console.error(error)
            catchError(error)
        }
    }

    return (
        <Modal size="lg" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg" centered>
            {isLoading && (
                <div className="w-100 h-100">
                    <Spinner style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 1500 }} />
                </div>
            )}
            <ModalHeader title={title} onHide={onHide}></ModalHeader>
            <TaskEditForm
                areasOptions={areasOptions}
                usersOptions={usersOptions}
                saveTask={saveTask}
                actionsLoading={actionsLoading}
                dataLoading={isLoading}
                task={taskForEdit || initTask}
                onHide={onHide}
                taskboardContext={taskboardContext}
            />
        </Modal>
    )
}

export default withErrorCatching(TaskEditDialog)
