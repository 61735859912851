import { selector } from 'recoil'
import { IssueStruct } from '@shared/dataObjects'
import { issuesListAtom, selectedIssueIdAtom } from './atoms'

export const selectedIssueSelector = selector({
    key: 'selectedIssueSelector',
    get: ({ get }) => {
        const selectedIssueId = get(selectedIssueIdAtom)
        const issues = get(issuesListAtom)
        return issues.find((issue: IssueStruct) => issue.key === selectedIssueId)
    }
})
