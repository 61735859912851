import React, { useContext, useState } from 'react'
import { PermissionCheck } from '../../components/upgrade-signals'
import AssignActionBar from '../assign-action-bar'
import { notify } from 'react-notify-toast'
import { Toast } from '../../utils/toast'
import * as colors from '@shared/colors'
import { View } from 'react-native-web'
import { DashboardContext } from './DashboardContext'
import { AuthContext } from '../../modules/Auth/AuthContext'
import { UserStruct } from '@shared/firestore-structs'
import * as dataObjects from '@shared/dataObjects'
import * as areaData from '@shared/area-data'
import firebase, { asFirebase } from '../../utils/firebase'
import * as taskData from '@shared/task-data'
import * as c from '@shared/constants'

const PLEASE_SELECT_AREA_MESSAGE = 'Please select at least one area'
const CLEANING_ACTION_BAR_HEIGHT = 180

const ActionBar = ({ loading, areas }: { loading: boolean; areas: any[] }) => {
    const {
        showActionBar,
        setShowActionBar,
        setSelectedAreas,
        selectedAreas,
        setSelectUserModalIsOpen,
        assignModeFilter,
        setAssignModeFilter,
        selectedDateNumber,
        lastAssignedUsers,
        cleaningTaskName,
        setCleaningTaskName,
        setComponentControlsDisplay
    } = useContext(DashboardContext)
    const { currentUser, currentOrganization } = useContext(AuthContext)
    const actionBarYposition = window.innerHeight - CLEANING_ACTION_BAR_HEIGHT - 50

    const closeAssignMode = () => {
        setSelectedAreas([])
        setShowActionBar(false)
        setComponentControlsDisplay(false)
    }

    const clearSelectedAreas = () => {
        setSelectedAreas([])
    }

    const onUnassignPress = () => {
        setSelectUserModalIsOpen(false)
        selectedAreas.map(async (area: any) => {
            const changeObj = {
                before: 'unassigned'
            }
            await areaData.logActivity(asFirebase(firebase), currentUser!, area.key, 'assignment', selectedDateNumber, changeObj)
            await taskData.unassignTask(asFirebase(firebase), currentUser!, area.task.key)
        })

        setSelectedAreas([])
    }

    const onAssignPress = (users: any) => {
        // TODO: IMPLEMENT ASSIGN PRESS
    }

    const openSelectUserModal = () => {
        setSelectUserModalIsOpen(true)
    }

    const selectAllAreas = () => {
        setSelectedAreas(areas)
    }

    return (
        <View style={{ zIndex: 100 }}>
            {showActionBar && (
                <PermissionCheck
                    permissionId="assign-housekeeping-assign"
                    onAllowed={() => (
                        <AssignActionBar
                            currentOrganization={currentOrganization}
                            showActivityIndicator={loading}
                            top={actionBarYposition}
                            height={CLEANING_ACTION_BAR_HEIGHT}
                            currentUser={currentUser}
                            allAreas={areas}
                            closeAssignMode={closeAssignMode}
                            clearSelectedAreas={clearSelectedAreas}
                            selectedAreas={selectedAreas}
                            onUnassignPress={onUnassignPress}
                            onAssignPress={onAssignPress}
                            openSelectUserModal={openSelectUserModal}
                            toastMessage={PLEASE_SELECT_AREA_MESSAGE}
                            // inputCleaningTaskName={inputCleaningTaskName}
                            // setInputCleaningTaskName={setInputCleaningTaskName}
                            setCleaningTaskName={setCleaningTaskName}
                            setAssignModeFilter={setAssignModeFilter}
                            assignModeFilter={assignModeFilter}
                            selectedDateNumber={selectedDateNumber}
                            selectAllAreas={selectAllAreas}
                            onAssignLastPress={() => {
                                if (lastAssignedUsers) {
                                    onAssignPress(lastAssignedUsers)
                                } else {
                                    notify.show('No last assignment found, first use Assign!', 'custom', 3000, {
                                        background: colors.green_spectaflow,
                                        text: colors.white
                                    })
                                }
                            }}
                        />
                    )}
                />
            )}
        </View>
    )
}

export default ActionBar
