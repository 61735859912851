import React from 'react'
import { View } from 'react-native-web'

import * as helpers from '@shared/helpers'
import * as colors from '@shared/colors'
import firebase, { asFirebase, changeIssueName, asBatch } from '../utils/firebase'
import { HoverableTextButton } from '../components/buttons/hoverable-text-button'
import { HoverableImageButton } from '../components/buttons/hoverable-image-button'
import IssueHashtagsSelect from '../components/hashtags-select'
import * as uc from '../utils/constants'
import * as hhelpers from '@shared/hashtag-helpers'
import { InputDescription } from '../components/input-description'
import { SectionTitle } from '../components/section-title'
import { IssueStruct, UserStruct } from '@shared/firestore-structs'
import { batchCommit } from '@shared/data-helpers'

interface MassHashTagProps {
    checkedIssues: IssueStruct[]
    currentUser: UserStruct
    closeModal: () => void
    clearCheckedIssues: () => void
    setShowIssueActionBar: (showIssueActionBar: boolean) => void
    defaultHashtags: string[]
}

interface MassHashTagState {
    issueHashtags: string[]
}

export default class MassHashTag extends React.Component<MassHashTagProps, MassHashTagState> {
    state: MassHashTagState = {
        issueHashtags: []
    }
    constructor(props: MassHashTagProps) {
        super(props)
        this.addHashTagsAndUpdate = this.addHashTagsAndUpdate.bind(this)
        this.removeHashTagsAndUpdate = this.removeHashTagsAndUpdate.bind(this)
    }

    componentDidMount() {}

    async addHashTagsAndUpdate() {
        const batch = firebase.firestore().batch()
        const issuesList = [...this.props.checkedIssues].reverse()

        issuesList.forEach(issue => {
            const joinedObj = hhelpers.joinTextAndUniqueHashtags(issue.name, this.state.issueHashtags)
            changeIssueName(
                asFirebase(firebase),
                {
                    issueKey: issue.key,
                    currentUser: this.props.currentUser,
                    name: joinedObj.textWithHashtags,
                    taskKey: issue.taskKey
                },
                asBatch(batch)
            )
        })

        batchCommit(asBatch(batch), 'mass-hashtag addHashTagsAndUpdate', 'adding hashtags to issues')

        this.props.closeModal()
        this.props.clearCheckedIssues()
        this.props.setShowIssueActionBar(false)
    }

    removeHashTagsAndUpdate() {
        const batch = firebase.firestore().batch()
        const issuesList = [...this.props.checkedIssues].reverse()

        issuesList.forEach(issue => {
            const strippedText = helpers.removeHashtags(issue.name, this.state.issueHashtags)
            changeIssueName(
                asFirebase(firebase),
                {
                    issueKey: issue.key,
                    currentUser: this.props.currentUser,
                    name: strippedText,
                    taskKey: issue.taskKey
                },
                asBatch(batch)
            )
        })

        batchCommit(asBatch(batch), 'mass-hashtag removeHashTagsAndUpdate', 'removing hashtags from issues')

        this.props.closeModal()
        this.props.clearCheckedIssues()
        this.props.setShowIssueActionBar(false)
    }

    render() {
        const is_active = this.state.issueHashtags.length > 0

        return (
            <View
                style={{
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingHorizontal: uc.MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE,
                    borderWidth: 0,
                    paddingTop: 36,
                    paddingBottom: 12
                }}>
                <View
                    style={{
                        borderWidth: 0,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                    <SectionTitle text={'Select or add #hashtags'} fontSize={19} />
                    <HoverableImageButton
                        dimension={uc.SMALL_BUTTON_DIM}
                        hoverOpacity={uc.HOVER_OPACITY}
                        imageSource={require('@shared/img/close.svg')}
                        onPress={this.props.closeModal}
                    />
                </View>
                <View
                    style={{
                        zIndex: 999
                    }}>
                    <InputDescription text={'Title:'} />
                    <IssueHashtagsSelect
                        width={'100%'}
                        defaultHashtags={this.props.defaultHashtags}
                        hashtags={this.state.issueHashtags}
                        isDisabled={false}
                        onChange={(issueHashtags: string[]) => {
                            this.setState({ issueHashtags })
                        }}
                    />
                </View>

                <View
                    style={{
                        borderWidth: 0,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <span
                        style={{
                            fontSize: 14,

                            color: colors.red_spectaflow
                        }}>
                        {this.props.checkedIssues.length} {helpers.getPlural('issue', this.props.checkedIssues.length)} will be affected
                    </span>
                </View>

                <View
                    style={{
                        borderWidth: 0,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingLeft: 0
                    }}>
                    <HoverableTextButton
                        text={'Cancel'}
                        className="btn btn-secondary"
                        // type={'cancel'}
                        onClick={() => {
                            this.props.closeModal()
                        }}
                    />
                    <HoverableTextButton
                        text={'Remove'}
                        className="btn btn-danger"
                        disabled={!is_active}
                        onClick={this.removeHashTagsAndUpdate}
                    />
                    <HoverableTextButton
                        text={'Add'}
                        className="btn btn-primary"
                        disabled={!is_active}
                        onClick={this.addHashTagsAndUpdate}
                    />
                </View>
            </View>
        )
    }
}
