import styled from 'styled-components'
import * as colors from '@shared/colors'

export const aIssueModalStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        borderRadius: 4,
        borderColor: 'red',
        borderWidth: 0,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '80vh',
        width: '70%',
        maxHeight: '80vh' // <-- This sets the height
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1002,
        backgroundColor: 'rgba(0, 0, 0,.2)'
    }
}

export const Row = styled.div`
    margin-bottom: 16px;
`
