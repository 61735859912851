import React from 'react'
import { useHistory } from 'react-router-dom'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { getWorkflowInstance, storeWorkflowInstance } from '../../../../utils/signup-flow-template/signup-workflow-instance'
import { currentIntegrationKey } from '../../signup-worflow-templates'
import OracleCustomerCredentialsForm from './oracleCredentials'

const OracleAuthorize = () => {
    const history = useHistory()
    const workflowInstance = getWorkflowInstance(currentIntegrationKey)
    const bus = workflowInstance.getInput('bus')

    const onSubmit = values => {
        const { clientId, clientSecret, password, username, hotelId, appKey, externalSystemCode, hostName } = values

        workflowInstance.completeStep(SIGN_UP.ORACLE_AUTHORIZE, {
            hostName,
            clientId,
            clientSecret,
            password,
            username,
            hotelId,
            appKey,
            externalSystemCode
        })

        storeWorkflowInstance(currentIntegrationKey, workflowInstance)

        setTimeout(() => history.push(workflowInstance.nextStep()), 10)
    }

    return <OracleCustomerCredentialsForm bus={bus} onSubmit={onSubmit} />
}

export default OracleAuthorize
