import React from 'react'
import moment from 'moment-timezone'
import * as c from '@shared/constants'
import { IssueStruct, UserStruct } from '@shared/dataObjects'
import * as helpers from '@shared/helpers'
import { AreaBredCrumbs } from 'app/components/area-bread-crumbs'
import { DueDateButton } from 'app/components/buttons/DueDateButton'
import { InitialsView } from 'app/components/initials-view'
import HashTagsBlock from './HashTagsBlock'
import {
    AreaBredCrumbsContainer,
    DateContainer,
    DateWrapper,
    DueDateContainer,
    HashTagsContainer,
    HashTagsWrapper,
    IssueInfoBreadcrubsContainer,
    IssueInfoContainer,
    IssueInfoFooterContainer,
    IssueInfoWrapper,
    IssueNumberWrapper,
    NoDueDateBlock
} from '../../style'

interface InfoBlockProps {
    issue: IssueStruct
    isHousekeepingModal: boolean
    currentUser: UserStruct
    onPressHashTag: (hashtag: string) => void
    onSearchPress: (value: string) => void
}

const line_height = 25

export default function InfoBlock({ issue, isHousekeepingModal, currentUser, onPressHashTag, onSearchPress }: InfoBlockProps) {
    const lastUpdatedTime = moment(issue.updated).format('HH:mm')
    const lastUpdatedHuman = issue && issue.updated ? moment(issue.updated).format(c.DATE_FORMAT_WITH_WEEK_DAY) : null
    const created = moment(issue.created).format(c.DATE_FORMAT_WITH_WEEK_DAY)

    const title_font_size = isHousekeepingModal ? 15 : 16
    const date_font_size = isHousekeepingModal ? 10 : 12
    const title_font_weight = '500'

    function renderInitialsView() {
        const onPress = (value: string) => {
            onSearchPress(value)
        }
        return (
            <InitialsView
                maxCountToDisplay={3}
                disabled={isHousekeepingModal}
                circleDimension={isHousekeepingModal ? 22 : 25}
                fontSize={isHousekeepingModal ? 8 : 10}
                justifyContent={isHousekeepingModal ? 'flex-start' : 'center'}
                onPress={isHousekeepingModal ? null : onPress}
                currentUser={currentUser}
                assignedTo={issue.assignedContacts}
            />
        )
    }

    return (
        <IssueInfoWrapper>
            <IssueInfoContainer>
                {!isHousekeepingModal && (
                    <IssueInfoBreadcrubsContainer>
                        <AreaBredCrumbsContainer>
                            <AreaBredCrumbs
                                isEditable
                                area={issue.area}
                                fontSize={14}
                                fontWeight={'500'}
                                currentUser={currentUser}
                                // Romevoe null after AreaBredCrumbs migrated to typescript
                                task={null}
                                issue={null}
                                spaceBetween={null}
                                fontColor={null}
                                arrowColor={null}
                            />
                        </AreaBredCrumbsContainer>
                        <IssueNumberWrapper>
                            <span
                                style={{
                                    fontSize: 13,
                                    color: helpers.getIssueNumberColor(issue.status)
                                }}>
                                {issue.issueNumber ? issue.issueNumber : ''}
                            </span>
                        </IssueNumberWrapper>
                    </IssueInfoBreadcrubsContainer>
                )}
                <HashTagsWrapper>
                    <HashTagsContainer titleFontSize={title_font_size} titleFontWeight={title_font_weight}>
                        {helpers.cleanHashTags(issue.name)}
                        <HashTagsBlock string={issue.name} fontSize={title_font_size} onPressHashTag={onPressHashTag} />
                    </HashTagsContainer>
                </HashTagsWrapper>
                {!isHousekeepingModal && (
                    <DueDateContainer lineHeight={line_height}>
                        {issue.dueDate && <DueDateButton dueDate={issue.dueDate} readOnly={true} fontSize={13} />}
                        {!issue.dueDate && <NoDueDateBlock>No due date</NoDueDateBlock>}
                    </DueDateContainer>
                )}

                {isHousekeepingModal && renderInitialsView()}

                <IssueInfoFooterContainer lineHeight={line_height}>
                    <DateContainer>
                        <DateWrapper fontSize={date_font_size}>
                            Changed {lastUpdatedTime} {lastUpdatedHuman}
                        </DateWrapper>
                    </DateContainer>
                    {!isHousekeepingModal && renderInitialsView()}

                    {!isHousekeepingModal && (
                        <DateContainer>
                            <DateWrapper fontSize={date_font_size}>Created {created}</DateWrapper>
                        </DateContainer>
                    )}
                </IssueInfoFooterContainer>
            </IssueInfoContainer>
        </IssueInfoWrapper>
    )
}
