import * as React from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { checkedIssuesAtom, allIssuesCheckedAtom, thisPageCheckedAtom, issuesListAtom } from '../state/atoms'

import { fadeInDown } from 'react-animations'
import Radium from 'radium'
import * as colors from '@shared/colors'
import CheckBox from '../../components/check-box'

const animationStyles = {
    fadeIn: {
        animation: 'x 0.300s',
        animationName: Radium.keyframes(fadeInDown, 'fadeInDown')
    }
}
interface ICheckBoxes {
    showActionBar?: boolean
}

export default function CheckBoxes({ showActionBar }: ICheckBoxes) {
    const text_left_margin = 9
    const view_width = 120
    const DEBUG_BORDER = 0
    const issuesList = useRecoilValue(issuesListAtom)
    const setCheckedIssues = useSetRecoilState(checkedIssuesAtom)

    const [allIssuesChecked, setAllIssuesChecked] = useRecoilState(allIssuesCheckedAtom)
    const [thisPageChecked, setThisPageChecked] = useRecoilState(thisPageCheckedAtom)

    function selectCheckedIssues(type: string) {
        if (type === 'all') {
            if (allIssuesChecked === false) {
                setCheckedIssues(issuesList)
                setAllIssuesChecked(true)
                setThisPageChecked(false)
            } else {
                setCheckedIssues([])
                setAllIssuesChecked(false)
                setThisPageChecked(false)
            }
        }

        if (type === 'this-page') {
            if (thisPageChecked === false) {
                setCheckedIssues(issuesList)
                setAllIssuesChecked(false)
                setThisPageChecked(true)
            } else {
                setCheckedIssues([])
                setAllIssuesChecked(false)
                setThisPageChecked(false)
            }
        }
    }

    return (
        <>
            {showActionBar && (
                <Radium.StyleRoot>
                    {/* FIXME define types */}
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <div style={animationStyles.fadeIn}>
                        <div
                            style={{
                                flexDirection: 'row',
                                marginBottom: 25,
                                borderWidth: DEBUG_BORDER,
                                width: '100%'
                            }}>
                            <div
                                style={{
                                    marginLeft: 12,
                                    paddingLeft: 5,
                                    alignItems: 'flex-end',

                                    width: view_width,
                                    borderWidth: DEBUG_BORDER,
                                    borderColor: 'red',
                                    flexDirection: 'row'
                                }}>
                                <CheckBox isChecked={thisPageChecked} onValueChange={() => selectCheckedIssues('this-page')} />

                                <span
                                    style={{
                                        marginLeft: text_left_margin,
                                        fontSize: 15,
                                        fontWeight: 400,
                                        color: colors.textPrimary
                                    }}>
                                    This page
                                </span>
                            </div>

                            <div
                                style={{
                                    marginLeft: 5,
                                    paddingLeft: 5,
                                    alignItems: 'flex-end',

                                    width: view_width,
                                    borderWidth: DEBUG_BORDER,
                                    borderColor: 'red',
                                    flexDirection: 'row'
                                }}>
                                <CheckBox isChecked={allIssuesChecked} onValueChange={() => selectCheckedIssues('all')} />
                                <span
                                    style={{
                                        marginLeft: text_left_margin,
                                        fontSize: 15,
                                        fontWeight: 400,
                                        color: colors.textPrimary
                                    }}>
                                    All
                                </span>
                            </div>
                        </div>
                    </div>
                </Radium.StyleRoot>
            )}
        </>
    )
}
