export const infoTypes = {
    dailyComments: {
        title: 'Daily Comments',
        emptyTitle: 'No daily comments',
        emptySubTitle: 'for Today',
        icon: 'specta-daily-comment',
        iconSize: 'icon-lg',
        iconSizeList: 'icon-2x',
        extractData: areas =>
            areas
                .filter(x => x.dailyComment)
                .map(x => {
                    return {
                        area: {
                            name: x.name,
                            description: x.description,
                            group: x.group
                        },
                        comment: x.dailyComment
                    }
                })
    },
    unitNotes: {
        title: 'Unit Notes',
        emptyTitle: 'No unit notes',
        emptySubTitle: '',
        icon: 'specta-note',
        extractData: areas =>
            areas
                .filter(x => x.note)
                .map(x => {
                    return {
                        area: {
                            name: x.name,
                            description: x.description,
                            group: x.group
                        },
                        comment: x.note
                    }
                })
    },
    bookingNotes: {
        title: 'Booking Notes',
        emptyTitle: 'No booking notes',
        emptySubTitle: 'for Today',
        icon: 'specta-booking-note',
        extractData: areas =>
            areas
                .filter(x => x.bookingNotes)
                .map(x => {
                    return {
                        area: {
                            name: x.name,
                            description: x.description,
                            group: x.group
                        },
                        comment: x.bookingNotes
                    }
                })
    },
    extraServices: {
        title: 'Extras',
        emptyTitle: 'No extras',
        emptySubTitle: 'for Today',
        icon: 'fas fa-shopping-basket',
        iconSize: 'icon-md',
        extractData: areas =>
            areas
                .filter(x => x.extra)
                .map(x => {
                    return {
                        area: {
                            name: x.name,
                            description: x.description,
                            group: x.group
                        },
                        comment: x.extra
                    }
                })
    }
}
