import React, { useContext } from 'react'
import { LogoWrap, NavMenuWrap, UserWrap, Wrapper } from './style'
import { getNavBarAuthLogo } from '@shared/brand'
import User from 'app/components/layouts/User'
import NavItem from './NavItem'
import { filterNavItems } from 'app/navigation/nav-items'
import { AuthContext } from 'app/modules/Auth/AuthContext'
import SubscriptionIndicator from '_metronic/layout/components/aside/aside-menu/SubscriptionIndicator'
import LogoutButton from '_metronic/layout/components/aside/LogoutButton'

function HeaderNavbar() {
    const { user, organization } = useContext(AuthContext)
    const brandLogo = getNavBarAuthLogo()
    const filteredNavItems = filterNavItems(user, organization)

    return (
        <Wrapper>
            <LogoWrap>
                <img src={brandLogo.img} style={brandLogo.style} alt="logo" />
            </LogoWrap>
            <SubscriptionIndicator tracesView={true} />
            <NavMenuWrap>
                {filteredNavItems.map(navItem => (
                    <NavItem key={navItem.name} navItem={navItem} />
                ))}
            </NavMenuWrap>

            <UserWrap>
                <LogoutButton padding={'0'} />
                <User usingInHeader />
            </UserWrap>
        </Wrapper>
    )
}

export default HeaderNavbar
