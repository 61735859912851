import { Firebase } from './firebase'

export function getRulesQuery(firebase: Firebase, organizationKey: string, areaKey: string | null = null) {
    let query = firebase.firestore().collection('rules').where('organizationKey', '==', organizationKey).where('visible', '==', true)

    if (areaKey) {
        query = query.where('areas', 'array-contains', areaKey)
    }

    return query
}
