import styled from 'styled-components'
import * as colors from '@shared/colors'

export const Wrap = styled.div<{ isAssign: boolean }>`
    display: flex;
    justify-content: ${({ isAssign }) => (isAssign ? 'start' : 'center')};
    
    > div {
        position: relative;
        display flex;
        flex-direction: column;
        row-gap: 6px;
        align-items: center;
    }

    .spinner_action_bar {
        position: absolute;
        height: 50%;
        width: 20px;
        top: 0;
        left: -30px;
    }
`

export const Text = styled.span`
    color: ${colors.body_color};
    font-size: 1rem;
`

export const TextButton = styled.u<{ disabled: boolean }>`
    color: ${colors.bs_primary};
    font-size: 1rem;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`
