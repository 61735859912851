import { iOSColors } from '@shared/react-native-typography'
import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity, Image, TextInput, findNodeHandle } from 'react-native-web'
import Hoverable from '../utils/hoverable/hoverable'
import * as helpers from '@shared/helpers'
import * as issueData from '@shared/issue-data'
import { HoverableImageButton } from '../components/buttons/hoverable-image-button'
import * as uc from '../utils/constants'
import Spinner from '../components/Spinner'

import firebase, { asFirebase } from '../utils/firebase'
import ImagesDropBox from '../components/images-dropbox'
import { IssueItem, UserStruct } from '@shared/firestore-structs'

type PhotoItemProps = {
    editable: boolean
    currentUser: UserStruct
    issueKey: string
    item: IssueItem

    onDeleteItemPress: (item: IssueItem) => void
    width: number
    padding: number
    openPhotoItemModal: (item: IssueItem) => void
    itemNumber: number
    length: number
    setImages: (images: any) => void
    isImagesLoading?: boolean
}
export const PhotoItem = ({
    editable,
    currentUser,
    issueKey,
    item,
    onDeleteItemPress,
    width,
    padding,
    openPhotoItemModal,
    itemNumber,
    length,
    setImages,
    isImagesLoading
}: PhotoItemProps) => {
    let text = null

    if (item.text === undefined || item.text === null) {
        text = ''
    } else {
        text = item.text.content
    }
    const [textInputActive, setTextInputActive] = useState(false)
    const [textInputValue, setTextInputValue] = useState(text)
    const [showActionButtons, setShowActionButtons] = useState(false)
    const [isLoading, setIsLoading] = useState(isImagesLoading)

    useEffect(() => {
        setIsLoading(isImagesLoading)
    }, [isImagesLoading])

    useEffect(() => {
        setIsLoading(false)
    }, [length])

    const buttonDim = 24

    const imagesDropBoxCondition = itemNumber === 1 && editable && <ImagesDropBox width={width} setImages={setImages} />
    const loadingCondition = itemNumber === 1 && isLoading && (
        <div style={{ width, height: width, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner />
        </div>
    )

    if (item !== null && item.thumbUrl && item.thumbUrl.length >= 5 && item.thumbUrl.slice(0, 4) === 'http') {
        return (
            <div style={{ display: 'flex' }}>
                {imagesDropBoxCondition}
                {loadingCondition}

                <Hoverable
                    onHoverIn={() => {
                        setShowActionButtons(true)
                    }}
                    onHoverOut={() => {
                        setShowActionButtons(false)
                    }}>
                    <View
                        style={{
                            borderWidth: 0,
                            width: width,
                            height: width,
                            marginRight: padding,
                            marginLeft: itemNumber === 1 ? padding : 0,
                            paddingTop: padding,
                            // @ts-ignore
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            if (!textInputActive) {
                                openPhotoItemModal(item)
                            }
                        }}>
                        <Image
                            // @ts-ignore
                            source={item.thumbUrl}
                            style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                width: width,
                                borderRadius: 6,
                                height: width
                            }}
                        />
                        {showActionButtons && (
                            <View
                                style={{
                                    width: width,
                                    position: 'absolute',
                                    top: 16,
                                    left: 0,
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    borderColor: 'red',
                                    borderWidth: 0,
                                    paddingLeft: 10,
                                    paddingRight: 10
                                }}>
                                {editable && (
                                    <HoverableImageButton
                                        dimension={buttonDim}
                                        hoverOpacity={uc.HOVER_OPACITY}
                                        imageSource={require('../img/add-item-text.svg')}
                                        onPress={() => setTextInputActive(true)}
                                        tooltipText={'Change text'}
                                        // tooltipFontSize={11}
                                    />
                                )}
                                {editable && (
                                    <HoverableImageButton
                                        dimension={buttonDim}
                                        hoverOpacity={uc.HOVER_OPACITY}
                                        imageSource={require('../img/trash.svg')}
                                        onPress={() => {
                                            onDeleteItemPress(item)
                                        }}
                                        tooltipText={'Delete photo'}
                                        // tooltipFontSize={11}
                                    />
                                )}
                            </View>
                        )}
                        {renderText(
                            item,
                            editable,
                            width,
                            textInputActive,
                            textInputValue,
                            setTextInputActive,
                            setTextInputValue,
                            currentUser,
                            issueKey
                        )}
                    </View>
                </Hoverable>
            </div>
        )
    }
    return null
}

const renderText = (
    item: IssueItem,
    editable: boolean,
    width: number,
    textInputActive: boolean,
    textInputValue: string,
    setTextInputActive: (value: boolean) => void,
    setTextInputValue: (value: string) => void,
    currentUser: UserStruct,
    issueKey: string
) => {
    const text: { [name: string]: string | number } = {}
    if (helpers.isItemTextAvailable(item)) {
        text.content = item.text!.content
        text.position = item.text!.position
    }
    const baseHeight = 700

    let percentText = null
    if (text.position === undefined || text.position === null) {
        percentText = '50%'
    } else {
        if (text.position > baseHeight) {
            text.position = baseHeight - 10
        }
        const percent = ((text.position as number) / baseHeight) * 100
        percentText = percent.toString() + '%'
    }

    const fontSize = 12

    return (
        <View
            style={{
                position: 'absolute',
                top: percentText,
                backgroundColor: 'rgba(0,0,0,0.5)',
                height: 19,
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: textInputActive ? 1 : 0,
                borderColor: iOSColors.white
            }}>
            {!textInputActive && text.content !== undefined && (
                <TouchableOpacity
                    disabled={!editable}
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: width
                    }}
                    onPress={() => {
                        setTextInputActive(true)
                        setTextInputValue(text.content as string)
                    }}>
                    <span style={{ color: iOSColors.white, fontSize }}>{item.text!.content}</span>
                </TouchableOpacity>
            )}
            {editable && textInputActive && (
                <TextInput
                    style={{
                        fontSize,
                        color: iOSColors.white,

                        // @ts-ignore
                        outlineStyle: 'none',
                        width: width,
                        textAlign: 'center'
                    }}
                    onBlur={e => {
                        handleClick(textInputActive, setTextInputActive)
                        onSubmit(item, textInputValue, currentUser, issueKey, setTextInputActive)
                    }}
                    onChangeText={text => {
                        setTextInputValue(text)
                    }}
                    value={textInputValue}
                />
            )}
        </View>
    )
}

const onSubmit = (
    item: IssueItem,
    textInputValue: string,
    currentUser: UserStruct,
    issueKey: string,
    setTextInputActive: (value: boolean) => void
) => {
    const text = {
        content: textInputValue,
        position: 0
    }
    if (helpers.isItemTextAvailable(item)) {
        text.position = item.text!.position
    } else {
        text.position = 350
    }

    item.text = text
    issueData.updateImageItemText(asFirebase(firebase), issueKey, item.key, text, currentUser)

    setTextInputActive(false)
}

const handleClick = (textInputActive: boolean, setTextInputActive: (value: boolean) => void) => {
    if (!textInputActive) {
        document.addEventListener('click', handleOutsideClick, false)
    } else {
        document.removeEventListener('click', handleOutsideClick, false)
    }
    setTextInputActive(!textInputActive)
}

const handleOutsideClick = (e: Event) => {
    // @ts-ignore
    const handle = findNodeHandle(this.node)
    // @ts-ignore
    if (handle!.contains(e.target)) {
        return
    }

    // @ts-ignore
    handleClick()
}
