import React from 'react'
import { View, TouchableOpacity, Image, Text, findNodeHandle } from 'react-native-web'
import Hoverable from '../utils/hoverable/hoverable'
import * as colors from '@shared/colors'
import * as uc from '../utils/constants'
import { iOSColors, webWeights } from '@shared/react-native-typography'
import { HoverableImageButton } from '../components/buttons/hoverable-image-button'
import * as helpers from '@shared/helpers'

import { fadeInDown } from 'react-animations'
import Radium from 'radium'
import { IconButton } from '../components/buttons/IconButton'
import { StatusOption } from './types'
import { CSSProperties } from 'styled-components'

const animationStyles = {
    fadeIn: {
        animation: 'x 0.25s',
        animationName: Radium.keyframes(fadeInDown, 'fadeInDown')
    }
}

type StatusFilterPopupProps = {
    buttonDimension: number
    setStatusFilter: (status: StatusOption[]) => void
    defaultFilter: StatusOption[]
    statusFilter: StatusOption[]
}

type StatusFilterPopupState = {
    open: boolean
    statusFilter: StatusOption[]
}
export default class StatusFilterPopup extends React.Component<StatusFilterPopupProps, StatusFilterPopupState> {
    constructor(props: StatusFilterPopupProps) {
        super(props)
        this.state = { open: false, statusFilter: this.props.defaultFilter }

        this.handleClick = this.handleClick.bind(this)
        this.handleOutsideClick = this.handleOutsideClick.bind(this)
        this.renderOptions = this.renderOptions.bind(this)
    }

    static getDerivedStateFromProps(props: StatusFilterPopupProps, state: StatusFilterPopupState) {
        if (!StatusFilterPopup.hasSameFilter(props.statusFilter, state.statusFilter)) {
            return { statusFilter: props.statusFilter }
        }
        return null
    }

    static hasSameFilter(filter1: StatusOption[], filter2: StatusOption[]) {
        if (!filter1) {
            return false
        }

        if (!filter2) {
            return false
        }

        if (filter1.length !== filter2.length) {
            return false
        }
        for (let i = 0; i < filter1.length; i++) {
            if (filter1[i].isSelected !== filter2[i].isSelected) {
                return false
            }
            if (filter1[i].value !== filter2[i].value) {
                return false
            }
        }

        return true
    }

    handleClick() {
        if (!this.state.open) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false)
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false)
        }

        this.setState(prevState => ({
            open: !prevState.open
        }))
    }

    handleOutsideClick(e: Event) {
        // @ts-ignore
        const handle = findNodeHandle(this.node)
        // @ts-ignore
        if (handle && handle.contains && handle.contains(e.target)) {
            return
        }

        // @ts-ignore
        this.handleClick()
    }

    isDefaultFilter(filter: StatusOption[]) {
        if (filter[0].isSelected && filter[1].isSelected && !filter[2].isSelected && !filter[3].isSelected) {
            return false
        } else {
            return true
        }
    }

    render() {
        const IS_DEFAULT_FILTER = this.isDefaultFilter(this.props.statusFilter)

        return (
            <View
                style={{ alignItems: 'center', zIndex: 1 }}
                ref={node => {
                    // @ts-ignore
                    this.node = node
                }}>
                <IconButton
                    className={`btn btn-circle btn-icon ${!IS_DEFAULT_FILTER ? 'btn-specta-gray' : 'btn-danger'}`}
                    onClick={this.handleClick}
                    icon="filter icon-lg"
                    tooltip={{
                        text: 'Filter by status',
                        position: 'top'
                    }}
                />
                {this.state.open && (
                    <View
                        style={{
                            minWidth: 230,
                            position: 'absolute',
                            top: '100%',
                            right: '-150%',
                            marginTop: 6,
                            elevation: 3,
                            backgroundColor: 'transparent',
                            // @ts-ignore
                            overflowY: 'auto'
                        }}>
                        {this.renderOptions(IS_DEFAULT_FILTER)}
                    </View>
                )}
            </View>
        )
    }

    renderOptions(IS_DEFAULT_FILTER: boolean) {
        return (
            <Radium.StyleRoot>
                <div style={animationStyles.fadeIn as CSSProperties}>
                    <View
                        style={{
                            borderWidth: 1,
                            borderColor: colors.gentle_gray_spectaflow,
                            // @ts-ignore
                            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)',
                            borderRadius: 8,
                            backgroundColor: 'white',
                            paddingVertical: 6
                        }}>
                        {this.state.statusFilter &&
                            this.state.statusFilter.map((option, index) => (
                                <OptionItem
                                    key={option.value}
                                    statusFilter={this.state.statusFilter}
                                    option={option}
                                    isDefaultFilter={IS_DEFAULT_FILTER}
                                    isLast={index === this.props.statusFilter.length - 1}
                                    toggleMenu={this.handleClick}
                                    setStatusFilter={this.props.setStatusFilter}
                                />
                            ))}
                    </View>
                </div>
            </Radium.StyleRoot>
        )
    }
}

type OptionItemProps = {
    statusFilter: StatusOption[]
    option: StatusOption
    isDefaultFilter: boolean
    isLast: boolean
    toggleMenu: () => void
    setStatusFilter: (status: StatusOption[], isDefaultFilter?: boolean) => void
}

type OptionItemState = {
    hover: boolean
    backgroundColor: string
    fontColor: string
}

// eslint-disable-next-line react/no-multi-comp
class OptionItem extends React.Component<OptionItemProps, OptionItemState> {
    constructor(props: OptionItemProps) {
        super(props)

        this.state = {
            backgroundColor: 'white',
            hover: false,

            fontColor: colors.webNavbarInactive
        }
        this.changeSelectionStatus = this.changeSelectionStatus.bind(this)
        this.onPress = this.onPress.bind(this)
    }

    getSelectIcon(selected?: boolean) {
        let iconSource
        if (selected) {
            iconSource = require('../img/select-on-mid-gray.svg')
        } else {
            iconSource = require('@shared/img/select-off-3.svg')
        }

        const BUTTON_DIM = 20
        return <Image style={{ height: BUTTON_DIM, width: BUTTON_DIM }} source={iconSource} />
    }

    resetToDefault(filter: StatusOption[]) {
        const statusFilter: StatusOption[] = JSON.parse(JSON.stringify(filter))
        statusFilter[0].isSelected = true
        statusFilter[1].isSelected = true
        statusFilter[2].isSelected = false
        statusFilter[3].isSelected = false
        return statusFilter
    }

    onPress(option: StatusOption) {
        if (option.value === 'reset') {
            this.props.toggleMenu()
            this.props.setStatusFilter(this.resetToDefault(this.props.statusFilter), true)
        } else {
            this.changeSelectionStatus(option)
        }
    }
    changeSelectionStatus(option: StatusOption) {
        const statusFilter: StatusOption[] = JSON.parse(JSON.stringify(this.props.statusFilter))
        const changedIndex = statusFilter.findIndex(o => o.value === option.value)
        statusFilter[changedIndex].isSelected = option.isSelected ? false : true
        this.props.setStatusFilter(statusFilter)
    }

    render() {
        const PADDING_HORIZONTAL = 16
        const BORDER_COLOR = 'green'
        const BORDER_BOTTOM_WIDTH = 1
        const ICON_MARGIN_TOP = 1
        const MARGIN_TOP = 0

        const UN_SELECTED_OPACITY_IMG = 0.4

        const isResetOption = this.props.option.value === 'reset'

        const disableResetOption = isResetOption && !this.props.isDefaultFilter

        let font_color = helpers.getIssueNumberColor(this.props.option.value)
        let font_opacity = 1
        if (isResetOption) {
            font_color = iOSColors.red
            font_opacity = 1
        }

        if (!this.props.option.isSelected) {
            font_opacity = 0.35
        }

        if (isResetOption && disableResetOption) {
            font_opacity = 0.5
        } else if (isResetOption) {
            font_opacity = 1
        }

        return (
            <Hoverable
                onHoverIn={() => {
                    this.setState({ hover: true, backgroundColor: colors.gentle_gray_spectaflow, fontColor: iOSColors.black })
                }}
                onHoverOut={() => {
                    this.setState({ hover: false, backgroundColor: iOSColors.white, fontColor: colors.webNavbarInactive })
                }}>
                <TouchableOpacity
                    onPress={() => {
                        this.onPress(this.props.option)
                    }}
                    disabled={disableResetOption}
                    style={{
                        paddingHorizontal: PADDING_HORIZONTAL,
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: this.state.backgroundColor
                    }}>
                    <View
                        style={{
                            width: '100%',
                            borderBottomWidth: this.props.isLast ? 0 : BORDER_BOTTOM_WIDTH,
                            borderBottomColor: '#F4F5F6'
                        }}>
                        <View
                            style={{
                                marginTop: MARGIN_TOP,
                                height: 48,
                                borderColor: BORDER_COLOR,
                                borderWidth: 0,
                                justifyContent: 'center'
                            }}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    borderWidth: 0,
                                    alignItems: 'center',
                                    justifyContent: isResetOption ? 'center' : 'space-between'
                                }}>
                                <span
                                    style={{
                                        fontWeight: isResetOption ? '500' : 400,
                                        opacity: font_opacity,
                                        color: font_color,
                                        borderWidth: 0
                                    }}>
                                    {this.props.option.label}
                                </span>
                                {!isResetOption && (
                                    <View
                                        style={{
                                            opacity: this.props.option.isSelected ? 1 : UN_SELECTED_OPACITY_IMG,
                                            marginLeft: 9,
                                            marginTop: ICON_MARGIN_TOP,
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                        {this.getSelectIcon(this.props.option.isSelected)}
                                    </View>
                                )}
                            </View>
                        </View>
                    </View>
                </TouchableOpacity>
            </Hoverable>
        )
    }
}
