import { durationToText } from '@shared/timer-helpers'
import { View } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import * as chelpers from '@shared/cleaning-helpers'
import moment from 'moment/moment'
import React, { useContext } from 'react'
import { TaskScreenContext } from './TaskScreenContext'
import { UNIT_HEADER_FONT_SIZE } from './consts'
import Assignees from '../../../components/assignees'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { TaskStruct } from '@shared/firestore-structs'

const LastHousekeeping = ({
    lastCleaningTask
}: {
    lastCleaningTask: Pick<TaskStruct, 'cleaning' | 'assignedTo'> & { bedSetup?: string }
}) => {
    const { selectedDateNumber } = useContext(TaskScreenContext)
    const { currentUser } = useContext(AuthContext)
    const task = lastCleaningTask
    const FONT_SIZE = 15
    const DEBUG_BORDER = 0

    const PADDING_LEFT = 6

    const dateFormat = {
        sameDay: '[Today at ] H:mm',
        lastDay: '[Yesterday at] H:mm',
        lastWeek: '[Last] dddd [at] H:mm',
        sameElse: 'dddd, MMM D YYYY [at] H:mm'
    }

    const MARGIN_BETWEEN_UNITS = 12

    const MARGIN_BETWEEN_LINES = 4
    const DESCRIPTION_FONT_SIZE = 14

    let durationText = ''

    if (task && task.cleaning && task.cleaning.end) {
        durationText = durationToText(task.cleaning)
    }

    return (
        <View
            style={{
                borderColor: 'red',
                borderWidth: 0
            }}>
            <span
                style={{
                    fontWeight: '600',
                    color: iOSColors.black,
                    fontSize: UNIT_HEADER_FONT_SIZE,
                    // marginBottom: 12,
                    overflow: 'visible'
                }}>
                Last housekeeping
            </span>

            <View>
                <View
                    style={{
                        marginTop: chelpers.isToday(selectedDateNumber) ? 8 : 0,
                        flexDirection: 'column',

                        borderWidth: DEBUG_BORDER,
                        borderColor: 'red',
                        marginBottom: MARGIN_BETWEEN_UNITS
                    }}>
                    <span
                        style={{
                            fontSize: DESCRIPTION_FONT_SIZE,
                            color: iOSColors.midGray,
                            marginBottom: MARGIN_BETWEEN_LINES
                        }}>
                        Finished
                    </span>

                    <span
                        style={{
                            fontSize: FONT_SIZE,
                            color: iOSColors.gray
                        }}>
                        {moment(task.cleaning?.end).calendar(null, dateFormat)}
                    </span>
                </View>
                {task.assignedTo && (
                    <View
                        style={{
                            flexDirection: 'column',
                            borderWidth: DEBUG_BORDER,
                            borderColor: 'red',
                            marginBottom: MARGIN_BETWEEN_UNITS
                        }}>
                        <span
                            style={{
                                fontSize: DESCRIPTION_FONT_SIZE,
                                color: iOSColors.midGray,
                                marginBottom: MARGIN_BETWEEN_LINES
                            }}>
                            Done by
                        </span>
                        <Assignees assignees={task.assignedTo} currentUser={currentUser} />
                    </View>
                )}
                {durationText.length > 0 && (
                    <View
                        style={{
                            flexDirection: 'column',

                            borderColor: 'yellow',
                            borderWidth: DEBUG_BORDER
                        }}>
                        <span
                            style={{
                                color: iOSColors.midGray,
                                fontSize: DESCRIPTION_FONT_SIZE,
                                marginBottom: MARGIN_BETWEEN_LINES
                            }}>
                            Duration
                        </span>
                        <span
                            style={{
                                color: iOSColors.gray,
                                fontSize: FONT_SIZE
                            }}>
                            {durationText}
                        </span>
                    </View>
                )}
            </View>

            {task.bedSetup && (
                <View
                    style={{
                        paddingLeft: PADDING_LEFT,
                        flexDirection: 'column',
                        borderColor: 'yellow',
                        borderWidth: DEBUG_BORDER,
                        marginBottom: MARGIN_BETWEEN_UNITS
                    }}>
                    <span
                        style={{
                            color: iOSColors.midGray,
                            fontSize: DESCRIPTION_FONT_SIZE,
                            marginBottom: MARGIN_BETWEEN_LINES
                        }}>
                        Last bed setup
                    </span>
                    <span
                        style={{
                            color: iOSColors.gray,
                            fontSize: FONT_SIZE
                        }}>
                        {task.bedSetup}
                    </span>
                </View>
            )}
        </View>
    )
}

export default LastHousekeeping
