import React from 'react'
import { ColumnHeaderWrap, Count, MinimizeButton, Title, TitleWrap } from './style'
import { Tooltip } from '../../../../components/misc/Tooltip'
import { useRecoilState } from 'recoil'
import { minimizedTasksAtom } from '../../../state/atoms'
import AsyncStorage from '@react-native-community/async-storage'
import { TaskStatus as TS } from '@shared/firestore-structs'

interface IColumnHeader {
    title: string
    count: number
    borderColor: string
    lane: TS
}

export default function ColumnHeader({ title, count, borderColor, lane }: IColumnHeader) {
    const [minimizedTasks, setMinimizedTasks] = useRecoilState(minimizedTasksAtom)

    const isMinimized = minimizedTasks.includes(lane)
    const tooltipMinimizedText = isMinimized ? 'Maximize tasks' : 'Minimize tasks'

    function toggleMinimized() {
        const updatedMinimizedTasks = isMinimized
            ? minimizedTasks.filter(minimizedTask => minimizedTask !== lane)
            : [...minimizedTasks, lane]

        setMinimizedTasks(updatedMinimizedTasks)
        AsyncStorage.setItem('minimizedTasks', JSON.stringify(updatedMinimizedTasks))
    }

    return (
        <ColumnHeaderWrap borderColor={borderColor}>
            <TitleWrap>
                <Title>{title}</Title>
                <Count>({count})</Count>

                {/* minimize task button */}
                <Tooltip position={'top'} text={tooltipMinimizedText}>
                    <MinimizeButton isMinimized={!isMinimized} onClick={toggleMinimized}>
                        <i className="specta-arrow-right" />
                    </MinimizeButton>
                </Tooltip>
            </TitleWrap>
        </ColumnHeaderWrap>
    )
}
