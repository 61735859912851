import moment from 'moment-timezone'
import { CleaningStruct } from './firestore-structs'

export interface TimerDisplay {
    hours: string
    min: string
    sec: string
}

export function getHousekeepingDuration(cleaningObj: CleaningStruct, timeUnit: 'seconds' | 'minutes' | 'hours') {
    let sinceStart = 0
    const now = moment()

    if (cleaningObj.end) {
        const start = moment(cleaningObj.start ?? cleaningObj.end)
        const end = moment(cleaningObj.end)
        sinceStart = end.diff(start, timeUnit)
    } else if (cleaningObj.pause && cleaningObj.pause.length > 0) {
        for (let i = 0; i < cleaningObj.pause.length; i++) {
            const start = moment(cleaningObj.play[i])
            const pause = moment(cleaningObj.pause[i])
            sinceStart += pause.diff(start, timeUnit)
        }

        if (cleaningObj.play.length > cleaningObj.pause.length) {
            const lastPlay = cleaningObj.play[cleaningObj.play.length - 1]
            sinceStart += now.diff(lastPlay, timeUnit)
        }
    } else {
        const start = moment(cleaningObj.start)
        sinceStart = now.diff(start, timeUnit)
    }
    return sinceStart
}

export function getTimerDisplay(cleaningObj: CleaningStruct | null): TimerDisplay {
    if (!cleaningObj)
        return {
            hours: '00',
            min: '00',
            sec: '00'
        }

    const sinceStart = getHousekeepingDuration(cleaningObj, 'seconds')

    const timerDisplay = {
        hours: moment().hour(0).minute(0).second(sinceStart).format('HH'),
        min: moment().hour(0).minute(0).second(sinceStart).format('mm'),
        sec: moment().hour(0).minute(0).second(sinceStart).format('ss')
    }

    return timerDisplay
}

export function durationToText(cleaningObj: CleaningStruct, showPostFix = false) {
    const min = getHousekeepingDuration(cleaningObj, 'minutes')

    const num = min
    const hours = num / 60
    const rhours = Math.floor(hours)
    const minutes = (hours - rhours) * 60
    const rminutes = Math.round(minutes)
    let hoursPostfix = ''
    let minPostfix = ''

    if (rhours > 1) {
        hoursPostfix = 's'
    }

    if (minutes !== 1) {
        minPostfix = 's'
    }

    let postfix = ''
    if (showPostFix) {
        postfix = ' ago'
    }

    if (rhours >= 1) {
        return rhours + ' hour' + hoursPostfix + ' and ' + rminutes + ' minute' + minPostfix + postfix
    }
    return rminutes + ' minute' + minPostfix + postfix
}
