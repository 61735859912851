import React from 'react'
import { MessageWrap, Wrapper } from './style'

interface Props {
    icon: string
    message: string
    row2?: string
    row3?: string
    actionText?: string
    onActionClick?: () => void
}

function NoItemsMessage({ icon, message, row2, row3, actionText, onActionClick }: Props) {
    return (
        <Wrapper>
            <MessageWrap>
                <img src={icon} />
                <span className="row-1">{message}</span>
                <span className="subtext-row">
                    {row2 && <span className="row-2">{row2}</span>}
                    {actionText && (
                        <span onClick={onActionClick && onActionClick} className="action">
                            {actionText}
                        </span>
                    )}
                </span>

                {row3 && <span className="row-3"> {row3}</span>}
            </MessageWrap>
        </Wrapper>
    )
}

export default NoItemsMessage
