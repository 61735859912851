import React, { useMemo, useState } from 'react'
import { FilterButtonWrap } from '../style'
import DailyCommentButton from './DailyCommentButton'
import DailyCommentsSummary from './DailyCommentsSummary'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { dailyCommentsAtom, subscriptionParamsAtom } from 'app/modules/HousekeepingDashboard/state/atoms'
import { SubscriptionCheck } from 'app/components/upgrade-signals'

function DailyComments() {
    const [show, setShow] = useState(false)
    const dailyComments = useRecoilValue(dailyCommentsAtom)

    const setSubscriptionParams = useSetRecoilState(subscriptionParamsAtom)

    const count = useMemo(() => {
        return dailyComments.filter(({ comment }) => comment.length > 0).length
    }, [dailyComments])

    return (
        <FilterButtonWrap>
            <SubscriptionCheck
                featureId="daily-comments"
                onAvailable={() => <DailyCommentButton count={count} onClick={() => setShow(!show)} />}
                onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                    return (
                        <DailyCommentButton
                            count={count}
                            onClick={() =>
                                setSubscriptionParams({
                                    hasSubscriptionAccess: false,
                                    notAccessibleFeature,
                                    availableUpgrades,
                                    upgradeMessageIsOpen: true
                                })
                            }
                        />
                    )
                }}
            />

            {show && <DailyCommentsSummary onClose={() => setShow(false)} />}
        </FilterButtonWrap>
    )
}

export default DailyComments
