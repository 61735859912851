import React from 'react'
import { useHistory } from 'react-router-dom'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { getWorkflowInstance, storeWorkflowInstance } from '../../../../utils/signup-flow-template/signup-workflow-instance'
import { currentIntegrationKey } from '../../signup-worflow-templates'
import CustomerCredentialsForm from '../../CustomerCredentials'

const GuestlineAuthorize = () => {
    const history = useHistory()
    const workflowInstance = getWorkflowInstance(currentIntegrationKey)
    const bus = workflowInstance.getInput('bus')

    const onSubmit = values => {
        const { siteId, operatorCode, password, inspectionCode, dirtyCode } = values

        workflowInstance.completeStep(SIGN_UP.GUESTLINE_AUTHORIZE, { siteId, operatorCode, password, inspectionCode, dirtyCode })
        storeWorkflowInstance(currentIntegrationKey, workflowInstance)

        setTimeout(() => history.push(workflowInstance.nextStep()), 10)
    }

    return <CustomerCredentialsForm bus={bus} onSubmit={onSubmit} />
}

export default GuestlineAuthorize
