import moment from 'moment-timezone'
import React from 'react'
import Chart from 'react-apexcharts'
import * as colors from '@shared/colors'

export function ChartSummary(props) {
    const formattedDates = props.dates.map(x => {
        return moment(x).format('MMM DD, YY')
    })
    const pink = colors.pink_spectaflow //'rgb(118, 213, 114)'
    const teal = colors.teal_sweeply //'rgb(145, 130, 237)'

    const series = [
        {
            name: 'Requested',
            data: props.summary['optIn'].cols.map(x => x.value),
            color: pink
        },
        {
            name: 'No service',
            data: props.summary['optOut'].cols.map(x => x.value),
            color: teal
        }
    ]
    const options = {
        chart: {
            type: 'bar',
            // stacked: true,
            background: '#fff'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: formattedDates
        },
        yaxis: {
            title: {
                text: 'occurances'
            }
        },
        fill: {
            opacity: 1
        }
    }

    return <Chart options={options} series={series} type={options.chart.type} height={350} background={'#fff'} />
}
