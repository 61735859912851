import React, { useState, useEffect, useContext } from 'react'
import firebase from '../firebase'
import { useHistory } from 'react-router-dom'
import { getWorkflowInstance, storeWorkflowInstance } from '../signup-flow-template/signup-workflow-instance'
import { currentIntegrationKey } from '../../modules/OrganizationSignUp/signup-worflow-templates'
import { AuthContext } from '../../modules/Auth/AuthContext'
import { httpsCallable } from '../../api'

const withIntegrationWorkflow = WrappedComponent => {
    const WithIntegrationWorkflow = props => {
        const { setStopAuthListener } = useContext(AuthContext)
        const [complete, setComplete] = useState(false)
        const history = useHistory()
        const workflowInstance = getWorkflowInstance(currentIntegrationKey)

        const params = new URLSearchParams(history.location.search)
        const settings = {}
        params.forEach((value, key) => (settings[key] = value))

        const message = complete ? 'Your account is ready!' : 'Almost done...'

        const newOrgKey = workflowInstance.getInput('orgKey')
        const newUserKey = workflowInstance.getInput('userKey')
        const signupComplete = httpsCallable('signupComplete')

        useEffect(() => {
            if (complete) {
                setTimeout(() => {
                    history.push(workflowInstance.nextStep())
                    setStopAuthListener(false)
                }, 2000)
            }
        }, [complete])

        return (
            <WrappedComponent
                {...props}
                settings={settings}
                setComplete={setComplete}
                setStopAuthListener={setStopAuthListener}
                message={message}
                newOrgKey={newOrgKey}
                newUserKey={newUserKey}
                signupComplete={signupComplete}
                currentIntegrationKey={currentIntegrationKey}
                workflowInstance={workflowInstance}
                storeWorkflowInstance={storeWorkflowInstance}
            />
        )
    }

    return WithIntegrationWorkflow
}

export default withIntegrationWorkflow
