import React from 'react'
import SearchInput from '../../../components/forms/Search'
import DatePicker from '../../../components/hoverable-date-picker'
import { PriorityButton } from '../../../components/priority-button'
import DailyCommentSummaryButton from '../../daily-comments-summary-button'
import StatusPopup from '../../houskeeping-status-popup'
import OccupancyFilterPopup from '../../occupancy-filter-popup'

import { SubscriptionCheck } from '../../../components/upgrade-signals'
import * as c from '@shared/constants'

export default function DashboardFilter(props) {
    const {
        searchValue,
        selectedDate,
        onDateChange,
        showActionBar,
        onPriorityPressTimeout,
        priorityFilter,
        priorityAreaCounter,
        openDailyCommentsSummary,
        areasWithDailyComments,
        setCleaningStatusFilter,
        cleaningStatusFilter,
        defaultStatusFilter,
        setOccupancyFilter,
        occupancyFilter,
        pickAssignMode,
        currentOrganization,
        openUpgradeMessage,
        onSearch
    } = props

    return (
        <div className="row row-paddingless align-items-start" style={{ position: 'relative', zIndex: 2 }}>
            <div className="col-xs-12 col-md-4 my-sm-3 my-xs-10">
                <SearchInput value={searchValue} onChange={onSearch} placeholder={'Units, assignees and more'} />
            </div>

            <div className="col-xs-12 col-md-4 my-sm-3 my-xs-10 d-flex align-items-center justify-content-center">
                <div style={{ height: 22, width: 22 }} />

                <SubscriptionCheck
                    featureId="browse-history"
                    onAvailable={() => (
                        <DatePicker hasTodayButton selectedDateNumber={selectedDate.valueOf()} onDateChange={onDateChange} />
                    )}
                    onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                        return (
                            <DatePicker
                                hasTodayButton
                                selectedDateNumber={selectedDate.valueOf()}
                                onDateChange={() => {
                                    openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                }}
                            />
                        )
                    }}
                />
            </div>
            <div className="col-xs-12 col-md-4 my-sm-3 my-xs-10">
                <div className="container" style={{ paddingRight: 22, paddingLeft: 90 }}>
                    <div className="row justify-content-between">
                        <PriorityButton
                            onPress={onPriorityPressTimeout}
                            priority={priorityFilter}
                            count={priorityAreaCounter}
                            subscriptonAccess={true}
                        />

                        <SubscriptionCheck
                            featureId="daily-comments"
                            onAvailable={() => (
                                <DailyCommentSummaryButton
                                    onPress={openDailyCommentsSummary}
                                    count={areasWithDailyComments ? areasWithDailyComments.length : 0}
                                />
                            )}
                            onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                                return (
                                    <DailyCommentSummaryButton
                                        onPress={() => {
                                            openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                        }}
                                        count={areasWithDailyComments ? areasWithDailyComments.length : 0}
                                    />
                                )
                            }}
                        />

                        <StatusPopup
                            setStatusFilter={setCleaningStatusFilter}
                            defaultFilter={defaultStatusFilter}
                            statusFilter={cleaningStatusFilter}
                        />

                        <OccupancyFilterPopup
                            setOccupancyFilter={setOccupancyFilter}
                            occupancy={occupancyFilter}
                            options={[
                                { occupancy: c.OCCUPANCY_ALL },
                                { occupancy: c.OCCUPANCY_CHECKIN },
                                { occupancy: c.OCCUPANCY_STAYOVER },
                                { occupancy: c.OCCUPANCY_STAYOVER_80 },
                                { occupancy: c.OCCUPANCY_CHECKOUT },
                                { occupancy: c.OCCUPANCY_VACANT },
                                { occupancy: c.OCCUPANCY_TURNOVER },
                                { occupancy: 'reset' }
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
