import styled, { keyframes, css } from 'styled-components'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'

const gray = colors.gentle_gray_spectaflow
const green = colors.green_spectaflow

interface MinimizedProps {
    minimized: boolean
}

interface FontSizeProps {
    fontSize?: string
}
interface TaskRowWrapProps {
    isVisible: boolean
    completed: boolean
}
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`
export const TaskRowWrap = styled.div<TaskRowWrapProps>`
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    padding: 9px;
    width: 100%;
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'hidden')};
    align-items: flex-start;
    flex-direction: column;
    background-color: ${gray}
    margin-bottom: 16px;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    animation: ${({ isVisible }) =>
        isVisible
            ? css`
                  ${fadeIn} 0.5s ease-in-out
              `
            : css`
                  ${fadeOut} 0.5s ease-in-out
              `};


    &:hover {
        background-color: ${colors.gentle_gray_spectaflow}
    }
`

export const Header = styled.div<{ completed: boolean; minimized: boolean }>`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ minimized }) => (minimized ? '0' : '8px')};
    cursor: ${({ completed }) => (completed ? 'default' : 'pointer')};
    position: relative;
`

export const ContentWrap = styled.div<{ completed: boolean }>`
    display: flex;
    width: 100%;
    cursor: ${({ completed }) => (completed ? 'default' : 'pointer')};
`

export const GeneralVisual = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    border: 1px solid ${iOSColors.lightGray};
    border-radius: 6px;
    background-color: ${iOSColors.white};
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
        width: 30px;
        height: 30px;
    }
`

export const InfoWrap = styled.div`
    display: flex;
    width: 100%;
    flex-direction: ${({ minimized }: MinimizedProps) => (minimized ? 'row !important' : 'column')};
    padding-left: ${({ minimized }: MinimizedProps) => (minimized ? '0' : '9px')};
    justify-content: space-between;
    flex-direction: column;
`

export const Name = styled.span`
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
`
export const Hashtag = styled.span`
    margin-top: 2px;
    display: flex;
    justify-content: center;
    margin-left: 4px;
    font-size: 12px;
    color: ${green};
`

export const DueDateAndInitials = styled.div`
    display: flex;
    align-items: center;
    width: ${({ minimized }: MinimizedProps) => (minimized ? 'unset' : '100%')};
`

export const BreadCrumbs = styled.div`
    display: flex;
    flex-wrap: wrap;
    word-break: keep-all;

    > span {
        font-size: ${({ fontSize }: FontSizeProps) => (fontSize ? fontSize : '12px')};
        color: ${iOSColors.gray};
        font-weight: 600;

        &:not(:last-child)::after {
            content: '>';
            margin: 0 6px;
        }
    }
`

export const UsersListWrap = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    > div {
        width: 40%;
        height: 60%;
    }
`
