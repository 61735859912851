import React, { useEffect, useState } from 'react'
import { SearchInput } from '../../components/forms/SearchInput'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { allTasksAtom, fetchedTasksAtom } from '../state/atoms'
import { search } from 'app/utils/formatters'
import { TaskStruct } from '@shared/firestore-structs'

export default function Search() {
    const [searchValue, setSearchValue] = useState('')

    const fetchedTasks = useRecoilValue(fetchedTasksAtom)
    const setAllTasks = useSetRecoilState(allTasksAtom)

    useEffect(() => {
        if (searchValue.length !== 0) setAllTasks(search(searchValue, fetchedTasks, extract))
    }, [fetchedTasks.length])

    function handleSubmit(value: string) {
        setSearchValue(value)
        setAllTasks(search(value, fetchedTasks, extract))
    }

    function extract(task: TaskStruct) {
        const issueName = 'issue' in task ? task.issue.name : ''
        const name = task.name ? task.name : issueName
        const areaName = 'area' in task && task.area.name ? task.area.name : ''
        const assignees = task.assignedTo ? task.assignedTo.map(assignee => assignee.name).join(' ') : ''
        const group = task.area ? task.area.group : ''
        const reservationId = 'reservationId' in task ? `Reservation ${task.reservationId}` : ''
        const propName = 'propName' in task ? task.propName : ''

        return name + areaName + assignees + group + reservationId + propName
    }

    return (
        <div>
            {/* TODO - refactor this component to avoid unnecessary props */}
            <SearchInput
                handleBlur={() => null}
                handleSubmit={handleSubmit}
                setFieldValue={() => null}
                fieldValueToSet={searchValue}
                placeholderText={'Task, unit, assignees, and more'}
                helpText=""
                initValue={searchValue}
                containerStyle={{ width: '80%' }}
                containerClass=""
                hasTimeout={true}
            />
        </div>
    )
}
