import { useEffect } from 'react'
import { getAreasQuery } from '@shared/area-data'
import { getBookingsQuery } from '@shared/booking-data'
import { useCollection, useCollectionOnce } from 'react-firebase-hooks/firestore'
import firebase, { asFirebase, getDataFromSnapshot } from '../../utils/firebase'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    activitiesAtom,
    dailyCommentsAtom,
    areasAtom,
    bookingsAtom,
    cleaningTasksAtom,
    rulesAtom,
    dataLoadingAtom,
    fetchedAreasAtom,
    usersAtom
} from './state/atoms'
import { getActivitiesQuery } from '@shared/activity-data'
import { getDailyCommentsQuery } from '@shared/daily-comments-data'
import { getHousekeepingTasksQuery } from '@shared/task-data'
import { getRulesQuery } from '@shared/rules-data'
import { sortAlphabetically } from 'app/utils/formatters'
import { AreaStruct, UserStruct } from '@shared/firestore-structs'
import { currentOrganizationAtom, currentUserAtom } from '../Auth/atoms'
import { selectedDateNumberSelector } from './state/selectors/selectedDateNumber'
import { getUsersQuery } from '@shared/user-data'

function useHousekeepingFirestore() {
    const selectedDateNumber = useRecoilValue(selectedDateNumberSelector)
    const currentOrganization = useRecoilValue(currentOrganizationAtom)
    const currentUser = useRecoilValue(currentUserAtom) as UserStruct
    const organizationKey = currentOrganization?.key as string

    const setAreas = useSetRecoilState(areasAtom)
    const setFetchedAreas = useSetRecoilState(fetchedAreasAtom)
    const setBookings = useSetRecoilState(bookingsAtom)
    const setActivities = useSetRecoilState(activitiesAtom)
    const setDailyComments = useSetRecoilState(dailyCommentsAtom)
    const setCleaningTasks = useSetRecoilState(cleaningTasksAtom)
    const setRules = useSetRecoilState(rulesAtom)
    const setUsers = useSetRecoilState(usersAtom)

    const setDataLoading = useSetRecoilState(dataLoadingAtom)

    const [areasSnap, loadingAreas, errorAreas] = useCollection(
        getAreasQuery(asFirebase(firebase), organizationKey, true, currentUser.areaGroups)
    )
    const [bookingsSnap, loadingBookings, errorBookings] = useCollection(
        getBookingsQuery(asFirebase(firebase), organizationKey, selectedDateNumber)
    )
    const [activitiesSnap, loadingActivities, errorActivities] = useCollection(
        getActivitiesQuery(asFirebase(firebase), organizationKey, selectedDateNumber)
    )
    const [dailyCommentsSnap, loadingDailyComments, errorDailyComments] = useCollection(
        getDailyCommentsQuery(asFirebase(firebase), organizationKey, selectedDateNumber)
    )
    const [tasksSnap, loadingTasks, errorTasks] = useCollection(
        getHousekeepingTasksQuery(asFirebase(firebase), organizationKey, selectedDateNumber)
    )
    const [rulesSnap, loadingRules, errorRules] = useCollection(getRulesQuery(asFirebase(firebase), organizationKey))
    const [usersSnap, loadingUsers, errorUsers] = useCollectionOnce(getUsersQuery(asFirebase(firebase), organizationKey))

    const LOADING =
        loadingAreas || loadingBookings || loadingActivities || loadingDailyComments || loadingTasks || loadingRules || loadingUsers
    const ERROR = errorAreas || errorBookings || errorActivities || errorDailyComments || errorTasks || errorRules || errorUsers
    const DATA_READY = !LOADING && areasSnap && bookingsSnap && activitiesSnap && dailyCommentsSnap && tasksSnap && rulesSnap && usersSnap

    useEffect(() => {
        if (!LOADING) {
            setTimeout(() => {
                setDataLoading(LOADING)
            }, 200)
        } else {
            setDataLoading(LOADING)
        }
    }, [LOADING])

    useEffect(() => {
        if (!LOADING) {
            setTimeout(() => {
                setDataLoading(LOADING)
            }, 200)
        } else {
            setDataLoading(LOADING)
        }
    }, [LOADING])

    useEffect(() => {
        if (DATA_READY) {
            const areas = getDataFromSnapshot(areasSnap).sort((a: AreaStruct, b: AreaStruct) =>
                sortAlphabetically(a.name, b.name)
            ) as AreaStruct[]

            setBookings(getDataFromSnapshot(bookingsSnap))
            setActivities(getDataFromSnapshot(activitiesSnap))
            setDailyComments(getDataFromSnapshot(dailyCommentsSnap))
            setCleaningTasks(getDataFromSnapshot(tasksSnap))
            setRules(getDataFromSnapshot(rulesSnap))
            setAreas(areas)
            setFetchedAreas(areas)
            setUsers(getDataFromSnapshot(usersSnap))
        }
    }, [areasSnap, bookingsSnap, activitiesSnap, dailyCommentsSnap, tasksSnap, rulesSnap])

    return [ERROR]
}

export default useHousekeepingFirestore
