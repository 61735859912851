import styled from 'styled-components'

export const SearchAndFiltersWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    margin-bottom: 39px;
    margin-top: 9.8px;
`

export const PriorityButtonWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
