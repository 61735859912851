import React, { useState, useEffect } from 'react'

export function SearchInput({
    handleBlur,
    handleSubmit,
    setFieldValue,
    fieldValueToSet,
    placeholderText,
    helpText,
    initValue,
    hasTimeout,
    containerStyle,
    containerClass
}) {
    const [inputValue, setInputValue] = useState('')
    const [searchTimeout, setSearchTimeout] = useState()

    useEffect(() => {
        // if (inputValue.length === 0) {
        setInputValue(initValue)
        // }
    }, [initValue])

    return (
        <div className={'form-group m-0 ' + containerClass} style={containerStyle}>
            <div className="input-icon">
                <input
                    type="text"
                    className="form-control bg-specta-gray border-specta-gray rounded font-color-primary font-size-base placeholder-dark-50 placeholder-bold pl-10"
                    name="searchText"
                    data-testid="search-box"
                    placeholder={placeholderText}
                    onBlur={handleBlur}
                    value={inputValue}
                    onChange={e => {
                        setFieldValue && setFieldValue(fieldValueToSet, e.target.value)
                        setInputValue && setInputValue(e.target.value)
                        if (hasTimeout) {
                            if (searchTimeout) {
                                clearTimeout(searchTimeout)
                            }

                            const st = setTimeout(() => {
                                handleSubmit(e.target.value)
                            }, 200)
                            setSearchTimeout(st)
                        } else {
                            handleSubmit(e.target.value)
                        }
                    }}
                />
                <span style={{ width: 0, paddingLeft: 18 }}>
                    <i className="specta-search icon-md"></i>
                </span>
                {(inputValue.length > 0 || initValue.length > 0) && (
                    <span style={{ right: '0', left: 'auto' }} data-testid="clear-search-box">
                        <img
                            style={{
                                width: 14,
                                height: 14
                            }}
                            className="ml-auto mr-3 cursor-pointer"
                            src={require('@shared/img/clear-text.svg')}
                            onClick={() => {
                                setFieldValue && setFieldValue(fieldValueToSet, '')
                                setInputValue && setInputValue('')
                                handleSubmit('')
                            }}
                        />
                    </span>
                )}
            </div>
            <small className="form-text text-muted">
                {helpText && helpText.length > 0 && (
                    <>
                        <b>Search</b> {helpText}
                    </>
                )}
            </small>
        </div>
    )
}
