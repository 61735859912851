import React, { useEffect } from 'react'
import { compose } from 'redux'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import withIntegrationWorkflow from '../../../../utils/hocs/withIntegrationWorkflow'
import SignUpLayout from '../../SignUpLayout/index'
import LoadingScreen from '../../LoadingScreen/index'
import { beds24setup } from '../../cloud-functions'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { errorModalConfigs } from '../../helpers'
import { INTEGRATIONS, PMS_NAMES } from '../../../../utils/constants'

const getPartner = bus => {
    switch (bus) {
        case PMS_NAMES.GODO:
            return 'godo'
        case 'BookingAutomation':
            return 'booking-automation'
        default:
            return 'beds24'
    }
}

const Beds24Config = ({
    newOrgKey,
    catchError,
    signupComplete,
    workflowInstance,
    storeWorkflowInstance,
    setComplete,
    currentIntegrationKey,
    newUserKey,
    settings,
    message,
    setStopAuthListener
}) => {
    const setupBeds24 = async () => {
        const setupBeds24Integration = beds24setup()
        const bed24AccountIDs = workflowInstance.getInput('propownerId')

        try {
            const { data } = await setupBeds24Integration({
                orgId: '' + newOrgKey,
                integration: INTEGRATIONS.BEDS_24,
                signupPayload: { bed24AccountIDs, options: {}, partner: getPartner(workflowInstance.getInput('bus')) }
            })

            if (data.reason !== 'all good') {
                catchError(data.message, errorModalConfigs)
                return
            }

            if ('test' in workflowInstance.values) {
                data.reason = 'test'
            }

            await signupComplete({ orgId: newOrgKey, userId: newUserKey, status: data })

            workflowInstance.completeStep(SIGN_UP.BEDS_24_CONFIG, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    async function setup() {
        setStopAuthListener(true)
        await setupBeds24()
    }

    useEffect(() => {
        setup()
    }, [])

    return (
        <SignUpLayout withModal>
            <LoadingScreen message={message} />
        </SignUpLayout>
    )
}

export default compose(withIntegrationWorkflow, withErrorCatching)(Beds24Config)
