import styled from 'styled-components'
import { getBrand } from '@shared/brand'
import * as colors from '@shared/colors'
import { MOBILE_BREAKPOINT, SMALL_DESKTOP_SCREEN_HEIGHT } from '../../../utils/constants'

const black = colors.black_sweeply
const brandColor = getBrand().navBarColor
const blue = colors.medium_blue
const red = colors.main_red
const yellow = colors.main_yellow

export const MagicLinkWrap = styled.div`
    > div {
        width: 377px;
        margin: 64.89px auto 89.46px auto;
        padding-top: 10px;

        h1,
        p {
            color: ${black};
        }

        h1 {
            font-weight: 700;
            font-size: 2.462rem;
            line-height: 48px;
            margin-bottom: 16px;
        }

        p {
            font-weight: 450;
            font-size: 1.077rem;
            line-height: 24px;
            margin-bottom: 32px;
        }

        span {
            font-weight: 450;
            font-size: 1.846rem;
            line-height: 40px;
            color: ${brandColor};
        }
    }

    @media (max-height: ${SMALL_DESKTOP_SCREEN_HEIGHT}px) {
        > div {
            margin: 25px auto 32px auto;

            h1 {
                margin-bottom: 8px;
            }

            p {
                margin-bottom: 16px;
            }
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;

        > div {
            width: 100%;

            h1 {
                font-size: 2rem;
            }

            span {
                font-size: 1rem;
            }
        }
    }
`

export const RectanglesWrap = styled.div`
    position: relative;
    width: 100%;
    height: 215.75px;

    > div {
        position: absolute;
    }

    .rectangle-5 {
        width: 21.9px;
        height: 21.9px;
        border-radius: 100px;
        background-color: ${blue};
        right: 20px;
        top: 0;
    }

    .rectangle-4 {
        width: 20.71px;
        height: 21.34px;
        background-color: ${red};
        border-radius: 4px;
        transform: rotate(-150deg);
        top: 15px;
        left: 80px;
    }

    .rectangle-3 {
        background-color: ${yellow};
        border-radius: 8px;
        transform: rotate(-180deg);
        width: 37.79px;
        height: 37.79px;
        top: 100px;
        left: 110px;
    }

    .rectangle-2 {
        background-color: ${yellow};
        border-radius: 4px;
        transform: rotate(135deg);
        width: 12.81px;
        height: 12.81px;
        bottom: 35px;
        right: 60px;
    }

    .rectangle-1 {
        background-color: ${blue};
        border-radius: 4px;
        width: 8.79px;
        height: 38.28px;
        bottom: -8px;
        left: 5px;
    }

    @media (max-height: ${SMALL_DESKTOP_SCREEN_HEIGHT}px) {
        height: 190px;
    }
`
