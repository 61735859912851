import { View } from 'react-native-web'
import React, { useContext } from 'react'
import { CleaningScheduleLocal } from 'app/modules/Guests/GuestHome/pages/CleaningSchedule/CleaningScheduleLocal'
import { TaskScreenContext } from './TaskScreenContext'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { BookingStruct } from '@shared/firestore-structs'

const CleaningSchedule = ({ leadBooking }: { leadBooking: BookingStruct }) => {
    const { area, selectedDateNumber } = useContext(TaskScreenContext)
    const { currentUser, currentOrganization } = useContext(AuthContext)

    return (
        <View style={{ marginBottom: 0, borderWidth: 0, width: '100%' }}>
            <div className="d-flex mb-4">
                <i className="specta-rules font-size-h3 line-height-xl text-dark mr-4" />
                <span className="font-weight-bolder font-size-h2">Housekeeping plan</span>
            </div>
            <span className="text-muted font-weight-bolder font-size-h6 mb-7">Press a day to either clean or skip cleaning.</span>

            <CleaningScheduleLocal
                allBookings={area.bookings}
                selectedDateNumber={selectedDateNumber}
                guestData={{
                    area: area,
                    booking: leadBooking,
                    rules: area.rules,
                    organization: currentOrganization!
                }}
            />
        </View>
    )
}

export default CleaningSchedule
