export const areaModalStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        borderRadius: 4,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '80vh',
        width: '75%',
        maxHeight: '80vh', // <-- This sets the height
        overflow: 'scroll', // <-- This tells the modal to scrol
        borderWidth: 0
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0,.2)',
        zIndex: 1002
    }
}

export const upgradeMessageStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        borderRadius: 6,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 320,
        height: 430,
        // maxHeight: '200', // <-- This sets the height
        overflow: 'scroll', // <-- This tells the modal to scrol
        borderWidth: 0
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0,.1)',
        zIndex: 2000
    }
}

export const quickGuideStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        borderRadius: 6,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        // height: '80vh',
        overflow: 'scroll', // <-- This tells the modal to scrol
        borderWidth: 0
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0,.4)',
        zIndex: 1005
    }
}

export const onboardingVideoStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        borderRadius: 6,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 768,
        height: 432,
        // maxHeight: '200', // <-- This sets the height
        // overflow: 'scroll', // <-- This tells the modal to scrol
        borderWidth: 0
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0,.4)',
        zIndex: 1005
    }
}

export const userListStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        borderRadius: 4,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '60vh',
        width: '50%',
        maxHeight: '200', // <-- This sets the height
        overflow: 'scroll', // <-- This tells the modal to scrol
        borderWidth: 0
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0,.2)',
        zIndex: 10005
    }
}

export const photoItemModalStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        borderRadius: 4,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '60vh',
        width: '50%',
        maxHeight: '200', // <-- This sets the height
        overflow: 'scroll', // <-- This tells the modal to scrol
        borderWidth: 0
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0,.2)',
        zIndex: 1005
    }
}
