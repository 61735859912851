import * as helpers from '@shared/helpers'

export function getAreasQuery(firebase, organizationKey) {
    return firebase
        .firestore()
        .collection('areas')
        .where('organizationKey', '==', organizationKey)
        .where('visible', '==', true)
        .where('displayCleaningStatus', '==', true)
}

export function getActivitiesQuery(firebase, organizationKey, date) {
    return firebase.firestore().collectionGroup('activities').where('organizationKey', '==', organizationKey).where('date', '==', date)
}

export function getRulesQuery(firebase, organizationKey) {
    return firebase.firestore().collection('rules').where('organizationKey', '==', organizationKey).where('visible', '==', true)
}

export function getBookingsQuery(firebase, organizationKey, date, areaKey = null) {
    let bookingsQuery = firebase
        .firestore()
        .collection('bookings')
        .where('organizationKey', '==', organizationKey)
        // .where('checkoutDate', '==', 1614038400000)
        .where('bookingDates', 'array-contains', date.toString())

    if (areaKey) {
        bookingsQuery = bookingsQuery.where('areaKey', '==', areaKey)
    }

    return bookingsQuery
}

export function getDailyCommentsQuery(firebase, organizationKey, date, areaKey = null) {
    let dailyCommentsQuery = firebase
        .firestore()
        .collectionGroup('dailyComments')
        .where('organizationKey', '==', organizationKey)
        .where('date', '==', date)

    if (areaKey) {
        dailyCommentsQuery = dailyCommentsQuery.where('areaKey', '==', areaKey)
    }

    return dailyCommentsQuery
}

export async function getBookings(firebase, organizationKey, date, areaKey = null) {
    let bookingQuery = getBookingsQuery(firebase, organizationKey, date, areaKey)

    let bookingsRef = await bookingQuery.get()

    let bookings = bookingsRef.docs.map(b => b.data())

    bookings = bookings.filter(b => b.status && b.status.toLowerCase() !== 'cancelled')
    bookings = bookings.sort((a, b) => helpers.sortTimeStampAscending(a.checkinDate, b.checkinDate))

    return bookings
}

export async function getActivities(firebase, organizationKey, date) {
    let activitesQuery = getActivitiesQuery(firebase, organizationKey, date)

    let activitesRef = await activitesQuery.get()

    let activities = activitesRef.docs.map(b => b.data())
    activities = activities.sort((a, b) => b.created - a.created)

    return activities
}

export async function getRules(firebase, organizationKey) {
    let rulesQuery = getRulesQuery(firebase, organizationKey)

    let rulesRef = await rulesQuery.get()

    let rules = rulesRef.docs.map(b => b.data())

    return rules
}

export async function getDailyComments(firebase, organizationKey, date, areaKey = null) {
    let dailyCommentsQuery = getDailyCommentsQuery(firebase, organizationKey, date, areaKey)

    let dailyCommentsRef = await dailyCommentsQuery.get()

    let dailyComments = dailyCommentsRef.docs.map(b => b.data())
    dailyComments = dailyComments.sort((a, b) => b.created - a.created)

    return dailyComments
}
