import React, { useState } from 'react'
import { TouchableOpacity, Text } from 'react-native-web'
import Hoverable from '../utils/hoverable/hoverable'
import * as colors from '@shared/colors'
import { HOVER_OPACITY } from '../utils/constants'

// *active  *type *hover

export const OrderedButton = props => {
    const [hover, setHover] = useState(false)

    const active = props.active ? props.active : false
    const height = props.height ? props.height : 22
    const color = props.color ? props.color : colors.green_spectaflow

    const marginLeft = props.marginLeft ? props.marginLeft : 0
    const marginRight = props.marginRight ? props.marginRight : 0
    const fontSize = props.fontSize ? props.fontSize : 14
    const fontWeight = props.fontWeight ? props.fontWeight : '400'
    const paddingHorizontal = props.paddingHorizontal ? props.paddingHorizontal : 7
    const paddingVertical = props.paddingVertical ? props.paddingVertical : 0
    const backgroundColor = props.backgroundColor ? props.backgroundColor : 'transparent'
    const showBorder = props.showBorder === false ? false : true
    const borderRadius = props.borderRadius ? props.borderRadius : 20
    const count_margin_top = props.countMarginTop ? props.countMarginTop : 0

    const no_hover = props.noHover ? props.noHover : false

    const borderWidth = showBorder ? 1 : 0
    let borderColor = props.borderColor ? props.borderColor : color

    let opacity = props.opacity ? props.opacity : 1
    if (hover) {
        if (no_hover) {
            opacity = 1
        } else {
            opacity = HOVER_OPACITY
        }
    }

    return (
        <Hoverable
            onHoverIn={() => {
                setHover(true)
            }}
            onHoverOut={() => {
                setHover(false)
            }}>
            <TouchableOpacity
                testID="group-button"
                style={{
                    flexDirection: 'row',
                    opacity: opacity,
                    borderWidth: borderWidth,
                    borderRadius: borderRadius,
                    borderColor: borderColor,
                    marginLeft: marginLeft,
                    marginRight: marginRight,
                    backgroundColor: backgroundColor,
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingHorizontal: paddingHorizontal,
                    paddingVertical: paddingVertical,
                    height: height
                }}
                disabled={!active}
                onPress={props.onPress}>
                <span
                    data-testid="group-name"
                    style={{
                        fontWeight: fontWeight,
                        color: color
                    }}
                    className="font-size-base">
                    {props.text ? props.text : ''}
                </span>
                {props.count && (
                    <span
                        className="ml-1"
                        data-testid="count"
                        style={{
                            fontWeight: fontWeight,
                            marginTop: count_margin_top,
                            fontSize: fontSize - 3,
                            color: color
                        }}>
                        ({props.count})
                    </span>
                )}
            </TouchableOpacity>
        </Hoverable>
    )
}
