export function getBookingsQuery(firebase, organizationKey, date, areaKey = null) {
    let bookingsQuery = firebase
        .firestore()
        .collection('bookings')
        .where('organizationKey', '==', organizationKey)
        // .where('checkoutDate', '==', 1614038400000)
        .where('bookingDates', 'array-contains', date.toString())

    if (areaKey) {
        bookingsQuery = bookingsQuery.where('areaKey', '==', areaKey)
    }

    return bookingsQuery
}
