import styled from 'styled-components'
import { light_grey_spectaflow, bs_gray_600, bs_gray_800 } from '@shared/colors'

export const Wrapper = styled.div`
    width: 100%;
    height: 38.4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${light_grey_spectaflow};
    border-radius: 6px;
    padding: 0 9.8px;
`

export const InputWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 6.9px;
    padding-right: 9.9px;

    > i {
        color: ${bs_gray_600};
    }
`

export const Input = styled.input`
    width: 100%;
    height: 100%;
    background-color: inherit;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    color: ${bs_gray_800};
`

export const ClearButton = styled.img`
    width: 14px;
    height: 14px;
    cursor: pointer;
`
