import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { startWorkflow } from '../../helpers'
import { PMS_NAMES } from '../../../../utils/constants'
import mewsLogo from '../../../../img/pms/mews/mews-logo.png'
import { MEWS_WORKFLOW } from '../../signup-worflow-templates'
import { SIGN_UP } from '../../../../navigation/url-constants'

const MewsStart = () => {
    const history = useHistory()

    useEffect(() => {
        startWorkflow({
            bus: PMS_NAMES.MEWS,
            imageUrl: mewsLogo,
            workflowTemplate: MEWS_WORKFLOW,
            currentStep: SIGN_UP.MEWS_START,
            history
        })
    }, [])

    return <></>
}

export default MewsStart
