import { useEffect, useMemo } from 'react'
import { getDataFromSnapshot } from 'app/utils/QuerySnapshotData'
import { getActivitiesQuery, getAreasQuery, getBookingsQuery, getDailyCommentsQuery, getRulesQuery, getUsersQuery } from './api'
import {
    getGeneralCompletedTasksQueryWithPMSIntegration,
    getGeneralActiveTasksQueryWithPMSIntegration,
    getHousekeepingTasksQueryWithPMSIntegration,
    getIssueTasksQueryWithPMSIntegration,
    getNoDueDateTasksQueryWithPMSIntegration
} from '@shared/task-data'
import { useCollection, useCollectionOnce, useDocumentOnce } from 'react-firebase-hooks/firestore'
import { AreaStruct, OrgStruct, TaskStruct, UserStruct } from '@shared/firestore-structs'
import firebase, { asFirebase } from '../utils/firebase'
import {
    activitiesAtom,
    areasAtom,
    bookingsAtom,
    currentTasksTypesAtom,
    dailyCommentsAtom,
    dataLoadingAtom,
    rulesAtom,
    taskboardContextAtom,
    usersAtom
} from './state/atoms'
import { integrationParamsAtom } from 'app/traces/state/atoms'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { allTasksSelector } from './state/selectors/allTasks'
import { selectedDateNumberSelector } from './state/selectors/selectedDateNumber'
import { currentOrganizationAtom, currentUserAtom } from 'app/modules/Auth/atoms'
import * as c from '@shared/constants'
import { TaskboardContext } from '@shared/traces-types'
import { getAreaQuery } from '@shared/area-data'

export default function useTaskboardFirestore() {
    const integrationParams = useRecoilValue(integrationParamsAtom)

    const taskboardContext = useRecoilValue(taskboardContextAtom)
    const selectedDateNumber = useRecoilValue(selectedDateNumberSelector)
    const currentTasksTypes = useRecoilValue(currentTasksTypesAtom)
    const currentUser = useRecoilValue(currentUserAtom) as UserStruct
    const currentOrganization = useRecoilValue(currentOrganizationAtom) as OrgStruct

    const setAllTasks = useSetRecoilState(allTasksSelector)
    const setAreas = useSetRecoilState(areasAtom)
    const setUsers = useSetRecoilState(usersAtom)
    const setActivities = useSetRecoilState(activitiesAtom)
    const setBookings = useSetRecoilState(bookingsAtom)
    const setDailyComments = useSetRecoilState(dailyCommentsAtom)
    const setRules = useSetRecoilState(rulesAtom)

    const setDataLoading = useSetRecoilState(dataLoadingAtom)

    const CONTEXT_RESERVATION = taskboardContext === TaskboardContext.RESERVATIONS

    const [housekeepingTasksSnap, loadingHousekeepingTasks, errorHousekeepingTasks] = useCollection<TaskStruct>(
        getHousekeepingTasksQueryWithPMSIntegration(asFirebase(firebase), {
            organizationKey: currentOrganization.key,
            date: selectedDateNumber,
            integrationParams,
            context: taskboardContext,
            currentTasksTypes
        })
    )
    const [issueTasksSnap, loadingIssueTasks, errorIssueTasks] = useCollection<TaskStruct>(
        getIssueTasksQueryWithPMSIntegration(asFirebase(firebase), {
            organizationKey: currentOrganization.key,
            date: selectedDateNumber,
            integrationParams,
            context: taskboardContext,
            currentTasksTypes
        })
    )
    const [generalActiveTasksSnap, loadingGeneralTasks, errorGeneralTasks] = useCollection<TaskStruct>(
        getGeneralActiveTasksQueryWithPMSIntegration(asFirebase(firebase), {
            organizationKey: currentOrganization.key,
            currentTasksTypes,
            context: taskboardContext,
            integrationParams,
            date: selectedDateNumber
        })
    )
    const [completedGeneralTasksSnap, loadingCompletedGeneralTasks, errorCompletedGeneralTasks] = useCollection<TaskStruct>(
        getGeneralCompletedTasksQueryWithPMSIntegration(asFirebase(firebase), {
            organizationKey: currentOrganization.key,
            currentTasksTypes,
            context: taskboardContext,
            date: selectedDateNumber,
            integrationParams
        })
    )
    const [noDueDateTasksSnap, loadingNoDueDateTasks, errorNoDueDateTasks] = useCollection<TaskStruct>(
        getNoDueDateTasksQueryWithPMSIntegration(asFirebase(firebase), {
            organizationKey: currentOrganization.key,
            currentTasksTypes,
            context: taskboardContext,
            integrationParams
        })
    )
    const [areasSnap, loadingAreas, errorAreas] = useCollection<AreaStruct>(
        getAreasQuery(currentOrganization.key, taskboardContext, integrationParams, currentUser.areaGroups)
    )
    const [usersSnap, loadingUsers, errorUsers] = useCollectionOnce(getUsersQuery(currentOrganization.key))

    const includingHousekeepingTasksData = useMemo(() => {
        return currentTasksTypes.includes(c.TASK_TYPE_HOUSEKEEPING) && housekeepingTasksSnap && housekeepingTasksSnap.size > 0
    }, [currentTasksTypes, housekeepingTasksSnap])

    const [activitiesSnap, loadingActivities, errorActivities] = useCollection(
        includingHousekeepingTasksData ? getActivitiesQuery({ organizationKey: currentOrganization.key, date: selectedDateNumber }) : null
    )
    const [bookingsSnap, loadingBookings, errorBookings] = useCollection(
        includingHousekeepingTasksData ? getBookingsQuery({ organizationKey: currentOrganization.key, date: selectedDateNumber }) : null
    )
    const [dailyCommentsSnap, loadingDailyComments, errorDailyComments] = useCollection(
        includingHousekeepingTasksData
            ? getDailyCommentsQuery({ organizationKey: currentOrganization.key, date: selectedDateNumber })
            : null
    )
    const [rulesSnap, loadingRules, errorRules] = useCollection(
        includingHousekeepingTasksData ? getRulesQuery(currentOrganization.key) : null
    )
    const [reservationAreaSnap, loadingReservationArea, errorReservationArea] = useDocumentOnce(
        CONTEXT_RESERVATION && integrationParams?.areaKey ? getAreaQuery(asFirebase(firebase), integrationParams.areaKey) : null
    )

    const loading =
        loadingHousekeepingTasks ||
        loadingIssueTasks ||
        loadingGeneralTasks ||
        loadingAreas ||
        loadingActivities ||
        loadingBookings ||
        loadingDailyComments ||
        loadingRules ||
        loadingCompletedGeneralTasks ||
        loadingNoDueDateTasks ||
        loadingReservationArea
    const error =
        errorHousekeepingTasks ||
        errorIssueTasks ||
        errorAreas ||
        errorActivities ||
        errorBookings ||
        errorDailyComments ||
        errorRules ||
        errorUsers ||
        errorGeneralTasks ||
        errorCompletedGeneralTasks ||
        errorNoDueDateTasks ||
        errorReservationArea
    const dataReady = !loading && !error && generalActiveTasksSnap && completedGeneralTasksSnap && noDueDateTasksSnap && usersSnap

    useEffect(() => {
        if (!loading && dataReady) {
            activitiesSnap && setActivities(getDataFromSnapshot(activitiesSnap))
            bookingsSnap && setBookings(getDataFromSnapshot(bookingsSnap))
            dailyCommentsSnap && setDailyComments(getDataFromSnapshot(dailyCommentsSnap))
            rulesSnap && setRules(getDataFromSnapshot(rulesSnap))

            areasSnap && !CONTEXT_RESERVATION && setAreas(getDataFromSnapshot(areasSnap))
            CONTEXT_RESERVATION &&
                reservationAreaSnap &&
                setAreas(reservationAreaSnap.exists ? [reservationAreaSnap.data() as AreaStruct] : [])

            setAllTasks([
                ...(housekeepingTasksSnap ? getDataFromSnapshot(housekeepingTasksSnap) : []),
                ...(issueTasksSnap ? getDataFromSnapshot(issueTasksSnap) : []),
                ...getDataFromSnapshot(noDueDateTasksSnap),
                ...getDataFromSnapshot(generalActiveTasksSnap),
                ...getDataFromSnapshot(completedGeneralTasksSnap)
            ])
        }
    }, [
        housekeepingTasksSnap,
        issueTasksSnap,
        generalActiveTasksSnap,
        areasSnap,
        activitiesSnap,
        bookingsSnap,
        dailyCommentsSnap,
        rulesSnap,
        completedGeneralTasksSnap,
        noDueDateTasksSnap
    ])

    useEffect(() => {
        if (!loadingUsers && usersSnap) {
            setUsers(getDataFromSnapshot(usersSnap))
        }
    }, [usersSnap])

    useEffect(() => {
        if (includingHousekeepingTasksData) {
            activitiesSnap && bookingsSnap && dailyCommentsSnap && rulesSnap && housekeepingTasksSnap && setDataLoading(loading)
            return
        }

        setDataLoading(loading)
    }, [loading])

    return { error }
}
