import React, { useEffect, useState } from 'react'
import QRCodeGenerator from 'app/components/QR'
import styled from 'styled-components'

export const QRContainer = styled.div`
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
export const QRText = styled.p`
    cursor: pointer;
    margin-top: 20px;
`

const GuestMagicLinkInQR = () => {
    const [token, setToken] = useState('')

    useEffect(() => {
        const searchParams = new URLSearchParams(document.location.search)
        const queryToken = searchParams.get('token')
        if (queryToken) {
            setToken(queryToken)
        }
    }, [])
    return (
        <QRContainer>
            <QRCodeGenerator token={token} text={`${window.location.origin}/guest/login-with-token?token=${token}`} />
        </QRContainer>
    )
}

export default GuestMagicLinkInQR
