import styled from 'styled-components'
import * as colors from '@shared/colors'

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const Input = styled.input`
    width: 100%;
    appearance: none;
    border: 1px solid ${colors.bs_gray_300};
    height: 38.4px;
    padding: 8.45px 13px;
    outline: none;
    border-radius: 6px;
    color: ${colors.bs_gray_800};
    font-size: 1rem;
    transition: border-color 0.2s ease-in-out;

    &:hover {
        border-color: ${colors.bs_success};
    }

    &:focus {
        border-color: ${colors.bs_success};
    }

    &::placeholder {
        color: ${colors.bs_gray_500};
        font-weight: 400;
    }
`

export const Message = styled.p`
    margin: 0;
    padding: 6.5px 0 0 6.5px;
    font-size: 0.9rem;
    font-weight: 400;
    color: ${colors.bs_gray_600};
`
