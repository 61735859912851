import React from 'react'
import { Route } from 'react-router-dom'
import { UsersLoadingDialog } from './users-loading-dialog/UsersLoadingDialog'
import { UserEditDialog } from './user-edit-dialog/UserEditDialog'
import { UsersCard } from './UsersCard'
import { UsersContext, UsersProvider, User } from '../UsersContext'
import { UserDialog } from './user-dialog/UserDialog'

export default function UsersPage({ history, currentOrganization }) {
    const usersUIEvents = {
        openUserDialog: id => {
            history.push(`/settings/users/${id}/view`)
        },
        newUserButtonClick: () => {
            history.push('/settings/users/new')
        },
        openEditUserDialog: id => {
            history.push(`/settings/users/${id}/edit`)
        }
    }

    return (
        <UsersProvider usersUIEvents={usersUIEvents}>
            <UsersLoadingDialog />
            <Route exact path="/settings/users/:id/view">
                {({ history, match }) => (
                    <UsersContext.Consumer>
                        {ctx => (
                            <UserDialog
                                show={match != null}
                                id={match && match.params.id}
                                onHide={() => {
                                    ctx.setUserForEdit(new User())
                                    history.push('/settings/users')
                                }}
                            />
                        )}
                    </UsersContext.Consumer>
                )}
            </Route>
            <Route path="/settings/users/new">
                {({ history, match }) => (
                    <UsersContext.Consumer>
                        {ctx => (
                            <UserEditDialog
                                show={match != null}
                                onHide={() => {
                                    ctx.setUserForEdit(new User())
                                    history.push('/settings/users')
                                }}
                            />
                        )}
                    </UsersContext.Consumer>
                )}
            </Route>
            <Route path="/settings/users/:id/edit">
                {({ history, match }) => (
                    <UsersContext.Consumer>
                        {ctx => (
                            <UserEditDialog
                                show={match != null}
                                id={match && match.params.id}
                                onHide={() => {
                                    ctx.setUserForEdit(new User())
                                    history.push('/settings/users')
                                }}
                            />
                        )}
                    </UsersContext.Consumer>
                )}
            </Route>

            <UsersCard currentOrganization={currentOrganization} />
        </UsersProvider>
    )
}
