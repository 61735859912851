import React from 'react'
import { infoTypes } from './helpers'
import { getBrand } from '@shared/brand'

const CountIndicator = ({ count }) => {
    return (
        <span
            className="d-flex justify-content-center align-items-center bg-specta-yellow zindex-4"
            style={{
                opacity: 0.9,
                width: 20,
                height: 20,
                borderRadius: 20 / 2
            }}>
            <span className="font-size-sm text-white">{count}</span>
        </span>
    )
}
function Buttons({ infoType, onClick, counts }) {
    return (
        <div className="example-preview">
            <ul className={`nav nav-tabs nav-${getBrand().colorClass} nav-tabs-line`} role="tablist">
                {Object.keys(infoTypes).map(type => {
                    return (
                        <li key={type} className="nav-item">
                            <a
                                className={`nav-link font-size-lg ${type === infoType ? 'active' : ''}`}
                                onClick={() => {
                                    onClick(type)
                                }}>
                                <span className="nav-icon">
                                    <i className={`${infoTypes[type].icon} ${infoTypes[type].iconSize}`}></i>
                                </span>
                                <span className={`nav-text ${type === infoType ? 'font-weight-bolder' : 'font-weight-bold'} mr-3`}>
                                    {infoTypes[type].title}
                                </span>
                                {counts[type] > 0 && CountIndicator({ count: counts[type] })}
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Buttons
