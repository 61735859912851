import React from 'react'
import { ContentWrap, LoaderWrap, Wrapper } from './style'
import { ModalOverlay, upgradeMessageStyle } from 'app/components/styled-components/modal'
import Header from './Header'
import CleaningSection from './CleaningSection'
import {
    ActivityStruct,
    AreaStruct,
    BookingStruct,
    DailyCommentStruct,
    RuleStruct,
    TaskStruct,
    UserStruct
} from '@shared/firestore-structs'
import { useHousekeepingModalState } from './useHousekeepingModalState'
import Loader from 'app/components/loaders/Loader'
import DailyCommentSection from './DailyCommentSection'
import UnitNoteSection from './UnitNoteSection'
import ActivitySection from './ActivitySection'
import IssuesSection from './IssuesSection'
import LastHousekeepingSection from './LastHousekeepingSection'
import { lastCleaningTaskAtom, subscriptionParamsAtom } from './state/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import ExtraServicesSection from './ExtraServices'
import BookingNotesSection from './BookingNotesSection'
import ActiveRuleSection from './ActiveRuleSection'
import CleaningScheduleSection from './CleaningScheduleSection'
import Modal from 'react-modal'
import { Subscription } from '@shared/subscriptions/subscription'
import { UpgradeMessageContent } from 'app/components/upgrade-signals'

interface Props {
    onClose: () => void
    area: AreaStruct
    ruleName: string
    activeRule: RuleStruct
    currentDateNumber: number
    rules: RuleStruct[]
    dailyComment: DailyCommentStruct | null
    activities: ActivityStruct[]
    users: UserStruct[]
    task?: TaskStruct | null
    priority: boolean
    bookings: BookingStruct[]
}

function HousekeepingModal({
    onClose,
    area,
    task,
    currentDateNumber,
    rules,
    dailyComment,
    users,
    activities,
    ruleName,
    activeRule,
    priority,
    bookings
}: Props) {
    const [currentArea, loading] = useHousekeepingModalState({
        area,
        task,
        currentDateNumber,
        rules,
        dailyComment,
        users,
        priority,
        bookings,
        activities,
        ruleName,
        activeRule
    })
    const lastCleaningTask = useRecoilValue(lastCleaningTaskAtom)
    const [subscriptionParams, setSubscriptionParams] = useRecoilState(subscriptionParamsAtom)

    function closeUpgradeMessage() {
        setSubscriptionParams({ ...subscriptionParams, upgradeMessageIsOpen: false })
    }

    return (
        <ModalOverlay basic>
            <Modal
                style={upgradeMessageStyle}
                isOpen={subscriptionParams.upgradeMessageIsOpen}
                contentLabel="UpgradeMessages"
                onRequestClose={closeUpgradeMessage}>
                <UpgradeMessageContent
                    onClose={closeUpgradeMessage}
                    notAccessibleFeature={subscriptionParams.notAccessibleFeature}
                    availableUpgrades={subscriptionParams.availableUpgrades as Subscription[]}
                    showCloseButton
                    height={'100%'}
                />
            </Modal>

            {currentArea && (
                <Wrapper>
                    <Header onClose={onClose} />

                    {loading ? (
                        <LoaderWrap>
                            <Loader />
                        </LoaderWrap>
                    ) : (
                        <ContentWrap>
                            <CleaningSection />
                            <CleaningScheduleSection />
                            <DailyCommentSection />
                            <ExtraServicesSection />
                            <UnitNoteSection />
                            <BookingNotesSection />
                            <ActiveRuleSection />
                            {lastCleaningTask && <LastHousekeepingSection lastCleaningTask={lastCleaningTask} />}
                            <IssuesSection />
                            <ActivitySection />
                        </ContentWrap>
                    )}
                </Wrapper>
            )}
        </ModalOverlay>
    )
}

export default HousekeepingModal
