import React from 'react'
import { LoginLayoutWrapper, FooterWrap } from './style'
import { getNavBarNOAuthLogo, getBrand } from '@shared/brand'
import { Link, useLocation } from 'react-router-dom'
import { LOGIN } from 'app/navigation/url-constants'
import { useRecoilValue } from 'recoil'
import { showEmailPasswordLoginLinkAtom } from 'app/traces/state/atoms'

const LoginLayout = ({ children }) => {
    const { helpEmail } = getBrand().urls
    const brandLogo = getNavBarNOAuthLogo()
    const { pathname } = useLocation()
    const showEmailPasswordLoginLink = useRecoilValue(showEmailPasswordLoginLinkAtom)
    const isEmailPass = pathname === LOGIN.EMAIL_PASS
    const otherLoginTypeLink = isEmailPass ? LOGIN.INDEX : LOGIN.EMAIL_PASS
    const otherLoginTypeText = isEmailPass ? 'Phone number login' : 'Email login'

    return (
        <LoginLayoutWrapper>
            <div>
                <img src={brandLogo.img} style={brandLogo.style} />
            </div>

            {children}

            <FooterWrap>
                <span>{'Having problems logging in?'}</span>
                <div>
                    <a href={'mailto:' + helpEmail}>{'Contact support'}</a>
                    {showEmailPasswordLoginLink && <Link to={otherLoginTypeLink}>{otherLoginTypeText}</Link>}
                </div>
            </FooterWrap>
        </LoginLayoutWrapper>
    )
}

export default LoginLayout
