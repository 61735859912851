import styled from 'styled-components'
import { iOSColors } from '@shared/react-native-typography'

export const Wrap = styled.div`
    width: 100%;
    height 63.5px;
    padding: 0 24px;
`

export const TitleWrap = styled.div`
    display: flex;
    column-gap: 8px;
    margin-bottom: 12px;

    > h3 {
        margin: 0;
        font-weight: 500;
        font-size: 1.308rem;
    }
`

export const UsersWrap = styled.div<{ moreThanTwo: boolean }>`
    display: flex;
    flex-direction: ${({ moreThanTwo }) => (moreThanTwo ? 'row' : 'column')};
    column-gap: 8px;
    row-gap: 6px;
`

export const User = styled.div`
    display: flex;
    column-gap: 4px;
    align-items: center;

    > span {
        font-weight: 500;
        font-size: 1.154rem;
        color: ${iOSColors.gray};
    }
`
