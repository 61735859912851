import React, { useState, useEffect, useMemo, useContext, useRef } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import {
    getSelectRow,
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses
} from '../../../../../_metronic/_helpers'
import * as uiHelpers from '../../AreasUIHelpers'
import * as columnFormatters from './column-formatters'
import { Pagination } from '../../../../../_metronic/_partials/controls'
import { AreasContext } from '../../AreasContext'
import { AuthContext } from '../../../Auth/AuthContext'
import { db } from '../../../../utils/firebase'
import LoadingView from '../../../../utils/loading-view'
import moment from 'moment-timezone'
import { areaSearchSet, search, pageIsNotExist } from '@shared/helpers'
import { AreaDeleteDialog } from '../area-delete-dialog/AreaDeleteDialog'

export function AreasTable(props) {
    const [deleteAreaId, setDeleteAreaId] = useState(null)

    const {
        areas,
        setAreas,
        setAllAreas,
        totalCount,
        setTotalCount,
        listLoading,
        setListLoading,
        ids,
        setIds,
        queryParams,
        setQueryParams,
        openEditAreaDialog,
        openDeleteAreaDialog
    } = useContext(AreasContext)

    const { user } = useContext(AuthContext)
    const bsTable = useRef()

    const fields = props.fields

    const areasUIProps = useMemo(() => {
        return {
            ids: ids,
            setIds: setIds,
            queryParams: queryParams,
            setQueryParams: setQueryParams,
            openEditAreaDialog: openEditAreaDialog,
            openDeleteAreaDialog: openDeleteAreaDialog
        }
    }, [ids, setIds, queryParams, setQueryParams, openEditAreaDialog, openDeleteAreaDialog])

    const sortAreas = areas => {
        const sortProperty = queryParams.sortField
        let sortedAreas = areas.sort((a, b) => (a[sortProperty] > b[sortProperty] ? 1 : -1))

        if (queryParams.sortOrder === 'desc') {
            sortedAreas = sortedAreas.reverse()
        }

        sortedAreas = sortedAreas.slice((queryParams.pageNumber - 1) * queryParams.pageSize, queryParams.pageNumber * queryParams.pageSize)
        return sortedAreas
    }

    const getAreas = async () => {
        setListLoading(true)

        const areasRef = await db
            .collection('areas')
            .where('organizationKey', '==', user.organizationKey)
            .where('visible', '==', true)
            .get()

        const fetchedAreas = areasRef.docs.map(a => ({
            id: a.data().key,
            name: a.data().name,
            description: a.data().description,
            group: a.data().group,
            status: a.data().cleaningStatus,
            updated: moment(a.data().updated).format('lll'),
            synced: a.data().synced ? 'Yes' : 'No'
        }))

        setAllAreas(fetchedAreas)

        let filteredAreas = fetchedAreas.filter(
            a =>
                (queryParams.filter.status === '' || a.status === queryParams.filter.status) &&
                (queryParams.filter.group.includes('All') || queryParams.filter.group.includes(a.group)) &&
                (queryParams.filter.synced === '' || a.synced === queryParams.filter.synced)
        )
        filteredAreas = search(queryParams.filter.name, filteredAreas, areaSearchSet)

        const sortedAreas = sortAreas(filteredAreas)
        const totalCount = filteredAreas.length
        const { setQueryParams } = areasUIProps
        const { pageNumber, pageSize } = areasUIProps.queryParams

        if (pageIsNotExist({ totalCount, pageNumber, pageSize })) {
            setQueryParams({ ...areasUIProps.queryParams, pageNumber: 1 })
        }

        setAreas(sortedAreas)
        setListLoading(false)
        setTotalCount(totalCount)
    }

    useEffect(() => {
        getAreas()
    }, [
        queryParams.pageNumber,
        queryParams.pageSize,
        queryParams.sortField,
        queryParams.sortOrder,
        setAreas,
        setIds,
        setListLoading,
        setTotalCount,
        user.organizationKey,
        ids,
        queryParams.filter.status,
        queryParams,
        setAllAreas
    ])

    const { status, synced, name, description, group } = areasUIProps.queryParams.filter

    useEffect(() => {
        setQueryParams({ ...areasUIProps.queryParams, pageNumber: 1 })
    }, [status, synced, name, description, group.length, group[0]])

    const allColumns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            classes: 'font-weight-bolder'
        },
        {
            dataField: 'description',
            text: 'Description',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            style: { maxWidth: '10%' },
            headerStyle: { maxWidth: '10%' }
        },
        {
            dataField: 'group',
            text: 'Group',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortCaret: sortCaret,
            formatter: columnFormatters.StatusColumnFormatter,
            headerSortingClasses
        },
        {
            dataField: 'updated',
            text: 'Last updated',
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: 'synced',
            text: 'Synced',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openEditAreaDialog: areasUIProps.openEditAreaDialog,
                hasSubscriptionAccess: props.hasSubscriptionAccess,
                setShowUpgradeMessage: props.setShowUpgradeMessage,
                setDeleteAreaId
            },
            classes: 'text-right pr-0 px-5',
            headerClasses: 'text-right pr-3 px-5',
            style: {
                minWidth: '130px'
            }
        }
    ]

    const columns = fields ? allColumns.filter(c => fields.includes(c.dataField)) : allColumns
    if (!props.fields) {
        columns.forEach(col => {
            col.events = {
                onClick: (e, column, columnIndex, row) => {
                    if (!props.hasSubscriptionAccess && row.synced !== 'Yes') {
                        props.setShowUpgradeMessage(true)
                    } else if (column.dataField !== 'action') {
                        areasUIProps.openEditAreaDialog(row.id)
                    }
                }
            }
        })
    }

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber
    }

    useEffect(() => {
        if (areasUIProps.ids.length === 0) {
            bsTable.current.selectionContext.selected = []
        } else {
            bsTable.current.selectionContext.selected = areasUIProps.ids
        }
    }, [areasUIProps.ids.length])

    return (
        <div>
            {deleteAreaId && <AreaDeleteDialog deleteAreaId={deleteAreaId} onHide={() => setDeleteAreaId(null)} />}

            {areasUIProps.ids.length > 0 && (
                <div className="row row-paddingless">
                    <div className="col">
                        <span className="font-size-lg font-weight-bolder ml-3">
                            Selected: &nbsp;<span className="font-weight-boldest">{areasUIProps.ids.length}</span>
                        </span>
                    </div>
                </div>
            )}
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination isLoading={listLoading} paginationProps={paginationProps}>
                            <BootstrapTable
                                ref={bsTable}
                                rowStyle={{ cursor: props.fields ? 'default' : 'pointer' }}
                                hover={true}
                                wrapperClasses="table-responsive"
                                bordered={false}
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                remote
                                keyField="id"
                                data={areas === null ? [] : areas}
                                columns={columns}
                                defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(areasUIProps.setQueryParams)}
                                selectRow={getSelectRow({
                                    entities: areas,
                                    ids: areasUIProps.ids,
                                    setIds: areasUIProps.setIds
                                })}
                                {...paginationTableProps}>
                                <PleaseWaitMessage entities={areas} />
                            </BootstrapTable>
                            <NoRecordsFoundMessage entities={areas} />
                        </Pagination>
                    )
                }}
            </PaginationProvider>

            {listLoading && <LoadingView />}
        </div>
    )
}
