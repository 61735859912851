import React, { useContext } from 'react'

import { UpgradeModal } from '../../modules/Subscriptions/UpgradeModal'

import Modal from 'react-modal'
import * as styles from '../dashboard/styles'
import { QuickGuide } from '../../components/quick-guide'
import { UpgradeMessageContent } from '../../components/upgrade-signals'
import DailyCommentsSummary from '../daily-comments-summary'
import * as ahelpers from '@shared/area-groups-helpers'
import HousekeepingDetail from '../housekeeping-modal'
import UsersList from '../../users/users-list'
import { DashboardContext } from './DashboardContext'
import { areaModalStyle } from '../dashboard/styles'
import { AreaStruct, UserStruct } from '@shared/firestore-structs'
import { AuthContext } from '../../modules/Auth/AuthContext'
import { SubscriptionContext } from 'app/modules/Subscriptions/SubscriptionContext'
import { TaskScreenProvider } from './details-modal/TaskScreenContext'
import ProjectionsModal from './details-modal/projections-modal'
import ProjectionsModalView from './details-modal/projections-modal-view'
import * as dataObjects from '@shared/dataObjects'
import * as areaData from '@shared/area-data'
import firebase, { asFirebase } from '../../utils/firebase'
import * as taskData from '@shared/task-data'
import * as c from '@shared/constants'

const ModalPage = ({ areas }: { areas: any[] }) => {
    const {
        showQuickGuideModal,
        setShowQuickGuideModal,
        showUpgradeMessage,
        setShowUpgradeMessage,
        notAccessibleFeature,
        availableUpgrades,
        showDailyCommentSummaryModal,
        setShowDailyCommentSummaryModal,
        selectedDateNumber,
        areaModalIsOpen,
        setAreaModalIsOpen,
        selectedTask,
        selectedArea,
        selectedAreas,
        setSelectedAreas,
        selectUserModalIsOpen,
        setSelectUserModalIsOpen,
        cleaningTaskName,
        allAreas,
        setAllAreas
    } = useContext(DashboardContext)

    const { currentUser, currentOrganization } = useContext(AuthContext)
    const { currentSubscription } = useContext(SubscriptionContext)

    const closeQuickGuide = () => {
        setShowQuickGuideModal(false)
    }
    const closeUpgradeMessage = () => {
        setShowUpgradeMessage(false)
    }

    const closeDailyCommentsSummary = () => {
        setShowDailyCommentSummaryModal(false)
    }

    const closeAreaModal = () => {
        setAreaModalIsOpen(false)
    }

    const closeSelectUserModal = () => {
        setSelectUserModalIsOpen(false)
    }

    const onUserListAssign = async (users: any) => {
        closeSelectUserModal()
        const assignedToMiniObj = users.map((a: Partial<UserStruct>) => dataObjects.getMiniUserObject(a))
        const tasksToUpdate: any[] = []

        for (const area of selectedAreas) {
            const changeObj = {
                before: 'assigned',
                after: createNamesForActivity(users)
            }
            await areaData.logActivity(asFirebase(firebase), currentUser!, area.key, 'assignment', selectedDateNumber, changeObj)

            const nameOfTask = area.activeRule ? area.activeRule.name : cleaningTaskName

            if (area.currentTask && area.currentTask.key) {
                const taskUpdateObject = {
                    assignedTo: assignedToMiniObj,
                    priority: area.currentTask.priority,
                    name: nameOfTask,
                    startDate: selectedDateNumber
                }
                const taskObject = await taskData.setTaskUpdate(asFirebase(firebase), currentUser!, area.currentTask.key, taskUpdateObject)
                tasksToUpdate.push(taskObject)
            } else {
                const taskObject = await taskData.createTask(asFirebase(firebase), area, currentUser!, c.TASK_TYPE_HOUSEKEEPING, {
                    assignedTo: users,
                    startDate: selectedDateNumber,
                    name: nameOfTask
                })
                tasksToUpdate.push(taskObject)
            }
        }

        // Update the UI
        const newAreas = [...allAreas]
        for (const task of tasksToUpdate) {
            const areaToUpdate = allAreas.find((area: any) => area.key === task.areaKey)
            if (areaToUpdate) {
                const index = newAreas.findIndex((area: any) => area.key === areaToUpdate.key)
                const newArea = { ...areaToUpdate, currentTask: task, task: task, assignedTo: task.assignedTo.map((x: any) => x.name) }
                newAreas[index] = newArea
            }
        }
        setAllAreas(newAreas)

        setSelectedAreas([])
    }

    const createNamesForActivity = (assignedUsers: any) => {
        let result = ''
        assignedUsers.forEach((user: any, index: number) => {
            result += user.name
            if (index !== assignedUsers.length - 1) {
                result += ', '
            }
        })
        return result
    }

    const areasWithDailyComments = areas === undefined ? [] : areas.filter(area => area.dailyComments && area.dailyComments.length > 0)
    const areasWithNotes = areas === undefined ? [] : areas.filter(area => area.note && area.note !== '')

    return (
        <>
            {/*<UpgradeModal show={isShowNotAccessModal} />*/}
            <Modal isOpen={showQuickGuideModal} style={styles.quickGuideStyle} contentLabel="QuickGuide" onRequestClose={closeQuickGuide}>
                <QuickGuide close={closeQuickGuide} />
            </Modal>
            <Modal
                isOpen={showUpgradeMessage}
                style={styles.upgradeMessageStyle}
                contentLabel="UpgradeMessages"
                onRequestClose={closeUpgradeMessage}>
                <UpgradeMessageContent
                    onClose={closeUpgradeMessage}
                    notAccessibleFeature={notAccessibleFeature}
                    availableUpgrades={availableUpgrades}
                    showCloseButton={true}
                />
            </Modal>
            <Modal
                isOpen={showDailyCommentSummaryModal}
                style={areaModalStyle}
                contentLabel="DailyCommentsSummary"
                onRequestClose={closeDailyCommentsSummary}>
                <DailyCommentsSummary
                    style={areaModalStyle}
                    // ref={ref => (dailyCommentSummaryModal = ref)}
                    show={showDailyCommentSummaryModal}
                    closeModal={closeDailyCommentsSummary}
                    areasWithDailyComments={areasWithDailyComments}
                    areasWithNotes={areasWithNotes}
                    areas={ahelpers.filterByAreaGroupAccess(currentUser!, areas)}
                    selectedDate={selectedDateNumber}
                />
            </Modal>
            <Modal isOpen={areaModalIsOpen} style={areaModalStyle} contentLabel="Area" onRequestClose={closeAreaModal}>
                <ProjectionsModalView
                    area={JSON.parse(JSON.stringify(selectedArea))}
                    selectedDateNumber={selectedDateNumber}></ProjectionsModalView>
            </Modal>
            <Modal isOpen={selectUserModalIsOpen} onRequestClose={closeSelectUserModal} style={styles.userListStyle} contentLabel="User">
                <UsersList
                    areas={selectedAreas}
                    closeModal={closeSelectUserModal}
                    currentUser={currentUser}
                    onAssignPress={onUserListAssign}
                />
            </Modal>
        </>
    )
}

export default ModalPage
