import { atom } from 'recoil'

export const signUpDataAtom = atom({
    key: 'signUpDataAtom',
    default: null
})

export const confirmationAtom = atom({
    key: 'confirmationAtom',
    default: null
})

export const formIsOpenAtom = atom({
    key: 'formIsOpenAtom',
    default: false
})
