import React, { useState } from 'react'
import ErrorModal from '../../components/ErrorMessage/ErrorModal'

const withErrorCatching = WrappedComponent => {
    const WithErrorCatching = props => {
        const [errorMessage, setErrorMessage] = useState(null)
        const [configs, setConfigs] = useState(null)

        function catchError(error, configs = null) {
            console.error(error)
            setErrorMessage(error.message ? error.message : error)

            if (configs) setConfigs(configs)
        }

        function closeModal() {
            setErrorMessage(null)
            setConfigs(null)
        }

        if (errorMessage) {
            return <ErrorModal errorMessage={errorMessage} closeModal={closeModal} {...configs} />
        }

        return <WrappedComponent {...props} catchError={catchError} />
    }

    return WithErrorCatching
}

export default withErrorCatching
