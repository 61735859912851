/**
 * This is a workflow instance that has state and can be used to advance the workflow.
 */
export class SignupWorkflowInstance {
    steps = []
    values = {}

    /**
     * Constructor either invoked by the Workflow definition or by the deserialization process
     * @param steps
     */
    constructor(steps) {
        if (Array.isArray(steps)) {
            this.steps = JSON.parse(JSON.stringify(steps))
        } else {
            const parts = JSON.parse(JSON.stringify(steps))
            this.steps = parts.steps
            this.values = parts.values
        }
    }

    /**
     * Not used at the moment
     * @returns {*}
     */
    fromIntegrationCatalog() {
        return this.steps[0]
    }

    /**
     * Completes a step, optionally with some inputs
     * @param step - the step's name
     * @param inputs - the step's inputs. An object with keys - the names of the inputs and matching values.
     */
    completeStep(step, inputs = {}) {
        for (const currentStep of this.steps.filter(s => !s.external)) {
            if (currentStep.state !== 'complete') {
                const currentStepRequiredInputNames = Object.entries(currentStep.inputs)
                    .filter(([k, v]) => v.required)
                    .map(([k, v]) => k)
                const currentStepProvidedInputsNames = Object.keys(inputs)
                const requiredPresent = currentStepRequiredInputNames.every(inputName => currentStepProvidedInputsNames.includes(inputName))
                const urlMatch = currentStep.url === step
                if (urlMatch && requiredPresent) {
                    this.values = Object.assign({}, this.values, inputs)
                    currentStep.state = 'complete'
                    return
                } else {
                    throw new Error('Either there is a step mismatch or required input parameters are missing')
                }
            }
        }
    }

    /**
     * Getting the next step's name
     * @returns {*}
     */
    nextStep() {
        for (const currentStep of this.steps.filter(s => !s.external)) {
            if (currentStep.state !== 'complete') {
                return currentStep.url
            }
        }
    }

    /**
     * Providing completion status
     * @returns {this is *[]}
     */
    isComplete() {
        return this.steps.filter(s => !s.external).every(s => s.state === 'complete')
    }
    /**
     * Retrieving the value of an input by name
     * @param inputName - name of the input
     * @returns {*} - value collected during one of the previous steps
     */
    getInput(inputName) {
        return this.values[inputName]
    }

    /**
     * Internal method to serialize the state. This can be later used as a parameter to the constructor of the class
     * @returns {any}
     */
    serialize() {
        return JSON.parse(JSON.stringify({ steps: this.steps, values: this.values }))
    }
}

/**
 * Store the workflow instance's state
 * @param name - name of the key to use when storing the state
 * @param workflowInstance
 */
export const storeWorkflowInstance = (name, workflowInstance) => {
    localStorage.setItem(name, JSON.stringify(workflowInstance.serialize()))
}

/**
 * Retrieving the state and instantiating a workflow instance with it
 * @param name - name of the key used when storing the state
 * @returns {string|SignupWorkflowInstance}
 */
export const getWorkflowInstance = name => {
    const item = localStorage.getItem(name)
    if (item) {
        return new SignupWorkflowInstance(JSON.parse(item))
    } else {
        return 'not found'
    }
}

export const integrationStartUrl = (newUrl = null) => {
    const KEY = 'integrationStartUrl'

    if (newUrl) {
        localStorage.setItem(KEY, newUrl)
        return
    }
    return { url: localStorage.getItem(KEY), remove: () => localStorage.removeItem(KEY) }
}

export const removeWorkflowInstance = name => {
    localStorage.removeItem(name)
}
