import styled from 'styled-components'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'

export const Wrapper = styled.div<{ top: number }>`
    width: 320px;
    position: absolute;
    height: max-content;
    border-left: 2px solid ${colors.gentle_gray_spectaflow};
    border-top: 2px solid ${colors.gentle_gray_spectaflow};
    border-top-left-radius: 4px;
    padding: 24px 14px 6px 14px;
    box-sizing: border-box;
    opacity: 0.93;
    right: 0;
    top: ${({ top }) => `-${top}px`};
    background-color: ${iOSColors.white};
`

export const ShowGroupsWrap = styled.div`
    display: flex;
    align-items: center;
    column-gap: 2px;
    margin-top: 8px;
    margin-bottom: 12px;

    > span {
        font-size: 0.923rem;
        font-weight: 500;
    }
`

export const UsersList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const UsersGroup = styled.div``
