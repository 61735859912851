import React from 'react'
import { hex2rgb, black_sweeply } from '@shared/colors'

export default function CheckmarkIcon() {
    return (
        <svg width="0.923rem" height="1rem" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="web-app-subscriptions" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="learn-more" transform="translate(-508.000000, -877.000000)" fill={hex2rgb(black_sweeply, 0.65)} fillRule="nonzero">
                    <g id="Group-5" transform="translate(508.000000, 872.000000)">
                        <g id="feature-checkmark" transform="translate(0.000000, 5.000000)">
                            <path
                                d="M17.5943828,2.36143236 L7.13754947,12.8182656 C6.87751181,13.0783033 6.52618435,13.2235371 6.15964191,13.2235371 C5.79309947,13.2235371 5.44038883,13.0783033 5.18173435,12.8182656 L0.405617244,8.04214854 C-0.135205748,7.50132555 -0.135205748,6.62715641 0.405617244,6.08633342 C0.946440235,5.54551043 1.82060937,5.54551043 2.36143236,6.08633342 L6.15964191,9.88454297 L15.6385676,0.405617244 C16.1793906,-0.135205748 17.0535598,-0.135205748 17.5943828,0.405617244 C18.1352057,0.946440235 18.1352057,1.82060937 17.5943828,2.36143236 Z"
                                id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
