import React from 'react'
import { getBrand } from '@shared/brand'

const EmphasisIcon = () => {
    const brandColor = getBrand().navBarColor

    return (
        <>
            <svg width="98" height="16" viewBox="0 0 98 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.18888 13.1267C17.5066 6.46785 51.4334 0.0195644 95.47 4.41285"
                    stroke="url(#paint0_linear_865_1830)"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_865_1830"
                        x1="54.2648"
                        y1="3.75582"
                        x2="61.6945"
                        y2="17.3977"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor={brandColor} />
                        <stop offset="1" stopColor="#F08F6C" />
                    </linearGradient>
                </defs>
            </svg>
        </>
    )
}

export default EmphasisIcon
