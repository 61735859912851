import styled from 'styled-components'
import { iOSColors } from '@shared/react-native-typography'

interface IHousekeepingWrap {
    fontSize: string
    backgroundColor: string
    marginTop: number
    marginLeft: number
    marginBottom: number
    marginRight: number
    extraFontSize: number
    color: string
}

export const HousekeepingWrap = styled.div`
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ backgroundColor }: IHousekeepingWrap) => backgroundColor};

    > i {
        margin-top: ${({ marginTop }: IHousekeepingWrap) => `${marginTop}px`};
        margin-bottom: ${({ marginBottom }: IHousekeepingWrap) => `${marginBottom}px`};
        margin-left: ${({ marginLeft }: IHousekeepingWrap) => `${marginLeft}px`};
        margin-right: ${({ marginRight }: IHousekeepingWrap) => `${marginRight}px`};
        color: ${({ color }: IHousekeepingWrap) => color};
        font-size: ${({ fontSize, extraFontSize }: IHousekeepingWrap) => `${fontSize + extraFontSize}px`};
    }
`

export const GeneralWrap = styled.div`
    background-color: ${iOSColors.white};
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid ${iOSColors.gray};

    > i {
        margin-left: 1px;
        font-size: 9px;
    }
`
