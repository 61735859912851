import React from 'react'
import { useRecoilValue } from 'recoil'
import { reportTasksHeaders } from '../atoms.js'
import { AreaBredCrumbs } from '../../../../../components/area-bread-crumbs'

const ReportTasks = ({ reportTasks }) => {
    const headers = useRecoilValue(reportTasksHeaders)

    const mapHeaders = headers.map((header, index) => <th key={header.key + index}>{header.label}</th>)

    const mapRows = reportTasks.map((row, index) => {
        const { area, task, date, duration, start, end, paused } = row

        return (
            <tr key={area + index} className="bg-gray-100">
                <td></td>
                <td>
                    <AreaBredCrumbs spaceBetween={5} area={area} fontSize={13} fontWeight={'500'} />
                </td>
                <td>{date}</td>
                <td>{duration}</td>
                <td>{start}</td>
                <td>{end}</td>
                <td>{paused}</td>
                <td>{task}</td>
                <td></td>
                <td></td>
            </tr>
        )
    })

    return (
        <>
            <tr className="bg-gray-200">
                <th></th>
                {mapHeaders}
                <th></th>
                <th></th>
            </tr>
            {mapRows}
        </>
    )
}

export default ReportTasks
