import { iOSColors } from '@shared/react-native-typography'
import styled from 'styled-components'
import { shadeColor } from '@shared/colors'

interface IWrapper {
    boxColor: string
    index: number
    selected: boolean
}

interface IAssignedUserInitialsWrap {
    boxColor: string
    transparent: boolean
}

export const Wrapper = styled.div.attrs<IWrapper>(({ boxColor, index, selected }) => {
    return {
        style: {
            animation: `fadeIn  0.${index * 3}s`,
            width: '100px',
            height: '100px',
            borderRadius: '6px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            position: 'relative',
            backgroundColor: selected ? shadeColor(boxColor, 10) : boxColor,
            border: selected ? `1px solid ${iOSColors.black}` : 'none',
            opacity: selected ? 0.6 : 1
        }
    }
})`
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    > label {
        font-size: 1.385rem;
        font-weight: 600;
        color: ${iOSColors.white};
        text-align: center;
    }
`

export const TopIconsWrap = styled.div`
    width: 100%;
    height: 40%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
`

export const BottomIconsWrap = styled.div`
    width: 100%;
    height: 30%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    column-gap: 5px;
`

export const AssignedUsersInitialsWrap = styled.div<IAssignedUserInitialsWrap>`
    width: 29px;
    height: 29px;
    border-radius: 100px;
    border: ${({ transparent }) => (transparent ? '1px solid white' : 'none')};
    background-color: ${({ transparent }) => (transparent ? 'transparent' : iOSColors.white)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    right: -2px;

    > span {
        position: relative;
        font-size: 0.923rem;
        color: ${({ boxColor, transparent }) => (transparent ? iOSColors.white : boxColor)};
        font-weight: 500;
    }
    .plus {
        position: absolute;
        bottom: -4px;
        left: 7px;
    }
`
