import React from 'react'
import { useRecoilValue } from 'recoil'
import { reportTableCleaningSummaryHeaders } from '../atoms.js'

const CleaningSummary = ({ summaryByCleaningType }) => {
    const headers = useRecoilValue(reportTableCleaningSummaryHeaders)

    const mapHeaders = headers.map((header, index) => <th key={header + index}>{header}</th>)

    const mapRows = summaryByCleaningType.map((item, index) => {
        const { type, areasCleaned, averageTime } = item

        return (
            <tr key={type + index} className="">
                <td>{type}</td>
                <td>{areasCleaned}</td>
                <td>{averageTime}</td>
                <td></td>
                <td></td>
            </tr>
        )
    })

    return (
        <div className="card card-custom bg-gray-100 mt-10">
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label font-size-h3">
                        Summary
                        <small> by cleaning type</small>
                    </h3>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="table-responsive">
                    <table className="table table-hover table-head-custom table-vertical-center">
                        <thead>
                            <tr className="">
                                {mapHeaders}
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        {mapRows}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CleaningSummary
