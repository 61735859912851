import moment from 'moment-timezone'
import { AreaStruct, BookingStruct } from './firestore-structs'
import * as c from './txt-constants'
import { getBookingDisplay } from './get-booking-display'
import { Firebase } from './firebase'

interface CurrentBookingDisplayParams {
    bookings: BookingStruct[]
    calculatedArea: AreaStruct
    selectedDateNumber: number
    organizationKey: string
}

//TODO - Proper type after refactoring getBookingDisplay
export interface BookingDisplay {
    defaultBedSetup?: string
    guestName?: string
    guestCount?: string
    bedSetup?: string
    notes?: string
    guestComments?: string
    nrOfDaysStayed?: number
    occupancyText: string
    occupancyState?: string
    guestsInfo?: string
}

interface CurrentBookingDisplay {
    bookingDisplay: BookingDisplay
    updatedBookings: BookingStruct[]
}

export function findLeadBooking(area: Partial<AreaStruct>, bookings: BookingStruct[], date: number): BookingStruct | null {
    if (bookings === undefined || bookings.length === 0 || bookings.every(booking => booking.status === c.BOOKING_STATUS_BLOCKED)) {
        return null
    }
    const todaysBookings = bookings.filter(b => b.bookingDates.includes(date.toString()) && b.status !== c.BOOKING_STATUS_BLOCKED)
    const futureBookings = bookings.filter(b => !b.bookingDates.includes(date.toString()) && b.status !== c.BOOKING_STATUS_BLOCKED)

    if ((area && area.occupancy === c.OCCUPANCY_CHECKOUT) || (area && area.occupancy === c.OCCUPANCY_TURNOVER)) {
        if (area.occupancy === c.OCCUPANCY_TURNOVER) {
            return todaysBookings[1]
        } else {
            return futureBookings[0]
        }
    } else if (todaysBookings.length > 0) {
        return todaysBookings[0]
    } else {
        return futureBookings[0]
    }
}

export async function prepareCurrentBookingDisplay(
    firebase: Firebase,
    { bookings, calculatedArea, selectedDateNumber, organizationKey }: CurrentBookingDisplayParams
): Promise<CurrentBookingDisplay> {
    const leadBooking = findLeadBooking(calculatedArea, bookings, selectedDateNumber)
    const isFutureBooking = leadBooking && leadBooking.bookingDates.includes(selectedDateNumber.toString()) ? false : true

    let updatedBookings: BookingStruct[] = []

    if (isFutureBooking) {
        const nextBooking: BookingStruct | null = await getNextBooking(firebase, calculatedArea.key, organizationKey, selectedDateNumber)

        if (bookings.length > 0 && nextBooking && bookings.filter(b => b.key === nextBooking.key).length === 0) {
            updatedBookings = [...bookings, nextBooking]
        } else {
            updatedBookings = bookings
        }
    } else {
        updatedBookings = bookings
    }

    //TODO - getBookingDisplay should be refactored to TS
    const bookingDisplay = getBookingDisplay(leadBooking, calculatedArea, moment(selectedDateNumber))

    return { bookingDisplay, updatedBookings }
}

export async function getNextBooking(firebase: Firebase, areaKey: string, organizationKey: string, date: number): Promise<BookingStruct> {
    const tomorrow = moment(date).add(1, 'days')

    const bookingsQuery = firebase
        .firestore()
        .collection<BookingStruct>('bookings')
        .where('organizationKey', '==', organizationKey)
        .where('areaKey', '==', areaKey)
        .where('checkoutDate', '>=', tomorrow.valueOf())

    const bookingsRef = await bookingsQuery.limit(1).get()

    return bookingsRef.docs.map(b => b.data()).filter(b => b.status && b.status.toLowerCase() !== 'cancelled')[0] as BookingStruct
}
