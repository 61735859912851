import React, { useEffect } from 'react'
import LoadingScreen from '../../LoadingScreen'
import SignUpLayout from '../../SignUpLayout'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { oracleSetup } from '../../cloud-functions'
import { compose } from 'redux'
import withIntegrationWorkflow from '../../../../utils/hocs/withIntegrationWorkflow'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import { errorModalConfigs } from '../../helpers'
import { INTEGRATIONS } from '../../../../utils/constants'

const OracleCallback = ({
    newOrgKey,
    catchError,
    signupComplete,
    workflowInstance,
    storeWorkflowInstance,
    setComplete,
    currentIntegrationKey,
    newUserKey,
    settings,
    message,
    setStopAuthListener
}) => {
    const setupOracle = async () => {
        const hostName = workflowInstance.getInput('hostName')
        const clientId = workflowInstance.getInput('clientId')
        const clientSecret = workflowInstance.getInput('clientSecret')
        const username = workflowInstance.getInput('username')
        const password = workflowInstance.getInput('password')
        const hotelId = workflowInstance.getInput('hotelId')
        const appKey = workflowInstance.getInput('appKey')
        const externalSystemCode = workflowInstance.getInput('externalSystemCode')

        const setupOracleIntegration = oracleSetup()

        try {
            const { data } = await setupOracleIntegration({
                orgId: newOrgKey,
                integration: INTEGRATIONS.ORACLE.toLowerCase(),
                signupPayload: {
                    organizationID: newOrgKey,
                    hostName,
                    clientId,
                    clientSecret,
                    username,
                    password,
                    hotelId,
                    applicationKey: appKey,
                    externalSystemCode
                }
            })

            if (data.reason !== 'all good') {
                catchError(data.message, errorModalConfigs)
                return
            }

            console.log(workflowInstance)
            if ('test' in workflowInstance.values) {
                data.reason = 'test'
            }

            await signupComplete({ orgId: newOrgKey, userId: newUserKey, status: data })

            workflowInstance.completeStep(SIGN_UP.ORACLE_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    async function setup() {
        setStopAuthListener(true)
        await setupOracle()
    }

    useEffect(() => {
        setup()
    }, [])

    return (
        <SignUpLayout withModal>
            <LoadingScreen message={message} />
        </SignUpLayout>
    )
}

export default compose(withIntegrationWorkflow, withErrorCatching)(OracleCallback)
