export const GuestStatusClasses = {
    active: { name: 'Active', color: 'specta-green' },
    suspended: { name: 'Suspended', color: 'specta-red' },
    trial: { name: 'Trial', color: 'specta-gold' },
    test: { name: 'Test', color: 'dark' }
}

export const defaultSorted = [{ dataField: 'status', order: 'asc' }]
export const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '100', value: 100 },
    { text: '250', value: 250 }
]
export const initialFilter = {
    filter: {
        name: '',
        subscription: 'All',
        status: 'active'
    },
    sortOrder: 'asc', // asc||desc
    sortField: 'status',
    pageNumber: 1,
    pageSize: 25
}
