import React, { useEffect } from 'react'
import { getWorkflowInstance, storeWorkflowInstance } from '../../../../utils/signup-flow-template/signup-workflow-instance'
import { currentIntegrationKey } from '../../signup-worflow-templates'
import { getCurrentEnv, SIGN_UP, SITE_URL } from '../../../../navigation/url-constants'
import Spinner from '../../../../components/Spinner'
import { CenteredContent } from '../../style'

const CloudbedsAuthorize = () => {
    const workflowInstance = getWorkflowInstance(currentIntegrationKey)
    const redirectUrl = SITE_URL + SIGN_UP.CLOUDBEDS_CALLBACK

    useEffect(() => {
        workflowInstance.completeStep(SIGN_UP.CLOUDBEDS_AUTHORIZE)
        storeWorkflowInstance(currentIntegrationKey, workflowInstance)
        const clientId = getCurrentEnv() === 'prod' ? 'sweeply_EekbDHt0LiA2nqoUVwlsYgrp' : 'sweeply_test_npKfbC2M1djawL6Bc3EqDRHe'

        window.location.href = `https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=${clientId}&redirect_uri=${encodeURIComponent(
            redirectUrl
        )}&response_type=code&scope=${encodeURIComponent(
            'read:reservation read:hotel read:room read:roomblock read:guest write:housekeeping'
        )}`
    }, [])

    return (
        <CenteredContent>
            <Spinner />
        </CenteredContent>
    )
}

export default CloudbedsAuthorize
