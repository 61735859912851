import styled from 'styled-components'
import * as colors from '@shared/colors'

export const Wrap = styled.div<{ isActiveTab: boolean }>`
    height: 100%;
    position: relative;
    top: 1px;
    color: ${({ isActiveTab: activeTab }) => (activeTab ? colors.yello_spectaflow : colors.bs_gray_600)};
    border-bottom: 1px solid ${({ isActiveTab: activeTab }) => (activeTab ? colors.yello_spectaflow : 'transparent')};
    display: flex;
    align-items: center;
    font-size: 1rem;
    column-gap: 9px;
    cursor: pointer;
    transition: all 0.1s ease;

    &:hover {
        color: ${colors.yello_spectaflow};
        border-bottom: 1px solid ${colors.yello_spectaflow};

        > i {
            color: ${colors.yello_spectaflow};
        }
    }

    > i {
        color: ${({ isActiveTab }) => (isActiveTab ? colors.yello_spectaflow : colors.bs_gray_600)};
    }

    > div {
        width: 20px;
        height: 20px;
        background-color: ${colors.yello_spectaflow};
        opacity: 0.9;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${colors.white};
        font-size: 0.925rem;
    }
`
