import styled from 'styled-components'

export const PreviewBoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 120px;
    height: 120px;
    position: relative;

    img {
        height: 100%;
    }

    span {
        width: 100%;
        font-size: 12px;
        color: rgb(142, 142, 147);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .trash-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 20px;
        height: 20px;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }
    }
`
