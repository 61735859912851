import React from 'react'
import * as uc from '../utils/constants'
import { httpsCallable } from '../api'
import { GUESTS } from '../navigation/url-constants'
import { HoverableImageButton } from 'app/components/buttons/hoverable-image-button'
import { useFeatureToggle } from 'app/features'
import imgName from '@shared/img/qr-code-icon.svg'
import { FEATURE_GUEST_FLOW } from '@shared/subscriptions/feature-items'

export const GuestQrButton = ({ bookingId }: { bookingId: number }) => {
    const { isFeatureOn } = useFeatureToggle()
    const onQrButtonPress = async () => {
        const generateToken = httpsCallable('generateCustomTokenHash')
        try {
            const { data: token } = await generateToken({ bookingId })
            // window.open(`${GUESTS.QR_MAGIC_LINK}?token=${token}`, '_blank')
            const linkElement = document.createElement('a')
            linkElement.id = 'guest-qr-link'
            linkElement.target = '_blank'
            window.document.body.appendChild(linkElement)
            const url = `${GUESTS.QR_MAGIC_LINK}?token=${token}`
            const link = document.getElementById('guest-qr-link')
            link?.setAttribute('href', url)
            link?.click()
            link?.remove()
        } catch (error) {
            console.error({ error })
        }
    }

    const tooltip = 'Open Guest page QR code'
    if (!isFeatureOn(FEATURE_GUEST_FLOW)) return null
    return (
        <HoverableImageButton
            dimension={uc.BUTTON_DIM}
            hoverOpacity={uc.HOVER_OPACITY}
            imageSource={imgName}
            onPress={onQrButtonPress}
            tooltipText={tooltip}
        />
    )
}
