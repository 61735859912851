import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { View, ActivityIndicator } from 'react-native-web'

import AIssue from '../../issues/aissue-modal'
import * as responsive from '../../utils/responsive'
import * as brand from '@shared/brand'
import { OpenNavbar } from '../../components/open-navbar'
import { httpsCallable } from '../../api'

class SharedIssue extends Component {
    constructor(props) {
        super(props)

        this.state = {
            issue: null,
            loaded: false,
            respWidth: null,
            respHeight: null
        }

        this.getIssue = httpsCallable('getIssue')
        this.updateDimensions = this.updateDimensions.bind(this)
    }

    updateDimensions() {
        responsive.updateDimensions((width, height) => {
            this.setState({ respWidth: width, respHeight: height })
        })
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.getIssue({ issueKey: this.props.match.params.id })
                .then(result => {
                    const issue = result.data
                    if (this.getIssue) {
                        this.setState({ loaded: true, issue })
                    }
                })
                .catch(error => {
                    console.error('error', error)
                    this.setState({ loaded: true })
                })
        }
    }

    UNSAFE_componentWillMount() {
        this.updateDimensions()
    }

    componentWillUnmount() {}

    render() {
        const DEBUG_BORDER = 0
        const MARGIN_TOP = window.innerHeight / 3
        const MARGIN_BOTTOM = MARGIN_TOP
        return (
            <>
                <OpenNavbar />
                <View
                    style={{
                        marginTop: 0,
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'yellow',
                        justifyContent: 'flex-start',

                        backgroundColor: 'white'
                    }}>
                    {!this.state.loaded && (
                        <View
                            style={{
                                width: '100%',
                                borderWidth: 0,
                                alignItems: 'center'
                            }}>
                            <ActivityIndicator
                                size="small"
                                color={brand.getBrand().navBarColor}
                                style={{
                                    marginTop: MARGIN_TOP,
                                    marginBottom: MARGIN_BOTTOM
                                }}
                            />
                        </View>
                    )}
                    {this.state.loaded && this.state.issue === null && (
                        <View
                            style={{
                                marginTop: MARGIN_TOP,
                                width: '100%',

                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 0,
                                marginBottom: MARGIN_BOTTOM
                            }}>
                            <span style={{ fontSize: 16 }}>No issue found</span>
                        </View>
                    )}
                    {this.state.loaded && this.state.issue && (
                        <AIssue
                            currentOrganization={{ subscription: 'pro' }}
                            editable={false}
                            sharedIssueStyle={this.props.sharedIssueStyle}
                            issue={this.state.issue}
                            closeModal={this.aIssueCloseModal}
                            respWidth={this.state.respWidth}
                            respHeight={this.state.respHeight}
                        />
                    )}
                </View>
            </>
        )
    }
}

export default withRouter(SharedIssue)
