import { atom } from 'recoil'
import * as c from '@shared/constants'
import { RespParams, SubscriptionParams } from '../../types'
import { TaskboardContext } from '@shared/traces-types'
import {
    ActivityStruct,
    AreaStruct,
    BookingStruct,
    DailyCommentStruct,
    IssueStruct,
    RuleStruct,
    TaskStruct,
    UserStruct
} from '@shared/dataObjects'
import moment from 'moment-timezone'
import { TaskStatus as TS } from '@shared/firestore-structs'

export const respParamsAtom = atom<RespParams>({
    key: 'taskboard-respParams',
    default: {
        respWidth: 0,
        respHeight: 0
    }
})

export const dataLoadingAtom = atom<boolean>({
    key: 'taskboard-dataLoadingAtom',
    default: true
})

export const taskboardContextAtom = atom<TaskboardContext>({
    key: 'taskboard-taskboardContextAtom',
    default: TaskboardContext.EMBEDDED
})

export const currentTasksTypesAtom = atom<TaskStruct['type'][]>({
    key: 'taskboard-currentTasksTypesAtom',
    default: [c.TASK_TYPE_GENERAL]
})

export const currentDateNumberAtom = atom<number>({
    key: 'taskboard-currentDateNumberAtom',
    default: moment().valueOf()
})

export const currentIssueAtom = atom<IssueStruct | null>({
    key: 'taskboard-currentIssue',
    default: null
})

export const currentTaskAtom = atom<TaskStruct | Partial<TaskStruct> | null>({
    key: 'taskboard-currentTask',
    default: null
})

export const currentAreaAtom = atom<AreaStruct | Partial<AreaStruct> | null>({
    key: 'taskboard-currentArea',
    default: null
})

export const fetchedTasksAtom = atom<TaskStruct[]>({
    key: 'taskboard-fetchedTasksAtom',
    default: []
})

export const allTasksAtom = atom<TaskStruct[]>({
    key: 'taskboard-allTasksAtom',
    default: []
})

export const areasAtom = atom<AreaStruct[]>({
    key: 'taskboard-areasAtom',
    default: []
})

export const activitiesAtom = atom<ActivityStruct[]>({
    key: 'taskboard-activitiesAtom',
    default: []
})

export const bookingsAtom = atom<BookingStruct[]>({
    key: 'taskboard-bookingsAtom',
    default: []
})

export const dailyCommentsAtom = atom<DailyCommentStruct[]>({
    key: 'taskboard-dailyCommentsAtom',
    default: []
})

export const rulesAtom = atom<RuleStruct[]>({
    key: 'taskboard-rulesAtom',
    default: []
})

export const usersAtom = atom<UserStruct[]>({
    key: 'taskboard-usersAtom',
    default: []
})

export const subscriptionParamsAtom = atom<SubscriptionParams>({
    key: 'taskboard-subscriptionParams',
    default: {
        hasSubscriptionAccess: false,
        availableUpgrades: null,
        notAccessibleFeature: null,
        upgradeMessageIsOpen: false
    }
})

export const minimizedTasksAtom = atom<TS[]>({
    key: 'taskboard-minimazedTasksAtom',
    default: []
})

export const newTaskModalisOpenAtom = atom<boolean>({
    key: 'taskboard-newTaskIsOpenAtom',
    default: false
})
