import React from 'react'
import { CheckboxWrap, ColorCircle, TitleWrap, Wrap } from './style'
import { CleaningStatusFilterOption } from 'app/modules/HousekeepingDashboard/types'
import { pickCleaningStatusColor } from '@shared/cleaning-helpers'
import selectOnIcon from '@shared/img/select-on-mid-gray.svg'
import selectOffIcon from '@shared/img/select-off-3.svg'

interface Props {
    option: CleaningStatusFilterOption
    onClick: (value: string) => void
}

function CleaningOption({ option, onClick }: Props) {
    const { label, value, isSelected } = option
    const color = pickCleaningStatusColor(value)
    const selectIcon = isSelected ? (selectOnIcon as string) : (selectOffIcon as string)

    return (
        <Wrap isSelected={isSelected} onClick={() => onClick(value)}>
            <TitleWrap>
                <ColorCircle color={color} />
                <span>{label}</span>
            </TitleWrap>
            <CheckboxWrap>
                <img src={selectIcon} />
            </CheckboxWrap>
        </Wrap>
    )
}

export default CleaningOption
