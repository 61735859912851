import React from 'react'
import * as c from '@shared/constants'
import { IssueStruct } from '@shared/dataObjects'
import Spinner from 'app/components/Spinner'
import {
    IssueImage,
    IssueImageBlock,
    IssueImageContainer,
    IssueImageNotDownloaded,
    IssueImageNotDownloadedContainer,
    IssueImageWrapper,
    MoreImagesContainer,
    MoreImagesText,
    PriorityContainer,
    PriorityImage,
    TextIssueImage
} from 'app/issues/style'

interface LeftBlockProps {
    issue: IssueStruct
    isHouskeeping: boolean
}

export default function IssueImageComponent({ issue, isHouskeeping }: LeftBlockProps) {
    const itemCount = issue.items ? issue.items.length : 0
    const IMG_WIDTH = isHouskeeping ? 112 : 120
    const IMG_HEIGHT = isHouskeeping ? 89 : 100
    const priority_dim = isHouskeeping ? 24 - 5 : 24
    const priority_position = isHouskeeping ? 3 : -5

    function renderImageView() {
        const borderRadius = 4
        if (issue.isImagesLoading) {
            return (
                <IssueImageWrapper borderRadius={borderRadius} width={IMG_WIDTH} height={IMG_HEIGHT}>
                    <Spinner />
                </IssueImageWrapper>
            )
        }
        if (issue.lastItemKey === c.TEXT_ISSUE) {
            return (
                <IssueImageWrapper borderRadius={borderRadius} width={IMG_WIDTH} height={IMG_HEIGHT}>
                    <TextIssueImage width={60 * 0.7} height={66 * 0.7} src={require('@shared/img/text-big.svg')} />
                </IssueImageWrapper>
            )
        }
        if (issue !== null && issue.lastThumbUrl && issue.lastThumbUrl.length >= 5 && issue.lastThumbUrl.slice(0, 4) === 'http') {
            return (
                <IssueImageContainer width={IMG_WIDTH} height={IMG_HEIGHT}>
                    <IssueImage width={IMG_WIDTH} height={IMG_HEIGHT} src={issue.lastThumbUrl} />
                </IssueImageContainer>
            )
        }
        return (
            <IssueImageNotDownloadedContainer borderRadius={borderRadius}>
                <IssueImageNotDownloaded width={30 * 1.5} height={20 * 1.5} src={require('@shared/img/not-downloaded.svg')} />
            </IssueImageNotDownloadedContainer>
        )
    }

    return (
        <IssueImageBlock width={IMG_WIDTH}>
            {renderImageView()}
            {issue.priority && (
                <PriorityContainer topPosition={priority_position} rightPosition={priority_position}>
                    <PriorityImage dimension={priority_dim} src={require('@shared/img/priority-high-red-bgr.svg')} />
                </PriorityContainer>
            )}

            {itemCount > 1 && (
                <MoreImagesContainer>
                    <MoreImagesText>+ {itemCount - 1}</MoreImagesText>
                </MoreImagesContainer>
            )}
        </IssueImageBlock>
    )
}
