import React, { useContext } from 'react'

import { AreasContainer, Wrapper } from '../../modules/HousekeepingDashboard/HousekeepingBody/GroupSection/style'
import { formatAreasBySection } from './helpers'
import Area from './area'
import { Workload } from '../../components/workload'
import { iOSColors } from '@shared/react-native-typography'
import { AreaStruct } from '@shared/firestore-structs'
import { DashboardContext } from './DashboardContext'

const ProjectionsAreaList = ({ areas }: { areas: any[] }) => {
    const areasBySections: {
        data: any[]
        unclean50TaskCount: number
        noServiceTaskCount: number
        count: number
        unclean80TaskCount: number
        title: string
        unclean100TaskCount: number
        checkinTaskCount: number
    }[] = formatAreasBySection(areas).sort((a, b) => a.title.localeCompare(b.title))
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '24px' }} className="row row-paddingless">
                {areasBySections.map((section: any, index: number) => (
                    <Wrapper key={section.title + index}>
                        <SectionLabel section={section} />
                        <AreasContainer key={section.title + index}>
                            {section.data.map((area: any, index: number) => {
                                return <Area key={area.key + index} area={area}></Area>
                            })}
                        </AreasContainer>
                    </Wrapper>
                ))}
            </div>
        </>
    )
}

export default ProjectionsAreaList

const SectionLabel = ({ section }: { section: any }) => {
    const { showActionBar, selectedAreas, setSelectedAreas } = useContext(DashboardContext)
    return (
        <label
            onClick={() => {
                if (showActionBar) {
                    const allSelectedInGroup = selectedAreas.filter(
                        (area: any) => section.data.filter((x: any) => x.key === area.key).length > 0
                    )
                    if (allSelectedInGroup.length > 0) {
                        setSelectedAreas(selectedAreas.filter((a: any) => section.data.filter((x: any) => x.key === a.key).length === 0))
                    } else {
                        setSelectedAreas([...selectedAreas, ...section.data])
                    }
                }
            }}
            style={{
                cursor: showActionBar ? 'pointer' : 'default'
            }}>
            <label
                style={{
                    cursor: showActionBar ? 'pointer' : 'default'
                }}>
                {section.title}
                <span>{` (${section.count})`}</span>
            </label>
            <Workload
                fontSize={14}
                fontWeight={'500'}
                color={iOSColors.gray}
                counts={{
                    unclean50Count: section.unclean50TaskCount,
                    unclean80Count: section.unclean80TaskCount,
                    unclean100Count: section.unclean100TaskCount,
                    checkInCount: section.checkinTaskCount,
                    noServiceCount: section.noServiceTaskCount
                }}
                tooltipDisabled={true}
            />
        </label>
    )
}
