import styled from 'styled-components'
import { TOP_BOTTOM_MARGIN } from '../style'
import { SummaryTabType } from 'app/modules/HousekeepingDashboard/types'
import * as colors from '@shared/colors'
import * as c from '@shared/constants'
import { iOSColors } from '@shared/react-native-typography'

export const Wrap = styled.div`
    width: 100%;
    height: calc(100% - (43px + (${TOP_BOTTOM_MARGIN} * 2)));
    overflow-y: auto;
`

export const Header = styled.div`
    width: 100%;
    padding: 0 40px;
`

export const Date = styled.div`
    color: ${colors.yello_spectaflow};
    font-size: 1rem;
    margin-bottom: 5px;
`

export const TitleWrap = styled.div<{ type: SummaryTabType }>`
    width: 100%;
    height: 39px;
    display: flex;
    column-gap: ${({ type }) => (type === c.ACTIVITY_TYPE_DAILY_COMMENT ? '10px' : '12px')};
    align-items: center;
    margin-bottom: 24px;

    > span {
        font-weight: 500;
        font-size: 1.692rem;
    }
`

export const NotesWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const NoteWrap = styled.div<{ even: boolean }>`
    width: 100%;
    height: 110.5px;
    border-bottom: 1px solid ${colors.gentle_gray_spectaflow};
    background-color: ${({ even }) => (even ? colors.gentle_gray_spectaflow : colors.white)};
    padding: 16px 40px;
    display: flex;
    flex-direction: column;
`

export const Group = styled.span`
    color: ${iOSColors.gray};
    font-size: 0.923rem;
    margin-bottom: 4px;
`
export const Name = styled.span`
    font-weight: 700;
    color: ${iOSColors.black};
    font-size: 1.308rem;
    display: flex;
`
export const Description = styled.span`
    color: ${iOSColors.gray};
    font-size: 1.308rem;
    font-weight: 400;
    margin-left: 6px;
`
export const NoteText = styled.div`
    margin-top: 6px;
    color: ${iOSColors.black};
    font-size: 1.231rem;
    width: 100%;
`
