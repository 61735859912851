import styled from 'styled-components'

export const Wrap = styled.div`
    width: 100%;
    margin-bottom: 16px;
`

export const DateWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    > h2 {
        font-size: 1.75rem;
        font-weight: 600;
    }
`

export const DateCirclesWrap = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    margin-top: 16px;
    row-gap: 40px;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

export const Subheader = styled.span`
    font-size: 0.925rem;
    margin-bottom: 6.5px;
    font-weight: 600;
`
