import styled from 'styled-components'
import * as colors from '@shared/colors'

export const Wrap = styled.div`
    width: 100%;
    padding: 24px 40px;
`

export const Description = styled.span`
    font-size: 1.175rem;
    color: ${colors.bs_gray_500};
    font-weight: 600;
`

export const PlanningInfoWrap = styled.div`
    margin-top: 22.8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16.5px;
`
