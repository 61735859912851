import moment from 'moment-timezone'

export const defaultSorted = [{ dataField: 'name', order: 'asc' }]
export const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '100', value: 100 },
    { text: '250', value: 250 }
]
export const initialFilter = {
    filter: {
        name: '',
        description: '',
        group: '',
        synced: '',
        address: '',
        daySelected: moment().startOf('day').valueOf()
    },
    sortOrder: 'asc', // asc||desc
    sortField: 'name',
    pageNumber: 1,
    pageSize: 25
}
