import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'
import * as helpers from '@shared/helpers'

export default class HashtagsSelect extends React.Component {
    constructor(props) {
        super(props)

        const defaultHashtagOptions = HashtagsSelect.createHastagOptions(this.props.defaultHashtags)
        const hashtagOptions = HashtagsSelect.createHastagOptions(this.props.hashtags)

        this.state = {
            hashtags: [],
            hashtagOptions: hashtagOptions,
            defaultHashtags: [],
            defaultHashtagOptions: defaultHashtagOptions,
            isDisabled: props.isDisabled
        }

        this.onChange = this.onChange.bind(this)
    }

    getSelectStyles(hashtagColor, borderHoverColor, optionBackgroundColor) {
        return {
            control: styles => ({
                ...styles,
                backgroundColor: colors.white,
                width: this.props.width ? this.props.width : 260,

                borderWidth: 1,
                borderColor: colors.bs_gray_300,
                borderRadius: 6,
                boxShadow: 'none',
                padding: 2,

                ':hover': {
                    borderWidth: 1,
                    borderColor: borderHoverColor,
                    borderRadius: 6
                }
            }),
            option: (styles, { data, isDisabled, isFocused }) => {
                const style = {
                    ...styles,

                    backgroundColor: isFocused ? optionBackgroundColor : null,
                    color: isFocused ? colors.white : iOSColors.gray,
                    fontSize: 13,
                    cursor: isDisabled ? 'default' : 'default'
                }

                return style
            },
            placeholder: styles => {
                const style = {
                    ...styles,
                    fontWeight: '400',
                    color: iOSColors.midGray,
                    zIndex: 1,
                    fontSize: 14,
                    backgroundColor: colors.white
                }

                return style
            },
            input: styles => {
                const style = {
                    ...styles,
                    fontWeight: '400',
                    color: iOSColors.gray,
                    fontSize: 14,
                    backgroundColor: colors.white
                }

                return style
            },
            indicatorSeparator: (styles, { data }) => ({
                ...styles,
                backgroundColor: '#f4f5f6',
                width: 0
            }),
            multiValue: styles => {
                const style = {
                    ...styles,
                    backgroundColor: colors.white,
                    paddingLeft: 7,

                    borderRadius: 4,
                    border: '1px solid ' + hashtagColor,
                    borderWidth: 1,
                    height: 22,

                    alignItems: 'center'
                }

                return style
            },
            multiValueLabel: styles => {
                const style = {
                    color: hashtagColor,
                    fontSize: 13,
                    fontWeight: '500',
                    ':hover': {
                        borderWidth: 1,
                        borderColor: iOSColors.gray
                    }
                }

                return style
            },
            multiValueRemove: styles => {
                const style = {
                    ...styles,
                    color: hashtagColor,
                    marginTop: 2,
                    ':hover': {
                        opacity: 0.7,
                        color: colors.red_spectaflow
                    }
                }

                return style
            },
            noOptionsMessage: styles => {
                const style = {
                    ...styles,
                    color: iOSColors.gray,
                    fontWeight: '500',
                    fontSize: 13,
                    backgroundColor: colors.white
                }

                return style
            }
        }
    }

    static createHastagOptions(hashtags) {
        if (!hashtags) {
            return []
        }
        const hashtagOptions = hashtags.map(hashtag => {
            return {
                value: hashtag,
                label: hashtag
            }
        })
        hashtagOptions.sort((a, b) => helpers.sortByName(a.label, b.label))

        return hashtagOptions
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isDisabled !== state.isDisabled) {
            return { isDisabled: props.isDisabled }
        }
        if (!HashtagsSelect.hasSameHashtags(props.defaultHashtags, state.defaultHashtags)) {
            const defaultHashtagOptions = HashtagsSelect.createHastagOptions(props.defaultHashtags)

            if (defaultHashtagOptions.length === 0) {
                return { defaultHashtags: props.defaultHashtags }
            } else {
                return { defaultHashtagOptions, defaultHashtags: props.defaultHashtags }
            }
        }

        if (!HashtagsSelect.hasSameHashtags(props.hashtags, state.hashtags)) {
            const hashtagOptions = HashtagsSelect.createHastagOptions(props.hashtags)
            return { hashtagOptions, hashtags: props.hashtags }
        }

        return null
    }

    static hasSameHashtags(hashtags1, hashtags2) {
        if (!hashtags1) {
            return false
        }

        if (!hashtags2) {
            return false
        }

        if (hashtags1.length !== hashtags2.length) {
            return false
        }
        for (let i = 0; i < hashtags1.length; i++) {
            if (hashtags1[i] !== hashtags2[i]) {
                return false
            }
        }

        return true
    }

    render() {
        const hashtagColor = this.props.hashtagColor ? this.props.hashtagColor : colors.green_spectaflow
        const borderHoverColor = this.props.borderHoverColor ? this.props.borderHoverColor : hashtagColor
        const optionBackgroundColor = this.props.optionBackgroundColor ? this.props.optionBackgroundColor : iOSColors.midGray

        return (
            <CreatableSelect
                formatCreateLabel={userInput => `Add ${userInput}`}
                isDisabled={this.state.isDisabled}
                value={this.state.hashtagOptions}
                maxMenuHeight={150}
                captureMenuScroll
                placeholder="Select hashtags..."
                isMulti
                name="hashTags"
                options={this.state.defaultHashtagOptions}
                styles={this.getSelectStyles(hashtagColor, borderHoverColor, optionBackgroundColor)}
                onChange={this.onChange}
                noOptionsMessage={() => (this.props.noOptionsMessage ? this.props.noOptionsMessage : 'Enter # to add a new category')}
            />
        )
    }

    onChange(hashtagOptions) {
        if (!hashtagOptions) {
            const emptyArr = []
            this.setState({ hashtagOptions: emptyArr })
            this.props.onChange(emptyArr)
        } else {
            let validHashtagOptions = hashtagOptions.map(hashtag => {
                let hashtagStr = hashtag.value

                if (hashtag.label.charAt(0) !== '#') {
                    hashtagStr = '#' + hashtagStr
                }

                hashtagStr = hashtagStr.replace(/\s/g, '_')

                return {
                    value: hashtagStr,
                    label: hashtagStr
                }
            })
            validHashtagOptions = validHashtagOptions.filter(x => x !== undefined)
            this.setState({ hashtagOptions: validHashtagOptions })
            const validHashtags = validHashtagOptions.map(hashtag => hashtag.value).sort((a, b) => helpers.sortByName(a.label, b.label))
            this.props.onChange(validHashtags)
        }
    }
}
