import * as React from 'react'
import { useRef } from 'react'
import styled from 'styled-components'
import { white } from '@shared/colors'
import { useClickOutside } from 'app/utils/hooks/useClickOutside'

type Overflow = 'visible' | 'hidden' | 'scroll' | 'auto'

const Wrap = styled.div<{ width?: number; height?: number; overflow?: Overflow }>`
    @keyframes fadeInDown {
        from {
            opacity: 0;
            transform: translateY(-10px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    position: absolute;
    width: ${({ width }) => (width ? `${width}px` : '245px')};
    left: -270%;
    display: flex;
    flex-direction: column;
    background-color: ${white};
    animation: fadeInDown 0.25s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 6px 0;
    border-radius: 8px;
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    overflow: ${({ overflow }) => (overflow ? overflow : 'visible')};
`
interface Props {
    onBlur: () => void
    children: React.ReactNode
    width?: number
    overflow?: Overflow
    height?: number
}

function ContextMenu({ children, onBlur, width, overflow, height }: Props) {
    const wrapRef = useRef<HTMLDivElement>(null)

    useClickOutside(wrapRef, onBlur)

    return (
        <Wrap width={width} overflow={overflow} height={height} ref={wrapRef}>
            {children}
        </Wrap>
    )
}

export default ContextMenu
