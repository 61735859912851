export const AreaStatusCssClasses = [
    'specta-green',
    'specta-red',
    'specta-purple',
    'specta-blue',
    'specta-teal',
    'specta-gray',
    'specta-yellow',
    'specta-gold'
]
export const CleaningStatusClasses = {
    clean: { name: 'Clean', color: 'specta-green' },
    dirty: { name: 'Unclean', color: 'specta-red' },
    'waiting-for-checkout': { name: 'Waiting for checkout', color: 'specta-purple' },
    'do-not-disturb': { name: 'Do not disturb', color: 'specta-blue' },
    'no-cleaning-service': { name: 'No service', color: 'specta-teal' },
    'no-service': { name: 'Out of service', color: 'specta-gray' },
    preparing: { name: 'Preparing', color: 'specta-yellow' },
    inspection: { name: 'Inspection', color: 'specta-gold' }
}
export const AreaStatusTitles = ['Clean', 'Unclean', 'Waiting for checkout', 'Do not disturb', 'Out of service', 'Preparing', 'Inspection']
export const AreaStatusConstants = [
    'clean',
    'dirty',
    'waiting-for-checkout',
    'do-not-disturb',
    'no-service',
    'no-cleaning-service',
    'preparing',
    'inspection'
]
export const AreaTypeCssClasses = ['success', 'primary', '']
export const AreaTypeTitles = ['Business', 'Individual', '']
export const defaultSorted = [{ dataField: 'name', order: 'asc' }]
export const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '100', value: 100 },
    { text: '250', value: 250 },
    { text: '500', value: 500 }
]
export const initialFilter = {
    filter: {
        name: '',
        description: '',
        group: ['All'],
        synced: '',
        address: '',
        status: ''
    },
    sortOrder: 'asc', // asc||desc
    sortField: 'name',
    pageNumber: 1,
    pageSize: 25
}
