import React from 'react'
import { ModalOverlay, ModalContainer } from '../../../components/styled-components/modal'
import { MagicLinkWrap, RectanglesWrap } from './style'
import { getNavBarNOAuthLogo } from '@shared/brand'
import { renderRectangles } from '../../../utils/styles'
import { SMALL_DESKTOP_SCREEN_HEIGHT } from '../../../utils/constants'

const MagicLink = ({ email }) => {
    const brandLogo = getNavBarNOAuthLogo()
    const padding = window.innerHeight <= SMALL_DESKTOP_SCREEN_HEIGHT ? '25px' : '64px 37px 51.72px 37px'

    return (
        <ModalOverlay>
            <ModalContainer width={'512px'} height={'718px'} padding={padding}>
                <MagicLinkWrap>
                    <img src={brandLogo.img} style={brandLogo.style} />

                    <div className="text-container">
                        <div>
                            <h1>{'We’ve sent you a link!'}</h1>
                            <p>{'We’ve sent a magic link to your email address.'}</p>
                            <span>{email}</span>
                        </div>
                    </div>

                    <RectanglesWrap>{renderRectangles(5)}</RectanglesWrap>
                </MagicLinkWrap>
            </ModalContainer>
        </ModalOverlay>
    )
}

export default MagicLink
