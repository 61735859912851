import styled from 'styled-components'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'

export const Wrapper = styled.div<{ width?: string }>`
    width: ${({ width }) => (width ? width : '240px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 6px;
`

export const Input = styled.div<{ height?: string }>`
    border: 1px solid ${colors.textInputBorder};
    border-radius: 6px;
    width: 100%;
    height: ${({ height }) => (height ? height : '40px')};
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
        color: ${iOSColors.midGray};
        font-size: 1.538rem;
        font-weight: 700;
    }
`

export const Label = styled.span`
    color: ${iOSColors.midGray};
    font-size: 0.923rem;
    font-weight: 500;
`
