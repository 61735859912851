import React from 'react'
import { View } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import { HoverableImageButton } from './buttons/hoverable-image-button'
import Assignees from './assignees'
import * as uc from '../utils/constants'

export default function AssigneesView(props) {
    const assignedTo = props.assignedTo

    if (!assignedTo) {
        return (
            <View>
                <span style={{ fontSize: 14, color: iOSColors.midGray }}>Not assigned yet</span>
            </View>
        )
    }

    return (
        <View
            style={{
                width: 'fit-content',
                minWidth: 120,
                borderColor: 'red',
                borderWidth: 0
            }}>
            <View
                style={{
                    opacity: props.unassignDisabled ? 0.35 : 1,
                    zIndex: 10,
                    position: 'absolute',
                    top: -6,
                    right: 0,
                    height: 34,
                    borderWidth: 0,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <HoverableImageButton
                    disabled={props.unassignDisabled}
                    dimension={22}
                    hoverOpacity={uc.HOVER_OPACITY}
                    imageSource={require('@shared/img/close-grey.svg')}
                    onPress={props.onUnassignPress}
                    tooltipText={'Unassign'}
                />
            </View>

            <span
                style={{
                    fontWeight: props.fontWeight ? props.fontWeight : '500',
                    color: iOSColors.black,
                    fontSize: 17,
                    marginBottom: 12
                }}>
                Assigned to
            </span>
            <Assignees maxCountToDisplay={props.maxCountToDisplay} assignees={assignedTo} currentUser={props.currentUser} />
        </View>
    )
}
