import React from 'react'
import Mouth from './Mouth'
import { ErrorImageWrap, ErrorMessageWrap } from './style'
import RectangularButton from '../buttons/RectangularButton'

const ErrorMessage = ({ message, button, closeModal }) => {
    function onClick() {
        if (button.onClick) button.onClick()
        closeModal()
    }

    return (
        <ErrorMessageWrap>
            <div>
                <h1>Error</h1>
                <p>{message}</p>

                {button && <RectangularButton onClick={onClick}>{button.text}</RectangularButton>}

                <ErrorImageWrap>
                    <div className="eye-1" />
                    <div className="eye-2" />
                    <Mouth />
                </ErrorImageWrap>
            </div>
        </ErrorMessageWrap>
    )
}

export default ErrorMessage
