import styled from 'styled-components'

export const ImagesDropBoxWrapper = styled.div`
    height: ${({ height }) => `${height}px`};
`

export const DropBoxAndPreviewWrap = styled.div`
    display: flex;
    column-gap: 10px;
    height: 100%;
`

export const DropBox = styled.div`
    border: 2px dashed rgb(229, 229, 234);
    width: ${({ width }) => `${width}px`};
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border-radius: 4px;

    > input {
        display: none;
    }

    > p {
        text-align: center;
        color: rgb(142, 142, 147);
        font-size: 10px;
        margin: 0;
    }
`

export const ImagesPreviewWrap = styled.div`
    display: flex;
    column-gap: 10px;
    width: 78%;
    height: 100%;
    overflow-x: scroll;
`
