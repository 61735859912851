import { AreaStruct, UserStruct } from './firestore-structs'
import { AREA_GROUPS_ALL } from './txt-constants'
import { clone } from 'ramda'

export function hasSameAreaGroups(areaGroups1: string[], areaGroups2: string[]) {
    if (!areaGroups1) {
        return false
    }

    if (!areaGroups2) {
        return false
    }

    if (areaGroups1.length !== areaGroups2.length) {
        return false
    }
    for (let i = 0; i < areaGroups1.length; i++) {
        if (areaGroups1[i] !== areaGroups2[i]) {
            return false
        }
    }

    return true
}

//TODO: add types
export function hasSameAreaGroupsFilter(filter1: any, filter2: any) {
    if (!filter1) {
        return false
    }

    if (!filter2) {
        return false
    }

    if (filter1.length !== filter2.length) {
        return false
    }
    for (let i = 0; i < filter1.length; i++) {
        if (filter1[i].selected !== filter2[i].selected) {
            return false
        }
        if (filter1[i].name !== filter2[i].name) {
            return false
        }
    }

    return true
}

export function filterByAreaGroupAccess(currentUser: UserStruct, areas: Partial<AreaStruct>[]) {
    let userAreaGroupsAreas = clone(areas)
    const userAreaGroups = new Set(currentUser.areaGroups)

    if (userAreaGroups.size > 0) {
        if (!userAreaGroups.has('All')) {
            userAreaGroupsAreas = userAreaGroupsAreas.filter(area => area.group && userAreaGroups.has(area.group))
        }
    } else {
        userAreaGroupsAreas = []
    }

    return userAreaGroupsAreas
}

export function userHasAccessToAreaGroup(currentUserAreaGroups: string[], areaGroup: string | undefined) {
    if (!areaGroup) return false
    return currentUserAreaGroups.includes(areaGroup) || currentUserAreaGroups.includes(AREA_GROUPS_ALL)
}
