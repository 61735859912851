import * as React from 'react'
import { useEffect } from 'react'
import * as c from '@shared/constants'
import AsyncStorage from '@react-native-community/async-storage'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentTasksTypesAtom, taskboardContextAtom } from '../../state/atoms'
import { TaskboardContext } from '@shared/traces-types'
import { Button } from './style'
import CheckmarkIcon from './CheckmarkIcon'
import { TaskStruct } from '@shared/firestore-structs'

export default function FilterButtons() {
    const [currentTasksTypes, setCurrentTasksTypes] = useRecoilState<TaskStruct['type'][]>(currentTasksTypesAtom)
    const taskboardContext = useRecoilValue(taskboardContextAtom)

    const filterButtons = [
        { title: 'General', type: c.TASK_TYPE_GENERAL as TaskStruct['type'] },
        { title: 'Issues', type: c.TASK_TYPE_ISSUE as TaskStruct['type'] },
        { title: 'Housekeeping', type: c.TASK_TYPE_HOUSEKEEPING as TaskStruct['type'] }
    ]

    useEffect(() => {
        if (taskboardContext !== TaskboardContext.RESERVATIONS) {
            AsyncStorage.getItem('currentTasksTypes', (err, result) => {
                if (result && !err) {
                    setCurrentTasksTypes(JSON.parse(result))
                }
            })
        } else {
            setCurrentTasksTypes([c.TASK_TYPE_GENERAL])
        }
    }, [taskboardContext])

    if (taskboardContext === TaskboardContext.RESERVATIONS) {
        return null
    }

    return (
        <>
            {filterButtons.map(({ title, type }, index) => {
                const selected = currentTasksTypes.includes(type)

                const onFilterButtonClick = () => {
                    const newTasksTypes = selected ? currentTasksTypes.filter(t => t !== type) : [...currentTasksTypes, type]

                    setCurrentTasksTypes(newTasksTypes)
                    AsyncStorage.setItem('currentTasksTypes', JSON.stringify(newTasksTypes))
                }

                return (
                    <Button selected={selected} className={'btn'} key={type + index} onClick={onFilterButtonClick}>
                        {selected && <CheckmarkIcon />}
                        {title}
                    </Button>
                )
            })}
        </>
    )
}
