import React, { Suspense, lazy, useContext } from 'react'
import { ActivityIndicator, View, Image, Text, TouchableOpacity } from 'react-native-web'
import { iOSColors, webWeights } from '@shared/react-native-typography'
import * as colors from '@shared/colors'
import * as brand from '@shared/brand'

export default function NewVersion() {
    return (
        <View
            style={{
                backgroundColor: colors.gentle_gray_spectaflow,
                height: 40,
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <View style={{ flexDirection: 'row', opacity: 0.9 }}>
                <Image source={require('@shared/img/refresh-monitor.svg')} style={{ opacity: 0.35, width: 20, height: 18.18 }} />
                <span style={{ marginLeft: 12, color: iOSColors.gray, fontWeight: '300' }}>
                    A new version of {brand.getBrand().name} is available.{' '}
                </span>
                <TouchableOpacity
                    onPress={() => {
                        window.location.reload(true)
                    }}
                    style={{
                        marginLeft: 12
                    }}>
                    <span style={{ color: colors.green_spectaflow, fontWeight: '500' }}>Refresh</span>
                </TouchableOpacity>
            </View>
        </View>
    )
}
