import * as uc from '../../utils/constants'
import * as helpers from '@shared/helpers'
import * as c from '@shared/constants'
import { CLEANING_STATUS_NO_SERVICE } from '@shared/constants'
import { AreaCleaningStatus, AreaStruct, ExtraStruct, Occupancy, TaskStruct, UserStruct } from '@shared/firestore-structs'
import { filterByAreaGroupAccess } from '@shared/area-groups-helpers'
import cleaningStatusFilter from '../../modules/HousekeepingDashboard/SearchAndFilters/Filters/CleaningStatusFilter'

export type AreaLikeStruct = {
    bookingNotes?: string
    note: string
    guestCheckedOut: boolean
    synced: boolean
    occupancy: Occupancy
    guestCheckedIn: boolean
    currentTask?: TaskStruct
    extras?: ExtraStruct[]
    priority: boolean
    assignedTo: string[]
    activeRule: { checklistTasks?: string[]; name?: string; description?: string }
    leadBooking: {
        bedSetup?: string
        checkoutDate: number
        notes?: string
        checkinDate: number
        nrOfGuests: number
        status: 'Cancelled' | 'Booked' | 'Request' | 'Blocked'
        guestName: string
    } | null
    cleaningStatus: AreaCleaningStatus
    name: string
    dailyComments: { date: number; comment: string }[]
    key: string
    group: string
}

export const filterAreas = (
    areas: Partial<AreaStruct>[],
    currentUser: UserStruct,
    searchValue: string,
    occupancyFilter: string,
    assignModeFilter: string,
    cleaningStatusFilter: { isSelected: boolean; value: string }[],
    priorityFilter: boolean
) => {
    let filteredAreas = filterByAreaGroupAccess(currentUser, areas)
    filteredAreas = helpers.searchAreas(searchValue, areas as (AreaStruct & { task: TaskStruct; status: string })[], helpers.areaSearchSet)
    filteredAreas = filterByOccupancy(filteredAreas, occupancyFilter)
    filteredAreas = filterByAssigned(filteredAreas, assignModeFilter)
    filteredAreas = filterByCleaningStatus(filteredAreas, cleaningStatusFilter)
    filteredAreas = helpers.sortAreas(filteredAreas)

    const priorityAreas = filteredAreas.filter((area: any) => area.priority)
    filteredAreas = priorityFilter ? priorityAreas : filteredAreas

    return filteredAreas
}

export const getWorkloadCounts = (areas: AreaStruct[]) => {
    const unclean50TaskCount = areas.filter(
        area => area.occupancy === c.OCCUPANCY_STAYOVER && area.cleaningStatus !== CLEANING_STATUS_NO_SERVICE
    ).length
    const unclean80TaskCount = areas.filter(
        area => area.occupancy === c.OCCUPANCY_STAYOVER_80 && area.cleaningStatus !== CLEANING_STATUS_NO_SERVICE
    ).length
    const checkOutCount = areas.filter(area => area.occupancy === c.OCCUPANCY_CHECKOUT).length
    const turnoverCount = areas.filter(area => area.occupancy === c.OCCUPANCY_TURNOVER).length
    const unclean100TaskCount = checkOutCount + turnoverCount
    const checkinTaskCount = areas.filter(area => area.occupancy === c.OCCUPANCY_CHECKIN || area.occupancy === c.OCCUPANCY_TURNOVER).length
    const noServiceTaskCount = areas.filter(area => area.cleaningStatus === c.CLEANING_STATUS_NO_SERVICE).length
    return {
        unclean50TaskCount,
        unclean80TaskCount,
        unclean100TaskCount,
        checkinTaskCount,
        noServiceTaskCount
    }
}

export const filterByOccupancy = (areas: Partial<AreaStruct>[], occupancyFilter: string) => {
    const filteredAreas = areas.filter((area: Partial<AreaStruct>) => {
        if (occupancyFilter === c.OCCUPANCY_ALL) {
            return true
        }
        if (
            occupancyFilter === c.OCCUPANCY_CHECKIN &&
            (area.occupancy === c.OCCUPANCY_TURNOVER || area.occupancy === c.OCCUPANCY_CHECKIN)
        ) {
            return true
        }
        if (
            occupancyFilter === c.OCCUPANCY_CHECKOUT &&
            (area.occupancy === c.OCCUPANCY_TURNOVER || area.occupancy === c.OCCUPANCY_CHECKOUT)
        ) {
            return true
        }
        return occupancyFilter === area.occupancy
    })

    return filteredAreas
}

export const filterByAssigned = (areas: any[], assignModeFilter: string) => {
    if (assignModeFilter === c.ASSIGN_MODE_FILTER_ALL) {
        return areas
    }

    return areas.filter(area => {
        if (area.currentTask && assignModeFilter === c.ASSIGN_MODE_FILTER_ASSIGNED) {
            return true
        } else if (area.currentTask && assignModeFilter === c.ASSIGN_MODE_FILTER_UN_ASSIGNED) {
            return false
        } else if (area.currentTask === null && assignModeFilter === c.ASSIGN_MODE_FILTER_ASSIGNED) {
            return false
        } else return area.currentTask === null && assignModeFilter === c.ASSIGN_MODE_FILTER_UN_ASSIGNED
    })
}

export const getContainerWidth = () => {
    if (uc.INSIDE_IFRAME) return window.innerWidth - 50
    else if (window.innerWidth <= 500) {
        return window.innerWidth - 30
    } else if (window.innerWidth >= 505 && window.innerWidth < 540) {
        return window.innerWidth - (2.25 * 12 + 30)
    } else if (window.innerWidth >= 540 && window.innerWidth < 720) {
        return window.innerWidth - (2.25 * 12 + 30)
    } else if (window.innerWidth >= 720 && window.innerWidth < 768) {
        return window.innerWidth - (2.25 * 12 + 30)
    } else if (window.innerWidth >= 768 && window.innerWidth < 960) {
        const tilesPerRow = Math.floor((window.innerWidth - (2.25 * 12 + 30)) / 110) + 1
        return tilesPerRow * 110 + (2.25 * 12 + 30)
    } else if (window.innerWidth >= 960 && window.innerWidth < 1200) {
        return 960 - 100
    } else if (window.innerWidth >= 1200 && window.innerWidth < 1440) {
        return window.innerWidth - (2.25 * 12 + 30)
    } else {
        return window.innerWidth - 250
    }
}

export const getTilesDimensions = () => {
    const tilesPerRow = getContainerWidth() / 110
    const tilesWidthTotal = 100 * Math.floor(tilesPerRow)
    const gutter = getContainerWidth() - tilesWidthTotal

    let numCols = Math.floor(tilesPerRow)
    if (!uc.INSIDE_IFRAME) {
        numCols -= 2
    }
    let tilesGutter = Math.floor(gutter / (numCols + 1) - 1)

    if (getContainerWidth() - (numCols * 100 + numCols * tilesGutter) < 0) {
        numCols = numCols - 1
        tilesGutter = tilesGutter + 4
    }

    return { numCols, tilesGutter }
}

export const filterByCleaningStatus = (areas: any, cleaningStatusFilter: { isSelected: boolean; value: string }[]) => {
    let filteredAreas = areas
    if (cleaningStatusFilter[0].isSelected) {
        return filteredAreas
    } else {
        filteredAreas = filteredAreas.filter((area: any) =>
            cleaningStatusFilter.some(option => option.isSelected && option.value === area.cleaningStatus)
        )
        return filteredAreas
    }
}

interface HashWithCount {
    [key: string]: number
}

export const getCountedAreaGroups = (areas: any) => {
    let countedAreaGroups: any[] = []
    const hashWithCount: HashWithCount = {}
    let longestAreaName = 0

    areas.forEach((area: any) => {
        longestAreaName = area.name.length > longestAreaName ? area.name.length : longestAreaName
        hashWithCount[area.group] = hashWithCount[area.group] === undefined ? 1 : hashWithCount[area.group] + 1
    })

    Object.keys(hashWithCount).forEach(key => {
        countedAreaGroups.push({
            name: key,
            count: hashWithCount[key]
        })
    })

    countedAreaGroups = countedAreaGroups.sort((a, b) => b.count - a.count)

    return countedAreaGroups
}

export const formatAreasBySection = (areas: any[]) => {
    const areaSections: any = {}

    // @ts-ignore
    const areasSorted = areas.sort((a: any, b: any) => {
        a.group = a.group ? a.group : a.address
        b.group = b.group ? b.group : b.address
        return a.group.toLowerCase() > b.group.toLowerCase()
    })

    areasSorted.forEach(area => {
        if (areaSections[area.group] === undefined || areaSections[area.group].length === 0) {
            areaSections[area.group] = []
        }
        areaSections[area.group].push(area)
    })

    return Object.keys(areaSections).map(key => {
        const sortedAreas = helpers.sortAreas(areaSections[key])
        const section = {
            title: key,
            data: sortedAreas,
            count: sortedAreas.length,
            unclean50TaskCount: sortedAreas.filter(
                area => area.occupancy === c.OCCUPANCY_STAYOVER && area.cleaningStatus !== CLEANING_STATUS_NO_SERVICE
            ).length,
            unclean80TaskCount: sortedAreas.filter(
                area => area.occupancy === c.OCCUPANCY_STAYOVER_80 && area.cleaningStatus !== CLEANING_STATUS_NO_SERVICE
            ).length,
            unclean100TaskCount: sortedAreas.filter(
                area => area.occupancy === c.OCCUPANCY_CHECKOUT || area.occupancy === c.OCCUPANCY_TURNOVER
            ).length,
            checkinTaskCount: sortedAreas.filter(area => area.occupancy === c.OCCUPANCY_CHECKIN || area.occupancy === c.OCCUPANCY_TURNOVER)
                .length,
            noServiceTaskCount: sortedAreas.filter(area => area.cleaningStatus === c.CLEANING_STATUS_NO_SERVICE).length
        }

        return section
    })
}
