import React, { useState } from 'react'
import firebase from 'app/utils/firebase'
import { ModalOverlay, ModalContainer } from 'app/components/styled-components/modal'
import { containerPadding, containerWidth } from './style'
import LoginLayout from 'app/components/layouts/LoginLayout'
import { LoginFormWrapper } from './LoginForm/style'
import { Field, Form, Formik } from 'formik'
import RectangularButton from 'app/components/buttons/RectangularButton'
import Input from 'app/components/forms/Input'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastErrorStyle } from 'app/utils/styles'
import Loader from 'app/components/loaders/Loader'

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required')
})

const LoginEmailPassword = () => {
    const [loading, setLoading] = useState(false)

    const login = async (email: string, password: string) => {
        setLoading(true)
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password)
        } catch (error) {
            console.error(error)
            toast.error(`${error instanceof Error ? error.message : 'Something went wrong'}`, toastErrorStyle)
            setLoading(false)
        }
    }

    return (
        <ModalOverlay>
            <ModalContainer width={containerWidth} padding={containerPadding}>
                <LoginLayout>{loading ? <Loader size="large" /> : <LoginEmailPasswordForm onSubmit={login} />}</LoginLayout>
            </ModalContainer>
        </ModalOverlay>
    )
}

export function LoginEmailPasswordForm({ onSubmit }: { onSubmit: (email: string, password: string) => void }) {
    return (
        <LoginFormWrapper>
            <Formik
                validateOnMount
                validationSchema={validationSchema}
                initialValues={{ email: '', password: '' }}
                onSubmit={({ email, password }) => onSubmit(email, password)}>
                {({ values, errors, setFieldValue, handleSubmit }) => {
                    const { email, password } = values
                    const disabled = Object.keys(errors).length !== 0

                    return (
                        <Form>
                            <Field
                                name="email"
                                type="email"
                                component={Input}
                                value={email}
                                label={'Email'}
                                placeholder={'your.email@mail.com'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('email', e.target.value)}
                            />
                            <Field
                                name="password"
                                type="password"
                                component={Input}
                                value={password}
                                label={'Password'}
                                placeholder={'Your password'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('password', e.target.value)}
                            />

                            <div className={'btn-wrap'}>
                                <RectangularButton
                                    disabled={disabled}
                                    type="submit"
                                    width={'181px'}
                                    height={'64px'}
                                    onClick={() => handleSubmit()}>
                                    Login
                                </RectangularButton>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </LoginFormWrapper>
    )
}

export default LoginEmailPassword
