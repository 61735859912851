import React from 'react'
import { ReportsProvider } from '../../ReportsContext.js'
import WorkloadCard from './WorkloadCard'
export default function WorkloadPage() {
    return (
        <div>
            <ReportsProvider>
                <WorkloadCard />
            </ReportsProvider>
        </div>
    )
}
