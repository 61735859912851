import React, { useContext, useEffect, useState } from 'react'
import { Header, Wrap } from './style'
import moment from 'moment-timezone'
import DailyCommentIcon from '@shared/img/cleaning-status/daily-comment-filter-off.svg?react'
import { HoverableTextInput } from 'app/components/hoverable-text-input'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { areaAtom, currentDateNumberAtom, dailyCommentAtom, subscriptionParamsAtom } from '../state/atoms'
import { createDailyComment, logActivity, touchArea, updateDailyComment } from '@shared/area-data'
import firebase, { asFirebase } from 'app/utils/firebase'
import { currentUserAtom } from 'app/modules/Auth/atoms'
import { AreaStruct, UserStruct } from '@shared/firestore-structs'
import { selectedDateNumberSelector } from '../state/selectors/selectedDateNumber'
import { toast } from 'react-toastify'
import * as c from '@shared/constants'
import { toastErrorStyle } from 'app/utils/styles'
import { SectionTitleWrap } from '../style'
import { FeatureLockedSignal, SubscriptionCheck } from 'app/components/upgrade-signals'
import { SubscriptionContext } from 'app/modules/Subscriptions/SubscriptionContext'

function DailyCommentSection() {
    const { checkSubscription } = useContext(SubscriptionContext)
    const [hasSubscriptionAccess, setHasSubscriptionAccess] = useState(false)

    const [dailyComment, setDailyComment] = useRecoilState(dailyCommentAtom)
    const selectedDateNumber = useRecoilValue(selectedDateNumberSelector)
    const area = useRecoilValue(areaAtom) as AreaStruct
    const currentDateNumber = useRecoilValue(currentDateNumberAtom)
    const currentUser = useRecoilValue(currentUserAtom) as UserStruct
    const setSubscriptionParams = useSetRecoilState(subscriptionParamsAtom)

    useEffect(() => {
        checkSubscription(
            'daily-comments',
            () => setHasSubscriptionAccess(true),
            () => setHasSubscriptionAccess(false)
        )
    }, [])

    async function onSubmit(value: string) {
        if (value === dailyComment?.comment || (!dailyComment && value === '')) return

        dailyComment && setDailyComment({ ...dailyComment, comment: value })

        try {
            const updatedDailyComment = dailyComment
                ? await updateDailyComment(asFirebase(firebase), area.key, dailyComment, value, currentUser)
                : await createDailyComment(asFirebase(firebase), area.key, selectedDateNumber, value, currentUser)

            const change = dailyComment
                ? { before: dailyComment.comment, after: updatedDailyComment.comment }
                : { after: updatedDailyComment.comment }

            await logActivity(asFirebase(firebase), currentUser, area.key, c.ACTIVITY_TYPE_DAILY_COMMENT, selectedDateNumber, change)

            await touchArea(asFirebase(firebase), area.key, currentUser)
        } catch (error: unknown) {
            console.error(error)
            toast.error(`Failed to save daily comment (${(error as Error).message})`, toastErrorStyle)
        }
    }

    return (
        <Wrap>
            <Header>
                <SectionTitleWrap>
                    <DailyCommentIcon />
                    <h2>Daily comment</h2>
                    <span>{`displays on ${moment(currentDateNumber).format('ddd, MMM D YYYY')}`}</span>
                </SectionTitleWrap>

                <SubscriptionCheck
                    featureId="daily-comments"
                    onAvailable={() => null}
                    onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                        return (
                            <FeatureLockedSignal
                                showLock
                                isActive
                                onClick={() =>
                                    setSubscriptionParams({
                                        notAccessibleFeature,
                                        availableUpgrades,
                                        upgradeMessageIsOpen: true,
                                        hasSubscriptionAccess: false
                                    })
                                }
                            />
                        )
                    }}
                />
            </Header>

            <div>
                <HoverableTextInput
                    disabled={!hasSubscriptionAccess}
                    showIcon
                    text={dailyComment?.comment ?? ''}
                    placeholder={'Click to add a comment'}
                    fontSize={17}
                    fontWeight={'300'}
                    textPaddingLeft={12}
                    height={40}
                    onSubmit={onSubmit}
                />
            </div>
        </Wrap>
    )
}

export default DailyCommentSection
