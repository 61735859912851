import React, { useMemo } from 'react'
import { SearchInput } from '../../components/forms/SearchInput'

export default function Search({ value, onChange }: { value: string; onChange: (value: string) => void }) {
    const memoizedSearchInput = useMemo(
        () => (
            <div className="col-xs-12 col-md-4 my-sm-3 my-xs-10">
                {/* TODO - refactor this component to avoid unnecessary props */}
                <SearchInput
                    handleBlur={() => null}
                    handleSubmit={onChange}
                    setFieldValue={() => null}
                    fieldValueToSet={value}
                    placeholderText={'Issues, units, assignees, and more'}
                    helpText=""
                    initValue={value}
                    containerStyle={{ width: '80%' }}
                    containerClass=""
                    hasTimeout={true}
                />
            </div>
        ),
        [value, onChange]
    )

    return memoizedSearchInput
}
