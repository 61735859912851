import { iOSColors } from '@shared/react-native-typography'
import styled from 'styled-components'

export const Wrap = styled.div`
    width: 100%;
    padding: 24px 40px;
`

export const Text = styled.span`
    font-size: 1.077rem;
    color: ${iOSColors.gray};
    white-space: pre-line;
`
