import React, { useEffect, useMemo, useState, useContext } from 'react'
import Select, { createFilter, components } from 'react-select'
import * as colors from '@shared/colors'
import { PhotoWrap } from '../layouts/User/style'
import { useRecoilValue } from 'recoil'
import { currentUserAtom } from 'app/modules/Auth/atoms'
import { getBrand } from '@shared/brand'
import { FeatureLockedSignal, UpgradeMessageContent } from '../upgrade-signals'
import { upgradeMessageStyle } from '../../housekeeping/dashboard/styles.js'
import Modal from 'react-modal'
import { SubscriptionContext } from '../../modules/Subscriptions/SubscriptionContext'

const getFieldCSSClasses = (touched, errors) => {
    console.log(touched, errors)
    const classes = []
    if (errors) {
        classes.push('is-invalid')
    }

    if (touched && !errors) {
        classes.push('is-valid')
    }

    return classes.join(' ')
}

const getDropdownStyles = (error, field, touched) => {
    const isValid = field.value && field.value.length > 0
    const isTouched = touched[field.name]
    return {
        control: (styles, state) => {
            return {
                ...styles,
                height: 'calc(1.5em + 1.3rem + 2px);',
                backgroundColor: state.isDisabled ? '#f3f6f9' : null,
                borderColor: error ? colors.bs_danger : colors.bs_gray_300,
                // borderColor:
                //     error && isTouched && !state.isFocused
                //         ? colors.bs_danger
                //         : state.isFocused || isValid
                //         ? colors.bs_success
                //         : colors.bs_gray_300,
                boxShadow: 'none',
                ':hover': {
                    borderColor:
                        error && isTouched && !state.isFocused ? colors.bs_danger : state.isFocused ? colors.bs_success : colors.bs_gray_300
                },
                ':focus': {
                    borderColor: error ? colors.bs_danger : colors.bs_success
                },
                cursor: 'text'
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isSelected || isFocused ? colors.bs_gray_300 : null,
            color: isSelected || isFocused ? colors.bs_gray_900 : '',
            cursor: isDisabled ? 'default' : 'pointer'
        }),
        menuList: base => ({
            ...base,
            padding: 0,
            maxHeight: 200
        }),
        indicatorSeparator: (styles, { data }) => ({
            ...styles,
            // backgroundColor: '#f4f5f6',
            width: 0
        }),
        indicatorsContainer: (styles, { data }) => ({
            ...styles,
            cursor: 'pointer'
        }),
        multiValue: styles => {
            return { ...styles, height: '27px !important', alignItems: 'center' }
        }
    }
}

export function Dropdown({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, isValid, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    ...props
}) {
    const [hasFocus, setHasFocus] = useState(false)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)
    const [availableFeature, setAvailableFeature] = useState()
    const [notAvailableFeature, setNotAvailableFeature] = useState()
    const { currentSubscription, checkSubscription } = useContext(SubscriptionContext)

    useEffect(() => {
        if (props.featureId) {
            checkSubscription(
                props.featureId,
                () => {},
                (notAccessibleFeature, availableUpgrades) => {
                    setNotAvailableFeature(notAccessibleFeature)
                    setAvailableFeature(availableUpgrades)
                }
            )
        }
    }, [currentSubscription?.id])

    const withInitials = useMemo(() => props.options.every(o => o.initials), [props.options])

    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        stringify: option => {
            return `${option.label} ${option.data.subText}`
        },
        matchFrom: 'any'
    }

    field.onBlur = () => {
        setHasFocus(false)
    }

    const setModalClose = () => {
        setShowUpgradeModal(false)
    }

    return (
        <>
            <Modal isOpen={showUpgradeModal} style={upgradeMessageStyle} contentLabel="UpgradeMessages" onRequestClose={setModalClose}>
                <UpgradeMessageContent
                    onClose={setModalClose}
                    notAccessibleFeature={notAvailableFeature}
                    availableUpgrades={availableFeature}
                    showCloseButton={true}
                />
            </Modal>
            <div className="d-flex justify-content-between">
                {label && (
                    <label className="font-weight-bold text-dark-65">
                        {label}:{props.required && <span className="text-danger">*</span>}
                    </label>
                )}
                {props.showFeatureLock && (
                    <FeatureLockedSignal
                        showLock
                        isActive
                        onClick={() => {
                            setShowUpgradeModal(true)
                        }}
                    />
                )}
            </div>

            <Select
                filterOption={createFilter(filterConfig)}
                components={{ Option, MultiValueContainer: withInitials ? MultiValueContainer : components.MultiValueContainer }}
                onFocus={() => {
                    setFieldTouched(field.name)
                    setHasFocus(true)
                }}
                styles={getDropdownStyles(errors[field.name], field, touched)}
                {...field}
                {...props}
            />

            {errors[field.name] && !hasFocus && <div className="invalid-feedback d-block">{errors[field.name]}</div>}
        </>
    )
}

const Option = props => {
    const currentUser = useRecoilValue(currentUserAtom)
    const backgroundColor = currentUser.key !== props.data.value ? colors.green_spectaflow : getBrand().navBarColor
    return (
        <div
            key={props.value}
            className="border-bottom border-dark-25 px-5"
            onClick={() => props.selectOption(props.data)}
            {...props.innerProps}
            style={props.getStyles('option', props)}>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: 7 }}>
                {props.data.initials && (
                    <PhotoWrap size={'22px'} fontSize="0.615rem" backgroundColor={backgroundColor}>
                        {props.data.initials}
                    </PhotoWrap>
                )}
                <span>{props.label}</span>
            </div>
            <div>
                <span className="font-size-sm text-muted">{props.data.subText}</span>
            </div>
        </div>
    )
}

function MultiValueContainer(props) {
    const currentUser = useRecoilValue(currentUserAtom)
    const backgroundColor = currentUser.key !== props.data.value ? colors.green_spectaflow : getBrand().navBarColor
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                height: 27,
                width: 'fit-content',
                paddingLeft: 5,
                backgroundColor: 'hsl(0, 0%, 90%)',
                marginRight: 7
            }}>
            <PhotoWrap size={'22px'} fontSize="0.615rem" backgroundColor={backgroundColor}>
                {props.data.initials}
            </PhotoWrap>
            <components.MultiValueContainer {...props} />
        </div>
    )
}
