import { iOSColors } from '@shared/react-native-typography'
import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;

    > label {
        display: flex;
        align-items: center;
        column-gap: 16px;

        > label {
            font-size: 1.175rem;
            color: ${iOSColors.gray};
            font-weight: 600;
            margin: 0;

            > span {
                font-weight: 400;
                font-size: 1rem;
            }
        }
    }
`

export const AreasContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
`
