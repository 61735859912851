import styled from 'styled-components'
import { bs_gray_300 } from '@shared/colors'
import { TOP_BOTTOM_MARGIN } from '../style'

export const Wrap = styled.div`
    width: 100%;
    height: 43px;
    padding: 0 40px;
    margin: ${TOP_BOTTOM_MARGIN} 0;
    display: flex;
    justify-content: space-between;
`

export const TabsWrap = styled.div`
    display: flex;
    height: 100%;
    border-bottom: 1px solid ${bs_gray_300};
    column-gap: 26px;
`
