import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { allTasksAtom, fetchedTasksAtom, subscriptionParamsAtom } from '../state/atoms'
import AsyncStorage from '@react-native-community/async-storage'
import { IconButton } from '../../components/buttons/IconButton'
import { PriorityButtonWrap } from './style'
import { TaskStruct } from '@shared/firestore-structs'

export default function PriorityFilter() {
    const [{ hasSubscriptionAccess }, setSubscriptionParams] = useRecoilState(subscriptionParamsAtom)
    const [allTasks, setAllTasks] = useRecoilState(allTasksAtom)
    const fetchedTasks = useRecoilValue(fetchedTasksAtom)

    const [filterPriority, setFilterPriority] = useState(false)
    const [priorityTasks, setPriorityTasks] = useState<TaskStruct[]>([])

    useEffect(() => {
        const priorityTasks = allTasks.filter(task => task.priority)
        setPriorityTasks(priorityTasks)

        if (filterPriority) setAllTasks(priorityTasks)
    }, [allTasks.length])

    useEffect(() => {
        AsyncStorage.getItem('tasks-filterPriority', (err, result) => {
            if (result && !err) setFilterPriority(JSON.parse(result))
        })
    }, [])

    function onClick() {
        if (!hasSubscriptionAccess) {
            setSubscriptionParams(prev => ({ ...prev, upgradeMessageIsOpen: true }))
            return
        }

        setAllTasks(!filterPriority ? priorityTasks : fetchedTasks)
        AsyncStorage.setItem('tasks-filterPriority', JSON.stringify(!filterPriority))
        setFilterPriority(!filterPriority)
    }

    return (
        <PriorityButtonWrap>
            <IconButton
                icon="priority-low icon-lg"
                onClick={onClick}
                tooltip={{ text: 'See all', position: 'top' }}
                className={`btn btn-circle btn-icon btn-${filterPriority ? 'danger' : 'specta-gray'} ${filterPriority ? 'text-white' : ''}`}
            />
        </PriorityButtonWrap>
    )
}
