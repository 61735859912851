import React, { createContext, useContext, useState } from 'react'
import { AuthContext } from '../../modules/Auth/AuthContext'
import { ASSIGN_MODE_FILTER_ALL, OCCUPANCY_ALL } from '@shared/txt-constants'
import moment from 'moment'
import * as c from '@shared/constants'

export const DashboardContext = createContext()

const defaultCleaningStatusFilter = [
    {
        label: 'All',
        value: c.CLEANING_STATUS_ALL,
        isSelected: true,
        count: 0
    },
    {
        label: 'Waiting for checkout',
        value: c.CLEANING_STATUS_WAITING_FOR_CHECKOUT,
        isSelected: true,
        count: 0
    },
    { label: 'Unclean', value: c.CLEANING_STATUS_DIRTY, isSelected: true, count: 0 },
    { label: 'Preparing', value: c.CLEANING_STATUS_PREPARING, isSelected: true, count: 0 },
    { label: 'Clean', value: c.CLEANING_STATUS_CLEAN, isSelected: true, count: 0 },
    { label: 'Inspection', value: c.CLEANING_STATUS_INSPECTION, isSelected: true, count: 0 },

    { label: 'Do not disturb', value: c.CLEANING_STATUS_DO_NOT_DISTURB, isSelected: true, count: 0 },
    { label: 'Out of service', value: c.CLEANING_STATUS_OUT_OF_SERVICE, isSelected: true, count: 0 },
    { label: 'No service', value: c.CLEANING_STATUS_NO_SERVICE, isSelected: true, count: 0 },
    { label: 'Reset', value: 'reset' }
]

export const DashboardProvider = props => {
    const { currentOrganization } = useContext(AuthContext)

    const [restart, setRestart] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [showQuickGuideModal, setShowQuickGuideModal] = useState(false)
    const [occupancyFilter, setOccupancyFilter] = useState(OCCUPANCY_ALL)
    const [cleaningStatusFilter, setCleaningStatusFilter] = useState(defaultCleaningStatusFilter)
    const [priorityFilter, setPriorityFilter] = useState(false)
    const [areaGroupsFilter, setAreaGroupsFilter] = useState([{ key: 'All', name: 'All', selected: true }])
    const [areaGroups, setAreaGroups] = useState([])
    const [showUpgradeMessage, setShowUpgradeMessage] = useState(false)
    const [areaCount, setAreaCount] = useState()
    const [priorityCount, setPriorityCount] = useState()
    const [selectedDateNumber, setSelectedDateNumber] = useState(moment().tz(currentOrganization.timezone).startOf('date').valueOf())
    const [filteredAreas, setFilteredAreas] = useState([])
    const [allAreas, setAllAreas] = useState([])
    const [allAreaGroups, setAllAreaGroups] = useState([])
    const [areaModalIsOpen, setAreaModalIsOpen] = useState(false)
    const [selectedArea, setSelectedArea] = useState(null)
    const [selectedTask, setSelectedTask] = useState(null)
    const [showActionBar, setShowActionBar] = useState(false)
    const [selectedAreas, setSelectedAreas] = useState([])
    const [notAccessibleFeatures, setNotAccessibleFeatures] = useState()
    const [availableUpgrades, setAvailableUpgrades] = useState()
    const [showDailyCommentSummaryModal, setShowDailyCommentSummaryModal] = useState(false)
    const [selectUserModalIsOpen, setSelectUserModalIsOpen] = useState(false)
    const [assignModeFilter, setAssignModeFilter] = useState(ASSIGN_MODE_FILTER_ALL)
    const [lastAssignedUsers, setLastAssignedUsers] = useState()
    const [lightAreaSummaries, setLightAreaSummaries] = useState([])
    const [rules, setRules] = useState([])
    const [fullSetAreas, setFullSetAreas] = useState([])
    const [cleaningTaskName, setCleaningTaskName] = useState('')
    const [componentControlsDisplay, setComponentControlsDisplay] = useState(false)

    const updateAreasLocally = areasToUpdate => {
        const updatedAreas = allAreas.map(area => {
            const updatedArea = areasToUpdate.find(a => a.key === area.key)
            return updatedArea ? { ...area, ...updatedArea } : area
        })
        setAllAreas(updatedAreas)
    }

    const value = {
        restart,
        setRestart,
        searchValue,
        setSearchValue,
        showQuickGuideModal,
        setShowQuickGuideModal,
        occupancyFilter,
        setOccupancyFilter,
        cleaningStatusFilter,
        setCleaningStatusFilter,
        priorityFilter,
        setPriorityFilter,
        areaGroupsFilter,
        setAreaGroupsFilter,
        areaGroups,
        setAreaGroups,
        showUpgradeMessage,
        setShowUpgradeMessage,
        areaCount,
        setAreaCount,
        priorityCount,
        setPriorityCount,
        selectedDateNumber,
        setSelectedDateNumber,
        filteredAreas,
        setFilteredAreas,
        setAllAreas,
        allAreas,
        allAreaGroups,
        setAllAreaGroups,
        areaModalIsOpen,
        setAreaModalIsOpen,
        selectedArea,
        setSelectedArea,
        selectedTask,
        setSelectedTask,
        showActionBar,
        setShowActionBar,
        selectedAreas,
        setSelectedAreas,
        notAccessibleFeatures,
        setNotAccessibleFeatures,
        availableUpgrades,
        setAvailableUpgrades,
        showDailyCommentSummaryModal,
        setShowDailyCommentSummaryModal,
        selectUserModalIsOpen,
        setSelectUserModalIsOpen,
        assignModeFilter,
        setAssignModeFilter,
        lastAssignedUsers,
        setLastAssignedUsers,
        cleaningTaskName,
        setCleaningTaskName,
        componentControlsDisplay,
        setComponentControlsDisplay,
        updateAreasLocally,
        setLightAreaSummaries,
        lightAreaSummaries,
        rules,
        setRules,
        fullSetAreas,
        setFullSetAreas
    }

    return (
        <DashboardContext.Provider value={value} {...props}>
            {props.children}
        </DashboardContext.Provider>
    )
}
