import React, { useState, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ModalProgressBar } from '../../../../../_metronic/_partials/controls'
import { AuthContext } from '../../../Auth/AuthContext'

import SubscriptionPlans from './subscription-plans/SubscriptionPlans'

export function AccountSubscription(props) {
    const [loading, setloading] = useState(false)
    const [logo, setLogo] = useState('')
    const { currentOrganization } = useContext(AuthContext)

    const initialValues = {
        logo: currentOrganization.logo,
        name: currentOrganization.name
    }

    const Schema = Yup.object().shape({
        logo: Yup.string(),
        name: Yup.string().required('Name is required')
    })

    const saveAccount = (values, setStatus, setSubmitting) => {}

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveAccount(values, setStatus, setSubmitting)
        },
        onReset: (values, { resetForm }) => {
            resetForm()
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            {loading && <ModalProgressBar />}

            {/* begin::Form */}
            <div className="form">
                {/* begin::Body */}
                <div className="card-body px-0">
                    <SubscriptionPlans />
                </div>
                {/* end::Body */}
            </div>
            {/* end::Form */}
        </form>
    )
}
