import React, { useContext, useState } from 'react'
import {
    BreadCrumbs,
    ContentWrap,
    DueDateAndInitials,
    GeneralVisual,
    Hashtag,
    Header,
    InfoWrap,
    Name,
    TaskRowWrap,
    UsersListWrap
} from './style'
import * as c from '@shared/constants'
import HousekeepingBox from '../../../../housekeeping/housekeeping-box/housekeeping-box'
import { bookingsAtom, subscriptionParamsAtom, taskboardContextAtom } from '../../../state/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import IssueVisual from './IssueVisual'
import generalIcon from '../../../../img/general-task.svg'
import { cleanHashTags, getHashTags } from '@shared/helpers'
import { DueDateButton } from '../../../../components/buttons/DueDateButton'
import moment from 'moment-timezone'
import { InitialsView } from '../../../../components/initials-view'
import { AuthContext } from '../../../../modules/Auth/AuthContext'
import ActionBar from './ActionBar'
import PrioritySign from 'app/components/PrioritySign'
import { toast } from 'react-toastify'
import { toastErrorStyle, toastSuccessStyle } from '../../../../utils/styles'
import * as taskData from '@shared/task-data'
import * as areaData from '@shared/area-data'
import * as issueData from '@shared/issue-data'
import firebase, { asFirebase } from '../../../../utils/firebase'
import MinimizedVisual from './MinimizedVisual/index'
import { AreaStruct, TaskStruct, UserStruct } from '@shared/firestore-structs'
import { selectedDateNumberSelector } from 'app/tasks/state/selectors/selectedDateNumber'
import { constructAssignedTo, constructBreadcrumbs } from '@shared/task-helpers'
import UsersList from 'app/users/users-list'
import { ModalOverlay } from 'app/components/styled-components/modal'

interface TaskRowProps {
    task: TaskStruct
    onHousekeepingRowClick: (task: TaskStruct) => void
    onIssueRowClick: (issueKey: string) => void
    onGeneralRowClick: (task: TaskStruct) => void
    isMinimized: boolean
    isVisible: boolean
}

function TaskRow({ task, onHousekeepingRowClick, onIssueRowClick, onGeneralRowClick, isMinimized, isVisible }: TaskRowProps) {
    const [hover, setHover] = useState(false)
    const [actionBarFixed, setActionBarFixed] = useState(false)
    const [usersListModalOpen, setUsersListModalOpen] = useState(false)

    const authContext = useContext(AuthContext)
    if (!authContext.currentUser) return null
    const { currentUser } = authContext

    const [{ hasSubscriptionAccess }, setSubscriptionParams] = useRecoilState(subscriptionParamsAtom)
    const selectedDateNumber = useRecoilValue(selectedDateNumberSelector)
    const taskboardContext = useRecoilValue(taskboardContextAtom)

    const { key, area, type, priority, startDate, name, status, assignedTo } = task
    const taskName = type === c.TASK_TYPE_ISSUE ? task.issue.name ?? '' : name ?? ''
    const selectedDateDueDateBtn = status === c.TASK_COMPLETE ? selectedDateNumber : moment().valueOf()
    const minimized = isMinimized && !hover
    const completed = status === c.TASK_COMPLETE

    function onRowClick() {
        if (task.status === c.TASK_COMPLETE) return

        switch (task.type) {
            case c.TASK_TYPE_HOUSEKEEPING:
                onHousekeepingRowClick(task)
                break

            case c.TASK_TYPE_ISSUE:
                onIssueRowClick(task.issue.key)
                break

            case c.TASK_TYPE_GENERAL:
                onGeneralRowClick(task)
                break

            default:
                break
        }
    }

    async function onDeleteClick() {
        try {
            await taskData.deleteTask(asFirebase(firebase), key, currentUser)
            task.type === c.TASK_TYPE_ISSUE &&
                (await issueData.unassignIssue(asFirebase(firebase), {
                    issueKey: task.issue.key,
                    taskKey: task.key,
                    currentUser
                }))

            toast.success('Task deleted', toastSuccessStyle)
        } catch (error: unknown) {
            console.error(error)
            if (error instanceof Error) {
                toast.error(`Error deleting task: ${error.message}`, toastErrorStyle)
            }
        }
    }

    async function onDueDateChange(date: Date) {
        const dueDateNumber = moment(date).startOf('day').valueOf()
        const taskObject = { startDate: dueDateNumber }

        try {
            task.type === c.TASK_TYPE_ISSUE
                ? await issueData.setDueDateToIssue(asFirebase(firebase), {
                      issueKey: task.issue.key,
                      dueDate: dueDateNumber,
                      assignedTo: task.issue.assignedContacts ?? null,
                      area: task.area as AreaStruct,
                      currentUser,
                      taskKey: task.key
                  })
                : await taskData.setTaskUpdate(asFirebase(firebase), currentUser, task.key, taskObject)

            const name = task.type === c.TASK_TYPE_ISSUE ? task.issue.name : task.name
            const dateString = date.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit' })
            const message = `"${name}" set due ${dateString}`

            toast.success(message, toastSuccessStyle)
        } catch (error: unknown) {
            console.error(error)
            if (error instanceof Error) {
                toast.error(`Error updating "${name}" due date: ${error.message}`, toastErrorStyle)
            }
        }
    }

    async function onPriorityChange() {
        try {
            const taskUpdate = { priority: !priority }
            const name = task.type === c.TASK_TYPE_ISSUE ? task.issue.name : task.name

            await taskData.setTaskPriority(asFirebase(firebase), currentUser, task.key, taskUpdate)

            if (task.type === c.TASK_TYPE_ISSUE) {
                await issueData.setPriorityToIssue(asFirebase(firebase), {
                    issueKey: task.issue.key,
                    priority: taskUpdate.priority,
                    currentUser
                })
            }

            if (task.area && type === c.TASK_TYPE_HOUSEKEEPING) {
                const changeObj = {
                    field: c.ACTIVITY_TYPE_CLEANING_PRIORITY,
                    before: priority,
                    after: !priority
                }

                await areaData.logActivity(
                    asFirebase(firebase),
                    currentUser,
                    task.area.key as string,
                    c.ACTIVITY_TYPE_CLEANING_PRIORITY,
                    selectedDateNumber,
                    changeObj
                )
            }

            toast.success(`"${name}" priority updated`, toastSuccessStyle)
        } catch (error: unknown) {
            console.error(error)
            if (error instanceof Error) {
                toast.error(`Error updating "${name}" priority: ${error.message}`, toastErrorStyle)
            }
        }
    }

    async function onCompleteClick() {
        try {
            const name = task.type === c.TASK_TYPE_ISSUE ? task.issue.name : task.name

            type === c.TASK_TYPE_ISSUE
                ? await issueData.completeIssue(asFirebase(firebase), {
                      issueKey: task.issue.key,
                      currentUser,
                      taskKey: task.key
                  })
                : await taskData.completeTask(asFirebase(firebase), currentUser, task.key)

            toast.success(`"${name}" task completed`, toastSuccessStyle)
        } catch (error: unknown) {
            console.error(error)
            if (error instanceof Error) {
                toast.error(`Error completing task: ${error.message}`, toastErrorStyle)
            }
        }
    }

    async function onUncompleteClick() {
        try {
            const status = task.assignedTo && task.assignedTo.length > 0 ? c.TASK_ASSIGNED : c.TASK_OPEN
            const name = task.type === c.TASK_TYPE_ISSUE ? task.issue.name : task.name

            if (type === c.TASK_TYPE_ISSUE) {
                await issueData.uncompleteIssue(asFirebase(firebase), {
                    issueKey: task.issue.key,
                    currentUser,
                    assignedContacts: task.assignedTo,
                    taskKey: task.key
                })
            } else {
                await taskData.setTaskUpdate(asFirebase(firebase), currentUser, task.key, { status })
            }

            toast.success(`"${name}" task uncompleted`, toastSuccessStyle)
        } catch (error: unknown) {
            console.error(error)
            if (error instanceof Error) {
                toast.error(`Error uncompleting task: ${error.message}`, toastErrorStyle)
            }
        }
    }

    async function handleAssigning(users: UserStruct[]) {
        /*TODO refactor all this spaghetti */
        setUsersListModalOpen(false)
        try {
            const taskObject = { assignedTo: users.length > 0 ? users.map(user => constructAssignedTo(user)) : [] }
            const name = task.type === c.TASK_TYPE_ISSUE ? task.issue.name : task.name

            task.type === c.TASK_TYPE_HOUSEKEEPING && taskObject.assignedTo.length === 0
                ? await taskData.unassignTask(asFirebase(firebase), currentUser, task.key)
                : await taskData.assignTask(asFirebase(firebase), currentUser, task.key, taskObject)

            if (task.type === c.TASK_TYPE_ISSUE) {
                if (taskObject.assignedTo.length > 0) {
                    await issueData.assignIssue(asFirebase(firebase), {
                        issueKey: task.issue.key,
                        assignedTo: taskObject.assignedTo,
                        area: task.area as AreaStruct,
                        dueDate: task.startDate,
                        taskKey: task.key,
                        currentUser
                    })
                } else {
                    await issueData.unassignIssue(asFirebase(firebase), {
                        issueKey: task.issue.key,
                        taskKey: task.key,
                        currentUser
                    })
                }
            }

            const message =
                taskObject.assignedTo.length > 0
                    ? `"${name}" assigned to ${taskObject.assignedTo.map(user => user.name).join(', ')}`
                    : `"${name}" unassigned`

            toast.success(message, toastSuccessStyle)
        } catch (error: unknown) {
            console.error(error)
            if (error instanceof Error) {
                toast.error(`Error assigning "${name}": ${error.message}`, toastErrorStyle)
            }
        }
    }

    const bookings = useRecoilValue(bookingsAtom)

    const breadcrumbItems = constructBreadcrumbs(task, taskboardContext, bookings)

    return (
        <>
            {usersListModalOpen && (
                <ModalOverlay basic>
                    <UsersListWrap>
                        <UsersList
                            task={task}
                            closeModal={() => setUsersListModalOpen(false)}
                            currentUser={currentUser}
                            onAssignPress={handleAssigning}
                            onUnassignPress={handleAssigning}
                            areaGroups={currentUser.areaGroups}
                        />
                    </UsersListWrap>
                </ModalOverlay>
            )}
            <TaskRowWrap
                completed={completed}
                isVisible={isVisible}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => !actionBarFixed && setHover(false)}>
                <Header completed={completed} minimized={minimized} onClick={onRowClick}>
                    {minimized && priority && <PrioritySign minimized={minimized} />}

                    <BreadCrumbs fontSize={'12px'}>
                        {breadcrumbItems.map((item, index) => (
                            <span key={index}>{item}</span>
                        ))}
                    </BreadCrumbs>
                </Header>

                <ContentWrap completed={completed} onClick={onRowClick}>
                    {!minimized && (
                        <div>
                            {task.type === c.TASK_TYPE_HOUSEKEEPING && (
                                <HousekeepingBox
                                    context={c.CONTEXT_WEB_APP_TASKS}
                                    priority={priority}
                                    onPressDisabled
                                    key={area.key}
                                    width={60}
                                    height={60}
                                    selectedDateNumber={startDate}
                                    hasSubscriptionAccess={hasSubscriptionAccess}
                                    area={task.area}
                                />
                            )}

                            {type === c.TASK_TYPE_ISSUE && task.issue && (
                                <IssueVisual
                                    minimized={minimized}
                                    priority={priority}
                                    lastItemKey={task.issue.lastItemKey}
                                    lastThumbUrl={task.issue.lastThumbUrl}
                                />
                            )}

                            {type === c.TASK_TYPE_GENERAL && (
                                <GeneralVisual>
                                    {priority && <PrioritySign minimized={minimized} />}
                                    <img src={generalIcon as string} />
                                </GeneralVisual>
                            )}
                        </div>
                    )}

                    <InfoWrap minimized={minimized}>
                        <Name>
                            {cleanHashTags(taskName)}
                            {getHashTags(taskName).map((hashTag, index) => (
                                <Hashtag key={hashTag + index}>{hashTag}</Hashtag>
                            ))}{' '}
                        </Name>

                        <DueDateAndInitials minimized={minimized}>
                            {!minimized && (
                                <DueDateButton
                                    readOnly
                                    dueDate={startDate!}
                                    selectedDate={selectedDateDueDateBtn}
                                    onClick={undefined}
                                    inverse={undefined}
                                    fontSize={undefined}
                                />
                            )}

                            <InitialsView
                                maxCountToDisplay={1}
                                circleDimension={minimized ? 20 : 22}
                                fontSize={8}
                                justifyContent={'flex-end'}
                                currentUser={currentUser}
                                assignedTo={assignedTo}
                                margin={minimized ? 4 : 6}
                                fontWeight={'800'}
                            />
                            {minimized && type === c.TASK_TYPE_ISSUE && (
                                <MinimizedVisual
                                    minimized={minimized}
                                    type={type}
                                    area={area}
                                    priority={priority}
                                    lastItemKey={task.issue && task.issue.lastItemKey}
                                    lastThumbUrl={task.issue && task.issue.lastThumbUrl}
                                />
                            )}
                        </DueDateAndInitials>
                    </InfoWrap>
                </ContentWrap>

                {hover && (
                    <ActionBar
                        startDate={startDate!}
                        status={status}
                        priority={priority}
                        onDeleteClick={onDeleteClick}
                        setActionBarFixed={setActionBarFixed}
                        onDueDateChange={onDueDateChange}
                        onPriorityChange={onPriorityChange}
                        onCompleteClick={onCompleteClick}
                        onUncompleteClick={onUncompleteClick}
                        onAssignClick={() => {
                            if (!hasSubscriptionAccess) {
                                setSubscriptionParams(prev => ({ ...prev, upgradeMessageIsOpen: true }))
                            } else {
                                setUsersListModalOpen(true)
                            }
                        }}
                    />
                )}
            </TaskRowWrap>
        </>
    )
}

export default TaskRow
