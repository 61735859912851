import React, { useEffect } from 'react'
import AsyncStorage from '@react-native-community/async-storage'
import { useRecoilState } from 'recoil'
import { filtersAtom, paginationAtom } from '../state/atoms'
import Filters from './Filters'
import HashtagBar from './HashtagBar'
import Search from './Search'
import CheckBoxes from './SelectCheckBoxes'
import { useIssues } from '../state/hooks/useIssues'

export default function SearchAndFilters() {
    const [filters, setFilters] = useRecoilState(filtersAtom)
    const [pagination, setPagination] = useRecoilState(paginationAtom)
    const { hashTags } = useIssues()

    useEffect(() => {
        AsyncStorage.getItem('issues-priority-filter', (err, result) => {
            if (result && !err) setFilters({ ...filters, priority: JSON.parse(result) })
        })
        AsyncStorage.getItem('issues-status-filter', (err, result) => {
            if (result && !err) setFilters({ ...filters, status: JSON.parse(result) })
        })
        // AsyncStorage.getItem('issues-search-filter', (err, result) => {
        //     if (result && !err) setFilters({ ...filters, search: JSON.parse(result) })
        // })
    }, [])

    const search = (value: string) => {
        if (value !== '' || value !== undefined) {
            AsyncStorage.setItem('issues-search-filter', value)
            setPagination({ ...pagination, page: 1 })
            setFilters({ ...filters, search: value })
        }
    }
    return (
        <>
            <div className="row justify-content-between row-paddingless mb-8">
                <Search value={filters.search} onChange={search} />
                <Filters />
            </div>
            {hashTags && <HashtagBar hashTags={hashTags} onClick={search} />}
            <CheckBoxes />
        </>
    )
}
