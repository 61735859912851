import firebase from './firebase'

export const getRecaptchaVerifier = () => {
    if (window.recaptchaVerifier) {
        return window.recaptchaVerifier
    }

    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: function () {
            console.log('captcha approved')
        }
    })
    window.recaptchaVerifier = recaptchaVerifier

    return recaptchaVerifier
}
