import styled from 'styled-components'
import * as colors from '@shared/colors'
import { getBrand } from '@shared/brand'
import { MOBILE_BREAKPOINT, SMALL_DESKTOP_SCREEN_HEIGHT } from '../../../utils/constants'

const black = colors.black_sweeply
const brandColor = getBrand().navBarColor

export const VerificationWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
        width: 374px;

        p {
            font-weight: 450;
            font-size: 1.077rem;
            line-height: 24px;
            color: ${black};

            a {
                color: ${brandColor};

                &:hover {
                    color: ${brandColor};
                }
            }
        }

        #button-wrap {
            display: flex;
            justify-content: flex-end;
        }

        #text {
            margin-bottom: 40px;
        }

        #link {
            text-align: center;
        }
    }

    @media (max-height: ${SMALL_DESKTOP_SCREEN_HEIGHT}px) {
        > div {
            #text {
                margin-bottom: 20px;
            }

            button {
                height: 56px !important;
                margin-top: 20px !important;
                margin-bottom: 16px !important;
            }
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        > div {
            width: 295px;

            #text {
                margin-bottom: 20px;
            }

            #link {
                display: flex;
                flex-direction: column;
            }

            input {
                &::placeholder {
                    font-size: 1.167rem;
                }
            }

            button {
                margin-top: 45px;
                margin-bottom: 45px;
                width: 135px;
                height: 46px;
                font-size: 1.333rem;
            }
        }
    }
`
