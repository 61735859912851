import React from 'react'
import { TOOLTIP_DELAY } from '../tooltip-helpers'
import Tippy from '@tippyjs/react'
import './style.scss'

export default function BlackTooltip({ children, placement, content, ...rest }) {
    return (
        <Tippy delay={TOOLTIP_DELAY} arrow={false} placement={placement || 'top'} content={content} {...rest}>
            {children}
        </Tippy>
    )
}
