import React from 'react'
import DatePickerInput from 'app/components/DatePicker'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { dataLoadingAtom, currentDateNumberAtom, subscriptionParamsAtom } from '../state/atoms'
import { Moment } from 'moment-timezone'
import { SubscriptionParams } from 'app/types'

function DatePicker() {
    const [currentDateNumber, setCurrentDateNumber] = useRecoilState(currentDateNumberAtom)

    const setDataLoading = useSetRecoilState(dataLoadingAtom)
    const setSubscriptionParams = useSetRecoilState(subscriptionParamsAtom)

    function onDateChange(date: number | Moment) {
        const selectedDateNumber = typeof date === 'number' ? date : date.valueOf()
        setDataLoading(true)
        setCurrentDateNumber(selectedDateNumber)
    }

    function onNotAvailableDateChange(
        notAccessibleFeature: SubscriptionParams['notAccessibleFeature'],
        availableUpgrades: SubscriptionParams['availableUpgrades']
    ) {
        setSubscriptionParams({
            hasSubscriptionAccess: false,
            notAccessibleFeature: notAccessibleFeature,
            availableUpgrades: availableUpgrades,
            upgradeMessageIsOpen: true
        })
    }

    return (
        <DatePickerInput
            featureId={'browse-history'}
            selectedDateNumber={currentDateNumber}
            onDateChange={onDateChange}
            onNotAvailableDateChange={onNotAvailableDateChange}
        />
    )
}

export default DatePicker
