export class InputsBuilder {
    inputs = {}

    constructor() {}

    static inputs() {
        return new InputsBuilder()
    }

    input(name, required = true) {
        this.inputs[name] = {
            required
        }
        return this
    }

    build() {
        return this.inputs
    }
}
export const inputs = () => new InputsBuilder()
