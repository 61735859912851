import React from 'react'
import { ReportsProvider } from '../../ReportsContext.js'
import { OptionalCard } from './OptionalCard.js'

export default function OptionalPage() {
    return (
        <div>
            <ReportsProvider>
                <OptionalCard />
            </ReportsProvider>
        </div>
    )
}
