import React, { useState, useEffect } from 'react'
import { View, Image } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import * as c from '@shared/constants'
import { SMALL_DESKTOP_SCREEN_HEIGHT, MEDIUM_DESKTOP_SCREEN_HEIGHT } from '../utils/constants'
import * as chelpers from '@shared/cleaning-helpers'
import { CloseModalButton } from '../components/buttons/close-modal-button'

const leftStatusColumn = [
    { cleaningStatus: c.CLEANING_STATUS_WAITING_FOR_CHECKOUT, text: 'Waiting for check-out' },
    {
        cleaningStatus: c.CLEANING_STATUS_DIRTY,
        occupancy: c.OCCUPANCY_STAYOVER,
        text: '50% or 80% unclean'
    },
    { cleaningStatus: c.CLEANING_STATUS_INSPECTION, text: 'Inspection' },
    { cleaningStatus: c.CLEANING_STATUS_DO_NOT_DISTURB, text: 'Do not disturb' },
    { cleaningStatus: c.CLEANING_STATUS_PREPARING, text: 'Preparing' }
]
const rightStatusColumn = [
    {
        cleaningStatus: c.CLEANING_STATUS_DIRTY,
        occupancy: c.OCCUPANCY_CHECKOUT,
        text: '100% unclean'
    },
    { cleaningStatus: c.CLEANING_STATUS_CLEAN, text: 'Clean' },
    { cleaningStatus: c.CLEANING_STATUS_OUT_OF_SERVICE, text: 'Out of service' },
    { cleaningStatus: c.CLEANING_STATUS_NO_SERVICE, text: 'No service' }
]

export const QuickGuide = ({ close }) => {
    const [spaceBetween, setSpaceBetween] = useState(45)
    const [imgScale, setImgScale] = useState(0.7)

    const CONTENT_MAX_HEIGHT = '80vh'
    const button_dim = 24
    const bullet_left_padding = 16
    const DEBUG_BORDER = 0

    const { innerHeight } = window

    useEffect(() => {
        switch (true) {
            case innerHeight <= SMALL_DESKTOP_SCREEN_HEIGHT:
                console.log('innerHeight <= SMALL_DESKTOP_SCREEN_HEIGHT')
                setSpaceBetween(15)
                setImgScale(0.5)
                break

            case innerHeight <= MEDIUM_DESKTOP_SCREEN_HEIGHT:
                console.log('innerHeight <= MEDIUM_DESKTOP_SCREEN_HEIGHT')
                setSpaceBetween(25)
                setImgScale(0.6)
                break

            default:
                console.log('default')
                setSpaceBetween(45)
                setImgScale(0.7)
                break
        }
    }, [innerHeight])

    return (
        <View
            style={{
                height: 'auto',
                maxHeight: CONTENT_MAX_HEIGHT,
                paddingHorizontal: 40,
                backgroundColor: 'white',
                borderWidth: 0,
                marginBottom: spaceBetween
            }}
            showsVerticalScrollIndicator={false}
            contentContainerstyle={{
                flex: 1,
                borderColor: 'blue'
            }}>
            <CloseModalButton close={close} />

            <View style={{ marginTop: spaceBetween, paddingLeft: 6, borderWidth: DEBUG_BORDER }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: button_dim
                    }}>
                    <span style={{ fontSize: 22, fontWeight: '500', marginBottom: 0, color: iOSColors.gray }}>Housekeeping</span>
                </View>
                <span style={{ fontSize: 25, fontWeight: '700' }}>Quick Guide</span>
            </View>
            <View
                style={{
                    marginTop: spaceBetween,
                    flexDirection: 'row',
                    borderWidth: DEBUG_BORDER
                }}>
                <View style={{ width: '50%', borderWidth: 0, justifyContent: 'center' }}>
                    <Image
                        style={{
                            width: 307 * imgScale,
                            height: 611 * imgScale
                        }}
                        source={require('../img/quick-guide/dashboard-quick-guide-ios.png')}
                    />
                </View>
                <View
                    style={{
                        borderWidth: 0,
                        width: '50%',
                        paddingLeft: bullet_left_padding,
                        alignItems: 'flex-start',
                        justifyContent: 'center'
                    }}>
                    <View>
                        {renderStatusBullet(require('../img/quick-guide/priority-quick-guide.png'), 'Priority')}
                        {renderStatusBullet(require('../img/quick-guide/stayover-50-quick-guide.png'), '50 % unclean')}
                        {renderStatusBullet(require('../img/quick-guide/stayover-80-quick-guide.png'), '80% unclean')}
                        {renderStatusBullet(require('../img/quick-guide/checkout-quick-guide.png'), '100% unclean')}
                        {renderStatusBullet(require('../img/quick-guide/inspection-quick-guide.png'), 'Inspection')}
                        {renderStatusBullet(require('../img/quick-guide/guests-quick-guide.png'), 'Guests in room')}
                        {renderStatusBullet(require('../img/quick-guide/check-in-quickguide.png'), 'Check-in')}
                        {renderStatusBullet(require('../img/quick-guide/dnd-quick-guide.png'), 'Do not disturb')}
                        {renderStatusBullet(require('../img/quick-guide/no-service-quick-guide.png'), 'Out of service')}
                        {renderStatusBullet(require('../img/quick-guide/no-service-leaf-quick-guide.png'), 'No service')}
                        {renderStatusBullet(require('../img/quick-guide/daily-comments-quick-guide.png'), 'Daily comments')}
                        {renderStatusBullet(require('../img/quick-guide/unit-notes-quick-guide.png'), 'Unit notes', true)}
                    </View>
                </View>
            </View>
            <View
                style={{
                    borderWidth: DEBUG_BORDER,
                    flexDirection: 'row',
                    marginTop: spaceBetween,
                    paddingBottom: 8
                }}>
                {renderColorColumn(leftStatusColumn, bullet_left_padding, true)}
                {renderColorColumn(rightStatusColumn, bullet_left_padding, false)}
            </View>
        </View>
    )
}

const renderStatusBullet = (img, text, isLast = false) => {
    const bullet_view = { borderWidth: 0, flexDirection: 'row', alignItems: 'center', marginBottom: isLast ? 0 : 16 }
    const bullet_font_style = { marginLeft: 9, fontSize: 16, fontWeight: '400' }
    const icon_dim = 26
    return (
        <View style={bullet_view}>
            <Image
                style={{
                    width: icon_dim,
                    height: icon_dim
                }}
                source={img}
            />
            <span style={bullet_font_style}>{text}</span>
        </View>
    )
}
const renderColorColumn = (items, bullet_left_padding, isLeft) => {
    const DEBUG_BORDER = 0

    return (
        <View
            style={{
                paddingLeft: isLeft ? 6 : bullet_left_padding,
                flexDirection: 'column',
                width: '50%',
                borderWidth: DEBUG_BORDER,
                borderColor: 'red'
            }}>
            {items.map((item, index) => renderColorBullet(item, index === items.length - 1))}
        </View>
    )
}

const renderColorBullet = (item, isBottomRow = false) => {
    const DEBUG_BORDER = 0
    const item_view = {
        borderWidth: DEBUG_BORDER,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: isBottomRow ? 0 : 12
    }

    const bullet_font_style = { marginLeft: 9, fontSize: 15, fontWeight: '400' }
    const area_dim = 26
    const circle_dim = 20

    return (
        <View style={item_view}>
            <View style={{ width: area_dim, height: area_dim, alignItems: 'center', justifyContent: 'center', borderWidth: 0 }}>
                <View
                    style={{
                        borderRadius: circle_dim / 2,
                        width: circle_dim,
                        height: circle_dim,
                        backgroundColor: chelpers.pickCleaningStatusColor(item.cleaningStatus, item.occupancy)
                    }}
                />
            </View>
            <span style={bullet_font_style}>{item.text}</span>
        </View>
    )
}
