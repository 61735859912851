import firebase from './utils/firebase'
import { getClientFeatureValues } from './features'
import { GuestLoginWithLastNameAndRoomNamePayload, LoginTypesEnum } from '@shared/guest'
import moment from 'moment-timezone'
import axios from 'axios'

const isLocalDev = location.hostname === 'localhost'

export const httpsCallable = (name: string, bypassHostingFrontend = false) => <T>(payload: T) => {
    const invokeCallable =
        isLocalDev || bypassHostingFrontend
            ? firebase.app().functions().httpsCallable(name)
            : firebase.app().functions(`${window.location.protocol}//${window.location.host}/`).httpsCallable(`api/${name}`)
    const features = getClientFeatureValues()
    return invokeCallable({ ...payload, features })
}

export const guestLoginWithLastNameAndRoomNameRequest = async (values: GuestLoginWithLastNameAndRoomNamePayload) => {
    const payload = {
        ...values,
        type: LoginTypesEnum.withLastNameAndRoomName,
        checkInDate: moment(values.checkInDate).startOf('day').valueOf()
    }

    return axios.post(`/api/guests/login`, payload, { withCredentials: true })
}
