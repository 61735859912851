import React, { useEffect, useState } from 'react'

import { iOSColors } from '@shared/react-native-typography'

import PopupMenu from './popup-menu'
import * as helpers from '@shared/helpers'

import { OrderedButton } from '../components/ordered-button'

import { ButtonsBarContainer, MoreButtonContainer, MoreButtonImage } from './style'

interface ButtonsBarProps {
    type: 'hashtag-bar' | 'housekeeping-dashboard' | 'navbar' | string
    respWidth: number
    countedItems: {
        name: string
        count: number
    }[]
    onClick: (name: string) => void
}

const ButtonsBar: React.FC<ButtonsBarProps> = props => {
    const { type, countedItems, onClick } = props
    const [respWidth, setRespWidth] = useState(props.respWidth)
    useEffect(() => {
        setRespWidth(props.respWidth)
    }, [props.respWidth])

    const isHashtag = type === 'hashtag-bar'
    const isHousekeepingDashboard = type === 'housekeeping-dashboard'
    const numberOfButtonsToShow = respWidth / 170
    const topButtons = countedItems.slice(0, numberOfButtonsToShow)
    const restOfButtons = countedItems.slice(numberOfButtonsToShow, countedItems.length - 1)
    const restOfButtonsAction: (() => void)[] = []

    restOfButtons.forEach(tag => {
        restOfButtonsAction.push(() => onClick(tag.name))
    })
    let backgroundColor: string | undefined
    let borderWidth: number | undefined

    if (type === 'hashtag-bar') {
        backgroundColor = iOSColors.white
        borderWidth = 1
    } else if (type === 'housekeeping-dashboard') {
        backgroundColor = '#F2F2F2'
        borderWidth = 0
    }
    const moreButton = (
        <MoreButtonContainer backgroundColor={backgroundColor} borderWidth={borderWidth}>
            <MoreButtonImage source={require('@shared/img/more-black.svg')} />
        </MoreButtonContainer>
    )
    return (
        <ButtonsBarContainer>
            {isHashtag &&
                topButtons.map(prop => (
                    <OrderedButton
                        key={prop.name + prop.count}
                        text={helpers.shortenLongName(prop.name, 20)}
                        count={prop.count}
                        color={iOSColors.gray}
                        opacity={0.8}
                        active={true}
                        fontSize={14}
                        countMarginTop={2}
                        fontWeight={'600'}
                        height={22}
                        marginRight={8}
                        paddingHorizontal={10}
                        paddingVertical={12}
                        borderColor={iOSColors.midGray}
                        onPress={() => onClick(prop.name)}
                    />
                ))}
            {isHousekeepingDashboard &&
                topButtons.map(prop => (
                    <OrderedButton
                        key={prop.name + prop.count}
                        text={helpers.shortenLongName(prop.name, 20)}
                        count={prop.count}
                        color={iOSColors.gray}
                        active={true}
                        fontSize={13}
                        fontWeight={'500'}
                        height={30}
                        marginRight={8}
                        paddingHorizontal={10}
                        borderColor={iOSColors.midGray}
                        showBorder={false}
                        countMarginTop={2}
                        paddingVertical={0}
                        // borderRadius={20}
                        backgroundColor={'#F2F2F2'}
                        onPress={() => onClick(prop.name)}
                    />
                ))}
            <PopupMenu type={type} customButton={moreButton} options={restOfButtons} actions={restOfButtonsAction} />
        </ButtonsBarContainer>
    )
}

export default ButtonsBar
