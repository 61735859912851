import React from 'react'
import { Wrap, Header, Date, TitleWrap, NotesWrap, NoteWrap, Group, Name, Description, NoteText } from './style'
import moment from 'moment-timezone'
import { SummaryNote, SummaryTab, SummaryTabType } from 'app/modules/HousekeepingDashboard/types'
import * as c from '@shared/constants'
import { markdownToHtml } from '@shared/markdown-helper'
import NoItemsMessage from 'app/components/NoItemsMessage'
import noItemsIcon from '@shared/img/empty-screens/sun.png'

interface Props {
    activeTab: SummaryTab
    notes: SummaryNote[]
}

interface NoteProps {
    note: SummaryNote
    index: number
    type: SummaryTabType
}

function NotesList({ activeTab, notes }: Props) {
    return (
        <Wrap>
            <Header>
                <Date>{moment().format(c.DATE_FORMAT_WITH_WEEK_DAY)}</Date>

                <TitleWrap type={activeTab.type}>
                    <i className={activeTab.icon + `${activeTab.type === c.ACTIVITY_TYPE_DAILY_COMMENT ? ' icon-2x' : ' icon-lg'}`} />
                    <span>{activeTab.title}</span>
                </TitleWrap>
            </Header>

            {notes.length > 0 ? (
                <NotesWrap>
                    {notes.map((note, index) => {
                        return <Note key={note.name + index} note={note} index={index} type={activeTab.type} />
                    })}
                </NotesWrap>
            ) : (
                <NoItemsMessage icon={noItemsIcon} message={`No ${activeTab.title.toLowerCase()} for today`} />
            )}
        </Wrap>
    )
}

function Note({ note, index, type }: NoteProps) {
    return (
        <NoteWrap key={note.name + index} even={index % 2 === 0}>
            <Group>{note.group}</Group>
            <Name>
                {note.name} {note.description && <Description>{note.description}</Description>}
            </Name>

            <NoteText>
                {type === c.ACTIVITY_TYPE_AREA_NOTE ? (
                    <span dangerouslySetInnerHTML={{ __html: markdownToHtml(note.note) }} />
                ) : (
                    <span>{note.note}</span>
                )}
            </NoteText>
        </NoteWrap>
    )
}

export default NotesList
