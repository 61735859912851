import firebase from '../../utils/firebase'
import { getMiniUserObject } from '@shared/dataObjects'
import { logActivity } from '@shared/area-data'
import { assignTask, createTask, setTaskUpdate } from '@shared/task-data'
import { TASK_TYPE_HOUSEKEEPING } from '@shared/txt-constants'

export const assignUsers = async (usersToAssign, task, currentUser, selectedDateNumber) => {
    if (task.key) {
        const taskObject = {
            assignedTo: usersToAssign.map(u => getMiniUserObject(u))
        }
        await assignTask(firebase, currentUser, task.key, taskObject)
    } else {
        await createTask(firebase, task.area, currentUser, TASK_TYPE_HOUSEKEEPING, {
            assignedTo: usersToAssign,
            name: task.area.ruleName || 'Clean & prepare',
            startDate: selectedDateNumber
        })
    }

    const changeObj = {
        before: 'assigned',
        after: createNamesForActivity(usersToAssign)
    }

    logActivity(firebase, currentUser, task.area.key, 'assignment', selectedDateNumber, changeObj)
}

const createNamesForActivity = usersToAssign => {
    let result = ''
    usersToAssign.forEach((user, index) => {
        result += user.name
        if (index !== usersToAssign.length - 1) {
            result += ', '
        }
    })
    return result
}
