import styled from 'styled-components'
import * as colors from '@shared/colors'

const black = colors.light_black
const blue = colors.medium_blue
const red = colors.red_sweeply
const white = colors.white

export const PhoneInputWrap = styled.div`
    width: 100%;

    label {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: ${black};
    }

    .input-wrap {
        display: flex;
        width: 100%;
    }
`

export const countrySelectorStyles = ({ isError }) => ({
    control: styles => ({
        ...styles,
        backgroundColor: white,
        border: `2px solid ${isError ? red : blue};`,
        width: '60.34px',
        height: '56px',
        color: isError ? red : black,
        fontFamily: 'Circular Std',
        fontWeight: 450,
        fontSize: 16,
        borderRadius: '8px 0 0 8px',
        borderRightColor: 'transparent',
        boxShadow: 'none',
        cursor: 'pointer',
        paddingTop: 4.4,
        ':hover': {
            borderColor: isError ? red : blue,
            borderRightColor: 'transparent'
        }
    }),

    menu: styles => ({
        ...styles,
        width: '300px'
    }),

    option: styles => ({
        ...styles,
        height: '50px'
    }),

    indicatorsContainer: () => ({
        display: 'none'
    }),

    placeholder: styles => ({
        ...styles,
        fontSize: 12
    })
})
