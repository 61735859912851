import React from 'react'
import { ReportsProvider } from '../../ReportsContext.js'
import { ReportsCard } from './ReportsCard.js'

export default function ReportsPage() {
    return (
        <div>
            <ReportsProvider>
                <ReportsCard />
            </ReportsProvider>
        </div>
    )
}
