import React, { useMemo } from 'react'
import ButtonsBar from '../buttons-bar'
import { HashTag } from '../types'

interface IHashtagBar {
    hashTags: HashTag[]
    onClick: (value: string) => void
}

export default function HashtagBar({ hashTags, onClick }: IHashtagBar) {
    const memoizedButtonsBar = useMemo(
        () => <ButtonsBar type={'hashtag-bar'} countedItems={hashTags} onClick={onClick} respWidth={window.innerWidth * 0.8} />,
        [hashTags, onClick]
    )
    return memoizedButtonsBar
}
