import React from 'react'
import { FieldFeedbackLabel } from './FieldFeedbackLabel'
import { PhoneCountrySelector } from '../../../../app/components/country-selector'

export const getFieldCSSClasses = (touched, errors) => {
    const classes = ['form-control form-control-iconless']
    if (touched && errors) {
        classes.push('is-invalid')
    }

    return classes.join(' ')
}

export function PhoneInput({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = true,
    customFeedbackLabel,
    selectedCountry,
    onCountryChange,
    onlyError = true,
    type = 'text',
    ...props
}) {
    return (
        <>
            {label && (
                <label className="font-weight-bold text-dark-65">
                    {label}:{props.required && <span className="text-danger">*</span>}
                </label>
            )}
            <div className="input-group">
                <div className="input-group-prepend">
                    <PhoneCountrySelector selectedCountry={selectedCountry} onCountryChange={onCountryChange} disabled={props.disabled} />
                </div>
                <input type="text" className={getFieldCSSClasses(touched[field.name], errors[field.name])} {...field} {...props} />
                {withFeedbackLabel && (
                    <FieldFeedbackLabel
                        error={errors[field.name]}
                        touched={touched[field.name]}
                        label={label}
                        type={type}
                        customFeedbackLabel={customFeedbackLabel}
                        onlyError={onlyError}
                    />
                )}
            </div>
        </>
    )
}
