import * as React from 'react'
import Spinner from '../../Spinner'
import { LoaderWrapper } from './style'

interface Props {
    size?: 'small' | 'large'
    className?: string
    screenSized?: boolean
}

export default function Loader({ size = 'large', className = '', screenSized = false }: Props) {
    return (
        <LoaderWrapper className={className} screenSized={screenSized}>
            <Spinner size={size} />
        </LoaderWrapper>
    )
}
