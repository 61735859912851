import styled from 'styled-components'
import { yello_spectaflow } from '@shared/colors'

export const InitialsAndPriorityWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    column-gap: 10px;
`

export const PriorityAndSameAsLastBtnWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`

export const RedButton = styled.button<{ disabled?: boolean }>`
    color: red;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    font-size: 1.1rem;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 2px solid ${yello_spectaflow} !important;
    font-weight: 600;
    ${({ disabled }) => disabled && 'opacity: 0.5;'};
`
export const ExtraModelContentWrap = styled.div`
    padding-bottom: 1.75rem;
`
