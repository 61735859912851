import React, { useEffect, useState } from 'react'
import { HoverableImageButton } from 'app/components/buttons/hoverable-image-button'
import * as c from '@shared/constants'
import * as uc from 'app/utils/constants'
import { AssignFilterMode } from 'app/modules/HousekeepingDashboard/types'
import unassignedIcon from '@shared/img/assigned-to-gray-wbkgr.svg'
import assignedIcon from '@shared/img/assigned-to-yellow-wbkgr.svg'
import allIcon from 'app/img/assigned-to-all-wbkgr.svg'

interface Props {
    onClick: () => void
    filterMode: AssignFilterMode
}

function FilterButton({ onClick, filterMode }: Props) {
    const [text, setText] = useState('Show all')
    const [iconSource, setIconSource] = useState(allIcon as string)

    useEffect(() => {
        switch (filterMode) {
            case c.ASSIGN_MODE_FILTER_UN_ASSIGNED:
                setText('Unassigned')
                setIconSource(unassignedIcon as string)
                break
            case c.ASSIGN_MODE_FILTER_ASSIGNED:
                setText('Assigned')
                setIconSource(assignedIcon as string)
                break
            case c.ASSIGN_MODE_FILTER_ALL:
                setText('Show all')
                setIconSource(allIcon as string)
                break
        }
    }, [filterMode])

    return (
        <HoverableImageButton
            justifyContent={'center'}
            viewWidth={'100%'}
            dimension={24}
            hoverOpacity={uc.HOVER_OPACITY}
            imageSource={iconSource}
            text={text}
            fontSize={13}
            onPress={onClick}
        />
    )
}

export default FilterButton
