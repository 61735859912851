import styled from 'styled-components'

export const TitleWrap = styled.div<{ isSubTitle: boolean; expanded: boolean }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ isSubTitle }) => (isSubTitle ? '12px' : '0')};

    > div {
        display: flex;
        column-gap: 3.3px;
        align-items: end;

        > h1 {
            font-size: ${({ isSubTitle }) => (isSubTitle ? '1.231rem' : '1.462rem')};
            font-weight: 500;
            margin: 0;
        }
    }

    > svg {
        cursor: pointer;
        width: 15px;
        height: 9px;
        transform: ${({ expanded }) => (expanded ? 'rotate(0deg)' : 'rotate(180deg)')};
    }
`
