import React, { useContext, useState } from 'react'
import TaskRow from './TaskRow'
import ColumnHeader from './ColumnHeader'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
    allTasksAtom,
    areasAtom,
    currentAreaAtom,
    currentIssueAtom,
    currentTaskAtom,
    minimizedTasksAtom,
    respParamsAtom,
    usersAtom
} from '../../state/atoms'
import * as issueData from '@shared/issue-data'
import firebase, { asFirebase } from '../../../utils/firebase'
import TaskEditDialog from '../../pages/task-edit-dialog/TaskEditDialog'
import Modal from 'react-modal'
import HousekeepingModal from '../../../housekeeping/housekeeping-modal'
import * as areaData from '@shared/area-data'
import * as taskData from '@shared/task-data'
import * as dashboardStyles from '../../../housekeeping/dashboard/styles'
import { getHeightAIssueModal, getWidthModalAIssue } from '../../../utils/responsive'
import AIssueModal from '../../../issues/aissue-modal'
import { IssueStruct, TaskStruct, UserStruct } from '@shared/dataObjects'
import { TasksSectionTitle } from '@shared/task-types'
import { aIssueModalStyle, Row } from './style'
import Subsection from './Subsection'
import { selectedDateNumberSelector } from 'app/tasks/state/selectors/selectedDateNumber'
import { currentOrganizationAtom, currentUserAtom } from 'app/modules/Auth/atoms'
import { AreaStruct, TaskStatus as TS } from '@shared/firestore-structs'
import { structuredClone } from '@shared/polyfills'
import { SubscriptionContext } from '../../../modules/Subscriptions/SubscriptionContext'

interface IColumn {
    title: string
    count: number
    borderColor: string
    tasks: TaskStruct[]
    totalCount: number
    lane: TS
    overdueTasks?: TaskStruct[]
    noDueDateTasks?: TaskStruct[]
}

export default function Column({ title, count, borderColor, tasks, lane, overdueTasks, noDueDateTasks }: IColumn) {
    const currentUser = useRecoilValue(currentUserAtom) as UserStruct
    const currentOrganization = useRecoilValue(currentOrganizationAtom)
    const { currentSubscription } = useContext(SubscriptionContext)

    const [generalTaskModalIsOpen, setGeneralTaskModalIsOpen] = useState(false)
    const [areaModalIsOpen, setAreaModalIsOpen] = useState(false)
    const [issueModalIsOpen, setIssueModalIsOpen] = useState(false)

    const selectedDateNumber = useRecoilValue(selectedDateNumberSelector)
    const areas = useRecoilValue(areasAtom)
    const users = useRecoilValue(usersAtom)
    const { respWidth, respHeight } = useRecoilValue(respParamsAtom)
    const minimizedTasks = useRecoilValue(minimizedTasksAtom)

    const [currentTask, setCurrentTask] = useRecoilState(currentTaskAtom)
    const [currentArea, setCurrentArea] = useRecoilState(currentAreaAtom)
    const [currentIssue, setCurrentIssue] = useRecoilState(currentIssueAtom)
    const [allTasks, setAllTasks] = useRecoilState(allTasksAtom)

    const isMinimized = minimizedTasks.includes(lane)

    function onGeneralTaskModalHide() {
        setGeneralTaskModalIsOpen(false)
        setCurrentTask(null)
    }

    function closeAreaModal() {
        setAreaModalIsOpen(false)
        setCurrentArea(null)
        setCurrentTask(null)
    }

    function closeIssueModal() {
        setIssueModalIsOpen(false)
        setCurrentIssue(null)
    }

    function onGeneralRowClick(task: TaskStruct) {
        setCurrentTask(task)
        setGeneralTaskModalIsOpen(true)
    }

    function onHousekeepingRowClick(task: TaskStruct) {
        const updatedTask = { ...task, area: { ...task.area, tasks: [] } }

        setCurrentTask(updatedTask)
        setCurrentArea(updatedTask.area)

        /*
            temporary fix, as this logic will be rewritten
            after the new housekeeping modal is implemented
        */
        setTimeout(() => {
            setAreaModalIsOpen(true)
        })
    }

    async function onIssueRowClick(issueKey: string) {
        const issue = await issueData.getIssue(asFirebase(firebase), issueKey)
        setCurrentIssue(issue)
        setIssueModalIsOpen(true)
    }

    function onHousekeepingUnassignPress() {
        const task = structuredClone(currentTask) as TaskStruct
        const changeObj = {
            before: 'unassigned'
        }
        areaData.logActivity(asFirebase(firebase), currentUser, currentArea?.key as string, 'assignment', selectedDateNumber, changeObj)

        taskData.unassignTask(asFirebase(firebase), currentUser as UserStruct, task.key)
        setCurrentTask(null)
    }

    function updateTaskStatus(key: string, status: TS) {
        const updatedTasks = allTasks.map((task: TaskStruct) => (task.key === key ? { ...task, status: status } : task))
        setAllTasks(updatedTasks)
        setCurrentTask(updatedTasks.find((task: TaskStruct) => task.key === key) ?? null)
    }

    return (
        <>
            {currentTask && generalTaskModalIsOpen && (
                <TaskEditDialog show={true} onHide={onGeneralTaskModalHide} task={currentTask} areas={areas} users={users} />
            )}

            {/* FIXME define types */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Modal isOpen={areaModalIsOpen} style={dashboardStyles.areaModalStyle} contentLabel="Area" onRequestClose={closeAreaModal}>
                {/*TODO refactor this component */}
                <HousekeepingModal
                    context="tasks"
                    area={structuredClone(currentArea)}
                    task={structuredClone(currentTask)}
                    assignedTo={currentTask && currentTask.assignedTo ? structuredClone(currentTask.assignedTo) : [{ name: 'empty' }]}
                    onUnassignPress={onHousekeepingUnassignPress}
                    closeModal={closeAreaModal}
                    style={dashboardStyles.areaModalStyle}
                    selectedDateNumber={selectedDateNumber}
                    getSelectedDateNumber={() => selectedDateNumber}
                    currentUser={currentUser}
                    currentOrganization={currentOrganization}
                    currentSubscription={currentSubscription}
                    setCurrentArea={(area: AreaStruct) => setCurrentArea(area)}
                    setCurrentTask={(task: TaskStruct) => setCurrentTask(task)}
                />
            </Modal>
            {currentIssue && (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <Modal isOpen={issueModalIsOpen} onRequestClose={closeIssueModal} style={aIssueModalStyle} contentLabel="Open issue">
                    <AIssueModal
                        issue={structuredClone(currentIssue) as IssueStruct}
                        closeModal={closeIssueModal}
                        respWidth={getWidthModalAIssue(respWidth)}
                        respHeight={getHeightAIssueModal(respHeight)}
                        currentUser={currentUser}
                        currentOrganization={currentOrganization}
                        updateTaskStatus={updateTaskStatus}
                    />
                </Modal>
            )}

            <div className="col-12 col-md-4">
                <ColumnHeader title={title} count={count} borderColor={borderColor} lane={lane} />

                {tasks.map(task => {
                    const { key } = task

                    return (
                        <Row key={key} style={{ marginBottom: 16 }}>
                            <TaskRow
                                task={task}
                                onHousekeepingRowClick={onHousekeepingRowClick}
                                onIssueRowClick={onIssueRowClick}
                                onGeneralRowClick={onGeneralRowClick}
                                isMinimized={isMinimized}
                                isVisible
                            />
                        </Row>
                    )
                })}

                {overdueTasks && overdueTasks.length > 0 && (
                    <Subsection
                        title={TasksSectionTitle.OVERDUE}
                        tasks={overdueTasks}
                        onGeneralRowClick={onGeneralRowClick}
                        onHousekeepingRowClick={onHousekeepingRowClick}
                        onIssueRowClick={onIssueRowClick}
                        isMinimized={isMinimized}
                    />
                )}

                {/* Temporary doesn’t show the no due date section. DON'T DELETE */}
                {/* {noDueDateTasks && noDueDateTasks.length > 0 && (
                    <Subsection
                        title={TasksSectionTitle.NO_DUE_DATE}
                        tasks={noDueDateTasks}
                        onGeneralRowClick={onGeneralRowClick}
                        onHousekeepingRowClick={onHousekeepingRowClick}
                        onIssueRowClick={onIssueRowClick}
                        isMinimized={isMinimized}
                    />
                )} */}
            </div>
        </>
    )
}
