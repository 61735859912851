import React, { useState, useContext, useEffect } from 'react'
import { ControlContainer, selectStyles, OrgSelectorButton } from './style'
import { AuthContext } from '../../../../modules/Auth/AuthContext'
import Select from 'react-select'
import firebase from '../../../../utils/firebase'
import { sortByName } from '@shared/helpers'
import { INSIDE_IFRAME } from 'app/utils/constants'
import { getCurrentUserOrganizations, changeOrganizationSetup } from '../../../../modules/OrganizationSignUp/cloud-functions'

const Control = ({ children, orgName, ...props }) => {
    return (
        <ControlContainer {...props}>
            <span style={{ textAlign: 'left' }}>{orgName}</span>
            {!INSIDE_IFRAME && children}
        </ControlContainer>
    )
}

const OrganizationDropdown = ({ usingInHeader = false }) => {
    const { organization, setLoadingAuthState } = useContext(AuthContext)
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const [orgsOptions, setOrgsOptions] = useState([])

    async function getOrgs() {
        const getUserOrganizations = getCurrentUserOrganizations()
        const { data: orgs } = await getUserOrganizations()
        const orgsOptions = orgs
            .map(org => ({ value: org.organizationKey, label: org.organizationName }))
            .sort((a, b) => sortByName(a.label, b.label))

        setOrgsOptions(orgsOptions)
    }

    useEffect(() => {
        getOrgs()
    }, [])

    const control = ({ children, ...props }) => (
        <Control orgName={organization.name} usingInHeader={usingInHeader} {...props}>
            {children}
        </Control>
    )

    const onOrganizationChange = async org => {
        const changeOrgCloudFunc = changeOrganizationSetup()
        setLoadingAuthState(true)
        document.body.click()

        const result = await changeOrgCloudFunc({ organizationKey: org.value })

        // Update current user claims immediately
        await firebase.auth().currentUser.getIdToken(true)
        if (result.data !== null) {
            document.location.reload()
        }
    }

    return (
        <OrgSelectorButton
            usingInHeader={usingInHeader}
            onBlur={() => setMenuIsOpen(false)}
            onClick={() => !INSIDE_IFRAME && setMenuIsOpen(!menuIsOpen)}>
            <Select
                autoFocus={true}
                menuIsOpen={menuIsOpen}
                styles={selectStyles}
                options={orgsOptions}
                components={{ Control: control }}
                onChange={onOrganizationChange}
            />
        </OrgSelectorButton>
    )
}

export default OrganizationDropdown
