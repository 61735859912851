import styled from 'styled-components'
import * as uc from 'app/utils/constants'

export const isSmallDesktop = window.innerHeight < uc.SMALL_DESKTOP_SCREEN_HEIGHT
export const containerPadding = isSmallDesktop ? '25px 55px 25px 55px' : '64px 73px 35px 73px'
export const containerWidth = isSmallDesktop ? '500px' : '584px'

export const ActivityIndicatorWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 85%;
`
