import React, { useEffect, useState } from 'react'
import { Content, Wrapper } from './style'
import HeaderNavbar from '../HeaderNavbar'
import { useLocation } from 'react-router-dom'
import { defineTaskboardContext } from 'app/traces/integration-utils'
import { TaskboardContext } from '@shared/traces-types'

interface Props {
    children: React.ReactNode
}

function TracesLayout({ children }: Props) {
    const { pathname, search } = useLocation()
    const [showHeaderNavbar, setShowHeaderNavbar] = useState(true)

    useEffect(() => {
        if (defineTaskboardContext(pathname, search) === TaskboardContext.RESERVATIONS) {
            setShowHeaderNavbar(false)
        }
    }, [search])

    return (
        <Wrapper>
            {showHeaderNavbar && <HeaderNavbar />}
            <Content>{children}</Content>
        </Wrapper>
    )
}

export default TracesLayout
