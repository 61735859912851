// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from 'react'

export function ActionsColumnFormatter(
    cellContent,
    row,
    rowIndex,
    { openEditAreaDialog, hasSubscriptionAccess, setShowUpgradeMessage, setDeleteAreaId }
) {
    return (
        <>
            <a
                title="Edit unit"
                className="btn btn-icon btn-circle btn-bg-specta-gray btn-hover-bg-specta-gray-light btn-hover-icon-primary btn-sm mx-3"
                onClick={() => {
                    if (!hasSubscriptionAccess && row.synced !== 'Yes') {
                        setShowUpgradeMessage(true)
                    } else {
                        openEditAreaDialog(row.id)
                    }
                }}>
                <i className="specta-pencil2" />
            </a>
            <> </>

            <a
                title="Delete unit"
                className="btn btn-icon btn-circle btn-bg-specta-gray btn-hover-bg-specta-gray-light btn-hover-icon-danger btn-sm"
                onClick={() => setDeleteAreaId(row.id)}>
                <i className="specta-trash" />
            </a>
        </>
    )
}
