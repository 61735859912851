import React from 'react'
import ContextMenu from 'app/components/ContextMenu'
import styled from 'styled-components'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'

interface Props {
    groups: string[]
    groupCounts: Record<string, number>
    onBlur: () => void
    onOptionClick: (title: string) => void
}

export const MoreOption = styled.div`
    width: 100%;
    height: 47px;
    border: 1px solid ${colors.gentle_gray_spectaflow};
    color: ${iOSColors.gray};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${colors.white};

    &:hover {
        background-color: ${colors.gentle_gray_spectaflow};
    }
`

function MoreGroupsDropdown({ groups, groupCounts, onBlur, onOptionClick }: Props) {
    return (
        <ContextMenu height={350} overflow="scroll" width={150.9} onBlur={onBlur}>
            <div>
                {groups.slice(7).map((group, index) => {
                    return (
                        <MoreOption onClick={() => onOptionClick(group)} key={group + index}>
                            {group} ({groupCounts[group]})
                        </MoreOption>
                    )
                })}
            </div>
        </ContextMenu>
    )
}

export default MoreGroupsDropdown
