import React, { useContext, useEffect, useState } from 'react'
import { iOSColors } from '@shared/react-native-typography'
import { Modal } from 'react-bootstrap'
import { db } from '../utils/firebase'
import AreaSelector, { AreaOption } from './area-selector'
import { AuthContext } from '../modules/Auth/AuthContext'

import { HoverableImageButton } from '../components/buttons/hoverable-image-button'
import IssueHashtagsSelect from '../components/hashtags-select'
import * as uc from '../utils/constants'
import * as helpers from '@shared/helpers'
import { HoverableTextInput } from '../components/hoverable-text-input'
import * as Yup from 'yup'
import { useIssues } from './state/hooks/useIssues'
import { InputDescription } from '../components/input-description'
import { SectionTitle } from '../components/section-title'
import { CloseModalButton } from '../components/buttons/close-modal-button'
import { ButtonDatePicker } from '../components/button-date-picker'
import moment from 'moment-timezone'
import { DueDateButton } from '../components/buttons/DueDateButton'
import { Dropdown } from '../components/forms/Dropdown'
import { Field, Formik, Form } from 'formik'
import ImagesDropBox from '../components/images-dropbox'
import './new-issue.scss'

import {
    NewIssueFormWrapper,
    NewIssueTopSection,
    NewIssueBottomSection,
    DueDateFieldContainer,
    ImagesFieldContainer,
    ProirityFieldContainer,
    ButtonsContainer,
    assignUsersCustomStyles
} from './style'
import MainActionButton from '../components/buttons/MainActionButton'
import { NewIssueValues } from './types'
import { IssueStruct } from '@shared/firestore-structs'

const HightPriorityImg = require('@shared/img/priority-high-red-bgr.svg')
const LowPriorityImg = require('@shared/img/priority-low.svg')

const requiredMessageStyle = { color: iOSColors.red, fontSize: 12, marginTop: 2 }

const NewIssueSchema = Yup.object().shape({
    title: Yup.string().min(1, 'Minimum 1 symbol').max(50, 'Maximum 50 symbols').required('Title is required'),
    unit: Yup.object().required('Unit is required')
})
type NewIssueFormProps = {
    closeModal: () => void
    defaultHashtags: string[]
}
export default function NewIssueForm({ closeModal, defaultHashtags }: NewIssueFormProps) {
    const { currentUser } = useContext(AuthContext)
    const { createIssue } = useIssues()
    const [areasGroups, setAreasGroups] = useState<string[]>([])
    const [selectedUsers, setSelectedUsers] = useState<
        {
            value: string
            label: string
            initials: string
            areaGroups: string[]
        }[]
    >([])
    const [users, setUsers] = useState<
        {
            value: string
            label: string
            initials: string
            areaGroups: string[]
        }[]
    >([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function fetchData() {
            const usersRef = await db
                .collection('users')
                .where('organizationKey', '==', currentUser!.organizationKey)
                .where('visible', '==', true)
                .get()

            const usersFetched = usersRef.docs.map(a => ({
                value: a.data().key as string,
                label: a.data().name as string,
                initials: a.data().initials as string,
                areaGroups: a.data().areaGroups as string[]
            }))

            const usersSorted = usersFetched.sort((a, b) => helpers.sortByName(a.label, b.label))

            const usersFilteredByAreasGroups = usersSorted.filter(user => {
                return user.areaGroups.some(group => {
                    return areasGroups.some(areaGroup => areaGroup === group) || group === 'All'
                })
            })
            setUsers(usersFilteredByAreasGroups)
        }

        fetchData()
    }, [])

    function onSubmit(values: NewIssueValues) {
        setLoading(true)
        createIssue(values, currentUser!).then(() => {
            closeModal()
            setLoading(false)
        })
    }

    return (
        <>
            <Formik
                enableReinitialize={false}
                initialValues={{
                    images: [] as File[],
                    dueDateNumber: null as IssueStruct['dueDate'],
                    title: '' as IssueStruct['name'],
                    unit: ('' as unknown) as AreaOption,
                    hashtags: [] as string[],
                    assignedTo: '' as any,
                    priority: false
                }}
                validationSchema={NewIssueSchema}
                onSubmit={onSubmit}>
                {({ handleSubmit, values, setFieldValue, errors, touched }) => (
                    <>
                        <Modal.Body className="overlay overlay-block cursor-default">
                            <Form className="form form-label-right">
                                <NewIssueFormWrapper>
                                    <CloseModalButton close={closeModal} />
                                    <SectionTitle marginTop={30} text={'Create a new issue'} fontSize={22} />

                                    <NewIssueTopSection>
                                        <ImagesFieldContainer>
                                            <InputDescription text={'Images:'} />
                                            <Field
                                                name="images"
                                                component={ImagesDropBox}
                                                images={values.images}
                                                setImages={(images: File[]) => setFieldValue('images', [...values.images!, ...images])}
                                                width={125}
                                                isPreviewShown
                                                deleteImage={(imageIndex: number) =>
                                                    setFieldValue(
                                                        'images',
                                                        values.images!.filter((_, index) => index !== imageIndex)
                                                    )
                                                }
                                            />
                                        </ImagesFieldContainer>
                                        <DueDateFieldContainer>
                                            {!values.dueDateNumber && (
                                                <Field
                                                    name="dueDateNumber"
                                                    component={ButtonDatePicker}
                                                    selectedDateNumber={moment().startOf('day').valueOf()}
                                                    onDateChange={(date: Date) => {
                                                        setFieldValue('dueDateNumber', moment(date).startOf('day').valueOf())
                                                    }}
                                                />
                                            )}
                                            {values.dueDateNumber && (
                                                <Field
                                                    name="dueDateNumber"
                                                    component={DueDateButton}
                                                    dueDate={values.dueDateNumber}
                                                    onClick={() => {
                                                        setFieldValue('dueDateNumber', null)
                                                    }}
                                                />
                                            )}
                                        </DueDateFieldContainer>
                                    </NewIssueTopSection>

                                    <div className="mt-3">
                                        <div className="required-container">
                                            <InputDescription text={'Title:'} />
                                        </div>
                                        <Field
                                            text={values.title}
                                            name="title"
                                            component={HoverableTextInput}
                                            placeholder="Click to add title"
                                            nonActiveBorderColor={iOSColors.lightGray}
                                            fontSize={17}
                                            textPaddingLeft={12}
                                            height={40}
                                            width="100%"
                                            onChangeText={(text: string) => {
                                                setFieldValue('title', text)
                                            }}
                                            onSubmit={() => null}
                                        />
                                        {errors.title && touched.title ? <span style={requiredMessageStyle}>{errors.title}</span> : null}
                                    </div>
                                    <div className="mt-8">
                                        <div className="required-container">
                                            <InputDescription text={'Unit:'} />
                                        </div>
                                        <Field
                                            name="unit"
                                            component={AreaSelector}
                                            currentUser={currentUser}
                                            width="100%"
                                            onChange={(areas: AreaOption) => {
                                                setFieldValue('unit', areas)
                                            }}
                                            setAreasGroups={(areas: AreaOption[]) => {
                                                const areasGroups = [...new Set(areas.map(area => area.group))]
                                                setAreasGroups(areasGroups)
                                            }}
                                        />
                                        {errors.unit && touched.unit ? (
                                            <span style={requiredMessageStyle}>{errors.unit as string}</span>
                                        ) : null}
                                    </div>
                                    <div className="mt-8">
                                        <InputDescription text={'Hashtags (optional):'} />
                                        <Field
                                            component={IssueHashtagsSelect}
                                            width={'100%'}
                                            defaultHashtags={defaultHashtags}
                                            hashtags={values.hashtags}
                                            isDisabled={false}
                                            onChange={(issueHashtags: string[]) => {
                                                setFieldValue('hashtags', issueHashtags)
                                            }}
                                        />
                                    </div>
                                    <div className="mt-5">
                                        <Field
                                            placeholder="Select users..."
                                            name="assignedTo"
                                            isSearchable
                                            isMulti
                                            value={selectedUsers}
                                            options={users}
                                            onChange={(values: typeof users) => {
                                                setSelectedUsers(values)
                                                setFieldValue(
                                                    'assignedTo',
                                                    values.map(u => ({ name: u.label, key: u.value, initials: u.initials }))
                                                )
                                            }}
                                            currentUser={currentUser}
                                            noOptionsMessage={() => 'No users found try another search'}
                                            component={Dropdown}
                                            label="Assign users"
                                            styles={assignUsersCustomStyles}
                                            maxMenuHeight={130}
                                        />
                                    </div>

                                    <NewIssueBottomSection>
                                        <ProirityFieldContainer>
                                            <Field
                                                dimension={uc.BUTTON_DIM}
                                                hoverOpacity={uc.HOVER_OPACITY}
                                                imageSource={values.priority ? HightPriorityImg : LowPriorityImg}
                                                onPress={() => {
                                                    setFieldValue('priority', !values.priority)
                                                }}
                                                component={HoverableImageButton}
                                            />
                                            <InputDescription
                                                marginLeft={10}
                                                text={'Priority is ' + (values.priority ? 'high' : 'normal')}
                                            />
                                        </ProirityFieldContainer>
                                        <ButtonsContainer>
                                            <MainActionButton type="secondary" size="large" onClick={closeModal}>
                                                Cancel
                                            </MainActionButton>
                                            <div className="mr-5"> </div>
                                            <MainActionButton type="primary" size="large" onClick={handleSubmit} disabled={loading}>
                                                {loading ? (
                                                    <span>
                                                        <span
                                                            className="spinner-border spinner-border-sm"
                                                            role="status"
                                                            aria-hidden="true"></span>
                                                    </span>
                                                ) : (
                                                    'Save'
                                                )}
                                            </MainActionButton>
                                        </ButtonsContainer>
                                    </NewIssueBottomSection>
                                </NewIssueFormWrapper>
                            </Form>
                        </Modal.Body>
                    </>
                )}
            </Formik>
        </>
    )
}
