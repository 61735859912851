import { selector } from 'recoil'
import { currentDateNumberAtom } from '../atoms'
import { getStartOfDayTimestamp } from 'app/utils/formatters'
import { currentOrganizationAtom } from 'app/modules/Auth/atoms'

export const selectedDateNumberSelector = selector<number>({
    key: 'housekeeping-selectedDateNumberSelector',
    get: ({ get }) => {
        const currentDateNumber = get(currentDateNumberAtom)
        const currentOrganization = get(currentOrganizationAtom)

        return getStartOfDayTimestamp(currentDateNumber, currentOrganization?.timezone as string)
    }
})
