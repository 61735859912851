import { getBrand } from '@shared/brand'
import { OrgStruct } from '@shared/firestore-structs'
import { CurrentUser } from 'app/modules/Auth/AuthContext'
import { capitalize } from 'lodash'
import moment from 'moment-timezone'
import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'

declare global {
    interface Window {
        Intercom: any
    }
}

interface UserDataParams {
    currentUser: CurrentUser
    currentOrganization: OrgStruct
}

type IntercomFunctions = (props?: any) => void

export function useIntercomState(isAuthorized: boolean, currentUser: CurrentUser | null, currentOrganization: OrgStruct | null) {
    const { boot, update, shutdown } = useIntercom()

    useEffect(() => {
        isAuthorized && currentUser && currentOrganization
            ? intercomStateChange(boot, update, shutdown, {
                  currentUser: currentUser as CurrentUser,
                  currentOrganization: currentOrganization as OrgStruct
              })
            : intercomStateChange(boot, update, shutdown)
    }, [isAuthorized])
}

function createUserData(INTERCOM_APP_ID?: string, params?: UserDataParams) {
    if (!params) {
        return { appId: INTERCOM_APP_ID }
    }

    const { currentUser, currentOrganization } = params
    const userData = {
        appId: INTERCOM_APP_ID,
        userId: currentUser.key,
        name: currentUser.name,
        email: currentUser.email,
        phone: currentUser.phoneNumber,
        createdAt: moment(currentUser.created).unix(),
        customAttributes: {
            teammates: currentOrganization.userCount - 1,
            role: capitalize(currentUser.authRole)
        }
    }

    return !currentUser.authSuper
        ? {
              ...userData,
              company: {
                  companyId: currentOrganization.key,
                  name: currentOrganization.name,
                  createdAt: moment(currentOrganization.created).unix(),
                  customAttributes: {
                      syncedAreas: currentOrganization.areasSyncedTotal,
                      unsyncedAreas: currentOrganization.areasNotSyncedTotal,
                      userCount: currentOrganization.userCount
                  }
              }
          }
        : { ...userData, customAttributes: { role: 'Super admin' } }
}

function intercomStateChange(boot: IntercomFunctions, update: IntercomFunctions, shutdown: IntercomFunctions, params?: UserDataParams) {
    const INTERCOM_APP_ID = getBrand().intercomAppId
    const userData = createUserData(INTERCOM_APP_ID, params)

    if (!('userId' in userData)) {
        shutdown()
        userData.appId && boot(userData)
        return
    }
    if (!userData.appId) return

    window.Intercom && window.Intercom.booted ? update(userData) : boot(userData)
}
