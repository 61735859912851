import { gentle_gray_spectaflow } from '@shared/colors'
import styled from 'styled-components'

export const Wrap = styled.div`
    width: 100%;
    height: 48px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;

    &:hover {
        background-color: ${gentle_gray_spectaflow};
    }

    > img {
        width: 26px;
        height: 26px;
    }
`
