import React from 'react'

import DashboardCard from './DashboardCard'
import { useHistory } from 'react-router'
import { AccountsProvider } from '../../Accounts/AccountsContext'

export default function DashboardPage() {
    let history = useHistory()
    const accountsUIEvents = {
        newAccountButtonClick: () => {
            history.push('/partner/accounts/new')
        },
        openEditAccountDialog: id => {
            history.push(`/partner/accounts/${id}/edit`)
        },
        openDeleteAccountDialog: id => {
            history.push(`/partner/accounts/${id}/delete`)
        },
        openDeleteAccountsDialog: () => {
            history.push('/partner/accounts/deleteAccounts')
        }
    }
    return (
        <AccountsProvider accountsUIEvents={accountsUIEvents}>
            <DashboardCard />
        </AccountsProvider>
    )
}
