import React from 'react'
import Taskboard from '../index'
import { TasksProvider } from '../TasksContext'

export default function TasksPage() {
    return (
        <>
            <TasksProvider tasksUIEvents={{}}>
                <Taskboard />
            </TasksProvider>
        </>
    )
}
