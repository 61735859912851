import { useContext } from 'react'
import { SETTINGS, HOUSEKEEPING, REPORTS, TASKBOARD, ISSUES, HELP } from 'app/navigation/url-constants'

import { INSIDE_IFRAME } from 'app/utils/constants'
import { useFeatureToggle } from '../features'
import { AuthContext } from '../modules/Auth/AuthContext'

const haveCommonItems = (arr1, arr2) => {
    const set1 = new Set(arr1)
    const commonItems = arr2.filter(item => set1.has(item))
    return commonItems.length > 0
}

export const navItems = [
    ...(INSIDE_IFRAME
        ? [
              {
                  name: 'Taskboard',
                  path: INSIDE_IFRAME ? TASKBOARD.APALEO : TASKBOARD.INDEX,
                  authRole: ['user'],
                  iconSize: '12px',
                  // iconPath: '@shared/img/navbar/nav-tasks.svg',
                  icon: 'checkmark',
                  subMenuItems: []
              }
          ]
        : []),

    {
        name: 'Housekeeping',
        path: HOUSEKEEPING.INDEX,
        authRole: ['user'],
        icon: 'grid-2',
        iconSize: '18px',
        // iconPath: '@shared/img/navbar/nav-cleaning.svg',
        subMenuItems: []
    },
    ...(!INSIDE_IFRAME
        ? [
              {
                  name: 'Taskboard',
                  path: INSIDE_IFRAME ? TASKBOARD.APALEO : TASKBOARD.INDEX,
                  authRole: ['user'],
                  iconSize: '12px',
                  // iconPath: '@shared/img/navbar/nav-tasks.svg',
                  icon: 'checkmark',
                  subMenuItems: []
              }
          ]
        : []),

    {
        name: 'Issues',
        path: ISSUES.INDEX,
        authRole: ['user'],
        icon: 'issues',
        iconSize: '12px',
        style: { marginLeft: -1 },
        // iconPath: '@shared/img/navbar/nav-issues.svg',
        subMenuItems: []
    },

    {
        name: 'Reports',
        path: REPORTS.INDEX,
        authRole: ['admin', 'house-manager'],
        icon: 'chart',
        iconSize: 'md',
        // iconPath: '@shared/img/navbar/nav-settings.svg',
        subMenuItems: [
            {
                name: 'Housekeeping',
                path: REPORTS.HOUSEKEEPING,
                authRole: ['admin', 'house-manager'],
                icon: 'broom',
                iconSize: 'md',
                // iconPath: '@shared/img/navbar/navbar-users.svg',
                subMenuItems: []
            },
            {
                name: 'Issues',
                path: '/reports/issues',
                authRole: ['admin', 'house-manager'],
                icon: 'issues',
                iconSize: '12px',
                style: { marginLeft: -1 },
                // iconPath: '@shared/img/navbar/nav-issues.svg',
                subMenuItems: []
            }
        ]
    },

    {
        name: 'Settings',
        path: SETTINGS.INDEX,
        authRole: ['admin'],
        icon: 'settings',
        iconSize: '16px',
        // iconPath: '@shared/img/navbar/nav-settings.svg',
        subMenuItems: [
            {
                name: 'Users',
                path: '/settings/users',
                authRole: ['admin'],
                icon: 'assigned-to',
                iconSize: '1x',
                // iconPath: '@shared/img/navbar/navbar-users.svg',
                subMenuItems: []
            },
            {
                name: 'Units',
                path: SETTINGS.UNITS.INDEX,
                authRole: ['admin'],
                icon: 'home',
                iconSize: 'ms',
                // iconPath: '@shared/img/navbar/navbar-areas.svg',
                subMenuItems: []
            },
            {
                name: 'Rules',
                path: '/settings/rules',
                authRole: ['admin'],
                icon: 'rules',
                iconSize: 'ms',

                // iconPath: '@shared/img/navbar/nav-settings.svg',
                subMenuItems: []
            },
            // {
            //     name: 'Guests',
            //     path: '/settings/guests',
            //     authRole: ['admin'],
            //     icon: 'assigned-to',
            //     iconSize: '1x',
            //     // icon: require('@shared/img/navbar/nav-settings.svg'),
            //     subMenuItems: [
            //         // {
            //         //     name: 'Cleaning',
            //         //     path: '/guests/cleaning',
            //         //     authRole: 'super',
            //         //     icon: 'org-group',
            //         //     iconSize: 'ms',
            //         //     // icon: require('@shared/img/navbar/navbar-users.svg'),
            //         //     subMenuItems: []
            //         // }
            //     ]
            // },
            {
                name: 'Account',
                path: SETTINGS.ACCOUNT.INDEX,
                authRole: ['admin'],
                icon: 'organization',
                iconSize: '1x',

                // iconPath: '@shared/img/navbar/navbar-organizations.svg',
                subMenuItems: []
            }
        ]
    },

    {
        name: 'Partner',
        path: '/partner',
        authRole: ['super'],
        icon: 'money-bag',
        iconSize: '18px',
        // iconPath: '@shared/img/navbar/nav-settings.svg',
        // icon: require('@shared/img/navbar/nav-settings.svg'),
        subMenuItems: [
            // {
            //     name: 'Dashboard',
            //     path: '/partner/dashboard',
            //     authRole: 'super',
            //     icon: 'grid-2',
            //     iconSize: '18px',
            //     // icon: require('@shared/img/navbar/navbar-users.svg'),
            //     subMenuItems: []
            // },
            {
                name: 'Subscriptions',
                path: '/partner/accounts',
                authRole: ['super'],
                icon: 'org-group',
                iconSize: 'ms',
                // iconPath: '@shared/img/navbar/navbar-users.svg',
                subMenuItems: []
            }
        ]
    },

    {
        name: 'Help',
        path: HELP,
        external: true,
        authRole: ['user'],
        icon: 'far fa-question-circle',
        // iconPath: '',
        iconSize: '1.025rem',
        iconNotSpecta: true,
        subMenuItems: []
    }
]

export const filterNavItems = (user, organization) => {
    const { isFeatureOn } = useFeatureToggle()
    const { orgSubscription } = useContext(AuthContext)
    let authRoles = ['user']

    if (isFeatureOn('roles-and-permissions')) {
        if (user.authRole !== 'user') {
            authRoles.push(user.authRole)
        }
    } else {
        if (user.authRole === 'admin') {
            authRoles.push('admin')
        }
    }
    if (user.authSuper) {
        authRoles.push('super')
    }

    if (orgSubscription && orgSubscription.features.includes('workload-reports')) {
        const reportsNode = navItems.find(x => x.path === '/reports')
        const workloadNode = reportsNode.subMenuItems.find(x => x.path === REPORTS.WORKLOAD)
        if (!workloadNode) {
            reportsNode.subMenuItems.push({
                name: 'Workload',
                path: '/reports/workload',
                authRole: ['admin', 'house-manager'],
                style: { marginLeft: -1 },
                subMenuItems: []
            })
        }
    }

    if (organization.allowOptIn || organization.allowOptOut) {
        const reportsNode = navItems.find(x => x.path === '/reports')
        const optionalNode = reportsNode.subMenuItems.find(x => x.path === REPORTS.OPT_IN)
        if (!optionalNode) {
            reportsNode.subMenuItems.push({
                name: 'Optional housekeeping',
                path: '/reports/optional',
                authRole: ['admin', 'house-manager'],
                icon: 'broom',
                iconSize: 'md',
                subMenuItems: []
            })
        }
    }

    const filteredNavItems = navItems.filter(levelOneItem => {
        if (haveCommonItems(levelOneItem.authRole, authRoles)) {
            levelOneItem.subMenuItems = levelOneItem.subMenuItems.filter(levelTwoItem => {
                if (haveCommonItems(levelTwoItem.authRole, authRoles)) {
                    levelTwoItem.subMenuItems = levelTwoItem.subMenuItems.filter(levelThreeItem => {
                        if (haveCommonItems(levelThreeItem.authRole, authRoles)) {
                            return levelThreeItem
                        }
                    })
                    return levelTwoItem
                }
            })
            return levelOneItem
        }
    })

    return filteredNavItems
}
