import { RolePermissions } from './roles-types'
import { match } from 'ts-pattern'
import { OrgStruct, UserRole, UserStruct } from '../firestore-structs'
import { Subscription } from '../subscriptions/subscription'
import { isFeatureOn } from '../feature-toggles'

export class Role {
    // eslint-disable-next-line no-unused-vars
    constructor(private name: UserRole, private displayName: string, private permissions: RolePermissions[], private parentRole?: Role) {}

    checkPermission(permission: RolePermissions): boolean {
        const localAllowed = this.permissions.includes(permission)

        if (!localAllowed && this.parentRole) {
            return this.parentRole.checkPermission(permission)
        }

        return localAllowed
    }
}

export function defineBasicRoles(org: OrgStruct) {
    const housekeeper = new Role('housekeeper', 'Housekeeper', ['assign-tasks-start-task', 'assign-tasks-stop-task'])

    const customRoles = isFeatureOn(org, 'stayery-custom-roles')

    const user = new Role(
        'user',
        'User',
        [
            'issues-assign',
            'user-access-control-create-user',
            'user-access-control-remove-user',
            'assign-tasks-start-task',
            'assign-tasks-stop-task',
            'assign-housekeeping-assign',
            'assign-housekeeping-un-assign',
            'assign-tasks-complete-inspection'
        ],
        housekeeper
    )
    const admin = new Role('admin', 'Admin', [], user)

    const houseManager = new Role('house-manager', 'House Manager', [], user)

    return {
        user,
        admin,
        housekeeper,
        ...(customRoles && { houseManager })
    }
}

function defineProRoles(org: OrgStruct) {
    const housekeeper = new Role('housekeeper', 'Housekeeper', ['assign-tasks-start-task', 'assign-tasks-stop-task'])

    const customRoles = isFeatureOn(org, 'stayery-custom-roles')

    const user = new Role(
        'user',
        'User',
        [
            'issues-assign',
            'user-access-control-create-user',
            'user-access-control-remove-user',
            'assign-tasks-start-task',
            'assign-tasks-stop-task',
            'assign-housekeeping-assign',
            'assign-housekeeping-un-assign',
            'assign-tasks-complete-inspection'
        ],
        housekeeper
    )
    const admin = new Role('admin', 'Admin', [], user)

    const houseManager = new Role('house-manager', 'House Manager', [], user)

    return {
        user,
        admin,
        housekeeper,
        ...(customRoles && { houseManager })
    }
}

export function getRolesForOrg(org: OrgStruct) {
    return match(org.subscription ?? 'basic')
        .with('pro', () => defineProRoles(org))
        .with('basic', () => defineBasicRoles(org))
        .with('test', () => defineProRoles(org))
        .otherwise(() => {
            throw new Error(`Unknown plan ${org}`)
        })
}

export const userIsAllowed = (
    permissionId: RolePermissions,
    user: UserStruct | null,
    organization: OrgStruct,
    subscription: Subscription
) => {
    if (!subscription.permissions.includes(permissionId)) return false
    const { user: userRole, admin, housekeeper, houseManager } = getRolesForOrg(organization)

    if (user?.authSuper) return true

    const role = match(user!.authRole!)
        .with('admin', () => admin)
        .with('user', () => userRole)
        .with('housekeeper', () => housekeeper)
        .with('house-manager', () => houseManager)
        .exhaustive()

    return role?.checkPermission(permissionId)
}
