import React, { useEffect, useState } from 'react'
import { Box, BoxesWrap, Wrapper } from './style'
import BookingSelector from './BookingSelector'
import HousekeepingStatusSelector from './HousekeepingStatusSelector'
import { AreaStruct, TaskStruct, UserStruct } from '@shared/firestore-structs'
import AssignedTo from './AssignedTo'
import { persistHousekeepingModalSelectorValue, unassignHousekeepingTasks } from '../../api'
import { useRecoilValue } from 'recoil'
import { currentUserAtom } from 'app/modules/Auth/atoms'
import { toast } from 'react-toastify'
import { toastSuccessStyle } from 'app/utils/styles'
import { logActivity } from '@shared/area-data'
import firebase, { asFirebase } from 'app/utils/firebase'
import * as c from '@shared/constants'
import { BookingDisplay, prepareCurrentBookingDisplay } from '@shared/booking-service'
import { bookingsAtom, cleaningAtom, taskAtom } from '../state/atoms'
import { calculatedAreaSelector } from '../state/selectors/calculatedAreaData'
import { cleaningStatusColorSelector } from '../state/selectors/visuals'
import { selectedDateNumberSelector } from '../state/selectors/selectedDateNumber'

function CleaningSection() {
    const task = useRecoilValue(taskAtom)
    const { area } = useRecoilValue(calculatedAreaSelector)
    const bookings = useRecoilValue(bookingsAtom)
    const selectedDateNumber = useRecoilValue(selectedDateNumberSelector)
    const cleaningStatusColor = useRecoilValue(cleaningStatusColorSelector)
    const cleaning = useRecoilValue(cleaningAtom)

    const [assignedTo, setAssignedTo] = useState<TaskStruct['assignedTo']>(task?.assignedTo)
    const [selectedOccupancy, setSelectedOccupancy] = useState<AreaStruct['occupancy']>(area.occupancy)
    const [bookingDisplay, setBookingDisplay] = useState<BookingDisplay | null>(null)
    const [selectedCleaningStatus, setSelectedCleaningStatus] = useState<AreaStruct['cleaningStatus']>(area.cleaningStatus)

    const currentUser = useRecoilValue(currentUserAtom) as UserStruct

    const { occupancy, cleaningStatus, synced } = area

    useEffect(() => {
        setAssignedTo(task?.assignedTo)
    }, [task?.key])

    useEffect(() => {
        setSelectedOccupancy(occupancy)
    }, [occupancy])

    useEffect(() => {
        setSelectedCleaningStatus(cleaningStatus)
    }, [cleaningStatus])

    useEffect(() => {
        getCurrentBooking()
    }, [bookings.length, selectedDateNumber])

    async function getCurrentBooking() {
        const { bookingDisplay } = await prepareCurrentBookingDisplay(asFirebase(firebase), {
            bookings,
            calculatedArea: area,
            selectedDateNumber,
            organizationKey: currentUser.organizationKey
        })

        delete bookingDisplay.nrOfDaysStayed
        delete bookingDisplay.occupancyState

        setBookingDisplay(bookingDisplay)
    }

    async function onUnassignClick() {
        setAssignedTo(null)

        try {
            await unassignHousekeepingTasks([task as TaskStruct], currentUser)
            await logActivity(asFirebase(firebase), currentUser, area.key, c.ACTIVITY_TYPE_ASSIGNMENT, selectedDateNumber, {
                before: 'unassigned'
            })
            toast.success('Task unassigned', toastSuccessStyle)
        } catch (error: unknown) {
            setAssignedTo(task?.assignedTo)
            toast.error(`Error unassigning task ${error instanceof Error ? error.message : error}`)
            console.error(error)
        }
    }

    async function onOccupancyChange(occupancy: AreaStruct['occupancy']) {
        setSelectedOccupancy(occupancy)
        await persistHousekeepingModalSelectorValue(c.ACTIVITY_TYPE_OCCUPANCY, currentUser, area.key, selectedDateNumber, {
            field: 'occupancy',
            before: area.occupancy,
            after: occupancy
        })
    }

    async function onCleaningStatusChange(cleaningStatus: AreaStruct['cleaningStatus']) {
        setSelectedCleaningStatus(cleaningStatus)
        await persistHousekeepingModalSelectorValue(c.ACTIVITY_TYPE_CLEANING_STATUS, currentUser, area.key, selectedDateNumber, {
            field: 'cleaningStatus',
            before: area.cleaningStatus,
            after: cleaningStatus
        })
    }

    return (
        <Wrapper>
            <BoxesWrap>
                <BookingSelector
                    onOccupancyChange={onOccupancyChange}
                    synced={synced}
                    occupancy={selectedOccupancy}
                    cleaningStatusColor={cleaningStatusColor}
                    bookingDisplay={bookingDisplay}
                />

                <HousekeepingStatusSelector
                    onCleaningStatusChange={onCleaningStatusChange}
                    cleaningStatus={selectedCleaningStatus}
                    cleaningStatusColor={cleaningStatusColor}
                    cleaning={cleaning}
                />
            </BoxesWrap>

            {assignedTo && (
                <BoxesWrap>
                    <Box justifyContent={'start'} height={assignedTo.length === 2 ? '143px' : '111.5px'}>
                        <AssignedTo assignedTo={assignedTo} onUnassignClick={onUnassignClick} />
                    </Box>
                    <Box height={'111.5px'} opacity={0}></Box>
                </BoxesWrap>
            )}
        </Wrapper>
    )
}

export default CleaningSection
