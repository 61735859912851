import styled from 'styled-components'

export const Wrap = styled.div<{ size: number; disabled: boolean; hoverable: boolean }>`
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')}};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    > svg {
        width: ${({ size }) => size}px;
        height: ${({ size }) => size}px;
    }

    &:hover {
        > svg {
            ${({ disabled, hoverable }) => !disabled && hoverable && 'opacity: 0.5;'}
        }
    }
`
