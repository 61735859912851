import styled, { keyframes } from 'styled-components'
import * as colors from '@shared/colors'
import * as uc from '../utils/constants'
import { iOSColors } from '@shared/react-native-typography'
import { ViewProps } from 'react-native'
import { View, Image } from 'react-native-web'

const WHITE = colors.white
const DEBUG_BORDER = 0
const IMG_SCALE = 1.2

export const NewIssueFormWrapper = styled.div`
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 20px;
    border-width: 0;
`
export const NewIssueTopSection = styled.div`
    z-index: 1000;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
`
export const NewIssueBottomSection = styled.div`
    display: flex;
    z-index: -10;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 44px;
    border-width: 0px;
`

export const ImagesFieldContainer = styled.div`
    width: 79%;
`
export const DueDateFieldContainer = styled.div`
    display: flex;
    width: 21%;
    justify-content: flex-end;
    border-width: 0;
    height: ${uc.BUTTON_DIM}px;
`

export const ProirityFieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
export const ButtonsContainer = styled.div`
    display: flex;
    border-width: 0px;
    justify-content: space-between;
`

export const IssuesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: ${WHITE};
    border-width: ${DEBUG_BORDER}px;
    border-color: red;
`

export const aIssueModalStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        borderRadius: 4,
        borderColor: 'red',
        borderWidth: 0,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '80vh',
        width: '70%',
        maxHeight: '80vh' // <-- This sets the height
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 1002
    }
}

export const massStatusModalStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: 6,
        borderColor: 'red',
        borderWidth: 0,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: 340,
        width: 450,
        maxHeight: '200', // <-- This sets the height
        overflow: 'scroll' // <-- This tells the modal to scrol
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0,.2)',
        zIndex: 1002
    }
}

export const massHashTagModalStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: 6,
        borderColor: 'red',
        borderWidth: 0,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: 340,
        width: 550,
        maxHeight: '200', // <-- This sets the height
        overflow: 'scroll' // <-- This tells the modal to scrol
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0,.2)',
        zIndex: 1002
    }
}

export const newIssueModalStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: 6,
        borderColor: 'red',
        borderWidth: 0,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // height: '40vw',
        width: '50vw',
        //maxHeight: '200', // <-- This sets the height
        overflow: 'visible'
    },
    overlay: {
        position: 'fixed',
        zIndex: 1005,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0,.2)'
    }
}

export const assignUsersCustomStyles = {
    menu: (provided: any) => {
        return {
            ...provided
        }
    }
}

export const IssueRowStyle = styled.button<{ isHousekeeping: boolean }>`
    display: flex;
    width: 100%;
    border-radius: 8px;
    border-width: ${({ isHousekeeping }) => (isHousekeeping ? 0 : '1px')};
    border-color: ${colors.gentle_gray_spectaflow};
    border-style: solid;
    margin-bottom: 16px;
    padding: 16px 0;
    background-color: ${WHITE};

    &:hover {
        background-color: #efefef;
    }
`

export const CheckboxContainer = styled.div<{ height: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: ${({ height }) => `${height}px`};
    border-width: ${DEBUG_BORDER}px;
    border-color: blue;
    padding-top: 0;
    padding-bottom: 0;
`

export const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 52px;
    height: 33%;
    border-width: ${DEBUG_BORDER}px;
    border-color: red;
    padding-bottom: 1px;
`

export const IssueImageWrapper = styled.div<{ borderRadius: number; width: number; height: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    background-color: ${colors.white};
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    border: 1px solid ${iOSColors.lightGray};
`

export const TextIssueImage = styled.img<{ width: number; height: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    opacity: 0.2;
`

export const IssueImageContainer = styled.div<{ width: number; height: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
`

export const IssueImage = styled.img<{ width: number; height: number }>`
    object-fit: cover;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    max-width: ${({ width }) => `${width}px`};
    max-height: ${({ height }) => `${height}px`};
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    border-width: 0;
    border-color: purple;
    background-color: ${colors.ios_header_background};
`

export const IssueImageNotDownloadedContainer = styled.div<{ borderRadius: number }>`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-color: red;
    border-width: 0;
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    background-color: ${iOSColors.customGray};
`

export const IssueImageNotDownloaded = styled.img<{ width: number; height: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
`

export const IssueImageBlock = styled.div<{ width: number }>`
    position: relative;
    border-width: 0;
    border-color: red;
    width: ${({ width }) => `${width}px`};
    height: 100%;
`

export const PriorityContainer = styled.div<{ topPosition: number; rightPosition: number }>`
    position: absolute;
    top: ${({ topPosition }) => `${topPosition}px`};
    right: ${({ rightPosition }) => `${rightPosition}px`};
    justify-content: center;
    align-items: center;
    z-index: 100;
`

export const PriorityImage = styled.img<{ dimension: number }>`
    width: ${({ dimension }) => `${dimension}px`};
    height: ${({ dimension }) => `${dimension}px`};
`

export const MoreImagesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 32px;
    height: 32px;
    background-color: #1b1b1b;
    border-radius: 4px;
    opacity: 0.7;
`

export const MoreImagesText = styled.span`
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
`

export const HashTagsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-width: 0;
    border-color: blue;
`

export const HashTagsContainer = styled.span<{ titleFontSize: number; titleFontWeight: string }>`
    display: flex;
    flex-wrap: wrap;
    font-size: ${({ titleFontSize }) => `${titleFontSize}px`};
    font-weight: ${({ titleFontWeight }) => `${titleFontWeight}`};
    color: ${colors.textPrimary};
    align-items: flex-start;
    line-height: 20px;
`

export const HashTagText = styled.span<{ fontSize: number }>`
    margin-top: 1px;
    display: flex;
    justify-content: center;
    margin-left: 5px;
    font-size: ${({ fontSize }) => `${fontSize - 2}px`};
    color: ${colors.green_spectaflow};
`

export const IssueInfoWrapper = styled.div`
    display: flex;
    flex: 1;
`

export const IssueInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 16px;
    padding-right: 24px;
    width: 100%;
`

export const IssueInfoBreadcrubsContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    border-width: ${DEBUG_BORDER}px;
    margin-bottom: 4px;
`

export const IssueNumberWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
`

export const DueDateContainer = styled.div<{ lineHeight: number }>`
    display: flex;
    height: ${({ lineHeight }) => `${lineHeight}px`};
    justify-content: flex-start;
    align-items: flex-end;
`

export const NoDueDateBlock = styled.span`
    color: ${iOSColors.midGray};
    font-size: 13px;
    font-weight: '700';
`

export const IssueInfoFooterContainer = styled.div<{ lineHeight: number }>`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    height: ${({ lineHeight }) => `${lineHeight}px`};
`

export const AreaBredCrumbsContainer = styled.div`
    display: flex;
    width: 85%;
`

export const DateContainer = styled.div`
    display: flex;
`

export const DateWrapper = styled.span<{ fontSize: number }>`
    font-size: ${({ fontSize }) => `${fontSize}px`};
    font-weight: '600';
    color: ${iOSColors.midGray};
`

export const IssuesNotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-color: green;
    border-width: 0;
    align-items: center;
    justify-content: flex-start;
    padding-top: 60px;
    padding-bottom: 60px;
`
export const IssuesNotFoundImageBanner = styled.div`
    background-image: url(${require('@shared/img/no-issues-hotel-building.svg')});
    background-size: cover;
    width: ${149 * IMG_SCALE}px;
    height: ${127 * IMG_SCALE}px;
`

export const IssuesNotFoundDescription = styled.span<{ marginTop: number; fontSize: number }>`
    margin-top: ${({ marginTop }) => `${marginTop}px`};
    font-size: ${({ fontSize }) => `${fontSize}px`};
    font-weight: 500;
    color: ${iOSColors.gray};
`

export const IssuesListWrapper = styled.div`
    border-color: 'blue';
    border-width: ${DEBUG_BORDER};
    margin-top: 0;
`
export const IssuesListContentStyle = {
    width: '100%',
    paddingBottom: 0,
    borderColor: 'blue',
    borderWidth: 0,
    marginTop: 0
}

export const IssuesBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
`
export const LoaderWrapper = styled.div`
    align-items: center;
    margin-top: 200px;
    margin-bottom: 200px;
    border-width: 0;
    border-color: blue;
`
const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const ActionBarContainer = styled.div`
    animation: ${fadeInAnimation} 0.75s;
`

export const MoreButtonContainer = styled.div<{ backgroundColor?: string; borderWidth?: number } & ViewProps>`
    margin-left: 16px;
    background-color: ${props => props.backgroundColor};
    border-color: ${iOSColors.midGray};
    border-width: ${props => props.borderWidth};
    width: 30px;
    height: 23px;
    border-radius: 30px;
    padding-horizontal: 10px;
    justify-content: center;
    align-items: center;
`
export const MoreButtonImage = styled(Image)`
    height: 4px;
    width: 16px;
    opacity: 0.5;
`
export const ButtonsBarContainer = styled.div`
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    flex-direction: row;
    align-items: center;
    z-index: 1;
`
