import React from 'react'
import { ClearButton, Input, InputWrap, Wrapper } from './style'
import clearTextIcon from '@shared/img/clear-text.svg'

interface Props {
    value: string
    placeholder: string
    onChange: (value: string) => void
}

function Search({ value, placeholder, onChange }: Props) {
    function onClearClick() {
        onChange('')
    }

    return (
        <Wrapper>
            <InputWrap>
                <i className="specta-search icon-md" />
                <Input
                    value={value}
                    data-testid="search-box"
                    placeholder={placeholder}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                />
            </InputWrap>

            {value.length > 0 && <ClearButton data-testid="clear-search-box" src={clearTextIcon as string} onClick={onClearClick} />}
        </Wrapper>
    )
}

export default Search
