import styled from 'styled-components'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'

export const Wrapper = styled.div`
    background-color: ${colors.white};
    width: 75%;
    height: 75%;
    overflow-y: scroll;
    position: relative;
`

export const LoaderWrap = styled.div`
    width 100%;
    height: 75%;
`

export const SectionTitleWrap = styled.div<{ fontSize?: string; iconWidth?: number; iconHeight?: number; iconColor?: string }>`
    width: 100%;
    display: flex;
    column-gap: 6px;
    align-items: center;
    margin-bottom: 12px;

    > svg {
        width: ${({ iconWidth }) => iconWidth || 26}px;
        height: ${({ iconHeight }) => iconHeight || 26}px;
        ${({ iconColor }) => iconColor && `path { stroke: ${iconColor}; }`}
    }

    > h2 {
        margin: 0;
        font-weight: 600;
        font-size: ${({ fontSize }) => fontSize || '1.308rem'};
        color: ${iOSColors.black};
    }

    > span {
        margin-top: 3px;
        font-weight: 500;
        font-size: 1.077rem;
        color: ${iOSColors.midGray};
    }
`

export const ContentWrap = styled.div`
    > div:nth-child(even) {
        background-color: ${colors.gentle_gray_spectaflow};
    }
`
