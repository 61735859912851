import React from 'react'
import { Image } from 'react-native-web'
import { NavLink } from 'react-router-dom'
import Alert from '../../../../../../components/alerts/Alert'
import { green_spectaflow } from '@shared/colors'

export const UpgradeSuccessMessage = ({ onConfirm }) => {
    return (
        <Alert
            // title="You have successfully cancelled your subscription"
            type="success"
            onConfirm={onConfirm}>
            <div className="container">
                <div className="row flex-center">
                    <span className="font-weight-boldest font-size-h1">Welcome to Pro</span>
                    {/* {isTrial && <span className="font-size-lg text-gray">Free for 30 days</span>} */}
                </div>
                <span className="d-flex flex-center p-2 mt-2 text-dark-50 font-size-lg font-weight-bolder">
                    All Pro features are now unlocked!
                </span>
                <div className="d-flex flex-center p-2 text-dark-50 font-size-lg font-weight-bolder">Get started by checking out</div>
                <div className="d-flex flex-center p-2 text-dark-50 font-size-lg font-weight-bolder">
                    <NavLink
                        activeStyle={{ color: 'blue' }}
                        style={{
                            color: green_spectaflow,
                            ':hover': {
                                color: '#0078d2'
                            }
                        }}
                        to={'/taskboard'}>
                        &nbsp;Taskboard&nbsp;
                    </NavLink>
                    or
                    <NavLink style={{ color: green_spectaflow }} to={'/issues'}>
                        &nbsp;Issues
                    </NavLink>
                </div>
            </div>
        </Alert>
    )
}
