import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    padding: 24px 40px;
    margin-bottom: 40px;
`

export const ActivitiesWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
