import React from 'react'
import { SelectWrapper, selectInputStyles } from './style'
import ReactSelect from 'react-select'

const Select = ({ value, label, options, placeholder, onChange }) => {
    return (
        <SelectWrapper>
            <label>{label}</label>
            <ReactSelect value={value} placeholder={placeholder} styles={selectInputStyles} options={options} onChange={onChange} />
        </SelectWrapper>
    )
}

export default Select
