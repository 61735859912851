import React, { Component } from 'react'
import firebase from '../utils/firebase'
import * as areaData from '@shared/area-data'
import * as dataObjects from '@shared/dataObjects'
import { HoverableTextInput } from '../components/hoverable-text-input'
import moment from 'moment-timezone'

export default class DailyCommentTextInput extends Component {
    constructor(props) {
        super(props)

        this.onTextChange = this.onTextChange.bind(this)
        this.getDailyComment = this.getDailyComment.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.textInputValue = ''
        this.state = {
            dailyComment: null,
            textInputValue: this.textInputValue,
            dataIsReady: false,
            currentSelectedDate: this.props.selectedDateNumber
        }

        this.startListener = this.startListener.bind(this)
        this.stopListener = this.stopListener.bind(this)
        this.onDailyCommentsUpdate = this.onDailyCommentsUpdate.bind(this)
    }

    componentDidMount() {
        this.startListener()
        this.getDailyComment(firebase, this.props.area.key, this.state.currentSelectedDate)
    }

    componentWillUnmount() {
        this.stopListener()
    }

    startListener() {
        const dailyCommentRef = firebase
            .firestore()
            .collection('areas')
            .doc(this.props.area.key)
            .collection('dailyComments')
            .where('date', '==', this.props.selectedDateNumber)

        this.unsubscribe = dailyCommentRef.onSnapshot(this.onDailyCommentsUpdate)
    }

    onDailyCommentsUpdate(snap) {
        this.getDailyComment(firebase, this.props.area.key, this.state.currentSelectedDate)
    }

    stopListener() {
        if (this.unsubscribe) {
            this.unsubscribe()
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedDateNumber !== state.currentSelectedDate) {
            return { currentSelectedDate: props.selectedDateNumber }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentSelectedDate !== this.state.currentSelectedDate) {
            this.getDailyComment(firebase, this.props.area.key, this.state.currentSelectedDate)
        }
    }

    getDailyComment(firebase, areaKey, selectedDateNumber) {
        return firebase
            .firestore()
            .collection('areas')
            .doc(areaKey)
            .collection('dailyComments')
            .where('date', '==', selectedDateNumber)
            .get()
            .then(dailyCommentsSnap => {
                let foundComment = false
                dailyCommentsSnap.forEach(dcSnap => {
                    const dailyComment = dcSnap.data()
                    foundComment = true
                    if (dailyComment.comment === '') {
                        this.props.shouldRenderDailyCommentIcon(false)
                    } else {
                        this.props.shouldRenderDailyCommentIcon(true)
                    }
                    this.textInputValue = dailyComment.comment
                    this.oldTextInputValue = dailyComment.comment
                    this.setState({
                        textInputValue: this.textInputValue,
                        dailyComment: dailyComment,
                        dataIsReady: true
                    })
                })

                if (!foundComment) {
                    this.textInputValue = ''
                    this.oldTextInputValue = ''
                    this.props.shouldRenderDailyCommentIcon(false)
                    this.setState({
                        textInputValue: this.textInputValue,
                        dailyComment: null,
                        dataIsReady: true
                    })
                }
            })
    }

    onTextChange(value) {
        this.textInputValue = value
        this.setState({
            textInputValue: this.textInputValue
        })
    }

    onSubmit() {
        if (this.oldTextInputValue !== this.textInputValue) {
            const changeObj = {
                before: this.oldTextInputValue,
                after: this.textInputValue
            }
            areaData.logActivity(
                firebase,
                this.props.currentUser,
                this.props.area.key,
                'daily-comment',
                this.props.selectedDateNumber,
                changeObj
            )

            const updateStateFunc = dailyComment => {
                this.setState({ dailyComment: dailyComment })
                this.touchArea(firebase, this.props.area.key, this.props.currentUser)
            }
            if (this.textInputValue === '') {
                this.props.shouldRenderDailyCommentIcon(false)
            } else {
                this.props.shouldRenderDailyCommentIcon(true)
            }

            if (this.state.dailyComment) {
                this.updateDailyComment(
                    firebase,
                    this.props.area.key,
                    this.state.dailyComment.key,
                    this.textInputValue,
                    this.props.currentUser,
                    updateStateFunc
                )
            } else {
                this.createDailyComment(
                    firebase,
                    this.props.area.key,
                    this.props.selectedDateNumber,
                    this.textInputValue,
                    this.props.currentUser,
                    updateStateFunc
                )
            }

            this.oldTextInputValue = this.textInputValue
        }
    }

    touchArea(firebase, areaKey, currentUser) {
        const areaObject = {
            lastStatusChange: {
                user: dataObjects.getMiniUserObject(currentUser),
                updated: moment().valueOf()
            }
        }
        areaData.updateArea(firebase, areaKey, areaObject, currentUser)
    }

    createDailyCommentObject(dailyCommentKey, areaKey, date, comment, creator) {
        const timeStamp = moment().valueOf()
        return {
            key: dailyCommentKey,
            organizationKey: creator.organizationKey,
            areaKey: areaKey,
            created: timeStamp,
            date: date,
            comment: comment,
            creator: {
                key: creator.key,
                initials: creator.initials,
                name: creator.name
            },
            visible: true
            // updated:
        }
    }

    updateDailyComment(firebase, areaKey, dailyCommentKey, comment, currentUser, callback) {
        const db = firebase.firestore()
        var batch = db.batch()

        const areaRef = db.collection('areas').doc(areaKey)
        const dailyCommentRef = areaRef.collection('dailyComments').doc(dailyCommentKey)
        const timeStamp = moment().valueOf()
        const updateObject = {
            comment: comment,
            creator: {
                key: currentUser.key,
                initials: currentUser.initials,
                name: currentUser.name
            },
            updated: timeStamp
        }
        const dailyCommentState = JSON.parse(JSON.stringify(this.state.dailyComment))
        dailyCommentState.comment = updateObject.comment
        dailyCommentState.creator = updateObject.creator
        dailyCommentState.updated = updateObject.updated

        batch.update(dailyCommentRef, updateObject)
        batch.commit().catch(error => {
            console.log('(updateDailyComment) ', error)
        })
        if (callback) {
            callback(dailyCommentState)
        }
    }

    createDailyComment(firebase, areaKey, date, comment, currentUser, callback) {
        const db = firebase.firestore()
        var batch = db.batch()

        const areaRef = db.collection('areas').doc(areaKey)
        const dailyCommentRef = areaRef.collection('dailyComments').doc()

        const dailyCommentObject = this.createDailyCommentObject(dailyCommentRef.id, areaKey, date, comment, currentUser)

        batch.set(dailyCommentRef, dailyCommentObject)

        batch.commit().catch(error => {
            console.log('(createDailyComment) ', error)
        })
        if (callback) {
            callback(dailyCommentObject)
        }
    }

    render() {
        return (
            <HoverableTextInput
                showIcon={true}
                disabled={this.props.disabled}
                text={this.state.textInputValue}
                placeholderText={!this.state.dataIsReady ? 'Loading...' : 'Click to add a comment'}
                fontSize={17}
                fontWeight={'300'}
                textPaddingLeft={12}
                height={40}
                onSubmit={value => {
                    this.onTextChange(value)
                    this.onSubmit()
                }}
            />
        )
    }
}
