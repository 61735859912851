import React from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import './style.scss'
import { getBrand } from '@shared/brand'

const Tooltip = ({ children, placement, content, ...rest }) => {
    const brand = getBrand().key

    return (
        <Tippy {...rest} placement={placement} theme={brand} content={content}>
            {children}
        </Tippy>
    )
}

export default Tooltip
