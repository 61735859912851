import { ActivityChangeObject, ActivityType, AreaStruct, OrgStruct, TaskStruct, UserStruct } from '@shared/firestore-structs'
import { assignTask, createTask, deleteTask, setTaskUpdate, unassignTask } from '@shared/task-data'
import firebase, { asFirebase, logActivity, tasksRepo, touchArea } from 'app/utils/firebase'
import * as c from '@shared/constants'
import { toast } from 'react-toastify'
import { CleaningScheduleUpdateValue, LastCleaningTask } from '../types'
import { getStartOfDayTimestamp } from 'app/utils/formatters'
import { Needed } from '@shared/type-utils'

export async function assignHousekeepingTasks(
    areas: AreaStruct[],
    users: Partial<UserStruct>[],
    startDate: number,
    currentUser: UserStruct,
    names: string[],
    existedTasks?: TaskStruct[] | null
) {
    const batch = asFirebase(firebase).firestore().batch()
    const assignedTo = users as Needed<Partial<UserStruct>, 'key' | 'name' | 'initials'>[]

    const commonActivityLog = {
        type: c.ACTIVITY_TYPE_ASSIGNMENT as ActivityType,
        date: startDate,
        change: {
            before: 'assigned',
            after: users.map(user => user.name).join(', ')
        }
    }

    const result = areas.map((area, index) => {
        const existedTask = existedTasks?.find(task => task.areaKey === area.key)

        if (existedTask) {
            return assignTask(asFirebase(firebase), currentUser, existedTask.key, { assignedTo }, batch, {
                ...commonActivityLog,
                areaKey: area.key
            })
        }

        return createTask<typeof c.TASK_TYPE_HOUSEKEEPING>(
            asFirebase(firebase),
            area,
            currentUser,
            c.TASK_TYPE_HOUSEKEEPING,
            {
                assignedTo,
                name: names[index],
                startDate
            },
            batch,
            { ...commonActivityLog, areaKey: area.key }
        )
    })

    try {
        await batch.commit()
    } catch (error: unknown) {
        console.error('(HousekeepingDashboard api assignHousekeepingTasks)', error)
        throw new Error(`Assign failed, please try again`)
    }

    return result
}

export async function unassignHousekeepingTasks(tasks: TaskStruct[], currentUser: UserStruct) {
    const batch = asFirebase(firebase).firestore().batch()

    tasks.forEach(task => unassignTask(asFirebase(firebase), currentUser, task.key, batch))

    try {
        await batch.commit()
    } catch (error: unknown) {
        console.error(error)
        throw error
    }
}

export async function getLastCleaningTask(
    lastCleaningTaskKey?: string,
    currentTask: TaskStruct | null = null
): Promise<LastCleaningTask | null> {
    if (!lastCleaningTaskKey) return null

    if (lastCleaningTaskKey === currentTask?.key) {
        return currentTask?.cleaning ? (currentTask as LastCleaningTask) : null
    }

    const task = await tasksRepo.getTask(lastCleaningTaskKey)
    return task?.cleaning ? (task as LastCleaningTask) : null
}

export async function persistHousekeepingModalSelectorValue(
    type: ActivityType,
    currentUser: UserStruct,
    areaKey: string,
    selectedDateNumber: number,
    change: ActivityChangeObject
) {
    try {
        await logActivity(asFirebase(firebase), currentUser, areaKey, type, selectedDateNumber, change)
        await touchArea(asFirebase(firebase), areaKey, currentUser)
    } catch (error: unknown) {
        toast.error(`Error changing ${type} ${error instanceof Error ? error.message : error}`)
        console.error(error)
    }
}

export async function updateCleaningSchedule(
    bookingKey: string,
    areaKey: string,
    before: CleaningScheduleUpdateValue,
    after: CleaningScheduleUpdateValue,
    currentOrganization: OrgStruct,
    currentUser: UserStruct
) {
    const db = firebase.firestore()
    const updateField = currentOrganization.allowOptIn ? 'optInDates' : 'optOutDates'

    try {
        await db
            .collection('bookings')
            .doc(bookingKey)
            .update({ [updateField]: after })

        await logActivity(
            asFirebase(firebase),
            currentUser,
            areaKey,
            c.ACTIVITY_TYPE_CLEANING_SCHEDULE,
            getStartOfDayTimestamp(new Date(), currentOrganization.timezone),
            { before, after }
        )
    } catch (error: unknown) {
        console.error(error)
        throw error
    }
}
