import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { iOSUIKit, iOSColors, webWeights } from '@shared/react-native-typography'
import * as colors from '@shared/colors'

export default class OrganizationIDsSelect extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            organizationIDs: undefined,
            defaultOrganizationIDs: undefined,
            isDisabled: false
        }
        this.setSelcectStyle()
    }
    componentDidMount() {
        this.setSelcectStyle()
    }

    setSelcectStyle() {
        this.selectStyles = {
            control: styles => ({
                ...styles,
                backgroundColor: colors.white,
                width: this.props.width ? this.props.width : 260,
                borderWidth: 1,
                borderColor: colors.gentle_gray_spectaflow,
                borderRadius: 6,
                boxShadow: 'none',
                fontSize: 14,
                ':hover': {
                    borderWidth: 1,
                    borderColor: iOSColors.midGray,
                    borderRadius: 6
                }
            }),
            // eslint-disable-next-line no-unused-vars
            option: (styles, { data, isDisabled, isFocused }) => {
                const style = [
                    {
                        ...styles,
                        backgroundColor: isFocused ? iOSColors.gray : null,
                        color: isFocused ? colors.white : iOSColors.gray,
                        fontSize: 14,
                        cursor: isDisabled ? 'default' : 'default'
                    }
                ]
                return style
            },
            placeholder: styles => {
                const style = [
                    {
                        ...styles,
                        color: iOSColors.lightGray,
                        fontSize: 14,
                        backgroundColor: colors.white
                    }
                ]
                return style
            },
            multiValue: styles => {
                const style = [
                    {
                        ...styles,
                        backgroundColor: colors.white,
                        border: '1px solid ' + iOSColors.midGray,
                        borderRadius: 20,
                        borderWidth: 1,
                        height: 22,
                        alignItems: 'center'
                    }
                ]
                return style
            },
            multiValueLabel: styles => {
                const style = [
                    {
                        ...styles,
                        color: iOSColors.gray,
                        fontSize: 14
                    }
                ]
                return style
            },
            multiValueRemove: styles => {
                const style = [
                    {
                        ...styles,
                        color: iOSColors.gray,
                        ':hover': {
                            color: colors.textPrimary
                        }
                    }
                ]

                return style
            },
            noOptionsMessage: styles => {
                const style = [
                    {
                        ...styles,
                        color: iOSColors.gray,
                        fontWeight: '500',
                        fontSize: 14,
                        backgroundColor: colors.white
                    }
                ]
                return style
            },
            indicatorSeparator: (styles, { data }) => ({
                ...styles,
                backgroundColor: '#f4f5f6',
                width: 0
            })
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isDisabled !== state.isDisabled) {
            return { isDisabled: props.isDisabled }
        }

        if (Array.isArray(props.organizationIDs) && props.organizationIDs.length > 0 && state.organizationIDs === undefined) {
            const organizationIDs = props.organizationIDs
                .map(id => parseInt(id))
                .sort((a, b) => a - b)
                .map(id => ({ value: id, label: id }))

            return { organizationIDs }
        }

        if (
            Array.isArray(props.defaultOrganizationIDs) &&
            props.defaultOrganizationIDs.length > 0 &&
            props.defaultOrganizationIDs !== state.defaultOrganizationIDs
        ) {
            const defaultOrganizationIDs = props.defaultOrganizationIDs
                .map(id => parseInt(id))
                .sort((a, b) => a - b)
                .map(id => ({
                    value: id,
                    label: id
                }))
            return { defaultOrganizationIDs }
        }

        return null
    }

    render() {
        return (
            <CreatableSelect
                isDisabled={this.state.isDisabled}
                value={this.state.organizationIDs}
                maxMenuHeight={100}
                captureMenuScroll
                placeholder="Enter IDs"
                isMulti
                menuPlacement={'top'}
                name="organizationIDS"
                options={this.state.defaultOrganizationIDs}
                styles={this.selectStyles}
                noOptionsMessage={() => (this.props.noOptionsMessage ? this.props.noOptionsMessage : 'No options left ')}
                onChange={organizationIDs => {
                    const validateOrganizationIDs = Array.isArray(organizationIDs)
                        ? organizationIDs
                              .map(id => {
                                  if (Number.isInteger(Number(id.value))) {
                                      return {
                                          value: Number(id.value),
                                          label: Number(id.value)
                                      }
                                  }
                              })
                              .filter(x => x !== undefined)
                              .sort()
                        : []
                    this.setState({ organizationIDs: validateOrganizationIDs })
                    this.props.onChange(validateOrganizationIDs.map(id => parseInt(id.value)).sort((a, b) => a - b))
                }}
            />
        )
    }
}
