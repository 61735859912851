import React from 'react'
import { View, Text } from 'react-native-web'

import { iOSColors } from '@shared/react-native-typography'

export const InputDescription = props => {
    const font_size = props.fontSize ? props.fontSize : 13
    const font_weight = props.fontWeight ? props.fontWeight : '500'
    const color = props.color ? props.color : iOSColors.gray
    const margin_left = props.marginLeft !== undefined ? props.marginLeft : 0
    const margin_right = props.marginRight !== undefined ? props.marginRight : 0
    const margin_top = props.marginTop !== undefined ? props.marginTop : 0
    const margin_bottom = props.marginBottom !== undefined ? props.marginBottom : 3

    return (
        <View
            style={{
                borderWidth: 0,
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: margin_top,
                marginBottom: margin_bottom,
                marginLeft: margin_left,
                marginRight: margin_right,
                lineHeight: 20
            }}>
            <span
                style={{
                    fontSize: font_size,
                    fontWeight: font_weight,
                    color: color
                }}>
                {props.text ? props.text : ''}
            </span>
        </View>
    )
}
