import React from 'react'
import { DateCirclesWrap, DateWrap, Subheader, Wrap } from './style'
import { RedButton } from 'app/components/buttons/RedButton'
import DateCircle from '../DateCircle'
import { CleaningScheduleSection } from 'app/modules/HousekeepingDashboard/types'
import moment from 'moment-timezone'

interface Props {
    section: CleaningScheduleSection
    selectedDateNumber: number
    onDateCircleClick: (date: number) => void
    onSelectAllClick: () => void
}

function MonthSection({ section, onDateCircleClick, onSelectAllClick, selectedDateNumber }: Props) {
    const disabled = selectedDateNumber !== moment().startOf('day').valueOf()

    return (
        <Wrap>
            <DateWrap>
                <h2>{moment(section.month).format('MMMM YYYY')}</h2>
                <div>
                    <RedButton disabled={disabled} onClick={() => !disabled && onSelectAllClick()}>
                        Select all
                    </RedButton>
                </div>
            </DateWrap>

            <DateCirclesWrap>
                {section.schedule.map(scheduledDate => (
                    <div key={scheduledDate.date}>
                        <Subheader>{scheduledDate.subheader}</Subheader>
                        <DateCircle onClick={onDateCircleClick} scheduledDate={scheduledDate} />
                    </div>
                ))}
            </DateCirclesWrap>
        </Wrap>
    )
}

export default MonthSection
