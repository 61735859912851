import moment from 'moment-timezone'
import { AreaStruct, UserStruct, ActivityType, ActivityStruct } from './firestore-structs'
import { getMiniUserObject } from './dataObjects'
import { Firebase, WriteBatch } from './firebase'
import { getOrganization } from './org-data'

// TODO: add dates type
export async function getActivities(firebase: Firebase, dates: any, organizationKey: string): Promise<ActivityStruct[]> {
    const activityRef = await firebase
        .firestore()
        .collectionGroup('activities')
        .where('organizationKey', '==', organizationKey)
        .where('date', 'in', dates)
        .get()

    return !activityRef.empty ? activityRef.docs.map(a => a.data() as ActivityStruct) : []
}

export async function createActivity(
    firebase: Firebase,
    area: AreaStruct,
    currentUser: UserStruct,
    date: number,
    type: ActivityType,
    field: string,
    before: string | string[],
    after: string | string[],
    batch?: WriteBatch
) {
    const activityRef = firebase.firestore().collection('areas').doc(area.key).collection('activities')
    const org = await getOrganization(firebase, currentUser.organizationKey)

    if (org) {
        moment.tz.setDefault(org.timezone)
    }

    const refId = activityRef.doc().id

    const activity = {
        areaKey: area.key,
        type,
        date,
        created: moment().valueOf(),
        change: {
            field,
            before,
            after
        },
        key: refId,
        user: getMiniUserObject(currentUser),
        organizationKey: currentUser.organizationKey
    }

    if (batch) {
        batch.set(activityRef.doc(refId), activity)
    } else {
        await activityRef.doc(refId).set(activity)
    }
}

export function getActivitiesQuery(firebase: Firebase, organizationKey: string, date: number, areaKey: string | null = null) {
    let query = firebase.firestore().collectionGroup('activities').where('organizationKey', '==', organizationKey).where('date', '==', date)

    if (areaKey) {
        query = query.where('areaKey', '==', areaKey)
    }

    return query
}

export function getActivitiesRange(
    firebase: Firebase,
    organizationKey: string,
    from: number,
    to: number,
    areaKey: string | null = null,
    type: ActivityType | null = null
) {
    let query = firebase
        .firestore()
        .collectionGroup('activities')
        .where('organizationKey', '==', organizationKey)
        .where('date', '>=', from)
        .where('date', '<=', to)

    if (areaKey) {
        query = query.where('areaKey', '==', areaKey)
    }
    if (type) {
        query = query.where('type', '==', type)
    }

    return query
}
