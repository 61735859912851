// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from 'react'
import { CleaningStatusClasses } from '../../../AreasUIHelpers'

function getStatusClasses(row) {
    const cleaningStatus = CleaningStatusClasses[row.status]

    if (cleaningStatus) {
        return cleaningStatus
    }
    return { color: 'gray', name: 'Unknown' }
}

export function StatusColumnFormatter(cellContent, row) {
    const getLabelCssClasses = () => {
        return `label label-dot label-${getStatusClasses(row).color}`
    }
    return (
        <div className="d-flex align-items-center">
            <span className={getLabelCssClasses()}></span>
            <div className="ml-2">{getStatusClasses(row).name}</div>
        </div>
    )
}
