import React from 'react'
import { TitleWrap } from './style'
import ArrowIcon from 'app/img/arrow-down-black.svg?react'

interface Props {
    title: string
    count: number
    expanded: boolean
    onArrowClick: () => void
    isSubTitle?: boolean
}

function Title({ title, count, expanded, onArrowClick, isSubTitle = false }: Props) {
    return (
        <TitleWrap expanded={expanded} isSubTitle={isSubTitle}>
            <div>
                <h1>{title}</h1>
                <span>{`(${count})`}</span>
            </div>
            <ArrowIcon onClick={onArrowClick} />
        </TitleWrap>
    )
}

export default Title
