import { Feature } from './feature'

export const FEATURE_GUEST_FLOW = 'guest-flow'

export const ALL_FEATURES = {
    'everything-basic': new Feature({
        title: 'Everything in Basic',
        description: '',
        group: 'Everything in basic',
        display: true,
        permissions: [{ id: 'org-change' as const, description: 'Change organization name' }]
    }),

    issues: new Feature({
        title: 'Issue management',
        description: 'Manage issues by adding hashtags and assigning them to your staff',
        group: 'Issue management',
        display: true,
        permissions: [
            {
                id: 'assign' as const,
                description: 'Assign issues to staff'
            },
            {
                id: 'create' as const,
                description: 'Create issues'
            },
            {
                id: 'complete' as const,
                description: 'Complete issues'
            }
        ]
    }),
    'issues-2': new Feature({
        title: '',
        description: '',
        group: 'Issue management',
        display: false,
        marketingTitle: 'All issues in one place'
    }),
    'issues-3': new Feature({
        title: '',
        description: '',
        group: 'Issue management',
        display: false,
        marketingTitle: 'Create hashtags to categorize issues'
    }),
    'issues-4': new Feature({
        title: '',
        description: '',
        group: 'Issue management',
        display: false,
        marketingTitle: 'Simple sharing to contractors'
    }),
    'mobile-housekeeping-details': new Feature({
        title: 'Mobile housekeeping details',
        description: 'NOT SHOWN ANYWHERE',
        group: 'Advanced housekeeping',
        display: false
    }),
    'assign-housekeeping': new Feature({
        title: 'Assign housekeeping',
        description: 'Assign housekeeping tasks to your staff, and it shows up in their task list',
        group: 'Advanced housekeeping',
        display: true,
        permissions: [
            { id: 'assign' as const, description: 'Assign task to users' },
            { id: 'un-assign' as const, description: 'Un-assign tasks' }
        ]
    }),
    'browse-history': new Feature({
        title: 'Browse your history',
        description: 'Browse your housekeeping history and see what happened in the past',
        group: 'Advanced housekeeping',
        display: false,
        marketingTitle: 'Browse your housekeeping history'
    }),
    'daily-comments': new Feature({
        title: 'Add daily comments',
        description: 'Add daily comments and unit notes to rooms to guide your staff better',
        group: 'Advanced housekeeping',
        display: false,
        marketingTitle: 'Daily comments & unit notes for housekeeping instructions'
    }),
    'unit-notes': new Feature({
        title: 'Add unit notes',
        description: 'Add daily comments and unit notes to rooms to guide your staff better',
        group: 'Advanced housekeeping',
        display: false
    }),
    'complex-rules': new Feature({
        title: 'Unlimited rules',
        description: 'Setup complex housekeeping rules, for example "change the sheets on the 4th day of stay"',
        group: 'Advanced housekeeping',
        display: false,
        marketingTitle: 'Complex cleaning rules'
    }),
    'unlimited-areas': new Feature({
        title: 'Unlimited units',
        description: 'Start managing unlimited areas in your hotel such as the lobby, hallways, etc',
        group: 'Unlimited units',
        display: true
    }),
    'unlimited-areas-2': new Feature({
        title: '',
        description: '',
        group: 'Unlimited units',
        display: false,
        marketingTitle: 'Unlimited other units so you can manage your whole property'
    }),
    'schedule-housekeeping': new Feature({
        title: 'View future workload & assignments',
        description: '',
        group: 'Unlimited units',
        display: false
        // marketingTitle: 'View future workload & assignments'
    }),
    'assign-tasks': new Feature({
        title: 'Assign tasks',
        description: 'Assign tasks to your staff, and it shows up in their task list',
        group: 'Assign & manage tasks',
        display: true,
        permissions: [
            { id: 'start-task' as const, description: 'Start tasks' },
            { id: 'stop-task' as const, description: 'Stop tasks' },
            { id: 'complete-inspection' as const, description: 'Complete inspection' }
        ]
    }),
    'tasks-2': new Feature({
        title: 'Manage tasks',
        description: '',
        group: 'Assign & manage tasks',
        display: true
    }),
    'tasks-3': new Feature({
        title: '',
        description: '',
        group: 'Assign & manage tasks',
        display: false,
        marketingTitle: 'Assign issues to staff'
    }),
    'tasks-4': new Feature({
        title: '',
        description: '',
        group: 'Assign & manage tasks',
        display: false,
        marketingTitle: 'Browse tasks history'
    }),
    'tasks-5': new Feature({
        title: '',
        description: '',
        group: 'Assign & manage tasks',
        display: false,
        marketingTitle: 'Auto-assign to maintenance role'
    }),
    'user-access-control': new Feature({
        title: 'User access control',
        description: 'Control which rooms & areas your staff can see',
        group: 'User access control',
        display: true,
        permissions: [
            { id: 'create-user' as const, description: 'Create users' },
            { id: 'remove-user' as const, description: 'Remove users' }
        ]
    }),
    'housekeeping-reports': new Feature({
        title: 'Housekeeping reports',
        path: '/reports/housekeeping',
        description: 'Track staff performance with our detailed housekeeping reports',
        group: 'Advanced housekeeping',
        display: false
    }),
    'issue-reports': new Feature({
        title: 'Reports for insights & planning',
        path: '/reports/issues',
        description: 'Track your issue creation with our issue reports',
        group: 'Issue management',
        display: false
    }),
    'workload-reports': new Feature({
        title: 'Report for workload & planning',
        path: '/reports/issues',
        description: '',
        group: 'Advanced housekeeping',
        display: false
    }),
    'unlimited-users': new Feature({
        title: 'Unlimited users',
        description: '',
        group: 'Everyone can join',
        display: true
    }),
    'real-time-room-status': new Feature({
        title: 'Real-time room status & workload',
        description: '',
        group: 'Housekeeping dashboard',
        display: false
    }),
    'booking-data-updated-from-godo-property': new Feature({
        title: '2-way PMS syncing',
        description: '',
        group: 'Housekeeping dashboard',
        display: true
    }),
    'simple-cleaning-rules': new Feature({
        title: 'Housekeeping overview',
        description: '',
        group: 'Housekeeping dashboard',
        display: true
    }),
    'ios-android-apps': new Feature({
        title: 'Web, iOS & Android',
        description: '',
        group: 'All platforms',
        display: true
    }),
    'web-app': new Feature({
        title: 'Web app for desktop computers',
        description: '',
        group: 'All platforms',
        display: false
    }),
    'basic-housekeeping': new Feature({
        title: 'Housekeeping overview',
        description: '',
        group: 'Housekeeping dashboard',
        display: true,
        permissions: [{ id: 'inspect' as const, description: 'Perform inspection' }]
    }),
    'start-stop-housekeeping': new Feature({
        title: 'Start/stop housekeeping',
        description: '',
        group: 'Housekeeping dashboard',
        display: true
    }),

    'start-stop-housekeeping-2': new Feature({
        title: '2 rules for units and spaces',
        description: '',
        group: 'Housekeeping dashboard',
        display: true
    }),
    checklists: new Feature({
        title: 'Checklists',
        description: '',
        group: 'Advanced housekeeping',
        display: false
    })
}

export const ALL_FEATURES_IDS = Object.keys(ALL_FEATURES) as (keyof typeof ALL_FEATURES)[]
