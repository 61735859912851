import React from 'react'
import styled from 'styled-components'
import { Pagination } from '../../../_metronic/_partials/controls'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { paginationAtom, thisPageCheckedAtom, checkedIssuesAtom } from '../state/atoms'
import IssuesList from './IssuesList'
import Spinner from '../../components/Spinner'
import { getPaginationOptions } from '../../../_metronic/_partials/controls/pagination/pagination-helpers'
import withErrorCatching from '../../utils/hocs/withErrorCatching'
import { useIssues } from '../state/hooks/useIssues'
import { useAreas } from '../state/hooks/useAreas'
import { useUsers } from '../state/hooks/useUsers'
import { IssuesBodyWrapper, LoaderWrapper } from './../style'

//TODO - move to contants
const sizePerPageList = [
    { text: '2', value: 2 },
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '100', value: 100 },
    { text: '250', value: 250 }
]

function IssuesBody() {
    const { paginatedIssues, filteredIssues, isLoading: issuesLoading } = useIssues()
    const { isLoading: areasLoading } = useAreas()
    const { isLoading: usersLoading } = useUsers()

    const loading = areasLoading || usersLoading || issuesLoading
    const [pagination, setPagination] = useRecoilState(paginationAtom)
    const setCheckedIssues = useSetRecoilState(checkedIssuesAtom)
    const [thisPageChecked, setThisPageChecked] = useRecoilState(thisPageCheckedAtom)

    const handlePageChange = (page: number) => {
        if (thisPageChecked) {
            setThisPageChecked(false)
            setCheckedIssues([])
        }
        setPagination({ ...pagination, page })
    }

    const paginationOptions =
        filteredIssues.length &&
        getPaginationOptions(filteredIssues.length, pagination.limit, sizePerPageList, pagination.page, handlePageChange)

    return (
        <IssuesBodyWrapper>
            {loading ? (
                <LoaderWrapper>
                    <Spinner />
                </LoaderWrapper>
            ) : (
                <Pagination isLoading={true} paginationProps={paginationOptions}>
                    <IssuesList issues={paginatedIssues} />
                </Pagination>
            )}
        </IssuesBodyWrapper>
    )
}

export default withErrorCatching(IssuesBody)
