import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { UsersContext, User } from '../../UsersContext'
import firebase, { db, fetchAreaGroups } from '../../../../utils/firebase'
import moment from 'moment-timezone'
import { capitalize } from 'lodash'
import { AuthContext } from '../../../Auth/AuthContext'

import { View, Image } from 'react-native-web'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'
import { Initials } from '../../../../components/initials'

export function UserDialog({ id, show, onHide }) {
    const { setUserForEdit, actionsLoading, setActionsLoading, initUser, setAreaGroups } = useContext(UsersContext)
    const { currentUser } = useContext(AuthContext)
    const [isAdmin, setIsAdmin] = useState(false)
    const [showCleaningTaskNote, setShowCleaningTaskNote] = useState(false)
    const [user, setUser] = useState(initUser)
    useEffect(() => {
        setActionsLoading(true)
        const getUser = async () => {
            if (id) {
                const userRef = await db.collection('users').doc(id).get()

                const user = userRef.data()
                user.isAdmin = user.authRole === 'admin'

                setIsAdmin(user.isAdmin)
                setShowCleaningTaskNote(user.showCleaningTaskNote)

                setUserForEdit(user)
                setUser(user)
            }
            let allAreaGroups = await fetchAreaGroups(firebase, currentUser)
            allAreaGroups.unshift('All')
            setAreaGroups(allAreaGroups)
            setActionsLoading(false)
        }
        getUser(id)
        return () => {
            setUserForEdit(new User())
        }
    }, [id, setActionsLoading])

    const opacity = 1
    return (
        <Modal show={show} size="lg" onHide={onHide} centered={true}>
            <div className="modal-header d-flex justify-content-end border-0">
                <button type="button" className="close" onClick={onHide}>
                    <span aria-hidden="true">
                        <i className="specta-close icon-md"></i>
                    </span>
                </button>
            </div>
            <Modal.Body className="d-flex flex-center" style={{ minHeight: 150 }}>
                {actionsLoading && <div className="spinner spinner-specta-yellow spinner-xl spinner-center"></div>}
                {!actionsLoading && (
                    <div className="container">
                        <div className="row pl-5 pr-10 pt-2 pb-10">
                            <div className="col-md-1">
                                <Initials
                                    disabled={true}
                                    item={{ initials: user.initials }}
                                    currentUser={currentUser}
                                    dimension={40}
                                    fontSize={14}
                                    noMargin={true}
                                    toolTipDisabled={true}
                                />
                            </div>
                            {/* <div style={{ paddingLeft: 26, width: '100%' }}> */}
                            <div className="col" style={{ width: '100%' }}>
                                <View style={{ justifyContent: 'center' }}>
                                    <span
                                        style={{
                                            fontSize: 17,
                                            fontWeight: '500',
                                            color: colors.textPrimary
                                        }}>
                                        <span>{user.name}</span>
                                    </span>
                                </View>
                                <View style={{ marginTop: 4, justifyContent: 'center' }}>
                                    <span
                                        style={{
                                            color: iOSColors.gray,
                                            fontSize: 15,
                                            fontWeight: '500'
                                        }}>
                                        {user.phoneNumber}
                                    </span>
                                </View>
                                <View style={{ marginTop: 4, justifyContent: 'center' }}>
                                    <span
                                        style={{
                                            color: user.email ? iOSColors.gray : iOSColors.lightGray2,
                                            fontSize: 15,
                                            fontWeight: '400'
                                        }}>
                                        {user.email ? user.email : 'No email specified'}
                                    </span>
                                </View>
                                <View style={{ marginTop: 16, justifyContent: 'center' }}>
                                    <span
                                        style={{
                                            color: '#333333',
                                            fontSize: 15,
                                            fontWeight: '500'
                                        }}>
                                        Area groups
                                    </span>
                                    <View style={{ marginTop: 0, lineHeight: '24px' }}>{renderAreaGroups(user.areaGroups)}</View>
                                </View>
                                <hr className="my-3 bg-gray-100 border-light" />
                                {/* <View style={{ marginVertical: 16, height: 1, backgroundColor: separtion_line_color }} /> */}
                                <View style={{ lineHeight: 19, marginBottom: 6, justifyContent: 'center' }}>
                                    <span
                                        style={{
                                            color: iOSColors.gray,
                                            fontSize: 11,
                                            fontWeight: '400'
                                        }}>
                                        <span style={{ fontWeight: 700 }}>App login: </span>
                                        {user.lastWebAppLogin
                                            ? capitalize(moment(user.lastLogin).fromNow()) +
                                              ', with ' +
                                              capitalize(user.deviceBrand) +
                                              ' ' +
                                              user.deviceId
                                            : 'Never logged into app'}
                                        .
                                    </span>
                                </View>

                                <View style={{ marginBottom: 6, justifyContent: 'center' }}>
                                    <span
                                        style={{
                                            color: iOSColors.gray,
                                            fontSize: 11,
                                            fontWeight: '400'
                                        }}>
                                        <span style={{ fontWeight: 700 }}>Web login: </span>
                                        {user.lastWebAppLogin
                                            ? capitalize(moment(user.lastWebAppLogin.date).fromNow()) +
                                              ', with ' +
                                              user.lastWebAppLogin.browser.name +
                                              ' version ' +
                                              user.lastWebAppLogin.browser.fullVersion
                                            : 'Never logged into web app'}
                                        .
                                    </span>
                                </View>
                                <View style={{ justifyContent: 'center' }}>
                                    <span
                                        style={{
                                            color: iOSColors.gray,
                                            fontSize: 11,
                                            fontWeight: '400'
                                        }}>
                                        <span style={{ fontWeight: 700 }}>Issues created: </span>
                                        {user.issueCounter ? user.issueCounter : 0}
                                    </span>
                                </View>
                            </div>
                            <View style={{ borderWidth: 0 }}>
                                {isAdmin && (
                                    <View
                                        style={{
                                            borderWidth: 0,
                                            alignItems: 'center',
                                            justifyContent: 'center',

                                            height: 30,
                                            width: 30
                                        }}>
                                        <Image
                                            style={{
                                                height: 18.66,
                                                width: 24.88,
                                                opacity: opacity
                                            }}
                                            source={require('@shared/img/admin.svg')}
                                        />
                                    </View>
                                )}
                            </View>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}

const renderAreaGroups = areaGroups => {
    return (
        <div>
            {areaGroups.map((agroup, index) => {
                const isLast = index === areaGroups.length - 1
                return (
                    <span
                        key={agroup}
                        style={{
                            height: '30px',
                            marginRight: isLast ? 0 : 9,
                            flexWrap: 'wrap',
                            color: colors.blue_spectaflow,
                            fontSize: 13,
                            fontWeight: '600'
                        }}>
                        {agroup + (isLast ? '' : ',')}
                    </span>
                )
            })}
        </div>
    )
}
