import React from 'react'
import { useRecoilValue } from 'recoil'
import { asideMenuIsOpen } from '../../../_metronic/layout/components/aside/state'
import { useIsMobile } from '../hooks/useIsMobile'

const withAsideMenuToggleFlag = WrappedComponent => {
    const WithAsideMenuToggleFlag = props => {
        const asideIsOpen = useRecoilValue(asideMenuIsOpen)
        const isMobile = useIsMobile()

        return <WrappedComponent {...props} asideIsOpen={asideIsOpen} isMobile={isMobile} />
    }

    return WithAsideMenuToggleFlag
}

export default withAsideMenuToggleFlag
