import React, { useMemo } from 'react'
import objectPath from 'object-path'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import { HeaderMenu } from './GuestsHeaderMenu'
// import { Topbar } from './Topbar'
// import { HeaderMenuWrapper } from './header-menu/HeaderMenuWrapper'
// import { AnimateLoading } from '../../../_partials/controls'

export function Header({ guest }) {
    const uiService = useHtmlClassService()

    const layoutProps = useMemo(() => {
        return {
            headerClasses: uiService.getClasses('header', true),
            headerAttributes: uiService.getAttributes('header'),
            headerContainerClasses: uiService.getClasses('header_container', true),
            menuHeaderDisplay: true
        }
    }, [uiService])

    const getHeaderLogo = () => {
        // let result = 'logo-light.png'
        // if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== 'dark') {
        //     result = 'logo-dark.png'
        // }
        let logo = brand.getNavBarAuthLogo()
        return logo
        // return require(toAbsoluteUrl(`/media/logos/${result}`))
    }

    return (
        <>
            {/*begin::Header*/}
            <div
                className={`header header-fixed-lg`}
                id="kt_header"
                {...layoutProps.headerAttributes}
                style={{ backgroundColor: '#2D2D2D' }}
                // style={{ height: 115 }}
            >
                {/*begin::Container*/}
                <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}>
                    {/*begin::Header Menu Wrapper*/}
                    {layoutProps.menuHeaderDisplay && (
                        <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
                            <div className="header-logo">
                                <div className="d-flex flex-column">
                                    {guest && guest.organization && (
                                        <>
                                            <div className="display5 font-weight-boldest text-white">{guest.organization.name}</div>
                                            <div className="font-size-sm font-weight-bold text-white mt-n3">Guest services</div>
                                        </>
                                    )}
                                    {guest && guest.area && (
                                        <div className="font-size-h3 font-weight-bolder text-white">{guest.area.name}</div>
                                    )}
                                </div>
                            </div>
                            {layoutProps.disabledAsideSelfDisplay && (
                                <>
                                    {/*begin::Header Logo*/}
                                    <div className="header-logo">
                                        <Link to="/">
                                            <img alt="logo" src={getHeaderLogo().img} style={getHeaderLogo().style} />
                                        </Link>
                                    </div>
                                    {/*end::Header Logo*/}
                                </>
                            )}

                            {/*begin::Header Menu*/}
                            <HeaderMenu layoutProps={layoutProps} />
                            {/*end::Header Menu*/}
                        </div>
                    )}
                    {!layoutProps.menuHeaderDisplay && <div />}
                    {/*end::Header Menu Wrapper*/}

                    {/*begin::Topbar*/}
                    <div className="topbar"></div>
                    {/* <Topbar /> */}
                    {/*end::Topbar*/}
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Header*/}
        </>
    )
}
