import styled from 'styled-components'
import { iOSColors } from '@shared/react-native-typography'

export const AdditionalInfo = styled.div`
    margin-top: 19px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding-left: 6px;

    > span {
        font-weight: 400;
        color: ${iOSColors.gray};
        font-size: 1.154rem;
    }

    > span:first-child {
        font-weight: 500;
        color: ${iOSColors.black};
    }
`
