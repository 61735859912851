import styled from 'styled-components'
import * as colors from '@shared/colors'
import { MOBILE_BREAKPOINT, SMALL_DESKTOP_SCREEN_HEIGHT } from '../../utils/constants'

const gradient = colors.gradient_overlay
const white = colors.white

export const ModalOverlay = styled.div<{ basic?: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${({ basic }) => (basic ? 'rgba(0, 0, 0, 0.2)' : gradient)};
    backdrop-filter: ${({ basic }) => (basic ? 'none' : 'blur(10px)')};
    z-index: 1100;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ModalContainer = styled.div<{ width?: string; height?: string; padding?: string; basic?: boolean; overflowY?: string }>`
    width: ${props => props.width || '66%'};
    height: ${props => props.height || '718px'};
    max-height: ${({ basic }) => (basic ? 'none' : '900px')};
    background-color: ${white};
    border-radius: 8px;
    padding: ${props => props.padding || '40px'};
    z-index: 3;
    position: absolute;
    overflow-y: ${({ overflowY }) => overflowY || 'scroll'};

    > img {
        width: 62.73px !important;
        height: 32.75px !important;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        height: 100%;
        padding: 54px 40px;

        .text-container {
            display: flex;
            width: 100%;
            justify-content: center;
        }

        > img {
            width: 128px !important;
            height: 23.46px !important;
        }
    }

    @media (max-height: ${SMALL_DESKTOP_SCREEN_HEIGHT}px) {
        height: 80%;
        overflow-y: scroll;
    }
`

export const ContentWrap = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: block;
        width: 100%;
    }
`

export const upgradeMessageStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        borderRadius: 6,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 320,
        height: 430,
        overflow: 'scroll',
        borderWidth: 0
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0,.1)',
        zIndex: 10005
    }
}
