import React from 'react'
import { getBrand } from '@shared/brand'

const Mouth = () => {
    const brandColor = getBrand().navBarColor

    return (
        <svg width="119" height="49" viewBox="0 0 119 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M113.087 43.2804C113.087 43.2804 85.0357 15.0585 55.9613 7.26805C26.887 -0.522405 5.00006 14.3185 5.00006 14.3185"
                stroke={brandColor}
                strokeWidth="10"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default Mouth
