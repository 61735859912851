import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
// import GuestInformation from "./GuestInformation";
// import { ProfileOverview } from "./ProfileOverview";
// import ChangePassword from "./ChangePassword";
// import PersonaInformation from "./PersonaInformation";
// import EmailSettings from "./EmailSettings";
import { GuestsCard } from './GuestsCard'
import { CleaningSchedule } from './CleaningSchedule'
import { GuestsContext, GuestsProvider } from '../GuestsContext'
import { ActivityIndicator, Image } from 'react-native-web'
import { getBrand } from '@shared/brand'
import firebase from '../../../../utils/firebase'
import { Header } from '../../../../../_metronic/layout/components/Guests/GuestsHeader'
import { HeaderMobile } from '../../../../../_metronic/layout/components/Guests/GuestsHeaderMobile'
import { LayoutInit } from '../../../../../_metronic/layout/components/LayoutInit'
import { httpsCallable } from '../../../../api'

function GuestsPageWrapper(props) {
    const { setShowAlert, dataLoading, setDataLoading, setGuestData, guestData, setError, error } = useContext(GuestsContext)

    useEffect(() => {
        const getGuestData = async bookingId => {
            const guestData = httpsCallable('getGuestData')
            guestData({ bookingId })
                .then(data => {
                    setGuestData(data.data)
                    setDataLoading(false)
                })
                .catch(e => {
                    console.log(e.message)
                    setError(e.message)
                    setGuestData(e.details)
                    setDataLoading(false)
                })
        }

        getGuestData(props.match.params.bookingId)
        return () => {}
    }, [props.match.params.bookingId])

    document.body.classList.remove('aside-fixed')
    document.body.classList.remove('aside-enabled')
    document.body.classList.remove('aside-minimize-hoverable')

    return dataLoading ? (
        <div
            style={{
                position: 'absolute',
                right: '44%',
                top: '40%',
                height: 50,
                width: 50,
                zIndex: 10000
            }}>
            <ActivityIndicator size="large" color={getBrand().navBarColor} style={{ right: '50%', top: '50%' }} />
        </div>
    ) : (
        <>
            <HeaderMobile guest={guestData} />
            <div className="d-flex flex-column flex-root">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper" style={{ paddingTop: 100 }}>
                        <Header guest={guestData} />
                        {/*begin::Content*/}
                        <div id="kt_content" className={`content d-flex flex-column flex-column-fluid`}>
                            <div className="d-flex flex-column-fluid">
                                {/*begin::Container*/}
                                <div className="container">
                                    {error && (
                                        <div className="row justify-content-center">
                                            <div className="px-15 mt-40">
                                                <div className="d-flex flex-center">
                                                    <Image
                                                        style={{ width: '20rem', height: '20rem' }}
                                                        source={require('../../../../img/important-event.svg')}
                                                    />
                                                </div>
                                                <div className="font-size-lg text-center">
                                                    <>
                                                        <div className="font-size-h2 text-center">{error}.</div>
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!error && (
                                        <div className="row">
                                            <GuestsCard></GuestsCard>
                                            <div className="col-12 col-lg-8">
                                                <Switch>
                                                    <Redirect
                                                        from="/experience/:bookingId"
                                                        exact={true}
                                                        to="/experience/:bookingId/housekeeping"
                                                    />
                                                    <Route path="/experience/:bookingId/housekeeping" component={CleaningSchedule} />
                                                </Switch>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/*end::Container*/}
                            </div>

                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        {/* <Footer {...props} /> */}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            <LayoutInit />
        </>
    )
}

export default function GuestsPage(props) {
    return (
        <GuestsProvider guestsUIEvents={{}}>
            {/* <div className="row"> */}
            <GuestsPageWrapper {...props} />
            {/* </div> */}
        </GuestsProvider>
    )
}
