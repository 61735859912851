import { selector } from 'recoil'
import {
    dateFilterType,
    reportsData,
    reportsTaskTypes,
    reportTableCleaningSummaryHeaders,
    reportTableDateHeaders,
    reportTablePeriodHeaders
} from './atoms.js'
import moment from 'moment-timezone'
import {
    formatMonthDayYear,
    formatTimeHoursAndMinutes,
    hours,
    millisecondsAsHours,
    millisecondsAsMinutes,
    minutes
} from '../../../../utils/formatters'
import { sortTimeStampAscending } from '@shared/helpers'

const reportSummaryByCleaningType = selector({
    key: 'reportSummaryByCleaningType',
    get: ({ get }) => {
        const taskTypes = get(reportsTaskTypes)

        return taskTypes.map(task => {
            const { type, data } = task

            const areasCleaned = data.length
            const time =
                data.reduce(function (a, b) {
                    return a + (b.cleaningTime - b.pauses)
                }, 0) / areasCleaned
            const averageTime = hours(time) > 0 ? hours(time) + 'h ' : null + minutes(time) + 'm '

            return { type, areasCleaned, averageTime }
        })
    }
})

export const reportTableData = selector({
    key: 'reportTableData',
    get: ({ get }) => {
        const reports = get(reportsData)
        const dateFilter = get(dateFilterType)
        const summaryByCleaningType = get(reportSummaryByCleaningType)

        const dateFilterCondition = dateFilter === 'date'

        const userRows = reports.map(report => {
            const {
                name,
                tasks,
                taskTypes,
                totalCleaningTime,
                firstCleaningTime,
                lastCleaningTime,
                pauses,
                totalIdleTime,
                totalTime
            } = report

            const user = name
            const areasCleaned = tasks.length
            const averageTime = Math.ceil(totalCleaningTime / 1000 / 60 / areasCleaned)
            const start = formatTimeHoursAndMinutes(firstCleaningTime)

            const end = formatTimeHoursAndMinutes(lastCleaningTime)
            const paused = hours(pauses) ? hours(pauses) + 'h ' + minutes(pauses) + 'm' : '0m'
            const activeTime = millisecondsAsHours(totalCleaningTime) + 'h ' + millisecondsAsMinutes(totalCleaningTime) + 'm'
            const idleTime = moment.duration(totalIdleTime, 'milliseconds').hours() + 'h ' + millisecondsAsMinutes(totalIdleTime) + 'm'
            const _totalTime = millisecondsAsHours(totalTime) + 'h ' + millisecondsAsMinutes(totalTime) + 'm'

            const reportTasks = [...tasks]
                .sort((a, b) => sortTimeStampAscending(a.cleaning.start, b.cleaning.start))
                .map(taskItem => {
                    const {
                        area,
                        name,
                        cleaningTime,
                        cleaning: { start, end },
                        pauses
                    } = taskItem

                    const date = formatMonthDayYear(start)
                    const duration = hours(cleaningTime) > 0 ? hours(cleaningTime) + 'h ' : null + minutes(cleaningTime) + 'm'
                    const _start = formatTimeHoursAndMinutes(start)
                    const _end = formatTimeHoursAndMinutes(end)
                    const paused = hours(pauses) > 0 ? hours(pauses) + 'h ' : null + minutes(pauses) + 'm'

                    return { area, task: name, date, duration, start: _start, end: _end, paused }
                })

            const summaryByUser = taskTypes.map(task => {
                const { type, data, totalTime } = task

                const count = data.length
                const time = totalTime / count
                const averageTime = hours(time) > 0 ? hours(time) + 'h ' : null + minutes(time) + 'm'

                return { type, count, averageTime }
            })

            const dateTableData = {
                user,
                areasCleaned,
                averageTime,
                start,
                end,
                paused,
                activeTime,
                idleTime,
                totalTime: _totalTime,
                reportTasks
            }
            const periodTableData = { user, areasCleaned, averageTime, activeTime, summaryByUser }

            return dateFilterCondition ? dateTableData : periodTableData
        })

        const returnTableData = () => {
            if (!dateFilterCondition) {
                return { userRows, summaryByCleaningType }
            }
            return { userRows }
        }

        return returnTableData()
    }
})

export const exportReportData = selector({
    key: 'exportReportData',
    get: ({ get }) => {
        const tableData = get(reportTableData)
        const dateFilter = get(dateFilterType)
        const dateHeaders = get(reportTableDateHeaders)
        const cleaningSummaryHeaders = get(reportTableCleaningSummaryHeaders)
        const periodHeaders = get(reportTablePeriodHeaders)

        const { userRows, summaryByCleaningType } = tableData

        const headers = dateFilter === 'date' ? dateHeaders : periodHeaders
        const convertToArrOfArrays = arrOfObj => arrOfObj.map(row => Object.values(row))

        const userRowsArray = convertToArrOfArrays(userRows).map(row => row.filter(item => typeof item !== 'object'))

        const summaryByCleaningTypeArray = summaryByCleaningType && convertToArrOfArrays(summaryByCleaningType)

        return summaryByCleaningTypeArray
            ? [headers, ...userRowsArray, [], ['Summary by cleaning type'], cleaningSummaryHeaders, ...summaryByCleaningTypeArray]
            : [headers, ...userRowsArray]
    }
})
