import React from 'react'

import Tippy from '@tippyjs/react'
import { TOOLTIP_DELAY } from '../tooltips/tooltip-helpers'

export function Tooltip({ children, text, position }) {
    if (text) {
        return (
            <Tippy placement={position || 'top'} delay={TOOLTIP_DELAY} content={getTooltopView([text])}>
                {children}
            </Tippy>
        )
    }
    return children
}

const getTooltopView = textArr => {
    return (
        <div className="bg-specta-black-o-70 rounded-lg align-items-center px-3 py-2">
            {textArr.map(text => {
                return (
                    <span key={Math.random()} className="font-weight-bolder font-size-sm text-white">
                        {text}
                    </span>
                )
            })}
        </div>
    )
}
