import React, { useState } from 'react'
import firebase from '../utils/firebase'
import * as areaData from '@shared/area-data'
import * as dataObjects from '@shared/dataObjects'
import moment from 'moment-timezone'
import ContentEditable from '../components/content-editable'
import { htmlToMarkdown, htmlToString, markdownToHtml } from '@shared/markdown-helper'

const AreaNoteTextInput = props => {
    const [textInputValue, setTextInputValue] = useState(props.area.note ?? '')
    const [unitNote, setUnitNote] = useState(props.area.note ?? '')
    const { disabled } = props

    const onTextChange = value => {
        setTextInputValue(value)
    }

    const onSubmit = () => {
        const trimmedValue = textInputValue.trim()
        if (props.area.note !== trimmedValue) {
            const changeObj = {
                before: props.area.note || '',
                after: htmlToString(trimmedValue)
            }

            if (htmlToString(trimmedValue) !== htmlToString(unitNote)) {
                setUnitNote(trimmedValue)
                areaData.logActivity(firebase, props.currentUser, props.area.key, 'area-note', props.selectedDateNumber, changeObj)

                const areaObject = {}
                areaObject.lastStatusChange = {
                    user: dataObjects.getMiniUserObject(props.currentUser),
                    updated: moment().valueOf()
                }
                areaObject.note = htmlToMarkdown(trimmedValue)

                areaData.updateArea(firebase, props.area.key, areaObject, props.currentUser)
            }
        }
    }

    return (
        <ContentEditable
            showIcon
            disabled={disabled}
            placeholder={'Click to add a note'}
            content={markdownToHtml(disabled ? '' : textInputValue)}
            onChange={onTextChange}
            onSubmit={onSubmit}
        />
    )
}

export default AreaNoteTextInput
