import React, { useContext, useEffect, useState } from 'react'

import useProjectionFirestore from './useProjectionFirestore'
import Loader from '../../components/loaders/Loader'
import ProjectionsAreaList from './projections-area-list'
import { Card, CardBody, CardHeader, CardHeaderCounter } from '../../components/controls/Card'
import Header from './header'
import Notifications from 'react-notify-toast'
import { DashboardContext } from './DashboardContext'
import ModalPage from './modals'
import ActionBar from './action-bar'
import DashboardFilter from '../dashboard/dashboard-filter/DashboardFilter'
import moment from 'moment-timezone'
import { isToday } from '@shared/cleaning-helpers'
import { AuthContext } from 'app/modules/Auth/AuthContext'
import AreaGroupsFilter from './area-groups-filter'
import ProjectionsDashboardFilter from './projection-dashboard-filter'
import NoUnits from './no-units'

const ProjectionsView = () => {
    const { areaModalIsOpen, setAllAreas, allAreas, componentControlsDisplay } = useContext(DashboardContext)
    const { areasProjections, isDataReady, isLoading, refresh } = useProjectionFirestore()

    useEffect(() => {
        if (!componentControlsDisplay) {
            setAllAreas(areasProjections)
        }
    }, [areasProjections])

    useEffect(() => {
        console.log('allAreas update')
    }, [allAreas])

    return (
        <Card className="card-no-shadow">
            <Header areas={allAreas} />
            {!areaModalIsOpen && <Notifications options={{ animationDuration: 1000, top: '10%' }} />}
            <ModalPage areas={allAreas}></ModalPage>
            <ActionBar loading={!isDataReady} areas={allAreas} />
            <CardBody className="p-xs-10">
                <ProjectionsDashboardFilter areas={allAreas} />
                <AreaGroupsFilter areas={allAreas} />
                {!isDataReady && <Loader />}
                <NoUnits isDataReady={isDataReady} areas={allAreas} />
                <ProjectionsAreaList areas={allAreas} />
            </CardBody>
        </Card>
    )
}

export default ProjectionsView
