import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../components/controls/Card'
import { ReportsFilterAndExportWrap } from '../housekeeping-report/ReportsCard.style'
import { ReportsFilter } from '../reports-filter/ReportsFilter'
import React, { useContext } from 'react'
import { WorkloadTable } from './WorkloadTable'
import { FilterType } from '../reports-filter/types'
import { formatMonthDayYear } from '../../../../utils/formatters'
import { useRecoilValue } from 'recoil'
import { exportReportData } from './selectors'
import { ReportsContext } from '../../ReportsContext'
import { CSVLink } from 'react-csv'
import MainActionButton from '../../../../components/buttons/MainActionButton'
import { FilterRow, LeftColumn } from '../reports-filter/style'

const dateFilters: FilterType['filter']['dateFilter'][] = ['Today', 'Yesterday', 'This month', 'Next week']
export default function WorkloadCard() {
    const exportData = useRecoilValue(exportReportData)

    // console.log(exportData)

    const {
        queryParams: {
            filter: { dateFilterType, startDate, endDate }
        }
    } = useContext(ReportsContext)
    const createFileName = () => {
        const periodOrDate = dateFilterType === 'date' ? 'Date' : 'Period'
        const date = formatMonthDayYear(startDate)
        const periodDate = formatMonthDayYear(startDate) + ' - ' + formatMonthDayYear(endDate)
        const dateCondition = dateFilterType === 'date' ? date : periodDate
        const filename = `${periodOrDate} Report (${dateCondition})`

        return filename
    }

    const filename = createFileName()

    return (
        <Card className="card-no-shadow">
            <CardHeader title="Workload report">
                <CardHeaderToolbar></CardHeaderToolbar>
            </CardHeader>

            <CardBody>
                <ReportsFilter dateFilter={dateFilters} defaultDateFilter={'This month'}>
                    <CSVLink data={exportData} filename={filename}>
                        <MainActionButton size="large" type="primary">
                            {'Export to CSV'}
                        </MainActionButton>
                    </CSVLink>
                </ReportsFilter>

                <WorkloadTable />
            </CardBody>
        </Card>
    )
}
