import React from 'react'
import { capitalize } from 'lodash'
import { ActionButtonContainer, ButtonText } from '../style'
import * as colors from '@shared/colors'

const ActionButton = ({ text, onClick, loading }) => {
    return (
        <ActionButtonContainer type="button" className=" d-flex align-items-center  font-weight-bolder " onClick={onClick}>
            {!loading ? (
                <ButtonText color={colors.main_red}>{text}</ButtonText>
            ) : (
                <span>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                </span>
            )}
        </ActionButtonContainer>
    )
}

export default ActionButton
