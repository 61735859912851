import firebase from '../utils/firebase'
import { IntegrationParams, TaskboardContext } from '@shared/traces-types'
import * as c from '@shared/constants'
import { TaskStruct } from '@shared/firestore-structs'

interface QueryParams {
    organizationKey: string
    date: number
    currentTasksTypes?: TaskStruct['type'][]
}

export function getAreasQuery(
    organizationKey: string,
    context: TaskboardContext,
    integrationParams: IntegrationParams<TaskboardContext.PROPERTY | TaskboardContext.RESERVATIONS> | null,
    accessedGroups: string[]
) {
    if (context === TaskboardContext.RESERVATIONS) return null

    let query = firebase
        .firestore()
        .collection('areas')
        .where('organizationKey', '==', organizationKey)
        .where('visible', '==', true)
        .where('displayCleaningStatus', '==', true)

    if (!accessedGroups.includes(c.AREA_GROUPS_ALL) && accessedGroups.length > 0) {
        query = query.where('group', 'in', accessedGroups)
    }

    if (context === TaskboardContext.PROPERTY && integrationParams?.propKey && integrationParams?.propKey.length > 0) {
        return query.where('propKey', '==', integrationParams.propKey)
    }

    return query
}

export function getActivitiesQuery({ organizationKey, date }: QueryParams) {
    return firebase.firestore().collectionGroup('activities').where('organizationKey', '==', organizationKey).where('date', '==', date)
}

export function getBookingsQuery({ organizationKey, date }: QueryParams) {
    return firebase
        .firestore()
        .collection('bookings')
        .where('organizationKey', '==', organizationKey)
        .where('bookingDates', 'array-contains', date.toString())
}

export function getDailyCommentsQuery({ organizationKey, date }: QueryParams) {
    return firebase.firestore().collectionGroup('dailyComments').where('organizationKey', '==', organizationKey).where('date', '==', date)
}

export function getRulesQuery(organizationKey: string) {
    return firebase.firestore().collection('rules').where('organizationKey', '==', organizationKey).where('visible', '==', true)
}

export function getUsersQuery(organizationKey: string) {
    return firebase.firestore().collection('users').where('organizationKey', '==', organizationKey).where('visible', '==', true)
}
