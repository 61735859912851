import React from 'react'
import { NavLink } from 'react-router-dom'

export function HeaderMenu({ layoutProps }) {
    return (
        <div
            id="kt_header_menu"
            className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
            {...layoutProps.headerMenuAttributes}>
            {/*begin::Header Nav*/}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <li className={`menu-item menu-item-rel`}>
                    <NavLink className="menu-link" to={'/fuubar'}>
                        <span className={`menu-text font-size-base font-weight-bolder`}></span>
                    </NavLink>
                </li>
            </ul>
            {/*end::Header Nav*/}
        </div>
    )
}
