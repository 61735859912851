import { useEffect, useState } from 'react'
import { MOBILE_BREAKPOINT } from '../constants'
import { detectDevice } from '../helpers'
import * as us from 'app/utils/constants'

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(MOBILE_BREAKPOINT >= window.innerWidth && detectDevice(window) !== us.DEVICES.DESKTOP)
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return isMobile
}
