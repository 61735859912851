import React from 'react'
import { Image, ImageSourcePropType } from 'react-native'

interface Props {
    height?: number
    width?: number
    iconScale?: number
    source: ImageSourcePropType
}

function BoxIcon({ height = 20, width = 20, iconScale, source }: Props) {
    return (
        <Image
            style={{
                height: iconScale ? height * iconScale : height,
                width: iconScale ? width * iconScale : width
            }}
            source={source}
        />
    )
}

export default BoxIcon
