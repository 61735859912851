import React from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { subscriptionParamsAtom, upgradeMessageModalIsOpenAtom, filtersAtom, paginationAtom } from '../state/atoms'
import AsyncStorage from '@react-native-community/async-storage'
import { IconButton } from '../../components/buttons/IconButton'
import StatusFilterPopup from '../status-filter-popup'
import * as c from '@shared/constants'
import * as uc from '../../utils/constants'
import * as helpers from '@shared/helpers'
import { FiltersParams, PaginationParams, StatusOption, SubscriptionParams } from '../types'
import { FiltersContainer } from './style'

const defaultStatusFilter: StatusOption[] = [
    { label: helpers.capitalizeFirstLetter(c.ISSUE_OPEN), value: c.ISSUE_OPEN, isSelected: true },
    { label: helpers.capitalizeFirstLetter(c.ISSUE_ASSIGNED), value: c.ISSUE_ASSIGNED, isSelected: true },
    { label: helpers.capitalizeFirstLetter(c.ISSUE_COMPLETE), value: c.ISSUE_COMPLETE, isSelected: false },
    { label: helpers.capitalizeFirstLetter(c.ISSUE_DELETED), value: c.ISSUE_DELETED, isSelected: false },
    { label: 'Reset', value: 'reset' }
]

export default function Filters() {
    const { hasSubscriptionAccess } = useRecoilValue<SubscriptionParams>(subscriptionParamsAtom)
    const setUpgradeMessageModalIsOpen = useSetRecoilState<boolean>(upgradeMessageModalIsOpenAtom)
    const [filters, setFilters] = useRecoilState<FiltersParams>(filtersAtom)
    const [pagination, setPagination] = useRecoilState<PaginationParams>(paginationAtom)

    function onPriorityFilterChange() {
        if (!hasSubscriptionAccess) {
            setUpgradeMessageModalIsOpen(true)
            return
        }
        AsyncStorage.setItem('issues-priority-filter', JSON.stringify(!filters.priority))
        setPagination({ ...pagination, page: 1 })
        setFilters({ ...filters, priority: !filters.priority })
    }

    function onStatusFilterChange(value: StatusOption[]) {
        if (!hasSubscriptionAccess) {
            setUpgradeMessageModalIsOpen(true)
            return
        }
        AsyncStorage.setItem('issues-status-filter', JSON.stringify(value))
        setPagination({ ...pagination, page: 1 })
        setFilters({ ...filters, status: value })
    }
    return (
        <div className="col-xs-12 col-md-4 my-sm-3 my-xs-10">
            <FiltersContainer>
                <IconButton
                    icon="priority-low icon-lg"
                    onClick={onPriorityFilterChange}
                    tooltip={{ text: 'See all', position: 'top' }}
                    className={`btn btn-circle btn-icon btn-${filters.priority ? 'danger' : 'specta-gray'} ${
                        filters.priority ? 'text-white' : ''
                    }`}
                />
                <div style={{ zIndex: 100 }}>
                    <StatusFilterPopup
                        buttonDimension={uc.LARGE_BUTTON_DIM}
                        setStatusFilter={onStatusFilterChange}
                        defaultFilter={defaultStatusFilter}
                        statusFilter={filters.status}
                    />
                </div>
            </FiltersContainer>
        </div>
    )
}
