import React from 'react'
import { GuestData } from '.'
import styled from 'styled-components'
import * as colors from '@shared/colors'
import imgName from '@shared/img/kea-hotels-logo.png'

export const HeaderContainer = styled.div`
    background-color: #f7f7f7;
    display: flex;
    height: 140px;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    @media (max-width: 992px) {
        padding: 0 16px;
    }
`
export const HeaderInfo = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
})
export const Image = styled.img({
    height: 80
})
export const RoomText = styled.div({
    color: colors.black_sweeply,
    opacity: 0.5,
    fontSize: 16
})
export const Title = styled.div({
    fontSize: 18,
    color: colors.black_sweeply
})
export const RoomName = styled.div({
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.black_sweeply
})

export const Header = ({ guest }: { guest: GuestData }) => {
    const { area } = guest
    return (
        <HeaderContainer>
            <HeaderInfo>
                <RoomText>{'Room'}</RoomText>
                {area && <RoomName>{area.name}</RoomName>}
                <Title>{'Your guest page'}</Title>
            </HeaderInfo>
            {/*<Image src={imgName} />*/}
        </HeaderContainer>
    )
}
