import React from 'react'
import { TitleWrap, User, UsersWrap, Wrap } from './style'
import ButtonIcon from 'app/components/buttons/ButtonIcon'
import UnassignIcon from '@shared/img/close-grey.svg?react'
import { PhotoWrap } from 'app/components/layouts/User/style'
import Tooltip from 'app/components/tooltips/BlackTooltip'
import { UserStruct } from '@shared/firestore-structs'
import { getPhotoWrapBackgroundColor } from 'app/utils/helpers'
import { currentUserAtom } from 'app/modules/Auth/atoms'
import { useRecoilValue } from 'recoil'

interface Props {
    assignedTo: Pick<UserStruct, 'initials' | 'key' | 'name'>[]
    onUnassignClick: () => void
}

interface UserItemProps {
    user: Pick<UserStruct, 'initials' | 'key' | 'name'>
    moreThanTwo: boolean
}

function AssignedTo({ assignedTo, onUnassignClick }: Props) {
    const MORE_THAN_TWO = assignedTo.length > 2

    return (
        <Wrap>
            <TitleWrap>
                <h3>Assigned to</h3>
                <ButtonIcon size={22} tooltipText="Unassign" onClick={onUnassignClick}>
                    <UnassignIcon />
                </ButtonIcon>
            </TitleWrap>

            <UsersWrap moreThanTwo={MORE_THAN_TWO}>
                {assignedTo.map(user => (
                    <UserItem key={user.key} user={user} moreThanTwo={MORE_THAN_TWO} />
                ))}
            </UsersWrap>
        </Wrap>
    )
}

function UserItem({ user: { key, initials, name }, moreThanTwo }: UserItemProps) {
    const currentUser = useRecoilValue(currentUserAtom) as UserStruct
    const backgroundColor = getPhotoWrapBackgroundColor(key, currentUser.key)

    return (
        <User>
            <Tooltip placement={'top'} content={name}>
                <PhotoWrap backgroundColor={backgroundColor} size={'26px'} fontSize={'0.769rem'}>
                    {initials}
                </PhotoWrap>
            </Tooltip>
            {!moreThanTwo && <span>{name}</span>}
        </User>
    )
}

export default AssignedTo
