// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Switch } from 'react-native-web'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Input, DatePickerField } from '../../../../../_metronic/_partials/controls'
import { Checkbox } from '../../../../components/forms/Checkbox'

import * as colors from '@shared/colors'

import OrganizationSubscriptionPlanSelect from '../../../../admin/organizations/organization-subscription-plan-select'
import OrganizationStatusSelect from '../../../../admin/organizations/organization-status-select'

import IssueHashtagsSelect from '../../../../components/hashtags-select'
import { ModalFormFooter } from '../../../../components/modals/ModalFormFooter'
import * as constants from '@shared/constants'
import Alert from '../../../../components/alerts/Alert'
import { Beds24Form } from '../../pms/Beds24Form'

// Validation schema
const AccountEditSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Name is required')
    // plan: Yup.array(),
    // status: Yup.string(),
    // issueHashtags: Yup.array(),
    // assignMaintenance: Yup.boolean()
    // .email("Invalid email"),
})

const defaultHashtags = constants.HASHTAGS_DEFAULT

export function AccountEditForm({ saveAccount, account, actionsLoading, dataLoading, onHide }) {
    const [suspendingAlertIsShown, setSuspendingAlertIsShown] = useState(false)

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={account}
                validationSchema={AccountEditSchema}
                onSubmit={values => {
                    // values.displayCleaningStatus = displayCleaningStatus
                    saveAccount(values)
                }}>
                {({ handleSubmit, values, setFieldValue }) => {
                    const onSuspendingConfirm = () => {
                        setFieldValue('status', 'suspended')
                        setSuspendingAlertIsShown(false)
                    }

                    const onSuspendingCancel = () => {
                        setSuspendingAlertIsShown(false)
                    }

                    const suspendingAlertCondition = suspendingAlertIsShown && (
                        <Alert
                            title={'Suspend account'}
                            text={'Are you sure?'}
                            onConfirm={onSuspendingConfirm}
                            onCancel={onSuspendingCancel}
                        />
                    )

                    return (
                        <>
                            {suspendingAlertCondition}
                            <Modal.Body className="overlay overlay-block cursor-default">
                                <Form className="form form-label-right">
                                    <div className="my-3">
                                        <h3 className="text-dark font-weight-bolder mb-10">General</h3>

                                        <div className="form-group row">
                                            {/* Name */}
                                            <div className="col-lg-12">
                                                <Field
                                                    name="name"
                                                    component={Input}
                                                    placeholder="Enter name of account"
                                                    label="Name"
                                                    disabled={dataLoading || actionsLoading}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-lg-12">
                                                <label className="font-weight-bold" htmlFor="subscription">
                                                    Subscription:
                                                </label>
                                                <Field
                                                    name="subscription"
                                                    disabled={dataLoading || actionsLoading}
                                                    component={() => {
                                                        return (
                                                            <OrganizationSubscriptionPlanSelect
                                                                isDisabled={actionsLoading}
                                                                subscription={values.subscription || 'basic'}
                                                                onChange={subscription => {
                                                                    setFieldValue('subscription', subscription)
                                                                }}
                                                            />
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-12">
                                                <label className="font-weight-bold" htmlFor="status">
                                                    Status:
                                                </label>
                                                <Field
                                                    name="status"
                                                    disabled={dataLoading || actionsLoading}
                                                    component={() => {
                                                        return (
                                                            <OrganizationStatusSelect
                                                                isDisabled={dataLoading || actionsLoading}
                                                                status={values.status}
                                                                width="100%"
                                                                onChange={status => {
                                                                    if (status === 'suspended') {
                                                                        setSuspendingAlertIsShown(true)
                                                                    } else {
                                                                        setFieldValue('status', status)
                                                                    }
                                                                }}
                                                            />
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-3">
                                        <h3 className="text-dark font-weight-bolder mb-10">Issues</h3>

                                        <div className="form-group row">
                                            {/* Name */}
                                            <div className="col-lg-12">
                                                <label className="font-weight-bold" htmlFor="issueHashtags">
                                                    Predefined hashtags users can add to issues:
                                                </label>
                                                <Field
                                                    name="issueHashtags"
                                                    disabled={dataLoading || actionsLoading}
                                                    component={() => {
                                                        return (
                                                            <IssueHashtagsSelect
                                                                hashtagColor={colors.medium_gray_spectaflow}
                                                                borderHoverColor={colors.green_spectaflow}
                                                                isDisabled={dataLoading || actionsLoading}
                                                                defaultHashtags={defaultHashtags}
                                                                width={'100%'}
                                                                hashtags={values.issueHashtags}
                                                                onChange={issueHashtags => {
                                                                    setFieldValue('issueHashtags', issueHashtags)
                                                                }}
                                                            />
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {values.assignMaintenance && (
                                            <div className="form-group row">
                                                {/* Name */}
                                                <div className="col-lg-12">
                                                    <label className="font-weight-bold" htmlFor="excludeMaintenanceNotification">
                                                        Exclude issues with hashtag...
                                                    </label>
                                                    <Field
                                                        name="excludeMaintenanceNotification"
                                                        disabled={dataLoading || actionsLoading}
                                                        component={() => {
                                                            return (
                                                                <IssueHashtagsSelect
                                                                    hashtagColor={colors.medium_gray_spectaflow}
                                                                    borderHoverColor={colors.green_spectaflow}
                                                                    isDisabled={dataLoading || actionsLoading}
                                                                    defaultHashtags={values.issueHashtags}
                                                                    width={'100%'}
                                                                    hashtags={values.excludeMaintenanceNotification}
                                                                    onChange={issueHashtags => {
                                                                        setFieldValue('excludeMaintenanceNotification', issueHashtags)
                                                                    }}
                                                                    noOptionsMessage={'No options left'}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <Beds24Form
                                        actionsLoading={actionsLoading}
                                        dataLoading={dataLoading}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                    />
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className="border-top-0">
                                <ModalFormFooter
                                    onHide={onHide}
                                    onSubmit={handleSubmit}
                                    dataLoading={dataLoading}
                                    actionsLoading={actionsLoading}
                                    type="save"
                                />
                            </Modal.Footer>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}
