import React from 'react'
import { useRecoilValue } from 'recoil'
import { extraCommentSelector } from './state/selectors/commentsAndNotes'
import Notes from './Notes'

function ExtraServicesSection() {
    const extraComment = useRecoilValue(extraCommentSelector)

    if (!extraComment) return null
    return <Notes title={'Extra services'} notes={extraComment} />
}

export default ExtraServicesSection
