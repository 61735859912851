import React, { useMemo, useContext } from 'react'
import objectPath from 'object-path'
// LayoutContext
import { useHtmlClassService } from '../_core/MetronicLayout'
// Import Layout components
import { Header } from './header/Header'
import { HeaderMobile } from './header-mobile/HeaderMobile'
import { Aside } from './aside/Aside'
import { Footer } from '../../../app/footer'
import { LayoutInit } from './LayoutInit'
import { SubHeader } from './subheader/SubHeader'
import NewVersion from '../../../app/navigation/new-version'
import { FreeTrialCounter } from '../../../app/modules/Organizations/free-trial/Countdown'
import { TrialEnd } from '../../../app/modules/Organizations/free-trial/TrialEnd'

import { AuthContext } from '../../../app/modules/Auth/AuthContext'
import { getBrand } from '@shared/brand'
import { useIsMobile } from '../../../app/utils/hooks/useIsMobile'
import { getCurrentEnv } from '../../../app/navigation/url-constants'

export function Layout(props) {
    const uiService = useHtmlClassService()
    const { user, organization } = useContext(AuthContext)
    const isMobile = useIsMobile()
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const timezoneMismatch = getCurrentEnv() !== 'prod' && organization.timezone !== browserTimezone
    // Layout settings (cssClasses/cssAttributes)
    const children = props.children
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            selfLayout: objectPath.get(uiService.config, 'self.layout'),
            asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
            subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
            desktopHeaderDisplay: objectPath.get(uiService.config, 'header.self.fixed.desktop'),
            contentCssClasses: uiService.getClasses('content', true),
            contentContainerClasses: uiService.getClasses('content_container', true),
            contentExtended: objectPath.get(uiService.config, 'content.extended'),
            toolbarDisplay: objectPath.get(uiService.config, 'extras.toolbar.display') && getBrand().intercomAppId,
            newVersionDisplay: user.newVersionDisplay,
            headerDisplay: objectPath.get(uiService.config, 'header.self.display')
        }
    }, [uiService])

    return layoutProps.selfLayout !== 'blank' ? (
        <>
            {/*begin::Main*/}

            {isMobile && <HeaderMobile />}
            <div className="d-flex flex-column flex-root">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    {layoutProps.asideDisplay && <Aside />}
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        {layoutProps.newVersionDisplay && <NewVersion />}
                        {timezoneMismatch && (
                            <h1 style={{ color: 'red' }}>
                                Your current timezone is {browserTimezone}. Please switch to {organization.timezone}
                            </h1>
                        )}

                        {layoutProps.headerDisplay && <Header />}
                        {/*begin::Content*/}
                        <div id="kt_content" className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}>
                            {layoutProps.subheaderDisplay && <SubHeader />}
                            {/*begin::Entry*/}
                            {!layoutProps.contentExtended && <div style={{ width: '100%', height: '100%' }}>{children}</div>}

                            {layoutProps.contentExtended && { children }}
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        <Footer {...props} />
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            {/* <QuickUser />
            <QuickPanel /> */}
            {/* <ScrollTop /> */}

            {/*end::Main*/}
            <LayoutInit />
        </>
    ) : (
        // BLANK LAYOUT
        <div className="d-flex flex-column flex-root">{children}</div>
    )
}
