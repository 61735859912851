import React from 'react'
import { Wrapper } from './style'
import DatePicker from './DatePicker'
import Search from './Search'
import Filters from './Filters'

function SearchAndFilters() {
    return (
        <Wrapper>
            <Search />
            <DatePicker />
            <Filters />
        </Wrapper>
    )
}

export default SearchAndFilters
