import React from 'react'
import { SectionTitleWrap } from '../style'
import { Text, Wrap } from './style'

interface Props {
    title: string
    notes: string
    icon?: {
        width?: number
        height?: number
        color?: string
        Icon: React.ReactNode
    }
}

function Notes({ title, notes, icon }: Props) {
    return (
        <Wrap>
            <SectionTitleWrap iconWidth={icon?.width} iconHeight={icon?.height} iconColor={icon?.color}>
                {icon?.Icon}
                <h2>{title}</h2>
            </SectionTitleWrap>
            <Text>{notes}</Text>
        </Wrap>
    )
}

export default Notes
