import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ReportsContext } from '../../ReportsContext'
import { AuthContext } from '../../../Auth/AuthContext'
import { AreaStruct, TaskStruct } from '@shared/firestore-structs'
import moment from 'moment-timezone'
import { TASK_TYPE_GENERAL } from '@shared/txt-constants'
import ReportTableHeaders from '../housekeeping-report/components/ReportTableHeaders'
import LoadingView from '../../../../utils/loading-view'
import { HeaderColumn, HeaderRow, SummaryColumn, SummaryRow, WorkloadColumn, WorkloadTypeColumn } from './style'
import { calculateTasks, getWorkloadData, mergeTasks } from './api'
import { isToday } from '@shared/cleaning-helpers'
import { useRecoilState } from 'recoil'
import { reportData, reportHeaders, reportSummary } from './atoms'
import { Donut } from './Donut'

export function WorkloadTable() {
    const { listLoading, setListLoading, queryParams, setQueryParams } = useContext(ReportsContext)
    const authContext = useContext(AuthContext)
    if (!authContext.currentOrganization || !authContext.currentUser) return null
    const { currentOrganization, currentUser } = authContext

    const [data, setData] = useState<TaskStruct[]>([])
    const [dates, setDates] = useState<number[]>([])

    const [reports, setReports] = useRecoilState(reportData)
    const [headers, setHeaders] = useRecoilState(reportHeaders)
    const [summary, setSummary] = useRecoilState(reportSummary)

    const reportsUIProps = useMemo(() => {
        return {
            listLoading: listLoading,
            setListLoading: setListLoading
        }
    }, [listLoading, setListLoading])

    useEffect(() => {
        async function fetchDataAsync() {
            reportsUIProps.setListLoading(true)
            const data = await getWorkloadData(
                queryParams.filter.startDate,
                queryParams.filter.endDate,
                currentOrganization.key,
                currentUser
            )
            const { dates, tasks, bookings, rules, areas } = data

            // console.log(tasks.map(x => x.name))

            const calculatedTasks = []
            for (const date of dates) {
                const t = calculateTasks(areas as AreaStruct[], bookings, date, rules, currentUser, currentOrganization)
                console.log(t)
                calculatedTasks.push(...t)
            }

            tasks.push(...calculatedTasks)
            const mergedTasks = mergeTasks(tasks)

            setDates(dates)
            setReports(mergedTasks)

            setHeaders(dates)

            setData(mergedTasks)
            reportsUIProps.setListLoading(false)
        }

        fetchDataAsync()
    }, [queryParams.filter.startDate, queryParams.filter.endDate, setQueryParams])

    const tableData: { [key: string]: { [key: string]: number } } = {}

    data.forEach(task => {
        const key = task.type === TASK_TYPE_GENERAL ? task.type : task.name || task.type
        if (!tableData[key]) {
            tableData[key] = {}
        }
        tableData[key][task.completedDate] = (tableData[key][task.completedDate] || 0) + 1
    })

    const sortedEntries = Object.entries(tableData).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    const sortedTableData = Object.fromEntries(sortedEntries)

    const donutSeries: number[] = Object.entries(sortedTableData).map(([type, typeData]) => {
        const total = Object.values(typeData).reduce((total, value) => total + value, 0)
        return total
    })

    const donutLabels: string[] = Object.entries(sortedTableData).map(([type, typeData]) => type)

    const summaryData: { [key: string]: number } = {}
    dates.forEach(date => {
        summaryData[date] = data.reduce((total, task) => (task.completedDate === date ? total + 1 : total), 0)
    })

    const rowsCondition = !reportsUIProps.listLoading && (
        <tbody>
            {Object.entries(sortedTableData).map(([type, typeData]) => (
                <tr key={type}>
                    <WorkloadTypeColumn>{type}</WorkloadTypeColumn>
                    {dates.map(date => (
                        <WorkloadColumn isToday={isToday(date)} key={date}>
                            {typeData[date] || 0}
                        </WorkloadColumn>
                    ))}
                </tr>
            ))}
        </tbody>
    )

    const headersCondition = !reportsUIProps.listLoading && (
        <thead>
            <HeaderRow className="text-center text-nowrap">
                <tr></tr>
                {dates.map(date => (
                    <HeaderColumn isToday={isToday(date)} key={date}>
                        {moment(date).format('D MMM')}
                    </HeaderColumn>
                ))}
            </HeaderRow>
        </thead>
    )

    return (
        <>
            {!listLoading && (
                <>
                    {data && data.length > 0 && (
                        <>
                            <div className="react-bootstrap-table table-responsive">
                                <table className="table table-hover table-head-custom table-vertical-center overflow-hidden">
                                    {headersCondition}
                                    {/*<ReportTableHeaders headers={dates.map(x => moment(x).format('D MMM'))} />*/}
                                    {rowsCondition}
                                    <SummaryRow>
                                        <WorkloadTypeColumn>Total</WorkloadTypeColumn>
                                        {dates.map(date => (
                                            <SummaryColumn isToday={isToday(date)} key={date}>
                                                {summaryData[date]}
                                            </SummaryColumn>
                                        ))}
                                    </SummaryRow>
                                </table>
                            </div>
                            <div className="mt-10">
                                <Donut labels={donutLabels} series={donutSeries} />
                            </div>
                        </>
                    )}
                </>
            )}
            {!reportsUIProps.listLoading && data.length === 0 && <h3 className="mt-20 text-center">No records found</h3>}
            {reportsUIProps.listLoading && (
                <div className="mt-20">
                    <LoadingView />
                </div>
            )}
        </>
    )
}
