import React, { useMemo, useContext } from 'react'

import { UsersContext } from '../../UsersContext'

export function UsersGrouping() {
    // Customers UI Context
    const { ids, setIds, openDeleteUsersDialog } = useContext(UsersContext)
    // const customersUIContext = useCustomersUIContext();
    const usersUIProps = useMemo(() => {
        return {
            ids: ids,
            setIds: setIds,
            openDeleteUsersDialog: openDeleteUsersDialog
            // openFetchCustomersDialog: customersUIContext.openFetchCustomersDialog,
            // openUpdateCustomersStatusDialog:
            //   customersUIContext.openUpdateCustomersStatusDialog,
        }
    }, [ids, openDeleteUsersDialog, setIds])

    return (
        <div className="form">
            <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
                <div className="col-xl-12">
                    <div className="form-group form-group-inline mb-0">
                        <button
                            type="button"
                            className="btn btn-danger font-weight-bolder font-size-sm mb-6"
                            onClick={usersUIProps.openDeleteUsersDialog}>
                            <i className="fa fa-trash icon-nm"></i> Delete All
                        </button>
                        <div className="form-label form-label-no-wrap">
                            <label className="font-bold font-danger">
                                <span>
                                    Selected: <b>{usersUIProps.ids.length}</b>
                                </span>
                            </label>
                        </div>
                        {/* &nbsp;
							<button
								type="button"
								className="btn btn-light-primary font-weight-bolder font-size-sm"
								onClick={usersUIProps.openFetchCustomersDialog}
							>
								<i className="fa fa-stream"></i> Fetch Selected
							</button>
							&nbsp;
							<button
								type="button"
								className="btn btn-light-primary font-weight-bolder font-size-sm"
								onClick={usersUIProps.openUpdateCustomersStatusDialog}
							>
								<i className="fa fa-sync-alt"></i> Update Status
							</button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
