import React, { useMemo } from 'react'
import { InfoItem, Wrap } from './style'
import { SectionTitleWrap } from '../style'
import moment from 'moment-timezone'
import { durationToText } from '@shared/timer-helpers'
import { LastCleaningTask } from '../../types'
import Assignees from 'app/components/assignees'
import { useRecoilValue } from 'recoil'
import { currentUserAtom } from 'app/modules/Auth/atoms'
import { UserStruct } from '@shared/firestore-structs'

interface Props {
    lastCleaningTask: LastCleaningTask
}

function LastHousekeepingSection({ lastCleaningTask }: Props) {
    const currentUser = useRecoilValue(currentUserAtom) as UserStruct
    const { cleaning } = lastCleaningTask

    const DATE_FORMAT = {
        sameDay: '[Today at ] H:mm',
        lastDay: '[Yesterday at] H:mm',
        lastWeek: '[Last] dddd [at] H:mm',
        sameElse: 'dddd, MMM D YYYY [at] H:mm'
    }
    const FINISHED = useMemo(() => moment(cleaning.end).calendar(null, DATE_FORMAT), [cleaning.end])
    const DURATION = useMemo(() => {
        const duration = cleaning.end !== cleaning.start ? durationToText(cleaning) : ''
        return duration
    }, [cleaning.end])

    return (
        <Wrap>
            <SectionTitleWrap>
                <h2>Last housekeeping</h2>
            </SectionTitleWrap>

            <InfoItem>
                <h3>Finished</h3>
                <span>{FINISHED}</span>
            </InfoItem>

            {lastCleaningTask?.assignedTo && lastCleaningTask?.assignedTo?.length > 0 && (
                <InfoItem>
                    <h3>Done by</h3>
                    <span>
                        <Assignees assignees={lastCleaningTask.assignedTo} currentUser={currentUser} />
                    </span>
                </InfoItem>
            )}

            {DURATION.length > 0 && (
                <InfoItem>
                    <h3>Duration</h3>
                    <span>{DURATION}</span>
                </InfoItem>
            )}
        </Wrap>
    )
}

export default LastHousekeepingSection
