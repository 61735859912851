import { View, Image } from 'react-native-web'
import * as chelpers from '@shared/cleaning-helpers'
import { iOSColors } from '@shared/react-native-typography'
import React, { useContext } from 'react'
import { TaskScreenContext } from './TaskScreenContext'
import OccupancySelect from '../../occupancy-select'
import { BOX_HEIGHT, BOX_WIDTH, UNIT_HEADER_FONT_SIZE } from './consts'
import * as dataObjects from '@shared/dataObjects'
import moment from 'moment/moment'
import { createActivity } from '@shared/activity-data'
import firebase, { asFirebase } from '../../../utils/firebase'
import * as areaData from '@shared/area-data'
import { Occupancy } from '@shared/firestore-structs'
import { AuthContext } from 'app/modules/Auth/AuthContext'
import { DashboardContext } from '../DashboardContext'

const BookingInfo = (isFutureBooking: any) => {
    const { area, setArea, selectedDateNumber, bookingDisplay } = useContext(TaskScreenContext)
    const { updateAreasLocally } = useContext(DashboardContext)
    const { currentUser } = useContext(AuthContext)

    const onOccupancyPress = async (occupancy: Occupancy) => {
        const areaObject = {
            lastStatusChange: {
                user: dataObjects.getMiniUserObject(currentUser!),
                updated: moment().valueOf()
            }
        }

        createActivity(asFirebase(firebase), area, currentUser!, selectedDateNumber, 'occupancy', 'occupancy', area.occupancy, occupancy)

        await areaData.updateArea(asFirebase(firebase), area.key, areaObject, currentUser!)

        const updatedArea = { ...area, occupancy }

        updateAreasLocally([{ key: area.key, occupancy }])

        setArea(updatedArea)
    }

    const PADDING_BOTTOM = 5

    const FONT_SIZE = 15
    const MARGIN_BETWEEN_UNITS = 12
    const PADDING_LEFT = 6

    const FONT_WEIGHT = '400'

    let TITLE = 'Occupancy'

    if (area.synced) {
        TITLE = isFutureBooking ? 'Next booking' : 'Current booking'
    }

    const DEBUG_BORDER = 0

    if (!bookingDisplay) {
        return null
    }

    return (
        <View
            style={{
                // borderWidth: 1,
                zIndex: 2,
                borderColor: '#f4f5f6',
                borderRadius: 6,
                width: '95%',
                paddingVertical: 24,
                alignItems: 'center',
                // @ts-ignore
                boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, .2)'
                // minHeight: min_height
            }}>
            <View>
                <View
                    style={{
                        borderWidth: DEBUG_BORDER,
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: MARGIN_BETWEEN_UNITS
                    }}>
                    <View
                        style={{
                            width: 26,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderWidth: DEBUG_BORDER,
                            borderColor: 'red'
                        }}>
                        <Image
                            style={{ width: 17, height: 17.5 }}
                            source={chelpers.pickBookingNoteIcon(area.cleaningStatus, area.occupancy)}
                        />
                    </View>
                    <span
                        style={{
                            marginLeft: 4,
                            marginTop: 2,
                            fontWeight: '600',
                            color: iOSColors.black,
                            fontSize: UNIT_HEADER_FONT_SIZE
                        }}>
                        {TITLE}
                    </span>
                </View>
                <View
                    style={{
                        borderColor: 'red',
                        borderWidth: 0,
                        marginBottom: MARGIN_BETWEEN_UNITS + 6
                    }}>
                    <OccupancySelect
                        width={BOX_WIDTH}
                        height={BOX_HEIGHT}
                        isAreaSynced={area.synced}
                        selectedDateNumber={selectedDateNumber}
                        occupancy={area ? area.occupancy : null}
                        onChange={(item: any) => {
                            onOccupancyPress(item.value)
                        }}
                    />
                </View>
                {area.synced && (
                    <View>
                        {bookingDisplay.guestName && (
                            <View
                                style={{
                                    paddingLeft: PADDING_LEFT,
                                    justifyContent: 'center',
                                    borderWidth: DEBUG_BORDER,
                                    borderColor: 'red',
                                    paddingBottom: PADDING_BOTTOM
                                }}>
                                <span
                                    style={{
                                        fontWeight: '500',
                                        fontSize: FONT_SIZE, // 18
                                        color: iOSColors.black
                                    }}>
                                    {bookingDisplay.guestName}
                                </span>
                            </View>
                        )}
                        {bookingDisplay.guestCount && (
                            <View
                                style={{
                                    paddingLeft: PADDING_LEFT,
                                    justifyContent: 'center',
                                    borderWidth: DEBUG_BORDER,
                                    borderColor: 'red',
                                    paddingBottom: PADDING_BOTTOM
                                }}>
                                <span
                                    style={{
                                        fontWeight: FONT_WEIGHT,
                                        fontSize: FONT_SIZE, // 18
                                        color: iOSColors.gray
                                    }}>
                                    {bookingDisplay.guestCount}
                                </span>
                            </View>
                        )}

                        {bookingDisplay.occupancyText && (
                            <View
                                style={{
                                    paddingLeft: PADDING_LEFT,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    borderColor: 'yellow',
                                    borderWidth: DEBUG_BORDER,
                                    paddingBottom: PADDING_BOTTOM
                                }}>
                                <span
                                    style={{
                                        fontWeight: FONT_WEIGHT,
                                        color: iOSColors.gray,
                                        fontSize: FONT_SIZE
                                    }}>
                                    {bookingDisplay.occupancyText}
                                </span>
                            </View>
                        )}

                        {bookingDisplay.bedSetup && (
                            <View
                                style={{
                                    paddingLeft: PADDING_LEFT,
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                    alignItems: 'center',

                                    borderColor: 'yellow',
                                    borderWidth: DEBUG_BORDER
                                }}>
                                <span
                                    style={{
                                        fontWeight: FONT_WEIGHT,
                                        color: iOSColors.gray,
                                        fontSize: FONT_SIZE
                                    }}>
                                    {bookingDisplay.bedSetup}
                                </span>
                            </View>
                        )}
                    </View>
                )}
            </View>
        </View>
    )
}

export default BookingInfo
