import instance from '../../utils/api'

export const getUserEmail = async phoneNumber => {
    const {
        data: {
            data: { email }
        }
    } = await instance.post(`getUserEmail`, { data: { phoneNumber } }, { validateStatus: () => true })

    return email
}

export const sendEmailLink = async phoneNumber => {
    const response = await instance.post(
        `getUserEmail/sendInvite`,
        { data: { phoneNumber, devMode: typeof process.env['LOCAL_DEV'] === 'string' } },
        { validateStatus: () => true }
    )

    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        throw new Error(response.data.message)
    }
}
