import React, { useState, useContext } from 'react'
import { useFormik } from 'formik'
import { notify } from 'react-notify-toast'
import { ActivityIndicator } from 'react-native-web'
import { Toast } from '../../../../utils/toast'
import { AuthContext } from '../../../Auth/AuthContext'
import { SubscriptionCheck, FeatureLockedOverlay } from '../../../../components/upgrade-signals'
import IssueHashtagsSelect from '../../../../components/hashtags-select'
import { iOSColors } from '@shared/react-native-typography'
import { AccountsContext } from '../../AccountsContext'
import * as brand from '@shared/brand'
import { getOrganizationObject } from '@shared/dataObjects'
import * as constants from '@shared/constants'
import { httpsCallable } from '../../../../api'
import MainActionButton from '../../../../components/buttons/MainActionButton'

const AccountIssues = () => {
    const [loading, setloading] = useState(false)
    const { currentOrganization, setOrganization } = useContext(AuthContext)
    const { setShowAlert } = useContext(AccountsContext)
    const [assignMaintenance, setAssignMaintenance] = useState(currentOrganization.assignMaintenance ? true : false)
    const [issueHashtags, setIssueHashtags] = useState(
        Array.isArray(currentOrganization.issueHashtags) ? currentOrganization.issueHashtags : []
    )
    const [excludeMaintenanceNotification, setExcludeMaintenanceNotification] = useState(
        Array.isArray(currentOrganization.excludeMaintenanceNotification) && currentOrganization.excludeMaintenanceNotification[0] !== ''
            ? currentOrganization.excludeMaintenanceNotification
            : []
    )

    const defaultHashtags = constants.HASHTAGS_DEFAULT

    const initialValues = {
        issueHashtags,
        excludeMaintenanceNotification,
        assignMaintenance
    }

    const saveAccount = (values, setStatus, setSubmitting) => {
        setloading(true)
        let organizationObject = getOrganizationObject(
            currentOrganization.key,
            currentOrganization.name,
            currentOrganization.status,
            values.issueHashtags,
            values.assignMaintenance,
            values.excludeMaintenanceNotification,
            currentOrganization.currencyCode || 'ISK',
            currentOrganization.timezone,
            currentOrganization.allowOptIn,
            currentOrganization.allowOptOut
        )
        organizationObject.brand = currentOrganization.brand || 'sweeply'
        const manageOrganization = httpsCallable('manageOrganization')
        manageOrganization(organizationObject)
            .then(() => {
                setOrganization(organizationObject)
                setSubmitting(false)
                setShowAlert(true)
                setloading(false)
            })
            .catch(error => {
                setSubmitting(false)
                setloading(false)
                console.log(error)
                notify.show(<Toast width={250} message={error.message} />, 'custom', 3000, {})
            })
    }

    const formik = useFormik({
        initialValues,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveAccount(values, setStatus, setSubmitting)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            {/* {loading && <ModalProgressBar />} */}
            <div
                style={{
                    position: 'absolute',
                    right: '50%',
                    top: '40%',
                    height: 50,
                    width: 50,
                    zIndex: 10000
                }}>
                {loading && <ActivityIndicator size="large" color={brand.getBrand().navBarColor} style={{}} />}
            </div>

            {/* begin::Header */}
            <div className="px-9 with-border py-3">
                <div className="align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">Issue categories</h3>
                    <span className="font-weight-bold text-dark-50">Define your own issue categories</span>
                </div>
                {/* <div className="card-toolbar">
                    <button type="submit" className="btn btn-success mr-2" disabled={formik.isSubmitting || !formik.isValid}>
                        Save Changes
                        {formik.isSubmitting}
                    </button>
                </div> */}
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                {/* begin::Body */}
                <div className="card-body">
                    <div>
                        <SubscriptionCheck
                            // noCheck={this.isModal}
                            featureId="issues"
                            onAvailable={() => null}
                            onNotAvailable={(notAccessibleFeature, availableUpgrades) => (
                                <FeatureLockedOverlay
                                    notAccessibleFeature={notAccessibleFeature}
                                    availableUpgrades={availableUpgrades}
                                    showMessage={true}
                                    gradientPower={'2%'}
                                    gradientFadeOutDirection={'right'}
                                />
                            )}
                        />

                        <div className="form-group">
                            <IssueHashtagsSelect
                                hashtagColor={iOSColors.gray}
                                borderHoverColor={iOSColors.gray}
                                isDisabled={loading}
                                defaultHashtags={defaultHashtags}
                                width={'100%'}
                                hashtags={issueHashtags}
                                onChange={hashtags => {
                                    formik.values.issueHashtags = hashtags
                                    setIssueHashtags(hashtags)
                                }}
                            />
                            <span className="form-text text-sweeply-red font-weight-bold">
                                All users with access to issues can use these categories.
                            </span>
                        </div>
                    </div>
                </div>
                {/* end::Body */}
            </div>
            {/* end::Form */}
            <div className="px-9 d-flex justify-content-end">
                <MainActionButton
                    type="primary"
                    size={'large'}
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}>
                    Save
                    {formik.isSubmitting}
                </MainActionButton>
            </div>
        </form>
    )
}

export default AccountIssues
