import React, { useEffect, useMemo, useState } from 'react'
import { ActionsWrap, IconsWrap, InfoWrap, Wrap } from './style'
import ButtonIcon from 'app/components/buttons/ButtonIcon'
import DatePickerInput from 'app/components/DatePicker'
import CloseIcon from 'app/img/close-modal-mid-gray.svg?react'
import AssignIcon from 'app/img/assign-task-button-white-bkgr.svg?react'
import PriorityLowIcon from '@shared/img/priority-low-white-bkg.svg?react'
import PriorityHighIcon from '@shared/img/priority-high-white-bkg.svg?react'
import { UserStruct } from '@shared/firestore-structs'
import HousekeepingIcon from '../../HousekeepingIcon'
import UsersSelectModal from 'app/components/modals/UsersSelectModal'
import { UserOption, constructUsersOptions } from '@shared/user-data'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { currentUserAtom } from 'app/modules/Auth/atoms'
import { pickCleaningTaskName } from '../../api/utils'
import { assignHousekeepingTasks } from '../../api'
import { toast } from 'react-toastify'
import { toastErrorStyle, toastSuccessStyle } from 'app/utils/styles'
import * as c from '@shared/constants'
import { logActivity } from '@shared/area-data'
import firebase, { asFirebase } from 'app/utils/firebase'
import { setTaskPriority } from '@shared/task-data'
import { structuredClone } from '@shared/polyfills'
import {
    activeRuleAtom,
    priorityAtom,
    ruleNameAtom,
    currentDateNumberAtom,
    taskAtom,
    usersAtom,
    dataLoadingAtom,
    sameDateAsParentAtom,
    subscriptionParamsAtom
} from '../state/atoms'
import { calculatedAreaSelector } from '../state/selectors/calculatedAreaData'
import { cleaningStatusColorSelector, housekeepingIconsSelector } from '../state/selectors/visuals'
import { selectedDateNumberSelector } from '../state/selectors/selectedDateNumber'
import { SubscriptionCheck } from 'app/components/upgrade-signals'

interface Props {
    onClose: () => void
}

function Header({ onClose }: Props) {
    const { area } = useRecoilValue(calculatedAreaSelector)
    const priority = useRecoilValue(priorityAtom)
    const users = useRecoilValue(usersAtom)
    const task = useRecoilValue(taskAtom)
    const ruleName = useRecoilValue(ruleNameAtom)
    const activeRule = useRecoilValue(activeRuleAtom)
    const cleaningStatusColor = useRecoilValue(cleaningStatusColorSelector)
    const housekeepingIcons = useRecoilValue(housekeepingIconsSelector(false))
    const SAME_DATE_AS_PARENT = useRecoilValue(sameDateAsParentAtom)
    const selectedDateNumber = useRecoilValue(selectedDateNumberSelector)

    const [currentDateNumber, setCurrentDateNumber] = useRecoilState(currentDateNumberAtom)
    const [dataLoading, setDataLoading] = useRecoilState(dataLoadingAtom)

    const setSubscriptionParams = useSetRecoilState(subscriptionParamsAtom)

    const [usersSelectModalIsOpen, setUsersSelectModalIsOpen] = useState(false)
    const [isPriority, setIsPriority] = useState(priority)

    const currentUser = useRecoilValue(currentUserAtom) as UserStruct

    const usersOptions = useMemo(() => constructUsersOptions(users, [area.group as string]), [users])

    useEffect(() => {
        setIsPriority(priority)
    }, [priority])

    async function onPriorityClick() {
        const priority = structuredClone(isPriority)

        const change = {
            field: c.ACTIVITY_TYPE_CLEANING_PRIORITY,
            before: priority,
            after: !priority
        }

        setIsPriority(!isPriority)

        try {
            await logActivity(asFirebase(firebase), currentUser, area.key, c.ACTIVITY_TYPE_CLEANING_PRIORITY, selectedDateNumber, change)

            if (task) {
                await setTaskPriority(asFirebase(firebase), currentUser, task.key, { priority: !priority })
            }

            const message = `Priority ${!priority ? 'added' : 'removed'} ${!priority ? 'to' : 'from'} "${area.name}"`
            toast.success(message, toastSuccessStyle)
        } catch (error: unknown) {
            toast.error(`Error changing priority ${error instanceof Error ? error.message : error}`, toastErrorStyle)
            setIsPriority(priority)
            console.error(error)
        }
    }

    async function onAssignClick(users: UserOption[]) {
        const name = task?.name ?? pickCleaningTaskName(ruleName, activeRule)
        const usersData = users.map(user => user.data)

        try {
            await assignHousekeepingTasks([area], usersData, selectedDateNumber, currentUser, [name], task ? [task] : null)

            setUsersSelectModalIsOpen(false)

            const message = `Task "${name}" assigned to ${usersData.map(user => user.name).join(', ')}`
            toast.success(message, toastSuccessStyle)
        } catch (error: unknown) {
            if (error instanceof Error) {
                toast.error(error.message, toastErrorStyle)
            }
        }
    }

    function onDateChange(date: number | Date) {
        const currentDateNumber = typeof date === 'number' ? date : date.valueOf()
        setDataLoading(true)
        setCurrentDateNumber(currentDateNumber)
    }

    return (
        <>
            {usersSelectModalIsOpen && (
                <UsersSelectModal
                    onClose={() => setUsersSelectModalIsOpen(false)}
                    usersOptions={usersOptions}
                    currentUser={currentUser}
                    selected={new Set(task?.assignedTo?.map(user => user.key) ?? [])}
                    onAssignClick={onAssignClick}
                />
            )}

            <Wrap backgroundColor={cleaningStatusColor} dataLoading={dataLoading}>
                <ActionsWrap>
                    <div>
                        {SAME_DATE_AS_PARENT && (
                            <ButtonIcon tooltipText="High priority" size={35} hoverable={false} onClick={onPriorityClick}>
                                {isPriority ? <PriorityHighIcon /> : <PriorityLowIcon />}
                            </ButtonIcon>
                        )}

                        <SubscriptionCheck
                            featureId="assign-housekeeping"
                            onAvailable={() => (
                                <ButtonIcon
                                    tooltipText="Assign"
                                    size={35}
                                    hoverable={false}
                                    onClick={() => setUsersSelectModalIsOpen(true)}>
                                    <AssignIcon />
                                </ButtonIcon>
                            )}
                            onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                                return (
                                    <ButtonIcon
                                        tooltipText="Assign"
                                        size={35}
                                        hoverable={false}
                                        onClick={() =>
                                            setSubscriptionParams({
                                                hasSubscriptionAccess: false,
                                                notAccessibleFeature,
                                                availableUpgrades,
                                                upgradeMessageIsOpen: true
                                            })
                                        }>
                                        <AssignIcon />
                                    </ButtonIcon>
                                )
                            }}
                        />
                    </div>

                    <div>
                        <DatePickerInput
                            isWhite={true}
                            featureId={'browse-history'}
                            selectedDateNumber={currentDateNumber}
                            onDateChange={onDateChange}
                            onNotAvailableDateChange={(notAccessibleFeature, availableUpgrades) =>
                                setSubscriptionParams({
                                    notAccessibleFeature,
                                    availableUpgrades,
                                    hasSubscriptionAccess: false,
                                    upgradeMessageIsOpen: true
                                })
                            }
                        />
                    </div>

                    <div>
                        <ButtonIcon size={15} onClick={onClose}>
                            <CloseIcon />
                        </ButtonIcon>
                    </div>
                </ActionsWrap>

                <InfoWrap>
                    <h1>{`${area.group} > ${area.description && area.description.length > 0 ? `${area.description} >` : ''} ${
                        area.name
                    }`}</h1>

                    <IconsWrap>
                        {housekeepingIcons.map((icon, index) => {
                            return <HousekeepingIcon key={index} icon={icon} scale={1.5} />
                        })}
                    </IconsWrap>
                </InfoWrap>
            </Wrap>
        </>
    )
}

export default Header
