import React, { useMemo, useState } from 'react'
import {
    ButtonWrap,
    ClearUsersButtonWrap,
    FooterWrap,
    HeaderWrap,
    SearchWrap,
    SelectedUsers,
    TitleWrap,
    UserItem,
    UsersList,
    Wrapper
} from './style'
import { ModalOverlay } from 'app/components/styled-components/modal'
import CloseIcon from 'app/img/close-modal-mid-gray.svg?react'
import ButtonIcon from 'app/components/buttons/ButtonIcon'
import Search from 'app/components/forms/Search'
import { PhotoWrap } from 'app/components/layouts/User/style'
import { UserOption, findSelectedUsersOptions } from '@shared/user-data'
import { search } from 'app/utils/formatters'
import { UserStruct } from '@shared/firestore-structs'
import { getBrand } from '@shared/brand'
import MainActionButton from 'app/components/buttons/MainActionButton'
import ClearIcon from '@shared/img/clear-filter.svg?react'
import Tooltip from 'app/components/tooltips/BlackTooltip'
import { ActivityIndicator } from 'react-native-web'
import { getPhotoWrapBackgroundColor } from 'app/utils/helpers'

interface UsersSelectModalProps {
    onClose: () => void
    usersOptions: UserOption[]
    currentUser: UserStruct
    onAssignClick: (users: UserOption[]) => Promise<void> | void
    selected?: Set<string>
    unassignMode?: boolean
    onUnassignClick?: () => void
}

interface HeaderProps {
    usersQuantity: number
    onClose: () => void
}

interface UserProps {
    option: UserOption
    selectedOptionsKeys: Set<string>
    onOptionClick: (option: UserOption) => void
    currentUserKey: string
}

interface FooterProps {
    selectedOptions: UserOption[]
    onAssignClick: () => void
    onUnassignClick?: () => void
    currentUserKey: string
    onClearUsers: () => void
    loading: boolean
    options: UserOption[]
}

function UsersSelectModal({
    onClose,
    usersOptions,
    currentUser,
    onAssignClick,
    unassignMode = false,
    onUnassignClick,
    selected = new Set()
}: UsersSelectModalProps) {
    const [optionsToDisplay, setOptionsToDisplay] = useState<UserOption[]>(usersOptions)
    const [selectedOptionsKeys, setSelectedOptionsKeys] = useState<Set<string>>(selected)
    const [searchValue, setSearchValue] = useState('')
    const [loading, setLoading] = useState(false)

    const footerCondition = unassignMode && onUnassignClick ? true : selectedOptionsKeys.size > 0
    const selectedOptions = useMemo(() => findSelectedUsersOptions(usersOptions, selectedOptionsKeys), [usersOptions, selectedOptionsKeys])

    function onOptionClick(option: UserOption) {
        selectedOptionsKeys.has(option.value) ? selectedOptionsKeys.delete(option.value) : selectedOptionsKeys.add(option.value)
        setSelectedOptionsKeys(new Set(selectedOptionsKeys))
    }

    function onSearchChange(value: string) {
        setSearchValue(value)
        setOptionsToDisplay(search(value, usersOptions, option => option.label))
    }

    function onClearUsers() {
        setSelectedOptionsKeys(new Set())
    }

    async function onAssignSubmit() {
        setLoading(true)
        await onAssignClick(selectedOptions)
        setLoading(false)
    }

    return (
        <ModalOverlay basic>
            <Wrapper>
                <Header usersQuantity={usersOptions.length} onClose={onClose} />

                <SearchWrap>
                    <Search value={searchValue} placeholder={'User'} onChange={onSearchChange} />
                </SearchWrap>

                <UsersList>
                    {optionsToDisplay.map(option => (
                        <User
                            key={option.value}
                            option={option}
                            selectedOptionsKeys={selectedOptionsKeys}
                            onOptionClick={onOptionClick}
                            currentUserKey={currentUser.key}
                        />
                    ))}
                </UsersList>

                {footerCondition && (
                    <Footer
                        loading={loading}
                        options={optionsToDisplay}
                        selectedOptions={selectedOptions}
                        onAssignClick={onAssignSubmit}
                        onUnassignClick={onUnassignClick}
                        currentUserKey={currentUser.key}
                        onClearUsers={onClearUsers}
                    />
                )}
            </Wrapper>
        </ModalOverlay>
    )
}

function Header({ usersQuantity, onClose }: HeaderProps) {
    return (
        <HeaderWrap>
            <TitleWrap>
                <h2>Select users to assign</h2>
                <span>{`(${usersQuantity})`}</span>
            </TitleWrap>

            <ButtonIcon size={15} onClick={onClose}>
                <CloseIcon />
            </ButtonIcon>
        </HeaderWrap>
    )
}

function User({ option, selectedOptionsKeys, onOptionClick, currentUserKey }: UserProps) {
    return (
        <UserItem selected={selectedOptionsKeys.has(option.value)} onClick={() => onOptionClick(option)}>
            <PhotoWrap backgroundColor={getPhotoWrapBackgroundColor(option.value, currentUserKey)}>{option.initials}</PhotoWrap>
            <span>{option.label}</span>
        </UserItem>
    )
}

function Footer({ loading, selectedOptions, onAssignClick, onUnassignClick, currentUserKey, onClearUsers, options }: FooterProps) {
    const unassignCondition = onUnassignClick && selectedOptions.length === 0

    return (
        <FooterWrap>
            <SelectedUsers>
                {selectedOptions.map(option => {
                    return (
                        <Tooltip key={option.value} placement={'top'} content={option.label}>
                            <PhotoWrap
                                size={'30px'}
                                fontSize="0.846rem"
                                backgroundColor={getPhotoWrapBackgroundColor(option.value, currentUserKey)}>
                                {option.initials}
                            </PhotoWrap>
                        </Tooltip>
                    )
                })}
                {selectedOptions.length > 0 && (
                    <ClearUsersButtonWrap>
                        <ButtonIcon tooltipText="Clear users" size={22} onClick={onClearUsers}>
                            <ClearIcon />
                        </ButtonIcon>
                    </ClearUsersButtonWrap>
                )}
            </SelectedUsers>

            <ButtonWrap>
                {loading && <ActivityIndicator size="small" color={getBrand().navBarColor} />}
                <MainActionButton onClick={unassignCondition ? onUnassignClick : onAssignClick}>
                    {unassignCondition ? 'Unassign' : 'Assign'}
                </MainActionButton>
            </ButtonWrap>
        </FooterWrap>
    )
}

export default UsersSelectModal
