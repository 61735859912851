import styled from 'styled-components'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'

export const Wrap = styled.div<{ backgroundColor: string; dataLoading: boolean }>`
    width: 100%;
    height: 152.5px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 16px 24px 16px 40px;
    background-color: ${({ backgroundColor, dataLoading }) => (dataLoading ? iOSColors.black : backgroundColor)};
    opacity: ${({ dataLoading }) => (dataLoading ? 0.1 : 1)};
`

export const ActionsWrap = styled.div`
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 5.5fr 1fr;
    margin-bottom: 30px;

    > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    > div:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    > div:last-child {
        display: flex;
        justify-content: flex-end;

        svg {
            path {
                fill: ${colors.white};
            }
        }
    }
`

export const InfoWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > h1 {
        font-size: 1.462rem;
        font-weight: 500;
        color: ${colors.white};
    }
`

export const IconsWrap = styled.div`
    display: flex;
    align-items: center;
    column-gap: 20px;

    > svg {
        width: 24px;
        height: 24px;
    }
`
