import React, { useState, useEffect } from 'react'
import { TouchableOpacity, Text, View, TextInput, Image } from 'react-native-web'
import Hoverable from '../utils/hoverable/hoverable'

import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'

// *active  *type *hover

//TODO - Rewrite to functional TSX component
export const HoverableTextInput = props => {
    const text = props.text ? props.text : ''
    const placeholder_text = props.placeholderText ? props.placeholderText : 'Click to add title'
    const placeholder_font_weight = props.placeHolderFontWeight ? props.placeHolderFontWeight : '400'
    const [hover, setHover] = useState(false)
    const [inputActive, setInputActive] = useState(false)
    const [inputValue, setInputValue] = useState(text)

    const auto_focus = props.autoFocus !== undefined ? props.autoFocus : false
    useEffect(() => {
        if (auto_focus) {
            setInputActive(true)
        }
    }, [])

    const height = props.height ? props.height : 40
    const width = props.width ? props.width : '100%'

    const non_active_border_color = props.nonActiveBorderColor ? props.nonActiveBorderColor : iOSColors.white
    const active_border_color = props.activeBorderColor ? props.activeBorderColor : colors.green_spectaflow

    const text_padding_left = props.textPaddingLeft ? props.textPaddingLeft : 0
    const text_padding_right = props.textPaddingRight ? props.textPaddingRight : 0

    const text_input_padding_horizontal = props.textInputPaddingHorizontal ? props.textInputPaddingHorizontal : 12

    const justify_content = props.justifyContent ? props.justifyContent : 'flex-start'
    const text_input_background_color = props.textInputBackgroundColor ? props.textInputBackgroundColor : iOSColors.white

    const disabled = props.disabled ? props.disabled : false
    const show_icon = props.showIcon !== undefined ? props.showIcon : false

    const font_size = props.fontSize ? props.fontSize : 22
    const font_weight = props.fontWeight ? props.fontWeight : '400'
    let font_color = props.fontColor ? props.fontColor : iOSColors.black
    font_color = disabled && props.disabledFontColor ? props.disabledFontColor : font_color

    const letter_spacing = props.letterSpacing ? props.letterSpacing : 'normal'

    const DEBUG_BORDER = 0
    const img_scale = 0.7

    const BORDER_RADIUS = 6

    return (
        <View
            style={{
                borderWidth: 0,
                borderColor: 'blue'
            }}>
            {!inputActive && (
                <Hoverable
                    onHoverIn={() => {
                        if (!disabled) {
                            setHover(true)
                        }
                    }}
                    onHoverOut={() => {
                        if (!disabled) {
                            setHover(false)
                        }
                    }}>
                    <TouchableOpacity
                        style={{
                            backgroundColor: text_input_background_color,
                            width: width,
                            minHeight: height,
                            flexDirection: 'row',
                            justifyContent: justify_content,
                            alignItems: 'center',
                            marginTop: 0,
                            borderWidth: 1,
                            borderColor: hover ? active_border_color : non_active_border_color,
                            borderRadius: BORDER_RADIUS,
                            paddingVertical: 6
                        }}
                        disabled={disabled}
                        onPress={() => {
                            setInputActive(true)
                            setInputValue(text)
                            if (props.isActiveCallback) {
                                props.isActiveCallback(true)
                            }
                        }}>
                        {!inputActive && text === '' && (
                            <span
                                style={{
                                    paddingLeft: text_padding_left,
                                    paddingRight: text_padding_right,
                                    fontWeight: placeholder_font_weight,
                                    fontSize: font_size,
                                    letterSpacing: letter_spacing,
                                    color: iOSColors.midGray
                                }}>
                                {placeholder_text}
                            </span>
                        )}
                        {!inputActive && text !== '' && (
                            <span
                                style={{
                                    paddingLeft: text_padding_left,
                                    paddingRight: text_padding_right,
                                    fontWeight: font_weight,
                                    fontSize: font_size,
                                    letterSpacing: letter_spacing,
                                    color: font_color,
                                    flexWrap: 'wrap'
                                }}>
                                {text}
                            </span>
                        )}
                    </TouchableOpacity>
                </Hoverable>
            )}
            {inputActive && (
                <View
                    style={{
                        backgroundColor: text_input_background_color,
                        width: width,
                        height: height,
                        minHeight: height,
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: justify_content,
                        borderWidth: 1,
                        borderColor: colors.green_spectaflow,
                        borderRadius: BORDER_RADIUS
                    }}>
                    <TextInput
                        autoFocus={true}
                        autogrow
                        multiline={props.multiline}
                        style={[
                            {
                                minHeight: height,
                                paddingHorizontal: text_input_padding_horizontal,
                                fontSize: font_size,
                                fontWeight: font_weight,
                                color: font_color,
                                textAlign: justify_content,
                                outlineStyle: 'none',
                                letterSpacing: letter_spacing,
                                width: '100%'
                            }
                        ]}
                        onBlur={() => {
                            setHover(false)
                            setInputActive(false)
                            if (props.isActiveCallback) {
                                props.isActiveCallback(false)
                            }

                            if (props.onBlur) {
                                props.onBlur()
                            }

                            props.onSubmit(inputValue)
                        }}
                        onChangeText={text => {
                            if (props.onChangeText) {
                                text = props.onChangeText(text)
                            }
                            setInputValue(text)
                        }}
                        value={inputValue}
                    />

                    {show_icon && (
                        <TouchableOpacity
                            onPress={() => {
                                props.onSubmit(inputValue)
                            }}
                            style={{ marginLeft: 14, marginRight: 9 }}>
                            <Image
                                style={{ width: 21 * img_scale, height: 15 * img_scale }}
                                source={require('@shared/img/close-textbox.svg')}
                            />
                        </TouchableOpacity>
                    )}
                </View>
            )}
        </View>
    )
}
