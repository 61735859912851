import React, { Component } from 'react'
import { findNodeHandle, TextInput, TouchableOpacity, View } from 'react-native-web'
import firebase, { asFirebase } from '../utils/firebase'
import { iOSColors, webWeights } from '@shared/react-native-typography'

import * as ImageHandler from '../utils/image-handler'
import * as helpers from '@shared/helpers'
import * as issueData from '@shared/issue-data'
import Hoverable from '../utils/hoverable/hoverable'
import { HoverableImageButton } from '../components/buttons/hoverable-image-button'
import * as uc from '../utils/constants'
import { IssueItem, UserStruct } from '@shared/firestore-structs'
import { TextStyle } from 'react-native'

type ItemViewProps = {
    item: IssueItem
    respWidth: string
    respHeight: string
    sharedIssueStyle: any
    closeModal: () => void
    editable: boolean
    onDeleteItemPress: (item: IssueItem) => void
    issueKey: string
    currentUser: UserStruct
}
type ItemViewState = {
    respWidth: string
    respHeight: string
    showActionButtons: boolean
    isImagedLoaded: boolean
    textInputActive: boolean
    textInputValue: string
}
export default class ItemView extends Component<ItemViewProps, ItemViewState> {
    canvasName: string
    respWidth: string
    respHeight: string
    state: ItemViewState = {
        isImagedLoaded: false,

        showActionButtons: !!(this.props.sharedIssueStyle && this.props.sharedIssueStyle.mobile),
        respWidth: this.props.respWidth,
        respHeight: this.props.respHeight,
        textInputActive: false,
        textInputValue: this.props.item.text ? this.props.item.text.content : ''
    }
    borderRadius: string | number | undefined
    constructor(props: ItemViewProps) {
        super(props)
        this.canvasName = 'canvas' + Math.random()

        this.respWidth = this.props.respWidth
        this.respHeight = this.props.respHeight
        this.imageLoaded = this.imageLoaded.bind(this)
        this.renderImage = this.renderImage.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleOutsideClick = this.handleOutsideClick.bind(this)
    }

    componentDidMount() {
        this.renderImage()
    }

    UNSAFE_componentWillReceiveProps(newProps: ItemViewProps) {
        if (newProps.respWidth) {
            this.respWidth = newProps.respWidth
            this.setState({ respWidth: newProps.respWidth })
        }
    }

    renderImage() {
        const imageUrl = this.props.item.url
        const borderRadius = '4px'
        const orientation = true

        const image = ImageHandler.renderImage(
            imageUrl,
            orientation,
            this.canvasName,
            this.respWidth,
            this.respHeight,
            borderRadius,
            false,
            false
        )
        image.then(im => {
            setTimeout(() => {
                this.imageLoaded()
            }, 650)
        })
    }

    imageLoaded() {
        this.setState({ isImagedLoaded: true })
    }

    render() {
        const isMobile = !!(this.props.sharedIssueStyle && this.props.sharedIssueStyle.mobile)
        let buttonDim = 28

        if (isMobile) {
            buttonDim = 24
        }

        return (
            <TouchableOpacity
                style={{
                    width: this.state.respWidth,
                    height: this.state.respHeight,
                    flexDirection: 'row',
                    backgroundColor: iOSColors.black,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderWidth: 0,
                    borderColor: 'yellow'
                }}
                disabled={isMobile}
                onPress={() => {
                    if (!this.state.textInputActive) {
                        this.props.closeModal()
                    }
                }}>
                <Hoverable
                    onHoverIn={() => {
                        this.setState({ showActionButtons: true })
                    }}
                    onHoverOut={() => {
                        this.setState({ showActionButtons: true })
                    }}>
                    <div
                        id={this.canvasName}
                        style={{
                            // position: 'relative',
                            width: this.state.respWidth,
                            height: this.state.respHeight,
                            maxWidth: this.state.respWidth,
                            borderRadius: this.borderRadius,
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: 10,
                            paddingLeft: 10
                        }}>
                        {this.state.showActionButtons && (
                            <View
                                style={{
                                    width: this.state.respWidth,
                                    position: 'absolute',
                                    top: 24,
                                    left: 0,
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    borderColor: 'red',
                                    borderWidth: 0,
                                    paddingLeft: 24,
                                    paddingRight: 24
                                }}>
                                <View style={{ flexDirection: 'row' }}>
                                    {this.props.editable && (
                                        <View
                                            style={{
                                                marginRight: 32
                                            }}>
                                            <HoverableImageButton
                                                dimension={buttonDim}
                                                hoverOpacity={uc.HOVER_OPACITY}
                                                imageSource={require('../img/trash.svg')}
                                                onPress={() => {
                                                    this.props.onDeleteItemPress(this.props.item)
                                                    this.props.closeModal()
                                                }}
                                                tooltipText={'Delete photo'}
                                                // tooltipFontSize={12}
                                            />
                                        </View>
                                    )}

                                    {this.props.editable && (
                                        <HoverableImageButton
                                            dimension={buttonDim}
                                            hoverOpacity={uc.HOVER_OPACITY}
                                            imageSource={require('../img/add-item-text.svg')}
                                            onPress={() => {
                                                this.setState({ textInputActive: true })
                                            }}
                                            tooltipText={'Change text'}
                                            // tooltipFontSize={12}
                                        />
                                    )}
                                </View>
                                <HoverableImageButton
                                    dimension={buttonDim}
                                    hoverOpacity={uc.HOVER_OPACITY}
                                    imageSource={require('@shared/img/close.svg')}
                                    onPress={() => {
                                        this.props.closeModal()
                                    }}
                                    tooltipText={'Close window'}
                                    // tooltipFontSize={12}
                                />
                            </View>
                        )}
                        {this.state.isImagedLoaded && this.renderText()}
                    </div>
                </Hoverable>
            </TouchableOpacity>
        )
    }

    onSubmit() {
        const text = {
            content: this.state.textInputValue,
            position: 0
        }
        if (helpers.isItemTextAvailable(this.props.item)) {
            text.position = this.props.item.text!.position
        } else {
            text.position = 350
        }

        this.props.item.text = text

        issueData.updateImageItemText(asFirebase(firebase), this.props.issueKey, this.props.item.key, text, this.props.currentUser)

        this.setState({ textInputActive: false })
    }

    handleClick() {
        if (!this.state.textInputActive) {
            document.addEventListener('click', this.handleOutsideClick, false)
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false)
        }

        this.setState(prevState => ({
            textInputActive: !prevState.textInputActive
        }))
    }

    handleOutsideClick(e: Event) {
        // @ts-ignore
        const handle = findNodeHandle(this.node)
        // @ts-ignore
        if (handle!.contains(e.target)) {
            return
        }

        this.handleClick()
    }

    renderText() {
        let fontSize = 17
        let backgroundHeight = 25

        if (this.props.sharedIssueStyle && this.props.sharedIssueStyle.mobile) {
            fontSize = 16
            backgroundHeight = 18
        }
        const text = {
            content: '',
            position: undefined as number | undefined
        }
        if (helpers.isItemTextAvailable(this.props.item)) {
            text.content = this.props.item.text!.content
            text.position = this.props.item.text!.position
        }
        const baseHeight = this.respHeight

        let percentText: string | null = null
        if (text.position === undefined || text.position === null) {
            percentText = '50%'
        } else {
            percentText = 'NaN%'
        }

        return (
            <View
                style={{
                    position: 'absolute',
                    top: percentText,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    height: backgroundHeight,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: this.state.textInputActive ? 1 : 0,
                    borderColor: iOSColors.white
                }}>
                {!this.state.textInputActive && text.content !== undefined && (
                    <TouchableOpacity
                        disabled={!this.props.editable}
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: this.state.respWidth
                        }}
                        onPress={() => {
                            this.setState({
                                textInputActive: true,
                                textInputValue: text.content
                            })
                        }}>
                        <span
                            style={{
                                color: iOSColors.white,
                                fontSize
                            }}>
                            {this.props.item.text!.content}
                        </span>
                    </TouchableOpacity>
                )}
                {this.props.editable && this.state.textInputActive && (
                    <TextInput
                        style={[
                            // @ts-ignore
                            webWeights.regular,
                            {
                                fontSize,
                                color: iOSColors.white,

                                // @ts-ignore
                                outlineStyle: 'none',
                                width: this.state.respWidth,
                                textAlign: 'center'
                            } as TextStyle
                        ]}
                        onBlur={e => {
                            this.handleClick()
                            this.onSubmit()
                        }}
                        onChangeText={text => {
                            this.setState({ textInputValue: text })
                        }}
                        value={this.state.textInputValue}
                    />
                )}
            </View>
        )
    }
}
