import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import Introduction from './Introduction'
import SignUpForm from './SignUpForm'
import { SignUpLayoutWrapper } from './style'

const SignUpLayout = ({ children, withModal }) => {
    const [codeVerificationModalIsOpen, setCodeVerificationModalIsOpen] = useState(false)

    return (
        <SignUpLayoutWrapper>
            <Introduction />
            <SignUpForm setCodeVerificationModalIsOpen={setCodeVerificationModalIsOpen} />

            {(codeVerificationModalIsOpen || withModal) && children}
            <ToastContainer />
        </SignUpLayoutWrapper>
    )
}

export default SignUpLayout
