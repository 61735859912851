import styled from 'styled-components'
import * as colors from '@shared/colors'

export const Wrapper = styled.div`
    padding-left: 20px;
    width: 100%;
    height: 65px;
    display: grid;
    align-items: center;
    background-color: ${colors.hex2rgb(colors.gray_sweeply, 0.1)};
    grid-template-columns: 0.2fr 0.5fr 4fr 0.7fr;
`

export const LogoWrap = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 26px;
`

export const NavMenuWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 25px;
    @media (max-width: 1150px) {
        column-gap: 45px;
    }
    @media (max-width: 929px) {
        column-gap: 30px;
    }
    @media (max-width: 853px) {
        column-gap: 15px;
    }
`

export const UserWrap = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`
