import { AreaCleaningStatus, AreaStruct, BookingStruct, Occupancy, TaskStruct, UserStruct } from '@shared/firestore-structs'
import * as c from '@shared/constants'

export interface GroupOfAreas {
    group: string
    areas: AreaStruct[]
}

export type CleaningStatusFilterValue = AreaCleaningStatus | typeof c.CLEANING_STATUS_ALL

export interface CleaningStatusFilterOption {
    label: string
    value: CleaningStatusFilterValue
    isSelected: boolean
}

export type OccupancyFilterValue = Occupancy | typeof c.OCCUPANCY_ALL

export interface SummaryNote {
    name: string
    note: string
    group?: string
    description?: string
}

export type SummaryTabType = typeof c.ACTIVITY_TYPE_DAILY_COMMENT | typeof c.ACTIVITY_TYPE_AREA_NOTE | typeof c.BOOKING_NOTE

export interface SummaryTab {
    icon: string
    title: string
    count: number
    type: SummaryTabType
    isActiveTab: boolean
}

export const HOUSEKEEPING_SEARCH_VALUE_KEY = 'HOUSEKEEPING_SEARCH_VALUE_KEY'

export enum AssignFilterMode {
    ALL = 'all',
    UN_ASSIGNED = 'un-assigned',
    ASSIGNED = 'assigned'
}

export interface WorkloadCounts {
    unclean50Count: number
    unclean80Count: number
    unclean100Count: number
    checkInCount: number
    noServiceCount: number
    otherWorkCount?: number
}

export type Assignee = Pick<UserStruct, 'key' | 'name' | 'initials'>
export type AssigneeWithCountAndAreas = Assignee & { count: number; areas: AreaStruct[] }

export interface TeamWorkloadGroup {
    group: string
    users: AssigneeWithCountAndAreas[]
}

export type LastCleaningTask = TaskStruct & { cleaning: NonNullable<TaskStruct['cleaning']> }

export interface CleaningScheduledDate {
    date: number
    subheader: string
    cleaningStatus: AreaCleaningStatus
    occupancy: Occupancy
}

export interface CleaningScheduleSection {
    month: string
    schedule: CleaningScheduledDate[]
}

export type CleaningScheduleUpdateValue = BookingStruct['optInDates'] | BookingStruct['optOutDates']
