import React from 'react'
import { PriceSubtitle, PriceContainer, PriceWrapper } from '../style'

const Price = ({ price, period, isLineThrough, isPro, hasExtraDetails }) => {
    const freeCondition = !isPro && (
        <PriceSubtitle className="d-flex justify-content-center align-items-center font-size-h6">{'Free forever'}</PriceSubtitle>
    )

    const periodCondition = isPro && (
        <PriceSubtitle className="d-flex justify-content-center align-items-center font-size-h6 ">
            Room / {period.periodNumber > 1 ? period.periodNumber : null}{' '}
            {period.periodNumber > 1 ? period.periodUnit + '`s' : period.periodUnit}
        </PriceSubtitle>
    )

    return (
        <PriceContainer>
            <PriceWrapper hasExtraDetails={hasExtraDetails}>
                <span style={{ textDecoration: isLineThrough ? 'line-through' : 'none' }} className="pr-2 font-weight-bolder">
                    {price}
                </span>
            </PriceWrapper>
            {/*{freeCondition}*/}
            {periodCondition}
        </PriceContainer>
    )
}

export default Price
