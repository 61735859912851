import { View } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import React, { useContext } from 'react'
import { Checkbox } from '../../../components/forms/Checkbox'
import { TaskScreenContext } from './TaskScreenContext'
import { UNIT_HEADER_FONT_SIZE } from './consts'

const Checklist = () => {
    const { currentTask } = useContext(TaskScreenContext)
    return (
        <View
            style={{
                display: 'flex',
                flex: 1
            }}>
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderColor: 'red',
                    marginBottom: 6
                }}>
                <span
                    style={{
                        marginTop: 2,
                        fontWeight: '600',
                        color: iOSColors.black,
                        fontSize: UNIT_HEADER_FONT_SIZE
                    }}>
                    Checklist
                </span>
            </View>
            <View>
                <div>
                    {currentTask.checklist.map((t: any, i: number) => {
                        return (
                            <div key={t.name + i} className="d-flex my-5">
                                <div>
                                    <Checkbox isSelected={t.checked} disabled={true} />
                                </div>
                                <div style={{ color: iOSColors.gray }} className="w-75 font-size-lg font-weight-bold">
                                    {t.name}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </View>
        </View>
    )
}

export default Checklist
