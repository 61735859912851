import React, { useState, useCallback } from 'react'
import { Header, OverdueImage } from './style'
import { Row } from '../style'
import { MinimizeButton, Title, Count } from '../ColumnHeader/style'
import { TaskStruct } from '@shared/dataObjects'
import TaskRow from '../TaskRow'
import OverdueIcon from 'app/img/warning-icon.svg'
import { TasksSectionTitle } from '@shared/task-types'
import { throttle } from 'lodash'

interface Props {
    tasks: TaskStruct[]
    title: TasksSectionTitle
    onHousekeepingRowClick: (task: TaskStruct) => void
    onIssueRowClick: (issueKey: string) => void
    onGeneralRowClick: (task: TaskStruct) => void
    isMinimized: boolean
}

function Subsection({ tasks, title, onGeneralRowClick, onHousekeepingRowClick, onIssueRowClick, isMinimized }: Props) {
    const [isSectionOpen, setIsSectionOpen] = useState(true)
    const onMinimizeClickThrottled = useCallback(
        throttle(() => {
            setIsSectionOpen(prevIsSectionOpen => !prevIsSectionOpen)
        }, 500),
        []
    )
    return (
        <div>
            <Header>
                <Title>{title}</Title>
                <Count>({tasks?.length})</Count>
                <MinimizeButton isMinimized={isSectionOpen} onClick={onMinimizeClickThrottled}>
                    <i className="specta-arrow-right" />
                </MinimizeButton>
                {title === TasksSectionTitle.OVERDUE && <OverdueImage src={OverdueIcon as string} />}
            </Header>

            {tasks.map(task => {
                const { key } = task

                return (
                    <Row key={key} style={{ marginBottom: 16 }}>
                        <TaskRow
                            task={task}
                            onHousekeepingRowClick={onHousekeepingRowClick}
                            onIssueRowClick={onIssueRowClick}
                            onGeneralRowClick={onGeneralRowClick}
                            isMinimized={isMinimized}
                            isVisible={isSectionOpen}
                        />
                    </Row>
                )
            })}
        </div>
    )
}

export default Subsection
