export const iOSUIKit = {
    largeTitleEmphasizedObject: { fontSize: 34, lineHeight: 41, fontFamily: 'System', fontWeight: '700', color: '#000000' },
    title3Object: { fontSize: 20, lineHeight: 25, fontFamily: 'System', fontWeight: '400', color: '#000000' },
    title3EmphasizedObject: { fontSize: 20, lineHeight: 25, fontFamily: 'System', fontWeight: '600', color: '#000000' },
    bodyObject: { fontSize: 17, lineHeight: 22, fontFamily: 'System', fontWeight: '400', color: '#000000' },
    bodyEmphasizedObject: { fontSize: 17, lineHeight: 22, fontFamily: 'System', fontWeight: '600', color: '#000000' },
    subheadObject: { fontSize: 15, lineHeight: 20, fontFamily: 'System', fontWeight: '400', color: '#000000' },
    subheadShortObject: { fontSize: 15, lineHeight: 18, fontFamily: 'System', fontWeight: '400', color: '#000000' },
    subheadEmphasizedObject: { fontSize: 15, lineHeight: 20, fontFamily: 'System', fontWeight: '600', color: '#000000' },
    calloutObject: { fontSize: 16, lineHeight: 21, fontFamily: 'System', fontWeight: '400', color: '#000000' },
    footnoteObject: { fontSize: 13, lineHeight: 18, fontFamily: 'System', fontWeight: '400', color: '#000000' },
    footnoteEmphasizedObject: { fontSize: 13, lineHeight: 18, fontFamily: 'System', fontWeight: '600', color: '#000000' },
    caption2Object: { fontSize: 11, lineHeight: 13, fontFamily: 'System', fontWeight: '400', color: '#000000' },
    caption2EmphasizedObject: { fontSize: 11, lineHeight: 13, fontFamily: 'System', fontWeight: '600', color: '#000000' },
    largeTitleEmphasizedWhiteObject: { fontSize: 34, lineHeight: 41, fontFamily: 'System', fontWeight: '700', color: '#FFFFFF' },
    title3WhiteObject: { fontSize: 20, lineHeight: 25, fontFamily: 'System', fontWeight: '400', color: '#FFFFFF' },
    title3EmphasizedWhiteObject: { fontSize: 20, lineHeight: 25, fontFamily: 'System', fontWeight: '600', color: '#FFFFFF' },
    bodyWhiteObject: { fontSize: 17, lineHeight: 22, fontFamily: 'System', fontWeight: '400', color: '#FFFFFF' },
    bodyEmphasizedWhiteObject: { fontSize: 17, lineHeight: 22, fontFamily: 'System', fontWeight: '600', color: '#FFFFFF' },
    subheadWhiteObject: { fontSize: 15, lineHeight: 20, fontFamily: 'System', fontWeight: '400', color: '#FFFFFF' },
    subheadShortWhiteObject: { fontSize: 15, lineHeight: 18, fontFamily: 'System', fontWeight: '400', color: '#FFFFFF' },
    subheadEmphasizedWhiteObject: { fontSize: 15, lineHeight: 20, fontFamily: 'System', fontWeight: '600', color: '#FFFFFF' },
    calloutWhiteObject: { fontSize: 16, lineHeight: 21, fontFamily: 'System', fontWeight: '400', color: '#FFFFFF' },
    footnoteWhiteObject: { fontSize: 13, lineHeight: 18, fontFamily: 'System', fontWeight: '400', color: '#FFFFFF' },
    footnoteEmphasizedWhiteObject: { fontSize: 13, lineHeight: 18, fontFamily: 'System', fontWeight: '600', color: '#FFFFFF' },
    caption2WhiteObject: { fontSize: 11, lineHeight: 13, fontFamily: 'System', fontWeight: '400', color: '#FFFFFF' },
    caption2EmphasizedWhiteObject: { fontSize: 11, lineHeight: 13, fontFamily: 'System', fontWeight: '600', color: '#FFFFFF' },
    largeTitleEmphasized: 204,
    title3: 205,
    title3Emphasized: 206,
    body: 207,
    bodyEmphasized: 208,
    subhead: 209,
    subheadShort: 210,
    subheadEmphasized: 211,
    callout: 212,
    footnote: 213,
    footnoteEmphasized: 214,
    caption2: 215,
    caption2Emphasized: 216,
    largeTitleEmphasizedWhite: 217,
    title3White: 218,
    title3EmphasizedWhite: 219,
    bodyWhite: 220,
    bodyEmphasizedWhite: 221,
    subheadWhite: 222,
    subheadShortWhite: 223,
    subheadEmphasizedWhite: 224,
    calloutWhite: 225,
    footnoteWhite: 226,
    footnoteEmphasizedWhite: 227,
    caption2White: 228,
    caption2EmphasizedWhite: 229
}
export const iOSColors = {
    red: '#FF3B30',
    orange: '#FF9500',
    yellow: '#FFCC00',
    green: '#4CD964',
    tealBlue: '#5AC8FA',
    blue: '#007AFF',
    purple: '#5856D6',
    pink: '#FF2D55',
    white: '#FFFFFF',
    customGray: '#EFEFF4',
    lightGray: '#E5E5EA',
    lightGray2: '#D1D1D6',
    midGray: '#C7C7CC',
    gray: '#8E8E93',
    black: '#000000'
}
export const webWeights = {
    thin: { fontFamily: 'System', fontWeight: '100' },
    ultraLight: { fontFamily: 'System', fontWeight: '200' },
    light: { fontFamily: 'System', fontWeight: '300' },
    regular: { fontFamily: 'System', fontWeight: '400' },
    medium: { fontFamily: 'System', fontWeight: '500' },
    semibold: { fontFamily: 'System', fontWeight: '600' },
    bold: { fontFamily: 'System', fontWeight: '700' },
    heavy: { fontFamily: 'System', fontWeight: '800' },
    black: { fontFamily: 'System', fontWeight: '900' }
}
