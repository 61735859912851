import { useEffect, useMemo, useState } from 'react'
import { INSIDE_IFRAME } from '../utils/constants'
import { TASKBOARD } from 'app/navigation/url-constants'
import { useHistory } from 'react-router-dom'
import { LOCAL_STORAGE } from '../utils/constants'
import { integrationParamsSelector } from './state/integrationParamsSelector'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { defineTaskboardContext, EMAIL_PASSWORD_LOGIN_ALLOWED_ACCOUNT_CODES } from './integration-utils'
import { integrationParamsAtom, lastKnownSearchParamsAtom, showEmailPasswordLoginLinkAtom } from './state/atoms'

export function useTracesInitialUrlCatcher() {
    const history = useHistory()
    const { pathname, search } = window.location
    const setShowEmailPasswordLoginLink = useSetRecoilState(showEmailPasswordLoginLinkAtom)

    useEffect(() => {
        if (INSIDE_IFRAME && pathname === TASKBOARD.APALEO) {
            const accountCode = new URLSearchParams(search).get('accountCode')
            window.localStorage.setItem(LOCAL_STORAGE.IFRAME_INITIAL_SEARCH_PARAMS, search)
            accountCode && EMAIL_PASSWORD_LOGIN_ALLOWED_ACCOUNT_CODES.includes(accountCode) && setShowEmailPasswordLoginLink(true)
        }
    }, [])

    useEffect(() => {
        if (INSIDE_IFRAME && pathname === TASKBOARD.APALEO && !search.includes('accountCode' as const)) {
            const initialSearchParams = window.localStorage.getItem(LOCAL_STORAGE.IFRAME_INITIAL_SEARCH_PARAMS)
            initialSearchParams && history.push(initialSearchParams)
        }
    }, [window.location.pathname])
}

export function useTracesIntegrationParams() {
    const { pathname, search } = window.location
    const [cachedSearch, setCachedSearch] = useRecoilState(lastKnownSearchParamsAtom)
    const finalSearch = search || cachedSearch
    if (finalSearch !== cachedSearch) setCachedSearch(finalSearch)

    const searchParams = new URLSearchParams(finalSearch)
    const accountCode = searchParams.get('accountCode')

    const integrationParamsLoadable = useRecoilValueLoadable(
        integrationParamsSelector({
            taskboardContext: accountCode ? defineTaskboardContext(pathname, finalSearch) : null,
            search: finalSearch
        })
    )
    const setIntegrationParams = useSetRecoilState(integrationParamsAtom)

    const [loadingIntegrationParams, setLoadingIntegrationParams] = useState(true)
    const [error, setError] = useState<Error | null>(null)

    const loading = !accountCode || loadingIntegrationParams

    useEffect(() => {
        switch (integrationParamsLoadable.state) {
            case 'loading':
                setLoadingIntegrationParams(true)
                break
            case 'hasValue':
                setIntegrationParams(integrationParamsLoadable.contents)
                setTimeout(() => setLoadingIntegrationParams(false))
                break
            case 'hasError':
                setLoadingIntegrationParams(false)
                console.error('(useTracesIntegrationParams)', integrationParamsLoadable.contents)
                setError(new Error('Failed to load data. Please try again.'))
                break
            default:
                break
        }
    }, [integrationParamsLoadable.state])

    return [loading, error]
}
