import React, { useState } from 'react'
import Radium from 'radium'
import * as c from '@shared/constants'
import { IssueStruct, UserStruct } from '@shared/dataObjects'
import * as issueData from '@shared/issue-data'
import * as uc from 'app/utils/constants'
import Hoverable from 'app/utils/hoverable/hoverable'
import firebase, { asFirebase } from 'app/utils/firebase'
import LeftBlock from './LeftBlock'
import IssueImage from './IssueImage'
import { IssueRowStyle } from '../../style'
import InfoBlock from './InfoBlock'

interface IssueRowProps {
    issue: IssueStruct
    checked: boolean
    currentUser: UserStruct
    onPressIssueRow: (issue: IssueStruct) => void
    addCheckedIssue: (issue: IssueStruct) => void
    removeCheckedIssue: (issue: IssueStruct) => void
    onPressHashTag: (hashtag: string) => void
    onSearchPress: (value: string) => void
    context?: string
    disabled?: boolean
}

export default function IssueRow1({
    issue,
    disabled,
    checked,
    currentUser,
    context,
    onPressIssueRow,
    addCheckedIssue,
    removeCheckedIssue,
    onPressHashTag,
    onSearchPress
}: IssueRowProps) {
    const [currentIssue, setCurrentIssue] = useState(issue)
    const isHousekeepingModal = context === uc.ISSUE_ROW_CONTEXT_HOUSEKEEPING_MODAL

    function onPressComplete() {
        const assignedOrOpen = currentIssue.assignedContacts && currentIssue.assignedContacts?.length > 0 ? c.ISSUE_ASSIGNED : c.ISSUE_OPEN
        const status = currentIssue.status === c.ISSUE_COMPLETE ? assignedOrOpen : c.ISSUE_COMPLETE
        const updatedIssue: IssueStruct = { ...currentIssue, status }
        setCurrentIssue(updatedIssue)

        if (issue.status === c.ISSUE_COMPLETE) {
            issueData.uncompleteIssue(asFirebase(firebase), {
                issueKey: issue.key,
                currentUser,
                assignedContacts: issue.assignedContacts,
                taskKey: issue?.taskKey
            })
        } else {
            issueData.completeIssue(asFirebase(firebase), {
                issueKey: issue.key,
                currentUser,
                taskKey: issue?.taskKey
            })
        }
    }

    return (
        <Radium.StyleRoot>
            <div>
                <Hoverable>
                    <IssueRowStyle
                        isHousekeeping={isHousekeepingModal}
                        disabled={disabled}
                        onClick={() => {
                            onPressIssueRow(issue)
                        }}>
                        {!isHousekeepingModal && (
                            <LeftBlock
                                issue={issue}
                                checked={checked}
                                addCheckedIssue={addCheckedIssue}
                                removeCheckedIssue={removeCheckedIssue}
                                onPressComplete={onPressComplete}
                            />
                        )}
                        <IssueImage issue={issue} isHouskeeping={isHousekeepingModal} />
                        <InfoBlock
                            issue={issue}
                            currentUser={currentUser}
                            isHousekeepingModal={isHousekeepingModal}
                            onPressHashTag={onPressHashTag}
                            onSearchPress={onSearchPress}
                        />
                    </IssueRowStyle>
                </Hoverable>
            </div>
        </Radium.StyleRoot>
    )
}
