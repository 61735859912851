import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { AlertWrap } from './style'
import RectangularButton from '../buttons/RectangularButton'

interface Props {
    title: string
    text?: string
    children?: React.ReactNode
    cancelBtnText?: string
    confirmBtnText?: string
    type?: string
    onConfirm: () => void
    onCancel: () => void
}

const Alert = ({
    title,
    type = 'default',
    text,
    onConfirm,
    onCancel,
    cancelBtnText = 'Cancel',
    confirmBtnText = 'Confirm',
    children
}: Props) => {
    return (
        <AlertWrap>
            {/* FIXME */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <SweetAlert
                title={title}
                customClass="alert-wrap"
                onCancel={onCancel}
                customButtons={
                    <>
                        {type !== 'success' && (
                            <div className="buttons-wrap">
                                <button onClick={onCancel} className="cancel-button">
                                    {cancelBtnText}
                                </button>
                                {/* TODO: add types to RectangularButton */}
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                <RectangularButton onClick={onConfirm} width={'196px'} height={'48px'}>
                                    {confirmBtnText}
                                </RectangularButton>
                            </div>
                        )}
                        {type === 'success' && (
                            <div className="buttons-wrap justify-content-center">
                                {/* TODO: add types to RectangularButton */}
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                <RectangularButton onClick={onConfirm} width={'196px'} height={'48px'}>
                                    {confirmBtnText}
                                </RectangularButton>
                            </div>
                        )}
                    </>
                }>
                {text}
                {children}
            </SweetAlert>
        </AlertWrap>
    )
}

export default Alert
