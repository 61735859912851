import React, { useContext, useEffect, useState } from 'react'
import Notifications from 'react-notify-toast'
import Modal from 'react-modal'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { aIssueModalStyle, IssuesWrapper, massHashTagModalStyle, massStatusModalStyle, newIssueModalStyle } from './style'
import { Card, CardBody } from '../../_metronic/_partials/controls'
import AIssue from './aissue-modal'
import { AuthContext } from '../modules/Auth/AuthContext'
import Header from './Header'
import SearchAndFilters from './SearchEndFilters'
import { UpgradeMessageContent } from '../components/upgrade-signals'

import {
    allIssuesCheckedAtom,
    checkedAreaGroupsAtom,
    checkedIssuesAtom,
    selectedIssueIdAtom,
    showActionBarAtom,
    showNewIssueModalAtom,
    subscriptionParamsAtom,
    thisPageCheckedAtom,
    upgradeMessageModalIsOpenAtom
} from './state/atoms'
import { SubscriptionContext } from '../modules/Subscriptions/SubscriptionContext'
import IssuesBody from './IssuesBody'
import * as responsive from '../utils/responsive'
import * as styles from '../housekeeping/dashboard/styles'
import ActionBar from './action-bar'
import firebase, { asFirebase } from '../utils/firebase'
import Alert from '../components/alerts/Alert'
import MassStatus from './mass-status'
import MassHashTag from './mass-hashtag'
import NewIssue from './new-issue'
import UsersList from '../users/users-list'
import { selectedIssueSelector } from './state/selectors'
import { IssueStruct, UserStruct } from '@shared/dataObjects'
import { deleteIssue, assignIssue, unassignIssue } from '@shared/issue-data'
import { width, height } from '../utils/styles'
import { AssignedUser, ConfirmationAlertData } from './types'
import { Needed } from '@shared/type-utils'

Modal.setAppElement('#root')

export default function IssuesScreen() {
    const { checkSubscription } = useContext(SubscriptionContext)
    const { currentUser } = useContext(AuthContext)
    const { currentOrganization } = useContext(AuthContext)
    const [upgradeMessageIsOpen, setUpgradeMessageIsOpen] = useRecoilState(upgradeMessageModalIsOpenAtom)
    const [subscriptionParams, setSubscriptionParams] = useRecoilState(subscriptionParamsAtom)
    const setSelectedIssueId = useSetRecoilState(selectedIssueIdAtom)
    const [checkedIssues, setCheckedIssues] = useRecoilState(checkedIssuesAtom)
    const [checkedAreaGroups, setCheckedAreaGroups] = useRecoilState(checkedAreaGroupsAtom)
    const [showActionBar, setShowActionBar] = useRecoilState(showActionBarAtom)
    const [showNewIssueModal, setShowNewIssueModal] = useRecoilState(showNewIssueModalAtom)
    const [showSelectUserModal, setShowSelectUserModal] = useState<boolean>(false)
    const [showConfirmationAlert, setShowConfirmationAlert] = useState<boolean>(false)
    const [confirmationAlertData, setConfirmationAlertData] = useState<ConfirmationAlertData>({
        alertTitle: '',
        alertText: '',
        alertOnConfirm: () => {}
    })
    const [showMassHashTagModal, setShowMassHashTagModal] = useState<boolean>(false)
    const [showMassStatusModal, setShowMassStatusModal] = useState<boolean>(false)
    const selectedIssue = useRecoilValue(selectedIssueSelector)
    const setAllIssuesChecked = useSetRecoilState(allIssuesCheckedAtom)
    const setThisPageChecked = useSetRecoilState(thisPageCheckedAtom)
    useEffect(() => {
        checkSubscription(
            'issues',
            () => setSubscriptionParams({ hasSubscriptionAccess: true, notAccessibleFeature: null, availableUpgrades: null }),
            (notAccessibleFeature, availableUpgrades) =>
                setSubscriptionParams({ hasSubscriptionAccess: false, notAccessibleFeature, availableUpgrades })
        )
    }, [])
    const clearCheckedIssues = () => {
        setCheckedIssues([])
        setCheckedAreaGroups([])
    }
    const setShowIssueActionBar = (val: boolean) => {
        setShowActionBar(val)
        setAllIssuesChecked(val)
        setThisPageChecked(val)
    }
    const closeUpgradeMessage = () => {
        setUpgradeMessageIsOpen(false)
    }
    const closeConfirmationAlert = () => {
        setShowConfirmationAlert(false)
    }
    const closeMassStatusModal = () => {
        setShowMassStatusModal(false)
    }
    const closeMassHashTagModal = () => {
        setShowMassHashTagModal(false)
    }
    const closeNewIssueModal = () => {
        setShowNewIssueModal(false)
    }
    const openSelectUserModal = () => {
        setShowSelectUserModal(true)
    }
    const openMassHashTagModal = () => {
        setShowMassHashTagModal(true)
    }
    const openMassStatusModal = () => {
        setShowMassStatusModal(true)
    }
    const closeSelectUserModal = () => {
        setShowSelectUserModal(false)
    }
    const onDeletePress = () => {
        const countIssues: number = checkedIssues.length
        const textIssues: string = countIssues > 1 ? ' issues?' : ' issue?'
        const alertOnConfirm = () => {
            checkedIssues.forEach((issue: IssueStruct) => {
                deleteIssue(asFirebase(firebase), { issueKey: issue.key, currentUser: currentUser! })
            })
            setShowConfirmationAlert(false)
            setShowActionBar(false)
            setCheckedIssues([])
        }
        setConfirmationAlertData({
            alertTitle: 'Deleting',
            alertText: 'Are you sure you want to delete ' + countIssues + textIssues,
            alertOnConfirm
        })
        setShowConfirmationAlert(true)
    }
    const onMassUnassign = () => {
        const countIssues: number = checkedIssues.length
        const textIssues: string = countIssues > 1 ? ' issues?' : ' issue?'
        const alertOnConfirm = (checkedIssues: IssueStruct[]) => {
            const issuesList: IssueStruct[] = [...checkedIssues].reverse()
            issuesList.forEach((issue: IssueStruct) => {
                unassignIssue(asFirebase(firebase), {
                    issueKey: issue.key,
                    currentUser: currentUser!,
                    taskKey: issue.taskKey as string
                })
            })
            clearCheckedIssues()
            closeConfirmationAlert()
            setShowIssueActionBar(false)
        }
        setConfirmationAlertData({
            alertTitle: 'Unassigning',
            alertText: 'Are you sure you want to unassign ' + countIssues + textIssues,
            alertOnConfirm: () => alertOnConfirm(checkedIssues)
        })
        setShowConfirmationAlert(true)
    }
    const onMassAssign = (assignedUsers: AssignedUser[]) => {
        closeSelectUserModal()
        setShowIssueActionBar(false)
        const issuesList: IssueStruct[] = [...checkedIssues].reverse()
        issuesList.forEach((issue: IssueStruct) => {
            const assignedContacts = assignedUsers.map((assignee: AssignedUser) => {
                return {
                    email: assignee.email ? assignee.email : null,
                    key: assignee.key,
                    name: assignee.name,
                    initials: assignee.initials,
                    phoneNumber: assignee.phoneNumber ? assignee.phoneNumber : null,
                    isUser: true,
                    appOS: assignee.appOS ? assignee.appOS : ''
                }
            })
            assignIssue(asFirebase(firebase), {
                issueKey: issue.key,
                assignedTo: assignedContacts as Needed<Partial<UserStruct>, 'key' | 'name' | 'initials'>[],
                currentUser: currentUser!,
                area: issue.area,
                taskKey: issue.taskKey,
                dueDate: issue.dueDate
            })
            clearCheckedIssues()
        })
    }
    const aIssueStyle = subscriptionParams.hasSubscriptionAccess
        ? aIssueModalStyle
        : { ...aIssueModalStyle, content: { ...aIssueModalStyle.content, overflowY: 'hidden' } }

    return (
        <IssuesWrapper>
            <Notifications options={{ animationDuration: 850, zIndex: 100000, top: 60 }} />
            {showConfirmationAlert && (
                // FIXME define types
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <Alert
                    title={confirmationAlertData.alertTitle}
                    text={confirmationAlertData.alertText}
                    onConfirm={confirmationAlertData.alertOnConfirm}
                    onCancel={closeConfirmationAlert}
                />
            )}
            <Modal
                /* FIXME define types */
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                /* @ts-ignore */
                style={massStatusModalStyle}
                isOpen={showMassStatusModal}
                onRequestClose={closeMassStatusModal}
                contentLabel="Open issue">
                <MassStatus
                    currentUser={currentUser!}
                    closeModal={closeMassStatusModal}
                    checkedIssues={checkedIssues}
                    clearCheckedIssues={clearCheckedIssues}
                    setShowIssueActionBar={setShowIssueActionBar}
                />
            </Modal>
            <Modal
                /* FIXME define types */
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                /* @ts-ignore */
                style={massHashTagModalStyle}
                isOpen={showMassHashTagModal}
                onRequestClose={closeMassHashTagModal}
                contentLabel="Open issue">
                <MassHashTag
                    currentUser={currentUser!}
                    defaultHashtags={currentOrganization ? currentOrganization.issueHashtags : ['#nohashtags-defined']}
                    closeModal={closeMassHashTagModal}
                    checkedIssues={checkedIssues}
                    clearCheckedIssues={clearCheckedIssues}
                    setShowIssueActionBar={setShowIssueActionBar}
                />
            </Modal>
            <Modal
                isOpen={upgradeMessageIsOpen}
                style={styles.upgradeMessageStyle}
                contentLabel="UpgradeMessages"
                onRequestClose={closeUpgradeMessage}>
                <UpgradeMessageContent
                    onClose={closeUpgradeMessage}
                    notAccessibleFeature={subscriptionParams.notAccessibleFeature}
                    availableUpgrades={subscriptionParams.availableUpgrades}
                    showCloseButton={true}
                    height={'100%'}
                />
            </Modal>
            {/* FIXME define types */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Modal isOpen={!!selectedIssue} style={aIssueStyle} onRequestClose={() => setSelectedIssueId('')} contentLabel="Open issue">
                <AIssue
                    currentUser={currentUser}
                    issue={selectedIssue}
                    currentOrganization={currentOrganization}
                    closeModal={() => setSelectedIssueId('')}
                    respWidth={responsive.getWidthModalAIssue(width)}
                    respHeight={responsive.getHeightAIssueModal(height)}
                />
            </Modal>
            {/* FIXME define types */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Modal isOpen={showNewIssueModal} onRequestClose={closeNewIssueModal} style={newIssueModalStyle} contentLabel="New issue">
                <NewIssue
                    closeModal={closeNewIssueModal}
                    defaultHashtags={currentOrganization ? currentOrganization.issueHashtags : ['#nohashtags-defined']}
                />
            </Modal>
            <Modal isOpen={showSelectUserModal} onRequestClose={closeSelectUserModal} style={styles.userListStyle} contentLabel="User">
                <UsersList
                    closeModal={closeSelectUserModal}
                    currentUser={currentUser}
                    onAssignPress={onMassAssign}
                    areaGroups={checkedAreaGroups}
                />
            </Modal>
            {/* FIXME define types */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Card className="card-no-shadow">
                <Header />
                <div style={{ zIndex: 100 }}>
                    {showActionBar && (
                        <ActionBar
                            openUserList={openSelectUserModal}
                            checkedIssues={checkedIssues}
                            clearCheckedIssues={clearCheckedIssues}
                            setShowIssueActionBar={setShowIssueActionBar}
                            onDeletePress={onDeletePress}
                            openMassHashTagModal={openMassHashTagModal}
                            openMassStatusModal={openMassStatusModal}
                            onMassUnassign={onMassUnassign}
                        />
                    )}
                </div>
                {/* FIXME define types */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <CardBody>
                    <SearchAndFilters />
                    <IssuesBody />
                </CardBody>
            </Card>
        </IssuesWrapper>
    )
}
