import { toast } from 'react-toastify'
import AsyncStorage from '@react-native-community/async-storage'
import { LAST_TASK_ASSIGNEE } from '@shared/task-types'
import { toastErrorStyle } from './styles'
import { TaskStruct, UserStruct } from '@shared/firestore-structs'
import { getMiniUserObject } from '@shared/dataObjects'
import { getBrand } from '@shared/brand'
import * as colors from '@shared/colors'
import * as uc from './constants'
import { Dispatch, SetStateAction } from 'react'

export async function getLastAssignee(): Promise<TaskStruct['assignedTo']> {
    try {
        const assignee = await AsyncStorage.getItem(LAST_TASK_ASSIGNEE)

        if (!assignee) {
            toast.error('No last assignee found', toastErrorStyle)
            return
        }

        return JSON.parse(assignee)
    } catch (error) {
        console.error(error)
        toast.error('Failed to set assignee. Please, try again', toastErrorStyle)
    }
}

export function setLastAssignee(users: UserStruct[] | TaskStruct['assignedTo']): void {
    const assignee = users?.map(getMiniUserObject)
    AsyncStorage.setItem(LAST_TASK_ASSIGNEE, JSON.stringify(assignee))
}

export function getPhotoWrapBackgroundColor(userKey: string, currentUserKey: string) {
    return userKey === currentUserKey ? getBrand().navBarColor : colors.green_spectaflow
}

export function detectDevice(window: Window): typeof uc.DEVICES[keyof typeof uc.DEVICES] {
    const userAgent = window.navigator.userAgent || window.navigator.vendor

    if (/android/i.test(userAgent)) {
        return uc.DEVICES.ANDROID
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return uc.DEVICES.IOS
    }

    return uc.DEVICES.DESKTOP
}

export function setInitialState<T, I>(value: T, setState: Dispatch<SetStateAction<T | I>>, initValue: I, condition = true): () => void {
    if (condition) setState(value)
    return () => {
        setState(initValue)
    }
}
