import { iOSColors } from '@shared/react-native-typography'
import styled from 'styled-components'
import { gentle_gray_spectaflow } from '@shared/colors'

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const FilterButtonWrap = styled.div`
    position: relative;
`

export const ResetButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    color: ${iOSColors.red};
    font-size: 1.077rem;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        background-color: ${gentle_gray_spectaflow};
    }
`
