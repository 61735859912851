import React, { useContext, useMemo } from 'react'
import { Formik } from 'formik'
import { isEqual } from 'lodash'
import { AreasContext } from '../../AreasContext'
import { AreaStatusConstants, AreaStatusTitles } from '../../AreasUIHelpers'
import { SearchInput } from '../../../../components/forms/SearchInput'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'
import Select, { components, createFilter } from 'react-select'

const prepareFilter = (queryParams, values) => {
    const { status, group, synced, searchText } = values
    const newQueryParams = { ...queryParams }
    const filter = {}
    // Filter by status
    filter.status = status !== '' ? status : ''
    filter.group = group !== '' ? group.map(x => x.label) : ''
    filter.synced = synced !== '' ? synced : ''

    if (group === 'All') {
        filter.group = ''
    }
    // Filter by type
    // filter.type = type !== "" ? +type : undefined;
    // Filter by all fields
    filter.name = searchText
    filter.description = searchText
    if (searchText) {
        filter.name = searchText
    }
    newQueryParams.filter = filter
    return newQueryParams
}

const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'start'
}

const selectStyles = {
    control: styles => ({
        ...styles,
        borderWidth: 1,
        borderColor: '#e4e6ef',
        borderRadius: 6,
        boxShadow: 'none',
        minHeight: '38.39px',
        ':hover': {
            borderWidth: 1,
            borderRadius: 6,
            borderColor: colors.green_spectaflow
        }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected || isFocused ? iOSColors.customGray : null,
        color: data.isFixed ? colors.green_spectaflow : isSelected ? colors.textPrimary : colors.textPrimary,
        cursor: isDisabled ? 'default' : 'pointer'
    }),
    placeholder: styles => {
        return [
            {
                ...styles,
                color: '#bab9c7'
            }
        ]
    },
    multiValue: styles => {
        return [
            {
                ...styles,
                paddingLeft: 4,
                paddingRight: 2,
                height: 26,
                alignItems: 'center'
            }
        ]
    },
    multiValueLabel: styles => {
        return {
            ...styles
        }
    },
    multiValueRemove: styles => {
        return [
            {
                ...styles,
                color: colors.dark_grey_spectaflow,
                ':hover': {
                    height: 24,
                    borderRadius: 23,
                    color: colors.blue_spectaflow
                }
            }
        ]
    },
    indicatorSeparator: styles => ({
        ...styles,
        backgroundColor: '#f4f5f6',
        width: 0
    }),
    indicatorsContainer: styles => ({
        ...styles,
        padding: 0
    }),
    dropdownIndicator: styles => ({
        ...styles,
        padding: '0 10px 0 0'
    }),
    clearIndicator: style => ({
        ...style,
        paddingRight: 0,
        marginRight: 0,
        marginBottom: 2,
        padding: 0
    }),
    valueContainer: base => ({
        ...base,
        height: '100%',
        // marginBottom: 3,
        paddingLeft: 5,
        maxHeight: 200
    }),
    menuList: base => ({
        ...base,
        padding: 0,
        minHeight: 200,
        height: 200
    })
}

export function AreasFilter(props) {
    // Customers UI Context
    const { allAreas, queryParams, setQueryParams } = useContext(AreasContext)
    const customersUIProps = useMemo(() => {
        return {
            queryParams: queryParams,
            setQueryParams: setQueryParams
        }
    }, [queryParams, setQueryParams])

    const allGroups = allAreas.map(a => a.group).filter((x, i, a) => a.indexOf(x) === i)

    const groups = allGroups.map(ag => {
        return { value: ag, label: ag }
    })
    groups.unshift({ value: 'All', label: 'All' })

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <span className="custom-dropdown-indicator" />
            </components.DropdownIndicator>
        )
    }

    // queryParams, setQueryParams,
    const applyFilter = values => {
        const newQueryParams = prepareFilter(customersUIProps.queryParams, values)
        if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
            // update list by queryParams
            customersUIProps.setQueryParams(newQueryParams)
        }
    }
    const columnWidth = 12 / (props.fields.length + 1)

    return (
        <>
            <Formik
                initialValues={{
                    status: '',
                    searchText: '',
                    group: queryParams.filter.group.map(x => {
                        return { value: x, label: x }
                    }),
                    synced: ''
                }}
                onSubmit={values => {
                    applyFilter(values)
                }}>
                {({ values, handleSubmit, handleBlur, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="form form-label-right">
                        <div className="form-group row">
                            <div className={'col-lg-' + columnWidth}>
                                <SearchInput
                                    handleBlur={handleBlur}
                                    handleSubmit={handleSubmit}
                                    setFieldValue={setFieldValue}
                                    fieldValueToSet="searchText"
                                    placeholderText="Name, status, group and more"
                                    initValue=""
                                />
                            </div>

                            {props.fields.includes('group') && (
                                <div className={'col-lg-' + columnWidth}>
                                    <Select
                                        value={values.group}
                                        placeholder="Filter by Group"
                                        isMulti
                                        name="group"
                                        components={{ DropdownIndicator }}
                                        options={groups}
                                        styles={selectStyles}
                                        onChange={(value, { action, option }) => {
                                            if (action === 'clear') {
                                                setFieldValue('group', [{ value: 'All', label: 'All' }])
                                                handleSubmit()
                                                return
                                            }
                                            if (option && option.value === 'All') {
                                                setFieldValue('group', [{ value: 'All', label: 'All' }])
                                                handleSubmit()
                                                return
                                            }

                                            value = value.filter(x => x.value !== 'All')
                                            if (value.length === 0) {
                                                value = [{ value: 'All', label: 'All' }]
                                            }

                                            setFieldValue('group', value)
                                            handleSubmit()
                                        }}
                                        filterOption={createFilter(filterConfig)}
                                    />

                                    <small className="form-text text-muted">Group</small>
                                </div>
                            )}
                            {props.fields.includes('synced') && (
                                <div className={'col-lg-' + columnWidth}>
                                    <select
                                        className="form-control custom-select"
                                        placeholder="Filter by synced"
                                        name="synced"
                                        onBlur={handleBlur}
                                        onChange={e => {
                                            setFieldValue('synced', e.target.value)
                                            handleSubmit()
                                        }}
                                        value={values.synced}>
                                        <option value="">All</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <small className="form-text text-muted">
                                        <b>Filter</b> by Synced
                                    </small>
                                </div>
                            )}
                            {props.fields.includes('status') && (
                                <div className={'col-lg-' + columnWidth}>
                                    <select
                                        className="form-control custom-select"
                                        name="status"
                                        placeholder="Filter by Status"
                                        // TODO: Change this code
                                        onChange={e => {
                                            setFieldValue('status', e.target.value)
                                            handleSubmit()
                                        }}
                                        onBlur={handleBlur}
                                        value={values.status}>
                                        <option value="">All</option>
                                        {AreaStatusTitles.map((status, index) => {
                                            return (
                                                <option key={status + index} value={AreaStatusConstants[index]}>
                                                    {status}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    <small className="form-text text-muted">
                                        <b>Filter</b> by Status
                                    </small>
                                </div>
                            )}
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}
