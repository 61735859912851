import { yello_spectaflow } from '@shared/colors'
import styled from 'styled-components'

export const RedButton = styled.div<{ disabled?: boolean }>`
    color: red;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    font-size: 1.1rem;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 2px solid ${yello_spectaflow} !important;
    font-weight: 600;
    ${({ disabled }) => disabled && 'opacity: 0.5;'};
`
