import React, { useContext } from 'react'
import ProjectionsModal from './projections-modal'
import { TaskScreenContext, TaskScreenProvider } from './TaskScreenContext'
import { AreaStruct } from '@shared/firestore-structs'
import { DashboardContext } from '../DashboardContext'
import { AuthContext } from '../../../modules/Auth/AuthContext'

const ProjectionsModalView = ({ area, selectedDateNumber }: { area: AreaStruct; selectedDateNumber: number }) => {
    const { setAreaModalIsOpen } = useContext(DashboardContext)
    const { currentUser } = useContext(AuthContext)

    const closeAreaModal = () => {
        setAreaModalIsOpen(false)
    }

    return (
        <TaskScreenProvider area={area} currentUser={currentUser} selectedDateNumber={selectedDateNumber}>
            <ProjectionsModal area={area} closeModal={closeAreaModal} selectedDateNumber={selectedDateNumber} />
        </TaskScreenProvider>
    )
}
export default ProjectionsModalView
