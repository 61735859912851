import React from 'react'
import { View } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import * as brand from '@shared/brand'

export const JoyrideTooltip = ({ continuous, index, step, backProps, closeProps, primaryProps, tooltipProps }) => {
    return (
        <View style={{ backgroundColor: iOSColors.white, paddingHorizontal: 20, paddingVertical: 20, borderWidth: 0 }} {...tooltipProps}>
            <View style={{ flexDirection: 'row', borderWidth: 0 }}>
                <View style={{ borderWidth: 0 }}>
                    <span style={{ fontSize: 15, fontWeight: '600' }}>{step.title}</span>
                    <span style={{ fontSize: 15 }}>{step.content}</span>
                </View>
            </View>

            <View>
                {index > 0 && (
                    <button {...backProps}>
                        <span id="back">Back</span>
                    </button>
                )}
                {continuous && (
                    <button {...primaryProps}>
                        <span id="back">Next</span>
                    </button>
                )}
                {!continuous && (
                    <View style={{ borderWidth: 0, alignItems: 'flex-end', marginTop: 16 }}>
                        <button
                            style={{
                                borderRadius: 20,
                                borderWidth: 0,
                                width: 60,
                                height: 26,
                                backgroundColor: brand.getBrand().navBarColor
                            }}
                            {...closeProps}>
                            <span id="close" style={{ color: 'white', fontWeight: '600' }}>
                                Close
                            </span>
                        </button>
                    </View>
                )}
            </View>
        </View>
    )
}
