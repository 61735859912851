import React, { useMemo, useEffect, useRef, useCallback } from 'react'
import objectPath from 'object-path'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import * as brand from '@shared/brand'

export function Brand({ asideIsOpen }) {
    const uiService = useHtmlClassService()
    const toggleBtnRef = useRef(null)
    const brandLogoRef = useRef(null)
    const aside = useRef(null)

    const mouseEnterListener = useCallback(() => showToggleButton(), [asideIsOpen])
    const mouseLeaveListener = useCallback(() => hideToggleButton(), [asideIsOpen])

    useEffect(() => {
        aside.current = document.getElementById('kt_aside')
        toggleBtnRef.current = document.getElementById('kt_aside_toggle')
        brandLogoRef.current = document.querySelector('.brand-logo')
    }, [])

    useEffect(() => {
        if (asideIsOpen) {
            brandLogoRef.current.style.opacity = 1
        }

        aside.current.addEventListener('mouseenter', mouseEnterListener)
        aside.current.addEventListener('mouseleave', mouseLeaveListener)

        return () => {
            aside.current.removeEventListener('mouseenter', mouseEnterListener)
            aside.current.removeEventListener('mouseleave', mouseLeaveListener)
        }
    }, [asideIsOpen])

    const layoutProps = useMemo(() => {
        return {
            brandClasses: uiService.getClasses('brand', true),
            asideSelfMinimizeToggle: objectPath.get(uiService.config, 'aside.self.minimize.toggle'),
            headerLogo: uiService.getLogo(),
            headerStickyLogo: uiService.getStickyLogo()
        }
    }, [uiService])

    const getHeaderLogo = () => {
        let logo = brand.getNavBarAuthLogo()
        return logo
    }

    function showToggleButton() {
        toggleBtnRef.current.style.opacity = 1
        toggleBtnRef.current.style.zIndex = 3
        toggleBtnRef.current.style.transition = 'opacity 0.3s'

        if (!asideIsOpen) {
            brandLogoRef.current.style.opacity = 0
            brandLogoRef.current.style.transition = 'opacity 0.3s'
        }
    }

    function hideToggleButton() {
        toggleBtnRef.current.style.opacity = 0
        toggleBtnRef.current.style.zIndex = 1
        toggleBtnRef.current.style.transition = 'unset'

        if (!asideIsOpen) {
            brandLogoRef.current.style.opacity = 1
            brandLogoRef.current.firstChild.style.opacity = 1
        }
    }

    return (
        <>
            {/* begin::Brand */}
            <div className={`brand flex-column-auto ${layoutProps.brandClasses}`} id="kt_brand">
                {/* begin::Logo */}
                {/* <div className="brand-logo" onMouseEnter={onBrandLogoMouseEnter}> */}
                <div className="brand-logo">
                    <img alt="logo" src={getHeaderLogo().img} style={getHeaderLogo().style} />
                </div>
                {/* end::Logo */}

                {layoutProps.asideSelfMinimizeToggle && (
                    <>
                        {/* begin::Toggle */}
                        <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
                            {/* <span className="svg-icon svg-icon-xl"> */}
                            <i className="specta-arrow-left"></i>
                            {/* </span> */}
                        </button>
                        {/* end::Toolbar */}
                    </>
                )}
            </div>
            {/* end::Brand */}
        </>
    )
}
