import React, { useEffect } from 'react'
import { getWorkflowInstance, storeWorkflowInstance } from '../../../../utils/signup-flow-template/signup-workflow-instance'
import { currentIntegrationKey } from '../../signup-worflow-templates'
import { APALEO, SIGN_UP, SITE_URL } from '../../../../navigation/url-constants'
import Spinner from '../../../../components/Spinner'
import { CenteredContent } from '../../style'
import { useFeatureToggle } from '../../../../features'

const ApaleoAuthorize = () => {
    const workflowInstance = getWorkflowInstance(currentIntegrationKey)
    const redirectUrl = SITE_URL + SIGN_UP.APALEO_CALLBACK
    const { isFeatureOn } = useFeatureToggle()

    useEffect(() => {
        workflowInstance.completeStep(SIGN_UP.APALEO_AUTHORIZE)
        storeWorkflowInstance(currentIntegrationKey, workflowInstance)

        window.location.href = APALEO.AUTHORIZE(redirectUrl)
    }, [])

    return (
        <CenteredContent>
            <Spinner />
        </CenteredContent>
    )
}

export default ApaleoAuthorize
