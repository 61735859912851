import React, { Component } from 'react'
import { ActivityIndicator, View, Keyboard, FlatList, TextInput, TouchableOpacity, Image } from 'react-native-web'
import fuzzy from 'fuzzy/lib/fuzzy'
import unorm from 'unorm'
import { iOSColors } from '@shared/react-native-typography'
import firebase from '../utils/firebase'
import * as colors from '@shared/colors'
import * as brand from '@shared/brand'
import ListUserItem from './list-user-item'
import * as helpers from '@shared/helpers'
import { CloseModalButton } from '../components/buttons/close-modal-button'
import { InitialsView } from '../components/initials-view'
import { HoverableImageButton } from '../components/buttons/hoverable-image-button'
import * as uc from '../utils/constants'
import * as c from '@shared/constants'
import { httpsCallable } from '../api'
import { isEqual, uniqWith } from 'lodash'
import MainActionButton from 'app/components/buttons/MainActionButton'

const ITEM_HEIGHT = 65

export default class UsersList extends Component {
    constructor(props) {
        super(props)
        this.allUsers = []
        this.state = {
            searchValue: '',
            users: [],
            usersLoaded: false,
            showActionBar: false,
            selectedUsers: []
        }

        this.allUsers = []

        this.currentUser = this.props.currentUser
        this.isAdmin = this.props.currentUser.authRole && this.props.currentUser.authRole === 'admin'
        this.subscribe = null
        this.onUserUpdate = this.onUserUpdate.bind(this)
        this.filterSearchAndDisplay = this.filterSearchAndDisplay.bind(this)
        this.search = this.search.bind(this)
        this.onSearch = this.onSearch.bind(this)
        this.onSearchClear = this.onSearchClear.bind(this)
        this.removeUser = this.removeUser.bind(this)
        this.addUser = this.addUser.bind(this)
        this.clearUsers = this.clearUsers.bind(this)
        this.renderEmpty = this.renderEmpty.bind(this)
        this.setInitialSelectedUsers = this.setInitialSelectedUsers.bind(this)
    }

    componentDidMount() {
        if (this.isAdmin) {
            const userRef = firebase.firestore().collection('users').where('organizationKey', '==', this.currentUser.organizationKey)
            this.subscribe = userRef.onSnapshot(this.onUserUpdate)
        } else {
            const userRef = httpsCallable('getUsers')
            userRef()
                .then(results => this.onUserUpdate(results ? results.data : null))
                .catch(error => {
                    console.log('(UserList getUsers) ', error)
                    firebase.crashlytics().setUserIdentifier(this.currentUser.phoneNumber)
                    firebase.crashlytics().recordError(401, '(UserList getUsers) ' + error.message)
                })
        }
    }

    componentWillUnmount() {
        if (this.subscribe && this.isAdmin) {
            this.subscribe()
        }
    }

    onUserUpdate(usersData) {
        const { task, areaGroups } = this.props

        let users = []
        if (usersData.length > 0) {
            users = usersData
        } else if (usersData) {
            usersData.forEach(userSnap => {
                const user = userSnap.data()
                if (user.visible === undefined || user.visible) {
                    users.push(user)
                }
            })
        }

        const filteredByAreaGroup =
            areaGroups && areaGroups.length > 0
                ? users.filter(user => {
                      return user.areaGroups.some(group => {
                          return areaGroups.every(areaGroup => areaGroup === group) || group === 'All'
                      })
                  })
                : users

        this.allUsers = filteredByAreaGroup

        task && task?.assignedTo && this.setInitialSelectedUsers(filteredByAreaGroup, task.assignedTo)

        this.filterSearchAndDisplay('')
    }

    setInitialSelectedUsers(allUsers, assignedTo) {
        const assignedToKeys = assignedTo.map(user => user.key)
        const selectedUsers = allUsers.filter(user => assignedToKeys.includes(user.key))
        this.setState({ selectedUsers: uniqWith([...this.state.selectedUsers, ...selectedUsers], isEqual), showActionBar: true })
    }

    filterSearchAndDisplay(searchValue) {
        let searchedAndFilteredUsers = this.allUsers

        if (searchValue !== '') {
            searchedAndFilteredUsers = this.search(searchValue, searchedAndFilteredUsers)
        }

        if (this.props.area) {
            searchedAndFilteredUsers = helpers.filterUsersByAreaGroup(this.props.area, searchedAndFilteredUsers)
        }

        const sortedUsers = searchedAndFilteredUsers.sort((a, b) => helpers.sortByName(a.name, b.name))

        this.setState({
            users: sortedUsers
        })

        setTimeout(() => {
            this.setState({
                usersLoaded: true
            })
        }, 600)
    }

    onSearch(value) {
        this.currentPageSize = 11
        this.setState({ searchValue: value })
        this.filterSearchAndDisplay(value)
    }

    onSearchClear() {
        this.currentPageSize = 11
        Keyboard.dismiss()
        this.setState({ searchValue: '' })
        this.filterSearchAndDisplay('')
    }

    search(value, searchDomain) {
        let matches = null
        if (value === undefined || value === '' || value === ' ' || value === '  ' || value === '   ') {
            matches = searchDomain
        } else {
            const options = {
                extract: user => unorm.nfd(user.name).replace(/[\u0300-\u036f]/g, '')
            }
            const results = fuzzy.filter(unorm.nfd(value.toString().toLowerCase()).replace(/[\u0300-\u036f]/g, ''), searchDomain, options)
            matches = results.map(result => result.original)
            matches.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), 'en', { sensitiviy: 'base' }))
        }

        return matches
    }

    addUser(user) {
        this.setState({
            selectedUsers: [...this.state.selectedUsers, user],
            showActionBar: true
        })
    }

    removeUser(user) {
        const selectedUsers = this.state.selectedUsers.filter(item => item.key !== user.key)

        this.setState({
            selectedUsers
        })

        if (selectedUsers.length === 0 && !this.props.onUnassignPress) {
            this.setState({
                showActionBar: false
            })
        }
    }
    clearUsers() {
        this.setState({ selectedUsers: [] })

        if (!this.props.onUnassignPress) {
            this.setState({
                showActionBar: false
            })
        }
    }

    render() {
        const { usersLoaded, showActionBar } = this.state
        const HORIZONTAL_MARGIN = 32

        return (
            <View
                style={{
                    height: '100%',
                    margin: 0,
                    borderWidth: 0,
                    borderColor: 'red',
                    justifyContent: 'flex-start',
                    backgroundColor: colors.ios_header_background
                }}>
                {this.state.usersLoaded && <CloseModalButton close={this.props.closeModal} />}
                {!this.state.usersLoaded && (
                    <View
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            borderWidth: 0,
                            zIndex: 300,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <ActivityIndicator size="large" color={brand.getBrand().navBarColor} />
                    </View>
                )}
                {usersLoaded && this.renderHeader(HORIZONTAL_MARGIN)}
                {usersLoaded && this.renderUsersList(HORIZONTAL_MARGIN)}
                {usersLoaded && showActionBar && this.renderActionBar(HORIZONTAL_MARGIN)}
            </View>
        )
    }

    renderHeader(HORIZONTAL_MARGIN) {
        const buttonDim = 30
        const DEBUG_BORDER = 0
        return (
            <View fluid style={{ zIndex: 100, backgroundColor: colors.white, position: 'sticky', top: 0, marginTop: 0, left: 0 }}>
                <View
                    style={{
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'red'
                    }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            paddingLeft: HORIZONTAL_MARGIN,
                            paddingRight: 40,
                            paddingTop: 40,
                            paddingBottom: 0,
                            borderWidth: DEBUG_BORDER,
                            borderColor: 'red',
                            alignItems: 'center'
                        }}>
                        <span
                            style={{
                                fontSize: 22,
                                color: colors.textPrimary
                            }}
                            className="font-weight-bolder">
                            Select users to assign
                        </span>
                        <span
                            style={{
                                marginLeft: 6,
                                fontSize: 16,
                                color: colors.gray
                            }}
                            className="font-weight-bolder">
                            {'(' + this.state.users.length})
                        </span>
                    </View>
                </View>

                <View
                    style={{
                        borderColor: 'pink',
                        borderWidth: DEBUG_BORDER,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',

                        height: 36,
                        width: 220,
                        borderRadius: 10,
                        marginTop: 16,
                        marginLeft: HORIZONTAL_MARGIN,
                        backgroundColor: '#F2F2F2'
                    }}>
                    <View
                        style={{
                            borderColor: 'yellow',
                            borderWidth: DEBUG_BORDER,
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: buttonDim,
                            width: buttonDim
                        }}>
                        <Image
                            style={{
                                width: 14,
                                height: 14
                            }}
                            source={require('@shared/img/searchinput-icon.png')}
                        />
                    </View>
                    <TextInput
                        keyboardType="default"
                        underlineColorAndroid="rgba(0,0,0,0)"
                        autoFocus={false}
                        style={[
                            {
                                outlineStyle: 'none',
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginTop: 0,
                                color: iOSColors.black,
                                flex: 1,
                                borderWidth: 0
                            }
                        ]}
                        selectionColor="red"
                        placeholder="User"
                        placeholderTextColor={colors.searchBarFontColor}
                        value={this.state.searchValue}
                        onChangeText={this.onSearch}
                    />
                    {this.state.searchValue.length > 0 && (
                        <TouchableOpacity
                            onPress={this.onSearchClear}
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderColor: 'red',
                                borderWidth: 0,
                                height: buttonDim,
                                width: buttonDim
                            }}>
                            <Image
                                style={{
                                    width: 14,
                                    height: 14
                                }}
                                source={require('@shared/img/clear-text.png')}
                            />
                        </TouchableOpacity>
                    )}
                </View>
            </View>
        )
    }

    renderUsersList(HORIZONTAL_MARGIN) {
        const cardSpacing = 2

        return (
            <FlatList
                keyboardShouldPersistTaps="always"
                style={{
                    paddingBottom: 80,
                    borderColor: 'blue',
                    borderWidth: 0,
                    backgroundColor: colors.white,
                    marginLeft: HORIZONTAL_MARGIN,
                    marginRight: HORIZONTAL_MARGIN,
                    paddingTop: 12
                }}
                ItemSeparatorComponent={() => (
                    <View
                        style={{
                            height: 1,
                            backgroundColor: iOSColors.customGray,

                            marginBottom: cardSpacing,
                            marginTop: cardSpacing
                        }}
                    />
                )}
                getItemLayout={(data, index) => ({
                    length: ITEM_HEIGHT,
                    offset: ITEM_HEIGHT * index,
                    index
                })}
                ListEmptyComponent={this.renderEmpty}
                showsVerticalScrollIndicator={false}
                data={this.state.users}
                renderItem={item => {
                    const user = item.item

                    const checkedIndex = this.state.selectedUsers.findIndex(item => item.key === user.key)
                    let isSelected = null
                    if (checkedIndex === -1) {
                        isSelected = false
                    } else {
                        isSelected = true
                    }

                    return (
                        <ListUserItem
                            currentUser={this.currentUser}
                            context={this.props.context}
                            key={user.key}
                            user={user}
                            addUser={this.addUser}
                            removeUser={this.removeUser}
                            isSelected={isSelected}
                        />
                    )
                }}
            />
        )
    }

    renderEmpty() {
        return (
            <View
                style={{
                    backgroundColor: colors.white,
                    borderColor: 'green',
                    borderWidth: 0,
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: '8%'
                }}>
                <Image
                    style={{ width: 148, height: 123, opacity: 0.7 }}
                    source={require('@shared/img/empty-screens/hotel-room-key@2x.png')}
                />
                <span
                    style={{
                        marginTop: 40,
                        fontSize: 18,
                        color: iOSColors.gray
                    }}>
                    No users found
                </span>
                <span
                    style={{
                        marginTop: 16,
                        fontSize: 17,

                        color: iOSColors.gray
                    }}>
                    Try a new search
                </span>
            </View>
        )
    }

    renderActionBar() {
        const disabled = this.props.onUnassignPress
            ? this.state.selectedUsers.length === 0 && this.props.task.status === c.TASK_OPEN
            : this.state.selectedUsers.length === 0
        const unassignCondition =
            this.state.selectedUsers.length === 0 &&
            this.props.task &&
            this.props.task.status === c.TASK_ASSIGNED &&
            this.props.onUnassignPress
        const buttonText = unassignCondition ? 'Unassign' : 'Assign'

        return (
            <View
                style={{
                    position: 'sticky',
                    bottom: 0,
                    marginTop: 0,
                    left: 0,
                    backgroundColor: 'white',
                    width: '100%',

                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 0,
                    flex: 1,
                    alignItems: 'flex-end',
                    borderWidth: 0
                }}>
                <View
                    style={{
                        backgroundColor: 'white',
                        flexDirection: 'row',
                        width: '100%',
                        height: 80,
                        justifyContent: 'space-between',
                        borderTopWidth: 1,
                        borderColor: iOSColors.lightGray,
                        paddingLeft: 28,
                        paddingRight: 30,

                        marginBottom: 0,
                        alignItems: 'center'
                    }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            borderColor: 'red',
                            borderWidth: 0,
                            height: 48,
                            alignItems: 'center'
                        }}>
                        <InitialsView
                            maxCountToDisplay={6}
                            circleDimension={30}
                            fontSize={11}
                            justifyContent={'flex-start'}
                            currentUser={this.props.currentUser}
                            assignedTo={this.state.selectedUsers}
                        />

                        {this.state.selectedUsers.length > 0 ? (
                            <View style={{ height: 48 }}>
                                <HoverableImageButton
                                    dimension={22}
                                    hoverOpacity={uc.HOVER_OPACITY}
                                    imageSource={require('@shared/img/clear-filter.svg')}
                                    onPress={this.clearUsers}
                                    tooltipText={'Clear users'}
                                />
                            </View>
                        ) : null}
                    </View>
                    <MainActionButton
                        disabled={disabled}
                        onClick={() => {
                            if (unassignCondition) {
                                this.props.onUnassignPress([])
                            } else {
                                this.props.onAssignPress(this.state.selectedUsers)
                            }
                        }}>
                        {buttonText}
                    </MainActionButton>
                </View>
            </View>
        )
    }
}
