import moment, { Moment } from 'moment-timezone'

type DateType = Moment | Date | number

export const startOfDay = (date: DateType = moment()) => {
    return moment(date).utcOffset(0).startOf('day')
}

export const startOfDayTimestamp = (date: DateType = moment()) => {
    return moment(date).utcOffset(0).startOf('day').valueOf()
}

export const utcDate = (date: Moment | Date | number = moment()) => {
    return moment(date).utcOffset(0)
}

export const getNumberOfMonths = (start: DateType, end: DateType) => moment(end).diff(moment(start), 'months')

export const sectionMonths = (dates: DateType[]) => {
    const months: { [key: string]: DateType[] } = {}
    months[moment(dates[0]).format('YYYY-MM')] = [dates[0]]
    months[moment(dates[0]).format('YYYY-MM')] = [dates[0]]
    for (const date of dates) {
        const month = moment(date).format('YYYY-MM')
        if (!months[month]) {
            months[month] = []
        }
        months[month].push(date)
    }
    return months
}

export const isDateInMonth = (date: DateType, month: string) => {
    return moment(date).format('YYYY-MM') === month
}
