import loadImage from 'blueimp-load-image'

export async function renderImage(imageUrl, orientation, canvasName, width, height, borderRadius, crop, removeChild) {
    const imagePromise = loadImage(
        imageUrl,
        img => {
            if (img.type === 'error') {
                // console.log('Error loading image ' + imageUrl)
            } else {
                const imgDiv = document.getElementById(canvasName)
                const image = img
                image.style = 'border-radius: ' + borderRadius
                if (imgDiv) {
                    if (removeChild && imgDiv.childElementCount > 0 && !imgDiv.childNodes[0].id.endsWith('.text')) {
                        imgDiv.removeChild(imgDiv.childNodes[0])
                    }

                    imgDiv.appendChild(image)
                }
            }
        },
        {
            maxHeight: height,
            maxWidth: width,
            orientation,
            crop
        }
    )
    return imagePromise
}

export default renderImage
