import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const LoginWithToken = () => {
    const history = useHistory()
    const [error, setError] = React.useState<string>('')
    useEffect(() => {
        const fetchData = async () => {
            const searchParams = new URLSearchParams(document.location.search)
            const queryToken = searchParams.get('token')
            try {
                await axios.get(`/api/guests/login-with-token`, { withCredentials: true, params: { token: queryToken } })
                history.push('/guest/data')
            } catch (e: any) {
                setError(e.message ?? 'Failed to login with token. Please report this error to the devs.')
                console.error(`Login with token failed:`, e)
            }
        }
        fetchData()
    }, [])
    return error ? <div style={{ margin: 'auto' }}>{error}</div> : <></>
}

export default LoginWithToken
