import { iOSColors } from '@shared/react-native-typography'
import { View } from 'react-native-web'
import React, { useContext } from 'react'
import { TaskScreenContext } from './TaskScreenContext'
import moment from 'moment-timezone'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { UNIT_HEADER_FONT_SIZE } from './consts'

const DeveloperSection = () => {
    const { area, currentTask } = useContext(TaskScreenContext)
    const { currentUser } = useContext(AuthContext)

    const TITLE_FONT_SIZE = 13
    const CONTENT_FONT_SIZE = 14
    const TITLE_FONT_COLOR = iOSColors.gray

    const BOOKINGS_MARGIN_VERTICAL = 6

    const task = currentTask
    area.tasks = []

    const todaysBookings = area.bookings
        ? area.bookings.filter((booking: any) => booking.bookingDates.includes(moment().startOf('day').valueOf().toString()))
        : []
    const futureBookings = area.bookings
        ? area.bookings.filter((booking: any) => booking.checkinDate < moment().startOf('day').valueOf())
        : []

    return (
        <View style={{ width: '100%' }}>
            <span
                style={{
                    fontWeight: '600',
                    color: iOSColors.black,
                    fontSize: UNIT_HEADER_FONT_SIZE,
                    // marginBottom: 12,
                    overflow: 'visible'
                }}>
                Developer section
            </span>
            {/* {this.state.area && this.state.area.ruleName && ( */}
            <div className="row row-paddingless mt-5">
                <div>
                    <strong>Calculation results:</strong>&nbsp;
                    {area.ruleName}
                </div>
            </div>
            {/* )} */}
            <View
                style={{
                    flexDirection: 'row',
                    backgroundColor: iOSColors.white,
                    justifyContent: 'space-between',
                    marginVertical: 12
                }}>
                <View>
                    <span
                        style={{
                            fontWeight: '500',
                            color: TITLE_FONT_COLOR,
                            fontSize: TITLE_FONT_SIZE,
                            marginBottom: 6
                        }}>
                        Organization key
                    </span>

                    <CopyToClipboard text={currentUser!.organizationKey} />
                </View>
                <View>
                    <span
                        style={{
                            fontWeight: '500',
                            color: TITLE_FONT_COLOR,
                            fontSize: TITLE_FONT_SIZE,
                            marginBottom: 6
                        }}>
                        Area key
                    </span>

                    <CopyToClipboard text={area.key} />
                </View>

                {todaysBookings && (
                    <View>
                        <span
                            style={{
                                fontWeight: '500',
                                color: TITLE_FONT_COLOR,
                                fontSize: TITLE_FONT_SIZE,
                                marginBottom: 6
                            }}>
                            Today&apos;s bookings
                        </span>
                        {todaysBookings.length > 0 &&
                            todaysBookings.map((booking: any) => (
                                <span key={booking.bookingId}>
                                    {booking.bookingId !== booking.key ? (
                                        <span>
                                            Id: <CopyToClipboard text={booking.bookingId} />
                                            Key: <CopyToClipboard text={booking.key} />
                                        </span>
                                    ) : (
                                        <CopyToClipboard text={booking.key} />
                                    )}
                                </span>
                            ))}
                        {todaysBookings.length < 1 && (
                            <span style={{ fontWeight: '500', color: iOSColors.black, fontSize: CONTENT_FONT_SIZE }}>
                                No bookings found
                            </span>
                        )}
                    </View>
                )}
                {futureBookings && (
                    <View>
                        <span
                            style={{
                                fontWeight: '500',
                                color: TITLE_FONT_COLOR,
                                fontSize: TITLE_FONT_SIZE,
                                marginBottom: 6
                            }}>
                            Future booking
                        </span>
                        {futureBookings.length > 0 &&
                            (futureBookings[0].bookingId !== futureBookings[0].key ? (
                                <span>
                                    Id: <CopyToClipboard text={futureBookings[0].bookingId} />
                                    Key: <CopyToClipboard text={futureBookings[0].key} />
                                </span>
                            ) : (
                                <CopyToClipboard text={futureBookings[0].key} />
                            ))}
                        {futureBookings.length < 1 && (
                            <span style={{ fontWeight: '500', color: iOSColors.black, fontSize: CONTENT_FONT_SIZE }}>
                                No bookings found
                            </span>
                        )}
                    </View>
                )}
            </View>
            <span style={{ fontWeight: '500', color: TITLE_FONT_COLOR, fontSize: TITLE_FONT_SIZE, marginBottom: 6 }}>Area JSON</span>
            <input className="form-control" type="text" value={JSON.stringify(area)} readOnly />
            <span style={{ fontWeight: '500', color: TITLE_FONT_COLOR, fontSize: TITLE_FONT_SIZE, marginBottom: 6 }}>Task JSON</span>
            <input className="form-control" type="text" value={JSON.stringify(task)} readOnly />
        </View>
    )
}

async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text)
    } else {
        return document.execCommand('copy', true, text)
    }
}
const CopyToClipboard = ({ text }: { text: string }) => {
    return (
        <span>
            {text}
            <a
                onClick={async () => {
                    await copyTextToClipboard(text)
                }}>
                ✂️
            </a>
        </span>
    )
}

export default DeveloperSection
