import { selector, selectorFamily } from 'recoil'
import { dailyCommentsAtom, fetchedAreasAtom } from '../atoms'
import { AreaStruct, BookingStruct, DailyCommentStruct, UserStruct } from '@shared/firestore-structs'
import { userHasAccessToAreaGroup } from '@shared/area-groups-helpers'
import { bookingsSelector } from './areaRelatedData'
import { findLeadBooking } from '@shared/booking-service'
import { SummaryNote } from '../../types'
import { selectedDateNumberSelector } from './selectedDateNumber'
import { currentUserAtom } from 'app/modules/Auth/atoms'

export const dailyCommentSelector = selectorFamily<DailyCommentStruct | null, AreaStruct>({
    key: 'housekeeping-dailyCommentsSelector',
    get: area => ({ get }) => {
        const currentUser = get(currentUserAtom)
        const dailyComments = get(dailyCommentsAtom)

        if (!currentUser || !userHasAccessToAreaGroup(currentUser.areaGroups, area.group)) return null

        return dailyComments.find(comment => comment.areaKey === area.key) || null
    }
})

export const extraComment = selectorFamily<string | null, AreaStruct>({
    key: 'housekeeping-extraComment',
    get: area => ({ get }) =>
        getExtraComment(area, get(currentUserAtom) as UserStruct, get(bookingsSelector(area.key)), get(selectedDateNumberSelector))
})

export const bookingNotesSelector = selectorFamily<string | null, AreaStruct>({
    key: 'housekeeping-bookingNots',
    get: area => ({ get }) =>
        getBookingNotes(area, get(currentUserAtom) as UserStruct, get(bookingsSelector(area.key)), get(selectedDateNumberSelector))
})

export const areaNotesSummarySelector = selector<SummaryNote[]>({
    key: 'housekeeping-allAreasNotes',
    get: ({ get }) => {
        const areas = get(fetchedAreasAtom)

        return areas.reduce((acc: SummaryNote[], area) => {
            if (area.note && area.note.length > 0) {
                const { name, description, note, group } = area
                acc.push({ name, note, group, description })
            }

            return acc
        }, [])
    }
})

export const dailyCommentsSummarySelector = selector<SummaryNote[]>({
    key: 'housekeeping-dailyCommentsSummarySelector',
    get: ({ get }) => {
        const areas = get(fetchedAreasAtom)

        return areas.reduce((acc: SummaryNote[], area) => {
            const dailyComment = get(dailyCommentSelector(area))

            if (dailyComment) {
                const { name, description, group } = area
                dailyComment.comment.length > 0 && acc.push({ name, note: dailyComment.comment, group, description })
            }

            return acc
        }, [])
    }
})

export const bookingNotesSummarySelector = selector<SummaryNote[]>({
    key: 'housekeeping-bookingNotesSummarySelector',
    get: ({ get }) => {
        const areas = get(fetchedAreasAtom)

        return areas.reduce((acc: SummaryNote[], area) => {
            const bookingNotes = get(bookingNotesSelector(area))

            if (bookingNotes && bookingNotes.length > 0) {
                const { name, description, group } = area
                acc.push({ name, note: bookingNotes, group, description })
            }

            return acc
        }, [])
    }
})

export function getBookingNotes(
    area: AreaStruct,
    currentUser: UserStruct,
    bookings: BookingStruct[],
    selectedDateNumber: number
): string | null {
    if (!currentUser || !userHasAccessToAreaGroup(currentUser.areaGroups, area.group)) return null

    const leadBooking = findLeadBooking(area, bookings, selectedDateNumber)

    return leadBooking?.notes ? leadBooking.notes : null
}

export function getExtraComment(
    area: AreaStruct,
    currentUser: UserStruct,
    bookings: BookingStruct[],
    selectedDateNumber: number
): string | null {
    if (!currentUser || !userHasAccessToAreaGroup(currentUser.areaGroups, area.group)) return null

    const bookingExtras = bookings
        .flatMap(booking => booking.extras || [])
        .filter(extra => extra.date === selectedDateNumber)
        .map(extra => `${extra.quantity}x ${extra.name}`)

    return bookingExtras.length > 0 ? bookingExtras.join(', ') : null
}
