import React from 'react'
import { View, TouchableOpacity, Image, Text, findNodeHandle } from 'react-native-web'
import Hoverable from '../utils/hoverable/hoverable'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'
import { OrderedButton } from '../components/ordered-button'
import { fadeInDown } from 'react-animations'
import Radium from 'radium'
import { ImageStyle } from 'react-native'
import { CSSProperties } from 'styled-components'

const animationStyles = {
    fadeInDown: {
        animation: 'x 0.25s',
        animationName: Radium.keyframes(fadeInDown, 'fadeInDown')
    }
}

type PopupMenuProps = {
    startActivityIndicator?: () => void
    button?: any
    customButton?: React.JSX.Element
    buttonStyle?: ImageStyle
    options: { name: string; count: number }[]
    type: string
    actions: (() => void)[]
}
type PopupMenuState = {
    open: boolean
}
export default class PopupMenu extends React.Component<PopupMenuProps, PopupMenuState> {
    state: PopupMenuState = { open: false }
    constructor(props: PopupMenuProps) {
        super(props)

        this.handleClick = this.handleClick.bind(this)
        this.handleOutsideClick = this.handleOutsideClick.bind(this)
        this.renderOptions = this.renderOptions.bind(this)
    }

    handleClick() {
        if (!this.state.open) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false)
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false)
        }

        this.setState(prevState => ({
            open: !prevState.open
        }))
    }

    handleOutsideClick(e: Event) {
        // @ts-ignore
        const handle = findNodeHandle(this.node)
        // @ts-ignore
        if (handle && handle.contains && handle.contains(e.target)) {
            return
        }

        // @ts-ignore
        this.handleClick()
    }

    render() {
        const startActivityIndicator = this.props.startActivityIndicator ? this.props.startActivityIndicator : () => null

        const component = this.props.button ? <Image source={this.props.button} style={this.props.buttonStyle} /> : this.props.customButton

        return (
            <View
                style={{ alignItems: 'center' }}
                ref={node => {
                    // @ts-ignore
                    this.node = node
                }}>
                <View>
                    <TouchableOpacity onPress={this.handleClick}>{this.props.options.length > 0 && component}</TouchableOpacity>
                </View>

                {this.state.open && (
                    <View
                        style={{
                            padding: 0,
                            position: 'absolute',
                            top: '100%',
                            marginTop: 9,
                            elevation: 3,
                            backgroundColor: 'transparent',
                            // @ts-ignore
                            overflowY: 'auto'
                        }}>
                        {this.renderOptions(startActivityIndicator)}
                    </View>
                )}
            </View>
        )
    }

    renderOptions(startActivityIndicator: () => void) {
        return (
            <Radium.StyleRoot>
                <div style={animationStyles.fadeInDown as CSSProperties}>
                    <View
                        style={{
                            // boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.9)',
                            borderRadius: 8,
                            maxHeight: 330,
                            borderWidth: 1,
                            borderColor: colors.gentle_gray_spectaflow,
                            backgroundColor: 'white',
                            paddingVertical: 6
                        }}>
                        {this.props.options.map((option, index) => {
                            const isLast = this.props.options.length - 1 === index
                            return (
                                <OptionItem
                                    isLast={isLast}
                                    type={this.props.type}
                                    startActivityIndicator={startActivityIndicator}
                                    key={option + ' ' + index}
                                    options={this.props.options}
                                    option={option}
                                    index={index}
                                    toggleMenu={this.handleClick}
                                    actions={this.props.actions}
                                />
                            )
                        })}
                    </View>
                </div>
            </Radium.StyleRoot>
        )
    }
}

type OptionItemProps = {
    isLast: boolean
    type: string
    startActivityIndicator: () => void
    key: string
    options: { name: string; count: number }[]
    option: { name: string; count: number }
    index: number
    toggleMenu: () => void
    actions: (() => void)[]
}

type OptionItemState = {
    hover: boolean
}
// eslint-disable-next-line react/no-multi-comp
class OptionItem extends React.Component<OptionItemProps, OptionItemState> {
    constructor(props: OptionItemProps) {
        super(props)
        this.state = { hover: false }
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(index: number) {
        const options = this.props.options
        for (let i = 0; i < options.length; i++) {
            if (index === i) {
                if (this.props.actions[i] !== null) {
                    this.props.startActivityIndicator()
                    this.props.toggleMenu()
                    this.props.actions[i]()
                }
            }
        }
    }

    pickStatusColor(orgStatus: string) {
        if (orgStatus === 'active') {
            return iOSColors.green
        } else if (orgStatus === 'aeady') {
            return colors.green_spectaflow
        } else if (orgStatus === 'auspended') {
            return colors.red_spectaflow
        } else if (orgStatus === 'trial') {
            return colors.yello_spectaflow
        } else {
            return iOSColors.gray
        }
    }

    render() {
        const STATUS_FONT_STYLE = {
            fontSize: 14,
            fontWeight: '400',
            color: this.pickStatusColor('used to be undefined')
        }

        let isString = false
        let isHashtag = false
        let isNavbar = false
        let isHouskeepingDashboard = false
        if (this.props.type === 'hashtag-bar') {
            isHashtag = true
        } else if (this.props.type === 'housekeeping-dashboard') {
            isHouskeepingDashboard = true
        } else if (this.props.type === 'navbar') {
            isNavbar = true
        } else {
            isString = true
        }

        let backgroundColor = iOSColors.white
        const nameColor = iOSColors.black

        if (this.state.hover) {
            backgroundColor = colors.gentle_gray_spectaflow
        }

        const option = this.props.option

        return (
            <Hoverable
                onHoverIn={() => {
                    this.setState({ hover: true })
                }}
                onHoverOut={() => {
                    this.setState({ hover: false })
                }}>
                <View style={{ backgroundColor: backgroundColor }} key={this.props.index}>
                    <TouchableOpacity
                        style={{
                            // borderColor: colors.gentle_gray_spectaflow,
                            // borderWidth: 1,
                            flexDirection: 'row',
                            paddingVertical: 10,
                            paddingHorizontal: 16,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderBottomWidth: this.props.isLast ? 0 : 1,
                            borderBottomColor: colors.gentle_gray_spectaflow
                        }}
                        onPress={() => this.handleClick(this.props.index)}>
                        {isHashtag && (
                            <View
                                style={{
                                    height: 22,
                                    flexDirection: 'row',
                                    borderWidth: 0
                                }}>
                                <OrderedButton
                                    active={false}
                                    key={option.name + option.count}
                                    text={option.name}
                                    count={option.count}
                                    color={iOSColors.gray}
                                    opacity={0.8}
                                    fontSize={14}
                                    fontWeight={'500'}
                                    height={22}
                                    showBorder={false}
                                    borderColor={iOSColors.midGray}
                                    noHover={true}
                                />
                            </View>
                        )}
                        {isHouskeepingDashboard && (
                            <View
                                style={{
                                    height: 26,
                                    flexDirection: 'row',
                                    borderWidth: 0,
                                    paddingHorizontal: 20
                                }}>
                                <OrderedButton
                                    active={false}
                                    key={option.name + option.count}
                                    text={option.name}
                                    count={option.count}
                                    countMarginTop={2}
                                    color={iOSColors.gray}
                                    opacity={0.8}
                                    fontSize={15}
                                    fontWeight={'500'}
                                    height={26}
                                    marginRight={8}
                                    borderColor={iOSColors.midGray}
                                    showBorder={false}
                                    borderRadius={6}
                                    noHover={true}
                                />
                            </View>
                        )}

                        {isNavbar && (
                            <View
                                style={{
                                    height: 26,
                                    flexDirection: 'row',
                                    borderWidth: 0,
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                {this.props.option.name && (
                                    <span style={{ fontWeight: '500', fontSize: 14, color: nameColor }}>{this.props.option.name}</span>
                                )}
                                {/*<View style={{ flexDirection: 'row' }}>*/}
                                {/*    {this.props.option.status && (*/}
                                {/*        <span style={{ marginLeft: 20, ...STATUS_FONT_STYLE }}>*/}
                                {/*            {helpers.capitalizeFirstLetter(this.props.option.status)}*/}
                                {/*        </span>*/}
                                {/*    )}*/}

                                {/*    {this.props.option.status === 'trial' && (*/}
                                {/*        <span style={{ marginLeft: 6, ...STATUS_FONT_STYLE }}>{this.props.option.trialStartDateText}</span>*/}
                                {/*    )}*/}
                                {/*</View>*/}
                            </View>
                        )}
                    </TouchableOpacity>
                </View>
            </Hoverable>
        )
    }
}
