import React from 'react'
import * as c from '@shared/constants'
import { TextIssue, TextIssueWrap, ImageWrap } from './style'
import notDownloaded from '@shared/img/not-downloaded.svg'
import PrioritySign from 'app/components/PrioritySign'

interface IssueVisualProps {
    lastItemKey: string | null | undefined
    lastThumbUrl: string | null | undefined
    priority: boolean
    minimized: boolean
}

function IssueVisual({ lastItemKey, lastThumbUrl, priority, minimized }: IssueVisualProps) {
    const prioritySign = priority && <PrioritySign minimized={minimized} />

    if (lastItemKey === c.TEXT_ISSUE) {
        return (
            <TextIssueWrap minimized={minimized}>
                {prioritySign}
                <div className={'text-container'}>
                    <TextIssue minimized={minimized} className="specta-text-issue" />
                </div>
            </TextIssueWrap>
        )
    }

    if (lastThumbUrl && lastThumbUrl.length >= 5 && lastThumbUrl.slice(0, 4) === 'http') {
        return (
            <ImageWrap minimized={minimized}>
                {prioritySign}
                <img src={lastThumbUrl} />
            </ImageWrap>
        )
    }

    return (
        <TextIssueWrap minimized={minimized}>
            {prioritySign}
            <img src={notDownloaded as string} />
        </TextIssueWrap>
    )
}

export default IssueVisual
