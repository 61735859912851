import React, { PureComponent } from 'react'
import { View, Image, TouchableOpacity } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import { fadeIn } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import { Tooltip } from 'react-tippy'

import Hoverable from '../../utils/hoverable/hoverable'
import * as colors from '@shared/colors'
import * as helpers from '@shared/helpers'
import * as chelpers from '@shared/cleaning-helpers'
import * as c from '@shared/constants'
import * as thelpers from '../../components/tooltips/tooltip-helpers'
import moment from 'moment-timezone'
import BoxIcon from './box-icon'
import CircleNumber from '../../components/CircleChar/index'
import { useFeatureToggle } from '../../features'

const animationStyles = {
    fadeIn: {
        animation: 'x 0.45s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn')
    }
}

function withFeatureToggle(Component) {
    return function WrappedComponent(props) {
        const { isFeatureOn } = useFeatureToggle()
        return <Component {...props} usingProjection={isFeatureOn('display-projection')} />
    }
}

class HousekeepingBox extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selected: this.props.selected,
            backgroundColor: 'white',
            active: false
        }
        this.renderHotcorner = this.renderHotcorner.bind(this)
    }

    getAreaCleaningBoxDisplay(context, name) {
        const areaCleaningBoxDisplay = {
            fontSize: null,
            fontWeight: '600',
            lineHeight: null,
            viewHeight: null,
            useMargin: true,
            initialsCircleSize: 29,
            textPadding: null,
            iconMarginTopAndBottom: 10,
            iconScaler: 1,
            paddingTop: 10,
            paddingBottom: 10,
            priorityCircleSize: 28
        }
        let fontSize = 20

        if (context === c.CONTEXT_WEB_APP_CLEANING) {
            const shortName = helpers.shortenLongName(name, 32)
            const NAME_LENGTH = shortName ? shortName.length : 0
            areaCleaningBoxDisplay.name = shortName
            if (NAME_LENGTH > 8) {
                areaCleaningBoxDisplay.fontWeight = '400'
            }
            if (NAME_LENGTH < 10) {
                areaCleaningBoxDisplay.iconMarginTopAndBottom = 13
            }
            if (NAME_LENGTH > 2) {
                fontSize = 18
            }
            if (NAME_LENGTH > 6) {
                fontSize = 16
            }
            if (NAME_LENGTH > 14) {
                fontSize = 14
            }
            if (NAME_LENGTH > 22) {
                fontSize = 13
            }
            if (NAME_LENGTH > 24) {
                fontSize = 12
            }
            areaCleaningBoxDisplay.viewHeight = 51
            areaCleaningBoxDisplay.useMargin = true
            areaCleaningBoxDisplay.textPadding = 4
            areaCleaningBoxDisplay.lineHeight = 1.15
        } else if (context === c.CONTEXT_WEB_APP_TASKS) {
            const shortName = helpers.shortenLongName(name, 8)
            const NAME_LENGTH = shortName ? shortName.length : 0

            areaCleaningBoxDisplay.name = shortName

            fontSize = 14
            if (NAME_LENGTH > 2) {
                fontSize = 13
            }
            if (NAME_LENGTH > 6) {
                fontSize = 11
            }
            if (NAME_LENGTH > 14) {
                fontSize = 10
            }
            if (NAME_LENGTH > 22) {
                fontSize = 10
            }
            if (NAME_LENGTH > 24) {
                fontSize = 10
            }
            if (NAME_LENGTH > 24) {
                fontSize = 10
            }

            areaCleaningBoxDisplay.viewHeight = 44
            areaCleaningBoxDisplay.useMargin = false
            areaCleaningBoxDisplay.textPadding = 4
            areaCleaningBoxDisplay.iconMarginTopAndBottom = 7

            areaCleaningBoxDisplay.lineHeight = 1
            areaCleaningBoxDisplay.iconScaler = 0.65
            areaCleaningBoxDisplay.paddingBottom = 5
            areaCleaningBoxDisplay.paddingTop = 5
            areaCleaningBoxDisplay.priorityCircleSize = 18
        }

        areaCleaningBoxDisplay.fontSize = fontSize

        return areaCleaningBoxDisplay
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selected !== state.selected) {
            return { selected: props.selected }
        }

        return null
    }

    render() {
        const selectedDate = moment(this.props.selectedDateNumber).startOf('day')

        const IS_TODAY = chelpers.isToday(selectedDate)
        let SHOW_PRIORITY = false
        if (this.props.context === c.CONTEXT_WEB_APP_CLEANING) {
            SHOW_PRIORITY = IS_TODAY && (this.props.priority === true || (this.props.area && this.props.area.priority === true))
        }

        if (this.props.context === c.CONTEXT_WEB_APP_TASKS) {
            SHOW_PRIORITY = this.props.priority === true
        }

        const SHOW_DAILY_COMMENT = this.props.area.dailyComment && this.props.area.dailyComment.comment !== '' ? true : false

        const cleaningActionIcon = chelpers.pickCleaningActionIcon(
            this.props.area.cleaningStatus,
            this.props.area.occupancy,
            this.props.area.cleaningFrequency,
            this.props.area.rules,
            false,
            this.props.usingProjection
        )
        const cleaningStatusIcon = chelpers.pickCleaningStatusIcon(this.props.area.cleaningStatus, false)
        const checkinIcon = chelpers.pickCheckinIcon(this.props.area.cleaningStatus, this.props.area.occupancy, false)
        const guestsIcon = chelpers.pickPeopleIcon(
            this.props.area.cleaningStatus,
            this.props.area.occupancy,
            this.props.area.guestCheckedIn,
            false
        )
        const ruleNumber =
            this.props.area.activeRule && this.props.area.activeRule.customChar ? this.props.area.activeRule.customChar : null
        const pauseIcon = chelpers.pickPauseIcon(this.props.area.cleaningStatus, false)
        const inspectionIcon = chelpers.pickInspectionIcon(this.props.area.cleaningStatus, false)

        const areaCleaningBoxDisplay = this.getAreaCleaningBoxDisplay(this.props.context, this.props.area.name)

        let areaNoteIcon
        if (this.props.area.note && this.props.area.note !== '') {
            areaNoteIcon = chelpers.pickAreaNoteIcon(null, null)
        }

        const DEBUG_BORDER = 0
        const SPACE_BETWEEN_ICONS = 6

        const BOX_COLOR =
            (this.props.hasSubscriptionAccess || this.props.area.synced) && !this.props.area.dormant
                ? chelpers.pickCleaningStatusColor(this.props.area.cleaningStatus, this.props.area.occupancy)
                : colors.gray

        let shade = 10
        if (BOX_COLOR === colors.red_spectaflow) {
            shade = 20
        }
        const alpha = this.props.problems ? '55' : 'FF'
        const BOX_BORDER_RADIUS = 6

        const icon_scaler = areaCleaningBoxDisplay.iconScaler

        const bottomIcons = [
            ...(pauseIcon
                ? [
                      <BoxIcon
                          key={'pause-icon'}
                          height={pauseIcon.width}
                          width={pauseIcon.height}
                          iconScale={icon_scaler}
                          source={pauseIcon.source}
                      />
                  ]
                : []),
            ...(checkinIcon
                ? [
                      <BoxIcon
                          key={'checkin-icon'}
                          height={checkinIcon.height}
                          width={checkinIcon.width}
                          iconScale={icon_scaler}
                          source={checkinIcon.source}
                      />
                  ]
                : []),
            ...(areaNoteIcon
                ? [<BoxIcon key={'area-note-icon'} height={16.5} width={13} iconScale={icon_scaler} source={areaNoteIcon} />]
                : []),
            ...(guestsIcon
                ? [
                      <BoxIcon
                          key={'guests-icon'}
                          height={guestsIcon.height}
                          width={guestsIcon.width}
                          iconScale={icon_scaler}
                          source={guestsIcon.source}
                      />
                  ]
                : []),
            ...(inspectionIcon
                ? [
                      <BoxIcon
                          key={'inspection-icon'}
                          height={inspectionIcon.height}
                          width={inspectionIcon.width}
                          iconScale={icon_scaler}
                          source={inspectionIcon.source}
                      />
                  ]
                : []),
            ...(ruleNumber ? [<CircleNumber key={'custom-icon'} char={ruleNumber} size={icon_scaler ? icon_scaler * 20 : 20} />] : [])
        ]

        const cleaningActionIconCondition = () => {
            if (cleaningActionIcon !== null) {
                return (
                    <Image
                        style={{
                            width: cleaningActionIcon.width * icon_scaler,
                            height: cleaningActionIcon.height * icon_scaler,
                            marginRight: cleaningStatusIcon !== null ? SPACE_BETWEEN_ICONS : 0
                        }}
                        source={cleaningActionIcon.source}
                    />
                )
            }
        }

        return (
            <StyleRoot>
                <div style={animationStyles.fadeIn}>
                    <Hoverable
                        onHoverIn={() => {
                            this.setState({ active: true })
                        }}
                        onHoverOut={() => {
                            this.setState({ active: false })
                        }}>
                        <View
                            testID="area-card"
                            style={{
                                borderRadius: BOX_BORDER_RADIUS,
                                height: this.props.height,
                                width: this.props.width,
                                marginRight: areaCleaningBoxDisplay.useMargin && !this.props.withoutMargin ? this.props.tilesGutter : 0,
                                marginBottom: areaCleaningBoxDisplay.useMargin && !this.props.withoutMargin ? this.props.tilesGutter : 0,
                                borderWidth: this.state.selected ? 1 : 0,
                                borderColor: iOSColors.black
                            }}>
                            <TouchableOpacity
                                style={{
                                    alignItems: 'center',
                                    height: this.props.height,
                                    width: this.props.width,
                                    opacity: this.state.selected ? 0.6 : 1,
                                    backgroundColor:
                                        !this.props.assignMode &&
                                        this.state.active &&
                                        this.state.hasSubscriptionAccess &&
                                        this.props.area.synced
                                            ? `${colors.shadeColor(BOX_COLOR, shade)}${alpha}`
                                            : `${BOX_COLOR}${alpha}`,
                                    display: 'flex',
                                    borderRadius: 6,
                                    justifyContent: 'space-between',
                                    paddingTop: areaCleaningBoxDisplay.paddingTop,
                                    paddingBottom: areaCleaningBoxDisplay.paddingBottom
                                }}
                                disabled={this.props.onPressDisabled ? this.props.onPressDisabled : false}
                                accessibilityLabel={'room' + this.props.idx}
                                onPress={() => {
                                    if (this.props.assignMode) {
                                        if (this.state.selected) {
                                            this.props.removeSelectedArea(this.props.area)
                                        } else {
                                            this.props.addSelectedArea(this.props.area)
                                        }
                                    } else {
                                        this.props.onSinglePress(this.props.area)
                                    }
                                }}>
                                {this.props.area.task &&
                                    this.renderInitals(
                                        areaCleaningBoxDisplay,
                                        this.props.area.task.assignedTo,
                                        this.props.area.cleaningStatus,
                                        BOX_COLOR,
                                        IS_TODAY
                                    )}
                                {this.props.area.task &&
                                    this.renderDueDate(
                                        areaCleaningBoxDisplay,
                                        this.props.area.task.assignedTo,
                                        this.props.area.cleaningStatus,
                                        BOX_COLOR,
                                        IS_TODAY
                                    )}

                                {SHOW_PRIORITY && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            top: -5,
                                            left: -2,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 100
                                        }}>
                                        <Image
                                            style={{
                                                width: areaCleaningBoxDisplay.priorityCircleSize,
                                                height: areaCleaningBoxDisplay.priorityCircleSize
                                            }}
                                            source={require('@shared/img/cleaning-status/ct-priority-high-white-background.png')}
                                        />
                                    </View>
                                )}
                                <div className="row" style={{ height: 18 }}>
                                    {SHOW_DAILY_COMMENT && (
                                        <Image
                                            style={{
                                                width: 18 * icon_scaler,
                                                height: 18 * icon_scaler,
                                                marginRight:
                                                    cleaningActionIcon !== null || cleaningStatusIcon !== null ? SPACE_BETWEEN_ICONS : 0
                                            }}
                                            source={require('@shared/img/cleaning-status/daily-comment-white.svg')}
                                        />
                                    )}

                                    {cleaningActionIconCondition()}
                                    {cleaningStatusIcon !== null && (
                                        <Image
                                            style={{
                                                width: cleaningStatusIcon.width * icon_scaler,
                                                height: cleaningStatusIcon.height * icon_scaler
                                            }}
                                            source={cleaningStatusIcon.source}
                                        />
                                    )}
                                </div>
                                <span
                                    style={{
                                        lineHeight: areaCleaningBoxDisplay.lineHeight,
                                        marginTop: 0,
                                        fontWeight: areaCleaningBoxDisplay.fontWeight,
                                        fontSize: areaCleaningBoxDisplay.fontSize,
                                        borderWidth: 0,
                                        color: colors.white,
                                        textAlign: 'center',
                                        wordBreak: 'break-word',
                                        padding: 5
                                    }}>
                                    {areaCleaningBoxDisplay.name}
                                </span>

                                <div className="row" style={{ height: 18, alignItems: 'center' }}>
                                    {bottomIcons.map((icon, idx) => {
                                        if (idx < bottomIcons.length - 1) {
                                            return (
                                                <div
                                                    key={idx}
                                                    style={{
                                                        marginRight: SPACE_BETWEEN_ICONS
                                                    }}>
                                                    {icon}
                                                </div>
                                            )
                                        }
                                        return icon
                                    })}
                                </div>

                                {this.props.assignMode && this.state.active && this.renderHotcorner(BOX_COLOR)}
                            </TouchableOpacity>
                        </View>
                    </Hoverable>
                </div>
            </StyleRoot>
        )
    }

    renderHotcorner(BOX_COLOR) {
        let top_position = -5
        let right_position = -2
        let circle_dim = 22
        let background_color = iOSColors.white
        let border_width = 0

        const dot_dim = 8

        return (
            <TouchableOpacity
                onPress={() => this.props.onSinglePress(this.props.area)}
                style={{
                    position: 'absolute',
                    bottom: top_position,
                    left: right_position,
                    height: circle_dim,
                    width: circle_dim,
                    borderRadius: circle_dim / 2,
                    backgroundColor: background_color,
                    borderWidth: border_width,
                    borderColor: iOSColors.white,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <View style={{ backgroundColor: BOX_COLOR, width: dot_dim, height: dot_dim, borderRadius: dot_dim / 2 }} />
            </TouchableOpacity>
        )
    }

    renderDueDate(areaCleaningBoxDisplay, assignedTo) {
        if (assignedTo) {
            return null
        }

        return (
            <Tooltip
                distance={20}
                offset={0}
                html={thelpers.getDefaultTooltip(['due date'], iOSColors.black, 0.8)}
                theme="transparent"
                delay={500}
                arrowSize="regular"
                position="top"
                trigger="mouseenter"
                interactiveBorder={0}>
                <View
                    style={{
                        position: 'absolute',
                        top: 5,
                        right: 9,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <i className="specta-duedate" style={{ color: iOSColors.white, fontSize: 13 }} />
                </View>
            </Tooltip>
        )
    }

    renderInitals(areaCleaningBoxDisplay, assignedTo, cleaningStatus, BOX_COLOR, IS_TODAY) {
        if (!assignedTo || assignedTo.length === 0) {
            return null
        }
        const SHOW_INITIALS_GREEN_BACKGROUND = cleaningStatus === c.CLEANING_STATUS_CLEAN

        let showPlusInitials = false
        if (assignedTo.length > 1) {
            showPlusInitials = true
        }
        const assignedToNames = assignedTo.map(n => n.name).join(' ')

        const PLUS_TOP_POSITION = 15
        const PLUS_LEFT_POSITION = 8
        const FONT_SIZE = 12
        const FONT_WEIGHT = '500'

        let top_position = -5
        let right_position = -2
        let circle_dim = areaCleaningBoxDisplay.initialsCircleSize
        let background_color = iOSColors.white
        let border_width = 0
        let font_color = BOX_COLOR

        if (IS_TODAY && SHOW_INITIALS_GREEN_BACKGROUND) {
            top_position = -7
            right_position = -3
            circle_dim = areaCleaningBoxDisplay.initialsCircleSize + 2
            background_color = 'transparent'
            border_width = 1
            font_color = iOSColors.white
        }

        const tooltipTextArr = assignedTo.map(a => {
            return a.name
        })

        return (
            <Tooltip
                distance={20}
                offset={0}
                html={thelpers.getDefaultTooltip(tooltipTextArr, iOSColors.black, 0.8)}
                theme="transparent"
                delay={500}
                arrowSize="regular"
                position="top"
                trigger="mouseenter"
                interactiveBorder={0}>
                <TouchableOpacity
                    onPress={() => this.props.onSearchPress(assignedToNames)}
                    style={{
                        position: 'absolute',
                        top: top_position,
                        right: right_position,
                        height: circle_dim,
                        width: circle_dim,
                        borderRadius: circle_dim / 2,
                        backgroundColor: background_color,
                        borderWidth: border_width,
                        borderColor: iOSColors.white,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    {showPlusInitials && (
                        <span
                            style={{
                                color: font_color,
                                position: 'absolute',
                                fontSize: FONT_SIZE,
                                fontWeight: FONT_WEIGHT,
                                left: PLUS_LEFT_POSITION,
                                top: PLUS_TOP_POSITION
                            }}>
                            +{assignedTo.length - 1}
                        </span>
                    )}
                    <span
                        style={{
                            position: 'absolute',
                            color: font_color,
                            top: showPlusInitials ? 4 : 8,
                            fontSize: FONT_SIZE,
                            fontWeight: FONT_WEIGHT
                        }}>
                        {assignedTo[0].initials}
                    </span>
                </TouchableOpacity>
            </Tooltip>
        )
    }
}

export default withFeatureToggle(HousekeepingBox)
