import styled from 'styled-components'
import * as colors from '@shared/colors'

const WHITE = colors.white
const DEBUG_BORDER = 0

export const TaskboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: ${WHITE};
    border-width: ${DEBUG_BORDER}px;
    border-color: red;
`
