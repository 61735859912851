import React, { useState, useEffect } from 'react'

import { IconButton } from './buttons/IconButton'

export const PriorityButton = props => {
    const [priority, setPriority] = useState(props.priority)
    const [tooltipText, setTooltipText] = useState(props.priority ? 'See all' : 'See only high priority')

    useEffect(() => {
        if (priority === true) {
            setTooltipText('See all')
        } else {
            setTooltipText('See only high priority')
        }
    }, [priority])

    return (
        <IconButton
            className={`btn btn-circle btn-icon btn-${priority ? 'danger' : 'specta-gray'} ${priority ? 'text-white' : ''}`}
            onClick={() => {
                if (props.subscriptonAccess) {
                    setPriority(!priority)
                }
                props.onPress(!priority)
            }}
            tooltip={{ text: tooltipText, position: 'top' }}
            icon="priority-low icon-lg"
        />
    )
}
