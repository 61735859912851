import React, { useMemo } from 'react'
import { GroupOfAreas } from '../../types'
import { AreasContainer, Wrapper } from './style'
import AreaBox from './AreaBox'
import { Workload } from 'app/components/workload'
import { iOSColors } from '@shared/react-native-typography'
import { useRecoilValue } from 'recoil'
import { calculatedAreasSelector } from '../../state/selectors/calculatedArea'
import { countWorkload } from '../../state/selectors/areaRelatedData'

interface Props {
    groupOfAreas: GroupOfAreas
}

function GroupSection({ groupOfAreas: { group, areas } }: Props) {
    const calculatedAreas = useRecoilValue(calculatedAreasSelector(areas))
    const workloadCounts = useMemo(() => {
        const counts = countWorkload(calculatedAreas.map(calArea => calArea.area))
        delete counts.otherWorkCount
        return counts
    }, [calculatedAreas])

    return (
        <Wrapper>
            <label>
                <label>
                    {group}
                    <span>{` (${areas.length})`}</span>
                </label>
                <Workload fontSize={14} fontWeight={'500'} color={iOSColors.gray} counts={workloadCounts} tooltipDisabled={true} />
            </label>

            <AreasContainer>
                {areas.map((area, index) => (
                    <AreaBox key={area.key} area={area} index={index} />
                ))}
            </AreasContainer>
        </Wrapper>
    )
}

export default GroupSection
