import React, { useRef, useState } from 'react'
import { ShowGroupsWrap, UsersList, Wrapper } from './style'
import { UserStruct } from '@shared/firestore-structs'
import { useRecoilValue } from 'recoil'
import { teamWorkloadListSelector } from 'app/modules/HousekeepingDashboard/state/selectors/areaRelatedData'
import { useElementOffsetSize } from 'app/utils/hooks/useElementOffsetSize'
import { Checkbox } from 'app/components/forms/Checkbox'
import { Assignee, AssigneeWithCountAndAreas } from 'app/modules/HousekeepingDashboard/types'
import { currentUserAtom } from 'app/modules/Auth/atoms'
import { cleaningTasksAtom, selectedAreasKeysAtom } from 'app/modules/HousekeepingDashboard/state/atoms'
import { toast } from 'react-toastify'
import { toastErrorStyle } from 'app/utils/styles'
import Title from './Title'
import UserItem from './UserItem'
import UsersGroup from './UsersGroup'

interface TeamWorkloadProps {
    assign: (users: Assignee[]) => void
}

function TeamWorkload({ assign }: TeamWorkloadProps) {
    const wrapperRef = useRef<HTMLDivElement>(null)
    const { offsetHeight } = useElementOffsetSize(wrapperRef)

    const [showGroups, setShowGroups] = useState(false)
    const [expanded, setExpanded] = useState(true)

    const currentUser = useRecoilValue(currentUserAtom) as UserStruct
    const teamWorkloadList = useRecoilValue(teamWorkloadListSelector(showGroups))
    const selectedAreasKeys = useRecoilValue(selectedAreasKeysAtom)
    const cleaningTasks = useRecoilValue(cleaningTasksAtom)

    function onUserClick(user: AssigneeWithCountAndAreas) {
        if (selectedAreasKeys.size === 0) {
            toast.error('Please select at least one unit', toastErrorStyle)
            return
        }
        assign([{ key: user.key, name: user.name, initials: user.initials }])
    }

    return (
        <Wrapper ref={wrapperRef} top={offsetHeight}>
            <Title expanded={expanded} title={'Team workload'} count={cleaningTasks.length} onArrowClick={() => setExpanded(!expanded)} />

            <ShowGroupsWrap>
                {expanded && (
                    <>
                        <Checkbox isSelected={showGroups} onChange={() => setShowGroups(!showGroups)} />
                        <span>Show groups</span>
                    </>
                )}
            </ShowGroupsWrap>

            {expanded && (
                <UsersList>
                    {showGroups
                        ? teamWorkloadList.map(({ group, users }, index) => {
                              return (
                                  <UsersGroup
                                      key={group + index}
                                      group={group}
                                      users={users}
                                      currentUserKey={currentUser.key}
                                      onUserClick={onUserClick}
                                      cleaningTasks={cleaningTasks}
                                  />
                              )
                          })
                        : teamWorkloadList[0].users.map((user, index, array) => (
                              <UserItem
                                  key={user.key + index}
                                  user={user}
                                  isLast={index === array.length - 1}
                                  currentUserKey={currentUser.key}
                                  onUserClick={onUserClick}
                              />
                          ))}
                </UsersList>
            )}
        </Wrapper>
    )
}

export default TeamWorkload
