import React from 'react'
import styled from 'styled-components'
import priorityIcon from '@shared/img/priority-high-white-bkg.svg'
import redPriorityIcon from '@shared/img/priority-high-red-bgr.svg'

interface IPrioritySignProps {
    minimized?: boolean
    color?: 'white' | 'red'
    size?: number
}

const PrioritySignWrap = styled.div<IPrioritySignProps>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ minimized, size }) => (minimized ? '15px' : `${size}px`)};
    top: ${({ minimized }) => (minimized ? '-12px' : '-5px')};
    left: ${({ minimized }) => (minimized ? '-12px' : '-2px')};

    > img {
        width: ${({ size }) => `${size}px`};
        height: ${({ size }) => `${size}px`};
    }
`

function PrioritySign({ minimized = false, color = 'red', size = 18 }: IPrioritySignProps) {
    const icon = color === 'red' ? redPriorityIcon : priorityIcon
    return (
        <PrioritySignWrap minimized={minimized} color={color} size={size}>
            <img src={icon as string} />
        </PrioritySignWrap>
    )
}

export default PrioritySign
