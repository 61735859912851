import React, { useContext } from 'react'
import Alert from '../../../../components/alerts/Alert'

export function RuleEditDialog({ onHide, onConfirm }) {
    async function handleConfirm() {
        onConfirm(true)
        onHide()
    }

    return (
        <>
            <Alert
                title={'Set inspection'}
                text={'Are you sure to add insection to this rule?'}
                onCancel={onHide}
                onConfirm={handleConfirm}
            />
        </>
    )
}
