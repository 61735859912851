import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 29.3px;
`

export const InfoWrap = styled.div`
    display: flex;
    column-gap: 25px;
    height: 100%;
    align-items: center;
`

export const WorkloadAndQuickGuideWrap = styled.div`
    display: flex;
    column-gap: 10px;
    height: 100%;
    align-items: center;
`

export const quickGuideStyle = {
    content: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        borderRadius: 6,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        overflow: 'scroll',
        borderWidth: 0
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0,.4)',
        zIndex: 1005
    }
}
