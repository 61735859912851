import React, { Component } from 'react'
import { View } from 'react-native-web'

import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'
import * as uc from '../utils/constants'
import { HoverableImageButton } from '../components/buttons/hoverable-image-button'
export default class DailyCommentsSummaryButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            opacity: 1
        }
    }

    render() {
        const count_circle_dim = 20
        return (
            <View>
                <HoverableImageButton
                    dimension={uc.LARGE_BUTTON_DIM}
                    hoverOpacity={uc.HOVER_OPACITY}
                    imageSource={require('@shared/img/cleaning-status/daily-comment-filter-off.svg')}
                    onPress={this.props.onPress}
                    tooltipText={'Daily comments & other notes'}
                />

                {this.props.count > 0 && (
                    <View
                        style={{
                            opacity: 0.9,
                            zIndex: 10,
                            position: 'absolute',
                            top: -10,
                            left: 20,
                            backgroundColor: iOSColors.orange,
                            width: count_circle_dim,
                            height: count_circle_dim,
                            borderRadius: count_circle_dim / 2,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <span
                            style={{
                                color: colors.white,
                                fontSize: 11,
                                fontWeight: '400',
                                backgroundColor: 'transparent',
                                marginBottom: 1
                            }}>
                            {this.props.count}
                        </span>
                    </View>
                )}
            </View>
        )
    }
}
