import React, { useMemo } from 'react'
import { Box, SelectorWrap, TitleWrap } from '../style'
import BookingIcon from '@shared/img/cleaning-status/booking-note-white.svg?react'
import OccupancySelect from 'app/housekeeping/occupancy-select'
import * as c from '@shared/constants'
import moment from 'moment-timezone'
import { AdditionalInfo } from './style'
import { AreaStruct } from '@shared/firestore-structs'
import { BookingDisplay } from '@shared/booking-service'

interface Props {
    cleaningStatusColor: string
    occupancy: AreaStruct['occupancy']
    synced: AreaStruct['synced']
    onOccupancyChange: (occupancy: AreaStruct['occupancy']) => void
    bookingDisplay: BookingDisplay | null
}

function BookingSelector({ cleaningStatusColor, occupancy, synced, onOccupancyChange, bookingDisplay }: Props) {
    const additionalInfo = useMemo(() => {
        return (
            bookingDisplay &&
            Object.entries(bookingDisplay)
                .filter(item => item[1])
                .map(item => {
                    const [key, value] = item
                    return <span key={key}>{value}</span>
                })
        )
    }, [bookingDisplay])

    return (
        <Box>
            <SelectorWrap>
                <TitleWrap iconColor={cleaningStatusColor}>
                    <BookingIcon />
                    <h1>{'Next booking'}</h1>
                </TitleWrap>

                <OccupancySelect
                    onChange={(item: { value: AreaStruct['occupancy']; label: string; contex: string }) => onOccupancyChange(item.value)}
                    occupancy={occupancy}
                    isAreaSynced={synced}
                    selectedDateNumber={moment().startOf('day').valueOf()}
                />

                <AdditionalInfo>{additionalInfo}</AdditionalInfo>
            </SelectorWrap>
        </Box>
    )
}

export default BookingSelector
