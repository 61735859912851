import React, { createRef } from 'react'
import { View, TouchableOpacity, Image } from 'react-native-web'
import Hoverable from '../utils/hoverable/hoverable'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'

import * as chelpers from '@shared/cleaning-helpers'

import { fadeInDown } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import { IconButton } from '../components/buttons/IconButton'

const animationStyles = {
    fadeIn: {
        animation: 'x 0.25s',
        animationName: Radium.keyframes(fadeInDown, 'fadeInDown')
    }
}

export default class HousekeepingStatusPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = { open: false, statusFilter: this.props.defaultFilter }

        this.handleClick = this.handleClick.bind(this)
        this.handleOutsideClick = this.handleOutsideClick.bind(this)
        this.renderOptions = this.renderOptions.bind(this)
        this.nodeRef = createRef()
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick)
    }

    static getDerivedStateFromProps(props, state) {
        if (!HousekeepingStatusPopup.hasSameFilter(props.statusFilter, state.statusFilter)) {
            return { statusFilter: props.statusFilter }
        }
        return null
    }

    static hasSameFilter(filter1, filter2) {
        if (!filter1) {
            return false
        }

        if (!filter2) {
            return false
        }

        if (filter1.length !== filter2.length) {
            return false
        }
        for (let i = 0; i < filter1.length; i++) {
            if (filter1[i].isSelected !== filter2[i].isSelected) {
                return false
            }
            if (filter1[i].count !== filter2[i].count) {
                return false
            }
        }

        return true
    }

    handleClick() {
        this.setState(prevState => ({
            open: !prevState.open
        }))
    }

    handleOutsideClick(e) {
        if (this.nodeRef.current && !this.nodeRef.current.contains(e.target)) {
            this.setState({ open: false })
        }
    }

    render() {
        const IS_DEFAULT_FILTER = this.props.statusFilter[0].isSelected

        return (
            <View style={{ alignItems: 'center', zIndex: 30 }} ref={this.nodeRef}>
                <IconButton
                    className={`btn btn-circle btn-icon ${IS_DEFAULT_FILTER ? 'btn-specta-gray' : 'btn-danger'}`}
                    onClick={this.handleClick}
                    icon="housekeeping-filter icon-lg"
                    tooltip={{
                        text: 'Housekeeping status filter',
                        position: 'top'
                    }}
                />
                {this.state.open && (
                    <View
                        style={{
                            minWidth: 245,
                            position: 'absolute',
                            top: '100%',
                            marginTop: 6,
                            elevation: 3,
                            backgroundColor: 'transparent',
                            overflowY: 'auto'
                        }}>
                        {this.renderOptions(IS_DEFAULT_FILTER)}
                    </View>
                )}
            </View>
        )
    }

    renderOptions(IS_DEFAULT_FILTER) {
        return (
            <StyleRoot>
                <div style={animationStyles.fadeIn}>
                    <View
                        style={{
                            borderWidth: 1,
                            borderColor: colors.gentle_gray_spectaflow,
                            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)',
                            borderRadius: 8,
                            backgroundColor: 'white',
                            paddingVertical: 6
                        }}>
                        {this.state.statusFilter &&
                            this.state.statusFilter.map((option, index) => (
                                <OptionItem
                                    key={option.value}
                                    statusFilter={this.state.statusFilter}
                                    option={option}
                                    isDefaultFilter={IS_DEFAULT_FILTER}
                                    isLast={index === this.props.statusFilter.length - 1}
                                    toggleMenu={this.handleClick}
                                    setStatusFilter={this.props.setStatusFilter}
                                />
                            ))}
                    </View>
                </div>
            </StyleRoot>
        )
    }
}

// eslint-disable-next-line react/no-multi-comp
class OptionItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            backgroundColor: 'white',

            fontColor: colors.webNavbarInactive
        }
        this.changeSelectionStatus = this.changeSelectionStatus.bind(this)
        this.onPress = this.onPress.bind(this)
    }

    getSelectIcon(selected) {
        let iconSource
        if (selected) {
            iconSource = require('../img/select-on-mid-gray.svg')
        } else {
            iconSource = require('@shared/img/select-off-3.svg')
        }

        const BUTTON_DIM = 20
        return <Image style={{ height: BUTTON_DIM, width: BUTTON_DIM }} source={iconSource} />
    }

    changeAll(filter, isSelected) {
        const statusFilter = JSON.parse(JSON.stringify(filter))
        for (let i = 0; i < statusFilter.length; i++) {
            statusFilter[i].isSelected = isSelected
        }

        return statusFilter
    }

    onPress(option) {
        let shouldRender = true
        let statusFilter
        const IS_RESET = option.value === 'reset'
        if (IS_RESET) {
            if (this.props.statusFilter[0].isSelected) {
                shouldRender = false
            }
            this.props.toggleMenu()
            statusFilter = this.changeAll(this.props.statusFilter, true)
        } else {
            statusFilter = this.changeSelectionStatus(option)
        }

        this.props.setStatusFilter(statusFilter, shouldRender)
    }

    changeSelectionStatus(option) {
        let statusFilter = JSON.parse(JSON.stringify(this.props.statusFilter))
        const changedIndex = statusFilter.findIndex(o => o.value === option.value)
        statusFilter[changedIndex].isSelected = option.isSelected ? false : true

        if (changedIndex === 0) {
            if (statusFilter[0].isSelected) {
                statusFilter = this.changeAll(this.props.statusFilter, true)
            } else {
                statusFilter = this.changeAll(this.props.statusFilter, false)
            }
        } else {
            statusFilter[0].isSelected = false
        }
        return statusFilter
    }

    render() {
        const PADDING_HORIZONTAL = 16
        const BORDER_COLOR = 'green'
        const BORDER_BOTTOM_WIDTH = 1
        const ICON_MARGIN_TOP = 1
        const MARGIN_TOP = 0

        const UN_SELECTED_OPACITY_IMG = 0.4

        const isResetOption = this.props.option.value === 'reset'

        let disabled = false
        let font_color = iOSColors.black
        let opacity = 1

        if (isResetOption && this.props.isDefaultFilter) {
            font_color = iOSColors.red
            disabled = true
            opacity = 0.5
        } else if (isResetOption) {
            font_color = iOSColors.red
            opacity = 1
        } else if (!this.props.option.isSelected) {
            opacity = 0.5
        }

        const color_circle_size = 12

        return (
            <Hoverable
                onHoverIn={() => {
                    this.setState({ hover: true, backgroundColor: colors.gentle_gray_spectaflow, fontColor: iOSColors.black })
                }}
                onHoverOut={() => {
                    this.setState({ hover: false, backgroundColor: iOSColors.white, fontColor: colors.webNavbarInactive })
                }}>
                <TouchableOpacity
                    onPress={() => {
                        this.onPress(this.props.option)
                    }}
                    disabled={disabled}
                    style={{
                        paddingHorizontal: PADDING_HORIZONTAL,
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: this.state.backgroundColor
                    }}>
                    <View
                        style={{
                            width: '100%',
                            borderBottomWidth: this.props.isLast ? 0 : BORDER_BOTTOM_WIDTH,
                            borderBottomColor: '#F4F5F6'
                        }}>
                        <View
                            style={{
                                marginTop: MARGIN_TOP,
                                height: 48,
                                borderColor: BORDER_COLOR,
                                borderWidth: 0,
                                justifyContent: 'center'
                            }}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    borderWidth: 0,
                                    alignItems: 'center',
                                    justifyContent: isResetOption ? 'center' : 'space-between'
                                }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    {!isResetOption && (
                                        <View
                                            style={{
                                                opacity: opacity,
                                                backgroundColor: chelpers.pickCleaningStatusColor(this.props.option.value),
                                                borderRadius: color_circle_size,

                                                marginRight: 8,
                                                height: color_circle_size,
                                                width: color_circle_size
                                            }}
                                        />
                                    )}
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontWeight: isResetOption ? '500' : 400,
                                            opacity: opacity,
                                            color: font_color,
                                            borderWidth: 0
                                        }}>
                                        {this.props.option.label}
                                    </span>
                                </View>
                                {!isResetOption && (
                                    <View
                                        style={{
                                            opacity: this.props.option.isSelected ? 1 : UN_SELECTED_OPACITY_IMG,

                                            marginTop: ICON_MARGIN_TOP,
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                        {this.getSelectIcon(this.props.option.isSelected)}
                                    </View>
                                )}
                            </View>
                        </View>
                    </View>
                </TouchableOpacity>
            </Hoverable>
        )
    }
}
