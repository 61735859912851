import React from 'react'
import { activeRuleAtom } from './state/atoms'
import { useRecoilValue } from 'recoil'
import Notes from './Notes'
import { housekeepingIconsSelector } from './state/selectors/visuals'
import HousekeepingIcon from '../HousekeepingIcon'

function ActiveRuleSection() {
    const activeRule = useRecoilValue(activeRuleAtom)
    const [cleaningActionIcon] = useRecoilValue(housekeepingIconsSelector(true))

    if (!activeRule) return null
    return (
        <Notes
            title={activeRule.name}
            notes={activeRule.description}
            icon={{
                Icon: <HousekeepingIcon icon={cleaningActionIcon} scale={1.2} />
            }}
        />
    )
}

export default ActiveRuleSection
