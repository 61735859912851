import React, { useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import objectPath from 'object-path'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import * as brand from '@shared/brand'
import { useRecoilState } from 'recoil'
import { asideMenuIsOpen } from '../aside/state'

export function HeaderMobile() {
    const uiService = useHtmlClassService()
    const [asideIsOpen, setAsideIsOpen] = useRecoilState(asideMenuIsOpen)

    const layoutProps = useMemo(() => {
        return {
            asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
            headerMenuSelfDisplay: objectPath.get(uiService.config, 'header.menu.self.display') === true,
            headerMobileCssClasses: uiService.getClasses('header_mobile', true),
            headerMobileAttributes: uiService.getAttributes('header_mobile')
        }
    }, [uiService])

    const onBurgerClick = () => {
        setAsideIsOpen(!asideIsOpen)
    }

    const hideAside = e => {
        const aside = document.getElementById('aside-wrapper')

        if (aside && !aside.contains(e.target) && asideIsOpen) {
            setAsideIsOpen(false)
            window.removeEventListener('click', hideAside)
        }
    }

    useEffect(() => {
        window.addEventListener('click', hideAside)
        return () => {
            window.removeEventListener('click', hideAside)
        }
    }, [asideIsOpen])

    return (
        <>
            {/*begin::Header Mobile*/}
            <div
                id="kt_header_mobile"
                style={{ background: 'white' }}
                className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
                {...layoutProps.headerMobileAttributes}>
                {/*begin::Logo*/}
                <Link to="/">
                    <img alt="logo" src={brand.getNavBarAuthLogo().img} style={brand.getNavBarAuthLogo().style} />
                </Link>
                {/*end::Logo*/}

                {/*begin::Toolbar*/}
                <div className="d-flex align-items-center ml-auto">
                    {layoutProps.asideDisplay && (
                        <>
                            {/*begin::Aside Mobile Toggle*/}
                            <button onClick={onBurgerClick} className="btn p-0 burger-icon burger-icon-left">
                                <span />
                            </button>
                            {/*end::Aside Mobile Toggle*/}
                        </>
                    )}
                </div>
                {/*end::Toolbar*/}
            </div>
            {/*end::Header Mobile*/}
        </>
    )
}
