import * as React from 'react'
import { Wrap } from './style'
import Tooltip from 'app/components/tooltips/BlackTooltip'

interface Props {
    onClick: () => void
    children: React.ReactNode
    size?: number
    tooltipText?: string
    disabled?: boolean
    hoverable?: boolean
}

function ButtonIcon({ tooltipText, onClick, children, size = 27, hoverable = true, disabled = false }: Props) {
    const content = (
        <Wrap onClick={onClick} disabled={disabled} size={size} hoverable={hoverable}>
            {children}
        </Wrap>
    )

    if (tooltipText) {
        return (
            <Tooltip disabled={disabled} placement={'top'} content={tooltipText}>
                {content}
            </Tooltip>
        )
    }

    return content
}

export default ButtonIcon
