import React, { useMemo, useContext, useState } from 'react'
import { RulesContext } from '../../RulesContext'
import { RulesDeleteDialog } from '../rules-delete-dialog/RulesDeleteDialog'

export function RulesGrouping() {
    const { ids, setIds } = useContext(RulesContext)
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const rulesUIProps = useMemo(() => {
        return {
            ids: ids,
            setIds: setIds
        }
    }, [ids, setIds])

    return (
        <div className="form">
            <RulesDeleteDialog show={deleteModalIsOpen} onHide={() => setDeleteModalIsOpen(false)} />
            <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
                <div className="col-xl-12">
                    <div className="form-group form-group-inline mb-0">
                        <div>
                            <button
                                type="button"
                                className="btn btn-specta-red font-weight-bolder font-size-sm mb-5"
                                onClick={() => setDeleteModalIsOpen(true)}>
                                <i className="fa fa-trash"></i> Delete All
                            </button>
                            <div className="form-label form-label-no-wrap">
                                <label className="font-bold font-danger">
                                    <span>
                                        Selected: <b>{rulesUIProps.ids.length}</b>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
