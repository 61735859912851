import * as Yup from 'yup'
import moment from 'moment-timezone'
import ruleFormModel from './ruleFormModel'
const {
    formField: { name, description, taskName, taskColor, trigger }
} = ruleFormModel

export default [
    Yup.object().shape({
        [name.name]: Yup.string()
            .required(`${name.requiredErrorMsg}`)
            .min(3, 'Rule name must be atleast 3 symbols')
            .max(30, 'Rule name can be maximum 30 symbols'),
        [trigger.name]: Yup.string().required(`${trigger.requiredErrorMsg}`),
        repeatType: Yup.string(),
        repeatDays: Yup.array().when('repeatType', {
            is: 'weekly',
            then: Yup.string().required('You must pick days of week')
        })
    })
    // Yup.object().shape({
    // 	[taskName.name]: Yup.string()
    // 		.required(`${taskName.requiredErrorMsg}`)
    // 		.max(30, "Maximum 30 symbols"),
    // 	[taskColor.name]: Yup.string().required("MUST HAVE COLOR")
    // })
]
