import styled from 'styled-components'

export const Circle = styled.div<{ backgroundColor: string; disabled: boolean }>`
    width: 91px;
    height: 91px;
    border-radius: 50%;
    display: flex;
    gap: 3.3px;
    flex-direction: column;
    align-items: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: white;
    flex: none;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    &:hover {
        opacity: ${({ disabled }) => (disabled ? 0.5 : 0.7)};
    }
`

export const Day = styled.span`
    margin-top: 14px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1rem;
`

export const Date = styled.span`
    font-size: 2.154rem;
    font-weight: 600;
    line-height: 24px;
`
