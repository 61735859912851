export enum TaskboardContext {
    EMBEDDED = 'EMBEDDED',
    RESERVATIONS = 'RESERVATIONS',
    PROPERTY = 'PROPERTY',
    ACCOUNT = 'ACCOUNT'
}

export interface IntegrationParams<T extends TaskboardContext.PROPERTY | TaskboardContext.RESERVATIONS> {
    areaKey?: string
    propKey: string
    reservationId?: T extends TaskboardContext.RESERVATIONS ? string : undefined
    pmsLinkUrl?: T extends TaskboardContext.RESERVATIONS ? string : undefined
    propName?: T extends TaskboardContext.RESERVATIONS ? string : undefined
}

export interface ApaleoQueryParams {
    reservationId: string | null
    propertyId: string | null
    accountCode: string | null
    subjectId: string | null
}
