import React, { useState } from 'react'
import { Wrapper } from './style'
import { SectionTitleWrap } from '../style'
import UnitNoteAction from '@shared/img/cleaning-status/area-note-black.svg?react'
import { cleaningStatusColorSelector } from '../state/selectors/visuals'
import { useRecoilValue } from 'recoil'
import ContentEditable from 'app/components/content-editable'
import { htmlToMarkdown, markdownToHtml } from '@shared/markdown-helper'
import { areaAtom } from '../state/atoms'
import { AreaStruct, UserStruct } from '@shared/firestore-structs'
import { logActivity, updateArea } from '@shared/area-data'
import { currentUserAtom } from 'app/modules/Auth/atoms'
import firebase, { asFirebase } from 'app/utils/firebase'
import { toast } from 'react-toastify'
import * as c from '@shared/constants'
import { selectedDateNumberSelector } from '../state/selectors/selectedDateNumber'

function UnitNoteSection() {
    const cleaningStatusColor = useRecoilValue(cleaningStatusColorSelector)
    const area = useRecoilValue(areaAtom) as AreaStruct
    const currentUser = useRecoilValue(currentUserAtom) as UserStruct
    const selectedDateNumber = useRecoilValue(selectedDateNumberSelector)

    const [unitNoteValue, setUnitNoteValue] = useState(area.note ?? '')

    async function onSubmit() {
        if (unitNoteValue === area.note) return

        const note = htmlToMarkdown(unitNoteValue)
        const change = area?.note ? { before: area.note, after: note } : { after: note }

        try {
            await updateArea(asFirebase(firebase), area.key, { ...area, note }, currentUser)
            await logActivity(asFirebase(firebase), currentUser, area.key, c.ACTIVITY_TYPE_AREA_NOTE, selectedDateNumber, change)
        } catch (error: unknown) {
            setUnitNoteValue(area.note ?? '')
            console.error(error)
            toast.error(`Failed to save unit note (${(error as Error).message})`)
        }
    }

    return (
        <Wrapper>
            <SectionTitleWrap iconColor={cleaningStatusColor} iconWidth={18} iconHeight={23}>
                <UnitNoteAction />
                <h2>Unit note</h2>
                <span>is permanent</span>
            </SectionTitleWrap>

            <div>
                <ContentEditable
                    placeholder={'Click to add a note'}
                    content={markdownToHtml(unitNoteValue)}
                    onChange={(value: string) => setUnitNoteValue(value)}
                    onSubmit={onSubmit}
                    showIcon
                    style={{}}
                    disabled={false}
                />
            </div>
        </Wrapper>
    )
}

export default UnitNoteSection
