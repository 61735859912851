import { Formik, Form, Field } from 'formik'
import React, { useState, useEffect, useContext } from 'react'
import Input from '../../../../components/forms/Input'
import { FormBox } from './style'
import RectangularButton from '../../../../components/buttons/RectangularButton'
import * as Yup from 'yup'
import { getGeoLocation, getCountryItem, getAllCountries } from '../../../../components/country-selector'
import PhoneInput from '../../../../components/forms/PhoneInput'
import firebase from '../../../../utils/firebase'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { confirmationAtom, signUpDataAtom, formIsOpenAtom } from '../../state'
import { AuthContext } from '../../../Auth/AuthContext'
import { toast } from 'react-toastify'
import { toastErrorStyle } from '../../../../utils/styles'
import { getBrand } from '@shared/brand'
import { formatPhoneInputValue, phoneNumberValidationTest } from '../../../../utils/formatters'
import { getRecaptchaVerifier } from '../../../../utils/app-verifier'

const SignUpForm = ({ setCodeVerificationModalIsOpen }) => {
    const initialValues = { orgName: '', ownerPhoneNumber: '', ownerName: '', ownerEmail: '' }

    const [selectedCountry, setSelectedCountry] = useState('')
    const formIsOpen = useRecoilValue(formIsOpenAtom)
    const { setStopAuthListener } = useContext(AuthContext)
    const setSignUpData = useSetRecoilState(signUpDataAtom)
    const setConfirmation = useSetRecoilState(confirmationAtom)
    const brand = getBrand()
    const brandColor = brand.navBarColor
    const brandName = brand.name

    const accountSchema = Yup.object().shape({
        orgName: Yup.string().required('Account name is required'),
        ownerName: Yup.string().required('Full name is required'),
        ownerEmail: Yup.string().email('Email must be valid').required('Email is required'),
        ownerPhoneNumber: Yup.string()
            .test('ownerPhoneNumber', 'Phone number must be valid', function (value) {
                return phoneNumberValidationTest(value, selectedCountry)
            })
            .required('Phone number is required')
    })

    useEffect(() => {
        const allCountries = getAllCountries().map(country => ({ ...country, label: country.dialCode }))
        getGeoLocation(allCountries, country => {
            if (country) {
                setSelectedCountry(country)
            } else {
                setSelectedCountry(getCountryItem('US', allCountries))
            }
        })
    }, [])

    const onSubmit = async values => {
        setCodeVerificationModalIsOpen(true)
        setStopAuthListener(true)

        const { orgName, ownerName, ownerEmail, ownerPhoneNumber } = values
        const phoneNumber = selectedCountry.dialCode + ownerPhoneNumber

        try {
            const appVerifier = getRecaptchaVerifier()
            const confirmation = await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)

            setSignUpData({
                orgName,
                ownerName,
                ownerEmail,
                phoneNumber
            })
            setConfirmation(confirmation)
        } catch (error) {
            const message = error.code === 'auth/invalid-phone-number' ? 'Invalid phone number, please use another one' : error.message

            setCodeVerificationModalIsOpen(false)

            toast.error(message, toastErrorStyle)
            console.error(error)
        }
    }

    return (
        <FormBox formIsOpen={formIsOpen}>
            <h1>Your {brandName} Account</h1>

            <Formik enableReinitialize initialValues={initialValues} validationSchema={accountSchema} onSubmit={onSubmit}>
                {({ handleSubmit, setFieldValue, values }) => {
                    const { orgName, ownerName, ownerEmail, ownerPhoneNumber } = values

                    return (
                        <Form>
                            <Field
                                autoFocus
                                name={'orgName'}
                                value={orgName}
                                component={Input}
                                label={'Account name'}
                                placeholder={'Name of your hotel or organization'}
                                onChange={e => setFieldValue('orgName', e.target.value)}
                            />

                            <Field
                                name={'ownerName'}
                                value={ownerName}
                                component={Input}
                                label={'Full name'}
                                placeholder={'Your name'}
                                onChange={e => setFieldValue('ownerName', e.target.value)}
                            />

                            <Field
                                name={'ownerEmail'}
                                value={ownerEmail}
                                component={Input}
                                label={'Email'}
                                placeholder={'Your email address'}
                                onChange={e => setFieldValue('ownerEmail', e.target.value)}
                            />

                            <Field
                                name={'ownerPhoneNumber'}
                                component={PhoneInput}
                                label={'Mobile phone number'}
                                placeholder={'Your mobile phone number'}
                                value={formatPhoneInputValue(ownerPhoneNumber, selectedCountry)}
                                selectedCountry={selectedCountry}
                                onChange={e => setFieldValue('ownerPhoneNumber', e.target.value)}
                                onCountryChange={setSelectedCountry}
                            />

                            <RectangularButton
                                type="submit"
                                onClick={handleSubmit}
                                width={'318px'}
                                height={'64px'}
                                backgroundColor={brandColor}
                                margin={'62px 0'}>
                                Create your account
                            </RectangularButton>

                            <div id="recaptcha-container" />
                        </Form>
                    )
                }}
            </Formik>
        </FormBox>
    )
}

export default SignUpForm
