import React from 'react'
import { SummaryTabType, SummaryTab } from 'app/modules/HousekeepingDashboard/types'
import * as c from '@shared/constants'
import { Wrap } from './style'

interface SummaryTabProps {
    icon: string
    title: string
    count: number
    type: SummaryTabType
    isActiveTab: boolean
    onClick: (tab: SummaryTab) => void
}

function Tab({ icon, title, count, type, isActiveTab, onClick }: SummaryTabProps) {
    return (
        <Wrap isActiveTab={isActiveTab} onClick={() => onClick({ title, type, count, icon, isActiveTab })}>
            <i className={`${icon} ${type === c.ACTIVITY_TYPE_DAILY_COMMENT ? 'icon-lg' : ''}`} />
            <span>{title}</span>
            {count > 0 && <div>{count}</div>}
        </Wrap>
    )
}

export default Tab
