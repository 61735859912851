import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity } from 'react-native-web'
import Select from 'react-select'
import firebase from '../utils/firebase'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'
import listOfCountriesOriginal from './country-dial-codes.json'

const Option = props => {
    const labelCondition = props.data.optionLabel ? props.data.optionLabel : props.data.label

    return (
        <TouchableOpacity onPress={() => props.selectOption(props.data)}>
            <View key={props.value} style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 8, marginRight: 8 }}>
                <View style={{ width: '100%', borderBottomWidth: 1, borderBottomColor: '#F4F5F6' }}>
                    <View
                        style={{
                            height: props.selectProps.height,
                            flexDirection: 'column',
                            borderColor: 'green',
                            borderWidth: 0,
                            justifyContent: 'center'
                        }}>
                        <span
                            className="font-weight-bold text-dark-65"
                            style={{
                                borderWidth: 0,
                                paddingLeft: 10
                            }}>
                            {labelCondition}
                        </span>
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    )
}

export const PhoneCountrySelector = ({ selectedCountry, onCountryChange, disabled = false, customStyles = null }) => {
    const [allCountries, setAllCountries] = useState([])
    const [value, setValue] = useState()

    useEffect(() => {
        const countries = getAllCountries()

        setAllCountries(countries.map(country => ({ ...country, label: country.dialCode })))
    }, [])

    useEffect(() => {
        if (allCountries.length > 0 && !selectedCountry && window.location.href.includes('new')) {
            try {
                getGeoLocation(allCountries, country => {
                    setValue(country)
                    if (onCountryChange) {
                        onCountryChange(country)
                    }
                })
            } catch (err) {
                console.error(`PhoneCountrySelector -> getGeoLocation -> ${err}`)
                setValue(getCountryItem('US', allCountries))
                if (onCountryChange) {
                    onCountryChange(getCountryItem('US', allCountries))
                }
            }
        } else {
            setValue(selectedCountry)
        }
    })

    const styles = {
        control: styles => ({
            ...styles,
            backgroundColor: colors.white,
            border: `1px solid ${colors.bs_gray_300}`,
            width: '60.34px',
            color: '#3f4254',
            fontSize: '12px',
            borderRadius: '6px 0 0 6px',
            boxShadow: 'none',
            cursor: 'pointer',
            ':hover': {
                borderColor: colors.green_spectaflow
            }
        }),

        menu: styles => ({
            ...styles,
            width: '300px'
        }),

        option: styles => ({
            ...styles,
            height: '50px'
        }),

        indicatorsContainer: () => ({
            display: 'none'
        })
    }

    const onChange = country => {
        onCountryChange(country)
    }

    return (
        <Select
            height={38}
            value={value}
            placeholder="Loading"
            styles={customStyles ? customStyles : styles}
            isSearchable
            components={{ Option }}
            options={allCountries}
            onChange={onChange}
            isDisabled={disabled}
        />
    )
}

const getStyle = props => {
    return {
        control: styles => ({
            ...styles,
            height: props.height,
            width: '100%',
            borderWidth: 1,
            borderColor: colors.textInputBorder,
            borderRadius: 6,
            boxShadow: 'none',
            ':hover': {
                borderWidth: 1,
                borderColor: colors.green_spectaflow,
                borderRadius: 6
            }
        }),
        placeholder: () => ({
            paddingLeft: 8,
            color: iOSColors.midGray
        }),
        singleValue: () => ({
            paddingLeft: 6,
            color: iOSColors.black
        }),
        indicatorSeparator: styles => ({
            ...styles,
            width: 0
        })
    }
}

export const getAllCountries = () => {
    const countries = listOfCountriesOriginal.map(item => {
        const dialCodeAndName = item.dial_code + ' ' + item.name

        return {
            label: dialCodeAndName,
            value: dialCodeAndName,
            key: item.code,
            dialCode: item.dial_code,
            optionLabel: dialCodeAndName
        }
    })

    return countries
}

export const getCountryItem = (countryCode, allCountries) => {
    if (!allCountries) {
        return null
    }
    const result = allCountries.filter(el => el.key === countryCode)
    return result[0]
}

export const getGeoLocation = (allCountries, callback) => {
    const getGeoLocation = firebase.functions().httpsCallable('getGeolocation')
    getGeoLocation()
        .then(result => {
            const userLocale = result.data
            if (userLocale.country !== undefined && userLocale.country !== '') {
                const countryItem = getCountryItem(userLocale.country, allCountries)
                callback(countryItem)
            }
        })
        .catch(error => {
            console.error('error', error)
        })
}
