import React, { useEffect } from 'react'
import SignUpLayout from '../../SignUpLayout/index'
import LoadingScreen from '../../LoadingScreen/index'
import { compose } from 'redux'
import withIntegrationWorkflow from '../../../../utils/hocs/withIntegrationWorkflow'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import { INTEGRATIONS } from '../../../../utils/constants'
import { mewsSetup } from '../../cloud-functions'
import { errorModalConfigs } from '../../helpers'
import { SIGN_UP } from '../../../../navigation/url-constants'

const MewsSetup = ({
    newOrgKey,
    catchError,
    signupComplete,
    workflowInstance,
    storeWorkflowInstance,
    setComplete,
    currentIntegrationKey,
    newUserKey,
    settings,
    message,
    setStopAuthListener
}) => {
    const setupMews = async () => {
        try {
            const enterpriseId = workflowInstance.getInput('enterpriseId')
            const isDemo = !!workflowInstance.getInput('demo')

            const setupMewsIntegration = mewsSetup()

            const { data } = await setupMewsIntegration({
                orgId: newOrgKey,
                integration: INTEGRATIONS.MEWS,
                signupPayload: { organizationID: newOrgKey, enterpriseID: enterpriseId, isDemo: isDemo }
            })

            if (data.reason !== 'all good') {
                catchError(data.message, errorModalConfigs)
                return
            }

            if ('test' in workflowInstance.values) {
                data.reason = 'test'
            }

            await signupComplete({ orgId: newOrgKey, userId: newUserKey, status: data })

            workflowInstance.completeStep(SIGN_UP.MEWS_SETUP, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    async function setup() {
        setStopAuthListener(true)
        setupMews()
    }

    useEffect(() => {
        setup()
    }, [])

    return (
        <SignUpLayout withModal>
            <LoadingScreen message={message} />
        </SignUpLayout>
    )
}

export default compose(withIntegrationWorkflow, withErrorCatching)(MewsSetup)
