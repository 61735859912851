import React from 'react'
import { View } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'

import * as helpers from '@shared/helpers'
import { Initials } from '../components/initials'
import { InitialsView } from '../components/initials-view'

//TODO - Rewrite to tsx
export default function Assignees(props) {
    const assignees = props.assignees

    if (!assignees) {
        return (
            <View>
                <span style={{ fontSize: 14, color: iOSColors.midGray }}>Not assigned yet</span>
            </View>
        )
    }

    const MORE_THAN_2_ASSIGNEES = assignees.length > 2

    if (MORE_THAN_2_ASSIGNEES) {
        return (
            <InitialsView
                maxCountToDisplay={props.maxCountToDisplay}
                circleDimension={30}
                fontSize={11}
                justifyContent={'flex-start'}
                currentUser={props.currentUser}
                assignedTo={assignees}
            />
        )
    }
    const DEBUG_BORDER = 0
    return (
        <View
            style={{
                borderWidth: DEBUG_BORDER
            }}>
            {assignees.map((item, index) => {
                const isLast = index === assignees.length - 1

                return (
                    <View
                        key={item.initials + Math.random()}
                        style={{
                            borderWidth: DEBUG_BORDER,
                            borderColor: 'blue',
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginBottom: isLast ? 0 : 6
                        }}>
                        <Initials
                            noMargin={true}
                            key={item.initials + Math.random()}
                            disabled={true}
                            item={item}
                            currentUser={props.currentUser}
                            dimension={26}
                            fontSize={10}
                        />
                        <View
                            style={{
                                flexDirection: 'column',
                                borderColor: 'green',
                                borderWidth: DEBUG_BORDER,
                                justifyContent: 'center',
                                marginLeft: 6
                            }}>
                            <span
                                style={{
                                    fontWeight: 500,
                                    fontSize: 15,
                                    borderWidth: DEBUG_BORDER,
                                    color: iOSColors.gray
                                }}>
                                {helpers.shortenLongName(item.name, 30)}
                            </span>
                        </View>
                    </View>
                )
            })}
        </View>
    )
}
