import React from 'react'
import { HoverableImageButton } from 'app/components/buttons/hoverable-image-button'
import styled from 'styled-components'
import * as uc from 'app/utils/constants'
import dailyCommentIcon from '@shared/img/cleaning-status/daily-comment-filter-off.svg'
import { iOSColors } from '@shared/react-native-typography'
import { white } from '@shared/colors'

interface Props {
    count: number
    onClick: () => void
}

const Count = styled.div`
    opacity: 0.9;
    z-index: 1;
    position: absolute;
    top: -10px;
    left: 20px;
    background-color: ${iOSColors.orange};
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${white};
    font-size: 0.846rem;
    font-weight: 400;
    padding-bottom: 1px;
`

function DailyCommentButton({ count, onClick }: Props) {
    return (
        <>
            <HoverableImageButton
                dimension={uc.LARGE_BUTTON_DIM}
                hoverOpacity={uc.HOVER_OPACITY}
                imageSource={dailyCommentIcon}
                onPress={onClick}
                tooltipText="Daily comments & other notes"
                hoverImageSource={''}
                tooltipDistance={0}
                tooltipOffset={0}
            />

            {count > 0 && <Count>{count}</Count>}
        </>
    )
}

export default DailyCommentButton
