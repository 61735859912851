import ButtonsBar from '../../issues/buttons-bar'
import React, { useContext } from 'react'
import { DashboardContext } from './DashboardContext'

const AreaGroupsFilter = ({ areas }: { areas: any[] }) => {
    const { setSearchValue } = useContext(DashboardContext)
    const onSearch = (val: string) => {
        setSearchValue(val)
    }

    return (
        <div className="row row-paddingless" style={{ position: 'relative', zIndex: 1 }}>
            <div className="col-xs-12">
                <ButtonsBar
                    type={'housekeeping-dashboard'}
                    countedItems={getCountedAreaGroups(areas)}
                    onClick={onSearch}
                    respWidth={window.innerWidth * 0.8}
                />
            </div>
        </div>
    )
}

export default AreaGroupsFilter

const getCountedAreaGroups = (areas: { name: string | any[]; group: string | number }[]) => {
    let countedAreaGroups: any[] = []
    const hashWithCount = {}
    let longestAreaName = 0

    areas.forEach((area: { name: string | any[]; group: string | number }) => {
        longestAreaName = area.name.length > longestAreaName ? area.name.length : longestAreaName
        // @ts-ignore
        hashWithCount[area.group] = hashWithCount[area.group] === undefined ? 1 : hashWithCount[area.group] + 1
    })

    Object.keys(hashWithCount).forEach(key => {
        countedAreaGroups.push({
            name: key,
            // @ts-ignore
            count: hashWithCount[key]
        })
    })

    countedAreaGroups = countedAreaGroups.sort((a, b) => b.count - a.count)

    return countedAreaGroups
}
