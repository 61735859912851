import HousekeepingBox from '../housekeeping-box/housekeeping-box'
import * as c from '@shared/constants'
import React, { useContext } from 'react'
import { DashboardContext } from './DashboardContext'
import { SubscriptionContext } from '../../modules/Subscriptions/SubscriptionContext'
import { getTilesDimensions } from './helpers'
import { AreaStruct } from '@shared/firestore-structs'
import { AuthContext } from '../../modules/Auth/AuthContext'

const Area = ({ area }: { area: any }) => {
    const {
        setAreaModalIsOpen,
        setSelectedArea,
        setSelectedTask,
        selectedDateNumber,
        showActionBar,
        setSearchValue,
        selectedAreas,
        setSelectedAreas
    } = useContext(DashboardContext)
    const { isFeatureInSubscription } = useContext(SubscriptionContext)
    const { currentUser } = useContext(AuthContext)

    const onSinglePress = (area: { synced: any; key: any; task: any }) => {
        if (isFeatureInSubscription('unlimited-areas') || area.synced) {
            setAreaModalIsOpen(true)
            setSelectedArea(area)
            setSelectedTask(area.task)
        } else {
            // this.openUpgradeMessage(this.state.notAccessibleFeature, this.state.availableUpgrades)
        }
    }

    const dim = getTilesDimensions()

    const onSearch = (val: string) => setSearchValue(val)
    const onAddSelectedArea = (area: AreaStruct) => {
        setSelectedAreas([...selectedAreas, area])
        // const actionBarYposition = window.innerHeight - CLEANING_ACTION_BAR_HEIGHT
        //
        // this.setState({
        //     actionBarYposition
        // })
    }
    const onRemoveSelectedArea = (area: AreaStruct) => {
        setSelectedAreas(selectedAreas.filter((a: AreaStruct) => a.key !== area.key))
    }

    return (
        <HousekeepingBox
            context={c.CONTEXT_WEB_APP_CLEANING}
            key={area.key + area.updated}
            height={100}
            width={100}
            selectedDateNumber={selectedDateNumber}
            assignMode={showActionBar}
            problems={(area.bookings?.length ?? 0) > 2 && currentUser?.authSuper}
            area={area}
            tilesGutter={dim.tilesGutter}
            onSinglePress={onSinglePress}
            onSearchPress={onSearch}
            selected={selectedAreas.find((a: AreaStruct) => a.key === area.key) !== undefined}
            addSelectedArea={onAddSelectedArea}
            removeSelectedArea={onRemoveSelectedArea}
            hasSubscriptionAccess={isFeatureInSubscription('unlimited-areas')}
            onPressDisabled={area.dormant}
            withoutMargin={true}
        />
    )
}

export default Area
