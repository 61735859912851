import styled from 'styled-components'
import { gentle_gray_spectaflow } from '@shared/colors'

export const Wrap = styled.div<{ isSelected: boolean }>`
    width: 100%;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.5)};

    &:hover {
        background-color: ${gentle_gray_spectaflow};
    }
`

export const TitleWrap = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;

    > span {
        font-size: 1.077rem;
        font-weight: 400;
    }
`

export const ColorCircle = styled.div<{ color: string }>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
`

export const CheckboxWrap = styled.div`
    width: 20px;
    height: 20px;

    > img {
        width: 100%;
        height: 100%;
    }
`
