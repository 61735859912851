import React, { ReactNode } from 'react'
import { View } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'
import Radium from 'radium'
import withAsideMenuToggleFlag from '../utils/hocs/withAsideMenuToggleFlag'
import MainActionButton from '../components/buttons/MainActionButton'
import { IssueStruct } from '@shared/firestore-structs'
import { ActionBarContainer } from './style'

const ISSUES_ACTION_BAR_HEIGHT = 85

interface ActionBarProps {
    asideIsOpen: boolean
    isMobile: boolean
    checkedIssues: IssueStruct[]
    clearCheckedIssues: () => void
    setShowIssueActionBar: (show: boolean) => void
    onDeletePress: () => void
    openMassStatusModal: () => void
    openMassHashTagModal: () => void
    onMassUnassign: () => void
    openUserList: () => void
}

const ActionBar: React.FC<ActionBarProps> = props => {
    const { asideIsOpen, isMobile } = props
    const DEBUG_BORDER = 0

    const is_active = props.checkedIssues.length > 0
    const asideMargin = !isMobile ? (asideIsOpen ? '248px' : '80px') : '0'

    return (
        <Radium.StyleRoot>
            <ActionBarContainer className="test">
                <div
                    className="fixed-bottom h-20"
                    style={{
                        backgroundColor: colors.gentle_gray_spectaflow,
                        left: asideMargin
                    }}>
                    <View
                        style={{
                            borderTopWidth: 1,
                            borderTopColor: colors.gentle_gray_spectaflow,
                            backgroundColor: colors.gentle_gray_spectaflow,
                            height: ISSUES_ACTION_BAR_HEIGHT,
                            width: '100%',

                            zIndex: 10000,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingHorizontal: 40
                        }}>
                        <View
                            style={{
                                width: '40%',
                                flexDirection: 'row',
                                alignItems: 'center',
                                borderColor: 'red',
                                borderWidth: DEBUG_BORDER,
                                justifyContent: 'flex-start'
                            }}>
                            <View
                                style={{
                                    width: '90%',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    borderColor: 'red',
                                    borderWidth: DEBUG_BORDER,
                                    justifyContent: 'space-between'
                                }}>
                                <MainActionButton
                                    type="secondary"
                                    onClick={() => {
                                        props.clearCheckedIssues()
                                        props.setShowIssueActionBar(false)
                                    }}>
                                    Close
                                </MainActionButton>
                                <MainActionButton type="danger" featureToCheck="issues" disabled={!is_active} onClick={props.onDeletePress}>
                                    Delete
                                </MainActionButton>
                                <MainActionButton
                                    type="info"
                                    featureToCheck="issues"
                                    disabled={!is_active}
                                    onClick={props.openMassStatusModal}>
                                    Set status
                                </MainActionButton>
                            </View>
                        </View>
                        <View style={{ width: '20%', alignItems: 'center', justifyContent: 'center', borderWidth: DEBUG_BORDER }}>
                            <span
                                style={{
                                    fontSize: 14,
                                    fontWeight: '400',
                                    color: iOSColors.gray
                                }}>
                                {props.checkedIssues.length} selected
                            </span>
                        </View>

                        <View
                            style={{
                                width: '40%',
                                flexDirection: 'row',
                                alignItems: 'center',
                                borderColor: 'blue',
                                borderWidth: DEBUG_BORDER,
                                justifyContent: 'flex-end'
                            }}>
                            <View
                                style={{
                                    width: '90%',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    borderColor: 'red',
                                    borderWidth: DEBUG_BORDER,
                                    justifyContent: 'space-between'
                                }}>
                                <MainActionButton
                                    type="info"
                                    disabled={!is_active}
                                    featureToCheck="issues"
                                    onClick={props.openMassHashTagModal}>
                                    Set hashtags
                                </MainActionButton>
                                <MainActionButton
                                    type="danger"
                                    featureToCheck="issues"
                                    disabled={!is_active}
                                    onClick={props.onMassUnassign}>
                                    Unassign
                                </MainActionButton>
                                <MainActionButton type="primary" featureToCheck="issues" disabled={!is_active} onClick={props.openUserList}>
                                    Assign
                                </MainActionButton>
                            </View>
                        </View>
                    </View>
                </div>
            </ActionBarContainer>
        </Radium.StyleRoot>
    )
}

export default withAsideMenuToggleFlag(ActionBar)
