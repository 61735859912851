import React from 'react'
import '../../_metronic/_assets/sass/pages/error/error-5.scss'
import * as brand from '@shared/brand'

export function ErrorPage({ notLoggedIn }) {
    const bgImage = require('@shared/img/error/bg5.jpg')

    const brandColor = brand.getBrand().navBarColor

    const backLink = notLoggedIn ? (
        <span>
            Try <a href="/">login</a>
        </span>
    ) : (
        <span>
            Try going back to to the <a href="/">dashboard</a>
        </span>
    )

    return (
        <div className="d-flex flex-column flex-root">
            <div
                className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
                style={{
                    backgroundImage: { bgImage }
                }}>
                <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
                    <h1 className="error-title font-weight-boldest mt-10 mt-md-0 mb-12" style={{ color: brandColor }}>
                        Oops!
                    </h1>
                    <p className="font-weight-boldest display-4">Something went wrong here.</p>
                    <p className="font-size-h3">
                        The link is broken or the page has moved.
                        <br />
                        {backLink}
                    </p>
                </div>
            </div>
        </div>
    )
}
