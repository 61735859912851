import React from 'react'
import { capitalize } from 'lodash'
import MainActionButton from '../buttons/MainActionButton'

export function ModalFormFooter({ onHide, onSubmit, dataLoading, actionsLoading, type }) {
    const getButtonType = () => {
        return type === 'save' ? 'primary' : 'danger'
    }

    return (
        <>
            <MainActionButton type="secondary" size="large" onClick={onHide}>
                Cancel
            </MainActionButton>
            <div className="mr-5"> </div>
            <MainActionButton type={getButtonType()} size="large" onClick={() => onSubmit()} disabled={dataLoading || actionsLoading}>
                {!actionsLoading ? (
                    capitalize(type)
                ) : (
                    <span>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                    </span>
                )}
            </MainActionButton>
        </>
    )
}
