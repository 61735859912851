import moment, { Moment } from 'moment-timezone'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import fuzzy from 'fuzzy'
import { isPhoneNumberValid } from '@shared/helpers'

export const formatTimeHoursAndMinutes = (time: Moment) => moment(time).format('HH:mm')
export const formatMonthDayYear = (time: Moment) => moment(time).format('MMM DD, YYYY')
export const hours = (time: number) => moment.duration(time).hours()
export const minutes = (time: number) => moment.duration(time).minutes()
export const millisecondsAsHours = (time: number) => Math.floor(moment.duration(time, 'milliseconds').asHours())
export const millisecondsAsMinutes = (time: number) => Math.floor(moment.duration(time, 'milliseconds').minutes())

//TODO type selectedCountry
export const formatPhoneInputValue = (inputValue: string, selectedCountry?: any) => {
    const dialCode = selectedCountry?.dialCode
    const pn = dialCode ? dialCode + inputValue : inputValue
    const parsedNumber = parsePhoneNumberFromString(selectedCountry ? pn : '')
    const value = parsedNumber ? parsePhoneNumberFromString(pn)?.formatNational() : inputValue.replace(/\D/g, '')

    return value
}

//TODO type selectedCountry
export const phoneNumberValidationTest = (inputValue: string, selectedCountry: any) => {
    const phoneNumber = selectedCountry.dialCode + inputValue
    const countryCode = selectedCountry.key
    return isPhoneNumberValid(phoneNumber, countryCode)
}

export const sortAlphabetically = (a: string, b: string) => {
    const numA = parseInt(a, 10)
    const numB = parseInt(b, 10)

    if (!isNaN(numA) && !isNaN(numB)) {
        return numA - numB
    }

    if (!isNaN(numA)) {
        return -1
    }

    if (!isNaN(numB)) {
        return 1
    }

    return a.localeCompare(b)
}

export const normalizeString = (str: string) =>
    str
        .trim()
        .toLowerCase()
        .replace(/[\u0300-\u036f]/g, '')

export function search<T>(value: string, searchArray: T[], extract: (item: T) => string): T[] {
    return fuzzy
        .filter(normalizeString(value), searchArray, {
            extract: item => normalizeString(extract(item))
        })
        .map(result => result.original)
}

export function getStartOfDayTimestamp(date: number | Moment | Date | null = null, timezone: string = moment.tz.guess()): number {
    return moment(date || new Date())
        .startOf('day')
        .tz(timezone)
        .valueOf()
}

export function convertTimestampToLocalDateObject(timestamp: number) {
    return moment(moment(timestamp).format('ddd, MMM DD, YYYY')).toDate()
}
