import React from 'react'
import { Button, Count, Title } from './style'

interface ButtonContent {
    title: string
    count?: number
}

interface Props extends ButtonContent {
    onClick: (props: ButtonContent) => void
}

function ButtonWithCount({ title, count, onClick }: Props) {
    return (
        <Button onClick={() => onClick({ title, count })}>
            <Title>{title}</Title>
            {count && <Count>{`(${count})`}</Count>}
        </Button>
    )
}

export default ButtonWithCount
