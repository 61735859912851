import React from 'react'
import Tooltip from '../tooltips/BlackTooltip'

export const IconButton = props => {
    const button = (
        <button href="#" {...props}>
            <i className={`specta-${props.icon} ${props.iconsize}`} />
        </button>
    )

    if (props.tooltip) {
        const { text, position, ...rest } = props.tooltip

        return (
            <Tooltip placement={position || 'top'} content={text} {...rest}>
                {button}
            </Tooltip>
        )
    }

    return button
}
