import React, { useState } from 'react'
import { PreviewBoxWrapper } from './style'
import trashIcon from '../../../img/trash.svg'

const PreviewBox = ({ name, preview, index, deleteImage }) => {
    const [deleteButtonIsShown, setDeleteButtonIsShown] = useState(false)

    const onMouseEnter = () => {
        setDeleteButtonIsShown(true)
    }

    const onMouseLeave = () => {
        setDeleteButtonIsShown(false)
    }

    const onDeleteButton = () => {
        deleteImage(index)
    }

    const deleteButtonCondition = deleteButtonIsShown && <img onClick={onDeleteButton} className="trash-icon" src={trashIcon} />

    return (
        <PreviewBoxWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <img src={preview} />
            <span>{name}</span>
            {deleteButtonCondition}
        </PreviewBoxWrapper>
    )
}

export default PreviewBox
