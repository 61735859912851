import React, { createContext, useState, useContext, useCallback } from 'react'
import { isEqual, isFunction } from 'lodash'
import { initialFilter } from './TasksUIHelpers'
import moment from 'moment-timezone'
import * as c from '@shared/constants'

export const TasksContext = createContext()

const initTask = {
    name: '',
    startDate: moment().toDate(),
    status: c.TASK_OPEN,
    type: c.TASK_TYPE_GENERAL,
    priority: false
}

export const TasksProvider = props => {
    const [tasks, setTasks] = useState([])
    const [allTasks, setAllTasks] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [listLoading, setListLoading] = useState(true)
    const [queryParams, setQueryParamsBase] = useState(initialFilter)
    const [ids, setIds] = useState([])
    const [taskForEdit, setTaskForEdit] = useState(initTask)
    const [actionsLoading, setActionsLoading] = useState(false)
    const [modalDaySelected, setModalDaySelected] = useState(moment().startOf('day').valueOf())
    const [selectedDate, setSelectedDate] = useState(moment().startOf('day').valueOf())

    const tasksUIEvents = props.tasksUIEvents

    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams)
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams
            }
            return nextQueryParams
        })
    }, [])

    const value = {
        tasks,
        setTasks,
        allTasks,
        setAllTasks,
        totalCount,
        setTotalCount,
        listLoading,
        setListLoading,
        ids,
        setIds,
        queryParams,
        setQueryParams,
        setQueryParamsBase,
        initTask,
        taskForEdit,
        setTaskForEdit,
        actionsLoading,
        setActionsLoading,
        modalDaySelected,
        setModalDaySelected,
        selectedDate,
        setSelectedDate,
        newTaskButtonClick: tasksUIEvents.newTaskButtonClick,
        openEditTaskDialog: tasksUIEvents.openEditTaskDialog,
        openDeleteTaskDialog: tasksUIEvents.openDeleteTaskDialog,
        openDeleteTasksDialog: tasksUIEvents.openDeleteTasksDialog
    }

    return <TasksContext.Provider value={value} {...props} />
}
