import React, { useState } from 'react'
import { Wrap } from './style'
import { SectionTitleWrap } from '../style'
import { useRecoilValue } from 'recoil'
import { issuesAtom } from '../state/atoms'
import IssueRow from 'app/issues/IssuesBody/IssueRow'
import { IssueStruct, OrgStruct, UserStruct } from '@shared/firestore-structs'
import { currentOrganizationAtom, currentUserAtom } from 'app/modules/Auth/atoms'
import * as uc from 'app/utils/constants'
import AIssueModal from 'app/issues/aissue-modal'
import Modal, { Styles } from 'react-modal'
import { aIssueModalStyle } from 'app/tasks/TasksBody/Column/style'

function IssuesSection() {
    const issues = useRecoilValue(issuesAtom)
    const currentUser = useRecoilValue(currentUserAtom) as UserStruct
    const currentOrganization = useRecoilValue(currentOrganizationAtom) as OrgStruct

    const [currentIssue, setCurrentIssue] = useState<IssueStruct | null>(null)

    const openModal = (issue: IssueStruct) => setCurrentIssue(issue)
    const closeModal = () => setCurrentIssue(null)

    if (issues.length === 0) return null
    return (
        <>
            {currentIssue && (
                <Modal
                    isOpen={!!currentIssue}
                    onRequestClose={closeModal}
                    style={
                        {
                            content: aIssueModalStyle.content,
                            overlay: { ...aIssueModalStyle.overlay, position: 'fixed', zIndex: 10000 }
                        } as Styles
                    }
                    contentLabel="Open issue">
                    <AIssueModal
                        respWidth={0}
                        respHeight={0}
                        currentUser={currentUser}
                        issue={currentIssue}
                        currentOrganization={currentOrganization}
                        closeModal={closeModal}
                    />
                </Modal>
            )}

            <Wrap>
                <SectionTitleWrap>
                    <h2>Issues</h2>
                    <span>{`(${issues.length})`}</span>
                </SectionTitleWrap>

                {issues.map(issue => {
                    return (
                        /*  
                            TODO - Redefine Props types, 
                            because some of the props are not necessary in this context
                        */
                        <IssueRow
                            key={issue.key}
                            context={uc.ISSUE_ROW_CONTEXT_HOUSEKEEPING_MODAL}
                            issue={issue}
                            checked={false}
                            currentUser={currentUser}
                            onPressIssueRow={openModal}
                            addCheckedIssue={() => null}
                            removeCheckedIssue={() => null}
                            onPressHashTag={() => null}
                            onSearchPress={() => null}
                        />
                    )
                })}
            </Wrap>
        </>
    )
}

export default IssuesSection
