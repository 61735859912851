import React, { useContext } from 'react'
import { AuthContext } from '../../../../../app/modules/Auth/AuthContext'
import { useRecoilValue } from 'recoil'
import { asideMenuIsOpen } from '../state'
import { IntegrationLink, IntegrationContainer, IntegrationLogo, IntegrationAction } from './style'

const ApaleoIcon = require('@shared/img/integration/apaleo-logo.svg')
const ActionIcon = require('@shared/img/integration/go-to-link.svg')

const resolveIntegrationData = (integrationName: string) => {
    const integrationObject = {
        name: integrationName,
        icon: '',
        url: ''
    }
    switch (integrationName) {
        case 'apaleo':
            integrationObject.url = 'https://app.apaleo.com/apps/UBLQ-AC-SWEEPLY-ACCOUNTMENUAPPS'
            integrationObject.icon = ApaleoIcon
            break
    }
    return integrationObject
}

export const Integration = () => {
    const { organization } = useContext(AuthContext)
    if (!organization) return null

    const asideIsOpen = useRecoilValue(asideMenuIsOpen)
    const integrationData = resolveIntegrationData(organization.pms)

    return (
        asideIsOpen &&
        integrationData.icon && (
            <IntegrationLink>
                <IntegrationContainer onClick={() => window.open(integrationData.url, '_blank')}>
                    <IntegrationLogo alt="integration-logo" src={integrationData.icon} />
                    <IntegrationAction alt="action" src={ActionIcon} />
                </IntegrationContainer>
            </IntegrationLink>
        )
    )
}
