import React, { useEffect, useState, useContext } from 'react'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import CodeVerification from '../CodeVerification/index'
import SignUpLayout from '../SignUpLayout'
import { signUpDataAtom } from '../state'
import { AuthContext } from '../../Auth/AuthContext'
import withErrorCatching from '../../../utils/hocs/withErrorCatching'
import { currentIntegrationKey } from '../signup-worflow-templates'
import { getWorkflowInstance, storeWorkflowInstance } from '../../../utils/signup-flow-template/signup-workflow-instance'
import { signupOrganizationCall } from '../cloud-functions'
import { SIGN_UP } from '../../../navigation/url-constants'
import LoadingScreen from '../LoadingScreen'
import { errorModalConfigs } from '../helpers'
import { PMS_NAMES } from '../../../utils/constants'
import firebase from '../../../utils/firebase'

const StartScreen = ({ catchError }) => {
    const history = useHistory()
    const [codeApproved, setCodeApproved] = useState(false)
    const signUpData = useRecoilValue(signUpDataAtom)
    const { setStopAuthListener } = useContext(AuthContext)
    const [complete, setComplete] = useState(false)

    const workflowInstance = getWorkflowInstance(currentIntegrationKey)
    const pmsBrand = typeof workflowInstance === 'string' ? 'sweeply' : workflowInstance.getInput('brand') || 'sweeply'
    const sourcePMS = typeof workflowInstance === 'string' ? '' : workflowInstance.getInput('bus') || ''
    const signupOrganization = signupOrganizationCall()

    const redirectMessage =
        sourcePMS === PMS_NAMES.APALEO ? `We will now redirect you to ${sourcePMS} to authorize your account` : `Almost done...`
    const message = complete ? redirectMessage : 'We are setting up your account'

    const startSignUp = async () => {
        try {
            const { orgName, ownerName, ownerEmail } = signUpData

            const orgTimezone = workflowInstance.getInput('timezone') ?? moment.tz.guess()
            const orgCurrencyCode = orgTimezone === 'Atlantic/Reykjavik' ? 'ISK' : 'EUR'
            const pms = sourcePMS.toLowerCase()

            const {
                data: { newUserKey, newOrgKey }
            } = await signupOrganization({
                userName: ownerName,
                userEmail: ownerEmail,
                ping: false,
                brand: pmsBrand,
                orgName,
                orgCurrencyCode,
                pms: '',
                orgTimezone
            })
            // Update current user claims immediately
            await firebase.auth().currentUser.getIdToken(true)

            workflowInstance.completeStep(SIGN_UP.ORG, { userKey: newUserKey, orgKey: newOrgKey })
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setStopAuthListener(false)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    async function handleCodeApproved() {
        if (codeApproved) {
            await startSignUp()
        }
    }

    useEffect(() => {
        handleCodeApproved()
    }, [codeApproved])

    useEffect(() => {
        if (complete) {
            const nextStep = workflowInstance.nextStep()

            setTimeout(() => {
                history.push(nextStep)
            }, [2000])
        }
    }, [complete])

    return (
        <SignUpLayout>
            {codeApproved ? <LoadingScreen message={message} /> : <CodeVerification setCodeApproved={setCodeApproved} />}
        </SignUpLayout>
    )
}

export default withErrorCatching(StartScreen)
