import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { startWorkflow } from '../../helpers'
import beds24Logo from '../../../../img/pms/beds24/beds24-logo.svg'
import { BEDS_24_V2_WORKFLOW } from '../../signup-worflow-templates'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { PMS_NAMES } from '../../../../utils/constants'

const Beds24V2Start = () => {
    const history = useHistory()

    useEffect(() => {
        startWorkflow({
            bus: PMS_NAMES.BEDS_24,
            imageUrl: beds24Logo,
            workflowTemplate: BEDS_24_V2_WORKFLOW,
            currentStep: SIGN_UP.BEDS_24_V2_START,
            history
        })
    }, [])

    return <></>
}

export default Beds24V2Start
