import React from 'react'

export function ModalHeader({ children, onHide, title, showCloseButton = true }) {
    return (
        <div className="modal-header border-bottom-0">
            <h1 className="modal-title">
                {title} {children}
            </h1>
            {showCloseButton && (
                <button type="button" className="close" onClick={onHide}>
                    <span aria-hidden="true">
                        <i className="specta-close icon-md"></i>
                    </span>
                </button>
            )}
        </div>
    )
}
