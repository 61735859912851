import styled from 'styled-components'
import * as colors from '@shared/colors'
import { MOBILE_BREAKPOINT, MEDIUM_DESKTOP_SCREEN_HEIGHT } from '../../../../utils/constants'

const black = colors.black_sweeply

export const FormBox = styled.div`
    height: max-content;
    padding: 80px;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        background: linear-gradient(134.14deg, rgba(255, 220, 0, 0.25) 18.05%, rgba(255, 52, 66, 0.25) 85.85%);
        opacity: 0.25;
        width: 100%;
        height: 100%;
        border-radius: 24px;
    }

    h1 {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 2.462rem;
        line-height: 48px;
        color: ${black};
        opacity: 1;
        margin-bottom: 40px;
    }

    form {
        display: flex;
        flex-direction: column;
    }

    @media (max-height: ${MEDIUM_DESKTOP_SCREEN_HEIGHT}px) {
        padding: 40px;

        h1 {
            margin-bottom: 20px;
        }

        button {
            margin-top: 40px;
            margin-bottom: 30px;
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: ${({ formIsOpen }) => (formIsOpen ? 'block' : 'none')};
        position: relative;
        top: 32.54px;
        left: 0;
        width: 91vw;
        padding: 24px 27px 64px 21px;

        &::before {
            border-radius: 8px;
        }

        h1 {
            font-size: 1.667rem;
            line-height: 32px;
            margin-bottom: 24px;
        }

        button {
            margin-top: 40px !important;
            width: 230px !important;
            height: 48px !important;
            font-size: 1.333rem !important;
            line-height: 24px !important;
            margin-bottom: 0 !important;
        }

        label,
        span,
        input,
        input::placeholder,
        div {
            font-size: 1rem;
            line-height: 15px;
        }
    }
`
