export class Feature<T = never> {
    readonly title: string
    readonly path?: string
    readonly description: string
    readonly group: string
    readonly display: boolean
    readonly marketingTitle?: string
    readonly permissions?: { id: T; description: string }[]

    constructor({
        title,
        path,
        description,
        group,
        display,
        marketingTitle,
        permissions
    }: {
        title: string
        path?: string
        description: string
        group: string
        display: boolean
        marketingTitle?: string
        permissions?: { id: T; description: string }[]
    }) {
        this.title = title
        this.path = path
        this.description = description
        this.group = group
        this.display = display
        this.marketingTitle = marketingTitle
        this.permissions = permissions
    }
}
