import * as c from '@shared/constants'
import * as areaData from '@shared/area-data'
import firebase, { asFirebase } from '../../utils/firebase'
import * as taskData from '@shared/task-data'
import { AreaStruct, TaskStruct, UserStruct } from '@shared/firestore-structs'

export const onCleaningPriorityPress = async (
    area: AreaStruct,
    priority: boolean,
    task: TaskStruct,
    currentUser: UserStruct,
    selectedDateNumber: number
) => {
    let nextPriority = null

    nextPriority = priority !== true

    const changeObj = {
        field: c.ACTIVITY_TYPE_CLEANING_PRIORITY,
        before: priority,
        after: nextPriority
    }

    await areaData.logActivity(
        asFirebase(firebase),
        currentUser,
        area.key,
        c.ACTIVITY_TYPE_CLEANING_PRIORITY,
        selectedDateNumber,
        changeObj
    )

    if (task && task.key) {
        await taskData.setTaskPriority(asFirebase(firebase), currentUser, task.key, { priority: nextPriority })
    }
}
