import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { bs_gray_300, bs_gray_500, bs_success } from '@shared/colors'

const CreatableDropdown = ({ label, required, placeholder, value, options, onChange, onCreateOption, error, ...props }) => {
    const requiredMarkup = required && <span className="text-danger">*</span>
    const errorMarkup = error && <span className="text-danger">{error}</span>

    const styles = {
        control: (provided, state) => {
            const borderColor = state.isFocused ? bs_success : bs_gray_300

            return {
                ...provided,
                cursor: 'pointer',
                boxShadow: 'none',
                borderColor,

                '&:hover': {
                    borderColor: bs_success
                }
            }
        },

        placeholder: provided => ({
            ...provided,
            color: bs_gray_500
        })
    }

    return (
        <div>
            <label>
                {label}:{requiredMarkup}
            </label>
            <CreatableSelect
                placeholder={placeholder}
                value={value}
                options={options}
                onChange={onChange}
                onCreateOption={onCreateOption}
                styles={styles}
                {...props}
            />
            {errorMarkup}
        </div>
    )
}

export default CreatableDropdown
