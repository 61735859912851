import React, { Component } from 'react'
import { View, TouchableOpacity, Image } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'
import { notify } from 'react-notify-toast'
import { Toast } from '../utils/toast'
import * as helpers from '@shared/helpers'
import * as c from '@shared/constants'
import Hoverable from '../utils/hoverable/hoverable'
import { Initials } from '../components/initials'
import moment from 'moment-timezone'
import * as uc from '../utils/constants'

import { HoverableImageButton } from '../components/buttons/hoverable-image-button'
import { Tooltip } from 'react-tippy'
import * as thelpers from '../components/tooltips/tooltip-helpers'
import { Workload } from '../components/workload'

import { fadeIn } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import withAsideMenuToggleFlag from '../utils/hocs/withAsideMenuToggleFlag.js'
import MainActionButton from '../components/buttons/MainActionButton'
import { Checkbox } from '../components/forms/Checkbox'
import { isFeatureOn } from '@shared/feature-toggles'

const animationStyles = {
    actionBarFadeIn: {
        animation: 'x 0.75s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn')
    },
    workloadFadeIn: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn')
    }
}
class AssignActionBar extends Component {
    constructor(props) {
        super(props)

        this.actionBarRef = React.createRef()

        this.state = {
            assignModeFilter: this.props.assignModeFilter,
            cancelHoverColor: colors.button_gray,
            clearHoverColor: colors.button_gray,
            selectAllHoverColor: colors.gentle_gray_spectaflow,
            unassignHoverColor: colors.button_gray,
            assignHoverColor: colors.button_gray,
            showAllHoverColor: colors.gentle_gray_spectaflow,
            sameAsLastHoverColor: colors.gentle_gray_spectaflow,
            showWorkLoad: true,
            actionBarHeight: 0,
            showWorkloadByGroups: false,
            workloadCollapsedGroups: []
        }
        this.renderAsssignModeFilterButton = this.renderAsssignModeFilterButton.bind(this)
        this.isAreaSelected = this.isAreaSelected.bind(this)
        this.isPastDay = this.isPastDay.bind(this)
        this.handleResize = this.handleResize.bind(this)
    }

    debounce(func) {
        var timer
        return function (event) {
            if (timer) clearTimeout(timer)
            timer = setTimeout(func, 100, event)
        }
    }

    componentDidMount() {
        this.actionBarHeight = this.actionBarRef.current.clientHeight
        this.setState({ actionBarHeight: this.actionBarHeight })
        const that = this
        window.addEventListener(
            'resize',
            this.debounce(function () {
                that.handleResize()
            })
        )
    }

    handleResize() {
        if (this.actionBarRef.current) {
            this.actionBarHeight = this.actionBarRef.current.clientHeight
            this.setState({ actionBarHeight: this.actionBarHeight })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    createWorkloadByGroups(areas, selectedDateNumber) {
        const workload = []
        const unitGroups = new Set()
        areas.forEach(area => {
            if (area.task) {
                unitGroups.add(area.group)
            }
        })

        unitGroups.forEach(group => {
            areas
                .filter(area => area.task)
                .filter(area => area.group === group)
                .forEach(area => {
                    const task = area.task
                    const startDate = moment(task.startDate).startOf('day')

                    if (selectedDateNumber === startDate.valueOf()) {
                        if (!task.initials) {
                            return
                        }

                        if (!workload[group]) {
                            workload[group] = []
                            workload[group].count = 0
                        }
                        if (!workload[group][task.initials]) {
                            workload[group][task.initials] = {
                                assignedTo: task.assignedTo,
                                initials: task.initials,
                                unclean100Count: 0,
                                unclean80Count: 0,
                                unclean50Count: 0,
                                noServiceCount: 0,
                                otherWorkCount: 0
                            }
                        }

                        if (area.occupancy === c.OCCUPANCY_CHECKOUT || area.occupancy === c.OCCUPANCY_TURNOVER) {
                            workload[group][task.initials].unclean100Count++
                            workload[group].count++
                        } else if (area.occupancy === c.OCCUPANCY_STAYOVER_80 && area.cleaningStatus !== c.CLEANING_STATUS_NO_SERVICE) {
                            workload[group][task.initials].unclean80Count++
                            workload[group].count++
                        } else if (area.occupancy === c.OCCUPANCY_STAYOVER && area.cleaningStatus !== c.CLEANING_STATUS_NO_SERVICE) {
                            workload[group][task.initials].unclean50Count++
                            workload[group].count++
                        } else if (area.cleaningStatus === c.CLEANING_STATUS_NO_SERVICE) {
                            workload[group][task.initials].noServiceCount++
                            workload[group].count++
                        } else {
                            workload[group][task.initials].otherWorkCount++
                            workload[group].count++
                        }
                    }
                })
        })

        return workload
    }

    createWorkload(areas, selectedDateNumber) {
        const workload = []
        workload.count = 0

        areas.forEach(area => {
            if (area.task) {
                if (
                    isFeatureOn(this.props.currentOrganization, 'display-projection') ||
                    isFeatureOn(this.props.currentUser, 'display-projection')
                ) {
                    if (area.currentTask && area.currentTask.assignedTo) {
                        area.task = area.currentTask
                        area.task.initials = area.currentTask.assignedTo.map(x => x.initials)
                    }
                }
                const task = area.task

                const startDate = moment(task.startDate).startOf('day')
                console.log('FUUU', selectedDateNumber, startDate.valueOf())

                if (moment(selectedDateNumber).isSame(startDate, 'day')) {
                    if (!task.initials) {
                        return
                    }
                    if (!workload[task.initials]) {
                        workload[task.initials] = {
                            assignedTo: task.assignedTo,
                            initials: task.initials,
                            unclean100Count: 0,
                            unclean80Count: 0,
                            unclean50Count: 0,
                            noServiceCount: 0,
                            otherWorkCount: 0
                        }
                    }

                    if (area.occupancy === c.OCCUPANCY_CHECKOUT || area.occupancy === c.OCCUPANCY_TURNOVER) {
                        workload[task.initials].unclean100Count++
                        workload.count++
                    } else if (area.occupancy === c.OCCUPANCY_STAYOVER_80 && area.cleaningStatus !== c.CLEANING_STATUS_NO_SERVICE) {
                        workload[task.initials].unclean80Count++
                        workload.count++
                    } else if (area.occupancy === c.OCCUPANCY_STAYOVER && area.cleaningStatus !== c.CLEANING_STATUS_NO_SERVICE) {
                        workload[task.initials].unclean50Count++
                        workload.count++
                    } else if (area.cleaningStatus === c.CLEANING_STATUS_NO_SERVICE) {
                        workload[task.initials].noServiceCount++
                        workload.count++
                    } else {
                        workload[task.initials].otherWorkCount++
                        workload.count++
                    }
                }
            }
        })

        console.log(workload)

        return workload
    }

    renderTeamWorkload(props) {
        if (props.showActivityIndicator) {
            return null
        }
        const workload = this.createWorkload(props.allAreas, props.selectedDateNumber)
        const groupedWorkload = this.createWorkloadByGroups(props.allAreas, props.selectedDateNumber)
        const groupedWorkloadKeys = Object.keys(groupedWorkload)
        const workloadCount = workload.count
        delete workload.count

        const workloadKeys = Object.keys(workload)

        workloadKeys.sort(helpers.sortByName)
        if (workloadKeys.length === 0) {
            return null
        }

        const DEBUG_BORDER = 0

        const arrowImg = this.state.showWorkLoad ? require('../img/arrow-down-black.svg') : require('../img/arrow-up-black.svg')
        return (
            <View
                style={{
                    minWidth: 320,
                    position: 'absolute',
                    bottom: this.state.actionBarHeight,
                    borderColor: colors.gentle_gray_spectaflow,
                    borderTopLeftRadius: 4,
                    borderTopWidth: 2,
                    borderLeftWidth: 2,
                    opacity: 0.93,
                    right: 0,
                    backgroundColor: iOSColors.white,
                    overflowY: 'scroll',
                    maxHeight: '75vh'
                }}>
                <View
                    style={{
                        marginTop: 24,
                        marginHorizontal: 14,
                        marginBottom: 6,
                        justifyContent: 'center'
                    }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            marginBottom: 3,
                            borderWidth: DEBUG_BORDER,
                            borderColor: 'red',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                        <div className="flex-row align-items-baseline mr-2">
                            <span style={{ fontSize: 19, fontWeight: '500' }}>Team workload</span>

                            <span className="ml-1" style={{ fontSize: 15, fontWeight: '400' }}>
                                ({workloadCount})
                            </span>
                        </div>

                        <TouchableOpacity
                            onPress={() => {
                                const newState = !this.state.showWorkLoad
                                this.setState({ showWorkLoad: newState })
                            }}
                            style={{
                                marginRight: 5,
                                marginTop: 2,
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <Image
                                style={{
                                    opacity: 1,
                                    height: 9,
                                    width: 15
                                }}
                                source={arrowImg}
                            />
                        </TouchableOpacity>
                    </View>
                    {this.state.showWorkLoad && (
                        <View style={{ borderWidth: DEBUG_BORDER, borderColor: 'blue', paddingBottom: 10 }}>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    fontSize: 12,
                                    fontWeight: '500',
                                    marginBottom: 10,
                                    transform: `translateX(-13px) scale(0.9) `
                                }}>
                                <Checkbox
                                    isSelected={this.state.showWorkloadByGroups}
                                    onChange={() => this.setState(prevState => ({ showWorkloadByGroups: !prevState.showWorkloadByGroups }))}
                                    disabled={false}
                                />

                                <span>Show groups</span>
                            </View>
                            {this.state.showWorkloadByGroups
                                ? groupedWorkloadKeys.map(groupKey => {
                                      const groupCount = groupedWorkload[groupKey].count
                                      delete groupedWorkload[groupKey].count

                                      const groupWorkloadKeys = Object.keys(groupedWorkload[groupKey])
                                      groupWorkloadKeys.sort(helpers.sortByName)
                                      const arrowIcon = !this.state.workloadCollapsedGroups.includes(groupKey)
                                          ? require('../img/arrow-down-black.svg')
                                          : require('../img/arrow-up-black.svg')
                                      return (
                                          <View key={groupKey}>
                                              <View
                                                  style={{
                                                      flexDirection: 'row',
                                                      marginBottom: 10,
                                                      borderWidth: DEBUG_BORDER,
                                                      borderColor: 'red',
                                                      justifyContent: 'space-between'
                                                  }}>
                                                  <div className="flex-row align-items-baseline">
                                                      <span style={{ fontSize: 16, fontWeight: '500' }}>{groupKey}</span>

                                                      <span className="ml-1" style={{ fontSize: 15, fontWeight: '400' }}>
                                                          {' '}
                                                          ({groupCount})
                                                      </span>
                                                  </div>

                                                  <TouchableOpacity
                                                      onPress={() => {
                                                          if (this.state.workloadCollapsedGroups.includes(groupKey)) {
                                                              this.setState(prevState => ({
                                                                  workloadCollapsedGroups: prevState.workloadCollapsedGroups.filter(
                                                                      group => !group === groupKey
                                                                  )
                                                              }))
                                                          } else {
                                                              this.setState(prevState => ({
                                                                  workloadCollapsedGroups: [...prevState.workloadCollapsedGroups, groupKey]
                                                              }))
                                                          }
                                                      }}
                                                      style={{
                                                          marginRight: 5,
                                                          marginTop: 2,
                                                          justifyContent: 'center',
                                                          alignItems: 'center'
                                                      }}>
                                                      <Image
                                                          style={{
                                                              opacity: 1,
                                                              height: 9,
                                                              width: 15
                                                          }}
                                                          source={arrowIcon}
                                                      />
                                                  </TouchableOpacity>
                                              </View>
                                              {!this.state.workloadCollapsedGroups.includes(groupKey) &&
                                                  groupWorkloadKeys.map((key, index) => {
                                                      const item = groupedWorkload[groupKey][key]
                                                      const isLast = groupWorkloadKeys.length - 1 === index
                                                      const keyWithGroup = key + groupKey
                                                      return this.renderWorkloadLine(keyWithGroup, item, props, isLast)
                                                  })}
                                          </View>
                                      )
                                  })
                                : workloadKeys.map((key, index) => {
                                      const item = workload[key]
                                      const isLast = workloadKeys.length - 1 === index
                                      return this.renderWorkloadLine(key, item, props, isLast)
                                  })}
                        </View>
                    )}
                </View>
            </View>
        )
    }

    renderWorkloadLine(key, item, props, isLast) {
        const totalCount = item.unclean100Count + item.unclean80Count + item.unclean50Count + item.noServiceCount + item.otherWorkCount
        const KEY = key
        const isHoverAction = this.state.activeWorklineKey === KEY
        return (
            <Hoverable
                key={KEY}
                onHoverIn={() => {
                    this.setState({ activeWorklineKey: KEY })
                }}
                onHoverOut={() => {
                    this.setState({ activeWorklineKey: null })
                }}>
                <TouchableOpacity
                    onPress={() => {
                        if (!this.isAreaSelected()) {
                            return
                        }
                        if (this.isPastDay()) {
                            return
                        }
                        this.props.onAssignPress(item.assignedTo)
                    }}
                    style={{
                        marginLeft: 10,
                        height: 45,
                        flexDirection: 'row',
                        borderBottomWidth: isLast ? 0 : 1,
                        borderBottomColor: colors.gentle_gray_spectaflow
                    }}>
                    <View
                        style={{
                            opacity: isHoverAction ? uc.HOVER_OPACITY : 1,
                            flex: 1,
                            height: 40,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                        {this.renderInitials(props, item, totalCount)}
                        <Tooltip
                            distance={0}
                            offset={-20}
                            html={thelpers.getDefaultTooltip(['Press to assign'])}
                            theme="transparent"
                            delay={thelpers.TOOLTIP_DELAY}
                            position="top"
                            trigger="mouseenter"
                            interactiveBorder={0}>
                            {this.renderWorkloadIcons(
                                item.unclean100Count,
                                item.unclean80Count,
                                item.unclean50Count,
                                item.otherWorkCount,
                                item.noServiceCount
                            )}
                        </Tooltip>
                    </View>
                </TouchableOpacity>
            </Hoverable>
        )
    }

    renderInitials(props, item, totalCount) {
        const assignedTo = item.assignedTo

        const DEBUG_BORDER = 0
        return (
            <View
                style={{
                    flex: 1,
                    borderWidth: DEBUG_BORDER,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        borderWidth: DEBUG_BORDER,

                        justifyContent: 'flex-end',
                        marginRight: 0
                    }}>
                    {assignedTo &&
                        assignedTo.map((item, index) => {
                            const noMargin = assignedTo.length === index + 1
                            return (
                                <Initials
                                    key={item.initials + Math.random()}
                                    disabled={true}
                                    item={item}
                                    currentUser={props.currentUser}
                                    dimension={28}
                                    fontSize={12}
                                    noMargin={noMargin}
                                />
                            )
                        })}
                </View>
                <View
                    style={{
                        // flex: 1,
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'red',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        width: 40
                    }}>
                    <span
                        style={{
                            borderWidth: DEBUG_BORDER,
                            fontSize: 14,
                            fontWeight: '500'
                        }}>
                        ({totalCount})
                    </span>
                </View>
            </View>
        )
    }

    renderWorkloadIcons(unclean100Count, unclean80Count, unclean50Count, otherWorkCount, noServiceCount) {
        const workloadCounts = {
            otherWorkCount: otherWorkCount,
            unclean50Count: unclean50Count,
            unclean80Count: unclean80Count,
            unclean100Count: unclean100Count,
            noServiceCount: noServiceCount
        }

        const ITEM_WIDTH = 37.5

        const DEBUG_BORDER = 0
        return (
            <View
                style={{
                    // marginLeft: 100,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderColor: 'red',
                    borderWidth: DEBUG_BORDER
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: 180,
                        borderColor: 'green',
                        borderWidth: DEBUG_BORDER
                    }}>
                    <Workload
                        itemWidth={ITEM_WIDTH}
                        renderEmptySlots={true}
                        fontSize={14}
                        fontWeight={'500'}
                        color={iOSColors.black}
                        opacity={0.8}
                        counts={workloadCounts}
                        tooltipDisabled={true}
                    />
                </View>
            </View>
        )
    }

    isAreaSelected() {
        if (this.props.selectedAreas.length < 1) {
            notify.show(
                <Toast width={180} backgroundColor={colors.yello_spectaflow} message={this.props.toastMessage} />,
                'custom',
                3000,
                {}
            )
            return false
        } else {
            return true
        }
    }

    isPastDay() {
        let today = moment().startOf('day')

        if (this.props.selectedDateNumber < today.valueOf()) {
            notify.show(
                <Toast width={180} backgroundColor={colors.yello_spectaflow} message={'You can not assign tasks to past dates'} />,
                'custom',
                3000,
                {}
            )
            return true
        } else {
            return false
        }
    }

    getButtonClasses(buttons_active, type) {
        let classes = 'btn btn-lg no-disabled '
        classes += buttons_active ? 'btn-' : 'btn-outline-'
        classes += type === 'unassign' ? 'danger' : 'primary'
        return classes
    }

    render() {
        const { asideIsOpen, isMobile } = this.props
        const DEFAULT_WIDTH = 130
        const BUTTON_BORDER_RADIUS = 20

        const SMALL_BUTTON_DIM = 24
        const SMALL_BUTTON_LEFT_PADDING = 6
        const buttons_active = this.props.selectedAreas.length > 0
        const DEBUG_BORDER = 0

        const asideMargin = uc.INSIDE_IFRAME || isMobile ? '0px' : asideIsOpen ? '248px' : '80px'
        return (
            <StyleRoot>
                <div style={animationStyles.actionBarFadeIn}>
                    <div
                        ref={this.actionBarRef}
                        className="fixed-bottom p-5"
                        style={{
                            // width: '100%',
                            backgroundColor: colors.gentle_gray_spectaflow,
                            left: asideMargin
                        }}>
                        {this.props.allAreas && this.props.allAreas.length > 0 && this.renderTeamWorkload(this.props)}

                        <div className="row">
                            <div className="col-6 col-md-2 order-1">
                                <div className="row">
                                    <div className="col-12 text-center my-2">
                                        {/*<button type="button" className="btn btn-lg btn-secondary" onClick={this.props.closeAssignMode}>*/}
                                        {/*    Close*/}
                                        {/*</button>*/}
                                        <div className="d-flex justify-content-center">
                                            <MainActionButton type="secondary" onClick={this.props.closeAssignMode}>
                                                Close
                                            </MainActionButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-2 order-3 order-md-2">
                                <div className="row my-2 justify-content-center">
                                    <div>
                                        <div className="row mb-5">
                                            {this.renderAsssignModeFilterButton(
                                                DEBUG_BORDER,
                                                this.state.assignModeFilter,
                                                DEFAULT_WIDTH,
                                                SMALL_BUTTON_DIM,
                                                SMALL_BUTTON_LEFT_PADDING,
                                                BUTTON_BORDER_RADIUS
                                            )}
                                        </div>
                                        <div
                                            className="row"
                                            style={{
                                                color: iOSColors.gray,
                                                fontSize: 14,
                                                fontWeight: '400'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-2 order-4 order-md-3">
                                <div className="row my-2 justify-content-center">
                                    <div>
                                        <div
                                            onClick={this.props.selectAllAreas}
                                            className="row mb-5"
                                            style={{ justifyContent: 'flex-start' }}>
                                            <button className="btn btn-xs btn-circle btn-icon btn-white">
                                                <i className="specta-check-all" />
                                            </button>
                                            <span style={{ cursor: 'pointer' }} className="ml-3 text-dark">
                                                Select all
                                            </span>
                                        </div>
                                        <div
                                            onClick={this.props.clearSelectedAreas}
                                            className="row"
                                            style={{ justifyContent: 'flex-start' }}>
                                            <button className="btn btn-xs btn-circle btn-icon btn-white">
                                                <i className="specta-uncheck-all" />
                                            </button>
                                            <span style={{ cursor: 'pointer' }} className="ml-3 text-dark">
                                                Clear all
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-2 order-2 order-md-4">
                                <div className="row">
                                    <div className="col-12 my-2">
                                        <div className="form-group mb-0">
                                            <input
                                                type="name"
                                                className="form-control"
                                                placeholder="Cleaning task name"
                                                value={this.props.inputCleaningTaskName}
                                                onChange={e => {
                                                    this.props.setInputCleaningTaskName(e.target.value)
                                                    this.props.setCleaningTaskName(e.currentTarget.value)
                                                }}
                                            />
                                            <span className="form-text text-dark-50 float-left ml-2 mt-2">
                                                If left empty the name of a corresponding rule will be used
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-2 order-5">
                                <div className="row">
                                    <div className="col-12 text-center my-2">
                                        <div className="d-flex justify-content-center">
                                            <MainActionButton
                                                disabled={!buttons_active}
                                                type="danger"
                                                onClick={() => {
                                                    if (!this.isAreaSelected()) {
                                                        return
                                                    }
                                                    this.props.onUnassignPress()
                                                }}>
                                                Unassign
                                            </MainActionButton>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <span className="btn m-0 p-0 mt-2">
                                                    Selected: <b>{this.props.selectedAreas.length}</b>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-2 order-6">
                                <div className="row">
                                    <div className="text-center my-2">
                                        {/*<button*/}
                                        {/*    type="button"*/}
                                        {/*    className={this.getButtonClasses(buttons_active, 'assign')}*/}
                                        {/*    disabled={!buttons_active}*/}
                                        {/*    onClick={() => {*/}
                                        {/*        if (!this.isAreaSelected()) {*/}
                                        {/*            return*/}
                                        {/*        }*/}
                                        {/*        if (this.isPastDay()) {*/}
                                        {/*            return*/}
                                        {/*        }*/}

                                        {/*        this.props.openSelectUserModal()*/}
                                        {/*    }}>*/}
                                        {/*    Assign*/}
                                        {/*</button>*/}
                                        <div className="d-flex justify-content-center">
                                            <MainActionButton
                                                disabled={!buttons_active}
                                                type="primary"
                                                onClick={() => {
                                                    if (!this.isAreaSelected()) {
                                                        return
                                                    }
                                                    if (this.isPastDay()) {
                                                        return
                                                    }

                                                    this.props.openSelectUserModal()
                                                }}>
                                                Assign
                                            </MainActionButton>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <button
                                                    className="btn m-0 p-0 mt-2 text-primary"
                                                    onClick={() => {
                                                        if (!this.isAreaSelected()) {
                                                            return
                                                        }
                                                        if (this.isPastDay()) {
                                                            return
                                                        }
                                                        this.props.onAssignLastPress()
                                                    }}>
                                                    <u>Same as last</u>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StyleRoot>
        )
    }

    renderAsssignModeFilterButton(DEBUG_BORDER, assignModeFilter, DEFAULT_WIDTH, SMALL_BUTTON_DIM) {
        let source
        let text
        if (assignModeFilter === c.ASSIGN_MODE_FILTER_UN_ASSIGNED) {
            source = require('@shared/img/assigned-to-gray-wbkgr.svg')
            text = 'Unassigned'
        } else if (assignModeFilter === c.ASSIGN_MODE_FILTER_ASSIGNED) {
            source = require('@shared/img/assigned-to-yellow-wbkgr.svg')
            text = 'Assigned'
        } else if (assignModeFilter === c.ASSIGN_MODE_FILTER_ALL) {
            source = require('../img/assigned-to-all-wbkgr.svg')
            text = 'Show all'
        }

        return (
            <HoverableImageButton
                justifyContent={'center'}
                viewWidth={'100%'}
                dimension={SMALL_BUTTON_DIM}
                hoverOpacity={uc.HOVER_OPACITY}
                imageSource={source}
                text={text}
                fontSize={13}
                onPress={() => {
                    let assignModeFilter = this.state.assignModeFilter
                    let nextAssignMode
                    if (assignModeFilter === c.ASSIGN_MODE_FILTER_ALL) {
                        nextAssignMode = c.ASSIGN_MODE_FILTER_UN_ASSIGNED
                    } else if (assignModeFilter === c.ASSIGN_MODE_FILTER_UN_ASSIGNED) {
                        nextAssignMode = c.ASSIGN_MODE_FILTER_ASSIGNED
                    } else if (assignModeFilter === c.ASSIGN_MODE_FILTER_ASSIGNED) {
                        nextAssignMode = c.ASSIGN_MODE_FILTER_ALL
                    }
                    this.setState({ assignModeFilter: nextAssignMode })
                    this.props.setAssignModeFilter(nextAssignMode)
                }}
            />
        )
    }
}

export default withAsideMenuToggleFlag(AssignActionBar)
