import React from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native-web'
import Iframe from 'react-iframe'
import * as colors from '@shared/colors'
import * as brand from '@shared/brand'

export const Support = props => {
    const appStoreScale = 0.8

    document.getElementById('root').style.overflow = 'hidden'

    const height = window.innerWidth > 450 ? '4000px' : '9500px'

    const guide = brand.getBrand().urls.guide

    if (window.innerWidth < 450) {
        return (
            <View style={{ height, marginTop: 0, paddingBottom: 60, overflow: 'auto', WebkitOverflowScrolling: 'touch' }}>
                <embed width="100%" height="100%" src={guide} />
            </View>
        )
    }
    return (
        <View style={{ height: '100%', marginTop: 0, paddingBottom: 60, overflow: 'hidden' }}>
            <embed width="100%" height="100%" src={guide} />
        </View>
    )
}
