import React, { useContext, useEffect, useState } from 'react'
import { View } from 'react-native'

import { iOSColors } from '@shared/react-native-typography'
import moment from 'moment-timezone'
import * as colors from '@shared/colors'
import * as c from '@shared/constants'
import { HoverableImageButton } from '../components/buttons/hoverable-image-button'
import * as uc from '../utils/constants'

import { fadeIn } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import Empty from './info-summary-modal/empty'
import Buttons from './info-summary-modal/buttons'
import { infoTypes } from './info-summary-modal/helpers'
import { yello_spectaflow } from '@shared/colors'
import { markdownToHtml } from '@shared/markdown-helper'
import { sortAreas } from '@shared/helpers'

const animationStyles = {
    actionBarFadeIn: {
        animation: 'x 0.75s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn')
    }
}

function DailyCommentsSummaryModal(props) {
    const [infoType, setInfoType] = useState('dailyComments')
    const [data, setData] = useState([])

    useEffect(() => {
        if (props.areas) {
            const summaryData = infoTypes[infoType].extractData(sortAreas(props.areas))
            setData(summaryData)
        }

        return () => {}
    }, [props.areas, infoType])

    const BORDER_COLOR = iOSColors.customGray

    const DATE_STYLE = { color: yello_spectaflow, fontSize: 13 }
    const DEBUG_BORDER = 0

    const counts = {}
    Object.keys(infoTypes).forEach(type => {
        counts[type] = infoTypes[type].extractData(props.areas).length
    })

    if (counts.extraServices === 0) delete infoTypes.extraServices

    const renderList = data => {
        return data.map((item, index) => renderItem(item, index))
    }

    const renderItem = (item, index) => {
        const area = item.area
        const comment = item.comment

        const TITLE_FONT_SIZE = 17

        const unitNotesCondition = () => {
            if (infoType === 'unitNotes') {
                return <span dangerouslySetInnerHTML={{ __html: markdownToHtml(comment) }} />
            }
        }
        const commentCondition = () => {
            if (infoType !== 'unitNotes') {
                return <span>{comment}</span>
            }
        }

        const BACKGROUND_COLOR = index % 2 === 0 ? colors.gentle_gray_spectaflow : iOSColors.white
        return (
            <View
                style={{
                    flexDirection: 'column',
                    minHeight: 60,
                    backgroundColor: BACKGROUND_COLOR,
                    paddingHorizontal: 32,
                    paddingVertical: 16,
                    borderBottomColor: colors.gentle_gray_spectaflow,
                    borderBottomWidth: 1
                }}>
                <View style={{ flexDirection: 'column', justifyContent: 'center', borderWidth: 0 }}>
                    <span style={{ color: iOSColors.gray, fontSize: 12, marginBottom: 4 }}>{area.group}</span>
                    <span
                        style={{
                            fontWeight: '700',
                            color: iOSColors.black,
                            fontSize: TITLE_FONT_SIZE
                        }}>
                        {area.name + ' '}
                        <span
                            style={{
                                marginLeft: 6,
                                fontWeight: '400',
                                color: iOSColors.gray,
                                fontSize: TITLE_FONT_SIZE
                            }}>
                            {area.description}
                        </span>
                    </span>
                </View>
                <View style={{ marginTop: 6 }}>
                    <span
                        style={{
                            color: iOSColors.black,
                            fontSize: 16
                        }}>
                        {unitNotesCondition()}
                        {commentCondition()}
                    </span>
                </View>
            </View>
        )
    }

    const icon = infoTypes[infoType].icon
    const iconSizeList = infoTypes[infoType].iconSizeList
    const title = infoTypes[infoType].title
    const superTitle = moment(props.selectedDate || moment()).format(c.DATE_FORMAT_WITH_WEEK_DAY)

    const infoTypeClickHandler = type => {
        setInfoType(type)
    }

    return (
        <StyleRoot>
            <div style={animationStyles.actionBarFadeIn}>
                <View
                    style={{
                        height: '100%',
                        borderColor: 'green',
                        borderWidth: DEBUG_BORDER
                    }}>
                    <View
                        style={{
                            zIndex: 10000,
                            backgroundColor: colors.white,
                            position: 'sticky',
                            top: 0,
                            left: 0,
                            borderWidth: DEBUG_BORDER,

                            paddingVertical: uc.MODAL_TOP_TO_BUTTONS_BAR_VERTICAL_SPACE,
                            paddingHorizontal: uc.MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE
                        }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                borderWidth: DEBUG_BORDER,
                                borderColor: 'blue'
                            }}>
                            <Buttons infoType={infoType} onClick={infoTypeClickHandler} counts={counts} />

                            <HoverableImageButton
                                dimension={uc.SMALL_BUTTON_DIM}
                                hoverOpacity={uc.HOVER_OPACITY}
                                imageSource={require('@shared/img/close.svg')}
                                onPress={props.closeModal}
                            />
                        </View>
                    </View>
                    <View
                        style={{
                            borderBottomColor: BORDER_COLOR,
                            borderBottomWidth: 1,
                            justifyContent: 'flex-start',
                            paddingHorizontal: uc.MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE,
                            paddingBottom: uc.MODAL_SECTION_VERTICAL_SPACE
                        }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                marginBottom: 4,
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                borderWidth: DEBUG_BORDER
                            }}>
                            <span style={DATE_STYLE}>{superTitle}</span>
                        </View>

                        <View
                            style={{
                                borderWidth: DEBUG_BORDER,
                                alignItems: 'center',
                                flexDirection: 'row'
                            }}>
                            <View
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'row'
                                }}>
                                <i className={`${icon} ${iconSizeList || 'icon-lg'}`} />
                                <span
                                    style={{
                                        fontWeight: '500',

                                        marginLeft: 12,
                                        fontSize: 22,
                                        color: iOSColors.black
                                    }}>
                                    {title}
                                </span>
                                <span
                                    style={{
                                        marginLeft: 5,
                                        marginTop: 1,
                                        fontSize: 14,
                                        color: iOSColors.black
                                    }}>
                                    {/*({counts[infoType]})*/}
                                </span>
                            </View>
                        </View>
                    </View>
                    {data.length > 0 && renderList(data)}
                    {data.length === 0 && <Empty infoType={infoType} />}
                </View>
            </div>
        </StyleRoot>
    )
}

export default DailyCommentsSummaryModal
