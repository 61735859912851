import * as serviceWorkerRegistration from './serviceWorkerRegistration'

window.global ||= window
import './rum'
import './checksumai.js'
import React from 'react'
import App from './app/app'
import { createRoot } from 'react-dom/client'

import './index.scss' // Standard version
// import 'react-datepicker/dist/react-datepicker.css'
import './_metronic/_assets/plugins/flaticon/flaticon.css'
import './_metronic/_assets/plugins/flaticon2/flaticon.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './index.css'
import './assets/specta-icons/css/styles.min.css'
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css'
import { getCurrentEnv } from './app/navigation/url-constants'
// import './assets/specta-icons/scss/styles.scss'

createRoot(document.getElementById('root')).render(React.createElement(App))

if (getCurrentEnv() === 'dev') {
    serviceWorkerRegistration.register()
}
