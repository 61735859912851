import { selectInputStyles } from 'app/components/forms/Select/style'
import * as colors from '@shared/colors'
import { getBrand } from '@shared/brand'

const displayNone = () => ({ display: 'none' })
const brandColor = getBrand().navBarColor

export const selectStyles = {
    valueContainer: displayNone,

    indicatorsContainer: displayNone,

    menu: (provided: object) => ({
        ...provided,
        ...selectInputStyles.menu(),
        minWidth: 100,
        width: 'auto',
        zIndex: 1000
    }),

    menuList: (provided: object) => ({
        ...provided,
        ...selectInputStyles.menuList()
    }),

    option: (provided: object, state: { isSelected: boolean }) => {
        const { isSelected } = state

        return {
            ...provided,
            ...selectInputStyles.option(provided, state),
            backgroundColor: 'inherit',
            color: isSelected ? brandColor : colors.black_sweeply,
            opacity: isSelected ? 1 : 0.5,

            '&:hover': {
                backgroundColor: 'inherit',
                opacity: 1
            }
        }
    }
}
