import styled from 'styled-components'
import * as colors from '@shared/colors'

export const UserItemWrap = styled.div<{ isLast: boolean }>`
    width: 100%;
    height: 44px;
    align-items: center;
    cursor: pointer;
    display: grid;
    grid-template-columns: 0.8fr 3fr 0.8fr;
    padding-left: 32px;
    ${({ isLast }) => !isLast && `border-bottom: 1px solid ${colors.gentle_gray_spectaflow};`}
    ${({ isLast }) => isLast && `margin-bottom: 6.5px;`}

    &:hover {
        opacity: 0.6;
    }
`

export const TasksCount = styled.span`
    font-size: 1.077rem;
    font-weight: 500;
    margin-left: 8px;
`
