import React, { useEffect } from 'react'
import HoverableDatePicker from '../../components/hoverable-date-picker'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { currentDateNumberAtom, dataLoadingAtom } from '../state/atoms'
import moment from 'moment-timezone'

export default function DatePicker() {
    const [currentDateNumber, setCurrentDateNumber] = useRecoilState(currentDateNumberAtom)
    const setDateLoading = useSetRecoilState(dataLoadingAtom)

    useEffect(() => {
        const currentDateNumber = moment().valueOf()
        setCurrentDateNumber(currentDateNumber)
    }, [])

    function onDateChange(date: Date | number) {
        const currentDateNumber = typeof date === 'number' ? date : date.valueOf()
        setDateLoading(true)
        setCurrentDateNumber(currentDateNumber)
    }

    return (
        <div>
            <HoverableDatePicker hasTodayButton selectedDateNumber={currentDateNumber} onDateChange={onDateChange} />
        </div>
    )
}
