import styled from 'styled-components'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'

const IMG_SCALER = 1.2

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    margin-top: 5%;
    margin-bottom: 100px;
    width: 100%;
    background-color: 'transparent';
    align-items: center;
    align-self: center;
    flex-direction: column;
`
export const MessageWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;

    > img {
        opacity: 0.8;
        width: ${() => `${150 * IMG_SCALER}px`};
        height: ${() => `${129 * IMG_SCALER}px`};
    }

    .row-1,
    .row-2,
    .row-3 {
        color: ${colors.black_sweeply};
        opacity: 0.5;
    }

    .row-1 {
        margin-top: 12px;
        text-align: center;
        font-size: 21px;
        font-weight: 600;
    }

    .row-2,
    .row-3 {
        font-weight: 400;
    }
    .subtext-row {
        margin-top: 8px;
    }
    .row-2 {
        font-size: 16px;
    }

    .row-3 {
        font-size: 15px;
        margin-top: 9px;
    }
    .action {
        font-size: 16px;
        display: inline-block;
        color: red;
        cursor: pointer;
        border-bottom: 2px solid ${colors.yello_spectaflow};
        font-weight: 400;
    }
`
