import * as chelpers from '@shared/cleaning-helpers'
import { View, Image } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import React, { useContext } from 'react'
import { UNIT_HEADER_FONT_SIZE } from './consts'
import { TaskScreenContext } from './TaskScreenContext'
import { AuthContext } from '../../../modules/Auth/AuthContext'

const Rule = ({ activeRule }: { activeRule: any }) => {
    const { area, selectedDateNumber } = useContext(TaskScreenContext)
    const { currentUser } = useContext(AuthContext)
    const DEBUG_BORDER = 0
    const rule = activeRule
    const description = rule.description ? rule.description : 'This rule has no description'
    const cleaningActionIcon = chelpers.pickCleaningActionIcon(
        area.cleaningStatus,
        area.occupancy,
        area.cleaningFrequency,
        area.rules,
        true
    )

    return (
        <View
            style={{
                display: 'flex',
                flex: 1
            }}>
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',

                    borderWidth: DEBUG_BORDER,
                    borderColor: 'red',
                    marginBottom: 6
                }}>
                {cleaningActionIcon && (
                    <View
                        style={{
                            width: 26,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderWidth: DEBUG_BORDER,
                            borderColor: 'red'
                        }}>
                        <Image
                            style={{ width: cleaningActionIcon.width! * 1.2, height: cleaningActionIcon.height! * 1.2 }}
                            // @ts-ignore
                            source={cleaningActionIcon.source}
                        />
                    </View>
                )}

                <span
                    style={{
                        marginTop: 2,
                        marginLeft: cleaningActionIcon ? 4 : 0,
                        fontWeight: '600',
                        color: iOSColors.black,
                        fontSize: UNIT_HEADER_FONT_SIZE
                    }}>
                    {rule.name}
                </span>
            </View>
            <View>
                <span
                    style={{
                        // @ts-ignore
                        paddingHorizontal: 32,
                        fontSize: 14,
                        color: iOSColors.gray,
                        whiteSpace: 'pre-line'
                    }}>
                    {description}
                </span>
            </View>
        </View>
    )
}

export default Rule
