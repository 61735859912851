import styled from 'styled-components'
import * as colors from '@shared/colors'
import { MOBILE_BREAKPOINT } from '../../../utils/constants'

const black = colors.black_sweeply
const yellow = colors.main_yellow
const red = colors.main_red

export const LoadingScreenWrap = styled.div`
    width: 538px;
    margin-top: 127px;
    font-family: 'Circular Std';
    font-style: normal;
    text-align: center;
    color: ${black};

    > h1 {
        font-weight: 700;
        font-size: 2.462rem;
        line-height: 48px;
        margin-bottom: 40px;
    }

    > span {
        opacity: 0.5;
        font-size: 1.538rem;
        line-height: 40px;
        margin-top: 80px;
        display: block;
    }

    .MuiLinearProgress-root {
        height: 12px;
    }
    .MuiLinearProgress-colorPrimary {
        background-color: ${yellow};
    }
    .MuiLinearProgress-barColorPrimary {
        background-color: ${red};
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        margin-top: 42.54px;
        text-align: left;

        > span {
            font-size: 1.333rem;
            line-height: 32px;
            margin-top: 42px;
        }
    }
`
