import React, { useEffect, useMemo, useState } from 'react'
import * as c from '@shared/constants'
import { ModalContainer, ModalOverlay } from 'app/components/styled-components/modal'
import {
    areaNotesSummarySelector,
    bookingNotesSummarySelector,
    dailyCommentsSummarySelector
} from 'app/modules/HousekeepingDashboard/state/selectors/commentsAndNotes'
import { useRecoilValue } from 'recoil'
import { SummaryNote, SummaryTab } from 'app/modules/HousekeepingDashboard/types'
import { toggleLockBodyScroll } from 'app/components/modals/ModalLayout'
import Navigation from './Navigation'
import NotesList from './NotesList'

interface Props {
    onClose: () => void
}

function DailyCommentsSummary({ onClose }: Props) {
    const dailyComments = useRecoilValue(dailyCommentsSummarySelector)
    const areaNotes = useRecoilValue(areaNotesSummarySelector)
    const bookingNotes = useRecoilValue(bookingNotesSummarySelector)

    const defaultTab: Readonly<SummaryTab> = {
        title: 'Daily comments',
        type: c.ACTIVITY_TYPE_DAILY_COMMENT,
        icon: 'specta-daily-comment',
        count: dailyComments.length,
        isActiveTab: true
    }

    const [activeTab, setActiveTab] = useState<SummaryTab>(defaultTab)

    const allNotes = [
        { type: c.ACTIVITY_TYPE_DAILY_COMMENT, notes: dailyComments },
        { type: c.ACTIVITY_TYPE_AREA_NOTE, notes: areaNotes },
        { type: c.BOOKING_NOTE, notes: bookingNotes }
    ] as const

    const currentNotes = useMemo(() => {
        return allNotes.find(note => note.type === activeTab.type)?.notes as SummaryNote[]
    }, [allNotes, activeTab.type])

    useEffect(() => {
        toggleLockBodyScroll(true)

        return () => {
            toggleLockBodyScroll(false)
        }
    }, [])

    const tabs: Readonly<SummaryTab[]> = [
        {
            ...defaultTab,
            isActiveTab: activeTab.type === c.ACTIVITY_TYPE_DAILY_COMMENT
        },
        {
            icon: 'specta-note',
            title: 'Unit notes',
            count: areaNotes.length,
            type: c.ACTIVITY_TYPE_AREA_NOTE,
            isActiveTab: activeTab.type === c.ACTIVITY_TYPE_AREA_NOTE
        },
        {
            icon: 'specta-booking-note',
            title: 'Booking notes',
            count: bookingNotes.length,
            type: c.BOOKING_NOTE,
            isActiveTab: activeTab.type === c.BOOKING_NOTE
        }
    ]

    return (
        <ModalOverlay basic>
            <ModalContainer width="75%" height="80%" padding="0" basic>
                <Navigation tabs={tabs} setActiveTab={setActiveTab} onClose={onClose} />
                <NotesList activeTab={activeTab} notes={currentNotes} />
            </ModalContainer>
        </ModalOverlay>
    )
}

export default DailyCommentsSummary
