import React, { useMemo } from 'react'
import objectPath from 'object-path'
import { checkIsActive } from '../../../_helpers'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import { Dropdown } from 'react-bootstrap'

export function HeaderMobile({ guest }) {
    const uiService = useHtmlClassService()
    const getMenuItemActive = url => {
        return checkIsActive(location, url) ? 'nav-item-active' : ''
    }

    const layoutProps = useMemo(() => {
        return {
            headerLogo: uiService.getStickyLogo(),
            asideDisplay: false,
            headerMenuSelfDisplay: true,
            headerMobileCssClasses: uiService.getClasses('header_mobile', true),
            headerMobileAttributes: uiService.getAttributes('header_mobile'),
            ulClasses: uiService.getClasses('header_menu_nav', true),
            menuDesktopToggle: objectPath.get(uiService.config, 'header.menu.desktop.toggle')
        }
    }, [uiService])

    return (
        <>
            {/*begin::Header Mobile*/}
            <div
                id="kt_header_mobile"
                className={`header-mobile align-items-center header-mobile-fixed header-mobile-fixed-lg`}
                {...layoutProps.headerMobileAttributes}
                style={{ backgroundColor: '#2D2D2D' }}>
                {/*begin::Logo*/}
                {/* <Link to="/">
                    <img alt="logo" src={brand.getNavBarAuthLogo().img} style={brand.getNavBarAuthLogo().style} />
                </Link> */}
                <div className="d-flex flex-column">
                    {guest && guest.organization && (
                        <>
                            <div className="display5 font-weight-boldest text-white">{guest.organization.name}</div>
                            <div className="font-size-sm font-weight-bold text-white mt-n3">Guest services</div>
                        </>
                    )}
                    {guest && guest.area && <div className="font-size-h3 font-weight-bolder text-white">{guest.area.name}</div>}
                </div>
                {/*end::Logo*/}

                <div className="ml-10 d-none d-sm-inline">
                    <Dropdown className="dropdown dropdown-inline mr-5 mr-sm-7">
                        <Dropdown.Toggle className="cursor-pointer" variant="transparent" as={DropdownMobileToggler}>
                            {/* {i.icon && (
                                            <Tooltip text={i.name} position="bottom" distance={20}>
                                                <span className="symbol symbol-circle symbol-30">
                                                    <span className="symbol-label font-size-base font-weight-bolder text-white">
                                                        <i
                                                            className={`menu-icon specta-${i.icon} ${getMenuItemActive(i.path)} icon-${
                                                                i.iconSize
                                                            }`}
                                                        />
                                                    </span>
                                                </span>
                                            </Tooltip>
                                        )} */}
                            asdf
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            rootCloseEvent="click"
                            className="dropdown-menu dropdown-menu-sm dropdown-menu-right mt-2"></Dropdown.Menu>
                    </Dropdown>
                    {/* {filteredNavItems.map(i => {
                        if (i.subMenuItems.length > 0) {
                            return (
                                <Dropdown key={i.path} className="dropdown dropdown-inline mr-5 mr-sm-7">
                                    <Dropdown.Toggle className="cursor-pointer" variant="transparent" as={DropdownMobileToggler}>
                                        {i.icon && (
                                            <Tooltip text={i.name} position="bottom" distance={20}>
                                                <span className="symbol symbol-circle symbol-30">
                                                    <span className="symbol-label font-size-base font-weight-bolder text-white">
                                                        <i
                                                            className={`menu-icon specta-${i.icon} ${getMenuItemActive(i.path)} icon-${
                                                                i.iconSize
                                                            }`}
                                                        />
                                                    </span>
                                                </span>
                                            </Tooltip>
                                        )}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        rootCloseEvent="click"
                                        className="dropdown-menu dropdown-menu-sm dropdown-menu-right mt-2">
                                        {i.subMenuItems.map(subItem => {
                                            return (
                                                <NavLink
                                                    key={subItem.path}
                                                    onClick={() => {
                                                        document.body.click()
                                                    }}
                                                    className={`row row-paddingless px-5 py-3 align-items-center`}
                                                    to={subItem.path}>
                                                    {subItem.icon && (
                                                        <span className="symbol symbol-circle symbol-30">
                                                            <span className="symbol-label font-size-base font-weight-bolder text-white">
                                                                <i
                                                                    className={`menu-icon specta-${subItem.icon} ${getMenuItemActive(
                                                                        subItem.path
                                                                    )} icon-${subItem.iconSize}`}
                                                                />
                                                            </span>
                                                        </span>
                                                    )}
                                                    <span
                                                        className={`text-hover-specta-main ml-3 font-size-base font-weight-bolder ${getMenuItemActive(
                                                            subItem.path
                                                        )}`}
                                                        style={{ color: '#acacac' }}>
                                                        {subItem.name}
                                                    </span>
                                                </NavLink>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )
                        } else {
                            return (
                                <NavLink key={i.path} className="btn btn-clean btn-lg btn-icon mr-5 mr-sm-7" to={i.path}>
                                    {i.icon && (
                                        <Tooltip text={i.name} position="bottom" distance={20}>
                                            <span className="symbol symbol-circle symbol-30 ">
                                                <span className="symbol-label font-size-base font-weight-bolder text-white text-hover-specta-main">
                                                    <i
                                                        className={`menu-icon specta-${i.icon} ${getMenuItemActive(i.path)} icon-${
                                                            i.iconSize
                                                        }`}
                                                    />
                                                </span>
                                            </span>
                                        </Tooltip>
                                    )}
                                </NavLink>
                            )
                        }
                    })} */}
                </div>

                {/*begin::Toolbar*/}
                <div className="d-flex align-items-center ml-auto mr-3">
                    {layoutProps.asideDisplay && (
                        <>
                            {/*begin::Aside Mobile Toggle*/}
                            <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                                <span />
                            </button>
                            {/*end::Aside Mobile Toggle*/}
                        </>
                    )}

                    {/* {layoutProps.headerMenuSelfDisplay && (
                        <>
                            <button className="d-inline d-sm-none btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
                                <span />
                            </button>
                        </>
                    )} */}
                </div>
                {/*end::Toolbar*/}
            </div>
            {/*end::Header Mobile*/}
        </>
    )
}

export const DropdownMobileToggler = React.forwardRef((props, ref) => {
    return (
        <a
            ref={ref}
            className="btn btn-clean btn-lg btn-icon"
            onClick={e => {
                e.preventDefault()
                props.onClick(e)
            }}>
            {props.children}
        </a>
    )
})
