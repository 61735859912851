import { OrgStruct } from '@shared/firestore-structs'
import { IntegrationParams, TaskboardContext } from '@shared/traces-types'
import { currentOrganizationAtom } from 'app/modules/Auth/atoms'
import { handleSessionAutoTermination, getIntegrationParams, matchCurrentOrganizationWithApaleo } from 'app/traces/integration-utils'
import { selectorFamily } from 'recoil'

type Params = { taskboardContext: TaskboardContext | null; search: string }

export const integrationParamsSelector = selectorFamily<
    IntegrationParams<TaskboardContext.PROPERTY | TaskboardContext.RESERVATIONS> | null,
    Params
>({
    key: 'taskboard-integrationParamsSelector',
    get: ({ taskboardContext, search }: Params) => async ({ get }) => {
        const org = get(currentOrganizationAtom) as OrgStruct

        try {
            if (taskboardContext !== TaskboardContext.EMBEDDED) {
                handleSessionAutoTermination(search)
                await matchCurrentOrganizationWithApaleo(search, org)
            }

            if (taskboardContext === TaskboardContext.RESERVATIONS || taskboardContext === TaskboardContext.PROPERTY) {
                return await getIntegrationParams(taskboardContext, search)
            }
        } catch (error) {
            console.error(error)
            throw error
        }

        return null
    }
})
