import React, { useMemo } from 'react'
import moment from 'moment-timezone'
import { IconButton } from './IconButton'
export interface DueDateButtonProps {
    dueDate: number
    readOnly?: boolean
    fontSize?: number
    inverse?: boolean
    selectedDate?: number
    onClick?: () => void
}
interface Classes {
    text: string[]
    icon: string[]
    button: string[]
}
interface FormattedClasses {
    text: string
    icon: string
    button: string
}

export const DueDateButton = ({ dueDate, onClick, readOnly, inverse, selectedDate, fontSize }: DueDateButtonProps) => {
    const today = moment(selectedDate).startOf('day')

    const getColorClasses = () => {
        const classes: Classes = {
            text: [],
            icon: [],
            button: []
        }
        classes.text.push(moment(dueDate).diff(today, 'days') < 0 ? 'text-danger' : 'text-dark-50')
        classes.icon.push(moment(dueDate).diff(today, 'days') < 0 ? 'text-danger' : 'text-dark-50')
        if (inverse) {
            classes.text = classes.icon = ['text-white']
            classes.button = ['btn-transparent']
            classes.icon = ['text-white']
        }
        const formattedClasses: FormattedClasses = {
            text: classes.text.join(' '),
            icon: classes.icon.join(' '),
            button: classes.button.join(' ')
        }
        return formattedClasses
    }

    const font_size = fontSize ? fontSize : 12

    const button = useMemo(() => {
        return (
            <>
                {readOnly ? (
                    <div className={`d-flex align-items-center ${getColorClasses().text}`}>
                        <i className={`specta-duedate mr-2 ${getColorClasses().icon}`} style={{ fontSize: font_size }} />
                        <span className="font-weight-bolder" style={{ fontSize: font_size }}>
                            {dueDate ? moment(dueDate).format('MMM D') : 'No due date'}
                        </span>
                    </div>
                ) : (
                    <div className="row row-paddingless d-flex" style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                        <div style={{ display: 'flex' }}>
                            <span className={`d-flex align-items-center ${getColorClasses().text}`}>
                                <span className="font-weight-bolder font-size-lg" style={{ fontSize: font_size }}>
                                    {dueDate ? moment(dueDate).format('MMM D') : 'No due date'}
                                </span>
                            </span>
                            <IconButton
                                className={`btn btn-xxs btn-circle border-0 btn-icon bg-hover-danger text-hover-white ml-1 ${
                                    getColorClasses().button
                                }`}
                                onClick={onClick}
                                icon="close"
                                iconsize={`icon-xs ${getColorClasses().icon}`}
                                tooltip={{
                                    text: 'Remove due date',
                                    position: 'top'
                                }}
                            />
                        </div>
                        <i className={`specta-duedate icon-lg mr-3 ${getColorClasses().icon}`} style={{ fontSize: font_size }} />
                    </div>
                )}
            </>
        )
    }, [dueDate, onClick, readOnly, inverse, selectedDate, fontSize])

    return button
}
