import styled from 'styled-components'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'

export const Wrap = styled.div`
    width: 100%;
    padding: 24px 40px;
`

export const InfoItem = styled.div`
    margin-bottom: 12px;

    > h3 {
        font-size: 1.077rem;
        color: ${iOSColors.midGray};
    }

    > span {
        font-size: 1.154rem;
        color: ${iOSColors.gray};
    }
`
