import React from 'react'
import { Text, TextButton, Wrap } from './style'
import MainActionButton from 'app/components/buttons/MainActionButton'
import Loader from 'app/components/loaders/Loader'

interface Props {
    buttonText: string
    textButtonText: string
    onClick: () => void
    textButtonOnClick?: () => void
    type: 'assign' | 'unassign'
    disabled?: boolean
    loading?: boolean
}

function ActionBar({ buttonText, textButtonText, onClick, textButtonOnClick, type, disabled = false, loading = false }: Props) {
    return (
        <Wrap isAssign={type === 'assign'}>
            <div>
                {loading && <Loader className="spinner_action_bar" size="small" />}
                <MainActionButton type={type === 'assign' ? 'primary' : 'danger'} disabled={disabled} onClick={onClick}>
                    {buttonText}
                </MainActionButton>
                {textButtonOnClick ? (
                    <TextButton disabled={disabled} onClick={textButtonOnClick}>
                        {textButtonText}
                    </TextButton>
                ) : (
                    <Text>{textButtonText}</Text>
                )}
            </div>
        </Wrap>
    )
}

export default ActionBar
