import { Firebase, FirebaseFirestore } from '../firebase'
import { AreaStruct, AreaSummaryProjectionStruct, HousekeepingOverviewProjectionStruct, RuleStruct, TaskStatus } from '../firestore-structs'
import moment from 'moment-timezone'

export function getProjectionQuery(firebase: Firebase, organizationKey: string, date: number) {
    const projectionQuery = firebase
        .firestore()
        .collection('housekeeping-overview')
        .doc(organizationKey)
        .collection<HousekeepingOverviewProjectionStruct>('dates')
        .doc(moment(date).format('YYYY-MM-DD'))

    return projectionQuery
}

export function getAreaSummaryQuery(firebase: Firebase, organizationKey: string, date: number, areaKey: string) {
    const projectionQuery = firebase
        .firestore()
        .collection('housekeeping-overview')
        .doc(organizationKey)
        .collection('dates')
        .doc(moment(date).format('YYYY-MM-DD'))
        .collection<AreaSummaryProjectionStruct>('area-summary')
        .doc(areaKey)

    return projectionQuery
}
export function getAreasQuery(firebase: Firebase, organizationKey: string, areaGroups?: string[]) {
    let areasQuery = firebase
        .firestore()
        .collection<AreaStruct>('areas')
        .where('organizationKey', '==', organizationKey)
        .where('visible', '==', true)
        .where('displayCleaningStatus', '==', true)

    if (areaGroups) {
        const seeAll = areaGroups.find(g => g.toLowerCase() === 'all') !== undefined
        if (!seeAll && areaGroups.length < 10) {
            areasQuery = areasQuery.where('group', 'in', areaGroups)
        } else {
            areasQuery = areasQuery.orderBy('group', 'asc')
        }
    }

    areasQuery = areasQuery.orderBy('name', 'asc')

    return areasQuery
}

export function getAreasQuery2(firebase: Firebase, organizationKey: string) {
    return firebase.firestore().collection<{ areas: AreaStruct[] }>('housekeeping-overview').doc(organizationKey)
}

export function getActivitiesQuery(firebase: Firebase, date: number, organizationKey: string, areaKey = null) {
    if (areaKey) {
        return firebase.firestore().collection('areas').doc(areaKey).collection('activities').where('date', '==', date)
    }
    return firebase.firestore().collectionGroup('activities').where('organizationKey', '==', organizationKey).where('date', '==', date)
}

export function getRulesQuery(firebase: Firebase | FirebaseFirestore, organizationKey: string) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return db.collection<RuleStruct>('rules').where('organizationKey', '==', organizationKey).where('visible', '==', true)
}

export function getBookingsQuery(firebase: Firebase, organizationKey: string, date: number, areaKey = null) {
    let bookingsQuery = firebase
        .firestore()
        .collection('bookings')
        .where('organizationKey', '==', organizationKey)
        .where('bookingDates', 'array-contains', date.toString())

    if (areaKey) {
        bookingsQuery = bookingsQuery.where('areaKey', '==', areaKey)
    }

    bookingsQuery = bookingsQuery.orderBy('checkinDate', 'asc')

    return bookingsQuery
}

export function getDailyCommentsQuery(firebase: Firebase, date: number, organizationKey: string, areaKey = null) {
    let dailyCommentsQuery = firebase
        .firestore()
        .collectionGroup('dailyComments')
        .where('organizationKey', '==', organizationKey)
        .where('date', '==', date)

    if (areaKey) {
        dailyCommentsQuery = dailyCommentsQuery.where('areaKey', '==', areaKey)
    }

    return dailyCommentsQuery
    // TODO - Confirm this is the correct way to query for daily comments / remove other daily comments query in the app
    // if (areaKey) {
    //     return firebase.firestore().collection('areas').doc(areaKey).collection('dailyComments').where('date', '==', date)
    // }

    // return firebase.firestore().collectionGroup('dailyComments').where('organizationKey', '==', organizationKey).where('date', '==', date)
}

export function getIssuesQuery(firebase: Firebase, organizationKey: string, status: TaskStatus[], areaKey = '') {
    let issuesQuery = firebase.firestore().collection('issues').where('organizationKey', '==', organizationKey).orderBy('updated', 'desc')

    if (status.length > 0) {
        issuesQuery = issuesQuery.where('status', 'in', status)
    }

    if (areaKey !== '') {
        issuesQuery = issuesQuery.where('areaKey', '==', areaKey)
    }

    return issuesQuery
}

export const getQueryArray = <T>(statusFilter: { isSelected: boolean; value: T }[]) => {
    const queryArr = []
    for (let i = 0; i < 4; i++) {
        if (statusFilter[i].isSelected) {
            queryArr.push(statusFilter[i].value)
        }
    }
    return queryArr
}

export function getUsersQuery(firebase: Firebase, organizationKey: string) {
    return firebase.firestore().collection('users').where('organizationKey', '==', organizationKey).where('visible', '==', true)
}
