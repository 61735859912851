import styled from 'styled-components'

export const Wrapper = styled.div`
    padding: 30px 40px 6px 40px;
`

export const BoxesWrap = styled.div`
    width: 100%;
    display: flex;
    column-gap: 68px;
    margin-bottom: 24px;
`

export const Box = styled.div<{ opacity?: number; width?: string; height?: string; justifyContent?: 'start' | 'center' | 'end' }>`
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0.5rem 1rem;
    padding-top: 29px;
    padding-bottom: 24px;
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'center'};
    box-sizing: border-box;
    width: ${({ width }) => width || '50%'};
    height: ${({ height }) => height || '250px'} !important;
    opacity: ${({ opacity }) => `${opacity}` || 1};
`

export const SelectorWrap = styled.div`
    width: 240px;
`

export const TitleWrap = styled.div<{ iconColor: string }>`
    display: flex;
    column-gap: 8px;
    margin-bottom: 12px;

    > h1 {
        font-weight: 600;
        font-size: 1.308rem;
    }

    > svg {
        width: 17px;
        height: 17px;

        path {
            fill: ${({ iconColor }) => iconColor};
        }
    }
`
