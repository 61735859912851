import React, { useMemo, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import { AccountsFilter } from './accounts-filter/AccountsFilter'
import { AccountsTable } from './accounts-table/AccountsTable'
import { AccountsContext } from '../AccountsContext'
import MainActionButton from '../../../components/buttons/MainActionButton'

export function AccountsCard({ currentOrganization }) {
    let history = useHistory()
    const { ids, newAccountButtonClick, totalCount } = useContext(AccountsContext)
    // const accountsUIProps = useMemo(() => {
    //     return {
    //         ids: ids,
    //         newAccountButtonClick: newAccountButtonClick
    //     }
    // }, [ids, newAccountButtonClick])

    return (
        <Card className="card-no-shadow">
            <CardHeader count={totalCount} title="Accounts">
                <CardHeaderToolbar>
                    <MainActionButton
                        type="primary"
                        size="large"
                        onClick={() => {
                            history.push('/partner/accounts/new')
                        }}>
                        New Account
                    </MainActionButton>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <AccountsFilter />
                {/* {accountsUIProps.ids.length > 0 && <AccountsGrouping />} */}
                <AccountsTable currentOrganization={currentOrganization} />
            </CardBody>
        </Card>
    )
}
