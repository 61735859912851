import { SelectOption } from 'app/types'
import React, { ComponentType, useRef } from 'react'
import Select, { ControlProps, GroupBase, SingleValue } from 'react-select'
import { selectStyles } from './style'
import { useClickOutside } from 'app/utils/hooks/useClickOutside'

interface Props {
    value: SelectOption<string> | null
    options: SelectOption<string>[]
    onChange: (value: SingleValue<SelectOption<string>>) => void
    controlComponent: ComponentType<ControlProps<SelectOption<string>, false, GroupBase<SelectOption<string>>>>
    menuIsOpen: boolean
    onBlur: () => void
}

function SubNavMenu({ value, options, controlComponent, onChange, menuIsOpen, onBlur }: Props) {
    const menuRef = useRef<HTMLDivElement>(null)

    useClickOutside(menuRef, onBlur)

    return (
        <div ref={menuRef}>
            <Select
                styles={selectStyles}
                menuIsOpen={menuIsOpen}
                value={value}
                options={options}
                onChange={onChange}
                components={{ Control: controlComponent }}
            />
        </div>
    )
}

export default SubNavMenu
