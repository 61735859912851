import React from 'react'
import { FilterButtonWrap } from '../../SearchAndFilters/Filters/style'
import { useSetRecoilState } from 'recoil'
import { showAssignActionBarAtom, subscriptionParamsAtom } from 'app/modules/HousekeepingDashboard/state/atoms'
import MainActionButton from 'app/components/buttons/MainActionButton'
import { SubscriptionCheck } from 'app/components/upgrade-signals'

function AssignActionBarButton() {
    const setSubscriptionParams = useSetRecoilState(subscriptionParamsAtom)
    const setShowActionBar = useSetRecoilState(showAssignActionBarAtom)

    return (
        <FilterButtonWrap>
            <SubscriptionCheck
                featureId="assign-housekeeping"
                onAvailable={() => (
                    <MainActionButton size="large" onClick={() => setShowActionBar(prev => !prev)}>
                        Assign staff
                    </MainActionButton>
                )}
                onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                    return (
                        <MainActionButton
                            type="primary"
                            size="large"
                            onClick={() =>
                                setSubscriptionParams({
                                    hasSubscriptionAccess: false,
                                    notAccessibleFeature,
                                    availableUpgrades,
                                    upgradeMessageIsOpen: true
                                })
                            }>
                            Assign Staff
                        </MainActionButton>
                    )
                }}
            />
        </FilterButtonWrap>
    )
}

export default AssignActionBarButton
