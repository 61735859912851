import React, { useMemo } from 'react'
import { TasksCount, UserItemWrap } from './style'
import Tooltip from 'app/components/tooltips/BlackTooltip'
import { getPhotoWrapBackgroundColor } from 'app/utils/helpers'
import { AssigneeWithCountAndAreas } from 'app/modules/HousekeepingDashboard/types'
import { PhotoWrap } from 'app/components/layouts/User/style'
import { useRecoilValue } from 'recoil'
import { calculatedAreasSelector } from 'app/modules/HousekeepingDashboard/state/selectors/calculatedArea'
import { countWorkload } from 'app/modules/HousekeepingDashboard/state/selectors/areaRelatedData'
import { Workload } from 'app/components/workload'
import { iOSColors } from '@shared/react-native-typography'

interface Props {
    user: AssigneeWithCountAndAreas
    isLast: boolean
    currentUserKey: string
    onUserClick: (user: AssigneeWithCountAndAreas) => void
}

function UserItem({ user, isLast, currentUserKey, onUserClick }: Props) {
    const calculatedAreas = useRecoilValue(calculatedAreasSelector(user.areas))
    const workloadCounts = useMemo(() => {
        return countWorkload(calculatedAreas.map(calcArea => calcArea.area))
    }, [calculatedAreas])

    return (
        <UserItemWrap isLast={isLast} onClick={() => onUserClick(user)}>
            <Tooltip placement={'top'} content={user.name}>
                <PhotoWrap
                    cursor="pointer"
                    fontSize="0.923rem"
                    size={'28px'}
                    backgroundColor={getPhotoWrapBackgroundColor(user.key, currentUserKey)}>
                    {user.initials}
                </PhotoWrap>
            </Tooltip>

            <Tooltip placement={'top'} content={'Press to assign'}>
                <div>
                    <TasksCount>{`(${user.count})`}</TasksCount>
                </div>
            </Tooltip>

            <div>
                <Workload
                    itemWidth={32}
                    renderEmptySlots={true}
                    fontSize={14}
                    fontWeight={'500'}
                    color={iOSColors.black}
                    opacity={0.8}
                    counts={workloadCounts}
                    tooltipDisabled={true}
                />
            </div>
        </UserItemWrap>
    )
}

export default UserItem
