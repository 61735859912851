import React from 'react'
import { View, TouchableOpacity, Image } from 'react-native-web'

import * as helpers from '@shared/helpers'
import { getStatusImage } from '@shared/getStatusImage'
import * as issueData from '@shared/issue-data'
import * as colors from '@shared/colors'
import * as constants from '@shared/constants'
import firebase, { asFirebase, uncompleteIssue, completeIssue, asBatch } from '../utils/firebase'
import * as uc from '../utils/constants'
import { HoverableTextButton } from '../components/buttons/hoverable-text-button'
import { HoverableImageButton } from '../components/buttons/hoverable-image-button'
import { SectionTitle } from '../components/section-title'
import { IssueStruct, TaskStatus, UserStruct } from '@shared/firestore-structs'
import { batchCommit } from '@shared/data-helpers'

interface MassStatusProps {
    checkedIssues: IssueStruct[]
    currentUser: UserStruct
    closeModal: () => void
    clearCheckedIssues: () => void
    setShowIssueActionBar: (showIssueActionBar: boolean) => void
}

export default class MassStatus extends React.Component<MassStatusProps> {
    updateIssues(status: IssueStruct['status']) {
        const batch = firebase.firestore().batch()
        const issuesList = [...this.props.checkedIssues].reverse()

        issuesList.forEach(issue => {
            let updatedIssue = { ...issue }
            if (issue.status === constants.ISSUE_OPEN) {
                updatedIssue = { ...updatedIssue, assignedTo: null, assignedContacts: null }
            }

            updatedIssue = { ...updatedIssue, status }
            status === constants.ISSUE_COMPLETE
                ? completeIssue(
                      asFirebase(firebase),
                      {
                          issueKey: issue.key,
                          currentUser: this.props.currentUser,
                          taskKey: issue.taskKey
                      },
                      asBatch(batch)
                  )
                : uncompleteIssue(
                      asFirebase(firebase),
                      {
                          issueKey: issue.key,
                          currentUser: this.props.currentUser,
                          assignedContacts: issue.assignedContacts,
                          taskKey: issue.taskKey
                      },
                      asBatch(batch)
                  )

            issueData.addCommentivityToIssue(
                asFirebase(firebase),
                updatedIssue.key,
                'action',
                'set the status to ' + updatedIssue.status,
                this.props.currentUser
            )
        })

        batchCommit(asBatch(batch), 'mass-status updateIssues', 'updating issues status')

        this.props.closeModal()
        this.props.clearCheckedIssues()
        this.props.setShowIssueActionBar(false)
    }

    render() {
        return (
            <View
                style={{
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingHorizontal: uc.MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE,
                    borderWidth: 0,
                    paddingTop: 36,
                    paddingBottom: 12
                }}>
                <View
                    style={{
                        borderWidth: 0,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                    <SectionTitle text={'Select issue status'} fontSize={19} />
                    <HoverableImageButton
                        dimension={uc.SMALL_BUTTON_DIM}
                        hoverOpacity={uc.HOVER_OPACITY}
                        imageSource={require('@shared/img/close.svg')}
                        onPress={this.props.closeModal}
                    />
                </View>

                <View
                    style={{
                        borderWidth: 0,
                        borderColor: 'red',
                        marginTop: 24
                    }}>
                    <HoverableImageButton
                        viewWidth={85}
                        dimension={uc.BUTTON_DIM}
                        hoverOpacity={uc.HOVER_OPACITY}
                        imageSource={require('@shared/img/status-open.svg')}
                        onPress={() => {
                            this.updateIssues(constants.ISSUE_OPEN)
                        }}
                        text={helpers.capitalizeFirstLetter(constants.ISSUE_OPEN)}
                    />
                    <View style={{ marginBottom: 24 }} />
                    <HoverableImageButton
                        viewWidth={120}
                        dimension={uc.BUTTON_DIM - 2}
                        hoverOpacity={uc.HOVER_OPACITY}
                        imageSource={require('@shared/img/status-complete-inverse.svg')}
                        onPress={() => {
                            this.updateIssues(constants.ISSUE_COMPLETE)
                        }}
                        text={helpers.capitalizeFirstLetter(constants.ISSUE_COMPLETE)}
                    />
                </View>
                <View
                    style={{
                        borderWidth: 0,
                        flexDirection: 'row',
                        marginTop: 16,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <span
                        style={{
                            fontWeight: '400',
                            fontSize: 14,
                            color: colors.red_spectaflow
                        }}>
                        {this.props.checkedIssues.length} {helpers.getPlural('issue', this.props.checkedIssues.length)} will be affected
                    </span>
                </View>
                <View
                    style={{
                        borderWidth: 0,
                        flexDirection: 'row',
                        marginTop: 24,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <HoverableTextButton
                        text={'Cancel'}
                        className="btn btn-seconday"
                        onClick={() => {
                            this.props.closeModal()
                        }}
                    />
                </View>
            </View>
        )
    }

    renderStatusButton(status: TaskStatus) {
        const statusImgName = getStatusImage(status)

        return (
            <TouchableOpacity
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderColor: 'green',
                    borderWidth: 0,
                    height: 36,
                    marginBottom: 8
                }}
                onPress={() => {
                    this.updateIssues(status)
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <Image
                        style={{ height: 26, width: 26 }}
                        // @ts-ignore
                        source={statusImgName}
                    />
                    <span
                        style={{
                            fontSize: 15,
                            marginLeft: 10
                        }}>
                        {helpers.capitalizeFirstLetter(status)}
                    </span>
                </View>
            </TouchableOpacity>
        )
    }
}
