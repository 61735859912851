import Modal from 'react-modal'
import React, { useContext } from 'react'
import { UpgradeMessageContent } from '../../../components/upgrade-signals'
import UsersList from '../../../users/users-list'
import * as styles from '../../../housekeeping/dashboard/styles'
import { TaskScreenContext } from './TaskScreenContext'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import AIssue from '../../../issues/aissue-modal'
import * as dataObjects from '@shared/dataObjects'
import { assignUsers } from '../../../tasks/actions'
import { UserStruct } from '@shared/dataObjects'
import { DashboardContext } from '../DashboardContext'

const createAssignedToObj = (task: any) => {
    return task && task.assignedTo ? task.assignedTo : [{ name: 'empty' }]
}
const ModalPage = () => {
    const {
        selectedDateNumber,
        showUpgradeMessage,
        currentTask,
        area,
        closeUpgradeMessage,
        setShowUsersModal,
        showUsersModal,
        setCurrentTask,
        setShowIssueModal,
        showIssueModal,
        setAssignedTo,
        currentIssue
    } = useContext(TaskScreenContext)
    const { currentUser, currentOrganization } = useContext(AuthContext)
    const { allAreas, setAllAreas, updateAreasLocally } = useContext(DashboardContext)

    const onAssignPress = async (users: any) => {
        const task = currentTask
        if (!task) {
            setCurrentTask({})
        }

        const updatedTask = {
            ...task,
            assignedTo: users.map((a: UserStruct) => dataObjects.getMiniUserObject(a)),
            assignedToKeys: users.map((a: UserStruct) => a.key)
        }
        const assignedTo = createAssignedToObj(currentTask)

        await assignUsers(users, updatedTask, currentUser, selectedDateNumber)
        setCurrentTask(updatedTask)
        setShowUsersModal(false)
        setAssignedTo(assignedTo)
        // Update the UI

        const areasToUpdate = allAreas
            .filter((x: any) => x.key === area.key)
            .map((x: any) => {
                return {
                    key: x.key,
                    currentTask: updatedTask,
                    task: updatedTask,
                    assignedTo: updatedTask.assignedTo.map((x: any) => x.name)
                }
            })

        updateAreasLocally(areasToUpdate)
    }

    const aissue_style = {
        content: {
            maxHeight: '70vh',
            height: '70vh',
            width: '70%'
        }
    }

    const closeIssueModal = () => {
        setShowIssueModal(false)
    }

    return (
        <>
            <Modal
                isOpen={showUpgradeMessage}
                style={styles.upgradeMessageStyle}
                contentLabel="UpgradeMessages"
                onRequestClose={closeUpgradeMessage}>
                <UpgradeMessageContent
                    onClose={closeUpgradeMessage}
                    notAccessibleFeature={'browse-history'}
                    availableUpgrades={[]}
                    showCloseButton={true}
                />
            </Modal>
            <Modal
                isOpen={showUsersModal}
                onRequestClose={() => {
                    setShowUsersModal(false)
                }}
                style={styles.userListStyle}
                contentLabel="User">
                <UsersList
                    areas={[area]}
                    task={{ ...currentTask }}
                    closeModal={() => {
                        setShowUsersModal(false)
                    }}
                    currentUser={currentUser}
                    onAssignPress={onAssignPress}
                />
            </Modal>
            <Modal isOpen={showIssueModal} onRequestClose={closeIssueModal} style={styles.userListStyle} contentLabel="Open issue">
                <AIssue
                    currentUser={currentUser}
                    issue={currentIssue}
                    currentOrganization={currentOrganization}
                    closeModal={setShowIssueModal}
                    // @ts-ignore
                    respWidth={aissue_style.content.width}
                    // @ts-ignore
                    respHeight={aissue_style.content.height}
                />
            </Modal>
        </>
    )
}

export default ModalPage
