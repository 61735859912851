import React from 'react'
import { ActivitiesWrap, Wrapper } from './style'
import { useRecoilValue } from 'recoil'
import { activitiesAtom } from '../state/atoms'
import { SectionTitleWrap } from '../style'
import ActivityRow from 'app/housekeeping/activity-row'
import { currentOrganizationAtom, currentUserAtom } from 'app/modules/Auth/atoms'
import { OrgStruct, UserStruct } from '@shared/firestore-structs'

function ActivitySection() {
    const activities = useRecoilValue(activitiesAtom)
    const currentUser = useRecoilValue(currentUserAtom) as UserStruct
    const currentOrganization = useRecoilValue(currentOrganizationAtom) as OrgStruct

    if (activities.length === 0) return null
    return (
        <Wrapper>
            <SectionTitleWrap>
                <h2>Activity</h2>
            </SectionTitleWrap>

            <ActivitiesWrap>
                {activities
                    .map(activity => {
                        return (
                            <ActivityRow
                                key={activity.key}
                                activity={activity}
                                currentUser={currentUser}
                                currentOrganization={currentOrganization}
                            />
                        )
                    })
                    .reverse()}
            </ActivitiesWrap>
        </Wrapper>
    )
}

export default ActivitySection
