import styled from 'styled-components'
import { medium_blue, black_sweeply, hex2rgb } from '@shared/colors'

interface ButtonProps {
    selected: boolean
}

export const Button = styled.button`
    background-color: ${(props: ButtonProps) => (props.selected ? hex2rgb(medium_blue, 0.25) : hex2rgb(black_sweeply, 0.1))};
    height: 30px;
    border-radius: 16px;
    color: ${hex2rgb(black_sweeply, 0.65)};
    font-weight: 600;
    font-size: 0.923rem;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
`
