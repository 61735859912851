import React, { useEffect, useMemo, useState } from 'react'
import { CloseButtonWrap, CenterWrap, Wrapper, SelectButtonsWrap } from './style'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { asideMenuIsOpen } from '_metronic/layout/components/aside/state'
import FilterButton from './FilterButton'
import {
    areasAtom,
    assignFilterModeAtom,
    selectedAreasKeysAtom,
    usersAtom,
    showAssignActionBarAtom,
    cleaningTasksAtom,
    bookingsAtom,
    activitiesAtom
} from 'app/modules/HousekeepingDashboard/state/atoms'
import { AssignFilterMode } from 'app/modules/HousekeepingDashboard/types'
import SelectButton from './SelectButton'
import CleaningTaskNameInput from './CleaningTaskNameInput'
import ActionButton from './ActionButton'
import UsersSelectModal from 'app/components/modals/UsersSelectModal'
import { UserOption, constructUsersOptions } from '@shared/user-data'
import { currentOrganizationAtom, currentUserAtom } from 'app/modules/Auth/atoms'
import { UserStruct, TaskStruct, OrgStruct } from '@shared/firestore-structs'
import MainActionButton from 'app/components/buttons/MainActionButton'
import { assignHousekeepingTasks, unassignHousekeepingTasks } from 'app/modules/HousekeepingDashboard/api'
import { selectedDateNumberSelector } from 'app/modules/HousekeepingDashboard/state/selectors/selectedDateNumber'
import { getCleaningTask } from 'app/modules/HousekeepingDashboard/state/selectors/areaRelatedData'
import { toast } from 'react-toastify'
import { toastErrorStyle, toastSuccessStyle } from 'app/utils/styles'
import { getLastAssignee, setLastAssignee } from 'app/utils/helpers'
import { rulesAtom } from 'app/modules/HousekeepingDashboard/state/atoms'
import { createCleaningTaskDefaultNames } from 'app/modules/HousekeepingDashboard/api/utils'
import TeamWorkload from './TeamWorkload'
import { logActivity } from '@shared/area-data'
import firebase, { asFirebase } from 'app/utils/firebase'
import * as c from '@shared/constants'

const assignModeFilters: AssignFilterMode[] = Object.values(AssignFilterMode)

function AssignActionBar() {
    const [filterMode, setFilterMode] = useRecoilState(assignFilterModeAtom)
    const [selectedAreasKeys, setSelectedAreasKeys] = useRecoilState(selectedAreasKeysAtom)

    const asideIsOpen = useRecoilValue(asideMenuIsOpen)
    const areas = useRecoilValue(areasAtom)
    const users = useRecoilValue(usersAtom)
    const currentUser = useRecoilValue(currentUserAtom)
    const selectedDateNumber = useRecoilValue(selectedDateNumberSelector)
    const cleaningTasks = useRecoilValue(cleaningTasksAtom)
    const currentOrganization = useRecoilValue(currentOrganizationAtom)
    const bookings = useRecoilValue(bookingsAtom)
    const activities = useRecoilValue(activitiesAtom)
    const rules = useRecoilValue(rulesAtom)

    const setShowAssignActionBar = useSetRecoilState(showAssignActionBarAtom)

    const [usersSelectModalIsOpen, setUsersSelectModalIsOpen] = useState(false)
    const [unassignLoading, setUnassignLoading] = useState(false)
    const [cleaningTaskNameInputValue, setCleaningTaskNameInputValue] = useState('')

    const disabled = selectedAreasKeys.size === 0
    const selectedAreasGroups = useMemo(
        () => [...new Set(areas.filter(area => selectedAreasKeys.has(area.key)).map(area => area.group as string))],
        [selectedAreasKeys]
    )
    const usersOptions = useMemo(() => constructUsersOptions(users, selectedAreasGroups), [selectedAreasGroups])

    useEffect(() => {
        return () => {
            onClearAll()
            setFilterMode(AssignFilterMode.ALL)
        }
    }, [])

    useEffect(() => {
        onClearAll()
    }, [selectedDateNumber])

    function onFilterClick() {
        const currentFilterIndex = assignModeFilters.indexOf(filterMode)
        const nextFilterIndex = (currentFilterIndex + 1) % assignModeFilters.length
        const nextFilter = assignModeFilters[nextFilterIndex]

        setFilterMode(nextFilter)
    }

    function onSelectAll() {
        const allAreasKeys = areas.map(area => area.key)
        setSelectedAreasKeys(new Set(allAreasKeys))
    }

    function onClearAll() {
        setSelectedAreasKeys(new Set())
    }

    function onAssignClick(users: UserOption[]) {
        assign(users.map(user => user.data))
    }

    async function onUnassignClick() {
        setUnassignLoading(true)

        const areasForUnassign = areas.filter(area => selectedAreasKeys.has(area.key))
        const tasks = areasForUnassign.map(area => getCleaningTask(area.key, cleaningTasks)).filter(task => task) as TaskStruct[]

        onClearAll()

        try {
            const message = `Unit${tasks.length > 1 ? 's' : ''} ${tasks.map(task => task.area.name).join(', ')} unassigned successfully`

            await unassignHousekeepingTasks(tasks, currentUser as UserStruct)

            tasks.forEach(task =>
                logActivity(
                    asFirebase(firebase),
                    currentUser as UserStruct,
                    task.area.key as string,
                    c.ACTIVITY_TYPE_ASSIGNMENT,
                    selectedDateNumber,
                    {
                        before: 'unassigned'
                    }
                )
            )
            toast.success(message, toastSuccessStyle)
        } catch (e: unknown) {
            if (e instanceof Error) {
                toast.error(`Unassign failed, please try again (${e.message})`, toastErrorStyle)
            }
        }

        setUnassignLoading(false)
    }

    async function onSameAsLastClick() {
        const lastAssignee = await getLastAssignee()
        if (lastAssignee) assign(lastAssignee)
    }

    async function assign(users: UserStruct[] | TaskStruct['assignedTo']) {
        const areasForAssign = areas.filter(area => selectedAreasKeys.has(area.key))
        const existedTasks = areasForAssign.map(area => getCleaningTask(area.key, cleaningTasks)).filter(task => task) as TaskStruct[]
        const names =
            cleaningTaskNameInputValue.length === 0
                ? createCleaningTaskDefaultNames({
                      areas: areasForAssign,
                      tasks: existedTasks,
                      date: selectedDateNumber,
                      currentOrganization: currentOrganization as OrgStruct,
                      bookings,
                      activities,
                      rules
                  })
                : areasForAssign.map(() => cleaningTaskNameInputValue)

        onClearAll()

        try {
            const message = `Unit${areasForAssign.length > 1 ? 's' : ''} ${areasForAssign
                .map(area => area.name)
                .join(', ')} assigned successfully`

            await assignHousekeepingTasks(
                areasForAssign,
                users as Partial<UserStruct>[],
                selectedDateNumber,
                currentUser as UserStruct,
                names,
                existedTasks
            )

            setLastAssignee(users)
            toast.success(message, toastSuccessStyle)
        } catch (e: unknown) {
            if (e instanceof Error) {
                toast.error(e.message, toastErrorStyle)
            }
        }

        setUsersSelectModalIsOpen(false)
    }

    return (
        <>
            {usersSelectModalIsOpen && (
                <UsersSelectModal
                    onClose={() => setUsersSelectModalIsOpen(false)}
                    usersOptions={usersOptions}
                    currentUser={currentUser as UserStruct}
                    onAssignClick={onAssignClick}
                />
            )}

            <Wrapper asideIsOpen={asideIsOpen}>
                {cleaningTasks.length > 0 && <TeamWorkload assign={assign} />}
                <CloseButtonWrap>
                    <MainActionButton type="secondary" onClick={() => setShowAssignActionBar(false)}>
                        Close
                    </MainActionButton>
                </CloseButtonWrap>

                <CenterWrap>
                    <FilterButton filterMode={filterMode} onClick={onFilterClick} />
                </CenterWrap>

                <CenterWrap>
                    <SelectButtonsWrap>
                        <SelectButton onClick={onSelectAll} icon="specta-check-all" text="Select all" />
                        <SelectButton onClick={onClearAll} icon="specta-uncheck-all" text="Clear all" />
                    </SelectButtonsWrap>
                </CenterWrap>

                <CleaningTaskNameInput value={cleaningTaskNameInputValue} onChange={value => setCleaningTaskNameInputValue(value)} />

                <ActionButton
                    buttonText="Unassign"
                    textButtonText={`Selected: ${selectedAreasKeys.size}`}
                    onClick={onUnassignClick}
                    type="unassign"
                    disabled={disabled}
                    loading={unassignLoading}
                />
                <ActionButton
                    buttonText="Assign"
                    textButtonText="Same as last"
                    onClick={() => setUsersSelectModalIsOpen(true)}
                    textButtonOnClick={onSameAsLastClick}
                    type="assign"
                    disabled={disabled}
                />
            </Wrapper>
        </>
    )
}

export default AssignActionBar
