import { selector } from 'recoil'
import { areaAtom, bookingsAtom } from '../atoms'
import { currentUserAtom } from 'app/modules/Auth/atoms'
import { AreaStruct, UserStruct } from '@shared/firestore-structs'
import { selectedDateNumberSelector } from './selectedDateNumber'
import { getBookingNotes, getExtraComment } from 'app/modules/HousekeepingDashboard/state/selectors/commentsAndNotes'

export const extraCommentSelector = selector<string | null>({
    key: 'housekeeping-modal-extraCommentSelector',
    get: ({ get }) =>
        getExtraComment(get(areaAtom) as AreaStruct, get(currentUserAtom) as UserStruct, get(bookingsAtom), get(selectedDateNumberSelector))
})

export const bookingNotesSelector = selector<string | null>({
    key: 'housekeeping-modal-bookingNotesSelector',
    get: ({ get }) =>
        getBookingNotes(get(areaAtom) as AreaStruct, get(currentUserAtom) as UserStruct, get(bookingsAtom), get(selectedDateNumberSelector))
})
