import React, { useEffect } from 'react'
import { IconButton } from 'app/components/buttons/IconButton'
import { useRecoilState } from 'recoil'
import { priorityFilterAtom } from '../../state/atoms'
import AsyncStorage from '@react-native-community/async-storage'

function PriorityFilter() {
    const [filterPriority, setFilterPriority] = useRecoilState(priorityFilterAtom)

    useEffect(() => {
        AsyncStorage.getItem('housekeeping-filterPriority', (err, result) => {
            if (result && !err) setFilterPriority(JSON.parse(result))
        })
    }, [])

    function onClick() {
        setFilterPriority(!filterPriority)
        AsyncStorage.setItem('housekeeping-filterPriority', JSON.stringify(!filterPriority))
    }

    return (
        <IconButton
            icon="priority-low icon-lg"
            onClick={onClick}
            tooltip={{ text: filterPriority ? 'See all' : 'See only high priority', position: 'top' }}
            className={`btn btn-circle btn-icon btn-${filterPriority ? 'danger' : 'specta-gray'} ${filterPriority ? 'text-white' : ''}`}
        />
    )
}

export default PriorityFilter
