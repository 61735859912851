import { iOSColors } from '@shared/react-native-typography'
import React, { useContext } from 'react'
import { FeatureLockedSignal, SubscriptionCheck } from '../../../components/upgrade-signals'
import IssueRow from 'app/issues/IssuesBody/IssueRow'
import { ISSUE_ROW_CONTEXT_HOUSEKEEPING_MODAL } from '../../../utils/constants'
import { AuthContext } from 'app/modules/Auth/AuthContext'
import { FlatList, View } from 'react-native-web'
import { TaskScreenContext } from './TaskScreenContext'
import { IssueStruct } from '@shared/firestore-structs'

const Issues = ({ issues }: { issues: any }) => {
    const { currentUser } = useContext(AuthContext)

    const { setCurrentIssue, setShowIssueModal } = useContext(TaskScreenContext)

    function onPressIssueRow(issue: IssueStruct): void {
        setCurrentIssue(issue)
        setShowIssueModal(true)
    }

    return (
        <View
            style={{
                width: '100%',
                borderWidth: 0
            }}>
            <View
                style={{
                    flexDirection: 'row',
                    borderColor: 'red',
                    borderWidth: 0,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <span
                        style={{
                            fontWeight: '600',
                            color: iOSColors.black,
                            fontSize: 17 + 2
                        }}>
                        Issues
                    </span>
                    <span style={{ marginLeft: 6, marginBottom: 0, fontSize: 17 - 3 }}>({issues ? issues.length : ''})</span>
                </View>
                <SubscriptionCheck
                    featureId="issues"
                    onAvailable={() => null}
                    onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                        return (
                            <FeatureLockedSignal
                                showLock
                                isActive
                                onClick={() => {
                                    // TODO: Open upgrade modal
                                    // this.openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                }}
                            />
                        )
                    }}
                />
            </View>
            <FlatList
                contentContainerStyle={{
                    paddingTop: 12,

                    borderColor: 'blue'
                }}
                initialNumToRender={5}
                data={issues}
                renderItem={(item: any) => {
                    const currentIssue = item.item

                    return (
                        <IssueRow
                            disabled={false}
                            context={ISSUE_ROW_CONTEXT_HOUSEKEEPING_MODAL}
                            key={item.key}
                            issue={currentIssue}
                            checked={false}
                            onPressIssueRow={onPressIssueRow}
                            onPressHashTag={() => null}
                            onSearchPress={() => null}
                            currentUser={currentUser!}
                            addCheckedIssue={() => null}
                            removeCheckedIssue={() => null}
                        />
                    )
                }}
                showsVerticalScrollIndicator={false}
            />
        </View>
    )
}

export default Issues
