import * as c from '@shared/constants'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'
import * as chelpers from '@shared/cleaning-helpers'

export function pickHouskeepingStatusIcon(cleaningStatus, occupancy) {
    if (cleaningStatus === null && occupancy === null) {
        return require('../img/housekeeping-status-white.svg')
    }
    if (cleaningStatus === null) {
        return require('../img/housekeeping-status-black.svg')
    }
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        if (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80) {
            return require('../img/housekeeping-status-rose.svg')
        } else {
            return require('../img/housekeeping-status-red.svg')
        }
    }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING || cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        return require('../img/housekeeping-status-yellow.svg')
    }
    if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
        return require('../img/housekeeping-status-green.svg')
    }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        return require('../img/housekeeping-status-curry.svg')
    }
    if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
        return require('../img/housekeeping-status-purple.svg')
    }

    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        return require('../img/housekeeping-status-blue.svg')
    }

    if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        return require('../img/housekeeping-status-gray.svg')
    }

    return null
}

export const pickMinimizedWorkloadIcon = (cleaningStatus, occupancy, cleaningFrequency, useCleaningStatusColor = true) => {
    if (!occupancy) {
        return null
    }

    let iconObj = { name: null, color: null, extraFontSize: 0, marginTop: 0, marginBottom: 0, marginLeft: 0 }

    if (!cleaningStatus) {
        iconObj.color = iOSColors.black
    } else if (useCleaningStatusColor) {
        iconObj.color = chelpers.pickCleaningStatusColor(cleaningStatus, occupancy)
    } else {
        iconObj.color = iOSColors.white
    }

    if (cleaningFrequency === c.CLEANING_RULES_CHECKOUT && (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80)) {
        iconObj.name = 'specta-workload'
        iconObj.extraFontSize = 2
    }

    if (occupancy === c.OCCUPANCY_STAYOVER) {
        iconObj.name = 'specta-broom'
        iconObj.extraFontSize = 1
        iconObj.marginTop = 1
        iconObj.marginRight = 1
    } else if (occupancy === c.OCCUPANCY_STAYOVER_80) {
        if (cleaningFrequency === c.CLEANING_RULES_CHECKOUT) {
            return null
        }
        iconObj.name = 'specta-bed'
        iconObj.marginBottom = 1
    } else if (occupancy === c.OCCUPANCY_TURNOVER || occupancy === c.OCCUPANCY_CHECKIN) {
        iconObj.name = 'specta-check-in'

        iconObj.extraFontSize = 4
    } else if (occupancy === c.OCCUPANCY_CHECKOUT) {
        iconObj.name = 'specta-suitcase'
        iconObj.marginBottom = 1
    } else {
        iconObj.name = 'specta-workload'
        iconObj.marginTop = 0
        iconObj.extraFontSize = 2
    }

    return iconObj
}

export function pickOccupancyIcon(type) {
    let imgName = 'booking-note'
    if (type === c.OCCUPANCY_STAYOVER_80) {
        imgName = 'bed'
    } else if (type === c.OCCUPANCY_STAYOVER) {
        imgName = 'broom'
    } else if (type === c.OCCUPANCY_CHECKOUT) {
        imgName = 'suitcase'
    } else if (type === c.OCCUPANCY_CHECKIN) {
        imgName = 'check-in icon-lg'
    } else if (type === c.OCCUPANCY_VACANT) {
        imgName = 'vacant'
    } else if (type === c.OCCUPANCY_TURNOVER) {
        imgName = 'turnover icon-xl'
    }

    return imgName
}

export const pickCleaningStatusColorClass = (cleaningStatus, occupancy) => {
    if (cleaningStatus === null && occupancy === null) {
        return 'white'
    }
    if (cleaningStatus === null) {
        return 'dark'
    }
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        if (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80) {
            return 'specta-pink'
        } else {
            return 'specta-red'
        }
    }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING || cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        return 'specta-yellow'
    }
    if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
        return 'specta-green'
    }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        return 'specta-gold'
    }
    if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
        return 'specta-purple'
    }

    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        return 'specta-blue'
    }

    if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        return 'specta-gray-mid'
    }

    return null
}
