import React, { ChangeEvent } from 'react'
import { LoginFormWrapper } from './style'
import { Formik, Form, Field } from 'formik'
import Input from '../../../../components/forms/Input/index'
import RectangularButton from '../../../../components/buttons/RectangularButton'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment-timezone'
import * as Yup from 'yup'
import { GuestLoginWithLastNameAndRoomNamePayload } from '@shared/guest'

interface GuestLoginWIthLastNameAndRoomNameFormI {
    onLogin: (values: { lastName: string; roomName: string; checkInDate: string }) => void
    stateData: GuestLoginWithLastNameAndRoomNamePayload
}

const GuestLoginWIthLastNameAndRoomNameForm = ({ onLogin, stateData }: GuestLoginWIthLastNameAndRoomNameFormI) => {
    const loginScheme = Yup.object().shape({
        lastName: Yup.string()
            .test('isValid', 'Last name must be a valid string', value => {
                return !!value && value !== ''
            })
            .required('Last name is required'),
        roomName: Yup.string()
            .test('isValid', 'Room number must be a valid string', value => {
                return !!value && value !== ''
            })
            .required('Room number is required')
    })

    return (
        <LoginFormWrapper>
            <Formik
                validationSchema={loginScheme}
                enableReinitialize
                initialValues={{ lastName: stateData.lastName, roomName: stateData.roomName, checkInDate: stateData.checkInDate }}
                onSubmit={onLogin}>
                {({ handleSubmit, setFieldValue, values, errors }) => {
                    const { lastName, roomName } = values
                    const disabled = Object.keys(errors).length !== 0 || lastName === '' || roomName === ''

                    return (
                        <Form>
                            <Field
                                autoFocus
                                name="lastName"
                                component={Input}
                                value={lastName}
                                label={'Your Last Name'}
                                placeholder={'Your last name'}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('lastName', e.target.value)}
                            />
                            <Field
                                autoFocus
                                name="roomName"
                                component={Input}
                                value={roomName}
                                label={'Room Number'}
                                placeholder={'Your room number'}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('roomName', e.target.value)}
                            />
                            <div className={'form-group'}>
                                <label>Checkin Date</label>
                                <ReactDatePicker
                                    selected={new Date(values.checkInDate)}
                                    dateFormat="yyyy-MM-dd" // Set the date format
                                    onChange={date =>
                                        setFieldValue('checkInDate', moment(moment(date).startOf('day').format('YYYY-MM-DD').valueOf()))
                                    }
                                />
                            </div>

                            <div className={'btn-wrap'}>
                                <RectangularButton
                                    disabled={disabled}
                                    type="submit"
                                    width={'181px'}
                                    height={'64px'}
                                    onClick={handleSubmit as any}>
                                    Login
                                </RectangularButton>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </LoginFormWrapper>
    )
}

export default GuestLoginWIthLastNameAndRoomNameForm
