import React, { useMemo, useContext, useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import { AreasFilter } from './areas-filter/AreasFilter'
import { AreasTable } from './areas-table/AreasTable'
import { AreasContext } from '../AreasContext'
import { AreasGrouping } from './areas-grouping/AreasGrouping'
import Modal from 'react-modal'

import { UpgradeMessageContent } from '../../../components/upgrade-signals'
import * as styles from '../../../housekeeping/dashboard/styles'
import { SubscriptionContext } from '../../Subscriptions/SubscriptionContext'
import MainActionButton from '../../../components/buttons/MainActionButton'

export function AreasCard({ currentOrganization }) {
    const { ids, newAreaButtonClick, totalCount } = useContext(AreasContext)
    const { checkSubscription } = useContext(SubscriptionContext)
    const areasUIProps = useMemo(() => {
        return {
            ids: ids,
            newAreaButtonClick: newAreaButtonClick
        }
    }, [ids, newAreaButtonClick])

    const [hasSubscriptionAccess, setHasSubscriptionAccess] = useState(false)
    const [notAccessibleFeature, setNotAccessibleFeature] = useState(null)
    const [availableUpgrades, setAvailableUpgrades] = useState(null)

    const [showUpgradeMessage, setShowUpgradeMessage] = useState(false)

    useEffect(() => {
        const onAvailable = () => {
            setHasSubscriptionAccess(true)
        }
        const onNotAvailable = (notAccessible, availableUpgrad) => {
            setHasSubscriptionAccess(false)
            setNotAccessibleFeature(notAccessible)
            setAvailableUpgrades(availableUpgrad)
        }

        checkSubscription('unlimited-areas', onAvailable, onNotAvailable)
    }, [])

    return (
        <>
            <Modal
                isOpen={showUpgradeMessage}
                style={styles.upgradeMessageStyle}
                contentLabel="UpgradeMessages"
                onRequestClose={() => setShowUpgradeMessage(false)}>
                <UpgradeMessageContent
                    onClose={() => setShowUpgradeMessage(false)}
                    notAccessibleFeature={notAccessibleFeature}
                    availableUpgrades={availableUpgrades}
                    showCloseButton={true}
                />
            </Modal>
            <Card className="card-no-shadow">
                <CardHeader count={totalCount} title="Units">
                    <CardHeaderToolbar>
                        <MainActionButton
                            type="primary"
                            size="large"
                            onClick={() => {
                                if (!hasSubscriptionAccess) {
                                    setShowUpgradeMessage(true)
                                    return
                                }
                                areasUIProps.newAreaButtonClick()
                            }}>
                            New Unit
                        </MainActionButton>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <AreasFilter fields={['status', 'group', 'synced']} />
                    {areasUIProps.ids.length > 0 && <AreasGrouping />}
                    <AreasTable
                        setShowUpgradeMessage={setShowUpgradeMessage}
                        hasSubscriptionAccess={hasSubscriptionAccess}
                        currentOrganization={currentOrganization}
                    />
                </CardBody>
            </Card>
        </>
    )
}
