import React from 'react'
import * as c from '@shared/constants'
import { IconButton } from '../components/buttons/IconButton'
import { pickOccupancyIcon } from '../utils/housekeeping-helpers'

export const OccupancyFilterButton = props => {
    const getClassName = occupancy => {
        if (occupancy === c.OCCUPANCY_ALL) {
            return 'btn btn-circle btn-icon btn-specta-gray'
        }
        return 'btn btn-circle btn-icon btn-danger'
    }

    return (
        <IconButton
            className={getClassName(props.occupancy)}
            icon={pickOccupancyIcon(props.occupancy) + ' icon-lg'}
            tooltip={{
                text: 'Occupancy filter',
                position: 'top'
            }}
        />
    )
}

function pickOccupancyButtonImage(type) {
    let imgName = require('../img/occupancy-button.svg')
    if (type === c.OCCUPANCY_STAYOVER_80) {
        imgName = require('../img/prepare-room-button-red-background.svg')
    } else if (type === c.OCCUPANCY_STAYOVER) {
        imgName = require('../img/tidy-room-button-red-background.svg')
    } else if (type === c.OCCUPANCY_CHECKOUT) {
        imgName = require('../img/checkout-button-red-background.svg')
    } else if (type === c.OCCUPANCY_CHECKIN) {
        imgName = require('../img/check-in-button-red-background.svg')
    } else if (type === c.OCCUPANCY_VACANT) {
        imgName = require('../img/vacant-button-red-background.svg')
    } else if (type === c.OCCUPANCY_TURNOVER) {
        imgName = require('../img/turnover-button-red-background.svg')
    }

    return imgName
}
