import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AccountInformation } from './AccountInformation'
import { AccountSubscription } from './AccountSubscription'
import AccountIssues from './AccountIssues'
import { AccountsProvider } from '../../AccountsContext'
import { AccountTabs } from './AccountTabs'
import { SETTINGS } from '../../../../navigation/url-constants'

export default function AccountPage() {
    return (
        <AccountsProvider accountsUIEvents={{}}>
            <div>
                {/* <Route path="/account/upgrade/:upgradeType">
                {({ history, match }) => {
                    console.log(match)
                    return (
                        <UpgradeModal
                            show={match != null}
                            upgradeType={match && match.params.upgradeType}
                            onHide={() => {
                                history.push('/account/account-subscription')
                            }}
                        />
                    )
                }}
            </Route> */}
                <AccountTabs />
                <div className="col-12">
                    <Switch>
                        <Redirect from={SETTINGS.ACCOUNT.INDEX} exact={true} to={SETTINGS.ACCOUNT.INFORMATION} />
                        <Route path={SETTINGS.ACCOUNT.INFORMATION} component={AccountInformation} />
                        <Route path={SETTINGS.ACCOUNT.SUBSCRIPTION} component={AccountSubscription} />
                        {/* <Route path={SETTINGS.ACCOUNT.BILLING} component={Invoice} /> */}
                        <Route path={SETTINGS.ACCOUNT.ISSUES} component={AccountIssues} />
                    </Switch>
                </div>
            </div>
        </AccountsProvider>
    )
}
