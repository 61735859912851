import React, { useEffect } from 'react'
import LoadingScreen from '../../LoadingScreen'
import SignUpLayout from '../../SignUpLayout'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { bluelagoonSetup } from '../../cloud-functions'
import { compose } from 'redux'
import withIntegrationWorkflow from '../../../../utils/hocs/withIntegrationWorkflow'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import { errorModalConfigs } from '../../helpers'
import { INTEGRATIONS } from '../../../../utils/constants'

const BluelagoonCallback = ({
    newOrgKey,
    catchError,
    signupComplete,
    workflowInstance,
    storeWorkflowInstance,
    setComplete,
    currentIntegrationKey,
    newUserKey,
    settings,
    message,
    setStopAuthListener
}) => {
    const setupBluelagoon = async () => {
        const primaryKey = workflowInstance.getInput('apiKey')
        const setupBluelagoonIntegration = bluelagoonSetup()

        try {
            const { data } = await setupBluelagoonIntegration({
                orgId: newOrgKey,
                integration: INTEGRATIONS.BLUE_LAGOON.toLowerCase(),
                signupPayload: { organizationID: newOrgKey, key: primaryKey }
            })

            if (data.reason !== 'all good') {
                catchError(data.message, errorModalConfigs)
                return
            }

            console.log(workflowInstance)
            if ('test' in workflowInstance.values) {
                data.reason = 'test'
            }

            await signupComplete({ orgId: newOrgKey, userId: newUserKey, status: data })

            workflowInstance.completeStep(SIGN_UP.BLUE_LAGOON_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    async function setup() {
        setStopAuthListener(true)
        await setupBluelagoon()
    }

    useEffect(() => {
        setup()
    }, [])

    return (
        <SignUpLayout withModal>
            <LoadingScreen message={message} />
        </SignUpLayout>
    )
}

export default compose(withIntegrationWorkflow, withErrorCatching)(BluelagoonCallback)
