import React from 'react'
import { Wrap, Text, IconWrap } from './style'

interface Props {
    icon: string
    text: string
    onClick: () => void
}

function SelectButton({ icon, text, onClick }: Props) {
    return (
        <Wrap onClick={onClick}>
            <IconWrap>
                <i className={icon} />
            </IconWrap>
            <Text>{text}</Text>
        </Wrap>
    )
}

export default SelectButton
