// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form, Field, useFormik } from 'formik'
import * as Yup from 'yup'
import { Input, Select } from '../../../../../_metronic/_partials/controls'
import { CirclePicker } from 'react-color'
// import KTWizard from "../../../../../_metronic/_assets/js/components/wizard"
import '../../../../../_metronic/_assets/sass/components/_wizard.scss'
import '../../../../../_metronic/_assets/sass/pages/wizard/wizard-1.scss'
import RuleForm from './forms/RuleForm'
import TaskForm from './forms/TaskForm'
import ChecklistForm from './forms/ChecklistForm'
import LinkAreasForm from './forms/LinkAreasForm'
import ruleFormModel from './form-model/ruleFormModel'
import validationSchema from './form-model/validationSchema'
import Step from './navigation/Step'
import { useHistory } from 'react-router'
import MainActionButton from '../../../../components/buttons/MainActionButton'
import { useFeatureToggle } from '../../../../features'
import { SubscriptionContext } from '../../../Subscriptions/SubscriptionContext'
import { RuleEditDialog } from './RuleEditDialog'

// const steps = ["Setup Rule", "Task Details", "Create Checklist"]
const { formId, formField } = ruleFormModel

export function RuleEditFormWizard({ rule, btnRef, saveRule }) {
    const [activeStep, setActiveStep] = useState({ stepNumber: 1, key: 'setup', name: 'Setup rule', imageClass: 'specta-rules' })
    const [isOpen, setIsOpen] = useState(false)
    const [isModalConfirmed, setIsModalConfirmed] = useState(false)
    const currentValidationSchema = validationSchema[activeStep.stepNumber - 1]

    const { isFeatureInSubscription } = useContext(SubscriptionContext)

    function _renderStepContent(step, values, rule, handleChange, setFieldValue) {
        switch (step.stepNumber) {
            case 1:
                return <RuleForm formField={formField} values={values} handleChange={handleChange} setFieldValue={setFieldValue} />
            // case 1:
            //     return <TaskForm formField={formField} values={values} />
            case isFeatureInSubscription('checklists') && 2:
                return <ChecklistForm />
            case 3:
                return <LinkAreasForm />
            default:
                return <div>Not Found</div>
        }
    }

    const steps = [
        { stepNumber: 1, key: 'setup', name: 'Setup rule', imageClass: 'specta-rules' }
        // { stepNumber: 2, name: 'Assign to units', imageClass: 'specta-add-rule-to-room' }
        // { stepNumber: 2, name: 'Task Details', imageClass: 'flaticon-edit' },
        // { stepNumber: 3, name: 'Create Checklist', imageClass: 'flaticon-list-3' },
        // { stepNumber: 4, name: 'Assign to Areas', imageClass: 'flaticon2-hospital' }
    ]

    if (isFeatureInSubscription('checklists')) {
        steps.push({ stepNumber: 2, key: 'checklist', name: 'Create Checklist', imageClass: 'flaticon-list-3' })
    }

    steps.push({
        stepNumber: 3,
        key: 'assign',
        name: 'Assign to units',
        imageClass: 'specta-add-rule-to-room'
    })

    const isLastStep = activeStep.key == steps[steps.length - 1].key
    const history = useHistory()

    const _handleSubmit = (values, actions) => {
        if (isLastStep) {
            saveRule(values)
        } else {
            if (activeStep.stepNumber === 1 && values.trigger === 'booking' && values.inspection && !isModalConfirmed) {
                setIsOpen(true)
            } else {
                setActiveStep(steps[steps.findIndex(x => x.stepNumber === activeStep.stepNumber) + 1])
                actions.setTouched({})
                actions.setSubmitting(false)
            }
        }
    }

    const handleBack = () => {
        setActiveStep(steps[steps.findIndex(x => x.stepNumber === activeStep.stepNumber) - 1])
    }

    rule.id = rule.key

    return (
        <div className="wizard wizard-1" id="kt_wizard" data-wizard-state="first">
            <div className="wizard-nav">
                <div className="wizard-steps p-8 p-lg-10">
                    {steps.map(({ stepNumber, key, name, imageClass }, index) => (
                        <Step
                            index={index}
                            key={stepNumber + name}
                            stepNumber={stepNumber}
                            name={name}
                            imageClass={imageClass}
                            activeStep={activeStep.stepNumber}
                            stepsCount={steps.length}
                        />
                    ))}
                </div>
            </div>

            <Formik initialValues={rule} enableReinitialize validationSchema={currentValidationSchema} onSubmit={_handleSubmit}>
                {({ handleSubmit, handleChange, validateForm, values, resetForm, setFieldValue }) => (
                    <>
                        <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                            <div className="col-xl-12 col-xxl-7">
                                {_renderStepContent(activeStep, values, rule, validateForm, setFieldValue)}
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-5 pt-10">
                            <div className="mr-2 d-flex">
                                <div className="mr-2">
                                    <MainActionButton
                                        size="large"
                                        type="secondary"
                                        onClick={() => {
                                            history.goBack()
                                        }}>
                                        Cancel
                                    </MainActionButton>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="mr-2">
                                    {activeStep.stepNumber !== 0 && (
                                        <MainActionButton size="large" type="primary" onClick={handleBack}>
                                            <i className="specta-arrow-left text-white font-size-sm pr-2"></i>
                                            Back
                                        </MainActionButton>
                                    )}
                                </div>
                                <MainActionButton onClick={handleSubmit} size="large" type="primary">
                                    {isLastStep ? (
                                        'Save'
                                    ) : (
                                        <>
                                            Next <i className="specta-arrow-right text-white font-size-sm pl-2 pr-0"></i>
                                        </>
                                    )}
                                </MainActionButton>
                            </div>
                        </div>
                    </>
                )}
            </Formik>
            {isOpen ? <RuleEditDialog onHide={() => setIsOpen(false)} onConfirm={setIsModalConfirmed} /> : null}
        </div>
    )
}
