import React, { useEffect } from 'react'
import useHousekeepingFirestore from './useHousekeepingFirestore'
import Loader from 'app/components/loaders/Loader'
import withErrorCatching from 'app/utils/hocs/withErrorCatching'
import { CatchError } from 'app/types'
import { useRecoilState, useRecoilValue } from 'recoil'
import { dataLoadingAtom, showAssignActionBarAtom, subscriptionParamsAtom } from './state/atoms'
import HousekeepingBody from './HousekeepingBody'
import { Wrapper } from './style'
import SearchAndFilters from './SearchAndFilters'
import Modal from 'react-modal'
import { upgradeMessageStyle } from 'app/components/styled-components/modal'
import { UpgradeMessageContent } from 'app/components/upgrade-signals'
import AssignActionBar from './Header/AssignActionBar'
import Header from './Header'
import GroupsButtons from './GroupsButtons'
import { Subscription } from '@shared/subscriptions/subscription'

function HousekeepingDashboard({ catchError }: { catchError: CatchError }) {
    const [error] = useHousekeepingFirestore()

    const dataLoading = useRecoilValue(dataLoadingAtom)
    const showAssignActionBar = useRecoilValue(showAssignActionBarAtom)

    const [subscriptionParams, setSubscriptionParams] = useRecoilState(subscriptionParamsAtom)

    useEffect(() => {
        if (error) {
            console.error(error)
            catchError('Something went wrong. Please reload the page or contact support', {
                button: { text: 'Reload', onClick: () => window.location.reload() },
                contactSupportMessage: true
            })
        }
    }, [error])

    function closeUpgradeMessage() {
        setSubscriptionParams({ ...subscriptionParams, notAccessibleFeature: null, availableUpgrades: null, upgradeMessageIsOpen: false })
    }

    return (
        <Wrapper>
            <Modal
                style={upgradeMessageStyle}
                isOpen={subscriptionParams.upgradeMessageIsOpen}
                contentLabel="UpgradeMessages"
                onRequestClose={closeUpgradeMessage}>
                <UpgradeMessageContent
                    onClose={closeUpgradeMessage}
                    notAccessibleFeature={subscriptionParams.notAccessibleFeature}
                    availableUpgrades={subscriptionParams.availableUpgrades as Subscription[]}
                    showCloseButton
                    height={'100%'}
                />
            </Modal>
            <Header />
            <SearchAndFilters />
            <GroupsButtons />
            {dataLoading ? <Loader /> : <HousekeepingBody />}
            {showAssignActionBar && <AssignActionBar />}
        </Wrapper>
    )
}

export default withErrorCatching(HousekeepingDashboard)
