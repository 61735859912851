import firebase, { asFirebase, createIssue } from '../utils/firebase'
import * as dataObjects from '@shared/dataObjects'
import * as issueData from '@shared/issue-data'
import * as helpers from '@shared/helpers'
import { toast } from 'react-toastify'
import { toastSuccessStyle } from '../utils/styles'
import { IssueStruct, UserStruct } from '@shared/dataObjects'
import { HashTag, NewIssueValues, PaginationParams } from './types'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'
import { CSSObject } from 'styled-components'
import { search } from 'app/utils/formatters'

export const setDueDate = async (
    dueDateNumber: number,
    issue: dataObjects.IssueStruct,
    area: dataObjects.AreaStruct,
    currentUser: dataObjects.UserStruct
) => {
    const result = await issueData.setDueDateToIssue(asFirebase(firebase), {
        issueKey: issue.key,
        area,
        currentUser,
        dueDate: dueDateNumber,
        assignedTo: issue.assignedContacts,
        taskKey: issue.taskKey
    })

    toast.success('Due date set successfully', toastSuccessStyle)

    return { issue: { ...issue, ...result.issue, taskKey: result.task.key }, task: result.task }
}

export const filterIssues = (issues: IssueStruct[], searchValue: string, searchSetFunction: any) => {
    let issuesWithHastags = null
    if (searchValue.startsWith('#') && searchValue.indexOf(' ') === -1) {
        issuesWithHastags = helpers.filterIssuesWithHastags(issues, searchValue)
    } else {
        issuesWithHastags = issues
    }
    let searchedAndFilteredIssues = issuesWithHastags
    if (searchValue !== '') {
        searchedAndFilteredIssues = search<IssueStruct>(searchValue, issues, helpers.issueSearchSet)
    }
    return searchedAndFilteredIssues
}
export const paginateIssues = (issues: IssueStruct[], pagination: PaginationParams): IssueStruct[] => {
    const { firstRowOnPageIndex, lastRowOnPageIndex } = calculateAndSetPagination(pagination.limit, pagination.page, issues.length)
    return issues.slice(firstRowOnPageIndex, lastRowOnPageIndex)
}
const calculateAndSetPagination = (
    itemsCountPerPage: number,
    pageNumber: number,
    paginateTotalItemsCount: number
): { firstRowOnPageIndex: number; lastRowOnPageIndex: number } => {
    pageNumber -= 1
    let firstRowOnPageIndex = 0
    let lastRowOnPageIndex = 9
    firstRowOnPageIndex = pageNumber * itemsCountPerPage
    lastRowOnPageIndex = (pageNumber + 1) * itemsCountPerPage
    if (lastRowOnPageIndex > paginateTotalItemsCount) {
        lastRowOnPageIndex = paginateTotalItemsCount
    }
    return { firstRowOnPageIndex, lastRowOnPageIndex }
}
export const getHashTagsWithCount = (issues: IssueStruct[]): HashTag[] => {
    const hashWithCount: { [hashTag: string]: number } = {}
    issues?.forEach(issue => {
        const issueHashTags: string[] = helpers.getHashTags(issue.name)
        issueHashTags.forEach((hashTag: string) => {
            hashWithCount[hashTag] = hashWithCount[hashTag] === undefined ? 1 : hashWithCount[hashTag] + 1
        })
    })
    const hashTags: HashTag[] = []
    Object.keys(hashWithCount).forEach(key => {
        hashTags.push({
            name: key,
            count: hashWithCount[key]
        })
    })
    return hashTags.sort((a, b) => b.count - a.count)
}

export async function createNewIssue(values: NewIssueValues, currentUser: UserStruct) {
    try {
        const issue = await createIssue(asFirebase(firebase), {
            currentUser,
            name: values.title,
            area: values.unit.data,
            hashtags: values.hashtags,
            dueDate: values.dueDateNumber,
            priority: values.priority,
            images: values.images,
            assignedTo: values.assignedTo
        })

        return { issue, error: null }
    } catch (error: any) {
        return { issue: null, error: error.message }
    }
}

export const selectStyles = ({ width, inputHidden }: { width?: number; inputHidden?: boolean }) => ({
    control: (styles: object) => ({
        ...styles,
        width: width ? width : 560,
        borderWidth: 1,
        borderColor: '#e4e6ef',
        borderRadius: 6,
        boxShadow: 'none',
        ':hover': {
            borderWidth: 1,
            borderColor: colors.green_spectaflow,
            borderRadius: 6
        },
        ...(inputHidden && {
            height: 0,
            minHeight: 0,
            border: 'none'
        })
    }),
    option: (styles: object, state: { isDisabled: boolean; isFocused: boolean; isSelected: boolean }): CSSObject => {
        const { isSelected, isFocused, isDisabled } = state
        return {
            ...styles,
            backgroundColor: isSelected || isFocused ? iOSColors.customGray : '',
            color: colors.textPrimary,
            cursor: isDisabled ? 'default' : 'pointer'
        }
    },
    placeholder: (styles: object) => ({
        ...styles,
        color: '#bab9c7',
        ...(inputHidden && {
            display: 'none'
        })
    }),
    indicatorSeparator: (styles: object) => ({
        ...styles,
        backgroundColor: '#f4f5f6',
        width: 0
    }),
    indicatorsContainer: (styles: object) => ({
        ...styles,
        ...(inputHidden && {
            display: 'none'
        })
    }),
    valueContainer: (base: object) => ({
        ...base,
        maxHeight: 200,
        ...(inputHidden && {
            height: 0,
            width: 0,
            opacity: 0
        })
    }),
    menuList: (base: object) => ({
        ...base,
        padding: 0,
        minHeight: 200,
        height: 200
    })
})
