import { View, Image } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import React, { useContext } from 'react'
import { TaskScreenContext } from './TaskScreenContext'
import { pickHouskeepingStatusIcon } from '../../../utils/housekeeping-helpers'
import Timer from './timer'
import { BOX_HEIGHT, BOX_WIDTH, UNIT_HEADER_FONT_SIZE } from './consts'
import CleaningStatusSelect from '../../cleaning-status-select'
import { createActivity } from '@shared/activity-data'
import firebase, { asFirebase } from '../../../utils/firebase'
import * as analytics from '@shared/analytics'
import * as dataObjects from '@shared/dataObjects'
import moment from 'moment/moment'
import * as chelpers from '@shared/cleaning-helpers'
import * as c from '@shared/constants'
import * as taskData from '@shared/task-data'
import * as areaData from '@shared/area-data'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { AreaCleaningStatus } from '@shared/dataObjects'
import { DashboardContext } from '../DashboardContext'

const HousekeepingStatus = () => {
    const { area, currentTask, selectedDateNumber, setCurrentTask, setArea } = useContext(TaskScreenContext)
    const { updateAreasLocally } = useContext(DashboardContext)
    const { currentUser } = useContext(AuthContext)
    const onCleaningStatusChange = async (cleaningStatus: AreaCleaningStatus) => {
        const task = currentTask

        createActivity(
            asFirebase(firebase),
            area,
            currentUser!,
            selectedDateNumber,
            'cleaning-status',
            'cleaningStatus',
            area.cleaningStatus,
            cleaningStatus
        )

        await analytics.logEvent(
            asFirebase(firebase),
            analytics.AREA_CLEANING_STATUS[0],
            analytics.AREA_CLEANING_STATUS[1],
            area.cleaningStatus
        )

        const areaObject: any = {
            lastStatusChange: {
                user: dataObjects.getMiniUserObject(currentUser!),
                updated: moment().valueOf()
            }
        }

        if (chelpers.isToday(selectedDateNumber)) {
            areaObject.cleaningStatus = cleaningStatus
            if (task && cleaningStatus === c.CLEANING_STATUS_CLEAN) {
                await taskData.completeTask(asFirebase(firebase), currentUser!, task.key)
            }
        }

        await areaData.updateArea(asFirebase(firebase), area.key, areaObject, currentUser!)

        const updatedArea = { ...area, cleaningStatus }

        updateAreasLocally([{ key: area.key, cleaningStatus }])

        setArea(updatedArea)
    }

    const MARGIN_BETWEEN_UNITS = 12
    return (
        <View
            style={{
                zIndex: 2,
                borderColor: '#f4f5f6',
                borderRadius: 6,
                width: '95%',
                paddingVertical: 24,
                alignItems: 'center',
                // @ts-ignore
                boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, .2)',
                height: 250
            }}>
            <View>
                <View
                    style={{
                        borderWidth: 0,
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: MARGIN_BETWEEN_UNITS
                    }}>
                    <View
                        style={{
                            width: 26,
                            flexDirection: 'row',
                            marginLeft: 4,
                            alignItems: 'center',
                            // justifyContent: 'center',
                            borderWidth: 0,
                            borderColor: 'red'
                        }}>
                        <Image
                            style={{ width: 20, height: 16.59 }}
                            // @ts-ignore
                            source={pickHouskeepingStatusIcon(area.cleaningStatus, area.occupancy)}
                        />
                    </View>
                    <span
                        style={{
                            marginTop: 2,
                            fontWeight: '600',
                            color: iOSColors.black,
                            fontSize: UNIT_HEADER_FONT_SIZE
                        }}>
                        Housekeeping status
                    </span>
                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center', borderWidth: 0 }}>
                    <CleaningStatusSelect
                        selectedStatus={area.cleaningStatus}
                        width={BOX_WIDTH}
                        height={BOX_HEIGHT}
                        onChange={(item: any) => {
                            onCleaningStatusChange(item.value)
                        }}
                    />
                </View>
                {/*<View*/}
                {/*    style={{*/}
                {/*        marginTop: MARGIN_BETWEEN_UNITS + 6,*/}
                {/*        borderWidth: 0,*/}
                {/*        width: BOX_WIDTH*/}
                {/*    }}>*/}
                {/*    <Timer />*/}
                {/*</View>*/}
            </View>
        </View>
    )
}

export default HousekeepingStatus
