import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../../../../Auth/AuthContext'
import firebase from '../../../../../../utils/firebase'
import { Modal } from 'react-bootstrap'
import { ModalHeader } from '../../../../../../components/modals/ModalHeader'
import { numberFormat } from '../../../../../../utils/locale'
import { capitalize } from 'lodash'
import moment from 'moment-timezone'
import CardForm from './CardForm'
import { getEstimates } from '../api'
import ActivityView from '../../../../../../utils/loading-view'
import { useRecoilState } from 'recoil'
import { waitingForUpgradeAtom } from '../state'
import { httpsCallable } from '../../../../../../api'
import RectangularButton from '../../../../../../components/buttons/RectangularButton'
import MainActionButton from '../../../../../../components/buttons/MainActionButton/index'

const callUpgrade = async props => {
    const { orgKey, planItemId, currencyCode, paymentIntent, plan } = props
    const subscription = httpsCallable('updateSubscription')
    await subscription({ organizationKey: orgKey, priceId: planItemId, planId: plan.key, currencyCode, paymentIntent })
}

const createPaymentIntent = async (amount, currencyCode) => {
    const intent = httpsCallable('createPaymentIntent')
    return intent({ amount, currencyCode })
        .then(result => {
            return result.data
            // return { data: result }
        })
        .catch(error => {
            console.error('error', error)
        })
}

const PaymentModal = props => {
    const {
        show,
        onHide,
        currencyCode,
        setSubscriptionChangeAction,
        priceObj,
        planName,
        planItemId,
        setSuccess,
        setSubscriptionChanged
    } = props
    const [loading, setLoading] = useState(false)
    const [dataLoading, setDataLoading] = useState(true)
    const [estimate, setEstimate] = useState()
    const [error, setError] = useState()
    const [name, setName] = useState('')
    const [paymentIntent, setPaymentIntent] = useState()
    const [cardNumberError, setCardNumberError] = useState(true)
    const [cardExpiryError, setCardExpiryError] = useState(true)
    const [cardCVVError, setCardCVVError] = useState(true)
    const [waitingForUpgrade, setWaitingForUpgrade] = useRecoilState(waitingForUpgradeAtom)
    const {
        currentUser,
        currentOrganization: {
            hasValidPaymentMethod,
            subscription,
            pms,
            brand,
            subscriptionTrialDone,
            areasSyncedTotal,
            billingUnitsOverride
        },
        orgSubscription
    } = useContext(AuthContext)

    useEffect(() => {
        if (subscription === 'pro') {
            setLoading(false)
            setSubscriptionChangeAction('update')
            setSubscriptionChanged(true)
            setSuccess(true)
            onHide()
        }
    }, [subscription])

    const handleUpdate = async () => {
        setWaitingForUpgrade(true)
        if (!handleWithCard) {
            setLoading(true)
            await callUpgrade(props)
        } else {
            handlePayment()
        }
    }

    useEffect(() => {
        const getData = async () => {
            const e = await getEstimates(
                [planItemId],
                currencyCode,
                orgSubscription.key,
                billingUnitsOverride ?? areasSyncedTotal ?? 1,
                true
            )
            setEstimate(e[0])
            const pi = await createPaymentIntent(e[0].totalAmount, currencyCode)
            setPaymentIntent(pi)
            setDataLoading(false)
        }

        getData()

        return () => {}
    }, [])

    const cardRef = React.createRef()

    const setCardErrorState = state => {
        switch (state.field) {
            case 'number':
                setCardNumberError(!state.complete)
                break
            case 'expiry':
                setCardExpiryError(!state.complete)
                break
            case 'cvv':
                setCardCVVError(!state.complete)
                break
            default:
                break
        }
    }

    let trialDaysLeft
    if (estimate && !subscriptionTrialDone && priceObj.startingPrice > 0) {
        let start = moment.unix(estimate.from).startOf('day')
        let end = moment.unix(estimate.to).startOf('day')

        trialDaysLeft = moment(end).diff(moment(start), 'day')
    }

    const handleWithCard = brand === 'sweeply' && (subscriptionTrialDone || brand === 'godopronto') ? true : false //pms !== 'beds24' || (pms !== 'beds24' && !hasValidPaymentMethod)
    const isTrial =
        priceObj.trial.available && estimate && moment().add(priceObj.trial.period, priceObj.trial.unit).isSameOrAfter(moment(estimate.to))

    const handlePayment = () => {
        setLoading(true)
        const firstName = name.split(' ')[0]
        const lastName = name.split(' ').slice(1).join(' ')
        const additionalData = {
            email: currentUser.email,
            phone: currentUser.phone,
            billingAddress: {
                firstName: firstName,
                lastName: lastName
            }
            // email: 'a@ac.com'
        }

        cardRef.current
            .authorizeWith3ds(paymentIntent, additionalData)
            .then(async authorizedPaymentIntent => {
                await callUpgrade({ paymentIntent: authorizedPaymentIntent, ...props })
            })
            .catch(e => {
                console.error('Error occured', e)
                // setError(e)
                setError('Payment refused, please try another card!')
                setLoading(false)
                setWaitingForUpgrade(false)
            })
    }

    const isDisabled =
        waitingForUpgrade ||
        dataLoading ||
        loading ||
        (handleWithCard && (cardNumberError || cardExpiryError || cardCVVError || name.length === 0))
    const isLoading = waitingForUpgrade || loading

    return (
        <Modal size="md" show={show} onHide={onHide} centered>
            <ModalHeader title={'Review your subscription'} onHide={onHide} />
            <Modal.Body>
                {!dataLoading && (
                    <>
                        <h6 className="font-weight-bolder mb-3">Subscription details:</h6>
                        {isTrial && trialDaysLeft !== undefined && trialDaysLeft > 0 && (
                            <span className="text-warning font-weight-bolder">{trialDaysLeft} day(s) free and then:</span>
                        )}
                        <div className="text-dark-50 line-height-lg">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="pl-0 font-weight-bold text-muted text-uppercase">Subscription</th>
                                            <th className="text-right font-weight-bold text-muted text-uppercase">Units</th>
                                            <th className="text-right font-weight-bold text-muted text-uppercase">Price</th>
                                            <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {estimate.line_items.map((item, index) => ( */}
                                        <tr key={estimate.id} className={`font-weight-boldest border-bottom-0}`}>
                                            <td className="border-0 pl-0 pt-7 d-flex align-items-center">{planName}</td>
                                            <td className="text-right pt-7 align-middle">{estimate.quantity}</td>
                                            <td className="text-right pt-7 align-middle">
                                                {numberFormat(estimate.unitAmount / 100, currencyCode)}
                                            </td>
                                            <td className="text-primary pr-0 pt-7 text-right align-middle">
                                                {numberFormat(estimate.totalAmount / 100, currencyCode)}
                                            </td>
                                        </tr>
                                        {/* ))} */}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td className="font-weight-bolder text-right">Bills</td>
                                            <td className="font-weight-bolder text-right pr-0">{capitalize(props.periodUnit) + 'ly'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0 pt-0"></td>
                                            <td className="border-0 pt-0"></td>
                                            <td className="border-0 pt-0 font-weight-bolder text-right">Due on</td>
                                            <td className="border-0 pt-0 font-weight-bolder text-right pr-0">
                                                {moment.unix(estimate.from).format('D, MMM YYYY')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-0 pt-0"></td>
                                            <td className="border-0 pt-0"></td>
                                            <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-right">Grand Total</td>
                                            <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-success text-right pr-0">
                                                {numberFormat(estimate.totalAmount / 100, currencyCode)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="d-flex font-size-sm text-warning justify-content-end">
                                    {`* prorated charges for period ${moment.unix(estimate.from).format('D, MMM YYYY')} - ${moment
                                        .unix(estimate.to)
                                        .format('D, MMM YYYY')}`}
                                </div>
                            </div>
                        </div>
                        {handleWithCard && (
                            <CardForm setCardErrorState={setCardErrorState} cardRef={cardRef} setName={setName} {...props} />
                        )}
                        {error && <span className="text-danger font-weight-bolder">{error}</span>}
                    </>
                )}
                {dataLoading && (
                    <div className="mb-20">
                        <ActivityView />
                    </div>
                )}
            </Modal.Body>
            <div className="container mb-5 d-flex justify-content-center">
                <MainActionButton type="submit" disabled={isDisabled} size="xl" onClick={handleUpdate}>
                    {isLoading && (
                        <span>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="sr-only">Loading...</span>
                        </span>
                    )}
                    {!isLoading && 'Subscribe'}
                </MainActionButton>
            </div>
        </Modal>
    )
}

export default PaymentModal
