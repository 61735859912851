import React, { useState, useEffect, useContext } from 'react'
// import { shallowEqual, useSelector } from "react-redux";
import { Modal } from 'react-bootstrap'
import { ModalProgressBar } from '../../../../../_metronic/_partials/controls'
import { AccountsContext } from '../../AccountsContext'
import { ModalHeader } from '../../../../components/modals/ModalHeader'

export function AccountEditDialogHeader({ id, onHide }) {
    // Customers Redux state
    // const { customerForEdit, actionsLoading } = useSelector(
    //   (state) => ({
    //     customerForEdit: state.customers.customerForEdit,
    //     actionsLoading: state.customers.actionsLoading,
    //   }),
    //   shallowEqual
    // );

    const { accountForEdit, actionsLoading } = useContext(AccountsContext)

    const [title, setTitle] = useState('')
    // Title couting
    useEffect(() => {
        let _title = id ? '' : 'New account'
        if (accountForEdit && id) {
            _title = `Edit account`
        }

        setTitle(_title)
        // eslint-disable-next-line
        // }, [customerForEdit, actionsLoading]);
    }, [accountForEdit])

    return (
        <>
            <ModalHeader title={title} onHide={onHide} />
        </>
    )
}
