import React from 'react'
import { FieldFeedbackLabel } from './FieldFeedbackLabel'

export const getFieldCSSClasses = (touched, errors) => {
    const classes = ['form-control form-control-iconless']
    if (touched && errors) {
        classes.push('is-invalid')
    }

    // if (touched && !errors) {
    //     classes.push('is-valid')
    // }

    return classes.join(' ')
}

export function Input({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors },
    label,
    withFeedbackLabel = true,
    customFeedbackLabel,
    onlyError = true,
    type = 'text',
    ...props
}) {
    return (
        <>
            {label && (
                <label className="font-weight-bold text-dark-65">
                    {label}:{props.required && <span className="text-danger">*</span>}
                </label>
            )}

            <input type={type} className={getFieldCSSClasses(touched[field.name], errors[field.name])} {...field} {...props} />

            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={label}
                    type={type}
                    customFeedbackLabel={customFeedbackLabel}
                    onlyError={onlyError}
                />
            )}
        </>
    )
}
