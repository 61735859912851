import React from 'react'
import { bookingNotesSelector } from './state/selectors/commentsAndNotes'
import { useRecoilValue } from 'recoil'
import BookingNotesIcon from '@shared/img/cleaning-status/booking-note-black.svg?react'
import Notes from './Notes'

function BookingNotesSection() {
    const bookingNotes = useRecoilValue(bookingNotesSelector)

    if (!bookingNotes) return null
    return <Notes title={'Booking notes'} notes={bookingNotes} icon={{ width: 17, height: 17.49, Icon: <BookingNotesIcon /> }} />
}

export default BookingNotesSection
