import React from 'react'
import { View, Text } from 'react-native-web'
import { iOSColors, webWeights } from '@shared/react-native-typography'
import * as colors from '@shared/colors'

import { fadeIn, bounceInDown } from 'react-animations'
import Radium, { StyleRoot } from 'radium'

const animationStyles = {
    toastFadeIn: {
        animation: 'x 1.25s',
        // animationName: Radium.keyframes(fadeIn, 'fadeIn')
        animationName: Radium.keyframes(bounceInDown, 'bounceInDown')
    }
}

export const Toast = props => {
    let color = iOSColors.black
    let opacity = 0.85

    const paddingVertical = 10

    return (
        <StyleRoot>
            <div className="test" style={animationStyles.toastFadeIn}>
                <View
                    style={{
                        opacity: opacity,
                        backgroundColor: color,
                        paddingVertical: paddingVertical,

                        paddingHorizontal: 16,
                        borderRadius: 10,
                        width: 170
                    }}>
                    <span
                        style={{
                            color: props.textColor ? props.textColor : colors.white,
                            fontSize: 14,
                            fontWeight: '500'
                        }}>
                        {props.message}
                    </span>
                </View>
            </div>
        </StyleRoot>
    )
}
