import React, { useContext, useEffect } from 'react'
// import { shallowEqual, useSelector } from "react-redux";
import { LoadingDialog } from '../../../../../_metronic/_partials/controls'
import { RulesContext } from '../../RulesContext'

export function RulesLoadingDialog() {
    // Customers Redux state
    // const { isLoading } = useSelector((state) => ({ isLoading: state.customers.listLoading }), shallowEqual);
    const { listLoading } = useContext(RulesContext)
    // looking for loading/dispatch
    useEffect(() => {}, [listLoading])
    return <LoadingDialog listLoading={listLoading} text="Loading ..." />
}
