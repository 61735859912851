import styled from 'styled-components'
import * as colors from '@shared/colors'

export const Wrap = styled.div`
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: 0.5fr 3.5fr 1.4fr;
`

export const Header = styled.div`
    padding: 35px 25px 0 25px;
`

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
        width: 100%;
    }
`

export const Footer = styled.div`
    padding: 25px 35px;
    background-color: ${colors.light_gray_spectaflow};
    display: flex;
    flex-direction: column;

    > h1 {
        font-weight: 600;
        font-size: 2rem;
    }

    > span {
        font-weight: 400;
        font-size: 1.3rem;
        color: ${colors.gray};
        margin-bottom: 15px;
    }
`
