import styled from 'styled-components'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'

export const Wrapper = styled.div`
    background-color: ${colors.white};
    width: 50%;
    height: 60%;
    display: flex;
    flex-direction: column;
    position: relative;
`

export const HeaderWrap = styled.div`
    width: 100%;
    padding: 24px 24px 0 32px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`

export const TitleWrap = styled.div`
    display: flex;
    column-gap: 6px;
    align-items: center;
    padding-top: 26px;

    h2 {
        margin: 0;
        font-size: 1.692rem;
        color: ${colors.black_sweeply};
        font-weight: 600;
    }

    span {
        font-size: 1.231rem;
        color: ${colors.gray};
        font-weight: 600;
    }
`

export const SearchWrap = styled.div`
    max-width: 220px;
    padding-left: 32px;
    margin-bottom: 12px;
`

export const UsersList = styled.div`
    padding: 0 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
`

export const UserItem = styled.div<{ selected: boolean }>`
    min-height: 50px !important;
    border-bottom: 1px solid ${iOSColors.customGray};
    display: flex;
    align-items: center;
    column-gap: 16px;
    cursor: pointer;
    padding-left: 5px;
    opacity: ${({ selected }) => (selected ? 0.5 : 1)};

    > span {
        font-size: 1.231rem;
        color: ${colors.black_sweeply};
    }
`

export const FooterWrap = styled.div`
    width: 100%;
    border-top: 1px solid ${iOSColors.lightGray};
    height: 79px;
    background-color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 28px;
    position: absolute;
    bottom: 0;
    left: 0;
`

export const SelectedUsers = styled.div`
    display: flex;
    height: 48px;
    align-items: center;
    column-gap: 8px;
`

export const ClearUsersButtonWrap = styled.div`
    position: relative;
    bottom: 15px;
`

export const ButtonWrap = styled.div`
    display: flex;
    column-gap: 8px;
    align-items: center;
`
