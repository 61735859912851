import React, { useState } from 'react'
import { Formik, Form, Field, useFormikContext, FieldArray, useField } from 'formik'

const BottomBorderInput = ({
    field, // { name, value, onChange, onBlur }
    arrayHelpers,
    type = 'text',
    ...props
}) => {
    const [borderColor, setBorderColor] = useState('')
    return (
        <input
            placeholder="Put your checklist item name here"
            style={{ outline: 0, borderWidth: '0 0 2px', borderColor: borderColor }}
            type={type}
            {...field}
            {...props}
            onFocus={e => {
                setBorderColor('#1BC5BD')
            }}
            onBlur={e => {
                if (e.currentTarget.value === '') {
                    setBorderColor('')
                }
            }}
            onKeyUp={e => {
                if (e.keyCode === 13) {
                    arrayHelpers.push('')
                }
            }}
            autoFocus
        />
    )
}

export default function ChecklistForm(props) {
    const { values } = useFormikContext()

    if (values.checklistTasks.length === 0) {
        values.checklistTasks.push('')
    }

    return (
        <div className="pb-5">
            <FieldArray
                name="checklistTasks"
                render={arrayHelpers => (
                    <div>
                        {values.checklistTasks &&
                            values.checklistTasks.length > 0 &&
                            values.checklistTasks.map((checklistTask, index) => (
                                <div key={index} className="d-flex align-items-center mb-10">
                                    <span className="bullet bullet-bar bg-specta-blue align-self-stretch"></span>
                                    <label className="checkbox checkbox-lg checkbox-specta-blue checkbox-single flex-shrink-0 m-0 mx-4">
                                        <input type="checkbox" name="" value="1" disabled="disabled" />
                                        <span></span>
                                    </label>
                                    <div className="d-flex flex-column flex-grow-1">
                                        <Field name={`checklistTasks.${index}`} arrayHelpers={arrayHelpers} component={BottomBorderInput} />
                                    </div>
                                    <div className="dropdown-inline dropdown" onClick={() => arrayHelpers.remove(index)}>
                                        <a className="btn btn-clean btn-hover-light-primary btn-sm btn-icon">
                                            <i className="ki ki-bold-close"></i>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        {/* <button type="button" onClick={() => arrayHelpers.push("")}>
							Add checklist item
						</button> */}
                    </div>
                )}
            />
        </div>
    )
}
