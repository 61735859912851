import React from 'react'
import { View } from 'react-native-web'
import { HoverableImageButton } from './hoverable-image-button'
import * as uc from '../../utils/constants'

export const CloseModalButton = ({
    dimension,
    close,
    isWhite,
    style
}: {
    dimension?: number
    close: () => void
    isWhite?: boolean
    style?: any
}) => {
    const imgSource = isWhite ? require('../../img/close-modal-white.svg') : require('../../img/close-modal-mid-gray.svg')
    const dim = dimension ? dimension : 15
    return (
        <View style={style ? style : { position: 'absolute', right: 24, top: 24, zIndex: 3000 }}>
            <HoverableImageButton
                dimension={dim}
                hoverOpacity={uc.HOVER_OPACITY}
                imageSource={imgSource}
                onPress={() => {
                    close()
                }}
            />
        </View>
    )
}
