const getLang = () => {
    if (navigator.languages != undefined) return navigator.languages[0]
    return navigator.language
}

export const numberFormat = (value, currencyCode) =>
    new Intl.NumberFormat(getLang(), {
        style: 'currency',
        currency: currencyCode,
        maximumSignificantDigits: 3
    }).format(value)
