import React, { useContext, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import { Input } from '../../../../../../_metronic/_partials/controls'
import { Accordion, Card, Button } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers'
import SVG from 'react-inlinesvg'
import moment from 'moment-timezone'
import { resolveRule } from '@shared/rule-resolver'
import { Area } from '@shared/area'
import { CLEANING_STATUS_DIRTY, CLEANING_STATUS_WAITING_FOR_CHECKOUT, OCCUPANCY_CHECKOUT } from '@shared/txt-constants'
import { pickCleaningStatusColorClass, pickOccupancyIcon } from '../../../../../utils/housekeeping-helpers'
import { AuthContext } from '../../../../Auth/AuthContext'

export default function BookingRepeatForm(props) {
    const { values } = useFormikContext()
    const [frequency, setFrequency] = useState(values.repeatInterval !== undefined ? values.repeatInterval : 1)
    const [repeatOffsetStart, setRepeatOffsetStart] = useState(values.repeatOffsetStart !== undefined ? values.repeatOffsetStart : 1)
    const [repeatOffsetEnd, setRepeatOffsetEnd] = useState(values.repeatOffsetEnd !== undefined ? values.repeatOffsetEnd : 1)
    const [accordionIcon, setAccrordionIcon] = useState('ki-arrow-next')
    const { currentOrganization } = useContext(AuthContext)

    const bookingDates = [...Array(Math.ceil(5 * (values.repeatInterval > 0 ? values.repeatInterval : 1)))].map((e, i) => {
        return moment().add(i, 'days').startOf('day').valueOf()
    })

    const results = bookingDates.map(d => {
        const resultObject = resolveRule(
            values,
            [
                {
                    bookingDates: bookingDates,
                    checkinDate: bookingDates[0],
                    checkoutDate: bookingDates[bookingDates.length - 1]
                }
            ],
            d,
            currentOrganization,
            {},
            {}
        )

        if (resultObject && Object.keys(resultObject).length > 0) {
            resultObject.showIcon = true
        }
        if (resultObject.occupancy) {
            resultObject.cleaningStatus = CLEANING_STATUS_DIRTY
        }
        if (resultObject.cleaningStatus && resultObject.cleaningStatus === CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
            resultObject.occupancy = OCCUPANCY_CHECKOUT
        }
        const area = new Area()
        resultObject.date = d
        return Object.assign(area, resultObject)
    })

    return (
        <div>
            {values.repeatType === 'custom' && (
                <div>
                    <div className="form-group row">
                        <label className="col-2 col-form-label font-weight-bold">Start:</label>
                        <div className="col-2">
                            <Field
                                name="repeatOffsetStart"
                                type="number"
                                component={Input}
                                value={repeatOffsetStart}
                                onChange={e => {
                                    console.log('on change')
                                    setRepeatOffsetStart(e.currentTarget.value)
                                    values.repeatOffsetStart = parseInt(e.currentTarget.value)
                                }}
                                placeholder="Pick a number"
                                min="0"
                                max={10}
                                customFeedbackLabel={' '}
                            />
                        </div>
                        <label className="col-3 col-form-label font-weight-bold">day(s) from checkin</label>
                    </div>
                    <div className="form-group row">
                        <label className="col-2 col-form-label font-weight-bold">End:</label>
                        <div className="col-2">
                            <Field
                                name="repeatOffsetEnd"
                                type="number"
                                component={Input}
                                value={repeatOffsetEnd}
                                onChange={e => {
                                    setRepeatOffsetEnd(e.currentTarget.value)
                                    values.repeatOffsetEnd = parseInt(e.currentTarget.value)
                                }}
                                placeholder="Pick a number"
                                min="0"
                                max={10}
                                customFeedbackLabel={' '}
                            />
                        </div>
                        <label className="col-3 col-form-label font-weight-bold">day(s) from checkout</label>
                    </div>
                    <div className="form-group row">
                        <label className="col-2 col-form-label font-weight-bold">Repeat every:</label>
                        <div className="col-2">
                            <Field
                                name="repeatInterval"
                                type="number"
                                component={Input}
                                placeholder="Pick a number"
                                value={frequency}
                                onChange={e => {
                                    setFrequency(e.currentTarget.value)
                                    values.repeatInterval = parseInt(e.currentTarget.value)
                                    setRepeatOffsetStart(e.currentTarget.value)
                                    values.repeatOffsetStart = parseInt(e.currentTarget.value)
                                }}
                                min="1"
                                max={10}
                                customFeedbackLabel={' '}
                            />
                        </div>
                        <label className="col-3 col-form-label font-weight-bold">day(s)</label>
                    </div>
                </div>
            )}
            {values.trigger == 'booking' && !values.inspection && (
                <div className="form-group mt-10">
                    <h3 className="font-weight-bold">Example:</h3>
                    <div className="row mt-5">
                        {results.map((e, i) => {
                            const title = i === 0 ? 'Check in' : i === bookingDates.length - 1 ? 'Checkout' : i + 1
                            const showIcon = e.showIcon
                            const colorClass = e.showIcon
                                ? pickCleaningStatusColorClass(e.cleaningStatus, e.occupancy) + ' text-white'
                                : 'gray-200'
                            return (
                                <span key={i} className="col-4 col-md-2 flex-center d-flex">
                                    <div className={`rounded mb-5 text-center bg-${colorClass}`} style={{ height: 80, width: 80 }}>
                                        <div style={{ marginTop: showIcon ? '10%' : '37%' }}>
                                            {showIcon && (
                                                <div>
                                                    <i className={`specta-${pickOccupancyIcon(e.occupancy)} text-white`} />
                                                </div>
                                            )}
                                            <div className="font-weight-bolder">{title}</div>
                                        </div>
                                    </div>
                                </span>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}
