import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { dividedTasksSelector } from '../state/selectors/dividedTasks'
import { newTaskModalisOpenAtom } from '../state/atoms'
import Column from './Column'
import { iOSColors } from '@shared/react-native-typography'
import * as c from '@shared/constants'
import NoItemsMessage from 'app/components/NoItemsMessage'
import noTasksIcon from '@shared/img/no-tasks-beach.svg'
import { TasksSectionTitle } from '@shared/task-types'

export default function TasksColumns() {
    const {
        dueTodayTasks,
        assignedTasks,
        doneTasks,
        allTasks,
        overdueAssignedTasks,
        overdueDueTodayTasks,
        noDueDateTasks
    } = useRecoilValue(dividedTasksSelector)

    const setNewTaskModalIsOpen = useSetRecoilState(newTaskModalisOpenAtom)

    const openNewTaskModal = () => {
        setNewTaskModalIsOpen(true)
    }
    return (
        <>
            <div className="row">
                <Column
                    title={TasksSectionTitle.DUE_TODAY}
                    lane={c.TASK_OPEN}
                    count={dueTodayTasks.length + overdueDueTodayTasks.length}
                    borderColor={iOSColors.black}
                    tasks={dueTodayTasks}
                    overdueTasks={overdueDueTodayTasks}
                    totalCount={dueTodayTasks.length}
                />

                <Column
                    title={TasksSectionTitle.ASSIGNED}
                    lane={c.TASK_ASSIGNED}
                    count={assignedTasks.length + overdueAssignedTasks.length}
                    borderColor={iOSColors.yellow}
                    tasks={assignedTasks}
                    overdueTasks={overdueAssignedTasks}
                    totalCount={assignedTasks.length}
                    noDueDateTasks={noDueDateTasks}
                />

                <Column
                    title={TasksSectionTitle.DONE}
                    lane={c.TASK_COMPLETE}
                    count={doneTasks.length}
                    borderColor={iOSColors.green}
                    tasks={doneTasks}
                    totalCount={doneTasks.length}
                />
            </div>

            {allTasks.length === 0 && (
                <NoItemsMessage
                    icon={noTasksIcon as string}
                    message={'No tasks'}
                    row2={'Try a creating a '}
                    row3={'or assigning a task'}
                    actionText={'new task'}
                    onActionClick={openNewTaskModal}
                />
            )}
        </>
    )
}
