import moment from 'moment-timezone'

function myData(date, status) {
    const m = moment(date)
    this.date = m.format('YYYY-MM-DD')
    this.weekOfMonth = Math.ceil(m.date() / 7)
    this.status = status
}
myData.prototype.valueOf = function () {
    return this.date + this.status
}
export const prepareDimensions = cf => {
    const creators = cf.dimension(r => r.creatorName, false)
    const areas = cf.dimension(r => r.area.name, false)
    const dates = cf.dimension(r => {
        const res = [new myData(r.updated, r.status)]
        if (r.status !== 'open') res.push(new myData(r.created, 'open'))
        return res
    }, true)
    return { creators, areas, dates }
}

export const prepareResults = (areasDimension, creatorsDimension, dateDimension, cutoff, grouping) => {
    const areasDim =
        areasDimension.group &&
        areasDimension
            .group()
            .reduce(
                (acc, v) => {
                    let res = {}
                    if (v.updated >= v.cutoffMoment) {
                        res[v.status] = acc[v.status] + 1
                    }
                    return { ...acc, ...res }
                },
                (acc, v) => {
                    let res = {}
                    if (v.updated >= v.cutoffMoment) {
                        res[v.status] = acc[v.status] - 1
                    }
                    return { ...acc, ...res }
                },
                () => ({ open: 0, completed: 0, deleted: 0 })
            )
            .order(r => r.open * 100 + r.completed * 10 + r.deleted)
            .top(7)
    const creatorsDim =
        creatorsDimension.group &&
        creatorsDimension
            .group()
            .reduce(
                (acc, v) => {
                    let res = {}
                    if (v.updated >= v.cutoffMoment) {
                        res[v.status] = acc[v.status] + 1
                    }
                    return { ...acc, ...res }
                },
                (acc, v) => {
                    let res = {}
                    if (v.updated >= v.cutoffMoment) {
                        res[v.status] = acc[v.status] - 1
                    }
                    return { ...acc, ...res }
                },
                () => ({ open: 0, completed: 0, deleted: 0 })
            )
            .order(r => r.open * 100 + r.completed * 10 + r.deleted)
            .top(7)

    let dateDim =
        dateDimension.group &&
        dateDimension
            .group()
            .reduce(
                (acc, v, isFiltered, groupIdx, k) => {
                    const key = k || groupIdx
                    const res = { [key.status]: acc[key.status] + 1 }
                    return { ...acc, ...res }
                },
                (acc, v, isFiltered, groupIdx, k) => {
                    const key = k || groupIdx
                    const res = { [key.status]: acc[key.status] - 1 }
                    return { ...acc, ...res }
                },
                k => ({ open: 0, completed: 0, deleted: 0 })
            )
            .all()

    if (dateDim) {
        const d = dateDim
            .sort((a, b) => (a.key.date < b.key.date ? -1 : 1))
            .reduce((acc, v) => {
                const group = v.key.date
                const res = acc[group] || { open: 0, completed: 0, deleted: 0 }
                const newVal = {
                    [group]: {
                        date: v.key.date,
                        open: res.open + v.value.open,
                        completed: res.completed + v.value.completed,
                        deleted: res.deleted + v.value.deleted
                    }
                }
                return { ...acc, ...newVal }
            }, {})

        const result = Object.keys(d)
            .sort()
            .map(k => ({ key: k, value: d[k] }))
            .reduce(
                (acc, v) => {
                    return {
                        open: acc.open + v.value.open - v.value.completed - v.value.deleted,
                        result: acc.result.concat({ ...v, ...{ acc: { open: acc.open - v.value.deleted - v.value.completed } } })
                    }
                },
                { open: 0, result: [] }
            )
        dateDim = result.result.filter(r => moment(r.key).valueOf() >= cutoff)
    }
    return { dateDim, creatorsDim, areasDim }
}
