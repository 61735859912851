import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    height: 38.4px;
    margin-bottom: 34.8px;
    display: grid;
    grid-template-columns: 1fr 1.3fr 1fr;
    align-items: center;
`
