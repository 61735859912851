import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
`

export const Content = styled.div`
    width: 100%;
    padding-left: 20px;
`
