import React, { useContext } from 'react'
import { UsersContext } from '../../UsersContext'
import firebase, { db } from '../../../../utils/firebase'
import Alert from '../../../../components/alerts/Alert'
import { toast } from 'react-toastify'
import { toastErrorStyle, toastSuccessStyle } from '../../../../utils/styles'
import { httpsCallable } from '../../../../api'
import { useFeatureToggle } from '../../../../features'

export function UserDeleteDialog({ deleteUserId, onHide }) {
    const { setIds, setActionsLoading } = useContext(UsersContext)
    const { isFeatureOn } = useFeatureToggle()

    async function onConfirm() {
        try {
            setActionsLoading(true)
            const userRef = await db.collection('users').doc(deleteUserId).get()
            const user = userRef.data()
            if (user.phoneNumber) {
                const userPhoneNumberRef = await db
                    .collection('users')
                    .where('phoneNumber', '==', user.phoneNumber)
                    .where('organizationKey', '==', user.organizationKey)
                    .get()
                const userPhoneNumber = userPhoneNumberRef.docs[0].data()
                if (isFeatureOn('roles-and-permissions')) {
                    user.isAdmin = userPhoneNumber.authRole === 'admin'
                } else {
                    user.isAdmin = userPhoneNumber.authRole === 'admin'
                }
            }

            const manageUser = httpsCallable('manageUser')

            await manageUser({
                userKey: user.key,
                userName: user.name,
                userEmail: user.email,
                userPhoneNumber: user.phoneNumber,
                userNewPhoneNumber: user.phoneNumber,
                userInitials: user.initials,
                userAreaGroups: user.areaGroups,
                userOrganizationKey: user.organizationKey,
                userVisible: false,
                userIsMaintenance: user.userIsMaintenance || false,
                userIsAdmin: user.isAdmin || false,
                userShowCleaningTaskNote: user.showCleaningTaskNote || false,
                showCleaningTaskNote: user.showCleaningTaskNote,
                currentUserKey: user.key
            })

            setActionsLoading(false)
            setIds([])
            onHide()
            toast.success('User deleted successfully', toastSuccessStyle)
        } catch (error) {
            console.error(error)
            setActionsLoading(false)
            toast.error(`Error deleting user (${error.message})`, toastErrorStyle)
        }
    }

    return (
        <>
            {deleteUserId && (
                <Alert
                    title={'User deleting'}
                    text={'Are you sure to permanently delete this user?'}
                    onConfirm={onConfirm}
                    onCancel={onHide}
                />
            )}
        </>
    )
}
