import { white, body_color, bs_gray_900 } from '@shared/colors'
import styled from 'styled-components'

export const Wrap = styled.div`
    display: flex;
    column-gap: 10.8px;
    cursor: pointer;
`

export const IconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: ${white};
    border-radius: 50%;
    overflow: hidden;

    > i {
        font-size: 1rem;
        color: ${body_color};
    }
`

export const Text = styled.span`
    color: ${bs_gray_900};
    font-size: 1rem;
`
