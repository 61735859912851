import React from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { AreaStruct } from '@shared/dataObjects'
import { getDataFromSnapshot } from 'app/utils/QuerySnapshotData'
import { getAreasQuery } from '@shared/queries/data'
import firebase, { asFirebase } from '../../../utils/firebase'

export const useAreas = (): { areas: AreaStruct[]; isLoading: boolean; errorAreas: unknown } => {
    const { currentOrganization } = React.useContext(AuthContext)
    if (currentOrganization === null) return { areas: [], isLoading: true, errorAreas: {} }

    const [areas, setAreas] = React.useState<AreaStruct[]>([])

    const [areasSnap, loadingAreas, errorAreas] = useCollection<AreaStruct>(getAreasQuery(asFirebase(firebase), currentOrganization.key))

    React.useEffect(() => {
        if (!loadingAreas && areasSnap) {
            setAreas([...getDataFromSnapshot(areasSnap)])
        }
    }, [areasSnap, loadingAreas])

    const isLoading = React.useMemo(() => loadingAreas && !areas, [areas, loadingAreas])

    return { areas, isLoading, errorAreas }
}
