import React from 'react'
import * as helpers from '@shared/helpers'
import { HashTagText } from 'app/issues/style'

interface HashTagsBlockProps {
    string: string
    fontSize: number
    onPressHashTag: (hashTag: string) => void
}

export default function HashTagsBlock({ string, fontSize, onPressHashTag }: HashTagsBlockProps) {
    const hashTags: string[] = helpers.getHashTags(string)

    return (
        <>
            {hashTags.map(hashTag => (
                <HashTagText fontSize={fontSize} key={hashTag + Math.random()} onClick={() => onPressHashTag(hashTag)}>
                    {hashTag}
                </HashTagText>
            ))}
        </>
    )
}
