import React, { useState, useMemo } from 'react'
import Title from '../Title'
import UserItem from '../UserItem'
import { AssigneeWithCountAndAreas } from 'app/modules/HousekeepingDashboard/types'
import { TaskStruct } from '@shared/firestore-structs'

interface Props {
    group: string
    users: AssigneeWithCountAndAreas[]
    currentUserKey: string
    onUserClick: (user: AssigneeWithCountAndAreas) => void
    cleaningTasks: TaskStruct[]
}

function UsersGroup({ group, users, currentUserKey, onUserClick, cleaningTasks }: Props) {
    const [expanded, setExpanded] = useState(true)
    const count = useMemo(() => cleaningTasks.filter(task => task.area.group === group).length, [cleaningTasks, group])

    return (
        <div>
            <Title title={group} count={count} isSubTitle expanded={expanded} onArrowClick={() => setExpanded(!expanded)} />
            {expanded &&
                users.map((user, index, array) => (
                    <UserItem
                        key={user.key + index}
                        user={user}
                        isLast={index === array.length - 1}
                        currentUserKey={currentUserKey}
                        onUserClick={onUserClick}
                    />
                ))}
        </div>
    )
}

export default UsersGroup
