import React, { useContext } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls/index.js'
import { ReportsFilter } from '../reports-filter/ReportsFilter'
import { ReportsFilterAndExportWrap } from './ReportsCard.style.js'
import { ReportsTable } from './ReportsTable.js'
import { useRecoilValue } from 'recoil'
import { exportReportData } from './selectors.js'
import { CSVLink } from 'react-csv'
import { ReportsContext } from '../../ReportsContext.js'
import MainActionButton from '../../../../components/buttons/MainActionButton/index'
import { formatMonthDayYear } from '../../../../utils/formatters'

export function ReportsCard() {
    const exportData = useRecoilValue(exportReportData)
    const {
        queryParams: {
            filter: { dateFilterType, startDate, endDate }
        }
    } = useContext(ReportsContext)

    const createFileName = () => {
        const periodOrDate = dateFilterType === 'date' ? 'Date' : 'Period'
        const date = formatMonthDayYear(startDate)
        const periodDate = formatMonthDayYear(startDate) + ' - ' + formatMonthDayYear(endDate)
        const dateCondition = dateFilterType === 'date' ? date : periodDate
        const filename = `${periodOrDate} Report (${dateCondition})`

        return filename
    }

    const filename = createFileName()

    return (
        <>
            <Card className="card-no-shadow">
                <CardHeader title="Housekeeping report">
                    <CardHeaderToolbar></CardHeaderToolbar>
                </CardHeader>

                <CardBody>
                    <ReportsFilter>
                        <CSVLink data={exportData} filename={filename}>
                            <MainActionButton size="large" type="primary">
                                {'Export to CSV'}
                            </MainActionButton>
                        </CSVLink>
                    </ReportsFilter>

                    <ReportsTable />
                </CardBody>
            </Card>
        </>
    )
}
