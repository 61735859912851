import { calculateCleaningStatus, findActivitiesForArea, findBookingsForArea, findRulesForArea } from '@shared/cleaning-service'
import { ActivityStruct, AreaStruct, BookingStruct, OrgStruct, RuleStruct, TaskStruct } from '@shared/firestore-structs'
import * as c from '@shared/constants'

interface CreateCleaningTaskDefaultNamesParams {
    areas: AreaStruct[]
    tasks: TaskStruct[]
    bookings: BookingStruct[]
    activities: ActivityStruct[]
    rules: RuleStruct[]
    date: number
    currentOrganization: OrgStruct
}

export function createCleaningTaskDefaultNames({
    areas,
    tasks,
    bookings,
    activities,
    rules,
    date,
    currentOrganization
}: CreateCleaningTaskDefaultNamesParams): string[] {
    return areas.map(area => {
        const currentBookings = findBookingsForArea(bookings, area.key)
        const currentActivities = findActivitiesForArea(activities, area.key)
        const currentRules = findRulesForArea(rules, area.key)
        const existedName = tasks.find(task => task.areaKey === area.key)?.name
        const { ruleName } = calculateCleaningStatus(
            area.cleaningStatus,
            date,
            currentOrganization as OrgStruct,
            currentBookings,
            currentActivities,
            currentRules,
            area
        )
        return existedName || pickCleaningTaskName(ruleName, currentRules[0])
    })
}

export function pickCleaningTaskName(ruleName: string | null, activeRule: RuleStruct | null) {
    return ruleName || activeRule?.name || c.TASK_HOUSEKEEPING_DEFAULT_NAME
}
