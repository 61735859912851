import React from 'react'

export function MegaRadio({ field, form: { values }, value, options, onChange }) {
    return (
        <>
            <div className="col-lg-6">
                <label className="option">
                    <span className="option-control">
                        <span className="radio radio-outline radio-outline-2x radio-primary">
                            <input
                                type="radio"
                                onClick={onChange}
                                {...field}
                                value={value}
                                checked={values[field.name] === options.type ? 'checked' : ''}
                            />
                            <span />
                        </span>
                    </span>
                    <span className="option-label">
                        <span className="option-head">
                            <span className="option-title">{options.name}</span>
                            {/* <span className="option-focus">Free</span> */}
                        </span>
                        <span className="option-body">{options.description}</span>
                    </span>
                </label>
            </div>
        </>
    )
}
