import styled from 'styled-components'

export const Wrap = styled.div`
    width: 100%;
    height: 126px;
    padding: 24px 40px;
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`
