import React from 'react'
import { IconButton } from '../../../../components/buttons/IconButton'

interface IPriorityButton {
    priority: boolean
    onPriorityChange: () => Promise<void>
    disabled?: boolean
}

function PriorityButton({ priority, onPriorityChange, disabled = false }: IPriorityButton) {
    const getClasses = () => {
        if (priority) {
            return `btn-danger ${!disabled ? 'bg-hover-' : ''}light-danger text-color-white`
        }
        return `btn-specta-gray ${disabled ? '' : 'bg-hover-light text-hover-dark-50'}`
    }

    return (
        <IconButton
            style={{
                cursor: disabled ? 'default' : 'pointer'
            }}
            disabled={disabled}
            className={`btn btn-xs btn-circle btn-icon border-0 ${getClasses()}`}
            onClick={onPriorityChange}
            icon="priority-low"
            iconsize="icon-ms"
            tooltip={{
                text: 'Priority',
                position: 'top'
            }}
        />
    )
}

export default PriorityButton
