import React from 'react'
import { View, Text } from 'react-native-web'

import { iOSColors } from '@shared/react-native-typography'

export const SectionTitle = props => {
    let font_size = props.fontSize ? props.fontSize : 22
    if (props.isModal) {
        font_size = 17
    }

    const font_weight = props.fontWeight ? props.fontWeight : '600'
    const color = props.color ? props.color : iOSColors.black
    const margin_left = props.marginLeft !== undefined ? props.marginLeft : 0
    const margin_top = props.marginTop !== undefined ? props.marginTop : 0

    return (
        <View style={{ borderWidth: 0, flexDirection: 'row', alignItems: 'center', marginTop: margin_top, marginLeft: margin_left }}>
            <span
                style={{
                    fontSize: font_size,
                    fontWeight: font_weight,
                    color: color
                }}>
                {props.text ? props.text : ''}
            </span>
        </View>
    )
}
