import React from 'react'
import { useCollectionOnce } from 'react-firebase-hooks/firestore'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { UserStruct } from '@shared/dataObjects'
import { getDataFromSnapshot } from 'app/utils/QuerySnapshotData'
import { getUsersQuery } from '@shared/queries/data'
import firebase, { asFirebase } from '../../../utils/firebase'

export const useUsers = (): { users: UserStruct[]; isLoading: boolean; errorUsers: unknown } => {
    const { currentOrganization } = React.useContext(AuthContext)
    if (currentOrganization === null) return { users: [], isLoading: true, errorUsers: {} }

    const [users, setUsers] = React.useState<UserStruct[]>([])

    const [usersSnap, loadingUsers, errorUsers] = useCollectionOnce(getUsersQuery(asFirebase(firebase), currentOrganization.key))

    React.useEffect(() => {
        if (!loadingUsers && usersSnap) {
            setUsers([...getDataFromSnapshot(usersSnap)])
        }
    }, [usersSnap, loadingUsers])

    const isLoading: boolean = React.useMemo(() => loadingUsers && !users, [users, loadingUsers])

    return { users, isLoading, errorUsers }
}
