import React from 'react'
import FilterButtons from './FilterButtons'
import { useRecoilValue, useRecoilState } from 'recoil'
import { areasAtom, dataLoadingAtom, taskboardContextAtom, usersAtom, newTaskModalisOpenAtom } from '../state/atoms'
import TaskEditDialog from '../pages/task-edit-dialog/TaskEditDialog'
import MainActionButton from '../../components/buttons/MainActionButton'
import { dividedTasksSelector } from '../state/selectors/dividedTasks'
import { FilterButtonsWrap, NewTaskButtonWrap, Wrapper } from './style'
import { CardHeader } from '../../../_metronic/_partials/controls'

export default function Header() {
    const [newTaskModalIsOpen, setNewTaskModalIsOpen] = useRecoilState(newTaskModalisOpenAtom)

    const { allTasks } = useRecoilValue(dividedTasksSelector)
    const taskboardContext = useRecoilValue(taskboardContextAtom)

    const areas = useRecoilValue(areasAtom)
    const users = useRecoilValue(usersAtom)
    const dataLoading = useRecoilValue(dataLoadingAtom)
    // const titlePadding = taskboardContext === TaskboardContext.PROPERTY || taskboardContext === TaskboardContext.ACCOUNT ? 8.5 : 0

    function onNewTaskClick() {
        setNewTaskModalIsOpen(true)
    }

    return (
        <>
            {newTaskModalIsOpen && (
                <TaskEditDialog show={true} onHide={() => setNewTaskModalIsOpen(false)} task={null} areas={areas} users={users} />
            )}
            {/* FIXME define types */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <CardHeader className="title-x-x" title="Taskboard" count={allTasks.length || 0}>
                <Wrapper>
                    <FilterButtonsWrap>
                        <FilterButtons />
                    </FilterButtonsWrap>

                    <NewTaskButtonWrap>
                        <MainActionButton disabled={dataLoading} type="primary" size="large" onClick={onNewTaskClick}>
                            New Task
                        </MainActionButton>
                    </NewTaskButtonWrap>
                </Wrapper>
            </CardHeader>
        </>
    )
}
