import React from 'react'
import { createPortal } from 'react-dom'
import Alert from './Alert'

interface Props {
    title: string
    onConfirm: () => void
    onCancel: () => void
    type?: 'success' | 'danger' | 'warning' | 'default'
    text?: string
    confirmBtnText?: string
    cancelBtnText?: string
}

const alertContainer = document.createElement('div')
alertContainer.setAttribute('id', 'alert-portal-container')
document.body.appendChild(alertContainer)

function AlertPortal({ title, text, confirmBtnText, cancelBtnText, onConfirm, onCancel }: Props) {
    return createPortal(
        <Alert
            title={title}
            text={text}
            confirmBtnText={confirmBtnText}
            cancelBtnText={cancelBtnText}
            onConfirm={onConfirm}
            onCancel={onCancel}>
            <></>
        </Alert>,
        document.getElementById('alert-portal-container') as HTMLElement
    )
}

export default AlertPortal
