import * as React from 'react'
import { getBrand, getNavBarNOAuthLogo } from '@shared/brand'
import RectangularButton from '../buttons/RectangularButton'
import image from 'app/img/housekeeper-12.png'
import { Content, Footer, Header, Wrap } from './style'
import { detectDevice } from 'app/utils/helpers'
import appStoreIcon from '@shared/img/icon-ios.svg'
import playMarketIcon from '@shared/img/icon-android.svg'
import * as uc from 'app/utils/constants'

function ContentBlocker() {
    const appStoreScale = 1
    const brand = getBrand()
    const brandLogo = getNavBarNOAuthLogo()
    const device = detectDevice(window)

    function onOpenAppClick() {
        window.location.href = device === uc.DEVICES.IOS ? brand.urls.appStore : brand.urls.playStore
    }

    return (
        <Wrap>
            <Header>
                <img src={brandLogo.img} style={brandLogo.style} alt="logo" />
                <span>{brand.name}</span>
            </Header>

            <Content>
                <img src={image} alt={'use mobile app'} />
                <RectangularButton type="button" rounded onClick={onOpenAppClick}>
                    Open in the {brand.name} app
                </RectangularButton>
            </Content>

            <Footer>
                <h1>Don&apos;t have the app?</h1>
                <span>Install it from your store.</span>
                <a href={device === uc.DEVICES.IOS ? brand.urls.appStore : brand.urls.playStore}>
                    <img
                        alt={'Get Sweeply on Apple App Store'}
                        src={device === uc.DEVICES.IOS ? appStoreIcon : playMarketIcon}
                        style={{
                            opacity: 1,
                            width: 160 * appStoreScale,
                            height: 47 * appStoreScale,
                            marginRight: 16
                        }}
                    />
                </a>
            </Footer>
        </Wrap>
    )
}

export default ContentBlocker
