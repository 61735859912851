import React, { useContext, useState } from 'react'
import { Initials } from '../../../../../components/initials'
import { AuthContext } from '../../../../Auth/AuthContext'
import SummaryByUser from '../components/SummaryByUser'
import ReportTasks from '../components/ReportTasks'
import { useRecoilValue } from 'recoil'
import { reportSummaryByUserHeaders, reportTasksHeaders } from '../atoms.js'
import { CSVLink } from 'react-csv'
import { formatMonthDayYear } from '../../../../../utils/formatters'
import { ReportsContext } from '../../../ReportsContext'

const UserRow = ({ userRow, initials }) => {
    const { currentUser } = useContext(AuthContext)
    const {
        queryParams: {
            filter: { startDate, endDate, dateFilterType }
        }
    } = useContext(ReportsContext)
    const { user, areasCleaned, averageTime, start, end, paused, activeTime, idleTime, totalTime, summaryByUser, reportTasks } = userRow
    const [collapsed, setCollapsed] = useState(true)
    const exportTasksHeaders = useRecoilValue(reportTasksHeaders)
    const exportSummaryByUserHeaders = useRecoilValue(reportSummaryByUserHeaders)

    const collapsedCondition = collapsed ? (
        <span className="cursor-pointer" onClick={() => setCollapsed(false)}>
            <i className="specta-arrow-right icon-nm ml-1" />
        </span>
    ) : (
        <span className="cursor-pointer" onClick={() => setCollapsed(true)}>
            <i className="d-inline-block specta-arrow-right icon-nm rotate90 ml-1" />
        </span>
    )

    const summaryByUserCondition = summaryByUser && !collapsed && <SummaryByUser summaryByUser={summaryByUser} />
    const reportTasksCondition = reportTasks && !collapsed && <ReportTasks reportTasks={reportTasks} />

    const headers = reportTasks ? exportTasksHeaders : exportSummaryByUserHeaders

    const exportReportTasks =
        reportTasks &&
        reportTasks.map(row => {
            const {
                area: { group, description, name }
            } = row
            const areaString = group + ' > ' + description + ' > ' + name

            return { ...row, area: areaString }
        })

    const exportData = reportTasks ? exportReportTasks : summaryByUser

    const createFileName = () => {
        const periodOrDate = dateFilterType === 'date' ? 'Date' : 'Period'
        const date = formatMonthDayYear(startDate)
        const periodDate = formatMonthDayYear(startDate) + ' - ' + formatMonthDayYear(endDate)
        const dateCondition = dateFilterType === 'date' ? date : periodDate

        return `${periodOrDate} Report ${user} (${dateCondition})`
    }

    const filename = createFileName()

    return (
        <>
            <tr key={user.key}>
                <td className="">{collapsedCondition}</td>

                <td>
                    <div className="d-inline-block">
                        <Initials
                            disabled={true}
                            item={{ initials }}
                            currentUser={currentUser}
                            dimension={30}
                            fontSize={11}
                            noMargin={true}
                            toolTipDisabled={true}
                        />
                    </div>
                    <span className="ml-3">{user}</span>
                </td>

                <td>{areasCleaned}</td>

                <td>{averageTime}m</td>

                {start && end && paused && (
                    <>
                        <td>{start}</td>
                        <td>{end}</td>
                        <td>{paused}</td>
                    </>
                )}

                <td>{activeTime}</td>

                {idleTime && totalTime && (
                    <>
                        <td>{idleTime}</td>
                        <td>{totalTime}</td>
                    </>
                )}

                <td>
                    <div style={{ width: '50%' }}>
                        <CSVLink className="btn btn-light" headers={headers} data={exportData} filename={filename}>
                            {'Export'}
                        </CSVLink>
                    </div>
                </td>
            </tr>
            {summaryByUserCondition}
            {reportTasksCondition}
        </>
    )
}

export default UserRow
