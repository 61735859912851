import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
// import 'firebase/compat/remote-config'
export type DocumentData = firebase.firestore.DocumentData
export type DocumentReference<T> = firebase.firestore.DocumentReference<T>

import axios from 'axios'
import { getBrand } from '@shared/brand'
import 'firebase/compat/storage'
import { QuerySnapshot } from '@firebase/firestore-types'

import * as issueData from '@shared/issue-data'
import { IssueStruct, UserStruct } from '@shared/firestore-structs'
import { TaskClientRepo } from '@shared/task-data'

export * from '@shared/area-data'
export * from '@shared/issue-data'
import { Firebase, FirebaseFirestore, WriteBatch } from '@shared/firebase'

const config = getBrand().firebase!

firebase.initializeApp(config)

function getDynamicLink(url: string, issue: IssueStruct, dynamicLinkPrefix = null) {
    return new Promise((resolve, reject) => {
        const postUrl = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=' + config.apiKey
        axios.defaults.headers.post['Content-Type'] = 'application/json'
        axios
            .post(postUrl, {
                dynamicLinkInfo: {
                    domainUriPrefix: dynamicLinkPrefix || config.dynamicLinkPrefix,
                    link: url,
                    navigationInfo: {
                        enableForcedRedirect: true
                    },
                    iosInfo: {
                        iosBundleId: config.bundleId,
                        iosFallbackLink: url,
                        iosAppStoreId: config.iosAppStoreId
                    },
                    androidInfo: {
                        androidPackageName: config.bundleId,
                        androidFallbackLink: url
                    }
                },
                suffix: {
                    option: 'SHORT'
                }
            })
            .then(response => {
                issueData.getIssueItems(asFirebase(firebase), issue.key).then(items => {
                    let itemThumbUrl = null
                    if (items.length > 0) {
                        itemThumbUrl = items[0].thumbUrl
                    }
                    response.data.issueNumber = issue.issueNumber
                    response.data.issueKey = issue.key
                    response.data.thumbUrl = itemThumbUrl
                    resolve(response)
                })
            })
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}

export function dynamicLink(url: string, issue: IssueStruct, currentUser: UserStruct, organization: any = null) {
    return new Promise((resolve, reject) => {
        if (organization && organization.dynamicLinkPrefix) {
            resolve(getDynamicLink(url, issue, organization.dynamicLinkPrefix))
        }
        firebase
            .firestore()
            .collection('organizations')
            .doc(currentUser.organizationKey)
            .get()
            .then(organizationSnap => {
                const organizationDB = organizationSnap.data()
                resolve(getDynamicLink(url, issue, organizationDB?.dynamicLinkPrefix ?? null))
            })
    })
}

type QuerySnapshotData<T> = T extends QuerySnapshot<infer U> ? U : never

export function getDataFromSnapshot<T extends QuerySnapshot[]>(snapshot: QuerySnapshot): QuerySnapshotData<T>[] {
    return snapshot.docs.map(doc => doc.data()) as QuerySnapshotData<T>[]
}

export function logout() {
    firebase.auth().signOut()
}

// const firebaseWrapped = spy(firebase)
const firebaseWrapped = firebase
export const db = firebaseWrapped.firestore()
export const storage = firebase.storage()
export const asFirebase = (fb: typeof firebase): Firebase => (fb as unknown) as Firebase
export const asFirestore = (fb: typeof db): FirebaseFirestore => (fb as unknown) as FirebaseFirestore
export const asBatch = (batch: ReturnType<typeof db.batch>): WriteBatch => (batch as unknown) as WriteBatch

export const tasksRepo = new TaskClientRepo(asFirebase(firebase))

export default firebaseWrapped

if (location.hostname === 'localhost' && process.env.VITE_LOCAL_STACK) {
    db.useEmulator('localhost', 8080)
    firebase.functions().useEmulator('localhost', 5001)
}
