import React, { forwardRef } from 'react'
import moment from 'moment-timezone'
import { useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import { Input } from '../../controls/forms/Input'

const getFieldCSSClasses = (touched, errors) => {
    const classes = ['form-control']
    if (touched && errors) {
        classes.push('is-invalid')
    }

    // if (touched && !errors) {
    //     classes.push('is-valid')
    // }

    return classes.join(' ')
}

const getIconCSSClasses = (touched, errors) => {
    const classes = ['specta-duedate icon-md pl-13']
    // if (touched && errors) {
    //     classes.push('pr-30')
    // }

    // if (touched && !errors) {
    //     classes.push('pr-30')
    // }

    return classes.join(' ')
}

const IconInput = forwardRef(function IconInput({ value, touched, errors, field, ...props }, ref) {
    return (
        <div className="input-icon input-icon-left">
            <input onChange={() => {}} value={value} style={{ cursor: 'pointer' }} {...props} ref={ref} />
            <span style={{ width: 0, paddingRight: 0 }}>
                <i className={getIconCSSClasses(touched[field.name], errors[field.name])}></i>
            </span>
        </div>
    )
})
IconInput.displayName = 'IconInput'

export function DatePickerField({ ...props }) {
    const { setFieldValue, errors, touched } = useFormikContext()
    const field = props.field
    const label = props.label
    const now = moment().toDate()

    return (
        <>
            {label && (
                <label className="font-weight-bold text-dark-65">
                    {label}:{props.required && <span className="text-danger">*</span>}
                </label>
            )}
            {/* {props.label && <label className="row row-paddingless font-weight-bold text-dark-65">{props.label}:</label>} */}
            {props.iconInput === true && (
                <DatePicker
                    minDate={now}
                    className={getFieldCSSClasses(touched[field.name], errors[field.name])}
                    style={{ width: '100%' }}
                    {...field}
                    {...props}
                    selected={(field.value && new Date(field.value)) || null}
                    onChange={val => {
                        setFieldValue(field.name, val)
                    }}
                    customInput={<IconInput touched={touched} errors={errors} field={field} />}
                />
            )}

            {!props.iconInput && (
                <DatePicker
                    minDate={now}
                    className={getFieldCSSClasses(touched[field.name], errors[field.name])}
                    style={{ width: '100%' }}
                    {...field}
                    {...props}
                    selected={(field.value && new Date(field.value)) || null}
                    onChange={val => {
                        setFieldValue(field.name, val)
                    }}
                    // customInput={<IconInput touched={touched} errors={errors} field={field} />}
                />
            )}

            {errors[field.name] && touched[field.name] && (
                <div className="invalid-datepicker-feedback">{errors[field.name].toString()}</div>
            )}
            {!field.value && (
                <div className="feedback">
                    Please enter <b>{props.label}</b> in &lsquo;dd/mm/yyyy&rsquo; format
                </div>
            )}
        </>
    )
}
