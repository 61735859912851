import { GetRecoilValue, selector } from 'recoil'
import { AreaStruct, TaskStruct } from '@shared/firestore-structs'
import { sortAlphabetically } from 'app/utils/formatters'
import {
    areasAtom,
    assignFilterModeAtom,
    cleaningStatusFilterValuesAtom,
    fetchedAreasAtom,
    occupancyFilterValueAtom,
    priorityFilterAtom
} from '../atoms'
import { AssignFilterMode, CleaningStatusFilterValue, GroupOfAreas, OccupancyFilterValue } from '../../types'
import { areaPrioritySelector, calculatedAreaSelector } from './calculatedArea'
import * as c from '@shared/constants'
import { cleaningTaskSelector } from './areaRelatedData'
import { showActionBarAtom } from 'app/issues/state/atoms'

export const groupsSelector = selector<string[]>({
    key: 'housekeeping-groupsSelector',
    get: ({ get }) => {
        const areas = get(areasAtom)
        return [...new Set(areas.map((area: AreaStruct) => area.group))]
            .filter((group): group is string => group !== undefined)
            .sort(sortAlphabetically)
    }
})

export const groupsOfAreasSelector = selector<[GroupOfAreas[], number]>({
    key: 'housekeeping-areasByGroupsSelector',
    get: ({ get }) => {
        const areas = get(areasAtom)
        const groups = get(groupsSelector)

        const areasByGroups = groups.map((group: string) => {
            const areasInGroup = areas.filter((area: AreaStruct) => {
                return area.group === group
            })

            return {
                group,
                areas: areasInGroup
            }
        })

        return [areasByGroups, areas.length]
    }
})

export const priorityAreasSelector = selector<AreaStruct[]>({
    key: 'housekeeping-priorityAreas',
    get: ({ get }) => get(fetchedAreasAtom).filter((area: AreaStruct) => get(areaPrioritySelector(area.key)))
})

export const filteredAreasSelector = selector<AreaStruct[]>({
    key: 'housekeeping-filteredAreas',
    get: ({ get }) => {
        const fetchedAreas = get(fetchedAreasAtom)
        const cleaningStatusFilterValues = get(cleaningStatusFilterValuesAtom)
        const occupancyFilterValue = get(occupancyFilterValueAtom)
        const priorityFilter = get(priorityFilterAtom)
        const priorityAreas = get(priorityAreasSelector)
        const showAssignActionBarAtom = get(showActionBarAtom)

        const returnAllAreasCondition =
            !priorityAreas &&
            cleaningStatusFilterValues.length === 0 &&
            occupancyFilterValue === c.OCCUPANCY_ALL &&
            !showAssignActionBarAtom

        const filteredAreas = filterAreas(
            priorityFilter ? priorityAreas : fetchedAreas,
            cleaningStatusFilterValues,
            occupancyFilterValue,
            get
        )

        return returnAllAreasCondition ? fetchedAreas : filteredAreas
    }
})

function filterAreas(
    areas: AreaStruct[],
    cleaningStatusFilterValues: CleaningStatusFilterValue[],
    occupancyFilterValue: OccupancyFilterValue,
    get: GetRecoilValue
) {
    return areas.filter((area: AreaStruct) => {
        return (
            filterByCleaningStatus(get(calculatedAreaSelector(area)).area, cleaningStatusFilterValues) &&
            filterByOccupancy(get(calculatedAreaSelector(area)).area, occupancyFilterValue) &&
            filterByAssigningStatus(get(assignFilterModeAtom), get(cleaningTaskSelector(area.key)))
        )
    })
}

function filterByCleaningStatus(area: AreaStruct, cleaningStatusFilterValues: CleaningStatusFilterValue[]): boolean {
    if (cleaningStatusFilterValues.length === 0) return true
    return !cleaningStatusFilterValues.includes(area.cleaningStatus)
}

function filterByOccupancy(area: AreaStruct, occupancyFilterValue: OccupancyFilterValue): boolean {
    if (occupancyFilterValue === c.OCCUPANCY_ALL) return true
    return occupancyFilterValue === area.occupancy
}

function filterByAssigningStatus(assignFilterMode: AssignFilterMode, cleaningTask: TaskStruct | null): boolean {
    switch (assignFilterMode) {
        case AssignFilterMode.ASSIGNED:
            return cleaningTask !== null
        case AssignFilterMode.UN_ASSIGNED:
            return cleaningTask === null
        default:
            return true
    }
}
