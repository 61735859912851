import React, { ChangeEvent } from 'react'
import { Formik, Form, Field } from 'formik'
import { CodeVerificationWrapper } from '../style'
import SignUpLayout from '../SignUpLayout'
import { ContentWrap, ModalContainer, ModalOverlay } from '../../../components/styled-components/modal'
import RectangularButton from '../../../components/buttons/RectangularButton'
import Input from '../../../components/forms/Input'
import { getBrand } from '@shared/brand'

interface FormValues {
    apiKey: string
}

interface Props {
    bus: string
    apiKeyUrl: string
    onSubmit: (values: FormValues) => void
}

const ApiKeyForm = ({ bus, apiKeyUrl, onSubmit }: Props) => {
    const initialValues: FormValues = { apiKey: '' }
    const brandColor = getBrand().navBarColor

    return (
        <SignUpLayout withModal>
            <ModalOverlay>
                <ModalContainer>
                    <ContentWrap>
                        <CodeVerificationWrapper>
                            <h1>{`Enter ${bus} API Key`}</h1>
                            <p>
                                In order to complete the signup you must copy your API key from {bus}{' '}
                                {apiKeyUrl && (
                                    <a href={apiKeyUrl} target="_blank" rel="noreferrer">
                                        Click here to get your API key
                                    </a>
                                )}
                            </p>

                            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                                {({ handleSubmit, setFieldValue, values }) => {
                                    const disabled = !values.apiKey

                                    return (
                                        <Form>
                                            <Field
                                                component={Input}
                                                label={`${bus} API Key`}
                                                placeholder={'Enter API Key here'}
                                                value={values.apiKey}
                                                name={'apiKey'}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('apiKey', e.target.value)}
                                                withoutValidation
                                            />
                                            <RectangularButton
                                                type="submit"
                                                disabled={disabled}
                                                width={'205px'}
                                                height={'64px'}
                                                backgroundColor={brandColor}
                                                margin={'80px 0 0 0'}
                                                // FIXME define types
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                onClick={handleSubmit}>
                                                Confirm
                                            </RectangularButton>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </CodeVerificationWrapper>
                    </ContentWrap>
                </ModalContainer>
            </ModalOverlay>
        </SignUpLayout>
    )
}

export default ApiKeyForm
