import {
    AreaStruct,
    BookingStruct,
    ActivityStruct,
    DailyCommentStruct,
    TaskStruct,
    RuleStruct,
    UserStruct
} from '@shared/firestore-structs'
import { SubscriptionParams } from 'app/types'
import { atom } from 'recoil'
import { AssignFilterMode, CleaningStatusFilterValue, OccupancyFilterValue } from '../types'
import * as c from '@shared/constants'
import moment from 'moment-timezone'

export const currentDateNumberAtom = atom<number>({
    key: 'housekeeping-currentDateNumberAtom',
    default: moment().valueOf()
})

export const dataLoadingAtom = atom<boolean>({
    key: 'housekeeping-dataLoadingAtom',
    default: false
})

export const subscriptionParamsAtom = atom<SubscriptionParams>({
    key: 'housekeeping-subscriptionParams',
    default: {
        hasSubscriptionAccess: false,
        availableUpgrades: null,
        notAccessibleFeature: null,
        upgradeMessageIsOpen: false
    }
})

export const fetchedAreasAtom = atom<AreaStruct[]>({
    key: 'housekeeping-fetchedAreas',
    default: []
})

export const areasAtom = atom<AreaStruct[]>({
    key: 'housekeeping-areasAtom',
    default: []
})

export const bookingsAtom = atom<BookingStruct[]>({
    key: 'housekeeping-bookings',
    default: []
})

export const activitiesAtom = atom<ActivityStruct[]>({
    key: 'housekeeping-activities',
    default: []
})

export const dailyCommentsAtom = atom<DailyCommentStruct[]>({
    key: 'housekeeping-dailyComments',
    default: []
})

export const cleaningTasksAtom = atom<TaskStruct[]>({
    key: 'housekeeping-tasks',
    default: []
})

export const rulesAtom = atom<RuleStruct[]>({
    key: 'housekeeping-rules',
    default: []
})

export const usersAtom = atom<UserStruct[]>({
    key: 'housekeeping-users',
    default: []
})

export const priorityFilterAtom = atom<boolean>({
    key: 'housekeeping-priorityFilterIsActive',
    default: false
})

export const cleaningStatusFilterValuesAtom = atom<CleaningStatusFilterValue[]>({
    key: 'housekeeping-cleaningStatusFilterOptions',
    default: []
})

export const occupancyFilterValueAtom = atom<OccupancyFilterValue>({
    key: 'housekeeping-occupancyFilterValueAtom',
    default: c.OCCUPANCY_ALL
})

export const searchValueAtom = atom<string>({
    key: 'housekeeping-searchValue',
    default: ''
})

export const showAssignActionBarAtom = atom<boolean>({
    key: 'housekeeping-showAssignActionBar',
    default: false
})

export const assignFilterModeAtom = atom<AssignFilterMode>({
    key: 'housekeeping-assignFilterMode',
    default: AssignFilterMode.ALL
})

export const selectedAreasKeysAtom = atom<Set<string>>({
    key: 'housekeeping-selectedAreas',
    default: new Set()
})
