import React, { useContext } from 'react'
import { FlatList, View } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import ActivityRow from '../../activity-row'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { UNIT_HEADER_FONT_SIZE } from './consts'

const Activities = ({ activities }: { activities: any }) => {
    const { currentUser, currentOrganization } = useContext(AuthContext)

    return (
        <View style={{ marginBottom: 0, borderWidth: 0, width: '100%' }}>
            <span
                style={{
                    fontWeight: '600',
                    color: iOSColors.black,
                    fontSize: UNIT_HEADER_FONT_SIZE + 1,
                    marginBottom: 12
                }}>
                Activity
            </span>

            <FlatList
                data={activities}
                style={{}}
                renderItem={({ item }) => (
                    <ActivityRow activity={item} currentUser={currentUser} currentOrganization={currentOrganization} />
                )}
            />
        </View>
    )
}

export default Activities
