import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Modal } from 'react-bootstrap'

import { AccountEditDialogHeader } from './AccountEditDialogHeader'
import { AccountEditForm } from './AccountEditForm'
import { AccountsContext } from '../../AccountsContext'
import firebase, { db } from '../../../../utils/firebase'
import { AuthContext } from '../../../Auth/AuthContext'

import * as dataObjects from '@shared/dataObjects'
import Spinner from '../../../../components/Spinner'
import { toast } from 'react-toastify'
import { beds24remove, beds24setup } from '../../../OrganizationSignUp/cloud-functions'
import { toastErrorStyle } from '../../../../utils/styles'
import { httpsCallable } from '../../../../api'

export function AccountEditDialog({ id, show, onHide }) {
    // Customers UI Context
    const { accounts, setIds, accountForEdit, setAccountForEdit, actionsLoading, setActionsLoading, initAccount } = useContext(
        AccountsContext
    )
    const { user, organization } = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)

    const accountsUIProps = useMemo(() => {
        return {
            initAccount: initAccount
        }
    }, [initAccount])

    useEffect(() => {
        setIsLoading(true)
        const getAccount = async () => {
            if (id) {
                const accountRef = await db.collection('organizations').doc(id).get()
                const account = accountRef.data()
                account.key = accountRef.id
                console.log(account)
                setAccountForEdit(account)
            }
            setIsLoading(false)
        }
        getAccount(id)
    }, [id, setIsLoading, setAccountForEdit])

    const saveAccount = async account => {
        const manageOrganizationCall = httpsCallable('manageOrganization')
        const manageSubscription = httpsCallable('manageOrganizationSubscription')
        const pmsSetup = beds24setup()
        setActionsLoading(true)

        account.organizationKey = user.organizationKey
        account.visible = true

        console.log(`Currency code ${account.currencyCode}`)
        let organizationObject = dataObjects.getOrganizationObject(
            account.key,
            account.name,
            account.status,
            account.issueHashtags,
            account.assignMaintenance,
            account.excludeMaintenanceNotification,
            account.currencyCode,
            organization.timezone,
            organization.allowOptIn,
            organization.allowOptOut
        )

        organizationObject.brand = organization.brand || 'sweeply'

        const manageOrganizationResponse = await manageOrganizationCall(organizationObject).catch(error => {
            setActionsLoading(false)
            console.log(error)
            toast.error(error.message, toastErrorStyle)
            // notify.show(<Toast width={250} backgroundColor={iOSColors.red} message={error.message} />, 'custom', 3000, {})
        })

        if (account.pms) {
            const pmsAccountIDs = account._pmsAccountIDs ? account._pmsAccountIDs.map(a => a.toString()) : []
            const options = { include: account._include || [], exclude: account._exclude || [] }
            await pmsSetup({
                orgId: account.key,
                integration: 'beds24',
                signupPayload: { bed24AccountIDs: pmsAccountIDs, options: options }
            })
        } else {
            await beds24remove()({ orgId: account.key })
        }

        if (manageOrganizationResponse && manageOrganizationResponse.data) {
            account.key = manageOrganizationResponse.data
        }

        if (accountForEdit.subscription !== account.subscription) {
            const subscriptionObj = {
                key: account.key,
                subscription: account.subscription,
                subscriptionRenew: accountForEdit.subscriptionRenew
            }

            if (account.subscription === 'pro' || account.subscription === 'trial') {
                subscriptionObj.subscriptionRenew = true
            }

            const manageOrganizationSubscrtiptionResponse = await manageSubscription(subscriptionObj).catch(error => {
                setActionsLoading(false)
                console.log(error)
                toast.error(error.message, toastErrorStyle)
            })
            if (manageOrganizationSubscrtiptionResponse === undefined) {
                return
            }
        }

        setActionsLoading(false)

        setIds([])

        setAccountForEdit(initAccount)
        onHide()
        if (!id) {
            // window.location.href = '/sign-up-welcome'
        }
    }

    return (
        <Modal show={show} size="lg" onHide={onHide} centered={true} aria-labelledby="example-modal-sizes-title-lg">
            {isLoading && (
                <div className="w-100 h-100">
                    <Spinner style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 1500 }} />
                </div>
            )}
            <AccountEditDialogHeader id={id} onHide={onHide} />
            <AccountEditForm
                saveAccount={saveAccount}
                actionsLoading={actionsLoading}
                dataLoading={isLoading}
                account={accountForEdit || accountsUIProps.initAccount}
                onHide={onHide}
            />
        </Modal>
    )
}
