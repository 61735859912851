import { userIsAllowed } from '@shared/roles/roles'
import { View } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import React, { useContext } from 'react'
import { TaskScreenContext } from './TaskScreenContext'
import { SubscriptionContext } from '../../../modules/Subscriptions/SubscriptionContext'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { MODAL_SECTION_VERTICAL_SPACE } from '../../../utils/constants'
import AssigneesView from '../../../components/assignees-view'
import * as areaData from '@shared/area-data'
import firebase, { asFirebase } from '../../../utils/firebase'
import * as taskData from '@shared/task-data'
import { DashboardContext } from '../DashboardContext'

const AssignedTo = () => {
    const { currentTask, selectedDateNumber, area } = useContext(TaskScreenContext)
    const { currentUser, currentOrganization } = useContext(AuthContext)
    const { currentSubscription } = useContext(SubscriptionContext)
    const { setSelectUserModalIsOpen, selectedAreas, setSelectedAreas } = useContext(DashboardContext)
    const task = currentTask
    const assignedTo = task && task.assignedTo ? task.assignedTo : undefined
    const isAllowed = userIsAllowed('assign-housekeeping-un-assign', currentUser, currentOrganization!, currentSubscription!)

    const onUnassignPress = async () => {
        setSelectUserModalIsOpen(false)
        const changeObj = {
            before: 'unassigned'
        }
        await areaData.logActivity(asFirebase(firebase), currentUser!, area.key, 'assignment', selectedDateNumber, changeObj)
        if (currentTask && currentTask.key) {
            await taskData.unassignTask(asFirebase(firebase), currentUser!, currentTask.key)
        }

        setSelectedAreas([])
    }

    if (!assignedTo) {
        return null
    }

    return (
        <View
            style={{
                marginTop: MODAL_SECTION_VERTICAL_SPACE,
                borderColor: 'f#f4f5f6',
                borderRadius: 6,
                width: '95%',
                paddingVertical: 24,
                // @ts-ignore
                boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, .2)'
            }}>
            <View style={{ borderWidth: 0, paddingHorizontal: 24 }}>
                {assignedTo && assignedTo.length > 0 && (
                    <AssigneesView
                        maxCountToDisplay={6}
                        unassignDisabled={false}
                        assignedTo={assignedTo}
                        currentUser={currentUser}
                        onUnassignPress={onUnassignPress}
                    />
                )}
            </View>
        </View>
    )
}

export default AssignedTo
