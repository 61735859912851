import styled from 'styled-components'
import * as colors from '@shared/colors'
import { MOBILE_BREAKPOINT, SMALL_DESKTOP_SCREEN_HEIGHT } from '../../utils/constants'
import { getBrand } from '@shared/brand'

const black = colors.black_sweeply
const yellow = colors.main_yellow
const blue = colors.medium_blue
const brandColor = getBrand().navBarColor

export const ErrorLayout = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 0.65fr 3fr 0.3fr;

    .logo-wrap {
        display: flex;
        justify-content: start;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        label {
            font-size: 12px;
        }
    }
`

export const ContactSupportWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    span,
    a {
        font-weight: 450;
        font-size: 14px;
        line-height: 24px;
    }

    span {
        color: ${black};
    }

    a {
        color: ${brandColor};
        font-weight: 700;
    }
`

export const ErrorMessageWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    > div {
        width: 377px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        h1,
        p {
            color: ${black};
        }

        h1 {
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            text-align: left;
        }

        p {
            text-align: left;
            font-weight: 450;
            font-size: 14px;
            line-height: 24px;
            width: 100%;
        }
    }

    @media (max-height: ${SMALL_DESKTOP_SCREEN_HEIGHT}px) {
        > div {
            row-gap: 8px;

            h1 {
                line-height: 30px;
            }

            p {
                line-height: 20px;
            }
        }
    }
`

export const ErrorImageWrap = styled.div`
    width: 100%;
    height: 116px;
    position: relative;
    margin-top: 65px;

    div,
    svg {
        position: absolute;
    }

    .eye-1 {
        width: 20.71px;
        height: 21.34px;
        border-radius: 4px;
        background: ${blue};
        transform: rotate(-150deg);
        left: 117px;
    }

    .eye-2 {
        border-radius: 8px;
        transform: rotate(-180deg);
        width: 37.79px;
        height: 37.79px;
        background: ${yellow};
        right: 123px;
        top: -5px;
    }

    svg {
        bottom: -25px;
        left: 125px;
    }

    @media (max-height: ${SMALL_DESKTOP_SCREEN_HEIGHT}px) {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 295px;
        height: 116px;

        .eye-1 {
            left: 85px;
        }

        .eye-2 {
            right: 70px;
        }

        svg {
            left: 90px;
        }
    }
`
