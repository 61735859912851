import React, { useContext } from 'react'
import { BUTTON_DIM, HOVER_OPACITY } from '../../../utils/constants'
import { HoverableImageButton } from '../../../components/buttons/hoverable-image-button'
import { onCleaningPriorityPress } from '../actions'
import { TaskScreenContext } from './TaskScreenContext'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { DashboardContext } from '../DashboardContext'

const PriorityButton = () => {
    const { area, priority, setPriority, currentTask, selectedDateNumber } = useContext(TaskScreenContext)
    const { updateAreasLocally } = useContext(DashboardContext)
    const { currentUser } = useContext(AuthContext)
    let imgName = null
    let tooltip
    if (priority === true) {
        imgName = require('@shared/img/priority-high-white-bkg.svg')
        tooltip = 'Normal priority'
    } else {
        imgName = require('@shared/img/priority-low-white-bkg.svg')
        tooltip = 'High priority'
    }

    return (
        // eslint-disable-next-line react/jsx-no-undef
        <HoverableImageButton
            dimension={BUTTON_DIM}
            hoverOpacity={HOVER_OPACITY}
            imageSource={imgName}
            onPress={() => {
                onCleaningPriorityPress(area, priority, currentTask, currentUser!, selectedDateNumber)
                updateAreasLocally([{ key: area.key, priority: !priority }])
                setPriority(!priority)
            }}
            tooltipText={tooltip}
        />
    )
}

export default PriorityButton
