import axios from 'axios'
import { getBrand } from '@shared/brand'

const PROJECT_ID = getBrand().firebase.projectId

const instance = axios.create({
    baseURL: `https://us-central1-${PROJECT_ID}.cloudfunctions.net/`
})

export const deleteAccount = async organizationKey => {
    return instance.delete(`integrationsTools/maintenance/organization/${organizationKey}?areyousure=yes`)
}

export default instance
