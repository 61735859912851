import React from 'react'
import { PlanContainer, ActionsWrapper, ContactSalesButton, ButtonText } from '../style'
import * as colors from '@shared/colors'
import Price from './Price'
import { BenefitsItem } from '../style'
import { numberFormat } from '../../../../../../utils/locale'
import styled from 'styled-components'

export const FeaturesContainer = styled.div`
    margin-top: 30px;
    margin-bottom: 40px;
`
const EnterprisePlan = () => {
    const price = 500
    const currencyCode = 'EUR'
    const period = {
        periodNumber: 1,
        periodUnit: 'month'
    }
    const features = ['Everything in Pro', 'Optional housekeeping']
    return (
        <PlanContainer color={colors.black_sweeply} className=" bg-white shadow-sm">
            <div className="pt-5 pb-10">
                <h1 className="mb-5">Premium&nbsp;🏢</h1>
                <Price
                    price={numberFormat(price / 100, currencyCode)}
                    isPro={true}
                    period={period}
                    isLineThrough={false}
                    hasExtraDetails={false}
                />

                <FeaturesContainer>
                    {features.map((feature, index) => (
                        <BenefitsItem bold={index === 0} key={feature + index}>
                            {feature}
                        </BenefitsItem>
                    ))}
                </FeaturesContainer>

                <ActionsWrapper className="mt-7">
                    <ContactSalesButton
                        type="button"
                        href={'https://calendly.com/d/d2q-gdh-cng/sweeply-demo'}
                        rel="noopener noreferrer"
                        target="_blank">
                        <ButtonText className="font-size-lg" color={colors.white}>
                            Contact Sales
                        </ButtonText>
                    </ContactSalesButton>
                </ActionsWrapper>
            </div>
        </PlanContainer>
    )
}

export default EnterprisePlan
