import React from 'react'

const LogoutIcon = () => {
    return (
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.31049 6.121H7.54199C7.64049 6.121 7.73801 6.1404 7.82901 6.17809C7.92 6.21578 8.00268 6.27103 8.07232 6.34067C8.14197 6.41031 8.19721 6.49299 8.2349 6.58399C8.27259 6.67498 8.29199 6.77251 8.29199 6.871C8.29199 6.96949 8.27259 7.06702 8.2349 7.15801C8.19721 7.24901 8.14197 7.33169 8.07232 7.40133C8.00268 7.47097 7.92 7.52622 7.82901 7.56391C7.73801 7.6016 7.64049 7.621 7.54199 7.621H3.31049L4.15199 8.4625C4.29262 8.60323 4.37159 8.79406 4.37152 8.99302C4.37145 9.19197 4.29235 9.38274 4.15162 9.52338C4.01089 9.66401 3.82006 9.74297 3.6211 9.7429C3.42215 9.74283 3.23137 9.66373 3.09074 9.523L0.969744 7.402C0.899742 7.3325 0.844185 7.24983 0.806272 7.15876C0.768359 7.06769 0.74884 6.97002 0.74884 6.87137C0.74884 6.77273 0.768359 6.67506 0.806272 6.58399C0.844185 6.49292 0.899742 6.41025 0.969744 6.34075L3.08999 4.21975C3.23062 4.07902 3.4214 3.99992 3.62035 3.99985C3.81931 3.99978 4.01014 4.07874 4.15087 4.21938C4.2916 4.36001 4.3707 4.55078 4.37077 4.74973C4.37084 4.94869 4.29187 5.13952 4.15124 5.28025L3.31049 6.121ZM10.5 0.25C10.6989 0.25 10.8897 0.329018 11.0303 0.46967C11.171 0.610322 11.25 0.801088 11.25 1V13C11.25 13.1989 11.171 13.3897 11.0303 13.5303C10.8897 13.671 10.6989 13.75 10.5 13.75C10.3011 13.75 10.1103 13.671 9.96966 13.5303C9.82901 13.3897 9.74999 13.1989 9.74999 13V1C9.74999 0.801088 9.82901 0.610322 9.96966 0.46967C10.1103 0.329018 10.3011 0.25 10.5 0.25V0.25Z"
                fill="#211E1C"
            />
        </svg>
    )
}

export default LogoutIcon
