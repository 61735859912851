import React, { useState, ChangeEvent } from 'react'
import { View, TextInput } from 'react-native-web'
import Hoverable from '../utils/hoverable/hoverable'

import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'
import MainActionButton from '../components/buttons/MainActionButton'

interface CommentInputProps {
    horizontalMargin: number
    onSubmitComment: (comment: string) => void
}

export const CommentInput: React.FC<CommentInputProps> = props => {
    const [hover, setHover] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string>('')

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value)
    }

    const handleSubmitComment = () => {
        if (inputValue.length > 0) {
            props.onSubmitComment(inputValue)
            setInputValue('')
        }
    }

    return (
        <Hoverable
            onHoverIn={() => {
                setHover(true)
            }}
            onHoverOut={() => {
                setHover(false)
            }}>
            <View
                style={{
                    backgroundColor: 'white',
                    flexDirection: 'row',
                    width: '100%',
                    height: 48,
                    paddingTop: 20,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderTopWidth: 1,
                    borderColor: hover ? colors.green_spectaflow : iOSColors.lightGray
                }}>
                {/* TODO - remove inline styles */}
                {/* FIXME define types */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <TextInput
                    style={{
                        fontWeight: '500',
                        borderWidth: 0,
                        fontSize: 14,
                        height: 48,
                        color: colors.textPrimary,
                        paddingLeft: props.horizontalMargin,
                        outlineStyle: 'none',
                        width: '90%'
                    }}
                    placeholderTextColor={iOSColors.midGray}
                    placeholder="Type a comment..."
                    onChange={handleInputChange}
                    value={inputValue}
                    onSubmitEditing={handleSubmitComment}
                />
                <View
                    style={{
                        paddingRight: props.horizontalMargin
                    }}>
                    <MainActionButton disabled={inputValue.length === 0} type="primary" onClick={handleSubmitComment}>
                        Post
                    </MainActionButton>
                </View>
            </View>
        </Hoverable>
    )
}
