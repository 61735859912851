import styled from 'styled-components'

export const ActionBarWrap = styled.div`
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    display: flex;
    animation: fadeIn 0.75s ease-in-out;
    width: 100%;
    justify-content: space-between;
    margin-top: 12px;
    z-index: 25;

    svg {
        opacity: 0.6;
    }
`
