import React, { useEffect } from 'react'
import PriorityFilter from './PriorityFilter'
import CleaningStatusFilter from './CleaningStatusFilter'
import { Wrapper } from './style'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { filteredAreasSelector } from '../../state/selectors/areasGroups'
import { areasAtom } from '../../state/atoms'
import OccupancyFilter from './OccupancyFilter'
import DailyComments from './DailyComments'

function Filters() {
    const filteredAreas = useRecoilValue(filteredAreasSelector)
    const setAreas = useSetRecoilState(areasAtom)

    useEffect(() => {
        setAreas(filteredAreas)
    }, [filteredAreas.length])

    return (
        <Wrapper>
            <PriorityFilter />
            <DailyComments />
            <CleaningStatusFilter />
            <OccupancyFilter />
        </Wrapper>
    )
}

export default Filters
