import React, { Component } from 'react'
import { View, TouchableOpacity } from 'react-native-web'
import * as colors from '@shared/colors'
import { Initials } from '../components/initials'
export default class UserCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isSelected: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isSelected !== state.isSelected) {
            return { isSelected: props.isSelected }
        }
        return null
    }

    render() {
        const lineHeight = 50
        const cardHeight = 60
        const BUTTON_DIM = 34
        const DEBUG_BORDER = 0

        const user = this.props.user

        const opacity = this.state.isSelected ? 0.5 : 1

        return (
            <TouchableOpacity
                key={user.key}
                style={{
                    opacity: opacity,
                    flex: 1,
                    backgroundColor: colors.white,
                    borderRadius: 0,
                    borderColor: colors.blue_spectaflow,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderWidth: DEBUG_BORDER,
                    height: cardHeight,
                    flexDirection: 'row'
                }}
                testID={'user' + this.props.idx}
                accessibilityLabel={'user' + this.props.idx}
                onPress={() => {
                    if (this.state.isSelected) {
                        this.props.removeUser(user)
                    } else {
                        this.props.addUser(user)
                    }
                    this.setState({ isSelected: !this.state.isSelected })
                }}>
                <View
                    style={{
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'blue',
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginLeft: 5
                    }}>
                    <Initials
                        disabled={true}
                        item={{ initials: user.initials }}
                        currentUser={this.props.currentUser}
                        dimension={BUTTON_DIM}
                        fontSize={12}
                        noMargin={true}
                        toolTipDisabled={true}
                    />
                    <View
                        style={{
                            flexDirection: 'column',
                            borderColor: 'green',
                            borderWidth: DEBUG_BORDER,
                            height: lineHeight,
                            justifyContent: 'center',
                            marginLeft: 16
                        }}>
                        <span
                            style={{
                                fontSize: 16,
                                borderWidth: DEBUG_BORDER,
                                color: this.state.isSelected ? colors.medium_gray_spectaflow : colors.textPrimary
                            }}>
                            {user.name}
                        </span>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }
}
