import React, { useMemo, useState } from 'react'
import { MoreWrap, Wrapper } from './style'
import ButtonWithCount from 'app/components/buttons/ButtonWithCount'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { filteredAreasSelector, groupsSelector } from '../state/selectors/areasGroups'
import { AreaStruct } from '@shared/firestore-structs'
import { searchValueAtom } from '../state/atoms'
import AsyncStorage from '@react-native-community/async-storage'
import { HOUSEKEEPING_SEARCH_VALUE_KEY } from '../types'
import ContextMenu from 'app/components/ContextMenu'
import MoreGroupsDropdown from './MoreGroupsDropdown'

function GroupsButtons() {
    const groups = useRecoilValue(groupsSelector)
    const areas = useRecoilValue(filteredAreasSelector)

    const setSearchValue = useSetRecoilState(searchValueAtom)

    const [moreDropdownIsOpen, setMoreDropdownIsOpen] = useState(false)

    const groupCounts = useMemo(() => {
        return areas.reduce((counts: Record<string, number>, area: AreaStruct) => {
            const group = area.group
            if (group !== undefined) {
                counts[group] = (counts[group] || 0) + 1
            }
            return counts
        }, {})
    }, [areas])

    function onClick({ title }: { title: string }) {
        setSearchValue(title)
        AsyncStorage.setItem(HOUSEKEEPING_SEARCH_VALUE_KEY, title)
    }

    function onOptionClick(title: string) {
        onClick({ title })
        setMoreDropdownIsOpen(false)
    }

    return (
        <Wrapper>
            {groups.slice(0, 7).map((group, index) => {
                return <ButtonWithCount key={group + index} title={group} count={groupCounts[group]} onClick={onClick} />
            })}

            {groups.length > 7 && (
                <MoreWrap>
                    <ButtonWithCount title="..." onClick={() => setMoreDropdownIsOpen(true)} />
                    {moreDropdownIsOpen && (
                        <MoreGroupsDropdown
                            groups={groups}
                            groupCounts={groupCounts}
                            onBlur={() => setMoreDropdownIsOpen(false)}
                            onOptionClick={onOptionClick}
                        />
                    )}
                </MoreWrap>
            )}
        </Wrapper>
    )
}

export default GroupsButtons
