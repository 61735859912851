import { selector, selectorFamily } from 'recoil'
import { activitiesAtom, bookingsAtom, cleaningTasksAtom, rulesAtom } from '../atoms'
import { ActivityStruct, AreaStruct, BookingStruct, RuleStruct, TaskStruct } from '@shared/firestore-structs'
import { findActivitiesForArea, findBookingsForArea, findRulesForArea } from '@shared/cleaning-service'
import { sortByName, sortTimeStampDescending } from '@shared/helpers'
import { filteredAreasSelector } from './areasGroups'
import * as c from '@shared/constants'
import { Assignee, AssigneeWithCountAndAreas, TeamWorkloadGroup, WorkloadCounts } from '../../types'
import { calculatedAreaSelector } from './calculatedArea'

export const activitiesSelector = selectorFamily<ActivityStruct[], string>({
    key: 'housekeeping-activitiesSelector',
    get: (areaKey: string) => ({ get }) => findActivitiesForArea(get(activitiesAtom), areaKey)
})

export const bookingsSelector = selectorFamily<BookingStruct[], string>({
    key: 'housekeeping-bookingsSelectorFamily',
    get: (areaKey: string) => ({ get }) => findBookingsForArea(get(bookingsAtom), areaKey)
})

export const rulesSelector = selectorFamily<RuleStruct[], string>({
    key: 'housekeeping-rulesSelector',
    get: (areaKey: string) => ({ get }) => findRulesForArea(get(rulesAtom), areaKey)
})

export const cleaningTaskSelector = selectorFamily<TaskStruct | null, string>({
    key: 'housekeeping-taskSelector',
    get: areaKey => ({ get }) => getCleaningTask(areaKey, get(cleaningTasksAtom))
})

export const workloadCountsSelector = selector<WorkloadCounts>({
    key: 'housekeeping-workloadCountsSelector',
    get: ({ get }) => countWorkload(get(filteredAreasSelector).map(area => get(calculatedAreaSelector(area)).area))
})

export const teamWorkloadListSelector = selectorFamily<TeamWorkloadGroup[], boolean>({
    key: 'housekeeping-teamWorkloadListSelector',
    get: showGroups => ({ get }) => getTeamWorkloadList(get(cleaningTasksAtom), showGroups)
})

export function getCleaningTask(areaKey: string, cleaningTasks: TaskStruct[]): TaskStruct | null {
    const tasks = cleaningTasks.filter(task => task.area.key === areaKey).sort((a, b) => sortTimeStampDescending(a.created, b.created))
    return tasks.length > 0 ? tasks[0] : null
}

export function getTeamWorkloadList(cleaningTasks: TaskStruct[], showGroups: boolean): TeamWorkloadGroup[] {
    const groups = Array.from(new Set(cleaningTasks.map(task => task.area.group as string))).sort((a, b) => sortByName(a, b))
    const allAssignedUsers = cleaningTasks
        .flatMap(task => (task.assignedTo as unknown) as Assignee)
        .filter(assignedTo => assignedTo)
        .sort((a, b) => sortByName(a.name, b.name))
    const teamList = getUniqueUsers(allAssignedUsers)

    const workloadList = showGroups
        ? groups.map(group => {
              const users = teamList.filter(user => {
                  return cleaningTasks.some(
                      task => task.area.group === group && task.assignedTo?.map(assignee => assignee.key).includes(user.key as string)
                  )
              })

              const countedUsers = getAssigneesWithCountAndAreas(users, cleaningTasks, group)

              return { group, users: countedUsers }
          })
        : [{ group: '', users: getAssigneesWithCountAndAreas(teamList, cleaningTasks) }]

    return workloadList
}

export function countWorkload(areas: AreaStruct[]): WorkloadCounts {
    let checkOutCount = 0
    let turnOverCount = 0

    return areas.reduce(
        (acc, area) => {
            if (area.occupancy === c.OCCUPANCY_TURNOVER) {
                turnOverCount++
            }

            if (area.occupancy === c.OCCUPANCY_STAYOVER && area.cleaningStatus !== c.CLEANING_STATUS_NO_SERVICE) {
                acc.unclean50Count++
            } else if (area.occupancy === c.OCCUPANCY_STAYOVER_80 && area.cleaningStatus !== c.CLEANING_STATUS_NO_SERVICE) {
                acc.unclean80Count++
            } else if (area.occupancy === c.OCCUPANCY_CHECKOUT) {
                checkOutCount++
            } else if (area.occupancy === c.OCCUPANCY_CHECKIN || area.occupancy === c.OCCUPANCY_TURNOVER) {
                acc.checkInCount++
            } else if (area.cleaningStatus === c.CLEANING_STATUS_NO_SERVICE) {
                acc.noServiceCount++
            } else {
                acc.otherWorkCount++
            }

            acc.unclean100Count = checkOutCount + turnOverCount

            return acc
        },
        {
            unclean50Count: 0,
            unclean80Count: 0,
            unclean100Count: 0,
            checkInCount: 0,
            noServiceCount: 0,
            otherWorkCount: 0
        }
    )
}

function getUniqueUsers(users: Assignee[] | AssigneeWithCountAndAreas[]) {
    return Array.from(new Set(users.map(user => user.key)))
        .map(key => users.find(user => user.key === key))
        .filter((user): user is AssigneeWithCountAndAreas => user !== undefined)
}

function getAssigneesWithCountAndAreas(users: Assignee[], cleaningTasks: TaskStruct[], group?: string): AssigneeWithCountAndAreas[] {
    const cleaningTasksFilteredByGroup = group ? cleaningTasks.filter(task => task.area.group === group) : cleaningTasks
    return users.map(assignee => {
        const count = cleaningTasksFilteredByGroup.flatMap(task => task.assignedTo).filter(user => user?.key === assignee?.key).length
        const areas = cleaningTasksFilteredByGroup
            .filter(task => task.assignedTo?.some(user => user.key === assignee.key))
            .map(task => task.area) as AreaStruct[]

        return {
            ...assignee,
            count,
            areas
        }
    })
}
