import styled from 'styled-components'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'

export const Button = styled.button`
    outline: none;
    border: none;
    height: 30px;
    display: flex;
    column-gap: 3.3px;
    background-color: ${colors.light_gray_spectaflow};
    border-radius: 20px;
    align-items: center;
    padding: 0 10px;
    color: ${iOSColors.gray};
    font-weight: 500;

    &:hover {
        opacity: 0.5;
    }
`

export const Title = styled.span`
    font-size: 1rem;
`
export const Count = styled.span`
    margin-top: 2px;
    font-size: 0.769rem;
`
