// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Input, DatePickerField } from '../../../../_metronic/_partials/controls'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { ModalFormFooter } from '../../../components/modals/ModalFormFooter'
import { Dropdown } from '../../../components/forms/Dropdown'
import { InitialsView } from '../../../components/initials-view'
import { Checkbox } from '../../../components/forms/Checkbox'
import { PriorityAndSameAsLastBtnWrap, RedButton, InitialsAndPriorityWrap, ExtraModelContentWrap } from './style'
import { TaskboardContext } from '@shared/traces-types'
import { formatUserToOption } from '@shared/helpers'
import { useRecoilValue } from 'recoil'
import { BreadCrumbs, GeneralVisual } from '../../TasksBody/Column/TaskRow/style'
import { bookingsAtom, subscriptionParamsAtom } from '../../state/atoms.ts'
import { constructBreadcrumbs } from '@shared/task-helpers'
import generalIcon from '../../../img/general-task.svg'
import { getLastAssignee } from 'app/utils/helpers'

//TODO - refactor to the typescript
export function TaskEditForm({ areasOptions, usersOptions, saveTask, task, actionsLoading, dataLoading, onHide, taskboardContext }) {
    const { user } = useContext(AuthContext)
    const [selectedArea, setSelectedArea] = useState()
    const [selectedUsers, setSelectedUsers] = useState([])

    const reservationContext = taskboardContext === TaskboardContext.RESERVATIONS || task.reservationId

    const bookings = useRecoilValue(bookingsAtom)

    const breadcrumbItems = constructBreadcrumbs(task, taskboardContext, bookings, selectedArea)

    const { hasSubscriptionAccess } = useRecoilValue(subscriptionParamsAtom)

    const TaskEditSchema = Yup.object().shape({
        name: Yup.string().min(1, 'Minimum 3 symbols').max(200, 'Maximum 200 symbols').required('Name is required'),
        startDate: Yup.date().required('Due date is required'),
        areaKey: reservationContext ? null : Yup.string().required('Unit is required')
    })

    useEffect(() => {
        if (task.assignedTo) {
            const userKeys = task.assignedTo.map(u => u.key)
            const sUsers = usersOptions.filter(u => userKeys.includes(u.value))

            if (sUsers) {
                setSelectedUsers(sUsers)
            }
        }
        const sArea = areasOptions.find(a => a.value === task.areaKey)
        if (sArea) {
            setSelectedArea(sArea)
        }
    }, [task, areasOptions, usersOptions])

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={task}
                validationSchema={TaskEditSchema}
                validateOnBlur={false}
                validateOnChange={false}
                validateOnMount={false}
                onSubmit={values => {
                    saveTask(values)
                }}>
                {({ handleSubmit, values, setFieldValue, validateField }) => {
                    async function onSameAsLastClick() {
                        const lastAssignee = await getLastAssignee()
                        setFieldValue('assignedTo', lastAssignee)
                        setSelectedUsers(lastAssignee.map(user => formatUserToOption(user)))
                    }

                    return (
                        <>
                            <Modal.Body className="overlay overlay-block cursor-default pt-0">
                                <ExtraModelContentWrap>
                                    <div className="pb-7 d-flex justify-content-between align-items-center">
                                        {breadcrumbItems.length > 0 && (
                                            <BreadCrumbs fontSize="1.1rem">
                                                {breadcrumbItems.map((item, index) => (
                                                    <span key={index}>{item}</span>
                                                ))}
                                            </BreadCrumbs>
                                        )}
                                        <InitialsAndPriorityWrap>
                                            <div>
                                                <InitialsView
                                                    maxCountToDisplay={3}
                                                    circleDimension={30}
                                                    fontSize={11}
                                                    justifyContent={'flex-start'}
                                                    currentUser={user}
                                                    noMargin={!values.priority}
                                                    assignedTo={selectedUsers.map(a => ({ name: a.label, initials: a.initials }))}
                                                />
                                            </div>
                                            {values.priority && (
                                                <span className="symbol symbol-danger symbol-circle symbol-30">
                                                    <span className="symbol-label font-size-sm font-weight-bolder text-white">
                                                        <i className={`specta-priority-low text-white`} />
                                                    </span>
                                                </span>
                                            )}
                                        </InitialsAndPriorityWrap>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <GeneralVisual>
                                            <img src={generalIcon} />
                                        </GeneralVisual>

                                        <div className="d-flex">
                                            {task.reservationId && task.pmsLinkUrl && (
                                                <a
                                                    className="align-self-end"
                                                    href={task.pmsLinkUrl}
                                                    rel="noopener noreferrer"
                                                    target="_parent">
                                                    <RedButton>{`Reservation > ${task.reservationId}`}</RedButton>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </ExtraModelContentWrap>
                                <Form className="form form-label-right">
                                    <div className="form-group">
                                        <Field
                                            name="name"
                                            component={Input}
                                            placeholder="Enter name of task"
                                            label="Name"
                                            disabled={actionsLoading || dataLoading}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <Field
                                            name="startDate"
                                            component={DatePickerField}
                                            placeholder="Pick a due date"
                                            label="Due Date"
                                            dateFormat="MMM dd, yyyy"
                                            iconInput={true}
                                            disabled={actionsLoading || dataLoading}
                                            required
                                        />
                                    </div>

                                    {taskboardContext !== TaskboardContext.RESERVATIONS && !task.reservationId && (
                                        <div className="form-group">
                                            <Field
                                                isDisabled={actionsLoading || dataLoading}
                                                placeholder="Select unit..."
                                                name="areaKey"
                                                isSearchable
                                                value={selectedArea}
                                                options={areasOptions}
                                                onChange={area => {
                                                    setSelectedArea(area)
                                                    setFieldValue('areaKey', area.value)

                                                    setTimeout(() => {
                                                        validateField('areaKey')
                                                    })
                                                }}
                                                currentUser={user}
                                                noOptionsMessage={() => 'No units found try another search'}
                                                component={Dropdown}
                                                label="Unit"
                                                required={taskboardContext !== TaskboardContext.RESERVATIONS}
                                            />
                                        </div>
                                    )}

                                    <div className="form-group">
                                        <Field
                                            isDisabled={actionsLoading || dataLoading || !hasSubscriptionAccess}
                                            placeholder="Select users..."
                                            name="assignedTo"
                                            isSearchable
                                            isMulti
                                            value={selectedUsers}
                                            options={usersOptions}
                                            onChange={users => {
                                                setSelectedUsers(users)
                                                values.assignedTo = users.map(u => ({ name: u.label, key: u.value, initials: u.initials }))
                                            }}
                                            currentUser={user}
                                            noOptionsMessage={() => 'No users found try another search'}
                                            component={Dropdown}
                                            showFeatureLock={!hasSubscriptionAccess}
                                            featureId="assign-tasks"
                                            label="Assign users"
                                        />
                                    </div>

                                    <div className="form-group row">
                                        <PriorityAndSameAsLastBtnWrap className="col-lg-12">
                                            <Field
                                                name="priority"
                                                component={Checkbox}
                                                label="Is priority"
                                                isSelected={values.priority}
                                                disabled={dataLoading || actionsLoading}
                                                outline
                                                onClick={e => setFieldValue('priority', e.target.checked)}
                                            />

                                            <RedButton type="button" disabled={!hasSubscriptionAccess} onClick={onSameAsLastClick}>
                                                Assign same as last
                                            </RedButton>
                                        </PriorityAndSameAsLastBtnWrap>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className="border-top-0">
                                <ModalFormFooter
                                    onHide={onHide}
                                    onSubmit={handleSubmit}
                                    dataLoading={dataLoading}
                                    actionsLoading={actionsLoading}
                                    type="save"
                                />
                            </Modal.Footer>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}
