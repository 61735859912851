export default {
    formId: 'ruleForm',
    formField: {
        name: {
            name: 'name',
            label: 'Rule name',
            requiredErrorMsg: 'Rule name required'
        },
        description: {
            name: 'description',
            label: 'Description'
        },
        trigger: {
            name: 'trigger',
            label: 'Trigger',
            requiredErrorMsg: 'Trigger required'
        },
        taskName: {
            name: 'taskName',
            label: 'Task name',
            requiredErrorMsg: 'Task name required'
        },
        taskColor: {
            name: 'taskColor',
            label: 'Task color',
            requiredErrorMsg: 'Task color required'
        }
    }
}
