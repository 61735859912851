import React, { useEffect, useState } from 'react'
import { LoginFormWrapper } from './style'
import { Formik, Form, Field } from 'formik'
import Input from '../../../components/forms/Input/index'
import RectangularButton from '../../../components/buttons/RectangularButton'
import Select from '../../../components/forms/Select/index'
import * as Yup from 'yup'
import { formatPhoneInputValue, phoneNumberValidationTest } from '../../../utils/formatters'
import { getAllCountries, getGeoLocation, getCountryItem } from '../../../components/country-selector'

const LoginForm = ({ onSubmit }) => {
    const [allCountries, setAllCountries] = useState([])
    const [selectedCountry, setSelectedCountry] = useState('')

    useEffect(() => {
        const allCountries = getAllCountries()
        const US = getCountryItem('US', allCountries)

        setAllCountries(allCountries)
        setSelectedCountry(US)
        getGeoLocation(allCountries, country => setSelectedCountry(country ? country : US))
    }, [])

    const loginScheme = Yup.object().shape({
        phoneNumber: Yup.string()
            .test('isValid', 'Phone number must be valid', value => phoneNumberValidationTest(value, selectedCountry))
            .required('Phone number is required')
    })

    return (
        <LoginFormWrapper>
            <Formik
                validationSchema={loginScheme}
                enableReinitialize
                initialValues={{ phoneNumber: '' }}
                onSubmit={values => onSubmit({ values, selectedCountry })}>
                {({ handleSubmit, setFieldValue, values, errors }) => {
                    const { phoneNumber } = values
                    const disabled = Object.keys(errors).length !== 0 || phoneNumber.length === 0

                    return (
                        <Form>
                            <Select
                                value={selectedCountry}
                                label={'Country'}
                                options={allCountries}
                                onChange={country => setSelectedCountry(country)}
                            />

                            <Field
                                autoFocus
                                name="phoneNumber"
                                component={Input}
                                value={formatPhoneInputValue(phoneNumber, selectedCountry)}
                                label={'Phone Number'}
                                placeholder={'Your phone number'}
                                onChange={e => setFieldValue('phoneNumber', e.target.value)}
                                withoutValidation
                            />

                            <div className={'btn-wrap'}>
                                <RectangularButton disabled={disabled} type="submit" width={'181px'} height={'64px'} onClick={handleSubmit}>
                                    Login
                                </RectangularButton>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </LoginFormWrapper>
    )
}

export default LoginForm
