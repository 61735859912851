import React, { useState } from 'react'
import LogoutIcon from './LogoutIcon'
import { LogoutButtonWrap } from './style'
import Alert from '../../../../../../src/app/components/alerts/Alert'
import {logout} from '../../../../../app/utils/firebase'

const LogoutButton = ({ asideIsOpen = true, padding  }) => {
    const [showLogoutModal, setShowLogoutModal] = useState(false)

 
    return (
        <LogoutButtonWrap padding={padding} onClick={() => setShowLogoutModal(true)}>
            <div>
                <LogoutIcon />
                {asideIsOpen && <span>Logout</span>}
            </div>

            {showLogoutModal && (
                <Alert
                    title={'Log out?'}
                    text={'Are you sure you want to log out?'}
                    onConfirm={logout}
                    onCancel={() => setShowLogoutModal(false)}
                />
            )}
        </LogoutButtonWrap>
    )
}

export default LogoutButton
