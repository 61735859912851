import React from 'react'
import { getPages, getPagesCount } from '../../../_helpers'

export function PaginationLinks({ paginationProps }) {
    const { totalSize, sizePerPage, page, paginationSize } = paginationProps
    const pagesCount = getPagesCount(totalSize, sizePerPage)
    const pages = getPages(page, pagesCount, paginationSize)

    const handleFirstPage = ({ onPageChange }) => {
        onPageChange(1)
    }

    const handlePrevPage = ({ page, onPageChange }) => {
        if(page > 1) {
            onPageChange(page - 1)
        }
    }

    const handleNextPage = ({ page, onPageChange }) => {
        if (page < pagesCount) {
            onPageChange(page + 1)
        }
    }

    const handleLastPage = ({ onPageChange }) => {
        onPageChange(pagesCount)
    }

    const handleSelectedPage = ({ onPageChange }, pageNum) => {
        onPageChange(pageNum)
    }

    const moreLeftPagesCondition = page > paginationSize
    const moreRightPagesCondition = page + 3 <= pagesCount

    const morePages = (
        <a style={{ cursor: 'default' }} className="btn btn-icon btn-sm border-0 mr-2 my-1">
            ...
        </a>
    )

    const disabledClass = pagesCount > 1 ? '' : 'disabled'
    return (
        <>
            {pagesCount < 2 && <></>}
            {pagesCount > 1 && (
                <>
                    <div className={`d-flex flex-wrap py-2 mr-3 ${disabledClass}`}>
                        <a
                            onClick={() => handleFirstPage(paginationProps)}
                            className="btn btn-icon btn-sm btn-light btn-hover-success mr-2 my-1">
                            <i className="flaticon2-fast-back icon-xs" />
                        </a>

                        <a
                            onClick={() => handlePrevPage(paginationProps)}
                            className="btn btn-icon btn-sm btn-light btn-hover-success mr-2 my-1">
                            <i className="flaticon2-back icon-xs" />
                        </a>

                        {moreLeftPagesCondition && morePages}

                        {pages.map(p => (
                            <a
                                key={p}
                                onClick={() => handleSelectedPage(paginationProps, p)}
                                className={`btn btn-icon btn-sm border-0 btn-light ${
                                    page === p ? ' btn-hover-success active' : ''
                                } mr-2 my-1`}>
                                {p}
                            </a>
                        ))}

                        {moreRightPagesCondition && morePages}

                        <a
                            onClick={() => handleNextPage(paginationProps)}
                            className="btn btn-icon btn-sm btn-light btn-hover-success mr-2 my-1">
                            <i className="flaticon2-next icon-xs" />
                        </a>

                        <a
                            onClick={() => handleLastPage(paginationProps)}
                            className="btn btn-icon btn-sm btn-light btn-hover-success mr-2 my-1">
                            <i className="flaticon2-fast-next icon-xs" />
                        </a>
                    </div>
                </>
            )}
        </>
    )
}
