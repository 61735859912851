import { Checkbox } from '../../app/components/forms/Checkbox.js'
import React, { useEffect } from 'react'

function groupingItemOnSelect(props) {
    const { ids, setIds, id } = props

    if (ids.some(i => i === id)) {
        setIds(ids.filter(i => i !== id))
    } else {
        const newIds = [...ids]
        newIds.push(id)
        setIds(newIds)
    }
}

function groupingAllOnSelect(props) {
    const { isSelected, setIds, entities } = props
    if (!isSelected) {
        const allIds = []
        entities.forEach(el => allIds.push(el.id))
        setIds(allIds)
    } else {
        setIds([])
    }

    return isSelected
}

export function getSelectRow({ entities, ids, setIds }) {
    return {
        mode: 'checkbox',
        hideSelectAll: false,
        onSelectAll: () => {
            const isSelected = entities && entities.length > 0 && entities.length === ids.length

            groupingAllOnSelect({ isSelected, entities, setIds })
        },

        onSelect: (row, isSelect, rowIndex) => {
            groupingItemOnSelect({ ids, setIds, id: entities[rowIndex].id })
        },
        selectionRenderer: ({ mode, checked, disabled }) => {
            return (
                <label className="checkbox checkbox-outline checkbox-outline-2x checkbox-specta-blue">
                    <input
                        type="checkbox"
                        value={checked}
                        checked={checked}
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    />
                    <span></span>
                </label>
            )
        },
        selectionHeaderRenderer: ({ checked, disabled }) => {
            return (
                <label className="checkbox checkbox-outline checkbox-outline-2x checkbox-specta-blue">
                    <input
                        type="checkbox"
                        value={checked}
                        checked={checked}
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    />
                    <span></span>
                </label>
            )
        }
    }
}
