import React, { Suspense, useContext, useEffect } from 'react'
import LoadingView from '../utils/loading-view'
import { SubscriptionContext } from '../modules/Subscriptions/SubscriptionContext'
import TasksPage from './pages/TasksPage'
import { useLocation } from 'react-router-dom'
import Tasks from 'app/tasks'
import { LOCAL_STORAGE } from 'app/utils/constants'

function TaskboardStandalone() {
    const { subscriptionsLoaded } = useContext(SubscriptionContext)

    if (!subscriptionsLoaded) return <LoadingView />

    return (
        <Suspense fallback={<LoadingView />}>
            <TasksPage />
        </Suspense>
    )
}

export default TaskboardStandalone
