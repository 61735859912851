import React from 'react'
import { planningInfo } from '../../../GuestsUIHelpers'

export const PlanningInfo = () => {
    return (
        <>
            {planningInfo.map(x => (
                <div key={x.name} className="d-flex align-items-center">
                    <span
                        className={`rounded-circle text-center bg-${x.colorClass} cursor-pointer`}
                        style={{ height: '1.2rem', width: '1.2rem' }}></span>
                    <span className="font-size-lg font-weight-bolder ml-2">{x.name}</span>
                </div>
            ))}
        </>
    )
}
