import React, { useMemo } from 'react'
import { Box, SelectorWrap, TitleWrap } from '../style'
import HousekeepingStatusIcon from 'app/img/housekeeping-status-white.svg?react'
import CleaningStatusSelect from 'app/housekeeping/cleaning-status-select'
import Timer from 'app/components/Timer'
import { TimerWrap } from './style'
import { AreaStruct, CleaningStruct } from '@shared/firestore-structs'
import { getTimerDisplay } from '@shared/timer-helpers'

interface Props {
    cleaningStatusColor: string
    cleaningStatus: AreaStruct['cleaningStatus']
    cleaning: CleaningStruct | null
    onCleaningStatusChange: (cleaningStatus: AreaStruct['cleaningStatus']) => void
}

function HousekeepingStatusSelector({ cleaningStatusColor, cleaningStatus, cleaning, onCleaningStatusChange }: Props) {
    const timerDisplay = useMemo(() => getTimerDisplay(cleaning), [cleaning])

    return (
        <Box>
            <SelectorWrap>
                <TitleWrap iconColor={cleaningStatusColor}>
                    <HousekeepingStatusIcon />
                    <h1>{'Housekeeping status'}</h1>
                </TitleWrap>

                <CleaningStatusSelect
                    selectedStatus={cleaningStatus}
                    onChange={(item: { color: string; label: string; value: AreaStruct['cleaningStatus'] }) =>
                        onCleaningStatusChange(item.value)
                    }
                />

                <TimerWrap>
                    <Timer {...timerDisplay} />
                </TimerWrap>
            </SelectorWrap>
        </Box>
    )
}

export default HousekeepingStatusSelector
