import { AreaStruct, BookingStruct, OrgStruct } from '@shared/firestore-structs'
import { calculateArea } from 'app/modules/HousekeepingDashboard/state/selectors/calculatedArea'
import { selector, selectorFamily } from 'recoil'
import { activitiesAtom, areaAtom, bookingsAtom, rulesAtom } from '../atoms'
import { selectedDateNumberSelector } from './selectedDateNumber'
import { currentOrganizationAtom } from 'app/modules/Auth/atoms'
import { findLeadBooking } from '@shared/booking-service'
import { isDateInMonth, sectionMonths } from '@shared/date-helpers'
import * as c from '@shared/constants'
import { CleaningScheduleSection } from 'app/modules/HousekeepingDashboard/types'

export const calculatedAreaSelector = selector({
    key: 'housekeeping-modal-calculatedArea',
    get: ({ get }) => {
        const area = get(areaAtom) as AreaStruct
        const bookings = get(bookingsAtom)
        const activities = get(activitiesAtom)
        const rules = get(rulesAtom)
        const selectedDateNumber = get(selectedDateNumberSelector)
        const currentOrganization = get(currentOrganizationAtom) as OrgStruct

        return calculateArea({
            area,
            bookings,
            activities,
            rules,
            selectedDateNumber,
            currentOrganization
        })
    }
})

export const leadBookingSelector = selector({
    key: 'housekeeping-modal-leadBooking',
    get: ({ get }) => {
        const { area } = get(calculatedAreaSelector)
        const bookings = get(bookingsAtom)
        const selectedDateNumber = get(selectedDateNumberSelector)

        return findLeadBooking(area, bookings, selectedDateNumber)
    }
})

export const cleaningScheduleSectionsSelector = selectorFamily<
    CleaningScheduleSection[],
    { optInDates: BookingStruct['optInDates']; optOutDates: BookingStruct['optOutDates'] }
>({
    key: 'housekeeping-modal-cleaningScheduleSections',
    get: ({ optInDates, optOutDates }) => ({ get }) => {
        const area = get(areaAtom) as AreaStruct
        const activities = get(activitiesAtom)
        const rules = get(rulesAtom)
        const currentOrganization = get(currentOrganizationAtom) as OrgStruct
        const booking = get(leadBookingSelector)

        if (!booking?.bookingDates) return []

        const months = sectionMonths(booking.bookingDates.map(d => parseInt(d)))
        const bookingDates = [...booking.bookingDates].sort((a, b) => parseInt(a) - parseInt(b))

        const calculatedAreas = bookingDates.map(date =>
            calculateArea({
                area,
                bookings: [{ ...booking, optInDates, optOutDates }],
                activities,
                rules,
                selectedDateNumber: parseInt(date),
                currentOrganization
            })
        )

        const schedule = calculatedAreas.map(({ area: { occupancy, cleaningStatus } }, index) => {
            const subheader =
                occupancy === c.OCCUPANCY_CHECKIN
                    ? 'Check in'
                    : [c.OCCUPANCY_CHECKOUT, c.OCCUPANCY_TURNOVER].includes(occupancy)
                    ? 'Check out'
                    : ''

            return {
                date: parseInt(bookingDates[index]),
                subheader,
                cleaningStatus,
                occupancy
            }
        })

        const sections = Object.keys(months).map(month => ({
            month,
            schedule: schedule.filter(s => isDateInMonth(s.date, month))
        }))

        return sections
    }
})
