import { selectorFamily } from 'recoil'
import { ActivityStruct, AreaStruct, BookingStruct, OrgStruct, RuleStruct } from '@shared/firestore-structs'
import { activitiesSelector, bookingsSelector, cleaningTaskSelector, rulesSelector } from './areaRelatedData'
import { calculateCleaningStatus, calculateGuestStatus } from '@shared/cleaning-service'
import { ACTIVITY_TYPE_CLEANING_PRIORITY } from '@shared/txt-constants'
import { selectedDateNumberSelector } from './selectedDateNumber'
import { currentOrganizationAtom } from 'app/modules/Auth/atoms'
import { structuredClone } from '@shared/polyfills'

interface CalculatedAreaWithRule {
    area: AreaStruct
    ruleName: string
    activeRule: RuleStruct
}

export interface CalculateArea {
    area: AreaStruct
    bookings: BookingStruct[]
    activities: ActivityStruct[]
    rules: RuleStruct[]
    selectedDateNumber: number
    currentOrganization: OrgStruct
}

export const calculatedAreaSelector = selectorFamily<CalculatedAreaWithRule, AreaStruct>({
    key: 'housekeeping-unitSelector',
    get: (area: AreaStruct) => ({ get }) => {
        const bookings = get(bookingsSelector(area.key))
        const activities = get(activitiesSelector(area.key))
        const rules = get(rulesSelector(area.key))
        const selectedDateNumber = get(selectedDateNumberSelector)
        const currentOrganization = get(currentOrganizationAtom) as OrgStruct

        return calculateArea({
            area,
            bookings,
            activities,
            rules,
            selectedDateNumber,
            currentOrganization
        })
    }
})

export const calculatedAreasSelector = selectorFamily<CalculatedAreaWithRule[], AreaStruct[]>({
    key: 'housekeeping-calculatedAreas',
    get: (areas: AreaStruct[]) => ({ get }) => areas.map(area => get(calculatedAreaSelector(area)))
})

export const areaPrioritySelector = selectorFamily<boolean, string>({
    key: 'housekeeping-areaPrioritySelector',
    get: (areaKey: string) => ({ get }) => {
        const activities = get(activitiesSelector(areaKey))
        const task = get(cleaningTaskSelector(areaKey))

        const cleaningPriorityActivity: ActivityStruct | undefined = activities.find(
            activity => activity.type === ACTIVITY_TYPE_CLEANING_PRIORITY
        )
        const priorityActivity = cleaningPriorityActivity ? (cleaningPriorityActivity.change.after as boolean) : false

        return task ? task.priority : priorityActivity
    }
})

export function calculateArea({
    area,
    bookings,
    activities,
    rules,
    selectedDateNumber,
    currentOrganization
}: CalculateArea): CalculatedAreaWithRule {
    const { cleaningStatus, occupancy, ruleName, activeRule } = calculateCleaningStatus(
        area.cleaningStatus,
        selectedDateNumber,
        currentOrganization as OrgStruct,
        bookings,
        activities,
        rules,
        area
    )
    const { guestCheckedIn, guestCheckedOut } = calculateGuestStatus(occupancy, bookings, selectedDateNumber)

    return {
        area: structuredClone({ ...area, cleaningStatus, occupancy, guestCheckedIn, guestCheckedOut }),
        ruleName: (ruleName || rules[0]?.name) ?? '',
        activeRule: (activeRule || rules[0]) ?? ''
    }
}
