import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
    margin-bottom: 37px;
    flex-wrap: wrap;
`

export const MoreWrap = styled.div`
    position: relative;
`
