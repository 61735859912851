import React, { useRef, useState } from 'react'
import { iOSColors } from '@shared/react-native-typography'
import { View } from 'react-native-web'
import AreaSelector from '../issues/area-selector'
import firebase from '../utils/firebase'
import { changeArea } from '@shared/issue-data'
import styled from 'styled-components'

const AreaSelectorContainer = styled.div`
    position: absolute;
    top: -1;
    left: 0;
    width: 100%;
`
const AreaBredCrumbsContainer = styled(View)({
    borderColor: 'blue',
    borderWidth: 0,
    justifyContent: 'center',
    position: 'relative'
})
export const AreaBredCrumbs = ({
    area,
    task,
    issue,
    spaceBetween = null,
    fontSize,
    fontColor = null,
    fontWeight,
    arrowColor = null,
    currentUser,
    isEditable
}) => {
    const [editUnitVisible, setEditUnitVisible] = useState(false)
    const dropDownRef = useRef()
    const font_size = fontSize ? fontSize : 11
    const space_between = spaceBetween ? spaceBetween : 6
    const arrow_font_size = font_size - 3
    const font_color = fontColor ? fontColor : iOSColors.gray
    const arrow_color = arrowColor ? arrowColor : '#A1A1A1'
    const font_weight = fontWeight ? fontWeight : '600'

    const showAreaDescription = area.description && area.description !== '' && area.description !== ' '

    const openEditUnit = () => {
        setEditUnitVisible(true)
        setTimeout(() => {
            isEditable && dropDownRef?.current?.focus()
        }, 100)
    }

    const onUnitChange = async areaOption => {
        const { value } = areaOption

        // FIXME - this is a very bad move, we should not be updating the issue like that
        issue.area.name = areaOption.name
        changeArea(firebase, { currentUser, issueKey: issue.key, areaKey: value, taskKey: task ? task.key : null })

        setEditUnitVisible(false)
    }

    const onUnitChangeBlur = () => {
        setEditUnitVisible(false)
    }

    return (
        <AreaBredCrumbsContainer>
            <span style={{ flexWrap: 'wrap', fontSize: font_size, fontWeight: font_weight, color: font_color }}>
                {area.group}
                {showAreaDescription && (
                    <span
                        style={{
                            fontSize: arrow_font_size,
                            color: arrow_color,
                            marginLeft: space_between,
                            marginRight: space_between
                        }}
                        className="specta-arrow-right"
                    />
                )}
                {showAreaDescription && area.description}
                <span
                    style={{ fontSize: arrow_font_size, color: arrow_color, marginLeft: space_between, marginRight: space_between }}
                    className="specta-arrow-right"
                />
                <span
                    style={
                        isEditable && {
                            cursor: 'pointer'
                        }
                    }
                    onClick={openEditUnit}>
                    {area.name}
                </span>
            </span>
            {isEditable && editUnitVisible && (
                <AreaSelectorContainer>
                    <AreaSelector
                        width="100%"
                        setRef={ref => (dropDownRef.current = ref)}
                        currentUser={currentUser}
                        onChange={onUnitChange}
                        onBlur={onUnitChangeBlur}
                        setAreasGroups={() => {}}
                    />
                </AreaSelectorContainer>
            )}
        </AreaBredCrumbsContainer>
    )
}
