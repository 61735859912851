export const SIDE_MARGIN = 40

export function updateDimensions(callback) {
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
        height = w.innerHeight || documentElement.clientHeight || body.clientHeight

    if (callback) {
        callback(width, height)
    }
}

export function getWidthForMainView(screenWidth) {
    let widthToRender = null
    if (screenWidth >= 1100) {
        widthToRender = 1000
    } else if (screenWidth >= 1000 && screenWidth < 1100) {
        widthToRender = 900
    } else if (screenWidth >= 900 && screenWidth < 1000) {
        widthToRender = 800
    } else if (screenWidth >= 800 && screenWidth < 900) {
        widthToRender = 700
    } else if (screenWidth >= 700 && screenWidth < 800) {
        widthToRender = 600
    } else if (screenWidth >= 600 && screenWidth < 600) {
        widthToRender = 500
    } else {
        widthToRender = 500
    }

    return widthToRender
}

//1000
export function getWidthModalAIssue(screenWidth) {
    let widthToRender = null
    if (screenWidth >= 1100) {
        widthToRender = 900
    } else if (screenWidth >= 1000 && screenWidth < 1100) {
        widthToRender = 800
    } else if (screenWidth >= 900 && screenWidth < 1000) {
        widthToRender = 700
    } else if (screenWidth >= 800 && screenWidth < 900) {
        widthToRender = 600
    } else if (screenWidth >= 700 && screenWidth < 800) {
        widthToRender = 500
    } else if (screenWidth >= 600 && screenWidth < 600) {
        widthToRender = 400
    } else {
        widthToRender = 400
    }

    return widthToRender
}

//63
export function getHeightAIssueModal(screenHeight) {
    let heightToRender = null
    if (screenHeight >= 1100) {
        heightToRender = 1050
    } else if (screenHeight >= 1000 && screenHeight < 1100) {
        heightToRender = 950
    } else if (screenHeight >= 900 && screenHeight < 1000) {
        heightToRender = 850
    } else if (screenHeight >= 800 && screenHeight < 900) {
        heightToRender = 750
    } else if (screenHeight >= 700 && screenHeight < 800) {
        heightToRender = 650
    } else if (screenHeight >= 600 && screenHeight < 600) {
        heightToRender = 550
    } else {
        heightToRender = 550
    }

    return heightToRender
}
