import React, { useContext } from 'react'
import { DashboardContext } from './DashboardContext'
import { getWorkloadCounts } from './helpers'
import { CardHeader, CardHeaderCounter } from '../../components/controls/Card'
import { Workload } from '../../components/workload'
import { iOSColors } from '@shared/react-native-typography'
import { HoverableImageButton } from '../../components/buttons/hoverable-image-button'
import * as uc from '../../utils/constants'
import { SubscriptionCheck } from '../../components/upgrade-signals'
import MainActionButton from '../../components/buttons/MainActionButton'

const Header = ({ areas }: { areas: any[] }) => {
    const { setShowQuickGuideModal, setShowActionBar, showActionBar, setComponentControlsDisplay, componentControlsDisplay } = useContext(
        DashboardContext
    )

    const wc = getWorkloadCounts(areas)
    const workloadCounts = {
        unclean50Count: wc.unclean50TaskCount,
        unclean80Count: wc.unclean80TaskCount,
        unclean100Count: wc.unclean100TaskCount,
        checkInCount: wc.checkinTaskCount,
        noServiceCount: wc.noServiceTaskCount
    }

    const onAssignStaff = () => {
        // if (componentControlsDisplay) {
        //     setComponentControlsDisplay(false)
        // } else {
        //     setComponentControlsDisplay(true)
        // }
        setShowActionBar(!showActionBar)
    }

    return (
        <CardHeader className="title-x-x" title="Housekeeping" count={areas.length}>
            <CardHeaderCounter className="joyride-2">
                <Workload
                    fontSize={15}
                    fontWeight={'500'}
                    color={iOSColors.black}
                    opacity={0.6}
                    counts={workloadCounts}
                    tooltipDisabled={true}
                />
            </CardHeaderCounter>

            <div style={{ marginLeft: 9, display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="joyride-1">
                {/*{this.state.dataIsReady && (*/}
                <HoverableImageButton
                    dimension={22}
                    hoverOpacity={uc.HOVER_OPACITY}
                    imageSource={require('../../img/quick-guide/quick-guide-button.svg')}
                    imgStyle={{ marginTop: 4, height: 22, width: 22 }}
                    // opacity={0.4}
                    onPress={() => {
                        setShowQuickGuideModal(true)
                    }}
                    tooltipText={'Quick guide'}
                />
                {/*)}*/}
            </div>
            <div className="col d-flex justify-content-end align-items-center">
                <SubscriptionCheck
                    featureId="assign-housekeeping"
                    permissionId="assign-housekeeping-assign"
                    onAvailable={() => (
                        <MainActionButton type="primary" size="large" onClick={onAssignStaff}>
                            Assign Staff
                        </MainActionButton>
                    )}
                    onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                        return (
                            <MainActionButton
                                type="primary"
                                size="large"
                                onClick={() => {
                                    // this.openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                }}>
                                Assign Staff
                            </MainActionButton>
                        )
                    }}
                />
            </div>
        </CardHeader>
    )
}

export default Header
