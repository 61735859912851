import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { subscriptionParamsAtom, upgradeMessageModalIsOpenAtom, showNewIssueModalAtom } from '../state/atoms'
import { useAreas } from '../state/hooks/useAreas'
import { useIssues } from '../state/hooks/useIssues'
import { useUsers } from '../state/hooks/useUsers'
import { AreaStruct, UserStruct, IssueStruct } from '@shared/dataObjects'
import MainActionButton from '../../components/buttons/MainActionButton'
import { Wrapper } from './style'

export default function Header() {
    const setShowNewIssueModal: React.Dispatch<React.SetStateAction<boolean>> = useSetRecoilState(showNewIssueModalAtom)
    const { areas }: { areas: AreaStruct[] } = useAreas()
    const { users }: { users: UserStruct[] } = useUsers()
    const { filteredIssues }: { filteredIssues: IssueStruct[] } = useIssues()

    const disabled: boolean = areas.length === 0 || users.length === 0

    const { hasSubscriptionAccess }: { hasSubscriptionAccess: boolean } = useRecoilValue(subscriptionParamsAtom)
    const setUpgradeMessageIsOpen: React.Dispatch<React.SetStateAction<boolean>> = useSetRecoilState(upgradeMessageModalIsOpenAtom)

    function onNewIssueClick(): void {
        if (!hasSubscriptionAccess) {
            setUpgradeMessageIsOpen(true)
            return
        }
        setShowNewIssueModal(true)
    }

    return (
        <>
            <Wrapper className="card-header w-100 d-flex row align-items-center">
                <div className="card-title title-x-x col">
                    <h3 className="card-label text-dark">Issues</h3>
                    <div className="card-counter">
                        <h3 className="title-number ml-2">({filteredIssues.length})</h3>
                    </div>
                </div>

                <MainActionButton type="primary" size="large" disabled={disabled} onClick={onNewIssueClick}>
                    New Issue
                </MainActionButton>
            </Wrapper>
        </>
    )
}
