import React from 'react'
import { View } from 'react-native-web'
import { Initials } from './initials'
import * as helpers from '@shared/helpers'

export const InitialsView = props => {
    if (!props.assignedTo || props.assignedTo.length === 0) return null
    const assignedTo = [...props.assignedTo]

    const disabled = props.onPress ? false : true

    const justify_content = props.justifyContent ? props.justifyContent : 'center'
    const circle_dim = props.circleDimension ? props.circleDimension : 26
    const font_size = props.fontSize ? props.fontSize : 10
    const font_weight = props.fontWeight ? props.fontWeight : '700'
    const margin_between = props.margin ? props.margin : 8

    let maxCountToDisplay

    if (props.maxCountToDisplay && props.maxCountToDisplay === assignedTo.length) {
        maxCountToDisplay = null
    } else if (props.maxCountToDisplay) {
        maxCountToDisplay = props.maxCountToDisplay
    } else {
        maxCountToDisplay = null
    }

    assignedTo.sort((a, b) => helpers.sortByName(a.name, b.name))

    return (
        <View
            style={{
                flex: 1,
                borderWidth: 0,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: justify_content
            }}>
            {assignedTo.map((item, index) => {
                let noMargin = assignedTo.length === 1 ? true : false
                if (maxCountToDisplay) {
                    if (index === maxCountToDisplay && maxCountToDisplay !== assignedTo.length) {
                        const assignessLeft = assignedTo.slice(maxCountToDisplay)
                        item = { isPlusItem: true, assignees: assignessLeft }
                        noMargin = true
                    } else if (index > maxCountToDisplay) {
                        return null
                    }
                }
                if (props.noMargin) {
                    noMargin = true
                }
                return (
                    <Initials
                        key={item.initials + Math.random()}
                        onPress={props.onPress}
                        disabled={disabled}
                        item={item}
                        currentUser={props.currentUser}
                        dimension={circle_dim}
                        fontSize={font_size}
                        fontWeight={font_weight}
                        noMargin={noMargin}
                        margin={margin_between}
                    />
                )
            })}
        </View>
    )
}
