import React, { useContext } from 'react'
import { RulesContext } from '../../RulesContext'
import { AuthContext } from '../../../Auth/AuthContext'
import { db } from '../../../../utils/firebase'
import moment from 'moment-timezone'
import Alert from '../../../../components/alerts/Alert'
import { toast } from 'react-toastify'
import { toastSuccessStyle, toastErrorStyle } from '../../../../utils/styles'

export function RulesDeleteDialog({ show, onHide }) {
    const { ids, setIds } = useContext(RulesContext)
    const { user } = useContext(AuthContext)
    const text = `Are you sure to permanently delete ${ids.length} rule${ids.length === 1 ? '' : 's'}?`

    const onConfirm = async () => {
        try {
            const batch = db.batch()
            ids.map(id => {
                return batch.update(db.collection('rules').doc(id), {
                    visible: false,
                    updated: moment().valueOf(),
                    lastChangedByUser: user.key
                })
            })

            await batch.commit()
            setIds([])
            onHide()
            toast.success('Rules deleted successfully', toastSuccessStyle)
        } catch (error) {
            console.error(error)
            toast.error(`Error deleting rules (${error.message})`, toastErrorStyle)
        }
    }

    return <>{show && <Alert title={'Rules deleting'} text={text} onCancel={onHide} onConfirm={onConfirm} />}</>
}
