import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import PreviewBox from './preview-box/index.jsx'
import { ImagesDropBoxWrapper, DropBoxAndPreviewWrap, DropBox, ImagesPreviewWrap } from './style.js'

const ImagesDropBox = ({ images = undefined, setImages, isPreviewShown = false, width, deleteImage = null }) => {
    const onDrop = useCallback(acceptedImages => {
        const imagesWithPreviewAndKey = acceptedImages.map(image =>
            Object.assign(image, {
                preview: URL.createObjectURL(image)
            })
        )
        setImages(imagesWithPreviewAndKey)
    })

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg,image/png' })

    const dragCondition = isDragActive ? <p>drop the files here...</p> : <p>{"or drag 'n' drop"}</p>

    const imagesPreview =
        images &&
        images.map((image, index) => {
            const { name, preview } = image
            return <PreviewBox key={name + index} name={name} preview={preview} index={index} deleteImage={deleteImage} />
        })

    const previewCondition = isPreviewShown && <ImagesPreviewWrap>{imagesPreview}</ImagesPreviewWrap>

    return (
        <ImagesDropBoxWrapper height={width}>
            <DropBoxAndPreviewWrap>
                <DropBox {...getRootProps()} width={width}>
                    <input type="files" {...getInputProps()} />

                    <i className="specta-plus icon-xl"></i>
                    <p>{dragCondition}</p>
                </DropBox>
                {previewCondition}
            </DropBoxAndPreviewWrap>
        </ImagesDropBoxWrapper>
    )
}

export default ImagesDropBox
