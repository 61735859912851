import styled from 'styled-components'
import { MOBILE_BREAKPOINT, SMALL_DESKTOP_SCREEN_HEIGHT } from '../../../utils/constants'

export const LoginFormWrapper = styled.div`
    display: flex;
    justify-content: center;

    > form {
        width: 376px;

        input {
            height: 56px !important;
            padding-left: 25px;
            font-weight: 450 !important;
            font-size: 1.231rem !important;
        }
    }

    .btn-wrap {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    @media (max-height: ${SMALL_DESKTOP_SCREEN_HEIGHT}px) {
        margin-top: 20px;

        .btn-wrap {
            > button {
                height: 56px !important;
                margin-bottom: 16px !important;
            }
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        .btn-wrap {
            > button {
                height: 46px;
                font-size: 1.167rem;
            }
        }
    }
`
