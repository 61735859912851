import React from 'react'
import { Image, View } from 'react-native'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'
import { infoTypes } from './helpers'

function Empty({ infoType }) {
    const VIEW_HEIGHT = window.innerHeight
    const MARGIN_TOP = VIEW_HEIGHT / 8
    return (
        <View
            style={{
                marginTop: MARGIN_TOP,
                flexDirection: 'column',
                flex: 1,
                borderColor: 'red',
                justifyContent: 'center'
            }}>
            <View
                style={{
                    backgroundColor: colors.white,
                    borderColor: 'green',
                    alignItems: 'center'
                }}>
                <Image style={{ width: 149, height: 128, opacity: 0.7 }} source={require('@shared/img/empty-screens/sun.png')} />
                <span
                    style={{
                        marginTop: 40,
                        fontSize: 18,
                        color: iOSColors.gray
                    }}>
                    {infoTypes[infoType].emptyTitle}
                </span>

                <span
                    style={{
                        marginTop: 8,
                        fontSize: 17,
                        color: iOSColors.gray
                    }}>
                    {infoTypes[infoType].emptySubTitle}
                </span>
            </View>
        </View>
    )
}

export default Empty
