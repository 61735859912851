import { View, Image } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import moment from 'moment/moment'
import { FeatureLockedSignal, SubscriptionCheck } from '../../../components/upgrade-signals'
import React, { useContext } from 'react'
import { TaskScreenContext } from './TaskScreenContext'
import { UNIT_HEADER_FONT_SIZE } from './consts'
import DailyCommentTextInput from '../../daily-comment-text-input'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { SubscriptionContext } from '../../../modules/Subscriptions/SubscriptionContext'

const DailyComment = () => {
    const { area, setArea, selectedDateNumber, openUpgradeMessage } = useContext(TaskScreenContext)
    const { currentUser } = useContext(AuthContext)

    const { isFeatureInSubscription } = useContext(SubscriptionContext)

    const dailyCommentsFeatureAvailable = isFeatureInSubscription('daily-comments')
    const shouldRenderDailyCommentIcon = (value: string) => {
        if (value) {
            setArea({ ...area, dailyComment: value })
        } else {
            setArea({ ...area, dailyComment: '' })
        }
    }

    return (
        <View style={{ marginBottom: 0, borderWidth: 0, width: '100%' }}>
            <View
                style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    borderWidth: 0,
                    marginBottom: 12
                }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Image style={{ height: 26, width: 26 }} source={require('@shared/img/cleaning-status/daily-comment-filter-off.svg')} />

                    <span
                        style={{
                            marginLeft: 6,
                            fontWeight: '600',
                            color: iOSColors.black,
                            fontSize: UNIT_HEADER_FONT_SIZE
                        }}>
                        Daily comment
                        <span
                            style={{
                                marginLeft: 6,
                                fontWeight: '500',
                                color: iOSColors.midGray,
                                fontSize: UNIT_HEADER_FONT_SIZE - 3
                            }}>
                            displays on {moment(selectedDateNumber).format('ddd, MMM D YYYY')}
                        </span>
                    </span>
                </View>
                <SubscriptionCheck
                    featureId="daily-comments"
                    onAvailable={() => null}
                    onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                        return (
                            <FeatureLockedSignal
                                showLock
                                isActive
                                onClick={() => {
                                    openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                }}
                            />
                        )
                    }}
                />
            </View>
            <DailyCommentTextInput
                disabled={!dailyCommentsFeatureAvailable}
                area={area}
                currentUser={currentUser}
                selectedDateNumber={selectedDateNumber}
                shouldRenderDailyCommentIcon={shouldRenderDailyCommentIcon}
            />
        </View>
    )
}

export default DailyComment
