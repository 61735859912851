export const AreaStatusCssClasses = ['success', 'danger', 'info', 'primary', 'secondary', 'warning', 'warning']
export const AreaStatusTitles = [
    'Clean',
    'Unclean',
    'Waiting for checkout',
    'Do not disturb',
    'No service',
    'Out of service',
    'Preparing',
    'Inspection'
]
export const AreaStatusConstants = [
    'clean',
    'dirty',
    'waiting-for-checkout',
    'do-not-disturb',
    'no-service',
    'no-cleaning-service',
    'preparing',
    'inspection'
]
export const AreaTypeCssClasses = ['success', 'primary', '']
export const AreaTypeTitles = ['Business', 'Individual', '']
// CHECKOUT
// CHECKIN
// INSPECTION
// DAILY CLEANING
// REOCCURRING
export const RuleTriggers = [
    {
        type: 'booking',
        name: 'Booking',
        description: 'Triggers changes in room status based on bookings affecting the room and parameters you set.'
    },
    {
        type: 'date',
        name: 'Date',
        description: 'Triggers changes in room status for a specific set of dates you specify.'
    }
    // { type: "inspection", name: "Inspection" },
    // { type: "reoccuring", name: "Reoccuring" }
]
export const DataRuleRepeatTypes = [
    { type: 'daily', text: 'Repeat every', frequency: 1 }
    // { type: 'weekly', text: 'Repeat weekly', frequency: 7 },
    // { type: 'custom', text: 'Custom', frequency: null }
    // { type: "monthly", text: "Repeat monthly" }
]
export const BookingRuleRepeateTypes = [
    // { type: 'checkin', text: 'Repeat on checkin' },
    { type: 'checkout', text: 'Checkout' },
    { type: 'custom', text: 'Custom' }
]

export const AVAILABLE_COLORS = [
    '#e83e8c',
    '#ffc107',
    '#20c997',
    '#17a2b8',
    '#7E8299',
    '#3F4254',
    '#3699FF',
    '#E4E6EF',
    '#8950FC',
    '#FFA800',
    '#F64E60',
    '#F3F6F9'
]
export const defaultSorted = [{ dataField: 'name', order: 'asc' }]
export const sizePerPageList = [
    { text: '3', value: 3 },
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '25', value: 25 }
]
export const initialFilter = {
    filter: {
        name: '',
        description: '',
        group: '',
        address: '',
        status: ''
    },
    sortOrder: 'asc', // asc||desc
    sortField: 'name',
    pageNumber: 1,
    pageSize: 25
}
