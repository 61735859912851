import styled from 'styled-components'
import { device } from '../../../../components/styled-constants'
export const FilterRow = styled.div`
    margin-bottom: 3rem;
`
export const LeftColumn = styled.div`
    justify-content: flex-start;
    display: flex;

    @media ${device.laptop} {
        justify-content: center;
        margin-bottom: 1rem;
    }
`

export const MiddleColumn = styled.div`
    @media ${device.laptop} {
        justify-content: center;
        margin-bottom: 1rem;
    }
`

export const RightColumn = styled.div`
    justify-content: flex-end;
    display: flex;

    @media ${device.laptop} {
        justify-content: center;
        margin-bottom: 1rem;
    }
`
