import React from 'react'
import Select, { createFilter } from 'react-select'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'

const AreasGroupsSelector = ({
    disabled,
    areaGroupsToOptions,
    onAreaGroupsChange,
    filterConfig,
    currentUser,
    areaGroupsSelect,
    field,
    form: { errors }
}) => {
    const error = errors[field.name]

    const selectStyles = {
        control: styles => ({
            ...styles,
            borderWidth: 1,
            borderColor: error ? colors.bs_danger : colors.bs_gray_300,
            borderRadius: 6,
            boxShadow: 'none',
            ':hover': {
                borderWidth: 1,
                borderRadius: 6,
                borderColor: error ? colors.bs_danger : colors.green_spectaflow
            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isSelected || isFocused ? iOSColors.customGray : null,
            color: data.isFixed ? colors.green_spectaflow : isSelected ? colors.textPrimary : colors.textPrimary,
            cursor: isDisabled ? 'default' : 'pointer'
        }),
        placeholder: styles => {
            return [
                {
                    ...styles,
                    color: iOSColors.midGray
                }
            ]
        },
        multiValue: styles => {
            const backColor = colors.hex2rgb(colors.blue_spectaflow, 0.15)

            return [
                {
                    ...styles,
                    backgroundColor: backColor,
                    paddingLeft: 4,
                    paddingRight: 2,
                    borderRadius: 23,
                    height: 30,
                    alignItems: 'center'
                }
            ]
        },
        multiValueLabel: styles => {
            return {
                ...styles,
                color: colors.blue_spectaflow,
                fontWeight: 500,
                fontSize: 15
            }
        },
        multiValueRemove: styles => {
            return [
                {
                    ...styles,
                    color: colors.blue_spectaflow,
                    ':hover': {
                        height: 25,
                        borderRadius: 23,
                        color: colors.blue_spectaflow
                    }
                }
            ]
        },
        indicatorSeparator: styles => ({
            ...styles,
            backgroundColor: '#f4f5f6',
            width: 0
        }),
        valueContainer: base => ({
            ...base,
            maxHeight: 200
        }),
        menuList: base => ({
            ...base,
            padding: 0
        })
    }

    const errorMessage = error && (
        <span style={{ display: 'initial' }} className="invalid-feedback">
            {error}
        </span>
    )

    return (
        <>
            <Select
                isDisabled={disabled}
                value={areaGroupsToOptions}
                placeholder="Select unit groups..."
                isMulti
                name="areaGroups"
                options={areaGroupsSelect}
                styles={selectStyles}
                onChange={onAreaGroupsChange}
                filterOption={createFilter(filterConfig)}
                currentUser={currentUser}
            />
            {errorMessage}
        </>
    )
}

export default AreasGroupsSelector
