import React, { useEffect, useState, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { UserEditDialogHeader } from './UserEditDialogHeader'
import { UserEditForm } from './UserEditForm'
import { UsersContext, User } from '../../UsersContext'
import firebase, { db, fetchAreaGroups } from '../../../../utils/firebase'
import { AuthContext } from '../../../Auth/AuthContext'
import * as helpers from '@shared/helpers'
import Spinner from '../../../../components/Spinner'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { toast } from 'react-toastify'
import { toastErrorStyle } from '../../../../utils/styles'
import { httpsCallable } from '../../../../api'
import { useFeatureToggle } from '../../../../features'

export function UserEditDialog({ id, show, onHide }) {
    const { setIds, userForEdit, setUserForEdit, actionsLoading, setActionsLoading, setAreaGroups, areaGroups } = useContext(UsersContext)
    const { currentUser, organization } = useContext(AuthContext)
    const [dataLoading, setDataIsLoading] = useState(false)
    const { isFeatureOn } = useFeatureToggle()
    // const [isAdmin, setIsAdmin] = useState(false)
    // const [showCleaningTaskNote, setShowCleaningTaskNote] = useState(false)

    const user = new User(userForEdit) || new User()

    useEffect(() => {
        setDataIsLoading(true)
        const getUser = async () => {
            if (id) {
                const userRef = await db.collection('users').doc(id).get()

                let user = userRef.data()
                if (user) {
                    if (isFeatureOn('roles-and-permissions')) {
                        user.isAdmin = user.authRole === 'admin'
                    } else {
                        user.isAdmin = user.authRole === 'admin'
                    }
                    user = { ...user, authRole: user.authRole }
                    // setIsAdmin(user.isAdmin)
                    // setShowCleaningTaskNote(user.showCleaningTaskNote)
                }

                const u = new User(user)
                setUserForEdit(u)
            }
            let allAreaGroups = await fetchAreaGroups(firebase, currentUser)
            allAreaGroups.unshift('All')
            setAreaGroups(allAreaGroups)
            setDataIsLoading(false)
        }
        getUser(id)
    }, [id, setActionsLoading, setUserForEdit])

    const saveUser = async (user, phoneNumber) => {
        setActionsLoading(true)

        const manageUser = httpsCallable('manageUser')

        const key = userForEdit ? userForEdit.key : null
        const initials = helpers.getInitials(user.name)

        const parsed = parsePhoneNumberFromString(phoneNumber)
        let oldPhoneNumber = parsed ? parsed.number : phoneNumber

        if (id) {
            const oldNumberParsed = parsePhoneNumberFromString(userForEdit.phoneNumber)
            oldPhoneNumber = oldNumberParsed ? oldNumberParsed.number : userForEdit.phoneNumber
        }

        const manageUserResponse = await manageUser({
            userKey: key,
            userName: user.name,
            userEmail: user.email,
            userPhoneNumber: oldPhoneNumber.replace(/\s/g, ''),
            userNewPhoneNumber: parsed ? parsed.number : phoneNumber,
            userInitials: initials,
            userAreaGroups: user.areaGroups,
            userOrganizationKey: currentUser.organizationKey,
            userVisible: true,
            userIssueHashtags: user.issueHashtags,
            userAssignIssueHashtags: user.assignIssueHashtags,
            userIsAdmin: user.isAdmin || false,
            userRole: user.authRole,
            userShowCleaningTaskNote: user.showCleaningTaskNote || false,
            showCleaningTaskNote: user.showCleaningTaskNote || false,
            currentUserKey: currentUser.key
        }).catch(e => {
            toast.error(e.message, toastErrorStyle)
            setActionsLoading(false)
        })
        if (manageUserResponse) {
            setActionsLoading(false)

            setIds([])

            setUserForEdit(new User())
            onHide()
        }
    }

    return (
        <Modal show={show} size="lg" onHide={onHide} centered={true} aria-labelledby="example-modal-sizes-title-lg">
            {dataLoading && (
                <div className="w-100 h-100">
                    <Spinner style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 1500 }} />
                </div>
            )}
            <UserEditDialogHeader id={id} onHide={onHide} />
            <UserEditForm
                saveUser={saveUser}
                currentUser={currentUser}
                areaGroups={areaGroups}
                actionsLoading={actionsLoading}
                dataLoading={dataLoading}
                user={user}
                onHide={onHide}
                organization={organization}
            />
        </Modal>
    )
}
