import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
    areaAtom,
    bookingsAtom,
    dailyCommentAtom,
    priorityAtom,
    rulesAtom,
    currentDateNumberAtom,
    taskAtom,
    usersAtom,
    dataLoadingAtom,
    activitiesAtom,
    sameDateAsParentAtom,
    ruleNameAtom,
    activeRuleAtom,
    cleaningAtom,
    issuesAtom,
    lastCleaningTaskAtom
} from './state/atoms'
import { useEffect, useMemo } from 'react'
import {
    ActivityStruct,
    AreaStruct,
    BookingStruct,
    DailyCommentStruct,
    IssueStruct,
    OrgStruct,
    RuleStruct,
    TaskStruct,
    UserStruct
} from '@shared/firestore-structs'
import { setInitialState } from 'app/utils/helpers'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import { getAreaQuery } from '@shared/area-data'
import firebase, { asFirebase, getDataFromSnapshot } from 'app/utils/firebase'
import { getBookingsQuery } from '@shared/booking-data'
import { currentOrganizationAtom } from 'app/modules/Auth/atoms'
import { getHousekeepingTasksQuery } from '@shared/task-data'
import { getRulesQuery } from '@shared/rules-data'
import { getDailyCommentsQuery } from '@shared/daily-comments-data'
import { selectedDateNumberSelector } from './state/selectors/selectedDateNumber'
import { getStartOfDayTimestamp } from 'app/utils/formatters'
import { getActivitiesQuery } from '@shared/activity-data'
import { getLastCleaningTask } from '../api'
import { getIssuesQuery } from '@shared/queries/data'
import * as c from '@shared/constants'
import { TASK_ASSIGNED, TASK_COMPLETE, TASK_OPEN } from '@shared/constants'

interface StateProps {
    area: AreaStruct
    task?: TaskStruct | null
    currentDateNumber: number
    rules: RuleStruct[]
    dailyComment: DailyCommentStruct | null
    activities: ActivityStruct[]
    users: UserStruct[]
    bookings: BookingStruct[]
    priority: boolean
    activeRule: RuleStruct | null
    ruleName: string
}

export function useHousekeepingModalState(props: StateProps) {
    const [area, setArea] = useRecoilState(areaAtom)
    const [dataLoading, setDataLoading] = useRecoilState(dataLoadingAtom)
    const [task, setTask] = useRecoilState(taskAtom)

    const setRules = useSetRecoilState(rulesAtom)
    const setDailyComment = useSetRecoilState(dailyCommentAtom)
    const setUsers = useSetRecoilState(usersAtom)
    const setBookings = useSetRecoilState(bookingsAtom)
    const setActivities = useSetRecoilState(activitiesAtom)
    const setIssues = useSetRecoilState(issuesAtom)
    const setPriority = useSetRecoilState(priorityAtom)
    const setCurrentDateNumber = useSetRecoilState(currentDateNumberAtom)
    const setSameDateAsParent = useSetRecoilState(sameDateAsParentAtom)
    const setRuleName = useSetRecoilState(ruleNameAtom)
    const setActiveRule = useSetRecoilState(activeRuleAtom)
    const setCleaning = useSetRecoilState(cleaningAtom)
    const setLastCleaningTask = useSetRecoilState(lastCleaningTaskAtom)

    const selectedDateNumber = useRecoilValue(selectedDateNumberSelector)
    const currentOrganization = useRecoilValue(currentOrganizationAtom) as OrgStruct

    const SAME_DATE_AS_PARENT = useMemo(
        () => getStartOfDayTimestamp(props.currentDateNumber, currentOrganization.timezone) === selectedDateNumber,
        [props.currentDateNumber, currentOrganization.timezone, selectedDateNumber]
    )

    const [areaSnap, loadingArea, errorArea] = useDocument(SAME_DATE_AS_PARENT ? null : getAreaQuery(asFirebase(firebase), props.area.key))
    const [bookingsSnap, loadingBookings, errorBookings] = useCollection(
        SAME_DATE_AS_PARENT ? null : getBookingsQuery(asFirebase(firebase), currentOrganization.key, selectedDateNumber, props.area.key)
    )
    const [activitiesSnap, loadingActivities, errorActivities] = useCollection(
        SAME_DATE_AS_PARENT ? null : getActivitiesQuery(asFirebase(firebase), currentOrganization.key, selectedDateNumber, props.area.key)
    )
    const [tasksSnap, loadingTasks, errorTasks] = useCollection(
        SAME_DATE_AS_PARENT
            ? null
            : getHousekeepingTasksQuery(
                  asFirebase(firebase),
                  currentOrganization.key,
                  selectedDateNumber,
                  [TASK_OPEN, TASK_ASSIGNED, TASK_COMPLETE],
                  props.area.key
              )
    )
    const [rulesSnap, loadingRules, errorRules] = useCollection(
        SAME_DATE_AS_PARENT ? null : getRulesQuery(asFirebase(firebase), currentOrganization.key, props.area.key)
    )
    const [dailyCommentsSnap, loadingDailyComments, errorDailyComments] = useCollection(
        SAME_DATE_AS_PARENT
            ? null
            : getDailyCommentsQuery(asFirebase(firebase), currentOrganization.key, selectedDateNumber, props.area.key)
    )
    const [issuesSnap, loadingIssues, errorIssues] = useCollection(
        getIssuesQuery(asFirebase(firebase), currentOrganization.key, [c.ISSUE_ASSIGNED, c.ISSUE_OPEN], props.area.key)
    )

    const LOADING =
        loadingArea || loadingBookings || loadingTasks || loadingRules || loadingDailyComments || loadingActivities || loadingIssues
    const ERROR = errorArea || errorBookings || errorTasks || errorRules || errorDailyComments || errorActivities || errorIssues
    const DATA_READY = !LOADING && areaSnap && bookingsSnap && tasksSnap && rulesSnap && dailyCommentsSnap && activitiesSnap && issuesSnap

    useEffect(() => {
        if (!SAME_DATE_AS_PARENT) {
            setDataLoading(loadingArea)
            return
        }
        setDataLoading(false)
    }, [LOADING])

    useEffect(() => {
        setSameDateAsParent(SAME_DATE_AS_PARENT)
    }, [SAME_DATE_AS_PARENT])

    useEffect(() => {
        return setInitialState<number, number>(props.currentDateNumber, setCurrentDateNumber, props.currentDateNumber)
    }, [])

    useEffect(() => {
        return setInitialState<AreaStruct | null, null>(props.area, setArea, null)
    }, [props.area?.key])

    useEffect(() => {
        return setInitialState<UserStruct[], []>(props.users, setUsers, [])
    }, [props.users.length])

    useEffect(() => {
        return setInitialState<TaskStruct | null, null>(props.task ?? null, setTask, null, SAME_DATE_AS_PARENT)
    }, [props.task?.key, SAME_DATE_AS_PARENT])

    useEffect(() => {
        return setInitialState<RuleStruct[], []>(props.rules, setRules, [], SAME_DATE_AS_PARENT)
    }, [props.rules.length, SAME_DATE_AS_PARENT])

    useEffect(() => {
        return setInitialState<ActivityStruct[], []>(props.activities, setActivities, [], SAME_DATE_AS_PARENT)
    }, [props.activities.length, SAME_DATE_AS_PARENT])

    useEffect(() => {
        return setInitialState<DailyCommentStruct | null, null>(props.dailyComment, setDailyComment, null, SAME_DATE_AS_PARENT)
    }, [props.dailyComment?.areaKey, SAME_DATE_AS_PARENT])

    useEffect(() => {
        return setInitialState<BookingStruct[], []>(props.bookings, setBookings, [], SAME_DATE_AS_PARENT)
    }, [props.bookings.length, SAME_DATE_AS_PARENT])

    useEffect(() => {
        return setInitialState<boolean, false>(props.priority, setPriority, false)
    }, [props.priority])

    useEffect(() => {
        return setInitialState<RuleStruct | null, null>(props.activeRule, setActiveRule, null, SAME_DATE_AS_PARENT)
    }, [props.activeRule?.key, SAME_DATE_AS_PARENT])

    useEffect(() => {
        return setInitialState<string, ''>(props.ruleName, setRuleName, '', SAME_DATE_AS_PARENT)
    }, [props.ruleName, SAME_DATE_AS_PARENT])

    useEffect(() => {
        if (issuesSnap) return setInitialState<IssueStruct[] | [], []>(getDataFromSnapshot(issuesSnap), setIssues, [], issuesSnap.size > 0)
    }, [issuesSnap])

    useEffect(() => {
        if (DATA_READY && !SAME_DATE_AS_PARENT) {
            setArea(areaSnap.data() as AreaStruct)
            setTask(getDataFromSnapshot(tasksSnap)[0] ?? null)
            setBookings(getDataFromSnapshot(bookingsSnap))
            setActivities(getDataFromSnapshot(activitiesSnap))
            setRules(getDataFromSnapshot(rulesSnap))
            setDailyComment(getDataFromSnapshot(dailyCommentsSnap)[0] ?? null)
        }
    }, [areaSnap, tasksSnap, bookingsSnap, activitiesSnap, rulesSnap, dailyCommentsSnap])

    useEffect(() => {
        setLastHousekeepingData()
        return () => {
            setLastCleaningTask(null)
            setCleaning(null)
        }
    }, [area?.key, task?.key])

    async function setLastHousekeepingData() {
        const result = await getLastCleaningTask(area?.lastCleaningTaskKey, task)

        setLastCleaningTask(result)
        result?.cleaning && setCleaning(result.cleaning)
    }

    return [area, dataLoading, ERROR]
}
