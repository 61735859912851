import React, { useContext } from 'react'
import { FlatList } from 'react-native-web'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { IssueStruct } from '@shared/dataObjects'
import { checkedAreaGroupsAtom, checkedIssuesAtom, filtersAtom, selectedIssueIdAtom, showActionBarAtom } from '../state/atoms'
import IssuesNotFound from './IssuesNotFound'
import { AuthContext } from '../../modules/Auth/AuthContext'
import IssueRow from './IssueRow'
import { IssuesListWrapper, IssuesListContentStyle } from './../style'

const ITEM_HEIGHT = 146

interface IIssuesList {
    issues: IssueStruct[] | null
}

export default function IssuesList({ issues }: IIssuesList) {
    const filters = useRecoilValue(filtersAtom)
    const { currentUser } = useContext(AuthContext)
    if (!currentUser) return null

    const [checkedIssues, setCheckedIssues] = useRecoilState(checkedIssuesAtom)
    const [checkedAreaGroups, setCheckedAreaGroups] = useRecoilState(checkedAreaGroupsAtom)
    const setShowActionBar = useSetRecoilState(showActionBarAtom)
    const setSelectedIssueId = useSetRecoilState(selectedIssueIdAtom)

    function addCheckedIssue(issue: IssueStruct) {
        setCheckedIssues([...checkedIssues, issue])
        setCheckedAreaGroups([...new Set([...checkedAreaGroups, issue.area.group])])
        setShowActionBar(true)
    }

    function removeCheckedIssue(issue: IssueStruct) {
        const updatedCheckedIssues = checkedIssues.filter((el: IssueStruct) => el.key !== issue.key)

        setCheckedIssues(updatedCheckedIssues)
        setCheckedAreaGroups(checkedAreaGroups.filter((group: string) => group !== issue.area.group))

        if (updatedCheckedIssues.length === 0) {
            setShowActionBar(false)
        }
    }

    function onPressIssueRow(issue: IssueStruct) {
        setSelectedIssueId(issue.key)
    }

    return (
        <IssuesListWrapper>
            <FlatList
                contentContainerStyle={IssuesListContentStyle}
                initialNumToRender={5}
                getItemLayout={(_, index: number) => ({
                    length: ITEM_HEIGHT,
                    offset: ITEM_HEIGHT * index,
                    index
                })}
                ListEmptyComponent={() => {
                    if (issues?.length === 0) {
                        return <IssuesNotFound />
                    }
                    return null
                }}
                data={issues}
                renderItem={({ item }: { item: IssueStruct }) => {
                    const checkedIndex = checkedIssues.findIndex((issue: IssueStruct) => issue.key === item.key)
                    return (
                        <IssueRow
                            key={item.key + filters.search}
                            issue={item}
                            checked={checkedIndex === -1 ? false : true}
                            addCheckedIssue={addCheckedIssue}
                            removeCheckedIssue={removeCheckedIssue}
                            onPressIssueRow={onPressIssueRow}
                            onPressHashTag={() => null}
                            onSearchPress={() => null}
                            currentUser={currentUser}
                        />
                    )
                }}
                // stickySectionHeadersEnabled={false}
                showsVerticalScrollIndicator={false}
            />
        </IssuesListWrapper>
    )
}
