import moment from 'moment-timezone'
import * as helpers from './helpers'
import * as c from './txt-constants'
import { BookingStruct, TaskStruct, UserStruct } from './firestore-structs'
import { TasksSection, TasksSectionTitle } from './task-types'
import { TaskboardContext } from './traces-types'
import { structuredClone } from './polyfills'

type TaskViewType = 'myTasks' | 'allTasks'
interface DivideTasksBySections {
    tasks: TaskStruct[]
    selectedDateNumber: number
    currentUser: UserStruct
    showMoreCreatedByMe: boolean

    taskViewType: TaskViewType
}

export const otherWorkFilter = (task: TaskStruct) => {
    return (
        task.type === c.TASK_TYPE_GENERAL ||
        task.type === c.TASK_TYPE_ISSUE ||
        (task.type === c.TASK_TYPE_HOUSEKEEPING && task.area && task.area.occupancy === c.OCCUPANCY_VACANT)
    )
}

export const checkInFilter = (task: TaskStruct) => {
    return (
        (task.type === c.TASK_TYPE_HOUSEKEEPING && task.area && task.area.occupancy === c.OCCUPANCY_CHECKIN) ||
        (task.type === c.TASK_TYPE_HOUSEKEEPING && task.area && task.area.occupancy === c.OCCUPANCY_TURNOVER)
    )
}

export const unclean50Filter = (task: TaskStruct) => {
    return task.type === c.TASK_TYPE_HOUSEKEEPING && task.area && task.area.occupancy === c.OCCUPANCY_STAYOVER
}
export const unclean80Filter = (task: TaskStruct) => {
    return task.type === c.TASK_TYPE_HOUSEKEEPING && task.area && task.area.occupancy === c.OCCUPANCY_STAYOVER_80
}
export const unclean100Filter = (task: TaskStruct) => {
    return (
        (task.type === c.TASK_TYPE_HOUSEKEEPING && task.area && task.area.occupancy === c.OCCUPANCY_CHECKOUT) ||
        (task.type === c.TASK_TYPE_HOUSEKEEPING && task.area && task.area.occupancy === c.OCCUPANCY_TURNOVER)
    )
}

export const constructBreadcrumbs = (
    task: TaskStruct,
    context: TaskboardContext,
    bookings: BookingStruct[] = [],
    selectedArea: { label: string; value: string; subText: string } | null = null
) => {
    const breadcrumbItems = []
    const isReservationTask = 'reservationId' in task && task.reservationId
    const hasPropertyName = 'propName' in task && task.propName
    const isAccountContext = [TaskboardContext.ACCOUNT, TaskboardContext.EMBEDDED].includes(context)
    const hasArea = !!task.area || !!selectedArea
    let area = task.area

    if (!task.area && selectedArea) {
        area = { name: selectedArea.label, key: selectedArea.value, group: selectedArea.subText }
    }

    if (isAccountContext && hasPropertyName) {
        breadcrumbItems.push(task.propName)
    }
    if (hasArea && area.group) {
        if (hasPropertyName && task.propName !== area.group) {
            breadcrumbItems.push(area.group)
        } else if (isAccountContext && !hasPropertyName) {
            breadcrumbItems.push(area.group)
        } else if (!hasPropertyName) {
            breadcrumbItems.push(area.group)
        }
        breadcrumbItems.push(area.name)
    }
    if (!hasArea) {
        breadcrumbItems.push('No unit')
    }
    if (isReservationTask && task.reservationId) {
        const taskBooking = bookings.find(booking => booking._external?.parentReservationID === task.reservationId)
        if (taskBooking) {
            breadcrumbItems.push(taskBooking.guestName)
        } else {
            breadcrumbItems.push(task.reservationId)
        }
    }
    return breadcrumbItems
}

/*
    this is a temporary type fix, as this function is used in various places.
    TODO: we should rewrite it to not extend the TaskStruct with initials
    and don't mutate initial data
*/
type TaskStructWithInitials = TaskStruct & { initials?: string | null }
export function findNewestTask(tasks: TaskStructWithInitials[]) {
    let task
    if (tasks.length > 1) {
        tasks.sort((a, b) => helpers.sortTimeStampDescending(a.created, b.created))
        task = tasks[0]

        task.initials = Array.isArray(tasks[0].assignedTo) ? tasks[0].assignedTo.map(u => u.initials).join(', ') : null
    } else if (tasks.length === 1) {
        task = tasks[0]
        task.initials = Array.isArray(tasks[0].assignedTo) ? tasks[0].assignedTo.map(u => u.initials).join(', ') : null
    } else {
        task = null
    }
    return task
}

/*
    this is the old function for creating sections for my-tasks screen
*/
export function createSectionedTasks(tasks: TaskStruct[], titleDateFormat: string) {
    const sTasks = []
    let sectObject: any = {
        data: [],
        title: '',
        count: 0
    }

    for (let i = 0; i < tasks.length; i += 1) {
        const currentTask = tasks[i]
        const taskStartDate = moment(currentTask.startDate).startOf('day')
        const isToday = taskStartDate.isSame(moment(), 'day')
        let sectionTitle

        if (isToday) {
            sectionTitle = 'Due today'
        } else {
            sectionTitle = moment(currentTask.startDate).format(titleDateFormat)
        }

        if (!sectObject.title) {
            sectObject.title = sectionTitle
        }

        if (sectObject.title === sectionTitle) {
            sectObject.data.push(currentTask)
        } else {
            sectObject.data = sectObject.data.sort((a: any, b: any) => sortTasks(a, b))
            sTasks.push(sectObject)

            sectObject = structuredClone(sectObject)
            sectObject.title = sectionTitle
            sectObject.data = []
            sectObject.data.push(currentTask)
        }
    }
    if (tasks.length > 0) {
        sectObject.data = sectObject.data.sort((a: any, b: any) => sortTasks(a, b))
        sTasks.push(sectObject)
    }
    return sTasks
}

export function filterDueTodayMyTasks(tasks: TaskStruct[], date: number, currentUser: UserStruct, taskViewType: TaskViewType) {
    if (taskViewType === 'myTasks') {
        return tasks.filter(task => task.startDate === date && task.assignedToKeys?.includes(currentUser.key))
    } else {
        return tasks.filter(task => task.startDate === date)
    }
}

export function filterOverdueMyTasks(tasks: TaskStruct[], date: number, currentUser: UserStruct, taskViewType: TaskViewType) {
    if (date < moment().startOf('day').valueOf()) return []
    return tasks.filter(task => {
        const notTodaysTask = !moment(task.startDate).isSame(date, 'day')
        return task.startDate && task.startDate < date && taskViewType === 'myTasks'
            ? task.assignedToKeys?.includes(currentUser.key) && notTodaysTask
            : task.type !== c.TASK_TYPE_HOUSEKEEPING && notTodaysTask
    })
}

export function filterCreatedByMeTasks(tasks: TaskStruct[], currentUser: UserStruct, date: number, showMoreCreatedByMe = false) {
    return tasks.filter(
        task =>
            task.creator.key === currentUser.key &&
            task.type !== c.TASK_TYPE_HOUSEKEEPING &&
            (showMoreCreatedByMe ? true : task.startDate === date)
    )
}

export function filterNoDueDateTasks(tasks: TaskStruct[], currentUser: UserStruct) {
    return tasks.filter(task => task.startDate === null && task.assignedToKeys?.includes(currentUser.key))
}

export function divideTasksBySections({
    tasks,
    selectedDateNumber,
    currentUser,
    showMoreCreatedByMe = false,
    taskViewType = 'myTasks'
}: DivideTasksBySections): TasksSection[] {
    const dueTodayTasks = filterDueTodayMyTasks(tasks, selectedDateNumber, currentUser, taskViewType).sort(sortTasks)
    const overdueTasks = filterOverdueMyTasks(tasks, selectedDateNumber, currentUser, taskViewType).sort(sortTasksByUpdated)
    /* Temporary doesn’t show the no due date section. DON'T DELETE */
    // const noDueDateTasks = filterNoDueDateTasks(tasks, currentUser).sort(sortTasksByUpdated)
    const createdByMeTasks = filterCreatedByMeTasks(tasks, currentUser, selectedDateNumber, showMoreCreatedByMe).sort(sortTasksByUpdated)

    const sections = [
        { title: TasksSectionTitle.DUE_TODAY, data: dueTodayTasks, count: dueTodayTasks.length },
        { title: TasksSectionTitle.CREATED_BY_ME, data: createdByMeTasks, count: createdByMeTasks.length }
    ]

    if (overdueTasks.length > 0) {
        sections.splice(1, 0, { title: TasksSectionTitle.OVERDUE, data: overdueTasks, count: overdueTasks.length })
    }

    return sections
}

export function getNextTaskStatus(status: TaskStruct['status'] | typeof c.TASK_ANYSTATUS) {
    let nextStatus = status
    if (status === c.TASK_ANYSTATUS) {
        nextStatus = c.TASK_ASSIGNED
    } else if (status === c.TASK_ASSIGNED) {
        nextStatus = c.TASK_COMPLETE
    } else if (status === c.TASK_COMPLETE) {
        nextStatus = c.TASK_ANYSTATUS
    }

    return nextStatus
}

export function sortTasksByUpdated(a: TaskStruct, b: TaskStruct) {
    return a.updated - b.updated
}

export function sortByCleaningStatus(a: TaskStruct, b: TaskStruct) {
    // Sort by cleaning status and put prepping first
    if (a.area.cleaningStatus === c.CLEANING_STATUS_PREPARING && b.area.cleaningStatus !== c.CLEANING_STATUS_PREPARING) {
        return -1
    }
    if (b.area.cleaningStatus === c.CLEANING_STATUS_PREPARING && a.area.cleaningStatus !== c.CLEANING_STATUS_PREPARING) {
        return 1
    }
    return 0
}

export function sortTasks(a: TaskStruct, b: TaskStruct) {
    if (a.priority === true && b.priority === false) {
        return -1
    }
    if (b.priority === true && a.priority === false) {
        return 1
    }

    if (a.type !== c.TASK_TYPE_HOUSEKEEPING && b.type !== c.TASK_TYPE_HOUSEKEEPING) {
        return sortTasksByUpdated(a, b)
    }

    if (a.type === c.TASK_TYPE_HOUSEKEEPING && b.type === c.TASK_TYPE_HOUSEKEEPING) {
        return sortByHousekeepingWorkload(a, b)
    }

    if (a.type !== b.type) {
        return sortByType(a, b)
    }

    return 0
}

function sortByType(a: TaskStruct, b: TaskStruct) {
    if (a.type === c.TASK_TYPE_HOUSEKEEPING && b.type === c.TASK_TYPE_ISSUE) {
        return 1
    }
    if (b.type === c.TASK_TYPE_HOUSEKEEPING && a.type === c.TASK_TYPE_ISSUE) {
        return -1
    }

    if (a.type === c.TASK_TYPE_HOUSEKEEPING && b.type === c.TASK_TYPE_GENERAL) {
        return 1
    }
    if (b.type === c.TASK_TYPE_HOUSEKEEPING && a.type === c.TASK_TYPE_GENERAL) {
        return -1
    }

    if (a.type === c.TASK_TYPE_GENERAL && b.type === c.TASK_TYPE_ISSUE) {
        return -1
    }
    if (b.type === c.TASK_TYPE_GENERAL && a.type === c.TASK_TYPE_ISSUE) {
        return 1
    }
    return 0
}

function sortByHousekeepingWorkload(a: TaskStruct, b: TaskStruct) {
    // If both tasks have same workload sort by cleaning status
    if (a.area.occupancy === b.area.occupancy) {
        return sortByCleaningStatus(a, b)
    }
    if (a.area.occupancy === c.OCCUPANCY_TURNOVER && b.area.occupancy === c.OCCUPANCY_TURNOVER) {
        return helpers.sortByName(a.area.name as string, b.area.name as string)
    }
    if (a.area.occupancy === c.OCCUPANCY_CHECKIN && b.area.occupancy === c.OCCUPANCY_CHECKIN) {
        return helpers.sortByName(a.area.name as string, b.area.name as string)
    }
    if (a.area.occupancy === c.OCCUPANCY_STAYOVER_80 && b.area.occupancy === c.OCCUPANCY_STAYOVER_80) {
        return helpers.sortByName(a.area.name as string, b.area.name as string)
    }

    if (a.area.occupancy === c.OCCUPANCY_STAYOVER && b.area.occupancy === c.OCCUPANCY_STAYOVER) {
        return helpers.sortByName(a.area.name as string, b.area.name as string)
    }

    if (a.area.occupancy === c.OCCUPANCY_CHECKOUT && b.area.occupancy === c.OCCUPANCY_CHECKOUT) {
        return helpers.sortByName(a.area.name as string, b.area.name as string)
    }
    if (a.area.occupancy === c.OCCUPANCY_VACANT && b.area.occupancy === c.OCCUPANCY_VACANT) {
        return helpers.sortByName(a.area.name as string, b.area.name as string)
    }

    if (
        a.area.occupancy === c.OCCUPANCY_CHECKIN &&
        (b.area.occupancy === c.OCCUPANCY_TURNOVER ||
            b.area.occupancy === c.OCCUPANCY_STAYOVER_80 ||
            b.area.occupancy === c.OCCUPANCY_STAYOVER ||
            b.area.occupancy === c.OCCUPANCY_CHECKOUT ||
            b.area.occupancy === c.OCCUPANCY_VACANT)
    ) {
        return -1
    }

    if (
        b.area.occupancy === c.OCCUPANCY_CHECKIN &&
        (a.area.occupancy === c.OCCUPANCY_TURNOVER ||
            a.area.occupancy === c.OCCUPANCY_STAYOVER_80 ||
            a.area.occupancy === c.OCCUPANCY_STAYOVER ||
            a.area.occupancy === c.OCCUPANCY_CHECKOUT ||
            a.area.occupancy === c.OCCUPANCY_VACANT)
    ) {
        return 1
    }

    if (
        a.area.occupancy === c.OCCUPANCY_TURNOVER &&
        (b.area.occupancy === c.OCCUPANCY_STAYOVER_80 ||
            b.area.occupancy === c.OCCUPANCY_STAYOVER ||
            b.area.occupancy === c.OCCUPANCY_CHECKOUT ||
            b.area.occupancy === c.OCCUPANCY_VACANT)
    ) {
        return -1
    }

    if (
        b.area.occupancy === c.OCCUPANCY_TURNOVER &&
        (a.area.occupancy === c.OCCUPANCY_STAYOVER_80 ||
            a.area.occupancy === c.OCCUPANCY_STAYOVER ||
            a.area.occupancy === c.OCCUPANCY_CHECKOUT ||
            a.area.occupancy === c.OCCUPANCY_VACANT)
    ) {
        return 1
    }

    if (
        a.area.occupancy === c.OCCUPANCY_STAYOVER_80 &&
        (b.area.occupancy === c.OCCUPANCY_STAYOVER || b.area.occupancy === c.OCCUPANCY_CHECKOUT || b.area.occupancy === c.OCCUPANCY_VACANT)
    ) {
        return -1
    }

    if (
        b.area.occupancy === c.OCCUPANCY_STAYOVER_80 &&
        (a.area.occupancy === c.OCCUPANCY_STAYOVER || a.area.occupancy === c.OCCUPANCY_CHECKOUT || a.area.occupancy === c.OCCUPANCY_VACANT)
    ) {
        return 1
    }

    if (
        a.area.occupancy === c.OCCUPANCY_STAYOVER &&
        (b.area.occupancy === c.OCCUPANCY_CHECKOUT || b.area.occupancy === c.OCCUPANCY_VACANT)
    ) {
        return -1
    }

    if (
        b.area.occupancy === c.OCCUPANCY_STAYOVER &&
        (a.area.occupancy === c.OCCUPANCY_CHECKOUT || a.area.occupancy === c.OCCUPANCY_VACANT)
    ) {
        return 1
    }

    if (a.area.occupancy === c.OCCUPANCY_CHECKOUT && b.area.occupancy === c.OCCUPANCY_VACANT) {
        return -1
    }

    if (b.area.occupancy === c.OCCUPANCY_CHECKOUT && a.area.occupancy === c.OCCUPANCY_VACANT) {
        return 1
    }

    return 0
}

export function constructAssignedTo(user: UserStruct) {
    return {
        key: user.key,
        initials: user.initials,
        name: user.name
    }
}
