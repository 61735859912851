import React from 'react'

export function Checkbox({
    isSelected,
    onClick,
    disabled = false,
    label,
    outline,
    onChange
}: {
    isSelected: boolean
    onClick?: () => void
    disabled?: boolean
    label?: string
    outline?: boolean
    onChange?: () => void
}) {
    return (
        <>
            <label
                className={`checkbox checkbox-specta-blue d-flex ${disabled ? 'checkbox-disabled' : ''} ${
                    outline ? 'checkbox-outline checkbox-outline-2x' : ''
                }`}>
                <input className="ml-2" type="checkbox" checked={isSelected} onClick={onClick} onChange={onChange} disabled={disabled} />
                <span></span>
                <div className="ml-3">{label}</div>
            </label>
        </>
    )
}
