import styled from 'styled-components'
import * as colors from '@shared/colors'

export const Header = styled.div`
    font-size: 1.231rem;
    color: ${colors.black_sweeply};
    font-weight: 600;
    display: flex;
    column-gap: 2px;
    align-items: center;
    margin-bottom: 16px;
`
export const OverdueImage = styled.img`
    color: ${colors.black_sweeply};
    width: 20px;
`
