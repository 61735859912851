import * as React from 'react'
import DatePicker from './DatePicker'
import PriorityFilter from './PriorityFilter'
import Search from './Search'
import { SearchAndFiltersWrapper } from './style'

export default function SearchAndFilters() {
    return (
        <SearchAndFiltersWrapper>
            <Search />
            <DatePicker />
            <PriorityFilter />
        </SearchAndFiltersWrapper>
    )
}
