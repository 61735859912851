import { atom } from 'recoil'
import * as c from '@shared/constants'
import { SubscriptionParams, FiltersParams, PaginationParams, StatusOption } from '../types'
import * as helpers from '@shared/helpers'
import { AreaStruct, IssueStruct, UserStruct } from '@shared/dataObjects'

const defaultStatusFilter: StatusOption[] = [
    { label: helpers.capitalizeFirstLetter(c.ISSUE_OPEN), value: c.ISSUE_OPEN, isSelected: true },
    { label: helpers.capitalizeFirstLetter(c.ISSUE_ASSIGNED), value: c.ISSUE_ASSIGNED, isSelected: true },
    { label: helpers.capitalizeFirstLetter(c.ISSUE_COMPLETE), value: c.ISSUE_COMPLETE, isSelected: false },
    { label: helpers.capitalizeFirstLetter(c.ISSUE_DELETED), value: c.ISSUE_DELETED, isSelected: false },
    { label: 'Reset', value: 'reset' }
]

type StatusFilterOption = typeof defaultStatusFilter

export const selectedStatusAtom = atom<StatusFilterOption>({
    key: 'issues-selectedStatus',
    default: defaultStatusFilter
})

export const subscriptionParamsAtom = atom<SubscriptionParams>({
    key: 'issues-subscriptionParams',
    default: {
        hasSubscriptionAccess: false,
        availableUpgrades: null,
        notAccessibleFeature: null
    }
})

export const issuesToDisplayAtom = atom<IssueStruct[]>({
    key: 'issues-issuesToDisplayAtom',
    default: []
})

export const issuesListAtom = atom<IssueStruct[]>({
    key: 'issues-issuesListAtom',
    default: []
})

export const selectAllCheckedAtom = atom<boolean>({
    key: 'issues-selectAllCheckedAtom',
    default: false
})

export const checkedIssuesAtom = atom<IssueStruct[]>({
    key: 'issues-checkedIssuesAtom',
    default: []
})

export const thisPageCheckedAtom = atom<boolean>({
    key: 'issues-thisPageCheckedAtom',
    default: false
})

export const checkedAreaGroupsAtom = atom<string[]>({
    key: 'issues-checkedAreaGroupsAtom',
    default: []
})

export const showActionBarAtom = atom<boolean>({
    key: 'issues-showActionBarAtom',
    default: false
})

export const paginationAtom = atom<PaginationParams>({
    key: 'issues-paginationAtom',
    default: {
        page: 1,
        limit: 10
    }
})

export const filtersAtom = atom<FiltersParams>({
    key: 'issue-filtersAtom',
    default: {
        search: '',
        priority: false,
        status: defaultStatusFilter,
        hashTags: []
    }
})

export const upgradeMessageModalIsOpenAtom = atom<boolean>({
    key: 'issues-upgradeMessageModalIsOpen',
    default: false
})

export const areasAtom = atom<AreaStruct[]>({
    key: 'issues-areasAtom',
    default: []
})

export const usersAtom = atom<UserStruct[]>({
    key: 'issues-usersAtom',
    default: []
})

export const hashTagsAtom = atom<string[]>({
    key: 'issues-hashTagsAtom',
    default: []
})

export const allIssuesCheckedAtom = atom<boolean>({
    key: 'issues-allIssuesCheckedAtom',
    default: false
})

export const selectedIssueIdAtom = atom<string>({
    key: 'issues-selectedIssueIdAtom',
    default: ''
})

export const showNewIssueModalAtom = atom<boolean>({
    key: 'issues-showNewIssueModalAtom',
    default: false
})
