import React from 'react'
import { useRecoilValue } from 'recoil'
import { groupsOfAreasSelector } from '../state/selectors/areasGroups'
import GroupSection from './GroupSection'
import { Wrapper } from './style'
import NoItemsMessage from 'app/components/NoItemsMessage'
import noAreasIcon from '@shared/img/no-areas-hotel-bed.svg'

function HousekeepingBody() {
    const [groupsOfAreas, length] = useRecoilValue(groupsOfAreasSelector)

    return (
        <Wrapper>
            {length === 0 && (
                <NoItemsMessage
                    message={'No units found'}
                    row2={'Try a new search or'}
                    row3={'change the filters'}
                    icon={noAreasIcon as string}
                />
            )}
            {groupsOfAreas.map(groupOfAreas => {
                return <GroupSection key={groupOfAreas.group} groupOfAreas={groupOfAreas} />
            })}
        </Wrapper>
    )
}

export default HousekeepingBody
