// import Moment from 'moment-timezone'

// import Moment from 'moment-timezone'
// import { extendMoment } from 'moment-range'
//
// const moment = extendMoment(Moment)

// const { sortTimeStampAscending } = require('../helpers')

import moment from 'moment-timezone'
import { AreaCleaningStatus, AreaStruct, BookingStruct, Occupancy, OrgStruct, RuleStruct } from './firestore-structs'
import { Moment } from 'moment-timezone'
import { BOOKING_STATUS_BLOCKED, CLEANING_STATUS_CLEAN, CLEANING_STATUS_INSPECTION, CLEANING_STATUS_OUT_OF_SERVICE } from './txt-constants'
import { isFeatureOn } from './feature-toggles'
import { measurePerformanceSync } from './performance-utils'
import { memoizeWith } from 'ramda'

export const RULE_TYPE_DAILY = 'daily'
export const RULE_TYPE_WEEKLY = 'weekly'
export const RULE_TYPE_MONHTLY = 'monthly'
export const RULE_TYPE_CHECKIN = 'checkin'
export const RULE_TYPE_CHECKOUT = 'checkout'
export const RULE_TYPE_CHECKUP = 'checkup'
export const RULE_TYPE_CUSTOM = 'custom'
export const RULE_TYPE_OPTIN = 'optin'

export const RULE_TRIGGER_BOOKING = 'booking'
export const RULE_TRIGGER_DATE = 'date'

const haveCommonItems = (arr1: string[], arr2: string[]) => {
    const set1 = new Set(arr1)
    const commonItems = arr2.filter(item => set1.has(item))
    return commonItems.length > 0
}

export interface RuleResolveResult {
    optInDates?: number[]
    cleaningStatus?: 'clean' | 'dirty' | 'waiting-for-checkout' | 'inspection' | 'no-service'
    inspection?: boolean
    occupancy?: Occupancy
}

export type RuleResolverOptions = {
    cleanUntilCheckin: boolean
}
const getDateRangeMomoized = memoizeWith(
    (start, end, timezone) => `${start.valueOf()}-${end.valueOf()}-${timezone}`,
    (start: Moment, end: Moment, timezone: string) => {
        const diff = moment.tz(end, timezone).diff(moment.tz(start, timezone), 'days')
        const dates = []
        for (let i = 0; i <= diff; i++) {
            const date = moment.tz(start, timezone).add(i, 'days').startOf('day')
            dates.push(date)
        }
        return dates.map(d => d.valueOf())
    }
)

const getDateRange = (start: Moment, end: Moment, org: Pick<OrgStruct, 'timezone'>) => {
    return getDateRangeMomoized(start, end, org.timezone)
}

const createTaskTemplates = (rule: RuleStruct, dates: number[]) => {
    return dates.map(d => ({
        dueDate: d,
        //name: rule.taskName,
        //color: rule.taskColor,
        //estimatedTime: rule.estimatedTime,
        checklist: rule.checklistTasks
    }))
}

export const resolveRepeatDates = (rule: RuleStruct, startDate: number, endDate: number, org: Pick<OrgStruct, 'timezone'>) => {
    const start = moment.tz(startDate, org.timezone).add(rule.repeatOffsetStart, 'days').startOf('day')
    const end = moment.tz(endDate, org.timezone).add(-rule.repeatOffsetEnd, 'days').startOf('day')

    const range = getDateRange(start, end, org)
    const onlyAtInterval = range.filter((e, i) => i % rule.repeatInterval === 0)
    return onlyAtInterval
}

const isStayWithinRule = (lengthOfStay: number, rule: RuleStruct): boolean => {
    const isMinStayValid = rule.minStay !== undefined ? lengthOfStay >= rule.minStay : true
    const isMaxStayValid = rule.maxStay !== undefined ? lengthOfStay <= rule.maxStay : true

    return isMinStayValid && isMaxStayValid
}

export const resolveBookingRule = (
    rule: RuleStruct,
    bookings: BookingStruct[],
    date: number,
    org: Pick<OrgStruct, 'timezone'>,
    area: AreaStruct,
    options: RuleResolverOptions
) => {
    let dates: number[] = []
    const result: RuleResolveResult = {}
    // TODO - MAKE BETTER LOGIC LATER
    if (rule.inspection) {
        result.inspection = rule.inspection
        return result
    }
    switch (rule.repeatType) {
        case RULE_TYPE_CUSTOM:
            bookings
                .filter(b => !options.cleanUntilCheckin || (b.guestCheckedIn && !b.guestCheckedOut))
                .map(b => (dates = dates.concat(resolveRepeatDates(rule, b.checkinDate, b.checkoutDate, org))))
            if (dates.includes(date)) {
                // If regular daily cleaning the number of days to clean is 2 less days than the number of days in the booking
                result.cleaningStatus = 'dirty'
                if (rule.repeatInterval === 1) {
                    result.occupancy = 'stayover'
                } else {
                    result.occupancy = 'stayover-80'
                }
            }
            break
        case RULE_TYPE_CHECKIN: {
            dates = bookings.map(b => b.checkinDate)
            const checkinDate = bookings.filter(b => b.checkinDate === date)
            if (dates.includes(date)) {
                if (checkinDate && checkinDate.length > 0) {
                    result.cleaningStatus = 'dirty'
                }
            }
            break
        }
        case RULE_TYPE_CHECKOUT: {
            dates = bookings.map(b => b.checkoutDate)
            const checkoutBookings = bookings.filter(b => b.checkoutDate === date)
            const checkinBookings = bookings.filter(b => b.checkinDate === date)
            // @ts-ignore
            const newOOOBehaviour = isFeatureOn(org, 'new-ooo-behaviour')
            if (dates.includes(date)) {
                if (checkoutBookings && checkoutBookings.length > 0) {
                    const bookingNights = checkoutBookings[0].bookingDates.length - 1
                    if (!isStayWithinRule(bookingNights, rule)) {
                        return result
                    }
                    if (options.cleanUntilCheckin && !checkoutBookings[0].guestCheckedIn && !checkoutBookings[0].guestCheckedOut) {
                        result.cleaningStatus = 'clean'
                    } else if (
                        newOOOBehaviour &&
                        checkinBookings.length > 0 &&
                        checkinBookings[0].status &&
                        checkinBookings[0].status === BOOKING_STATUS_BLOCKED
                    ) {
                        result.cleaningStatus = CLEANING_STATUS_OUT_OF_SERVICE
                    } else if (checkoutBookings[0].guestCheckedOut) {
                        result.cleaningStatus = 'dirty'
                    } else {
                        result.cleaningStatus = 'waiting-for-checkout'
                    }
                }
            }
            break
        }
        case RULE_TYPE_CHECKUP: {
            const checkinBookings = bookings.filter(b => b.checkinDate === date)
            if ((bookings.length === 0 || checkinBookings.length > 0) && area.daysSinceLastCheckout! >= rule.repeatInterval) {
                if (area.daysSinceLastCleaning! >= rule.repeatInterval && area.cleaningStatus === CLEANING_STATUS_CLEAN) {
                    result.cleaningStatus = CLEANING_STATUS_INSPECTION
                }
            }
            break
        }
        case RULE_TYPE_OPTIN: {
            const bookingsWithMatchedExtras = bookings.filter(b =>
                haveCommonItems(b.extras?.map(x => x.productId) ?? [], rule.productIds ?? [])
            )
            const matchedExtras = bookingsWithMatchedExtras.map(b => b.extras!).flat()
            const bookingsWithMatchedExtrasDates = bookingsWithMatchedExtras.filter(b => b.extras?.map(x => x.date).includes(date))
            if (bookingsWithMatchedExtrasDates.length > 0) {
                result.occupancy = 'stayover'
                result.cleaningStatus = 'dirty'
                result.optInDates = matchedExtras.map(x => x?.date)
            }
            break
        }
        default:
            dates = [rule.start]
            break
    }
    return result
}

export const resolveDateRule = (rule: RuleStruct, date: number, org: Pick<OrgStruct, 'timezone'>) => {
    let dates = []
    const result: RuleResolveResult = {}
    switch (rule.repeatType) {
        case RULE_TYPE_DAILY:
            dates = resolveRepeatDates(rule, rule.start, moment.tz(org.timezone).add(1, 'year').valueOf(), org)
            if (dates.includes(date)) {
                result.cleaningStatus = 'dirty'
            }
            break
        default:
            dates = []
            break
    }

    return result
}

export const resolveRule = (
    rule: RuleStruct,
    bookings: BookingStruct[],
    date: number,
    org: Pick<OrgStruct, 'timezone'>,
    area: AreaStruct,
    options: RuleResolverOptions
) => {
    switch (rule.trigger) {
        case RULE_TRIGGER_BOOKING:
            return resolveBookingRule(rule, bookings, date, org, area, options)
        case RULE_TRIGGER_DATE:
        default:
            return resolveDateRule(rule, date, org)
    }
}
