import React from 'react'
import { View, TouchableOpacity, Text } from 'react-native-web'
import Select from 'react-select'

import { iOSColors } from '@shared/react-native-typography'

import * as colors from '@shared/colors'
import * as constants from '@shared/constants'

const FONT_SIZE = 15

const Option = props => (
    <TouchableOpacity onPress={() => props.selectOption(props.data)}>
        <View key={props.value} style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 8, marginRight: 8 }}>
            <View style={{ width: '100%', borderBottomWidth: 1, borderBottomColor: '#F4F5F6' }}>
                <View
                    style={{
                        height: 40,
                        flexDirection: 'column',
                        borderColor: 'green',
                        borderWidth: 0,

                        justifyContent: 'center'
                    }}>
                    <span
                        style={{
                            color: props.data.color,
                            fontSize: FONT_SIZE,
                            borderWidth: 0,
                            paddingLeft: 10
                        }}>
                        {props.data.label}
                    </span>
                </View>
            </View>
        </View>
    </TouchableOpacity>
)

export default class CleaningStatusSelect extends React.Component {
    constructor(props) {
        super(props)

        const dot = (color = '#ccc') => ({
            alignItems: 'center',
            display: 'flex',

            ':after': {
                backgroundColor: color,
                borderRadius: 10,
                content: '" "',
                display: 'block',
                marginLeft: 8,
                height: 10,
                width: 10
            }
        })

        this.selectStyles = {
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            control: styles => {
                return {
                    ...styles,
                    height: this.props.height ?? 40,
                    width: this.props.width ?? 240,
                    borderWidth: 1,
                    borderColor: colors.textInputBorder,
                    borderRadius: 6,
                    boxShadow: 'none',
                    ':hover': {
                        borderWidth: 1,
                        borderColor: colors.green_spectaflow,
                        borderRadius: 4
                    }
                }
            },

            placeholder: styles => [
                {
                    paddingLeft: 18,
                    color: iOSColors.midGray,
                    fontSize: 15 //FONT_SIZE
                }
            ],

            singleValue: (styles, { data }) => {
                return [
                    {
                        ...styles,
                        paddingLeft: 4,
                        color: iOSColors.black,
                        fontSize: FONT_SIZE,
                        ...dot(data.color)
                    }
                ]
            },
            indicatorSeparator: (styles, { data }) => ({
                ...styles,
                width: 0
            })
        }

        this.cleaningStatuses = [
            {
                label: 'Waiting for checkout',
                value: constants.CLEANING_STATUS_WAITING_FOR_CHECKOUT,
                color: colors.purple_spectaflow
            },
            { label: 'Unclean', value: constants.CLEANING_STATUS_DIRTY, color: colors.red_spectaflow },
            { label: 'Preparing', value: constants.CLEANING_STATUS_PREPARING, color: colors.yello_spectaflow },
            { label: 'Preparing pause', value: constants.CLEANING_STATUS_PREPARING_PAUSE, color: colors.yello_spectaflow },
            { label: 'Inspection', value: constants.CLEANING_STATUS_INSPECTION, color: colors.curry_yellow_inspection },
            { label: 'Clean', value: constants.CLEANING_STATUS_CLEAN, color: iOSColors.green },
            { label: 'Do not disturb', value: constants.CLEANING_STATUS_DO_NOT_DISTURB, color: colors.blue_spectaflow },
            { label: 'Out of service', value: constants.CLEANING_STATUS_OUT_OF_SERVICE, color: iOSColors.midGray },
            { label: 'No service', value: constants.CLEANING_STATUS_NO_SERVICE, color: colors.teal_sweeply }
        ]
    }

    getCleaningStatusItem(status) {
        const result = this.cleaningStatuses.filter(el => el.value === status)
        return result[0]
    }

    render() {
        const selectedItem = this.getCleaningStatusItem(this.props.selectedStatus)
        return (
            <Select
                value={selectedItem}
                placeholder="Select  status..."
                name="CleaningStatus"
                maxMenuHeight={180}
                isSearchable={false}
                components={{ Option }}
                options={this.cleaningStatuses}
                styles={this.selectStyles}
                onChange={this.props.onChange}
                menuPortalTarget={document.body}
            />
        )
    }
}

// label: item.dial_code + ' ' + item.name,
// value: item.code,
// key: item.code,
// dialCode: item.dial_code
