import styled from 'styled-components'
import * as colors from '@shared/colors'
import { INSIDE_IFRAME, MOBILE_BREAKPOINT } from '../../../../../app/utils/constants'

const black = colors.black_sweeply

export const LogoutButtonWrap = styled.div`
    padding: ${props => props.padding || '9px 33px'};
    width: 100%;
    height: 44px;
    cursor: pointer;
    position: relative;
    margin-left: ${INSIDE_IFRAME ? '25px' : 0};
    margin-right: ${INSIDE_IFRAME ? '5px' : 0};

    > div {
        width: 100%;
        height: 100%;
        display: flex;
        column-gap: 5px;
        align-items: center;

        > span {
            width: auto;
            font-weight: 450;
            font-size: 1.077rem;
            line-height: 16px;
            color: ${black};
            opacity: 0.75;
        }

        > svg {
            opacity: 0.75;
            height: 12px;
        }
    }

    &:hover {
        span {
            opacity: 1;
        }

        svg {
            color: ${black} !important;
            opacity: 1;
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        z-index: 1001;
    }
`
