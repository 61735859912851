import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { startWorkflow } from '../../helpers'
import rentlioLogo from '../../../../img/pms/rentlio/rentlio-logo.png'
import { RENTLIO_WORKFLOW } from '../../signup-worflow-templates'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { PMS_NAMES } from '../../../../utils/constants'

const RentlioStart = () => {
    const history = useHistory()

    useEffect(() => {
        startWorkflow({
            bus: PMS_NAMES.RENTLIO,
            imageUrl: rentlioLogo,
            workflowTemplate: RENTLIO_WORKFLOW,
            currentStep: SIGN_UP.RENTLIO_START,
            history
        })
    }, [])

    return <></>
}

export default RentlioStart
