import React, { createContext, useState, useContext, useCallback } from 'react'
import { isEqual, isFunction } from 'lodash'
import { initialFilter } from './AreasUIHelpers'

export const AreasContext = createContext()

const initArea = {
    id: '',
    name: '',
    description: '',
    address: '',
    group: '',
    displayCleaningStatus: true
}

export const AreasProvider = props => {
    const [areas, setAreas] = useState([])
    const [allAreas, setAllAreas] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [listLoading, setListLoading] = useState(true)
    const [queryParams, setQueryParamsBase] = useState(initialFilter)
    const [ids, setIds] = useState([])
    const [areaForEdit, setAreaForEdit] = useState(initArea)
    const [actionsLoading, setActionsLoading] = useState(false)

    const areasUIEvents = props.areasUIEvents

    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams)
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams
            }
            return nextQueryParams
        })
    }, [])

    const value = {
        areas,
        setAreas,
        allAreas,
        setAllAreas,
        totalCount,
        setTotalCount,
        listLoading,
        setListLoading,
        ids,
        setIds,
        queryParams,
        setQueryParams,
        setQueryParamsBase,
        initArea,
        areaForEdit,
        setAreaForEdit,
        actionsLoading,
        setActionsLoading,
        newAreaButtonClick: areasUIEvents.newAreaButtonClick,
        openEditAreaDialog: areasUIEvents.openEditAreaDialog,
        openDeleteAreasDialog: areasUIEvents.openDeleteAreasDialog
    }

    return <AreasContext.Provider value={value} {...props} />
}

// export const useAreasContext = () => {
// 	const context = useContext(AreasContext)

// 	if (!context) {
// 		throw new Error("useAreasContext must be used inside a AreasProvider")
// 	}

// 	const { state, dispatch } = context
// 	console.log(context)
// 	const { areas } = state

// 	return { areas }
// }
