import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
// import GuestInformation from "./GuestInformation";
// import { ProfileOverview } from "./ProfileOverview";
// import ChangePassword from "./ChangePassword";
// import PersonaInformation from "./PersonaInformation";
// import EmailSettings from "./EmailSettings";
import { GuestsCard } from './GuestsCard'
import { GuestsProvider } from '../../GuestsContext'
import { OptHousekeepingSetup } from './OptHousekeepingSetup'

export default function GuestsPage() {
    return (
        <GuestsProvider guestsUIEvents={{}}>
            <div className="row">
                {/* <Route path="/guest/upgrade/:upgradeType">
                {({ history, match }) => {
                    console.log(match)
                    return (
                        <UpgradeModal
                            show={match != null}
                            upgradeType={match && match.params.upgradeType}
                            onHide={() => {
                                history.push('/guest/guest-subscription')
                            }}
                        />
                    )
                }}
            </Route> */}
                <GuestsCard></GuestsCard>
                <div className="col-12 col-xl-9">
                    <Switch>
                        <Redirect from="/settings/guests" exact={true} to="/settings/guests/housekeeping" />
                        <Route path="/settings/guests/housekeeping" component={OptHousekeepingSetup} />
                        {/* <Route path="/settings/guest/subscription" component={GuestSubscription} />
                        <Route path="/settings/guest/billing" component={Invoice} />
                        <Route path="/settings/guest/issues" component={GuestIssues} /> */}
                    </Switch>
                </div>
            </div>
        </GuestsProvider>
    )
}
