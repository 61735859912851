import React from 'react'
import styled from 'styled-components'

interface Props {
    size?: number
    color?: string
    char: string
}

const Circle = styled.div<Props>`
    width: ${({ size }) => (size ? size : 35)}px;
    height: ${({ size }) => (size ? size : 35)}px;
    border-radius: ${({ size }) => (size ? size * 0.5 : 35 * 0.5)}px;
    border-color: ${({ color }) => (color ? color : 'white')};
    border-width: 2px;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Char = styled.span<Props>`
    font-size: ${({ size }) => (size ? size * 0.6 : 35 * 0.6)}px;
    color: ${({ color }) => (color ? color : 'white')};
    font-weight: bold;
    line-height: 1;
`

const CircleChar = ({ size = 28, color = '#FFF', char }: Props) => {
    const emojiRegex = /\p{Emoji}/u
    const isEmoji = emojiRegex.test(char)
    const numberOrLetterRegex = /^[A-Za-z0-9]$/
    const isNumberOrLetter = numberOrLetterRegex.test(char)

    if (isEmoji && !isNumberOrLetter) {
        return (
            <Char size={size * 1.5} color={color} char={char}>
                {char}
            </Char>
        )
    }

    return (
        <Circle size={size} color={color} char={char}>
            <Char size={size} color={color} char={char}>
                {char}
            </Char>
        </Circle>
    )
}

export default CircleChar
