import styled from 'styled-components'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'

const white = colors.white

interface MinimizedProp {
    minimized: boolean
}

const marginLeft = ({ minimized }: MinimizedProp) => (minimized ? '4px' : '0')
const dimensions = ({ minimized }: MinimizedProp) => (minimized ? '20px' : '60px')
const borderRadius = ({ minimized }: MinimizedProp) => (minimized ? '50%' : '6px')

export const TextIssueWrap = styled.div`
    margin-left: ${marginLeft};
    position: relative;

    .text-container {
        width: ${dimensions};
        height: ${dimensions};
        background-color: ${white};
        border: 1px solid ${({ minimized }: MinimizedProp) => (minimized ? iOSColors.midGray : iOSColors.lightGray)};
        border-radius: ${borderRadius};
        display: flex;
        justify-content: center;
        align-items: center;
    }
`
export const TextIssue = styled.i`
    margin-left: 0;
    color: ${iOSColors.gray};
    font-size: ${({ minimized }: MinimizedProp) => (minimized ? '11px' : '25px')};
`

export const ImageWrap = styled.div`
    position: relative;
    margin-left: ${marginLeft};
    width: ${dimensions};
    height: ${dimensions};
    border-radius: ${borderRadius};
    background-color: ${iOSColors.lightGray};

    > img {
        border-radius: ${borderRadius};
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`
