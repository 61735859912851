import React, { useState, Component, forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'

import { iOSColors } from '@shared/react-native-typography'

import './react-datepicker.css'
import { IconButton } from './buttons/IconButton'
import { getStartOfDayTimestamp } from 'app/utils/formatters'

export default class HoverableDatePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hover: false,
            datePickerBorderColor: iOSColors.white
        }
    }
    render() {
        const isWhite = this.props.isWhite ? true : false
        return (
            <DatePickerFunc
                isWhite={isWhite}
                hasTodayButton={this.props.hasTodayButton}
                selectedDateNumber={this.props.selectedDateNumber}
                hover={this.state.hover}
                onDateChange={this.props.onDateChange}
                clearHover={() => {
                    this.setState({ hover: false })
                }}
            />
        )
    }
}

const DatePickerFunc = ({ selectedDateNumber, onDateChange, clearHover, isWhite, hasTodayButton }) => {
    const [selectedPickerDate, setSelectedPickerDate] = useState(moment(getStartOfDayTimestamp(selectedDateNumber)))
    const ref = React.createRef()
    const selectedPickerDateNumber = moment(selectedPickerDate).valueOf()

    if (selectedDateNumber !== selectedPickerDateNumber) {
        setSelectedPickerDate(moment(selectedDateNumber))
    }

    const today = moment()

    const onDatePickerChange = date => {
        setSelectedPickerDate(moment(date))
        clearHover()
        onDateChange(date)
    }

    const CustomDateInput = forwardRef((props, ref) => {
        return (
            <div className="d-flex flex-center" ref={ref}>
                {hasTodayButton && !selectedPickerDate.isSame(today, 'day') && (
                    <div className="btn btn-circle btn-icon btn-transparent text-transparent" />
                )}
                <IconButton
                    className="btn btn-circle btn-icon btn-transparent"
                    onClick={() => {
                        const previousDay = moment(selectedPickerDateNumber).subtract(1, 'days').valueOf()
                        onDateChange(previousDay)
                    }}
                    icon={`arrow-left icon-nm ${isWhite ? 'text-white text-hover-specta-gray' : 'text-sweeply-black text-hover-dark-65'}`}
                />
                <span
                    style={{ fontSize: '1.231rem' }}
                    className={`font-weight-bolder cursor-pointer ${
                        isWhite ? 'text-white text-hover-specta-gray' : 'text-sweeply-black text-hover-dark-65'
                    }`}
                    onClick={() => {
                        props.onClick()
                    }}>
                    {moment(selectedPickerDate).format('ddd, MMM DD, YYYY')}
                </span>
                <IconButton
                    className="btn btn-circle btn-icon btn-transparent"
                    onClick={() => {
                        const nextDay = moment(selectedPickerDateNumber).add(1, 'days').valueOf()
                        onDateChange(nextDay)
                    }}
                    icon={`arrow-right icon-nm ${isWhite ? 'text-white text-hover-specta-gray' : 'text-sweeply-black text-hover-dark-65'}`}
                />
                {hasTodayButton && !selectedPickerDate.isSame(today, 'day') && (
                    <IconButton
                        className="btn btn-circle btn-icon btn-transparent"
                        onClick={() => {
                            onDateChange(today)
                        }}
                        icon={`arrow-left-filled ${isWhite ? 'text-white' : 'text-danger'}`}
                        iconsize="icon-xl"
                        tooltip={{ text: 'Today', position: 'top' }}
                    />
                )}
            </div>
        )
    })

    CustomDateInput.displayName = 'CustomDateInput'

    return (
        <DatePicker
            dateFormat="eee, MMM dd, yyyy"
            selected={selectedPickerDateNumber}
            onChange={onDatePickerChange}
            customInput={<CustomDateInput ref={ref} />}
        />
    )
}
