import React from 'react'
import { iOSColors } from '@shared/react-native-typography'

export type Extra = {
    name: string
    date: number
    quantity: number
}
export default function ExtraServicesInfo({ extras }: { extras: Extra[] }) {
    const extrasText = extras.map(x => `${x.quantity}x ${x.name}`).join(', ')
    return (
        <div>
            <span
                style={{
                    fontWeight: '600',
                    color: iOSColors.black,
                    fontSize: 17,
                    marginBottom: 6
                }}>
                Extra services
            </span>
            <div
                style={{
                    fontSize: 14,
                    color: iOSColors.gray,
                    whiteSpace: 'pre-line'
                }}>
                {extrasText}
            </div>
        </div>
    )
}
