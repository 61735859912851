import React from 'react'
import { Field, useFormikContext } from 'formik'
import { Input, DatePickerField } from '../../../../../../_metronic/_partials/controls'

const getRepeatType = values => {
    switch (values.repeatType) {
        case 'daily':
            return {
                text: 'day(s)'
            }
        case 'weekly':
            return {
                text: 'week(s)'
            }
        case 'monthly':
            return {
                text: 'month(s)'
            }
        default:
            return null
    }
}

export default function DateRepeatForm() {
    const {
        values: { repeatInterval, repeatType },
        setFieldValue,
        ...formik
    } = useFormikContext()

    const onRepeatDaysChange = e => {
        const value = parseInt(e.target.value)
        setFieldValue('repeatInterval', value)
    }

    return (
        <div>
            <div className="form-group row">
                <label className="col-2 col-form-label font-weight-bold">Repeat every:</label>
                <div className="col-2">
                    <Field
                        name="repeatInterval"
                        type="number"
                        component={Input}
                        placeholder="Pick a number"
                        value={repeatInterval}
                        onChange={onRepeatDaysChange}
                        min="1"
                        customFeedbackLabel={' '}
                    />
                </div>
                <label className="col-2 col-form-label font-weight-bold">{getRepeatType({ repeatType }).text}</label>
            </div>

            {/* {values.repeatType === 'weekly' && (
                <div className="form-group row">
                    <label className="col-2 col-form-label font-weight-bold">On:</label>
                    <div className="col-10">
                        <MultiSelectField
                            values={values}
                            defaultValue={moment(values.start).day()}
                            name="repeatDays"
                            onChange={selected => {
                                if (selected) {
                                    values.repeatDays = selected.map(e => e.value)
                                } else {
                                    values.repeatDays = []
                                }
                                setRepeatDays(values.repeatDays)
                            }}
                        />
                    </div>
                </div>
            )} */}
            <div className="form-group row">
                <div className="col-6">
                    <div style={{ display: 'block' }}>
                        <Field
                            name="start"
                            component={DatePickerField}
                            placeholder="Start date"
                            label="Start date"
                            dateFormat="MMM dd, yyyy"
                        />
                    </div>
                </div>
                <div className="col-6"></div>
            </div>
        </div>
    )
}
