import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding: 0 29.25px;
`

export const TitleWrap = styled.div`
    min-height: 70px;
    display: flex;
    align-items: center;
    column-gap: 6.5px;

    > h3 {
        font-weight: 600;
        font-size: 2.5rem;
        color: #181c32;
    }

    > div > h3 {
        padding-top: 6px;
    }
`

export const FilterButtonsWrap = styled.div`
    display: flex;
    flex: 1;
    column-gap: 15px;
    justify-content: center;
`

export const NewTaskButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const ButtonWrap = styled.div`
    > button {
        width: 122px;
        height: 40px;
        border-radius: 4px;
        font-size: 1.231rem;
    }
`
